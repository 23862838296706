import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { FormatsService } from '@onbatch/shared/services/formats.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AmountFormatPipe } from '@onbatch/shared/pipes';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { isEmpty } from 'lodash';
import { checkIfClickedElementHasClass } from '@onbatch/shared/utils';
import { FormBox } from '@onbatch/shared/models';

@Component({
  selector: 'app-input-micro-action',
  templateUrl: './input-micro-action.component.html'
})
export class InputMicroActionComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() options: FormBox;

  @Output() dropdownValueChanged = new EventEmitter<string>();
  @Output() inputValueChanged = new EventEmitter<string>();
  @Output() search = new EventEmitter<string>();

  inputValue: string;

  isInputFocused = false;
  isDropdownOpen = false;

  readonly patternForAmount = this.formatsService.patternForAmount();
  readonly patternForAmountInteger = this.formatsService.patternForAmountInteger();

  @ViewChild('select', { static: false }) private select: NgSelectComponent;
  @ViewChild('microActionInput', { static: false }) private microActionInput: ElementRef;

  get errors(): ValidationErrors {
    return {
      ...this.formGroup.controls[this.options.inputName].errors,
      ...this.formGroup.controls[this.options.dropdownName].errors
    };
  }

  get isInputInvalid(): boolean {
    return this.isFormSubmitted && !isEmpty(this.errors);
  }

  get uomName(): string {
    if (this.formGroup.get(this.options.dropdownName).value) {
      const selectedUom = this.options.selectItems.find(el => el.externalId === this.formGroup.get(this.options.dropdownName).value);
      return ` ${selectedUom ? selectedUom.name : ''}`;
    } else {
      return ` ${this.options && this.options.selectItems.length ? this.options.selectItems[0].name : ''}`;
    }
  }

  constructor(private formatsService: FormatsService,
              private amountPipe: AmountFormatPipe,
  ) {
  }

  ngOnInit(): void {
    this.setInputValue(String(this.formGroup.get(this.options.inputName).value));
  }

  inputClicked(): void {
    this.isInputFocused = true;
  }

  openSelect(): void {
    if (!this.isDropdownOpen) {
      this.select.open();
    } else {
      this.select.close();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeSelect(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['ng-arrow']);
    if (event.value && this.isDropdownOpen && !isExcludedElementClicked) {
      this.isDropdownOpen = false;
    }
  }

  onDropdownValueChange(event: string): void {
    this.dropdownValueChanged.emit(event);
  }

  getLookup(value: string = null): void {
    this.search.emit(value);
  }

  setInputValue(value: string): void {
    if (value) {
      const includesDecimalMarker = value.includes(this.patternForAmount.decimalMarker);
      let numberPrecision: number;
      if (includesDecimalMarker) {
        const splittedValue = value.split(this.patternForAmount.decimalMarker);
        numberPrecision = splittedValue[splittedValue.length - 1].length;
      }
      const isInteger: boolean = Number.isInteger(Number(this.formGroup.get(this.options.inputName).value));
      const precision: number = isInteger && !includesDecimalMarker ? 0 : numberPrecision;
      this.inputValue = `${this.amountPipe.transform(Number(this.formGroup.get(this.options.inputName).value), precision)}${isInteger
      && includesDecimalMarker ? this.patternForAmount.decimalMarker : ''}`;
    } else {
      this.inputValue = this.options.placeholder;
    }
  }

  onInputValueChange(event: string): void {
    if ((new RegExp(/^[0]+$/)).test(event)) {
      event = '0';
      this.microActionInput.nativeElement.value = event;
    }
    this.setInputValue(event);
    this.inputValueChanged.emit(event);
  }

  focusInput(): void {
    const value = this.microActionInput.nativeElement.value;
    this.microActionInput.nativeElement.setSelectionRange(value.length, value.length);
    if (!this.isInputFocused) {
      this.isInputFocused = true;
    }
    this.microActionInput.nativeElement.focus();
  }

  removeFocus(e: ClickOutsideData): void {
    if (e.value && this.isInputFocused) {
      this.isInputFocused = false;
    }
  }

  doubleClicked(): void {
    this.microActionInput.nativeElement.select();
  }
}
