import { Component, HostBinding, Input } from '@angular/core';
import { AnimationState, HistoryLogBaseModel, mapLogs } from './utils';
import { SlideInOutAnimation } from '../../animations';
import {
  InventoryHistoryLogListResponse,
  InventoryHistoryLogListResponseInventoryHistoryActionTypeId as ActionTypeId,
  InventoryHistoryLogResponse
} from '../../../core/services/InventoryRx';
import { MasterItemsService } from '../../../inventory/master-items/master-item.service';


@Component({
  selector: 'app-history-log',
  templateUrl: './history-log.component.html',
  animations: [SlideInOutAnimation]
})
export class HistoryLogComponent {
  @Input() log: InventoryHistoryLogListResponse;
  @Input() extended = false;

  logs: HistoryLogBaseModel[] = [];
  animationState = AnimationState.Out;

  get isLogSingle(): boolean {
    return this.log.inventoryHistoryActionTypeId === ActionTypeId.MasterItemCreated
      || this.log.inventoryHistoryActionTypeId === ActionTypeId.MasterItemDeleted
      || this.log.inventoryHistoryActionTypeId === ActionTypeId.MasterItemArchived;
  }

  @HostBinding('style.width') private width = '100%';

  constructor(private masterItemService: MasterItemsService) {
  }

  showLogs(): void {
    const onSuccess = (data: InventoryHistoryLogResponse) => {
      this.logs = mapLogs(data.logs);
      this.toggleAnimation();
    };

    if (!this.logs.length && !this.isLogSingle) {
      this.masterItemService
        .getSingleHistoryLogById(this.log.externalId)
        .subscribe((data: InventoryHistoryLogResponse) => onSuccess(data));
    } else {
      this.toggleAnimation();
    }
  }

  toggleAnimation(): void {
    this.animationState = this.animationState === AnimationState.Out ? AnimationState.In : AnimationState.Out;
  }

  getImage(image: string): string {
    if (image) {
      return image;
    } else {
      return './assets/images/avatar.png';
    }
  }
}

