import { HttpClient } from '@angular/common/http';
import { EnvService } from './Env';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { AccountClient, CompanySubscriptionResponseModel, CompanyWithCountryResponseModel, CostPriceFormatResponseModel, Features2, FormattingResponseModel, FormattingResponseModelMeasurementSystem, ManufacturingResponseModel, ManufacturingResponseModelAlcoholContent, ManufacturingResponseModelPureAlcohol, NameExternalIdModel, SubscriptionCompanyResponse, UserSettingsResponseModel } from './Account';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { dateFormats } from '../../account/settings/settings-statics.enum';
import smartlookClient from 'smartlook-client';
import { SettingsService } from 'app/account/settings/settings.service';
import * as crypto from "crypto-js";
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/common/http";
import * as i3 from "./Env";
import * as i4 from "@ngx-pwa/local-storage";
import * as i5 from "../../shared/services/mixpanel.service";
import * as i6 from "./subscription.service";
import * as i7 from "../../account/settings/settings.service";
export class AuthService {
    constructor(cookieService, http, envService, localStorage, mixpanelService, subscriptionService, settingsService) {
        this.cookieService = cookieService;
        this.http = http;
        this.envService = envService;
        this.localStorage = localStorage;
        this.mixpanelService = mixpanelService;
        this.subscriptionService = subscriptionService;
        this.settingsService = settingsService;
        this.formattingSettings = new BehaviorSubject(null);
        this.currentUser = new BehaviorSubject(null);
        this.currentUserRoles = new BehaviorSubject(null);
        this.currentSettings = new BehaviorSubject(null);
        this.currentOrg = new BehaviorSubject('');
        this.isAuthenticatedSubject = new BehaviorSubject(false);
        this.isAdmin = new BehaviorSubject(false);
        this.organizationsResponse = [];
        this.currentSubscription = new BehaviorSubject(null);
        this.user = null;
        this.checkLocalStorageVersion();
        this.adminClient = new AccountClient(this.http, envService.apiUrl);
        const key = this.getStorageKey('onbatch-permissions');
        this.localStorage.setItemSubscribe(key, null);
        this.initUser();
    }
    static getUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
    static getUserRoles() {
        const user = AuthService.getUser();
        if (!user) {
            return null;
        }
        return user.profile.TenantRoles;
    }
    static getAuthorizationHeaderValue() {
        const user = AuthService.getUser();
        if (!user) {
            return null;
        }
        return `Bearer ${user.access_token}`;
    }
    static startAuthentication() {
        window.location.href = '/account/signin';
        return;
    }
    static getCurrentSettings() {
        const user = AuthService.getUser();
        if (!user) {
            return null;
        }
        return user.settings;
    }
    static get isMixPanelActivated() {
        return AuthService.isMixPanelInitiated.getValue();
    }
    static get isImpersonate() {
        return AuthService.isImpersonatePerson.getValue();
    }
    static getFormattingSettingsFromLocalStorage() {
        const settings = localStorage.getItem('formatting-settings');
        if (settings) {
            return JSON.parse(settings);
        }
        return settings;
    }
    static get getOwlDatePickerSettings() {
        const owlSettings = {
            parseInput: { hour: 'numeric', minute: 'numeric' },
            fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
            datePickerInput: { month: 'short', day: 'numeric', year: 'numeric' },
            timePickerInput: { hour: 'numeric', minute: 'numeric' },
            monthYearLabel: { year: 'numeric', month: 'short' },
            dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
            monthYearA11yLabel: { year: 'numeric', month: 'long' }
        };
        const settings = AuthService.getFormattingSettingsFromLocalStorage();
        if (settings) {
            const { dateFormat } = settings;
            switch (dateFormat) {
                case dateFormats.format1: {
                    owlSettings.parseInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
                    owlSettings.datePickerInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
                    break;
                }
                case dateFormats.format2: {
                    owlSettings.parseInput = { month: 'short', day: 'numeric', year: 'numeric' };
                    owlSettings.datePickerInput = { month: 'short', day: 'numeric', year: 'numeric' };
                    break;
                }
                case dateFormats.format3: {
                    owlSettings.parseInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
                    owlSettings.datePickerInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
                    break;
                }
                case dateFormats.format4: {
                    owlSettings.parseInput = { month: 'long', day: 'numeric', year: 'numeric' };
                    owlSettings.datePickerInput = { month: 'long', day: 'numeric', year: 'numeric' };
                    break;
                }
                default:
            }
        }
        return owlSettings;
    }
    initUser() {
        const current = AuthService.getUser();
        this.currentUser.next(current);
        if (current && current.access_token) {
            this.isAuthenticatedSubject.next(true);
        }
    }
    setUser(data) {
        data.profile = jwt_decode(data.access_token);
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.isAdmin.next(data.profile.Role === 'OnBatchAdmin');
    }
    getCurrentOrganization() {
        return this.currentOrg.value;
    }
    static get isReportingAvailable() {
        if (AuthService.getUser().profile.ReportingAvailable[0] == "False") {
            return false;
        }
        else if (AuthService.getUser().profile.ReportingAvailable[0] == "True") {
            return true;
        }
        return false;
    }
    getFormattingSettingsObservable() {
        return this.formattingSettings.value;
    }
    updateLocalUser(data) {
        const current = AuthService.getUser();
        const details = Object.assign({}, current, data);
        this.currentUser.next(details);
        if (details.access_token && details.access_token.length > 0) {
            this.isAuthenticatedSubject.next(true);
        }
        else {
            this.isAuthenticatedSubject.next(false);
        }
        this.setUser(details);
    }
    setUserRoles() {
        const roles = JSON.parse(this.currentUser.value.profile.TenantRoles);
        const currentOrg = this.getCurrentOrganization();
        if (roles.hasOwnProperty(currentOrg)) {
            this.currentUserRoles.next(roles[currentOrg]);
        }
        else {
            this.currentUserRoles.next({});
        }
    }
    getAccess(module, permissions) {
        let shouldBeVisible = false;
        const isFeatureAvailable = new Features2({
            misc_RolesPermissions: this.subscriptionService.getAccess(SubscriptionFeatures.MiscRolesPermissions)
        });
        if (!isFeatureAvailable.misc_RolesPermissions) {
            shouldBeVisible = true;
        }
        else {
            const roles = JSON.parse(AuthService.getUserRoles());
            if (roles) {
                const rolesForCurrentOrganization = roles[this.getOrgFromStorage()];
                if (rolesForCurrentOrganization[module]) {
                    rolesForCurrentOrganization[module].map((permission) => {
                        if (permission === permissions) {
                            shouldBeVisible = true;
                        }
                    });
                }
            }
        }
        return shouldBeVisible;
    }
    setFormattingSettings() {
        return forkJoin([
            this.settingsService.getSettingsForFormatting(),
            this.adminClient.settingsManufacturingGet(),
        ]).subscribe((formatting) => {
            this.setFormattingSettingsObservable(Object.assign({}, formatting[0], { alcoholContent: formatting[1].alcoholContent, pureAlcohol: formatting[1].pureAlcohol }));
        });
    }
    setSubscription() {
        return this.subscriptionService.getSubscription();
    }
    getSettingsForFormatting() {
        return this.adminClient.settingsFormattingGet();
    }
    setFormattingSettingsObservable(data) {
        localStorage.setItem('formatting-settings', JSON.stringify(Object.assign({}, this.formattingSettings.value, data)));
        this.formattingSettings.next(Object.assign({}, this.formattingSettings.value, data));
    }
    getUserInfo(companyExternalId) {
        return this.adminClient.accountSettingsByCompanyExternalIdGet(companyExternalId);
    }
    getCurrentUser() {
        return this.currentUser.value;
    }
    isLoggedIn() {
        return this.isAuthenticatedSubject.value;
    }
    getClaims() {
        return this.currentUser.value;
    }
    setUserInfo(user) {
        this.currentUser.next(user);
    }
    setCountry() {
        const key = this.getStorageKey('onbatch-organizationId');
        const lsid = localStorage.getItem(key);
        const currentUser = this.currentUser.value;
        try {
            const organization = currentUser.organizations.find((org) => org.externalId === lsid).externalId;
            const countryID = this.organizationsResponse.find((org) => org.externalId === organization).country.externalId;
            localStorage.setItem('companiesCountry', countryID);
        }
        catch (e) {
            localStorage.setItem('companiesCountry', null);
            throw new Error(`Issue with getting company's country information`);
        }
    }
    setOrg(organizations) {
        this.organizationsResponse = organizations;
        const key = this.getStorageKey('onbatch-organizationId');
        const lsid = localStorage.getItem(key);
        localStorage.setItem(key, organizations[0].externalId);
        this.currentOrg.next(organizations[0].externalId);
        organizations.map((permission) => {
            if (permission.externalId === lsid) {
                this.currentOrg.next(lsid);
                localStorage.setItem(key, lsid);
            }
        });
    }
    getOrgFromStorage() {
        return localStorage.getItem(this.getStorageKey('onbatch-organizationId'));
    }
    getTokens() {
        return this.http.get('/account/tokens').toPromise().then((response) => response);
    }
    getUserSettings(companyExternalId) {
        return this.adminClient.accountSettingsByCompanyExternalIdGet(companyExternalId).toPromise();
    }
    setUserSettings(settings) {
        const current = AuthService.getUser();
        current.settings = settings;
        this.currentUser.next(current);
        this.setUser(current);
        this.currentSettings.next(settings);
        this.updateLocalUser(settings);
        this.setUserRoles();
    }
    completeAuthentication() {
        return this.getTokens()
            .then((user) => this.updateLocalUser(user))
            .then(() => !this.isAdmin.value && this.completeUserAuthentication())
            .catch(e => console.log(e));
    }
    silentRenew() {
        return this.getTokens()
            .then((user) => this.updateLocalUser(user))
            .then(() => !this.isAdmin.value && this.completeSilentRenew())
            .catch(e => console.log(e));
    }
    completeSilentRenew() {
        return this.getOrganizations()
            .then((organizations) => this.setOrg(organizations))
            .then(() => this.getUserSettings(this.currentOrg.value))
            .then((settings) => this.setUserSettings(settings))
            .then(() => this.setSubscription())
            .then(() => this.setFormattingSettings())
            .then(() => this.setIntercom())
            .then(() => this.initMixPanel())
            .catch(e => console.log(e));
    }
    completeUserAuthentication() {
        return this.getOrganizations()
            .then((organizations) => this.setOrg(organizations))
            .then(() => this.getUserSettings(this.currentOrg.value))
            .then((settings) => this.setUserSettings(settings))
            .then(() => this.setCountry())
            .then(() => this.setSubscription())
            .then(() => this.setFormattingSettings())
            .then(() => this.setHubSpot())
            //.then(() => this.setSmartLook())
            .then(() => this.setIntercom())
            .then(() => this.initMixPanel())
            .catch(e => console.log(e));
    }
    getOrganizations() {
        return this.adminClient.accountSettingsCompaniesGet().toPromise();
    }
    logout() {
        this.user = null;
        this.currentSettings.next(null);
        this.currentUser.next(null);
        this.currentOrg.next(null);
        this.currentUserRoles.next(null);
        this.currentSubscription.next(null);
        this.mixpanelService.logout();
        AuthService.isMixPanelInitiated.next(false);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentSubscription');
        localStorage.removeItem(this.getStorageKey('onbatch-permissions'));
        localStorage.removeItem(this.getStorageKey('onbatch-organizationId'));
        localStorage.removeItem('formatting-settings');
        this.localStorage.removeItem('select-vessel-active-tab').subscribe(() => {
            window.location.href = '/account/signout';
        });
        return false;
    }
    getStorageKey(prefix) {
        const user = this.getCurrentUser();
        if (user !== null && user.profile) {
            return `${prefix}-${user.profile.sub}`;
        }
        return prefix;
    }
    refreshToken() {
        const updatedUser = AuthService.getUser();
        return this.http.get(`/account/tokens/refresh/${updatedUser.refresh_token}`)
            .pipe(map((user) => {
            updatedUser.access_token = user.access_token;
            updatedUser.refresh_token = user.refresh_token;
            this.setUser(updatedUser);
            return user;
        }));
    }
    getSubscriptionForUserSettings() {
        return this.currentUser;
    }
    getCurrentOrganizationName() {
        const currentOrganization = this.currentUser.getValue().organizations.find(el => el.externalId === this.currentOrg.getValue());
        if (currentOrganization) {
            return currentOrganization.name;
        }
    }
    setIntercom() {
        if (!AuthService.isImpersonate && !this.isAdmin.value) {
            const user = this.currentUser.value;
            const currentOrgIndustry = user.organizations.find(el => el.externalId === this.currentOrg.value).industryTypeId;
            if (user) {
                window.Intercom('boot', {
                    app_id: this.envService.env.intercomAppId,
                    hide_default_launcher: false,
                    user_id: user.externalId,
                    email: user.email,
                    name: `${user.firstName} ${user.lastName}`,
                    phone: user.phoneNumber,
                    user_hash: this.signKey(user.externalId, this.envService.env.intercomIdentitySecret),
                    companies: user.organizations.map(organization => {
                        return {
                            company_id: organization.externalId,
                            name: organization.name
                        };
                    }),
                    company: {
                        company_id: this.currentOrg.value,
                        name: this.getCurrentOrganizationName(),
                        industry: currentOrgIndustry,
                        'plan': this.subscriptionService.currentSubscription.value ? this.subscriptionService.currentSubscription.value.subscriptionType : null,
                    }
                });
            }
        }
    }
    setSmartLook() {
        const user = this.currentSettings.value;
        smartlookClient.init(this.envService.env.smartLookToken);
        if (user) {
            smartlookClient.identify(AuthService.isImpersonate ? 999 : user.externalId, {
                name: AuthService.isImpersonate ? 'Impersonated User' : user.firstName,
                email: user.email,
            });
        }
    }
    setMixpanelUser() {
        const user = this.currentSettings.value;
        const currentOrgIndustry = user.organizations.find(el => el.externalId === this.currentOrg.value).industryTypeId;
        const setUserData = () => {
            const userData = {
                $distinct_id: user.externalId,
                $email: user.email,
                $name: `${user.firstName} ${user.lastName}`,
                $phone: user.phoneNumber,
                Companies: user.organizations.map(organization => {
                    return organization.name;
                }).join(', '),
                Company: `${this.getCurrentOrganizationName()} (Industry: ${currentOrgIndustry})`,
                Plan: this.subscriptionService.currentSubscription.value.subscriptionType
            };
            this.mixpanelService.register_once(userData);
            this.mixpanelService.setPeople(userData);
        };
        if (!localStorage.getItem('currentSubscription')) {
            this.adminClient.paymentSubscriptionCurrentGet().subscribe((subscription) => {
                this.subscriptionService.currentSubscription.next(subscription);
                localStorage.setItem('currentSubscription', JSON.stringify(subscription));
                setUserData();
            });
        }
        else {
            setUserData();
        }
    }
    initMixPanel() {
        const user = this.currentSettings.value;
        if (user && !AuthService.isMixPanelActivated && this.envService.env.mixPanelToken.length) {
            const isImpersonate = !!this.getClaims().profile.OnBatchImpersonation;
            try {
                AuthService.isImpersonatePerson.next(isImpersonate);
                const config = {
                    batch_requests: true
                };
                if (isImpersonate) {
                    config['opt_out_tracking_by_default'] = isImpersonate;
                }
                else {
                    for (const key in localStorage) {
                        if (key.includes('__mp_opt_in_out_')) {
                            localStorage.removeItem(key);
                        }
                    }
                    const allCookies = this.cookieService.getAll();
                    for (const key in allCookies) {
                        if (key.includes('__mp_opt_in_out_')) {
                            this.cookieService.delete(key);
                        }
                    }
                }
                this.mixpanelService.init(this.envService.env.mixPanelToken, config);
                AuthService.isMixPanelInitiated.next(true);
                this.mixpanelService.identify(user.externalId);
                this.setMixpanelUser();
                this.mixpanelService.setGroup('company_id', this.getCurrentOrganizationName());
            }
            catch (e) {
            }
        }
    }
    setHubSpot() {
        const user = this.currentSettings.value;
        return this.http.post(`${this.envService.env.hubspot.url}`, {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
        }).subscribe((response) => {
            window['hsConversationsSettings'] = {
                identificationEmail: user.email,
                identificationToken: response.token
            };
            if (window['HubSpotConversations'] !== undefined) {
                window['HubSpotConversations'].widget.load();
            }
        });
    }
    checkLocalStorageVersion() {
        if (!this.isDifferentLocalstorageVersion()) {
            return;
        }
        this.clearLocalstorageVersion();
    }
    isDifferentLocalstorageVersion() {
        return localStorage.getItem('localStorageVersion') !== this.envService.env.localStorageVersion;
    }
    clearLocalstorageVersion() {
        localStorage.clear();
        this.setLocalStorageVersionFromEnv();
    }
    setLocalStorageVersionFromEnv() {
        localStorage.setItem('localStorageVersion', this.envService.env.localStorageVersion);
    }
    signKey(msg, clientKey) {
        return crypto.HmacSHA256(msg, clientKey).toString(crypto.enc.Hex);
    }
}
AuthService.isMixPanelInitiated = new BehaviorSubject(false);
AuthService.isImpersonatePerson = new BehaviorSubject(false);
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.EnvService), i0.ɵɵinject(i4.LocalStorage), i0.ɵɵinject(i5.MixpanelService), i0.ɵɵinject(i6.SubscriptionService), i0.ɵɵinject(i7.SettingsService)); }, token: AuthService, providedIn: "root" });
