import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as timezone from 'moment-timezone';
import { Moment } from 'moment';
import { FormatsService, TimeFormat } from '../../services/formats.service';
import { closeCalendar, formatTimer } from '../../utils';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TimerInputValue } from '../../models';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import { SettingsService } from 'app/account/settings/settings.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class DateTimePickerComponent implements OnInit {
  @Input() isRequired?: boolean;
  @Input() formSubmitted?: boolean;
  @Input() noSpacing?: boolean;
  @Input() noMargin?: boolean;
  @Input() title?: string;
  @Input() id?: string | number;
  @Input() onlyDate;
  @Input() validationMessage: string;
  @Input() additionalInfo: string;
  @Input() additionalClassName = '';
  @Input() placeholder: string;
  @Input() noIcon: boolean;
  @Input() updateTimer: boolean;

  @Input() set date(value: number) {
    this._date = value;
    this.updateCalendar();
  }

  get date(): number {
    return this._date;
  }

  @Output() dateUpdated = new EventEmitter<Moment | null>();

  readonly timeFormat = this.formatsService.getTimeFormat();

  calendar = <{ date: Date, time: string }>{
    date: null,
    time: null
  };

  formBoxes = {
    timer: {
      fieldType: 'timer',
      name: 'timer',
      type: 'text',
      field: 'timer',
      customClass: this.timeFormat === TimeFormat.TwelveHours ? 'form__box--timer' : 'form__box--timer--small',
    }
  };

  closeDatepicker = closeCalendar;

  formGroup: FormGroup;
  private _date: number;
  private timeZone = timezone.tz.guess();

  constructor(
    private fb: FormBuilder,
    private formatsService: FormatsService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      timer: new FormControl(null, Validators.required)
    });

    this.setTimerValue(this.calendar.time);
    this.updateCalendar();
    if (this.id) {
      this.formBoxes.timer.name += this.id;
    }
  }

  onCalendarChange() {
    if (!!this.calendar.date && !!this.calendar.time) {
      const day = timezone(this.calendar.date).tz(this.timeZone).startOf('d').format('YYYY-MM-DD');
      this.dateUpdated.emit(timezone(day + ' ' + this.calendar.time));
    } else if (!!this.calendar.date) {
      const day = timezone(this.calendar.date).tz(this.timeZone).startOf('d');
      this.dateUpdated.emit(timezone(day.format('YYYY-MM-DD')));
    } else {
      this.dateUpdated.emit(null);
    }
  }

  onTimerInputChange(data: TimerInputValue) {
    if (data) {
      this.calendar.time = timezone(formatTimer(data), 'HH:mm').format('HH:mm');
    } else {
      this.calendar.time = null;
    }
    this.setTimerValue(this.calendar.time);
    this.onCalendarChange();
  }

  addClosePrevent(): void {
    document.querySelector('.owl-dt-container-row').addEventListener('click', (event: Event) => {
      event.stopPropagation();
    });
  }

  private setTimerValue(time: string) {
    if (this.formGroup) {
      this.formGroup.get('timer').setValue(time ? time : null);
    }
  }

  private updateCalendar() {
    if (this.date) {
      const date = timezone.unix(this.date).format();

      this.calendar = {
        date: timezone(date).tz(this.timeZone, true).startOf('d').toDate(),
        time: this.onlyDate ? null : timezone(date).format('HH:mm')
      };

    } else {
      this.calendar.date = null;
      this.calendar.time = null;
    }

    this.setTimerValue(this.calendar.time);
  }
}
