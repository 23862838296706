import { Pipe, PipeTransform } from '@angular/core';
import { AuthService, IFormattingSettings } from '../../core/services/auth.service';
import { CostPriceFormatResponseModel } from '../../core/services/Account';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'amountFormat'
})
export class AmountFormatPipe implements PipeTransform {
  settings: IFormattingSettings;

  constructor(public authService: AuthService) {
  }

  transform(value: number, precision?: number, asNumber?: boolean): any {
    function setCharAt(str: string, index: number, chr: string) {
      if (index > str.length - 1) {
        return str;
      }
      return str.substr(0, index) + chr + str.substr(index + 1);
    }

    const formatting = this.authService.getFormattingSettingsObservable();
    if (formatting) {
      this.settings = cloneDeep(formatting);
    } else {
      this.settings = <IFormattingSettings>{
        amountsPrecision: 2,
        costPriceFormat: new CostPriceFormatResponseModel({
          decimals: '.',
          thousands: ','
        })
      };
    }

    if (precision !== undefined && precision !== null) {
      this.settings.amountsPrecision = precision;
    }

    if (value === undefined || value === null) {
      return '';
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    let newValue = value.toFixed(this.settings.amountsPrecision);
    if (asNumber) {
      return parseFloat(newValue);
    }
    const rx = /(\d+)(\d{3})/;
    const _this = this;
    if (this.settings.amountsPrecision > 0) {
      newValue = setCharAt(newValue, newValue.length - _this.settings.amountsPrecision - 1, _this.settings.costPriceFormat.decimals);
    }
    return String(newValue).replace(/^\d+/, function(w: string) {
      let res = w;
      while (rx.test(res)) {
        res = res.replace(rx, `$1${_this.settings.costPriceFormat.thousands}$2`);
      }
      return res;
    });

  }
}
