import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseList, changeTableHeight} from '@onbatch/shared/classes/base-list';
import {AuthService} from '@onbatch/core/services/auth.service';
import {Subscription} from 'rxjs';
import {HeaderParamsModel} from '@onbatch/shared/models';
import {
  PaginatedResponseOfMaterialItemsSelectionListResponseModel,
  SellableMasterItemResponseModel,
  SalesOrderItemResponseModel
} from '@onbatch/core/services/Sales';
import {ShippingItemsDataTable, ShippingItemsParamsModel} from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import {FiltersService} from '../../../../../filters/filters.service';
import {PackagingMaterialsSelectItemsService} from '../../../../services/packaging-materials-select-items.service';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';

@Component({
  selector: 'app-packaging-materials-manual-selection-list',
  templateUrl: './packaging-materials-manual-selection-list.component.html',
  providers: [FiltersService]
})
export class PackagingMaterialsManualSelectionListComponent extends BaseList implements OnInit, OnDestroy {
  @Input() customClass: string;
  @Input() item: SalesOrderItemResponseModel;
  @Input() remainingQuantity: number;

  remainingQuantityExceeded = false;
  initRemainingQuantity: number;
  params: HeaderParamsModel = {
    x_query: undefined,
    x_order: 'masterItemName',
    x_desc: false,
    x_pageNumber: 1,
    x_pageSize: 10
  };

  masterItem: SellableMasterItemResponseModel;
  packagingMaterials: PaginatedResponseOfMaterialItemsSelectionListResponseModel;
  dataTableSettings: ShippingItemsDataTable;
  initRemainingQuantityTaken = false;

  private subscription: Subscription = new Subscription();

  constructor(public authService: AuthService,
              public filtersService: FiltersService,
              public packagingMaterialsSelectItemsService: PackagingMaterialsSelectItemsService,
              public subscriptionService: SubscriptionService) {
    super(filtersService, subscriptionService);
  }

  ngOnInit(): void {
    this.packagingMaterialsSelectItemsService.setMasterItem(this.item.masterItem);
    this.initRemainingQuantity = this.remainingQuantity;
    this.subscription.add(this.packagingMaterialsSelectItemsService
      .getPackagingMaterialsPagination()
      .subscribe(item => (this.pagination = item))
    );
    this.subscription.add(this.packagingMaterialsSelectItemsService.getMasterItem().subscribe((masterItem: SellableMasterItemResponseModel) => this.masterItem = masterItem));
    this.subscription.add(this.packagingMaterialsSelectItemsService
      .getPackagingMaterialsDataTableParams()
      .subscribe((settings: ShippingItemsDataTable) => {
        this.dataTableSettings = settings;
        if (this.dataTableSettings.requestModel && this.dataTableSettings.requestModel.filter && this.activeFilters !== this.dataTableSettings.requestModel.filter) {
          this.afterFiltersApplied(this.dataTableSettings.requestModel.filter, false);
        }
      })
    );

    this.subscription.add(this.packagingMaterialsSelectItemsService.getListOfPackagingMaterials().subscribe(list => this.afterFetchList(list)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.packagingMaterialsSelectItemsService.packagingMaterialsList.next(null);
  }

  setQuery(data: string): void {
    this.params.x_query = data;
    this.searchSubmit();
  }

  setFilters(): void {
    this.packagingMaterialsSelectItemsService.setPackagingMaterialsDataTableParams({
      params: this.dataTableSettings.params,
      requestModel: {
        filter: this.activeFilters,
        masterItemExternalId: this.masterItem.externalId
      }
    });

    changeTableHeight();
  }

  searchSubmit(): void {
    this.packagingMaterialsSelectItemsService.setParamsForPackagingMaterials(this.params);
  }

  setParams(): void {
    this.packagingMaterialsSelectItemsService.setPackagingMaterialsDataTableParams({params: this.dataTableSettings.params});
  }

  setSort(params: ShippingItemsParamsModel): void {
    this.packagingMaterialsSelectItemsService.setPackagingMaterialsDataTableParams({
      sort: {
        columnName: params.x_order,
        desc: params.x_desc
      }
    });
  }

  afterFetchList(list: PaginatedResponseOfMaterialItemsSelectionListResponseModel): void {
    this.packagingMaterials = list;
    this.listLoaded = true;
  }

  setSettings(): void {
    this.packagingMaterialsSelectItemsService.setPackagingMaterialsDataTableParams({settings: this.dataTableSettings.settings});
  }

  handleInitRemainingQuantityTaken(event: boolean) {
    this.initRemainingQuantityTaken = event;
  }
}
