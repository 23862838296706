import { BehaviorSubject, merge, combineLatest, timer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, } from 'rxjs/operators';
import { falsy } from '@onbatch/shared/utils';
import * as i0 from "@angular/core";
/*
  Let's assume our input Observable goes like this:
  true      |-----------------------------------------|
            |                                         |
  false  ---|                                         |------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  True means that request is in progress, false means that request is done.

  Using `debounceTime` results in a waveform looking something like this:
  true                    |-----------------------------------------|
                          |                                         |
  false  -----------------|                                         |-----------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  That's nice enough, it accounts for very short spikes and spares user from spinner-induced seizure, but we can do better.
  Notice how request ends at t3, yet after debouncing it ends at t4. That's not good - user has to stare at our ugly
  spinner after data is already fetched. We can solve it by defining a custom operator. Result looks like this:
  true                    |---------------------------|
                          |                           |
  false  -----------------|                           |--------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  Perfect! We can avoid spinner-fatigue by hiding small spikes (shorter than `delay`),
  but we don't needlessly postpone hiding the spinner.
 */
export function eagerDebounce(startDelay) {
    return input$ => merge(input$.pipe(debounceTime(startDelay)), input$.pipe(falsy()));
}
/*
  Makes sure that "time on" ([t1..t3]) lasts at least `minimalTimeOn` milliseconds.
  true                    |---------------------------|
                          |                           |
  false  -----------------|                           |--------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6
 */
export function minTime(minimalTimeOn) {
    return input$ => input$.pipe(switchMap(value => {
        if (value) {
            return of(value);
        }
        return combineLatest([
            input$.pipe(falsy()),
            timer(minimalTimeOn),
        ]).pipe(map(_ => false));
    }));
}
export class SpinnerService {
    constructor() {
        this.spinnerDelay = 400;
        this.routeSpinnerDelay = 100;
        this.minimalTimeOn = 300;
        this.routeSpinnerSubject = new BehaviorSubject(false);
        this.globalSpinnerSubject = new BehaviorSubject(false);
        this.filteredGlobalSpinnerSubject = merge(this.globalSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay)), this.routeSpinnerSubject.pipe(eagerDebounce(this.routeSpinnerDelay))).pipe(distinctUntilChanged(), minTime(this.minimalTimeOn));
        this.detailSpinnerSubject = new BehaviorSubject(false);
        this.doubledDetailSpinnerSubject = new BehaviorSubject(false);
        this.filteredDetailSpinnerSubject = this.detailSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay));
        this.filteredDoubledDetailSpinnerSubject = this.doubledDetailSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay));
    }
    setGlobalSpinner(show) {
        this.globalSpinnerSubject.next(show);
    }
    setDetailSpinner(show) {
        this.detailSpinnerSubject.next(show);
    }
    setDoubledDetailSpinner(show) {
        this.doubledDetailSpinnerSubject.next(show);
    }
    setRouteSpinner(show) {
        this.routeSpinnerSubject.next(show);
    }
    getGlobalSpinner() {
        return this.filteredGlobalSpinnerSubject;
    }
    getDetailSpinner() {
        return this.filteredDetailSpinnerSubject;
    }
    getDoubledDetailSpinner() {
        return this.filteredDoubledDetailSpinnerSubject;
    }
}
SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
