import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService) {
  }

  canActivate(): Promise<any> | boolean {
    const isUser = this.authService.getCurrentUser();
    const isOrgID = this.authService.getOrgFromStorage();

    if (isUser && (isOrgID || isUser.profile.Role === 'OnBatchAdmin')) {
      return true;
    }
    AuthService.startAuthentication();
    return false;
  }
}
