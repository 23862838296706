import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';

import { UiKitComponent } from './ui-kit.component';
import { SharedModule } from '../shared';
import { SidebarModule } from '../sidebar/sidebar.module';
import { TableAccComponent } from './table-acc/table-acc.component';

const routes: Routes = [
  {
    path: 'ui-kit',
    component: UiKitComponent
  }
];

@NgModule({
  declarations: [UiKitComponent, TableAccComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    NgSelectModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [UiKitComponent],
  providers: []
})
export class UiKitModule {
}
