import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {VesselLibraryService} from '../../../manufacturing/shared/services/vessel-library.service';
import {
  EquipmentModelResponse,
  EquipmentModelTypeId,
  ExcludedIdsFilterRequestApplyModel,
  FilterRequestApplyModel,
  PaginatedResponseOfVesselResponse,
  VesselResponse,
  VesselResponseMasterItemType,
  VesselStateTypeId
} from '../../../core/services/Manufacturing';
import {Subscription} from 'rxjs';
import {SpinnerService} from '../../../core/services/spinner.service';
import { EquipmentOverlayProps, EquipmentService } from '../../../manufacturing/equipment/equipment.service';
import {VesselLookupParams, VesselSortOptions} from './equipment-library.helpers';
import {debounce} from 'lodash';
import {Dictionary} from 'app/shared/utils';
import {ByCategoryPipe} from './category.pipe';
import {AvailableEquipmentResponse} from '../../../manufacturing/batches/shared/batches.interfaces';
import {SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-equipment-library',
  templateUrl: './equipment-library.component.html',
  styleUrls: ['./equipment-library.component.scss']
})
export class EquipmentLibraryComponent implements OnInit, OnDestroy {

  @Input() models: EquipmentModelResponse[];
  @Input() equipments: EquipmentModelResponse[] | AvailableEquipmentResponse[];
  @Input() isOpen = false;
  @Input() showSortBar = true;
  @Input() set forcedActiveTab(value: EquipmentModelTypeId) {
    this._forcedActiveTab = value;
    this.setTab(value);
  }
  @Input() selectedEquipment: EquipmentModelResponse | AvailableEquipmentResponse;
  @Input() disableActiveVessels: boolean;
  @Input() disableWipVessels: boolean;

  @Output() selectEquipment = new EventEmitter<VesselResponse | AvailableEquipmentResponse>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly vesselSortOptions = VesselSortOptions;
  readonly vesselStateTypeId = VesselStateTypeId;
  readonly masterItemType = VesselResponseMasterItemType;

  activeTab: EquipmentModelTypeId = EquipmentModelTypeId.MashTun;
  searchTerm: string;
  readonly availableCategories = VesselLibraryService.availableCategories;
  equipmentDetailedList: PaginatedResponseOfVesselResponse = new PaginatedResponseOfVesselResponse();
  filter: FilterRequestApplyModel[] = [];

  vesselLookupParams: VesselLookupParams;
  sortOptions: string[] = [];
  chosenSortOption = this.vesselSortOptions.Name;
  chosenVesselOption: VesselStateTypeId = VesselStateTypeId.Empty;
  sortMenuOpen = false;
  emptyVessels: boolean;
  activeVessels: boolean;

  imagesLoaded = false;
  imageLib: Dictionary<Dictionary<{url: SafeUrl}> > = {};

  get getEquipment() {
    return this.models ? this.byCategoryPipe.transform(this.models, this.activeTab) : this.equipmentDetailedList.list;
  }

  setSearchQuery = debounce((query) => {
    this.setQuery(query);
  }, 200);

  private subscription = new Subscription();
  private _forcedActiveTab: EquipmentModelTypeId;

  @HostBinding('style.width') private width = '100%';
  constructor(
    private vesselLibraryService: VesselLibraryService,
    private equipmentService: EquipmentService,
    private byCategoryPipe: ByCategoryPipe,
    public spinnerService: SpinnerService) {

    this.vesselLookupParams = {
      filterRequest: new ExcludedIdsFilterRequestApplyModel({
        externalIdsToExclude: [],
        filters: [],
      }),
      equipmentModelTypeId: this.activeTab,
      facilityExternalId: '',
      vesselStateTypeId: VesselStateTypeId.Empty,
      batchExternalId: undefined,
      processDate: undefined,
      x_query: this.searchTerm,
      x_order: 'equipmentName',
      x_desc: false,
      x_pageNumber: undefined,
      x_pageSize: undefined
    };

    this.subscription.add(this.equipmentService.getLookupEquipment()
      .subscribe(res => {
        this.equipmentDetailedList = res;
        if (this.equipmentDetailedList && !this.models) {
          this.fetchImages();
        }
      }));
  }

  ngOnInit() {
    Object.keys(this.vesselSortOptions).forEach(item => {
      this.sortOptions.push(item);
    });
    this.equipmentService.resetLookupParams();
    this.subscription.add(this.equipmentService.getVesselLookupParams()
      .subscribe((data: VesselLookupParams) => this.vesselLookupParams = data));
    this.subscription.add(this.vesselLibraryService.getActiveTab()
      .subscribe((activeTab: EquipmentModelTypeId | null) => {
        if (activeTab && !this._forcedActiveTab) {
          this.setTab(activeTab);
        }
      }));
    if (this.models) {
      this.fetchImages();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setTab(tab?: EquipmentModelTypeId | null): boolean {
    this.activeTab = tab;
    if (!this.models) {
      this.vesselLookupParams.equipmentModelTypeId = tab;
      this.equipmentService.setLookupParams(this.vesselLookupParams);
    }
    this.vesselLibraryService.saveActiveTab(tab);
    return false;
  }

  toggleSortMenu(action: boolean): void {
    this.sortMenuOpen = !action;
  }

  setSortOption(option: VesselSortOptions): void {
    this.chosenSortOption = option;
    switch (option) {
      case this.vesselSortOptions.Name:
        this.vesselLookupParams.x_order = 'equipmentName';
        this.vesselLookupParams.x_desc = false;
        break;
      case this.vesselSortOptions.LowestCapacity:
        this.vesselLookupParams.x_order = 'maxVolume';
        this.vesselLookupParams.x_desc = false;
        break;
      case this.vesselSortOptions.HighestCapacity:
        this.vesselLookupParams.x_order = 'maxVolume';
        this.vesselLookupParams.x_desc = true;
        break;
      default:
        this.vesselLookupParams.x_order = 'equipmentName';
        this.vesselLookupParams.x_desc = false;
        break;
    }
    this.equipmentService.setLookupParams(this.vesselLookupParams);
    this.sortMenuOpen = false;
  }

  setVesselOption(option: VesselStateTypeId): void {
    this.chosenVesselOption = option;
    this.vesselLookupParams.vesselStateTypeId = this.chosenVesselOption;
    this.equipmentService.setLookupParams(this.vesselLookupParams);
  }

  clearSearchInput() {
    this.searchTerm = null;
  }

  setQuery(query: string) {
    this.vesselLookupParams.x_query = query;
    this.equipmentService.setLookupParams(this.vesselLookupParams);
  }

  closeEquipmentLibrary() {
    this.equipmentService.equipmentOverlayProps.next(
      <EquipmentOverlayProps>{
        isVesselsLibraryOpen: false,
        disableActiveVessels: this.disableActiveVessels,
        disableWipVessels: this.disableWipVessels,
      });
    this.closed.emit();
  }

  assignEquipment(equipment: EquipmentModelResponse) {
    this.selectedEquipment = equipment;
  }

  submitSelectedEquipment() {
    this.vesselLookupParams.filterRequest.externalIdsToExclude.push(this.selectedEquipment.externalId);
    this.equipmentService.setLookupParams(this.vesselLookupParams);
    this.equipmentService.selectedEquipment.next(this.selectedEquipment);
    this.closeEquipmentLibrary();
  }

  isEquipmentDisabled(equipment: VesselResponse): boolean {
    return (this.disableActiveVessels && !!equipment.branchExternalId)
      || (this.disableWipVessels && equipment.volume && equipment.volume.value > 0)
      || equipment.isDisabled;
  }

  private fetchImages() {
    this.imagesLoaded = false;
    if (this.models) {
      for (const item of this.models) {
        this.fetchModelImage(item.equipmentModelImageExternalId, '0');
      }
    } else {
      for (const item of this.equipmentDetailedList.list) {
        const volume: number = item.volume && item.capacity
          ? +this.calcVolume(item.volume.value, item.capacity.value)
          : 0;

        this.fetchModelImage(item.equipmentModel.equipmentModelImageExternalId, `${volume}`);
      }
    }

    this.imagesLoaded = true;
  }

  private calcVolume(volume: number, capacity: number): string {
    const fill = VesselLibraryService.calcVesselFill(volume, capacity);
    return Math.floor(fill).toFixed(0);
  }

  private ensureModelDictionaryExists(modelImageExternalId: string) {
    if (!this.imageLib[modelImageExternalId]) {
      this.imageLib[modelImageExternalId] = {};
    }
  }

  private fetchModelImage(modelImageExternalId: string, volume: string) {
    this.ensureModelDictionaryExists(modelImageExternalId);

    if (this.imageLib[modelImageExternalId][volume]) {
      return;
    }

    this.vesselLibraryService.getImageUrl(modelImageExternalId, +volume)
      .subscribe((url: SafeResourceUrl | null) => {
        if (!this.imageLib[modelImageExternalId][volume]) {
          this.imageLib[modelImageExternalId][volume] = {
            url: url
          };
        }
      });
  }
}
