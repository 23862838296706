import { AllowSaveService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services/allow-save.service";
export class NotSavedGuard {
    constructor(allowSaveService) {
        this.allowSaveService = allowSaveService;
    }
    canDeactivate(component, currentRoute, currentState, nextState) {
        if (this.allowSaveService.isEnabledFeature) {
            return component.canDeactivate ? component.canDeactivate(nextState) : true;
        }
        return true;
    }
}
NotSavedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotSavedGuard_Factory() { return new NotSavedGuard(i0.ɵɵinject(i1.AllowSaveService)); }, token: NotSavedGuard, providedIn: "root" });
