<app-header [title]="'General Dashboard'"
            *ngIf="authService.isAdmin.value || (authService.currentUser.value && authService.currentSettings.value)"></app-header>
<div class="page">
  <app-sidebar></app-sidebar>
  <main role="main" class="page__content">
    <div class="welcome-page">
      <div class="text">
        <h1>Dashboard coming soon!</h1>
        <p>
          You will be the first to know when we roll out dashboard that you can customize with great variety of
          widgets.<br/>
          Stay tuned for updates!
        </p>
      </div>

      <div class="grid">
        <img src="assets/images/dashboard-placeholder-01.svg" class="grid--cell-01"/>
        <img src="assets/images/dashboard-placeholder-02.svg" class="grid--cell-02"/>
        <img src="assets/images/dashboard-placeholder-03.svg" class="grid--cell-03"/>
        <img src="assets/images/dashboard-placeholder-04.svg" class="grid--cell-04"/>
        <img src="assets/images/dashboard-placeholder-05.svg" class="grid--cell-05"/>
        <div class="grid--overlay"></div>
      </div>
    </div>
  </main>
</div>
