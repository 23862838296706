import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import IMask from 'imask';
import { OwlDateTimeComponent } from 'ng-pick-datetime';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { Moment } from 'moment';

import { ClickOutsideData } from './directives';
import { emailRegex } from '@onbatch/shared/constants';
import { FileType } from '@onbatch/core/services/Account';
import { TimerInputValue } from '.';
import {
  SemiFinishedGoodRepackagingCalculatedResponseModel,
  WarehouseContentFinishedGoodListResponseModel,
  WarehouseLookupResponse
} from '@onbatch/core/services/Warehousing';
import { UnitIdsData } from 'app/warehousing/warehouses/view-item/repackage/repackage-remnant-extra-case/repackage-remnant-extra-case.component';
import { LowerCasePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { AuthService } from '@onbatch/core/services/auth.service';
import * as timezone from 'moment-timezone';

export function pad(arg: any, size: number = 2) {
  let s = String(arg);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
}

export interface Dictionary<T> {
  [Key: string]: T;
}

export function capitalize(s: string): string {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function replaceAll(text: string, search: string, replace: string): string {
  return text.split(search).join(replace);
}

export function countChars(text: string, char: string): number {
  return text.split('').reduce((acc: 0, ch: string) => ch === char ? acc + 1 : acc, 0);
}

export function isDatepicker(element: HTMLElement): boolean {
  if (element.tagName === ('svg') || element.tagName === ('path')) {
    return true;
  }
  if (element.classList && element.className.includes) {
    return element.className.includes('owl');
  } else {
    return false;
  }
}

export function closeCalendar(event: ClickOutsideData, calendar: OwlDateTimeComponent<any>): void {
  if (event.value && !checkIfDatePickerWasClicked(event)) {
    calendar.close();
  }
}

export function prepareIMaskSchema(schema: string): IMask.AnyMaskedOptions {
  let preparedSchema = schema;
  const nHashes: number = countChars(schema, '#');
  preparedSchema = replaceAll(preparedSchema, '#', nHashes === 1 ? '9' : '0');

  return {
    overwrite: true,
    autofix: true,
    lazy: false,
    mask: preparedSchema,
    blocks: {
      9: {
        mask: IMask.MaskedNumber,
        placeholderChar: '#',
      },
      0: {
        mask: IMask.MaskedNumber,
        from: 0,
        to: 9,
        maxlength: 1,
        placeholderChar: '#',
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        placeholderChar: 'M',
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        placeholderChar: 'D',
      },
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
        placeholderChar: 'Y',
      },
      YY: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
        placeholderChar: 'Y',
      },
    }
  };
}

export function prepareRegexpForSchema(schema: string): string {
  // Schema as regexp
  let schemaRegex = schema;
  schemaRegex = replaceAll(schemaRegex, '#', '([0-9]+)');
  schemaRegex = replaceAll(schemaRegex, 'DD', '(0[1-9]|[12]\\d|3[01])');
  schemaRegex = replaceAll(schemaRegex, 'MM', '(0[1-9]|1[0-2])');
  schemaRegex = replaceAll(schemaRegex, 'YYYY', '([12]\\d{3})');
  schemaRegex = replaceAll(schemaRegex, 'YY', '(\\d{2})');
  return schemaRegex;
}

export enum ModalPosition {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export function calculateModalPosition(event: MouseEvent, modalHeight: number): ModalPosition {
  const spaceBelowButton = window.innerHeight - event.clientY;
  if (spaceBelowButton > modalHeight) {
    return ModalPosition.BELOW;
  } else {
    return ModalPosition.ABOVE;
  }
}


export function prependZeros(num: number): string {
  const str = ('' + num);
  return (Array(Math.max(7 - str.length, 0)).join('0') + str);
}

export const groupBy = (key: string) => <T>(array: Array<T>): Record<string, T[]> => array.reduce((objectsByKeyValue: Record<string, T[]>, obj: T) => {
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

// Email validator for when you keep array of emails inside of a single control
export const emailsValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  // I'd really love to use Validators.email embedded in Angular,
  // but given it's signature (`(control: AbstractControl): ValidationErrors | null`) - it doesn't compose well.
  const validateEmail = (email: string): ValidationErrors | null => emailRegex.test(email) ? null : { 'emailInvalid': true };

  const values: string[] = control.value;
  const errors: ValidationErrors = values.reduce((acc: ValidationErrors, value: string) => ({
    ...acc,
    ...validateEmail(value)
  }), {});
  return Object.keys(errors).length ? errors : null;
};

export function prepareFileName(name: string, fileType: FileType): string {
  return `${name}-${new Date().toISOString().slice(0, 10)}.${fileType}`;
}

export function truthy<T>(): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(filter(x => !!x));
}

export function falsy<T>(): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(filter(x => !x));
}

export function formatTimer(timer: TimerInputValue): string {
  const hours = pad(timer.hours);
  const minutes = pad(timer.minutes);
  return `${hours}:${minutes}`;
}

export function timerInputToDate(time: TimerInputValue): Date {
  return moment(formatTimer(time), 'HH:mm').toDate();
}


export interface DateTime {
  date: Date;
  time: string;
}

export function getDateTime(date?: number): DateTime {
  const initialDate: Moment = date ? moment.unix(date) : moment.unix(moment().unix());
  return <DateTime>{
    date: initialDate.toDate(),
    time: initialDate.format('HH:mm')
  };
}

export function prepareDate(dateValue: number, timeValue: string): number {
  const day = moment(dateValue, 'YYYY-MM-DD');
  const time = moment(timeValue, 'HH:mm');
  return moment(day.format('YYYY-MM-DD') + ' ' + time.format('HH:mm'), 'YYYY-MM-DD HH:mm').unix();
}

export function calculateTimeZoneOffset(timezoneName: string): number {
  if (!timezoneName) {
    return 0;
  }
  let localTimeZoneOffset = new Date().getTimezoneOffset();
  const year = +timezone.utc().format('YYYY');
  const month = +timezone.utc().format('MM');
  const day = +timezone.utc().format('DD');
  let timeZoneFromSettingsOffset = timezone.tz.zone(timezoneName).parse(Date.UTC(year, month, day));

  if (localTimeZoneOffset > 0 && timeZoneFromSettingsOffset > 0) {
    localTimeZoneOffset = -localTimeZoneOffset;
  } else if (localTimeZoneOffset < 0 && timeZoneFromSettingsOffset < 0) {
    timeZoneFromSettingsOffset = -timeZoneFromSettingsOffset;
    return -(localTimeZoneOffset + timeZoneFromSettingsOffset) * 60;
  } else {
    localTimeZoneOffset = Math.abs(localTimeZoneOffset);
    timeZoneFromSettingsOffset = Math.abs(timeZoneFromSettingsOffset);
  }
  return (localTimeZoneOffset + timeZoneFromSettingsOffset) * 60;
}

export function calculateTimeZoneUTCOffset(timezoneName: string): number {
  if (!timezoneName) {
    return 0;
  }

  const year = +timezone.utc().format('YYYY');
  const month = +timezone.utc().format('MM');
  const day = +timezone.utc().format('DD');
  return timezone.tz.zone(timezoneName).parse(Date.UTC(year, month, day)) * 60;
}

export function numberToString(num: number): string {
  return num.toLocaleString('en-US', {
    useGrouping: false,
    maximumSignificantDigits: 21,
  });
}

export function isCompanyFromUSA(): boolean {
  // This magic id is hardcoded on BE and cannot be changed
  const externalIdForUSA = '07879451-3d84-4c80-a57a-891141771f15';
  return localStorage.getItem('companiesCountry') === externalIdForUSA;
}

// Only impersonated and companies with ReportingAvailable set from admin
// will be able to see the reports side bar link.
export function hideReportsOnProduction(): boolean {

  // enable only for Admins
  try {
    return AuthService.getUser().profile.Role !== 'OnBatchAdmin';
  } catch (error) {
    console.log(error);
    return true;
  }
  /*if(AuthService.isReportingAvailable || AuthService.isImpersonate){
    return false;
  }
 
  return true;*/
}

export function checkIfClickedElementHasClass(event: ClickOutsideData, classesToCheck: string[], target?: HTMLElement): boolean {
  let hasClass = false;
  const targetElement: HTMLElement = event ? (event.target ? (event.target as HTMLElement) : null) : target;
  if (event && !event.target
    || !targetElement.className
    || !targetElement.className.includes) {
    hasClass = false;
  } else {
    classesToCheck.forEach(classToCheck => {
      if (targetElement.className.includes(classToCheck)) {
        hasClass = true;
      }
    });
  }
  return hasClass;
}

export function checkIfClickedParentElementHasClass(element: HTMLElement, classToCheck: string): boolean {
  if (!element || (element && element.classList && !element.classList.length && !element.parentElement)) {
    return false;
  }
  while (element !== null) {
    if (element.className && element.className.length && element.className.includes(classToCheck)) {
      return true;
    }
    element = element.parentElement;
  }

  return false;
}

export function checkIfDatePickerWasClicked(event: ClickOutsideData): boolean {
  if (!event || !event.target) {
    return false;
  }
  const target = event.target as HTMLElement;
  const isCalendar = checkIfClickedElementHasClass(event, ['owl']);
  const elementHasClassLoadingWrapper = checkIfClickedElementHasClass(event, ['loading-wrapper']);
  let parentElementHasClassOwl = false;
  if (!isCalendar) {
    parentElementHasClassOwl = checkIfClickedParentElementHasClass(target, 'owl');
  }

  return isCalendar || parentElementHasClassOwl || elementHasClassLoadingWrapper;
}


export function checkIfOverlayWasClicked(event: ClickOutsideData): boolean {
  if (!event || !event.target) {
    return false;
  }

  return checkIfClickedElementHasClass(event, ['loading-wrapper', 'spinner']);
}

export function recursionRegions(regions: WarehouseLookupResponse[], regionsShortNames: string[], result: string[]): string[] {
  if (regionsShortNames.length === 0) {
    return result;
  } else {
    const regionEl = regions.find((region: WarehouseLookupResponse) => region.acronymName === regionsShortNames[0]);
    result.push(regionEl.externalId);
    regionsShortNames.splice(0, 1);
    return recursionRegions(regionEl.regions, regionsShortNames, result);
  }
}

export function getWarehouseExternalId(regionsShortNames: string, locations: WarehouseLookupResponse[]): string {
  return locations.find((item: WarehouseLookupResponse) => item.acronymName === regionsShortNames.split('/')[0]).externalId;
}

export function getAllRegions(regionsShortNames: string, locations: WarehouseLookupResponse[]): string[] {
  if (!regionsShortNames) {
    return [];
  }
  const location: WarehouseLookupResponse = locations.find((item: WarehouseLookupResponse) => item.acronymName === regionsShortNames.split('/')[0]);
  if (!location.regions) {
    return [];
  }
  const regionsArray = regionsShortNames.split('/');
  regionsArray.shift();
  return recursionRegions(location.regions, regionsArray, []);
}

export function getWarehouseAndRegion(unitId: string, unitIdsDataInit: UnitIdsData[], regionExternalIds: string[][]): string[] {
  const index = unitIdsDataInit.findIndex((item: UnitIdsData) => item.unitId === unitId);
  if (!regionExternalIds[index]) {
    return [unitIdsDataInit[index].warehouseExternalId];
  }
  return [unitIdsDataInit[index].warehouseExternalId, ...regionExternalIds[index]];
}

export function getUnitIds(items: (WarehouseContentFinishedGoodListResponseModel | SemiFinishedGoodRepackagingCalculatedResponseModel)[]): UnitIdsData[] {
  return items.map((item: WarehouseContentFinishedGoodListResponseModel) => {
    return {
      unitId: item.unitId,
      warehouseExternalId: item.warehouseExternalId
    };
  });
}

export function getAlcoholContentValidators(uomName: string): ValidatorFn[] {
  return [Validators.min(0.000001),
  Validators.max(uomName === 'Proof' ? 200 : 100)];
}
export function getCardIcon(card: string): string | boolean {
  if (card) {
    card = card.replace(' ', '');
    card = new LowerCasePipe().transform(card);
    if (card === 'mastercard'
      || card === 'visa'
      || card === 'discover'
      || card === 'americanexpress') {
      return `assets/images/${card}.png`;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function hasDotAsLast(val: string) {
  return val.match(/(^\d+\.$)|(^\.$)/);
}
