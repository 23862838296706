import {
  EnumFilter,
  FilterRequestApplyModel, QuantityFilter,
  ValueFilter,
  WarehouseContentListRequestModel
} from '../core/services/Warehousing';
import { UtilityBarOptionsModel } from '@onbatch/shared/models';

export enum ActiveBreadcrumb {
    Regions = 'All regions',
    Floors = 'All floors',
    Warehouses = 'All warehouses'
}

export enum WarehouseContentListHeaders {
    RawMaterial = 'Raw Material',
    PackagingMaterials = 'Packaging Materials',
    Containers = 'Containers',
    SemiFinishedGoods = 'Semi-finished goods',
    FinishedGoods = 'Finished goods'
}

export enum RegionExternalId {
    EmptyId = ''
}

export enum WarehouseGroupByTypes {
  Name = 'name',
  LotId = 'lotId'
}

export enum KeyCodes {
  Enter = 'Enter',
  Esc = 'Escape',
  Backspace = 'Backspace'
}

export const ExampleWarehouseContentListRequestModel = new WarehouseContentListRequestModel({
    warehouseExternalId: null,
    regionExternalId: null,
    filter: [],
    showUnassigned: false
});

export const warehouseActionBarOptions: UtilityBarOptionsModel[] = [
    {
      label: 'Move',
      icon: 'transfer-arrows',
      action: 'transfer'
    },
    {
      label: 'Download as',
      icon: 'import',
      action: 'toggle-menu',
      dropdown: true,
      dropdownOptions: [
        // {
        //   label: 'PDF Document (.pdf)',
        //   slug: 'pdf'
        // },
        {
          label: 'Microsoft Excel (.xlsx)',
          slug: 'xlsx'
        },
        {
          label: 'Comma Separated Values (.csv)',
          slug: 'csv'
        }
      ]
    },
];

export const generateLabelsOption: UtilityBarOptionsModel = {
  label: 'Generate labels',
  icon: 'bar-code',
  action: 'generate',
};

export const repackagingOption: UtilityBarOptionsModel = {
  label: 'Repackage',
  icon: 'two-curly-arrows',
  action: 'repackage',
};

export const unselectAllOption: UtilityBarOptionsModel = {
  label: 'Unselect all',
  icon: 'reset',
  action: 'unselectAll',
};

export function prepareFiltersForWarehouse(activeFilters: FilterRequestApplyModel[]): FilterRequestApplyModel[] {
  const filter: FilterRequestApplyModel[] = [];
  if (activeFilters.length) {
    activeFilters.forEach((activeFilter: FilterRequestApplyModel, index: number) => {
      filter.push(new FilterRequestApplyModel({
        enumFilters: [],
        quantityFilters: [],
        valueFilters: []
      }));
      activeFilter.valueFilters.forEach((valueFilter: ValueFilter) => {
        filter[index].valueFilters.push(new ValueFilter({
          condition: valueFilter.condition,
          fieldName: valueFilter.fieldName,
          value: valueFilter.value,
          label: valueFilter.label,
          orderIndex: valueFilter.orderIndex
        }));
      });
      activeFilter.enumFilters.forEach((enumFilter: EnumFilter) => {
        filter[index].enumFilters.push(new EnumFilter({
          condition: enumFilter.condition,
          fieldName: enumFilter.fieldName,
          value: enumFilter.value,
          label: enumFilter.label,
          orderIndex: enumFilter.orderIndex
        }));
      });
      activeFilter.quantityFilters.forEach((quantityFilter: QuantityFilter) => {
        filter[index].quantityFilters.push(new QuantityFilter({
          condition: quantityFilter.condition,
          fieldName: quantityFilter.fieldName,
          value: quantityFilter.value,
          label: quantityFilter.label,
          isDate: quantityFilter.isDate,
          orderIndex: quantityFilter.orderIndex
        }));
      });
    });
  }
  return filter;
}
