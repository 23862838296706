import {EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  SalesOrderItemResponseModel,
  SalesOrderItemTransactionResponseModel,
  SalesOrderShippedItemRequestModelShipmentItemSelectionCriteriaId as ShippedCriteriaId,
  SalesOrderShippedItemUpdateRequestModelShipmentItemSelectionCriteriaId,
  SellableMasterItemResponseModelMasterItemTypeId,
  ShipmenLotIdsResponseModel,
  ShipmentLotIdRequestModel,
  ShippedItemRequestModel
} from '../../../../core/services/Sales';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {Shipping} from './shipment-type.enum';
import {FormBoxes} from '@onbatch/shared/models';
import {Observable, Subject} from 'rxjs';
import {takeUntil, take} from 'rxjs/operators';
import {ShippingService} from '../../../services/shipping.service';
import { SalesResponseModel } from '@onbatch/core/services/Account';
import { SettingsService } from 'app/account/settings/settings.service';

export abstract class BaseShipForm implements OnInit, OnDestroy {

  @Input() item: SalesOrderItemResponseModel;
  @Input() form: FormGroup;
  @Input() formSubmitStatus;
  @Input() editMode = false;
  @Input() shippingDetails: SalesOrderItemTransactionResponseModel;
  @Input() shipmentExternalId: string;
  @Output() overlayTriggered = new EventEmitter<boolean>();

  @Output() shippingChanged = new EventEmitter<Shipping>();

  salesApproval = false;
  shippedQuantity: { quantity: number, isValid: boolean }[] = [];
  validShipping: ShippedItemRequestModel[] = [];
  formBoxes: FormBoxes;
  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly ShipmentType = SalesOrderShippedItemUpdateRequestModelShipmentItemSelectionCriteriaId;
  readonly masterItemType = SellableMasterItemResponseModelMasterItemTypeId;

  protected constructor(protected shippingService: ShippingService, protected settingsService: SettingsService,) {
  }

  abstract initFormBoxes();

  abstract initEditForm();

  ngOnInit(): void {
    this.initFormBoxes();
    if (this.editMode) {
      this.initEditForm();
    }
    this.settingsService.getSalesSettings().pipe(take(1)).subscribe((data: SalesResponseModel) => {
      if (data) {
        this.salesApproval = data.salesApproval;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  removeFormArrayItem(idx: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
  }

  emitCurrentShipping(criteriaId: ShippedCriteriaId): void {
    this.shippingChanged.emit(<Shipping>{
      shipmentItemSelectionCriteriaId: criteriaId,
      shipmentItems: this.validShipping.filter((el: ShippedItemRequestModel) => el),
      isValid: !!this.validShipping.length && !this.validShipping.some(el => !el),
      remainingQuantity: this.getRemainingQuantity,
    });
  }

  lotIdChanged(idx: number): void {
    const form = this.lotIds.controls[idx];
    const lotId = form.get('lotId');
    const quantity = form.get('shippedLotQuantity');

    this.shippingService.validateLotId(new ShipmentLotIdRequestModel({
      lotId: lotId.value,
      masterItemExternalId: this.item.masterItem.externalId
    })).subscribe((data: ShipmenLotIdsResponseModel) => {
      this.shippedQuantity[idx].quantity = data.quantity;
      this.shippedQuantity[idx].isValid = data.lotIdValid;
      this.formSubmitStatus = true;
      quantity.setErrors(
        quantity.value
        && quantity.value <= this.getRemainingQuantity
        && quantity.value <= this.shippedQuantity[idx].quantity
          ? null
          : { 'incorrect': true });
      lotId.setErrors(data.lotIdValid ? null : { 'incorrect': true });
      if (this.shippedQuantity[idx].isValid && !quantity.errors) {
        this.validShipping[idx] = new ShippedItemRequestModel({
          lotId: lotId.value,
          quantity: Number(quantity.value),
        });
      } else {
        this.validShipping[idx] = null;
      }
      this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
    });
  }

  toggleListOverlay() {
    this.overlayTriggered.emit(true);
  }

  get getRemainingQuantity(): number {
    const items: SalesOrderItemTransactionResponseModel[] =
      this.salesApproval
        ? this.item.pickedItems
        : this.item.shippedItems;
    let quantities: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel[] = items
      .filter((el: SalesOrderItemTransactionResponseModel) => (el.shipmentExternalId !== this.shipmentExternalId && el.transactionExternalId !== this.shipmentExternalId) || this.shipmentExternalId === null);
    if (this.validShipping) {
      quantities = [...quantities, ...this.validShipping.filter((el: ShippedItemRequestModel) => el)];
    }
    return quantities.length ? quantities
      .reduce((quantity: number, item: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel) => {
        return quantity - (item.quantity ? item.quantity : (item as ShippedItemRequestModel).volume);
      }, this.item.quantity) : this.item.quantity;
  }

  get shipmentType(): AbstractControl {
    if (this.item.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood && this.item.masterItem.numberOfUnits) {
      return this.form.get(`shipmentType-${this.item.externalId + this.item.numberOfUnits}`);
    } else {
      return this.form.get(`shipmentType-${this.item.externalId}`);
    }
  }

  get containerIds(): FormArray {
    return this.form.get('containerIds') as FormArray;
  }

  get lotIds(): FormArray {
    return this.form.get('lotIds') as FormArray;
  }

  get unitIds(): FormArray {
    return this.form.get('unitIds') as FormArray;
  }

  get equipment(): FormArray {
    return this.form.get('equipment') as FormArray;
  }

  get shipmentTypeValueChanges$(): Observable<string> {
    return this.shipmentType.valueChanges.pipe(
      takeUntil(this.destroy$)
    );
  }
}
