import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html'
})
export class WarningModalComponent {
  @Output() buttonPressed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  onButtonPress(value: boolean) {
    this.buttonPressed.emit(value);
  }
}
