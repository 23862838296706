import { AccountClient } from '@onbatch/core/services/Account';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@onbatch/core/services/Env';
import { AuthService } from '@onbatch/core/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./Env";
export class SubscriptionService {
    constructor(http, envService) {
        this.http = http;
        this.envService = envService;
        this.currentSubscription = new BehaviorSubject(null);
        this.accountClient = new AccountClient(this.http, envService.apiUrl);
        this.initSubscription();
    }
    initSubscription() {
        this.currentSubscription.next(JSON.parse(localStorage.getItem('currentSubscription')));
    }
    setSubscription() {
        this.accountClient.paymentSubscriptionCurrentGet().subscribe((subscription) => {
            this.currentSubscription.next(subscription);
            localStorage.setItem('currentSubscription', JSON.stringify(subscription));
        });
    }
    getSubscription() {
        if (!this.currentSubscription.value) {
            this.setSubscription();
        }
    }
    getAccess(feature) {
        const user = AuthService.getUser();
        if (user !== null && user.profile !== null && user.profile.Role === 'OnBatchAdmin') {
            return true;
        }
        if (!feature) {
            return true;
        }
        let shouldBeVisible = false;
        const featuresInSubscription = this.currentSubscription.value.features;
        if (featuresInSubscription) {
            shouldBeVisible = featuresInSubscription[feature];
        }
        return shouldBeVisible;
    }
    checkLimit(limitedFeature, currentValue) {
        switch (limitedFeature) {
            case Feature.Companies:
                if (!this.currentSubscription.value.unlimitedCompanies) {
                    return currentValue < this.currentSubscription.value.maxNumberOfCompanies;
                }
                else {
                    return true;
                }
            case Feature.Facilities:
                if (!this.currentSubscription.value.unlimitedFacilitiesWarehouses) {
                    return currentValue < this.currentSubscription.value.maxNumberOfFacilitiesWarehouses;
                }
                else {
                    return true;
                }
            case Feature.Users:
                if (!this.currentSubscription.value.unlimitedUsers) {
                    return currentValue < this.currentSubscription.value.maxNumberOfUsers;
                }
                else {
                    return true;
                }
        }
    }
}
SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvService)); }, token: SubscriptionService, providedIn: "root" });
export var Feature;
(function (Feature) {
    Feature[Feature["Companies"] = 0] = "Companies";
    Feature[Feature["Facilities"] = 1] = "Facilities";
    Feature[Feature["Users"] = 2] = "Users";
})(Feature || (Feature = {}));
export var SubscriptionFeatures;
(function (SubscriptionFeatures) {
    SubscriptionFeatures["InventoryMasterItems"] = "inventory_MasterItems";
    SubscriptionFeatures["InventoryCriticalStockAlert"] = "inventory_CriticalStockAlert";
    SubscriptionFeatures["InventoryStockCount"] = "inventory_StockCount";
    SubscriptionFeatures["InventoryTransferItems"] = "inventory_TransferItems";
    SubscriptionFeatures["InventorySalesPurchaseHistory"] = "inventory_SalesPurchaseHistory";
    SubscriptionFeatures["InventoryTieredVolumePricing"] = "inventory_TieredVolumePricing";
    SubscriptionFeatures["InventoryLotTraceability"] = "inventory_LotTraceability";
    SubscriptionFeatures["InventoryReports"] = "inventory_Reports";
    SubscriptionFeatures["InventoryDashboard"] = "inventory_Dashboard";
    SubscriptionFeatures["PurchasingPurchaseOrders"] = "purchasing_PurchaseOrders";
    SubscriptionFeatures["PurchasingDraftVoidOrders"] = "purchasing_DraftVoidOrders";
    SubscriptionFeatures["PurchasingPartialFullReceive"] = "purchasing_PartialFullReceive";
    SubscriptionFeatures["PurchasingEmailOrders"] = "purchasing_EmailOrders";
    SubscriptionFeatures["PurchasingReturns"] = "purchasing_Returns";
    SubscriptionFeatures["PurchasingBackOrders"] = "purchasing_BackOrders";
    SubscriptionFeatures["PurchasingCreateBills"] = "purchasing_CreateBills";
    SubscriptionFeatures["PurchasingRefunds"] = "purchasing_Refunds";
    SubscriptionFeatures["PurchasingPurchaseRequisitions"] = "purchasing_PurchaseRequisitions";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["PurchasingVendorPerformance"] = "purchasing_VendorPerformance";
    SubscriptionFeatures["PurchasingAutoGeneratedOrders"] = "purchasing_AutoGeneratedOrders";
    SubscriptionFeatures["WarehousingTransferInBond"] = "warehousing_TransferInBond";
    // No changes required on FE side
    SubscriptionFeatures["WarehousingBarrelManagement"] = "warehousing_BarrelManagement";
    SubscriptionFeatures["WarehousingContainerLabeling"] = "warehousing_ContainerLabeling";
    SubscriptionFeatures["WarehousingFloorLayoutDesign"] = "warehousing_FloorLayoutDesign";
    SubscriptionFeatures["WarehousingMovementTracking"] = "warehousing_MovementTracking";
    SubscriptionFeatures["WarehousingMaterialGoodsCosting"] = "warehousing_MaterialGoodsCosting";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["WarehousingLotRating"] = "warehousing_LotRating";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["WarehousingTastingNotes"] = "warehousing_TastingNotes";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["WarehousingQRCodeScanning"] = "warehousing_QRCodeScanning";
    SubscriptionFeatures["WarehousingOrderPicking"] = "warehousing_OrderPicking";
    SubscriptionFeatures["Warehousing3rdPartyWarehousing"] = "warehousing_3rdPartyWarehousing";
    SubscriptionFeatures["WarehousingRepackaging"] = "warehousing_Repackaging";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["WarehousingPalletizing"] = "warehousing_Palletizing";
    SubscriptionFeatures["ManufacturingEquipmentManagement"] = "manufacturing_EquipmentManagement";
    SubscriptionFeatures["ManufacturingFederalGovernmentReports"] = "manufacturing_FederalGovernmentReports";
    // As long as Manual Batch is available for each subscription tier, no changes are required on FE side
    SubscriptionFeatures["ManufacturingManualBatch"] = "manufacturing_ManualBatch";
    // If this feature is unavailable backend shouldn't return SFG/FG Packaging stages from /manufacturing/Batch/{id}/getAvailableStages
    // No changes required on FE side
    SubscriptionFeatures["ManufacturingGoodsPackaging"] = "manufacturing_GoodsPackaging";
    SubscriptionFeatures["ManufacturingProductFlows"] = "manufacturing_ProductFlows";
    SubscriptionFeatures["ManufacturingProductFlowBatch"] = "manufacturing_ProductFlowBatch";
    SubscriptionFeatures["ManufacturingBatchCosting"] = "manufacturing_BatchCosting";
    SubscriptionFeatures["ManufacturingReports"] = "manufacturing_Reports";
    SubscriptionFeatures["ManufacturingBatchScheduling"] = "manufacturing_BatchScheduling";
    SubscriptionFeatures["ManufacturingGenerateLabels"] = "manufacturing_GenerateLabels";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ManufacturingPackagingLines"] = "manufacturing_PackagingLines";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ManufacturingQRCodeScanning"] = "manufacturing_QRCodeScanning";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ManufacturingWorkOrders"] = "manufacturing_WorkOrders";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ManufacturingProductionPlanning"] = "manufacturing_ProductionPlanning";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ManufacturingDemandForecasting"] = "manufacturing_DemandForecasting";
    SubscriptionFeatures["SalesSalesOrders"] = "sales_SalesOrders";
    SubscriptionFeatures["SalesDraftVoidOrders"] = "sales_DraftVoidOrders";
    // No changes required on FE side
    SubscriptionFeatures["SalesPartialFullProcessing"] = "sales_PartialFullProcessing";
    SubscriptionFeatures["SalesEmailOrders"] = "sales_EmailOrders";
    SubscriptionFeatures["SalesInvoicing"] = "sales_Invoicing";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SalesEmailInvoice"] = "sales_EmailInvoice";
    SubscriptionFeatures["SalesPayments"] = "sales_Payments";
    SubscriptionFeatures["SalesShippingDocuments"] = "sales_ShippingDocuments";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SalesBackOrders"] = "sales_BackOrders";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SalesReturnOrders"] = "sales_ReturnOrders";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SalesQRCodeScanning"] = "sales_QRCodeScanning";
    SubscriptionFeatures["SalesOrderPicking"] = "sales_OrderPicking";
    SubscriptionFeatures["SalesInvoiceConsolidation"] = "sales_InvoiceConsolidation";
    SubscriptionFeatures["ContactRelationshipCompanies"] = "contactRelationship_Companies";
    SubscriptionFeatures["ContactRelationshipContacts"] = "contactRelationship_Contacts";
    SubscriptionFeatures["ContactRelationshipPurchaseSalesHistory"] = "contactRelationship_PurchaseSalesHistory";
    SubscriptionFeatures["ContactRelationshipTieredVolumePricing"] = "contactRelationship_TieredVolumePricing";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ContactRelationshipTasks"] = "contactRelationship_Tasks";
    SubscriptionFeatures["HumanResourcesEmployeeManagement"] = "humanResources_EmployeeManagement";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ReportingAnalyticsDashboard"] = "reportingAnalytics_Dashboard";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ReportingAnalyticsTransactionReporting"] = "reportingAnalytics_TransactionReporting";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ReportingAnalyticsChartsGraphsKPIs"] = "reportingAnalytics_ChartsGraphsKPIs";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ReportingAnalyticsAdHocReporting"] = "reportingAnalytics_AdHocReporting";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["ReportingAnalyticsMultiBusinessUnit"] = "reportingAnalytics_MultiBusinessUnit";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["IntegrationsQuickBooksOnline"] = "integrations_QuickBooksOnline";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["IntegrationsXero"] = "integrations_Xero";
    SubscriptionFeatures["IntegrationsPaygov"] = "integrations_Paygov";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["IntegrationsQuickBooksDesktopServer"] = "integrations_QuickBooksDesktopServer";
    SubscriptionFeatures["MiscPersonalPreferences"] = "misc_PersonalPreferences";
    SubscriptionFeatures["MiscAccountSettings"] = "misc_AccountSettings";
    SubscriptionFeatures["MiscRolesPermissions"] = "misc_RolesPermissions";
    SubscriptionFeatures["MiscAdvancedFiltering"] = "misc_AdvancedFiltering";
    SubscriptionFeatures["MiscImportCSVExcel"] = "misc_ImportCSVExcel";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["MiscExportCSVExcel"] = "misc_ExportCSVExcel";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["MiscMobileApp"] = "misc_MobileApp";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SupportOnBatchAcademy"] = "support_OnBatchAcademy";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SupportEmail"] = "support_Email";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SupportPhone"] = "support_Phone";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SupportInAppChat"] = "support_InAppChat";
    // TODO: Add access checking based on subscription tier when feature will be available
    SubscriptionFeatures["SupportOnSite"] = "support_OnSite";
    SubscriptionFeatures["Reporting"] = "reporting";
})(SubscriptionFeatures || (SubscriptionFeatures = {}));
