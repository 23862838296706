import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

interface Error {
  internalCode: number;
  errorMessage: string;
  invalidProperties: null;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private toastr: ToastrService, public router: Router, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError(error => {
         if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.internalCode === 9100) {
            AuthService.startAuthentication();
            return;
          } else {
            this.blob2json(error.error).then(errorString => {
              const err = JSON.parse(errorString) as Error;
              if ([1000, 30000].includes(err.internalCode)) {
                this.toastr.error(err.errorMessage, 'Error');
              } else {
                this.toastr.error('An unexpected error has occurred.', 'Error');
              }
              return throwError(err);
            });
          }
        }
        if (error instanceof HttpErrorResponse && error.status === 452) {
          const observable = Observable.create((observer) => {
            const reader = new FileReader();
            let errorResponse: any;
            reader.onload = function () {
              errorResponse = JSON.parse(reader.result.toString());
              observer.error(errorResponse);
            };

            reader.readAsText(error.error);

          });

          return observable as Observable<never>;
        }
        if (error instanceof HttpErrorResponse && error.status === 422) {
          this.toastr.warning('Error', 'Unprocessable Entity.');
          return;
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.toastr.warning('Error', 'Unprocessable Entity.');
          return;
        }
        if (error instanceof HttpErrorResponse && error.status === 403) {
          this.toastr.warning('Forbidden', 'User does not have access rights for this.');
          return;
        }
        return throwError(error);
      }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': token
      }
    });
  }

  private addOrgID(request: HttpRequest<any>, orgid: string) {
    return request.clone({
      setHeaders: {
        'x-company': orgid
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, AuthService.getAuthorizationHeaderValue()));
        }));
    }
  }

  private blob2json(blob: Blob): Promise<string> {
    return (new Response(blob)).text();
  }
}
