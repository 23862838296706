import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-switch',
  templateUrl: './button-switch.component.html'
})
export class ButtonSwitchComponent {
  @Input() isLocked = false;
  @Input() label: string;
  @Input() state: boolean;
  @Input() isDefaultPosition = true;

  @Output() stateChanged = new EventEmitter<void>();
}
