import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))], { optional: true }),
    query(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))], { optional: true })
  ])
]);
export const fadeInOut = trigger('fadeInOut', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))], { optional: true }),
    query(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))], { optional: true })
  ])
]);
export const slideInUp = trigger('slideInUp', [
  transition('* => *', [
    query(':enter', style({ opacity: 0, transform: 'translateY(50%)' }), {
      optional: true
    }),
    query(
      ':enter',
      stagger('100ms', [
        animate(
          '.5s ease-in-out',
          keyframes([
            style({ opacity: 0.5, transform: 'translateY(25%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
          ])
        )
      ]),
      { optional: true }
    )
  ])
]);
export const slideToggle = trigger('slideToggle', [
  transition(':enter', [
    style({ transform: 'translateY(-10%)', opacity: 0 }),
    animate('200ms ease-in', style({ transform: 'translateY(0%)', opacity: 1 }))
  ]),
  transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-10%)', opacity: 0 }))])
]);
export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        opacity: '1',
        visibility: 'visible'
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden'
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px'
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ])
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '1000px'
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ])
];
