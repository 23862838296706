import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from '@onbatch/core/services/auth.service';
import { EnvService } from '@onbatch/core/services/Env';
import { EquipmentFullResponse, EquipmentModelTypeId, ExcludedIdsFilterRequestApplyModel, ManufacturingClient, VesselStateTypeId, } from '@onbatch/core/services/Manufacturing';
import { ObjectDeepCopyService } from '@onbatch/shared/services/object-deep-copy.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ManagementEquipmentDataTableDefaults, RetiredEquipmentDataTableDefaults } from './equipment.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/services/Env";
import * as i3 from "@ngx-pwa/local-storage";
import * as i4 from "../../shared/services/object-deep-copy.service";
import * as i5 from "../../core/services/auth.service";
export class EquipmentService {
    constructor(http, envService, localStorage, deepCopyService, authService) {
        this.localStorage = localStorage;
        this.deepCopyService = deepCopyService;
        this.authService = authService;
        this.equipmentOverlayProps = new Subject();
        this.selectedEquipment = new Subject();
        this.equipmentList$ = new BehaviorSubject(null);
        this.equipment$ = new BehaviorSubject(null);
        this.equipmentDuplicated$ = new BehaviorSubject(null);
        this.managementEquipmentDataTableParams$ = new BehaviorSubject(null);
        this.retiredEquipmentDataTableParams$ = new BehaviorSubject(null);
        this.managementEquipmentPagination$ = new BehaviorSubject(null);
        this.retiredEquipmentPagination$ = new BehaviorSubject(null);
        this.equipmentStatistics$ = new BehaviorSubject(null);
        this.equipmentList = this.equipmentList$.asObservable();
        this.equipment = this.equipment$.asObservable();
        this.equipmentDuplicated = this.equipmentDuplicated$.asObservable();
        this.managementEquipmentDataTableParams = this.managementEquipmentDataTableParams$.asObservable();
        this.retiredEquipmentDataTableParams = this.retiredEquipmentDataTableParams$.asObservable();
        this.managementEquipmentPagination = this.managementEquipmentPagination$.asObservable();
        this.retiredEquipmentPagination = this.retiredEquipmentPagination$.asObservable();
        this.equipmentStatistics = this.equipmentStatistics$.asObservable();
        this.defaultLookupParams = {
            equipmentModelTypeId: EquipmentModelTypeId.MashTun,
            filterRequest: new ExcludedIdsFilterRequestApplyModel({
                filters: [],
                externalIdsToExclude: [],
            }),
            facilityExternalId: '',
            vesselStateTypeId: VesselStateTypeId.Empty,
            batchExternalId: undefined,
            processDate: undefined,
            x_query: '',
            x_order: 'equipmentName',
            x_desc: false,
            x_pageNumber: null,
            x_pageSize: null,
        };
        this.vesselLookupParams$ = new BehaviorSubject(this.defaultLookupParams);
        this.vesselList$ = new BehaviorSubject(null);
        this.vesselLookupParams = this.vesselLookupParams$.asObservable();
        this.vesselList = this.vesselList$.asObservable();
        this.equipmentClient = new ManufacturingClient(http, envService.apiUrl);
        this.getInitialManagementEquipment();
        this.getInitialRetiredEquipment();
        this.getInitialVessel();
    }
    getInitialManagementEquipment() {
        this.localStorage
            .getItem(this.authService.getStorageKey('management-equipment-data-table'))
            .subscribe((data) => {
            this.setManagementDataTableParams(data || ManagementEquipmentDataTableDefaults, true);
        });
    }
    getInitialRetiredEquipment() {
        this.localStorage
            .getItem(this.authService.getStorageKey('retired-equipment-data-table'))
            .subscribe((data) => {
            this.setRetiredDataTableParams(data || RetiredEquipmentDataTableDefaults, true);
        });
    }
    getInitialVessel() {
        this.vesselLookupParams
            .pipe(switchMap((params) => this.lookupEquipment(params)))
            .subscribe((equipments) => this.vesselList$.next(equipments));
    }
    getLookupEquipment() {
        return this.vesselList;
    }
    getVesselLookupParams() {
        return this.vesselLookupParams;
    }
    setLookupParams(params) {
        this.vesselLookupParams$.next(params);
    }
    resetLookupParams() {
        const batchId = this.vesselLookupParams$.value.batchExternalId;
        const excludedIds = this.vesselLookupParams$.value.filterRequest.externalIdsToExclude;
        this.setLookupParams(Object.assign({}, this.defaultLookupParams, { batchExternalId: batchId, filterRequest: new ExcludedIdsFilterRequestApplyModel(Object.assign({}, this.defaultLookupParams.filterRequest, { externalIdsToExclude: excludedIds })) }));
    }
    resetVesselExcludedIds() {
        const vesselLookupParams = this.vesselLookupParams$.value;
        vesselLookupParams.filterRequest.externalIdsToExclude = [];
        this.vesselLookupParams$.next(vesselLookupParams);
    }
    fetchManagementEquipment() {
        const params = this.managementEquipmentDataTableParams$.value.params;
        const filters = this.managementEquipmentDataTableParams$.value.filters;
        const requestParams = [
            params.x_query,
            params.x_order,
            params.x_desc,
            params.x_pageNumber,
            params.x_pageSize,
        ];
        this.equipmentClient
            .manufacturingEquipmentFilterPut(filters, true, ...requestParams)
            .subscribe((data) => {
            this.setEquipmentList(data);
            this.setManagementEquipmentPagination(data.pageInfo);
            this.fetchEquipmentStatistic();
        });
    }
    fetchRetiredEquipment() {
        const params = this.retiredEquipmentDataTableParams$.value.params;
        const filters = this.retiredEquipmentDataTableParams$.value.filters;
        const requestParams = [
            params.x_query,
            params.x_order,
            params.x_desc,
            params.x_pageNumber,
            params.x_pageSize,
        ];
        this.equipmentClient
            .manufacturingEquipmentFilterPut(filters, false, ...requestParams)
            .subscribe((data) => {
            this.setEquipmentList(data);
            this.setRetiredEquipmentPagination(data.pageInfo);
            this.fetchEquipmentStatistic(true);
        });
    }
    fetchEquipment(id) {
        this.equipmentClient
            .manufacturingEquipmentByEquipmentExternalIdGet(id)
            .subscribe((equipment) => this.setSingleEquipment(equipment));
    }
    addNewEquipment() {
        const newEquipment = new EquipmentFullResponse();
        this.setSingleEquipment(newEquipment);
    }
    setDuplicateEquipment(equipment) {
        const newEquipment = this.deepCopyService.deepCopy(equipment);
        newEquipment.externalId = null;
        newEquipment.assetNumber = null;
        newEquipment.serialNumber = null;
        newEquipment.purchaseDate = null;
        newEquipment.placeInServiceDate = null;
        this.equipmentDuplicated$.next(newEquipment);
    }
    setSingleEquipment(data) {
        this.equipment$.next(data);
    }
    setEquipmentList(equipmentList) {
        this.equipmentList$.next(equipmentList);
    }
    clearSingleEquipment() {
        this.equipment$.next(null);
    }
    removeEquipment(equipmentId) {
        return this.equipmentClient.manufacturingEquipmentDeleteEquipmentPut(equipmentId);
    }
    insertEquipment(equipment) {
        return this.equipmentClient.manufacturingEquipmentInsertEquipmentPost(equipment);
    }
    updateEquipment(externalId, equipment) {
        return this.equipmentClient.manufacturingEquipmentUpdateEquipmentPut(externalId, equipment);
    }
    getEquipmentModels() {
        return this.equipmentClient.manufacturingEquipmentGetEquipmentModelsGet();
    }
    retireEquipment(externalId) {
        return this.equipmentClient.manufacturingEquipmentRetireEquipmentPut(externalId);
    }
    unretireEquipment(externalId) {
        return this.equipmentClient.manufacturingEquipmentUnRetireEquipmentPut(externalId);
    }
    setOutOfOrderEquipment(externalId, outOfOrderStatus) {
        return this.equipmentClient.manufacturingEquipmentSetOutOfOrderStatusPut(externalId, outOfOrderStatus);
    }
    clearDuplicateEquipment() {
        this.equipmentDuplicated$.next(null);
    }
    fetchEquipmentStatistic(isRetired) {
        this.equipmentClient
            .manufacturingEquipmentGetEquipmentStatisticGet(!isRetired)
            .subscribe((statistic) => this.equipmentStatistics$.next(statistic));
    }
    getEquipmentImage(externalId) {
        return this.equipmentClient.manufacturingEquipmentEquipmentModelImageByExternalIdGet(externalId);
    }
    /* Data Table params */
    setManagementDataTableParams(dataTableParams, initialTable) {
        const newParamsValue = Object.assign({}, this.managementEquipmentDataTableParams$.value, dataTableParams);
        this.managementEquipmentDataTableParams$.next(newParamsValue);
        const key = this.authService.getStorageKey('management-equipment-data-table');
        this.localStorage.setItemSubscribe(key, newParamsValue);
        if (!initialTable) {
            this.fetchManagementEquipment();
        }
    }
    setRetiredDataTableParams(dataTableParams, initialTable) {
        const newParamsValue = Object.assign({}, this.retiredEquipmentDataTableParams$.value, dataTableParams);
        this.retiredEquipmentDataTableParams$.next(newParamsValue);
        const key = this.authService.getStorageKey('management-equipment-data-table');
        this.localStorage.setItemSubscribe(key, newParamsValue);
        if (!initialTable) {
            this.fetchRetiredEquipment();
        }
    }
    setManagementEquipmentPagination(pagination) {
        this.managementEquipmentPagination$.next(pagination);
    }
    setRetiredEquipmentPagination(pagination) {
        this.retiredEquipmentPagination$.next(pagination);
    }
    getFacilities() {
        return this.equipmentClient.manufacturingLookUpFacilitiesGet();
    }
    generateLabel(externalId) {
        return this.equipmentClient.manufacturingEquipmentGenerateLabelByExternalIdGet(externalId);
    }
    lookupEquipment(params) {
        return this.equipmentClient.manufacturingLookUpVesselsByEquipmentModelTypeIdPut(params.equipmentModelTypeId, params.filterRequest, params.facilityExternalId, params.vesselStateTypeId, params.batchExternalId, params.processDate, params.x_query, params.x_order, params.x_desc, params.x_pageNumber, params.x_pageSize);
    }
}
EquipmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EquipmentService_Factory() { return new EquipmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvService), i0.ɵɵinject(i3.LocalStorage), i0.ɵɵinject(i4.ObjectDeepCopyService), i0.ɵɵinject(i5.AuthService)); }, token: EquipmentService, providedIn: "root" });
