import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FilterOptions } from '../../core/services/Inventory';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterConditionsModel, FilterPositionModel, SingleFilterToShowModel } from '../../shared/models';
import { closeCalendar } from '../../shared/utils';
import * as moment from 'moment-timezone';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';

@Component({
  selector: 'app-filters-item',
  templateUrl: './filters-item.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class FiltersItemComponent implements OnInit {
  @Input() fields: FilterOptions[];
  @Input() lastItem = false;
  @Input() sectionIndex: number;
  @Input() filter: SingleFilterToShowModel;

  @Output() filterAdded: EventEmitter<SingleFilterToShowModel> = new EventEmitter<SingleFilterToShowModel>();
  @Output() invalidFilter: EventEmitter<null> = new EventEmitter<null>();
  @Output() rowRemoved: EventEmitter<FilterPositionModel> = new EventEmitter<FilterPositionModel>();
  @Output() rowChanged: EventEmitter<FilterPositionModel> = new EventEmitter<FilterPositionModel>();

  conditions: FilterConditionsModel[];
  date = moment();
  filterForm = new FormGroup({
    typeControl: new FormControl(null, {
      validators: [Validators.required]
    }),
    conditionControl: new FormControl(null, {
      validators: [Validators.required]
    }),
    valueControl: new FormControl(null, {
      validators: [Validators.required]
    })
  });

  closeDatepicker = closeCalendar;

  @HostBinding('style.width') private width = '100%';

  constructor() {
  }

  get conditionFormField() {
    return this.filterForm.get('conditionControl');
  }

  get typeFormField() {
    return this.filterForm.get('typeControl');
  }

  get valueFormField() {
    return this.filterForm.get('valueControl');
  }

  ngOnInit(): void {
    if (this.filter.fieldName && this.filter.label) {
      if (this.filter.type === 'Enum') {
        if (this.fields) {
          this.fields.map(field => {
            if (field.name === this.filter.fieldName) {
              this.typeFormField.setValue({
                kind: this.filter.type,
                name: this.filter.fieldName,
                label: this.filter.label,
                possibleValues: field.possibleValues
              });
            }
          });
        }
      } else {
        this.typeFormField.setValue({ kind: this.filter.type, name: this.filter.fieldName, label: this.filter.label });
      }
    }
    if (this.filter.value) {
      if (this.filter.type === 'Date') {
        const dateInLocalTime = moment(this.filter.value as number * 1000);
        if (new Date().getTimezoneOffset() / 60 < 0) {
          const date = new Date(dateInLocalTime.clone().startOf('day').valueOf());
          this.valueFormField.setValue(date);
        } else {
          const smallAmountOfTime = 1;
          const date = new Date(dateInLocalTime.clone().endOf('day').valueOf() + smallAmountOfTime);
          this.valueFormField.setValue(date);
        }
      } else {
        this.valueFormField.setValue(this.filter.value);
      }
    }
    if (this.filter.condition) {
      this.conditionFormField.setValue(this.filter.condition);
    }
    this.setUpConditions(this.filter.type);
  }

  setUpConditions(value: string): void {
    if (value === 'String') {
      this.conditions = [
        {
          label: 'Contains',
          value: 'Contains',
          icon: 'string-contains'
        },
        {
          label: 'Does Not Contain',
          value: 'DoesNotContain',
          icon: 'string-not-contains'
        },
        {
          label: 'Ends With',
          value: 'EndsWith',
          icon: 'string-end'
        },
        {
          label: 'Starts With',
          value: 'StartsWith',
          icon: 'string-start'
        }
      ];
    } else if (value === 'Int' || value === 'Date') {
      this.conditions = [
        {
          label: 'Greater Than',
          value: 'GreaterThan',
          icon: 'greater-than'
        },
        {
          label: 'Less Than',
          value: 'LessThan',
          icon: 'less-than'
        },
        {
          label: 'Equals',
          value: 'Equals',
          icon: 'equals'
        },
        {
          label: 'Does Not Equal',
          value: 'DoesNotEqual',
          icon: 'not-equals'
        }
      ];
    } else if (value === 'Enum') {
      this.conditions = [
        {
          label: 'Equals',
          value: 'Equals',
          icon: 'equals'
        },
        {
          label: 'Does Not Equal',
          value: 'DoesNotEqual',
          icon: 'not-equals'
        }
      ];
    } else {
      this.conditions = [];
    }
    this.conditions.map(el => {
      if (el.value === this.filter.condition) {
        this.filter.condition = el.value;
        this.conditionFormField.setValue(el);
      }
    });
  }

  onTypeChange(value: FilterOptions): void {
    this.filter.condition = null;
    this.filter.value = null;
    this.filter.type = null;
    this.rowChanged.emit({ sectionIndex: this.sectionIndex, orderIndex: this.filter.orderIndex });
    this.conditionFormField.setValue(null);
    this.valueFormField.setValue(null);
    this.setUpConditions(value.kind);
  }

  onConditionChange(): void {
    if (this.filter.type === 'Date') {
      this.filter.value = null;
      this.valueFormField.setValue(null);
    }
    this.submit();
  }

  removeRow(): void {
    this.filterForm.reset();
    this.rowRemoved.emit({ sectionIndex: this.sectionIndex, orderIndex: this.filter.orderIndex });
  }

  submit(): void {
    if (this.filterForm.status === 'VALID') {
      const filter = {
        condition: this.conditionFormField.value.value,
        value: 0,
        fieldName: this.typeFormField.value.name,
        type: this.typeFormField.value.kind,
        orderIndex: this.filter.orderIndex,
        sectionIndex: this.sectionIndex,
        label: this.filterForm.get('typeControl').value.label
      };

      if (this.typeFormField.value.kind === 'Date') {
        // const transferredTime = moment(this.valueFormField.value).tz('America/Los_Angeles').valueOf();
        // const momentTime = moment.utc(transferredTime);
        // if (new Date().getTimezoneOffset() / 60 < 0) {
        //   const smallAmountOfTime = 0.001;
        //   filter.value = (momentTime.clone().endOf('day').valueOf() / 1000) + smallAmountOfTime;
        // } else {
        //   filter.value = (momentTime.clone().startOf('day').valueOf() / 1000);
        // }
        filter.value = this.valueFormField.value.unix();
      } else {
        filter.value = this.valueFormField.value;
      }
      this.filterAdded.emit(filter);
    } else {
      this.invalidFilter.emit();
    }
  }

  onCalendarChange(date: moment.Moment): void {
    this.date = date;
    this.filterForm.controls.valueControl.setValue(date);
    this.submit();
  }
}
