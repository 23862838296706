import { Pipe, PipeTransform } from '@angular/core';
import {ItemTransactionType} from '../../purchasing/orders/orders-interfaces';

@Pipe({
  name: 'itemTransaction'
})
export class ItemTransactionPipe implements PipeTransform {

  readonly map = {
    [ItemTransactionType.RECEIVED]: ['Received'],
    [ItemTransactionType.RETURNED]: ['Returned'],
    [ItemTransactionType.BACKORDER]: ['Back order', 'Marked'],
    [ItemTransactionType.REFUND]: ['Refund'],
    [ItemTransactionType.SHIPPED]: ['Shipped'],
    [ItemTransactionType.PICKED]: ['Picked'],
  };

  transform(value: ItemTransactionType, args?: any): string[] {
    return this.map[value];
  }

}

