import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'areaNamePipe' })
export class AreaNamePipe implements PipeTransform {
  transform(content: string, value: string): any {
    if (typeof content !== 'string') {
      return content;
    }
    return content
      .replace(`${value}_`, '')
      .split(/(?=[A-Z])/)
      .join(' ');
  }
}
