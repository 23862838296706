<aside (clickOutside)="onClickOutside($event)" class="sidebar {{sidebarOpen ? 'open' : ''}}" click-outside>
  <ul *ngIf="render" class="sidebar__item-list">
    <li class="sidebar__item {{currentItem === sidebarItem.title ? 'active': ''}}" *ngFor="let sidebarItem of sidebarItems">
      <span (click)="openSidebar(sidebarItem.title)" class="sidebar--icon-wrap">
        <span class="sidebar--blocker"></span>
          <a [routerLink]="sidebarItem.link" class="sidebar__link" routerLinkActive="active">
            <i class="sidebar__gfx">
              <svg-icon icon="{{sidebarItem.icon}}"></svg-icon>
            </i>
            <span class="sidebar__label">{{sidebarItem.title}}</span>
          </a>
      </span>
      <div *ngIf="currentItem === sidebarItem.title" class="sidebar-sub">
        <button (click)="closeSidebar()" class="btn btn--close sidebar-sub__close">
          <svg>
            <use xlink:href="#plus"></use>
          </svg>
        </button>
        <h4 class="sidebar-sub__title">{{sidebarItem.title}}</h4>
        <ul class="sidebar-sub__menu">
          <li *ngFor="let subItem of sidebarItem.sublinks" class="sidebar-sub__menu-item">
            <a  *ngIf="subscriptionService.getAccess(subItem.feature)"
                (click)="closeSidebar()"
                [routerLink]="subItem.link"
                class="sidebar-sub__menu-link"
                routerLinkActive="active">{{subItem.text}}</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</aside>
