import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../core/services/Env';
import {
  FilterRequestApplyModel,
  FilterRequestModel,
  FilterResponseModel,
  FilterTypeId2, InventoryClientFetch,
  PaginatedResponseOfLookUpResponse,
  RemovalResponseModel
} from '../core/services/Inventory';
import {
  AdminClient
} from '../core/services/Admin';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FilterOptions } from '@onbatch/core/services/Manufacturing';

@Injectable()
export class FiltersService {
  filtersOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersClosedWithoutAppilation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersApplied: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLastFilterLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSavePossible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  savedFilters: BehaviorSubject<PaginatedResponseOfLookUpResponse> = new BehaviorSubject<PaginatedResponseOfLookUpResponse>(new PaginatedResponseOfLookUpResponse);

  filtersProps = new Subject<FiltersProps>();
  activeFilters = new Subject<FilterRequestApplyModel[]>();

  private filtersClient: InventoryClientFetch;
  private filtersClientForAdmin: AdminClient;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.filtersClient = new InventoryClientFetch(envService.apiUrl);
    this.filtersClientForAdmin = new AdminClient(this.http, envService.apiUrl);
  }

  getAllFilters(filterTypeId?: FilterTypeId2 | null | undefined,
                x_query?: string | null | undefined,
                x_order?: string | null | undefined,
                x_desc?: any | null | undefined,
                x_pageNumber?: any | null | undefined,
                x_pageSize?: any | null | undefined): void {
    this.filtersClient.lookUpFiltersGet(filterTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize)
      .then((res: PaginatedResponseOfLookUpResponse) => {
        this.setSavedFilters(res);
      });
  }

  getAllFiltersForAdmin(filterTypeId?: FilterTypeId2 | null | undefined,
                        x_query?: string | null | undefined,
                        x_order?: string | null | undefined,
                        x_desc?: any | null | undefined,
                        x_pageNumber?: any | null | undefined,
                        x_pageSize?: any | null | undefined): void {
    this.filtersClientForAdmin.adminLookupFiltersGet(filterTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize)
      .toPromise()
      .then((res: PaginatedResponseOfLookUpResponse) => {
        this.setSavedFilters(res);
      });
  }

  saveFilter(model?: FilterRequestModel | null | undefined): Promise<FilterResponseModel> {
    return this.filtersClient.filterPost(model);
  }

  updateFilter(externalId: string, model?: FilterRequestModel | null | undefined): Promise<FilterResponseModel> {
    return this.filtersClient.filterByExternalIdPut(externalId, model);
  }

  deleteFilter(externalId?: string | null | undefined): Promise<RemovalResponseModel> {
    return this.filtersClient.filterDelete(externalId);
  }

  getFilterById(externalId: string): Promise<FilterResponseModel> {
    return this.filtersClient.filterByExternalIdGet(externalId);
  }

  getFiltersOpened(): Observable<boolean> {
    return this.filtersOpened.asObservable();
  }

  setFiltersAsOpened(value: boolean) {
    this.filtersOpened.next(value);
    if (value) {
      this.setFiltersClosedWithoutAppilation(false);
    }
  }

  getFiltersApplied(): Observable<boolean> {
    return this.filtersApplied.asObservable();
  }

  setFiltersAsApplied(value: boolean) {
    this.filtersApplied.next(value);
  }

  getFiltersLoaded(): Observable<boolean> {
    return this.filtersLoaded.asObservable();
  }

  setFiltersAsLoaded(value: boolean) {
    this.filtersLoaded.next(value);
  }

  getIsLastFilterLoaded(): Observable<boolean> {
    return this.isLastFilterLoaded.asObservable();
  }

  setIsLastFilterLoaded(value: boolean) {
    this.isLastFilterLoaded.next(value);
  }

  getIsSavePossible(): Observable<boolean> {
    return this.isSavePossible.asObservable();
  }

  setIsSavePossible(value: boolean) {
    this.isSavePossible.next(value);
  }

  getSavedFilters(): Observable<PaginatedResponseOfLookUpResponse> {
    return this.savedFilters.asObservable();
  }

  setSavedFilters(value: PaginatedResponseOfLookUpResponse) {
    this.savedFilters.next(value);
  }

  getFiltersClosedWithoutAppilation() {
    return this.filtersClosedWithoutAppilation.asObservable();
  }

  setFiltersClosedWithoutAppilation(value: boolean) {
    this.filtersClosedWithoutAppilation.next(value);
  }
}
export interface FiltersProps {
  resetFilters: boolean;
  showFilters: boolean;
  activeFilters: FilterRequestApplyModel[];
  filtersType: FilterTypeId2;
  allFields: FilterOptions[];
}
