import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'historyEventIcon' })
export class HistoryEventIconPipe implements PipeTransform {
  transform(content: string): any {
    let svgName: string;
    switch (content) {
      case 'MasterItemCreated':
      case 'PurchaseOrderCreated':
      case 'BillItemCreated':
      case 'RefundCreated':
      case 'PurchaseOrderItemCreated':
      case 'MasterItemPricingCreated':
      case 'MasterItemPricingByCustomerCreated':
      case 'SalesOrderCreated':
      case 'SalesOrderItemCreated':
      case 'ShipmentCreated':
      case 'ShipmentItemCreated':
      case 'ShipmentFileAdded':
      case 'BillOfLadingCreated':
      case 'InvoiceAttached':
      case 'ContractorContactAssigned':
      case 'AddressCreated':
      case 'ContactMethodCreated':
      case 'ContractorCreated':
      case 'BillCreated':
      case 'MasterItemPurchased':
      case 'MasterItemManufactured': {
        svgName = 'history-add';
        break;
      }
      case 'MasterItemEdited':
      case 'PurchaseOrderUpdated':
      case 'BillUpdated':
      case 'BillItemUpdated':
      case 'RefundUpdated':
      case 'RefundItemUpdated':
      case 'PurchaseOrderItemsUpdate':
      case 'PurchaseOrderReceiveItems':
      case 'PurchaseOrderReturnsItems':
      case 'PurchaseOrderBackOrderItems':
      case 'MasterItemPricingUpdated':
      case 'MasterItemPricingByCustomerUpdated':
      case 'ContractorUsedExternally':
      case 'ContractorUpdated':
      case '': {
        svgName = 'history-edit';
        break;
      }
      case 'PurchaseOrderVoided':
      case 'MasterItemDeleted':
      case 'BillDeleted':
      case 'BillItemDeleted':
      case 'PurchaseOrderClosed':
      case 'PurchaseOrderItemDeleted':
      case 'MasterItemPricingCustomerDeleted':
      case 'MasterItemPricingAssigmentDeleted':
      case 'MasterItemPricingCustomerAssigmentDeleted':
      case 'MasterItemPricingDeleted':
      case 'SalesOrderItemDeleted':
      case 'SalesOrderClosed':
      case 'SalesOrderItemPickedDeleted':
      case 'ShipmentFileDeleted':
      case 'ShipmentDeleted':
      case 'BillOfLadingDeleted':
      case 'InvoiceDetached':
      case 'SalesOrderVoided':
      case 'ContractorContactUnassigned':
      case 'ContactMethodDeleted':
      case 'AddressDeleted':
      case 'ContractorReleasedExternally':
      case 'ContractorArchived':
      case 'ContractorDeleted':
      case 'MasterItemArchived':
      case 'MasterItemSold':
      case 'MasterItemUsedInBatch':
      case 'MasterItemUsedInRepackaging': {
        svgName = 'history-remove';
        break;
      }
      case 'MasterTransferred':
      case 'MasterItemRepackaged': {
        svgName = 'history-transfer';
        break;
      }
      default: {
        svgName = 'history-edit';
      }
    }
    return svgName;
  }
}
