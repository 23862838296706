import { Injectable } from '@angular/core';
import { MasterItemType, PaginatedResponseOfLookUpResponse } from '../../core/services/Inventory';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../core/services/Env';
import {
  InventoryClient, LookUpResponseTreeWithDescription, MasterItemTypeId,
  PaginatedResponseOfLookUpResponseTreeWithDescription,
  PaginatedResponseOfMasterItemLookupResponse
} from '../../core/services/InventoryRx';
import { Observable } from 'rxjs';
import { KindOfSpirit } from '../components/create-item/create-item.component';

@Injectable({
  providedIn: 'root'
})
export class InventoryLookupsService {


  private inventoryClient: InventoryClient;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.inventoryClient = new InventoryClient(http, envService.apiUrl);
  }

  lookupTemplates(
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpMasteritemsTemplatesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupLocations(masterItemTypeId?: MasterItemTypeId | null | undefined,
                  x_query?: string | null | undefined,
                  x_order?: string | null | undefined,
                  x_desc?: any | null | undefined,
                  x_pageNumber?: any | null | undefined,
                  x_pageSize?: any | null | undefined): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpFacilitiesGet(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupUnitsOfMeasurement(
    masterItemType?: MasterItemType | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpUnitofmeasurementGet(masterItemType, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupUnitsOfMeasurementForContainer(
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpContainerUnitofmeasurementGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupFermentable(
    categoryExternalId?: string | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpFermentablematerialGet(categoryExternalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupMasterItems(
    withoutPricing?: boolean | null | undefined,
    withoutRawMaterials?: boolean | null | undefined,
    withoutFinishedGoods?: boolean | null | undefined,
    withoutSemiFinishedGoods?: boolean | null | undefined,
    withoutAlcohol?: boolean | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined): Observable<PaginatedResponseOfMasterItemLookupResponse> {
    return this.inventoryClient.lookUpMasteritemsGet(
      withoutPricing,
      withoutRawMaterials,
      withoutFinishedGoods,
      withoutSemiFinishedGoods,
      withoutAlcohol,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  lookupKindOfSpirits(
    filterBySettings: boolean | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponseTreeWithDescription> {
    return this.inventoryClient.lookUpKindofspiritGet(filterBySettings, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  flattenKindOfSpiritResponse(list: LookUpResponseTreeWithDescription[]): KindOfSpirit[] {
    const kindOfSpirits: KindOfSpirit[] = [];
    list.forEach(spiritClass => {
      if (spiritClass.list) {
        if (spiritClass.list.length === 0) {
          kindOfSpirits.push({
            name: spiritClass.name,
            externalId: mergeKindOfSpiritIds(spiritClass.externalId, spiritClass.customKindOfSpiritExternalId),
            originalExternalId: spiritClass.externalId,
            customExternalId: spiritClass.customKindOfSpiritExternalId,
          });
        }
        spiritClass.list.forEach(spiritType => {
          kindOfSpirits.push({
            name: spiritType.name,
            externalId: mergeKindOfSpiritIds(spiritType.externalId, spiritType.customKindOfSpiritExternalId),
            originalExternalId: spiritType.externalId,
            customExternalId: spiritType.customKindOfSpiritExternalId,
          });
        });
      } else {
        kindOfSpirits.push({
          name: spiritClass.name,
          externalId: mergeKindOfSpiritIds(spiritClass.externalId, spiritClass.customKindOfSpiritExternalId),
          originalExternalId: spiritClass.externalId,
          customExternalId: spiritClass.customKindOfSpiritExternalId,
          isCustom: !!spiritClass.customKindOfSpiritExternalId,
        });
      }
    });
    return kindOfSpirits;
  }
}

export function mergeKindOfSpiritIds(externalId: string, customExternalId: string) {
  return `${externalId}:${customExternalId}`;
}
