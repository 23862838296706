import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html'
})
export class FormErrorMessageComponent {
  @Input() formSubmitted = false;
  @Input() errors: ValidationErrors;
  @Input() patternError: string;
}
