import { Component, Input } from '@angular/core';
import { NameExternalIdModel } from '@onbatch/core/services/Account';
import { FacilityResponseModel } from '@onbatch/core/services/InventoryRx';

@Component({
  selector: 'app-multiple-items-value',
  templateUrl: './multiple-items-value.component.html'
})
export class MultipleItemsValueComponent {

  @Input() item: NameExternalIdModel | FacilityResponseModel;
  @Input() arrayLength: number;

}
