import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { AuthService } from '../../core/services/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

  static mixPanelInstanceSubject = new BehaviorSubject<mixpanel.Mixpanel>(mixpanel);

  get mixPanelInstance() {
    return MixpanelService.mixPanelInstanceSubject.value;
  }

  init(token: string, config?: Partial<mixpanel.Config>, name?: string): void {
    this.mixPanelInstance.init(token, config, name);
  }

  identify(unique_id?: string): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      this.mixPanelInstance.identify(unique_id);
    }
  }

  register(props: mixpanel.Dict, days?: number): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.register(props, days);
      } catch(e) {}
    }
  }

  register_once(props: mixpanel.Dict, default_value?: any, days?: number): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.register_once(props, default_value, days);
      } catch(e) {}
    }
  }


  reset(): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.reset();
      } catch(e) {}
    }
  }

  setPeople(prop: mixpanel.Dict, callback?: mixpanel.Callback): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.people.set(prop, callback);
      } catch(e) {}
    }
  }

  setGroup(group_key: string, group_ids: any, callback?: mixpanel.Callback) {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.set_group(group_key, group_ids, callback);
      } catch (e) { }
    }
  }

  track(event_name: string, properties?: mixpanel.Dict, optionsOrCallback?: mixpanel.RequestOptions | mixpanel.Callback, callback?: mixpanel.Callback): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.track(event_name, properties, optionsOrCallback, callback);
      } catch(e) {}
    }
  }

  unregister(property: string): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      try {
        this.mixPanelInstance.unregister(property);
      } catch(e) {}
    }
  }

  logout(): void {
    if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
      this.mixPanelInstance.reset();
    }
  }
}
