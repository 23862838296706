import { Routes } from '@angular/router';
import { CallbackPageComponent } from './user/callback-page/callback-page.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { AdminGuardService } from './core/guards/admin-guard.service';
import { WelcomePageComponent } from './user/welcome-page/welcome-page.component';
import { ActiveSubscriptionGuardsService } from '@onbatch/core/guards/active-subscription-guards.service';
const ɵ0 = () => import("./inventory/inventory.module.ngfactory").then(m => m.InventoryModuleNgFactory), ɵ1 = () => import("./account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ2 = () => import("./admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory), ɵ3 = () => import("./subscription/subscription.module.ngfactory").then(m => m.SubscriptionModuleNgFactory), ɵ4 = () => import("./manufacturing/manufacturing.module.ngfactory").then(m => m.ManufacturingModuleNgFactory), ɵ5 = () => import("./purchasing/purchasing.module.ngfactory").then(m => m.PurchasingModuleNgFactory), ɵ6 = () => import("./warehousing/warehousing.module.ngfactory").then(m => m.WarehousingModuleNgFactory), ɵ7 = () => import("./sales/sales.module.ngfactory").then(m => m.SalesModuleNgFactory), ɵ8 = () => import("./crm/crm.module.ngfactory").then(m => m.CrmModuleNgFactory), ɵ9 = () => import("./adhoc-reporting/reporting.module.ngfactory").then((m) => m.ReportingModuleNgFactory);
const routes = [
    {
        path: '',
        children: [
            {
                path: 'inventory',
                loadChildren: ɵ0,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'account',
                loadChildren: ɵ1,
                canActivate: [AuthGuardService]
            },
            {
                path: 'admin',
                loadChildren: ɵ2,
                canActivate: [AdminGuardService]
            },
            {
                path: 'subscriptions',
                loadChildren: ɵ3,
                canActivate: [AuthGuardService]
            },
            {
                path: 'manufacturing',
                loadChildren: ɵ4,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'purchasing',
                loadChildren: ɵ5,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'warehousing',
                loadChildren: ɵ6,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'sales',
                loadChildren: ɵ7,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'crm',
                loadChildren: ɵ8,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'callback',
                component: CallbackPageComponent
            },
            {
                path: 'reporting',
                loadChildren: ɵ9,
                canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
            },
            {
                path: 'welcome',
                component: WelcomePageComponent,
                canActivate: [AuthGuardService]
            },
            { path: '', redirectTo: 'welcome', pathMatch: 'full' }
        ]
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
