import {
  DstStageResponseModel,
  DstStageResponseModelFlowMapTransitionProcessType, IncomingTransferResponseModel, OutgoingTransferResponseModel,
  StageNodeResponseModel
} from '../../../core/services/Manufacturing';
import {getMergeNodeId, getNodeId, getSplitNodeId, getCrossTransferId} from './flow-graph.utils';
import {FlowMapConfig} from './flow-map-style';
import {sortBy} from 'lodash';
import {AlignmentConstraint, GapConstraint, NodeOffset, Constraints, MergeConstraints} from './flow-graph';

export function generateConstraints(sourceVertex: StageNodeResponseModel, putSplitUnderneath: boolean): Constraints {
  const forStageOnTheSameBranch: AlignmentConstraint[] = constraintsForStagesOnTheSameBranch(sourceVertex);
  const forSplit: Constraints = constraintsForSplit(sourceVertex, putSplitUnderneath);

  return {
    alignment: [
      ...forStageOnTheSameBranch,
      ...forSplit.alignment,
    ],
    gap: [
      ...forSplit.gap
    ]
  };
}

function constraintsForStagesOnTheSameBranch(sourceVertex: StageNodeResponseModel): AlignmentConstraint[] {
  const stagesOnTheSameBranch = sourceVertex.dstStages.filter(stage => stage.branchExternalId === sourceVertex.branchExternalId);
  if (!stagesOnTheSameBranch.length) {
    return [];
  }

  return [
    {
      axis: 'y',
      offsets: [
        {
          nodeId: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId),
          offset: 0
        },
        ...stagesOnTheSameBranch.map((stage: DstStageResponseModel) => ({
          nodeId: getNodeId(stage.branchExternalId, stage.externalId),
          offset: 0
        }))
      ]
    },
    {
      axis: 'x',
      offsets: [
        {
          nodeId: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId),
          offset: 0
        },
        ...stagesOnTheSameBranch.map((stage: DstStageResponseModel, index: number) => ({
          nodeId: getNodeId(stage.branchExternalId, stage.externalId),
          offset: (FlowMapConfig.nodeWidth + FlowMapConfig.margin) * (index + 1)
        }))
      ]
    }
  ];
}

function constraintsForSplit(sourceVertex: StageNodeResponseModel, putUnderneath: boolean): Constraints {
  const splitStages = sourceVertex.dstStages.filter(stage => stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Split);
  if (!splitStages.length) {
    return {alignment: [], gap: []};
  }

  const xOffset = FlowMapConfig.nodeWidth + 2 * FlowMapConfig.margin;
  const branchHeight = FlowMapConfig.clusterHeight + 2 * FlowMapConfig.margin;
  const totalHeight = splitStages.length * branchHeight;
  const yOffset = putUnderneath ? totalHeight / 2 : 0;

  const constraints: Constraints = {
    alignment: [
      {
        axis: 'x',
        offsets: [
          {
            nodeId: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId),
            offset: 0
          },
          {
            nodeId: getNodeId(sourceVertex.branchExternalId, getSplitNodeId(sourceVertex.stageInfo.externalId)),
            offset: putUnderneath ? 0 : xOffset
          },
          ...splitStages.map((stage: DstStageResponseModel) => ({
            nodeId: getNodeId(stage.branchExternalId, stage.externalId),
            offset: putUnderneath ? xOffset - FlowMapConfig.margin : xOffset * 2
          }))
        ]
      },
      {
        axis: 'y',
        offsets: sortBy([
          {nodeId: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId), offset: 0},
          {
            nodeId: getNodeId(sourceVertex.branchExternalId, getSplitNodeId(sourceVertex.stageInfo.externalId)),
            offset: putUnderneath ? yOffset : 0
          },
        ], (node: NodeOffset) => node.offset)
      }
    ],
    gap: splitStages.reduce((acc: GapConstraint[], current: DstStageResponseModel, currentIdx: number) => {
        if (currentIdx === splitStages.length - 1) {
          return acc;
        }

        const next: DstStageResponseModel = splitStages[currentIdx + 1];
        const constraint: GapConstraint = {
          axis: 'y',
          left: getNodeId(current.branchExternalId, current.externalId),
          right: getNodeId(next.branchExternalId, next.externalId),
          gap: FlowMapConfig.splitMergeGap,
        };
        return [...acc, constraint];
      }, []),
  };

  if (splitStages.length % 2 !== 0) {
    // we have odd number of branches. tie split node's y-coordinate with middle branch's
    const middleBranch: DstStageResponseModel = splitStages[(splitStages.length - 1) / 2];

    constraints.alignment.push({
      axis: 'y',
      offsets: [
        {
          nodeId: getNodeId(sourceVertex.branchExternalId, getSplitNodeId(sourceVertex.stageInfo.externalId)),
          offset: 0,
        },
        {
          nodeId: getNodeId(middleBranch.branchExternalId, middleBranch.externalId),
          offset: 0,
        }
      ]
    });
  } else {
    // even number of branches. the best we can do is to add additional gap constraints between split node and
    // two center branches

    const centerIdx = Math.floor((splitStages.length - 1) / 2);
    const topBranch: DstStageResponseModel = splitStages[centerIdx];
    const bottomBranch: DstStageResponseModel = splitStages[centerIdx + 1];

    constraints.gap.push(
      {
        axis: 'y',
        left: getNodeId(topBranch.branchExternalId, topBranch.externalId),
        right: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId),
        gap: 50,
      },
      {
        axis: 'y',
        left: getNodeId(sourceVertex.branchExternalId, sourceVertex.stageInfo.externalId),
        right: getNodeId(bottomBranch.branchExternalId, bottomBranch.externalId),
        gap: 50,
      });
  }

  return constraints;
}

export function updateMergeConstraints(vertex: StageNodeResponseModel, dst: DstStageResponseModel, mergeConstraints: MergeConstraints) {
  if (dst.externalId in mergeConstraints) {
    const xConstraints = mergeConstraints[dst.externalId].alignment[0];
    xConstraints.offsets = [
      {
        nodeId: getNodeId(vertex.branchExternalId, vertex.stageInfo.externalId),
        offset: 0
      },
      ...xConstraints.offsets,
    ];

    const yGapConstraints = mergeConstraints[dst.externalId].gap;
    const currentNodeId = getNodeId(vertex.branchExternalId, vertex.stageInfo.externalId);

    yGapConstraints[yGapConstraints.length - 1] = {
      ...yGapConstraints[yGapConstraints.length - 1],
      right: currentNodeId,
    };
    yGapConstraints.push({
      axis: 'y',
      left: currentNodeId,
      right: '',
      gap: FlowMapConfig.splitMergeGap,
    });
  } else {
    const xOffset = FlowMapConfig.nodeWidth + 2 * FlowMapConfig.margin;
    mergeConstraints[dst.externalId] = {
      alignment:
        [
          {
            axis: 'x',
            offsets: [
              {
                nodeId: getNodeId(vertex.branchExternalId, vertex.stageInfo.externalId),
                offset: 0
              },
              {nodeId: getNodeId(dst.branchExternalId, getMergeNodeId(dst.externalId)), offset: xOffset},
              {nodeId: getNodeId(dst.branchExternalId, dst.externalId), offset: xOffset * 2},
            ]
          },
          {
            axis: 'y',
            offsets: [
              {nodeId: getNodeId(dst.branchExternalId, dst.externalId), offset: 0},
              {nodeId: getNodeId(dst.branchExternalId, getMergeNodeId(dst.externalId)), offset: 0},
            ]
          }
      ],
      gap:
        [
          /*
           Due to structure of adjacency list, constraints for merges cannot be applied in one go.
           Instead, we'll use incremental approach: on first encounter with a merge destination branch,
           we'll populate constraints with dummy values. Since there must be at least two merge branches
           with the same destination branch, we'll update those dummy values later.
         */
          {
            axis: 'y',
            left: getNodeId(vertex.branchExternalId, vertex.stageInfo.externalId),
            right: '',
            gap: FlowMapConfig.splitMergeGap,
          }
        ],
    };
  }
}

export function constraintsForCrossTransfers(
  stage: StageNodeResponseModel,
  transfers: IncomingTransferResponseModel[] | OutgoingTransferResponseModel[],
): Constraints {
  const x: AlignmentConstraint = {
    axis: 'x',
    offsets: [
      {
        nodeId: getNodeId(stage.branchExternalId, stage.stageInfo.externalId),
        offset: 0
      },
      ...transfers.map((transfer: IncomingTransferResponseModel | OutgoingTransferResponseModel): NodeOffset => (
        {
          nodeId: getCrossTransferId(transfer),
          offset: (FlowMapConfig.nodeWidth / 2) + 16,
        }
      ))
    ]
  };

  const y: AlignmentConstraint = {
    axis: 'y',
    offsets: transfers.map((transfer: IncomingTransferResponseModel | OutgoingTransferResponseModel, index: number): NodeOffset => (
      {
        nodeId: getCrossTransferId(transfer),
        offset: index * FlowMapConfig.crossTransferHeight + 16,
      }))
  };

  const alignmentContraints: AlignmentConstraint[] = [];
  if (transfers.length > 0) {
    alignmentContraints.push(x);
  }
  if (transfers.length > 1) {
    alignmentContraints.push(y);
  }

  return {
    alignment: alignmentContraints,
    gap: [],
  };
}
