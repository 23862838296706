export enum TabsValues {
  GeneralInformation = 'GeneralInformation',
  Facilities = 'Facilities',
  Companies = 'Companies',
  Users = 'Users',
  Employees = 'Employees',
  TenantInformation = 'TenantInformation',
  PaymentHistory = 'PaymentHistory',
  CompanyStructure = 'CompanyStructure',
  Permissions = 'Permissions',
  AssignedUsers = 'AssignedUsers',
  SpecificDetails = 'SpecificDetails',
  BillOfMaterials = 'BillOfMaterials',
  Bills = 'Bills',
  History = 'History',
  TransferDetails = 'Transfer Details',
  ItemsTransferred = 'Items Transferred',
  FinishedGoods = 'Finished goods',
  SemiFinishedGoods = 'Semi-finished goods',
  BatchInformation = 'BatchInformation',
  BatchLog = 'BatchLog',
  VesselAssignment = 'VesselAssignment',
  Workers = 'Workers',
  ItemInformation = 'ItemInformation',
  ItemsList = 'ItemsList',
  ContentInformation = 'Content Information',
  VesselLog = 'Vessel Log',
  PricingDetails = 'Pricing Details',
  Invoice = 'Invoice',
  Shipments = 'Shipments',
  Locations = 'Locations',
  Associated = 'Associated',
  Prices = 'Prices',
  Schemas = 'Schemas',
  PurchaseOrders = 'PurchaseOrders',
  SalesOrders = 'SalesOrders',
  CsvImport = 'CsvImport',
  Reports = 'Reports',
  TransactionReports = 'TransactionReports',
}
