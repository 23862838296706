import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { pricePrefix } from '@onbatch/shared/constants';
import {
  CommonMeasurementRequest,
  CommonMeasurementResponse,
  MeasurementRecalculationRequest,
  TransferInBondIncomingItemResponseModel,
  TransferInBondReceivedItemRequestModel,
  WarehousingMasterItemResponseModelMasterItemTypeId,
} from '@onbatch/core/services/Warehousing';
import * as moment from 'moment';
import { getRemainingQuantity } from '../receive-items-utils';
import {
  checkIfClickedElementHasClass,
  checkIfDatePickerWasClicked,
} from '@onbatch/shared/utils';
import { debounce } from 'lodash';
import { MaxAmountDataForSFG } from '../receive-transfer.interface';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { TransfersService } from '../../../../warehousing/transfers/transfers.service';
import { ConfirmationModalService, ModalConfirmationState } from '@onbatch/shared/services/confirmation-modal.service';
import { skip, take } from 'rxjs/operators';

@Component({
  selector: 'app-receive-transfer-items-modal',
  templateUrl: './receive-transfer-items-modal.component.html'
})
export class ReceiveTransferItemsModalComponent implements OnInit {
  @Input() item: TransferInBondIncomingItemResponseModel;
  @Input() showModal = false;
  @Output() addReceive = new EventEmitter<void>();
  @Output() submitClicked = new EventEmitter<FormGroup>();

  canFormBeSubmitted = true;
  form: FormGroup;
  formSubmitStatus = false;
  isAddIncomingDirty = false;
  wasReceiveItemsChange = false;
  selectedItemsToReceive = true;

  date = moment();

  remainingQuantity = 0;
  maxAmountValidationForSFG: Map<string, MaxAmountDataForSFG> = new Map();

  readonly pricePrefix = pricePrefix;

  get isFinishedGood(): boolean {
    if (!this.item) {
      return false;
    } else {
      return this.item.masterItem.masterItemTypeId === WarehousingMasterItemResponseModelMasterItemTypeId.FinishedGood;
    }
  }

  calculateRemainingQuantity = debounce((request: MeasurementRecalculationRequest[]) => {
    this.setRemainingQuantity(request);
  }, 200);

  constructor(private fb: FormBuilder,
    private transfersService: TransfersService,
    private confirmationModalService: ConfirmationModalService,
    ) {
  }

  ngOnInit() {
    this.initForm();
  }

  closeModal(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['ng-option', 'owl', 'add-item-modal', 'btn-close']);

    if (event.value && (this.form.dirty || this.isAddIncomingDirty) && this.showModal && !isExcludedElementClicked && !checkIfDatePickerWasClicked(event)) {
      this.confirmationModalService.showConfirmationModal();
      this.confirmationModalService.getState().pipe(skip(1), take(1)).subscribe((modalState: ModalConfirmationState) => {
        this.showModal = !(modalState === ModalConfirmationState.confirmed);
        if (!this.showModal) {
          this.reset();
        }
      });
    } else if (event.value && !isExcludedElementClicked && !checkIfDatePickerWasClicked(event)) {
      this.showModal = false;
      this.reset();
    }
  }

  reset() {
    this.initForm();
    this.formSubmitStatus = false;
    this.resetSelectedItemsToReceive();
    this.resetWasReceiveItemsChange();
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.reset();
    } else {
      this.setRemainingQuantity([], false);
    }
  }

  submit() {
    this.checkItemsSelection();
    if (this.form.invalid || !this.canFormBeSubmitted || !this.selectedItemsToReceive) {
      this.formSubmitStatus = true;
      return;
    }
    this.save();
  }

  save() {
    this.showModal = false;
    this.submitClicked.emit(this.form);
  }

  validateSizeOfEquipment(form: FormGroup): void {
    const amountPerUnit = form.get('amountPerUnit').value;
    const unitOfMeasurementExternalId = form.get('equipment').value.unitOfMeasurementExternalId;
    const equipmentReceivedQuantityUnitOfMeasurementExternalId = form.get('equipmentReceivedQuantityUnitOfMeasurementExternalId').value;
    const alcoholContent = form.get('alcoholContent').value;
    const equipmentExternalId = form.get('equipmentExternalId').value;
    if (!!alcoholContent) {
      const request: MeasurementRecalculationRequest[] = [new MeasurementRecalculationRequest({
        amount: new CommonMeasurementRequest({
          value: amountPerUnit || 0,
          unitOfMeasurementExternalId: equipmentReceivedQuantityUnitOfMeasurementExternalId
        }),
        alcoholContent: alcoholContent || 0
      })];
      this.transfersService.calculateRemainingQuantity(request, unitOfMeasurementExternalId)
        .subscribe((data: CommonMeasurementResponse) => {
          this.maxAmountValidationForSFG = new Map(this.maxAmountValidationForSFG);
          this.maxAmountValidationForSFG.set(equipmentExternalId, {
            amount: data.value,
            externalId: equipmentExternalId,
            proof: +alcoholContent
          });
        });
    }
  }

  onReceiveItemsChange(form: FormArray) {
    this.checkItemsSelection(form);
    this.wasReceiveItemsChange = true;
    this.isAddIncomingDirty = form.dirty;

    if (!form || form.errors || !form.dirty) {
      this.canFormBeSubmitted = false;
      return;
    }
    if (!!(form.controls && form.controls[0] && form.controls[0].get('equipment') && !!form.controls[0].get('equipment').value)) {
      form.controls.forEach((formGroup: FormGroup) => this.validateSizeOfEquipment(formGroup));
    }
    const request: MeasurementRecalculationRequest[] = [];
    const items = form.controls.map(item => {
      const value: any = (item as FormGroup).value;

      if (value.equipment && value.equipmentReceivedQuantityUnitOfMeasurementExternalId) {
        request.push(new MeasurementRecalculationRequest({
          amount: new CommonMeasurementRequest({
            value: value.amountPerUnit || 0,
            unitOfMeasurementExternalId: value.equipmentReceivedQuantityUnitOfMeasurementExternalId
          }),
          alcoholContent: value.alcoholContent || 0
        }));
      }
      this.calculateRemainingQuantity(request);

      return new TransferInBondReceivedItemRequestModel({
        ...value,
        dateFilled: value.dateFilled ? moment(value.dateFilled).unix() : null
      });
    });
    this.canFormBeSubmitted = form.valid;
    this.form.get('receivedItems').patchValue(items);
  }

  initForm() {
    this.form = this.fb.group({
      useContractorSchema: new FormControl(false),
      containerExternalId: new FormControl(null),
      transferInBondItemExternalId: new FormControl(null),
      receivedItems: new FormControl(null),
      receiveDate: new FormControl(this.date.unix()),
    });
  }

  handleFormClick(event: Event) {
    event.stopPropagation();
  }

  setRemainingQuantity(request: MeasurementRecalculationRequest[], setErrors: boolean = true): void {
    if (request.length) {
      // Only for receiving SFG items into equipment
      this.transfersService.calculateRemainingQuantity(request, this.item.unitOfMeasurement.externalId)
        .subscribe((data: CommonMeasurementResponse) => {
          this.remainingQuantity = getRemainingQuantity(this.item, [], true, data.value);
        });
    } else {
      this.remainingQuantity = getRemainingQuantity(this.item, this.form.get('receivedItems').value);
    }

    if (this.remainingQuantity < 0 && setErrors) {
      this.form.get('receivedItems').setErrors({ 'max': true });
    } else {
      this.form.get('receivedItems').setErrors(null);
    }
  }

  onCalendarChange(date: moment.Moment) {
    this.date = date;
    this.form.controls.receiveDate.setValue(date.unix());
  }

  private checkItemsSelection(form?: FormArray) {
    if (!this.wasReceiveItemsChange && !form) {
      this.selectedItemsToReceive = false;
    }
    if (form) {
      this.selectedItemsToReceive = !!(form.controls && form.controls.length > 0);
    }
  }

  private resetSelectedItemsToReceive() {
    this.selectedItemsToReceive = true;
  }

  private resetWasReceiveItemsChange() {
    this.wasReceiveItemsChange = false;
  }
}
