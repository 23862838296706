/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-acc.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../shared/components/svg-icon/svg-icon.component";
import * as i5 from "./table-acc.component";
var styles_TableAccComponent = [i0.styles];
var RenderType_TableAccComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableAccComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: "1", visibility: "visible" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { "max-height": "0px", opacity: "0", visibility: "hidden" }, offset: null }, options: undefined }, { type: 1, expr: "in => out", animation: [{ type: 3, steps: [{ type: 4, styles: { type: 6, styles: { opacity: "0" }, offset: null }, timings: "400ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { "max-height": "0px" }, offset: null }, timings: "600ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { visibility: "hidden" }, offset: null }, timings: "700ms ease-in-out" }], options: null }], options: null }, { type: 1, expr: "out => in", animation: [{ type: 3, steps: [{ type: 4, styles: { type: 6, styles: { visibility: "visible" }, offset: null }, timings: "1ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { "max-height": "1000px" }, offset: null }, timings: "600ms ease-in-out" }, { type: 4, styles: { type: 6, styles: { opacity: "1" }, offset: null }, timings: "800ms ease-in-out" }], options: null }], options: null }], options: {} }] } });
export { RenderType_TableAccComponent as RenderType_TableAccComponent };
function View_TableAccComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [["class", "table__body-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "table__body-cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "table__body-cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "table__body-cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [["class", "table__body-cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.number; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.status; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.type; _ck(_v, 8, 0, currVal_3); }); }
function View_TableAccComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "table", [["class", "table table--small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "thead", [["class", "table__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "tr", [["class", "table__body-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["class", "table__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Number"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "table__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["class", "table__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [["class", "table__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "tbody", [["class", "table__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableAccComponent_2)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.table; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_TableAccComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "table__acc-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAcc(_co.item.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "table__acc-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["icon", "arrow-forward"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(4, 114688, null, 0, i4.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "table__acc-head-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "table__acc-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "table__acc-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " items"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "table__acc-body"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableAccComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "arrow-forward"; _ck(_v, 4, 0, currVal_1); var currVal_5 = _co.table; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "table__acc-head ", (_co.item.expanded ? "active" : ""), ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.item.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.item.count; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.animationState; _ck(_v, 10, 0, currVal_4); }); }
export function View_TableAccComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-acc", [], null, null, null, View_TableAccComponent_0, RenderType_TableAccComponent)), i1.ɵdid(1, 114688, null, 0, i5.TableAccComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableAccComponentNgFactory = i1.ɵccf("app-table-acc", i5.TableAccComponent, View_TableAccComponent_Host_0, { item: "item", table: "table" }, { onAccToggle: "onAccToggle" }, []);
export { TableAccComponentNgFactory as TableAccComponentNgFactory };
