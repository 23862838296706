import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-settings-dropdown',
  templateUrl: './settings-dropdown.component.html'
})
export class SettingsDropdownComponent {
  @Input() optionList: [];
  @Input() displayRemoved: null | number = null;
  @Input() open = false;

  @Output() displayChange = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() {
  }

  onChange(data: number) {
    this.displayChange.emit(data);
  }

  onClickOutside(event: any) {
    if (event.value && this.open) {
      this.close.emit();
    }
  }
}
