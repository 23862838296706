<ngx-smart-modal #{{identifier}} [identifier]="identifier" [closable]="false" [dismissable]="dismissable">
  <div class="popup__box popup__confirm-remove {{extraClass}}">
    <div class="popup__head">
      <h3 class="popup__title">{{title}}</h3>
      <p *ngIf="!customBody" class="popup__text">{{text}}</p>
      <ng-content *ngIf="customBody"></ng-content>
    </div>
    <app-loader *ngIf="spinnerService.getDetailSpinner() | async"
      vertical="true"
    ></app-loader>
    <div class="checkbox" *ngIf="!!checkboxText">
      <input
        [checked]="checkboxState"
        class="checkbox__input"
        type="checkbox"
      >
      <label
        class="checkbox__label"
        [for]="checkboxState"
        (click)="toggleCheckbox()"
      >
        {{checkboxText}}
      </label>
    </div>
    <div class="popup__btn-row">
      <app-button (click)="onCancelButtonClick()" labelClass="btn--cancel" [class]="'btn btn--blank btn--cancel'" [label]="cancelButtonLabel"></app-button>
      <app-button *ngIf="!hideConfirmButton"
                  (click)="onConfirmButtonClick()" [class]="'btn btn--red'" [label]="confirmButtonLabel"></app-button>
    </div>
  </div>
</ngx-smart-modal>
