import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { rollbarFactory } from "./rollbar";
import { environment } from 'environments/environment';
const DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
export const options = {};
const ɵ0 = DEFAULT_SWIPER_CONFIG, ɵ1 = rollbarFactory, ɵ2 = environment.baseUrl;
export class AppModule {
    constructor(injector) {
        AppModule.injector = injector;
    }
}
// required for AOT compilation
// @ts-ignore
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1, ɵ2 };
