import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {MaterialItemsSelectionListResponseModel} from '@onbatch/core/services/Sales';
import {AuthService} from '@onbatch/core/services/auth.service';
import {BaseTable} from '@onbatch/shared/classes/base-table';
import {MultiSelectionDirective} from '../../multi-selection.directive';
import {MultiSelection} from '../../multi-selection';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PackagingMaterialsSelectItemsService} from '../../../../services/packaging-materials-select-items.service';
import {QuantityItem} from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';

@Component({
  selector: 'app-packaging-materials-manual-selection-table',
  templateUrl: './packaging-materials-manual-selection-table.component.html',
  styleUrls: ['./packaging-materials-manual-selection-table.component.scss'],
})
export class PackagingMaterialsManualSelectionTableComponent extends BaseTable implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(MultiSelectionDirective) checkboxes: QueryList<MultiSelectionDirective>;
  @Input() materials: MaterialItemsSelectionListResponseModel[];
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;
  @Output() remainingQuantityChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() initRemainingQuantityTaken: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() remainingQuantityExceeded: boolean;
  @Output() remainingQuantityExceededChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  checkboxList: MultiSelection<MultiSelectionDirective>;
  checkboxesCheckedItemsQuantitySubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  checkboxesCheckedItemsQuantity$: Observable<number> = this.checkboxesCheckedItemsQuantitySubject.asObservable();
  destroy$: Subject<boolean> = new Subject<boolean>();
  checkedArrayItems: Array<MaterialItemsSelectionListResponseModel> = [];

  constructor(public authService: AuthService,
              public packagingMaterialsSelectItemsService: PackagingMaterialsSelectItemsService) {
    super();
  }

  ngOnInit() {
    this.initRemainingQuantity = this.remainingQuantity;
    this.checkboxesCheckedItemsQuantity$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((length: number) => {
      this.remainingQuantityChange.emit(this.initRemainingQuantity - length);
      let quantityExceeded;
      quantityExceeded = (this.initRemainingQuantity - length < 0);
      this.remainingQuantityExceededChange.emit(quantityExceeded);
      this.packagingMaterialsSelectItemsService.setIsSubmitDisabled(quantityExceeded);
    });
  }

  ngAfterViewInit() {
    this.checkboxList = new MultiSelection<MultiSelectionDirective>(this.checkboxes);
    this.checkboxesChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkboxList = new MultiSelection<MultiSelectionDirective>(this.checkboxes);
    });
  }

  ngOnDestroy() {
    this.checkboxList.destroy();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkAllShippingItems(items: any[], checkIfItemInfinite: boolean = false): void {
    if (items.length) {
      this.checkedAll = !this.checkedAll;
    }
    if (!this.checkedAll) {
      if (this.checkedArrayItems.length === 0) {
        this.checkedArrayItems = [...this.checkedArrayItems, ...items.filter(item => !item.isBlocked)];
      } else {
        this.checkedArrayItems = [];
      }
    } else {
      if (checkIfItemInfinite) {
        this.checkedArrayItems = [...this.checkedArrayItems, ...items.filter(item => !item.isBlocked)];
      }
      this.checkedArrayItems = this.checkedArrayItems.filter((item: MaterialItemsSelectionListResponseModel, pos: number) => {
        return this.checkedArrayItems.indexOf(item) === pos;
      });
    }
    this.emitCheckboxesCheckedItemsQuantity();
    this.setSelectedQuantity();
  }

  checkItem(item: MaterialItemsSelectionListResponseModel): void {
    if (!this.checkedArrayItems.includes(item)) {
      this.checkedArrayItems = [...this.checkedArrayItems, item];
      this.checkedAll = true;
    } else {
      this.checkedArrayItems = this.checkedArrayItems.filter(el => el !== item);
      if (this.checkedArrayItems.length === 0) {
        this.checkedAll = false;
      }
    }
    this.emitCheckboxesCheckedItemsQuantity();
    this.setSelectedQuantity();
  }

  private countCheckedItemsAmounts(items: MaterialItemsSelectionListResponseModel[]): number {
    return items.length && items
      .map((item: MaterialItemsSelectionListResponseModel) => item.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue) || 0;
  }

  private takeInitRemainingQuantity(): number {
    this.initRemainingQuantityTaken.emit(true);
    return this.initRemainingQuantity;
  }

  private takeCheckedAmount(amount: number): number {
    this.initRemainingQuantityTaken.emit(false);
    return amount;
  }

  private emitCheckboxesCheckedItemsQuantity() {
    const amount = this.countCheckedItemsAmounts(this.checkedArrayItems);
    const quantityTaken = amount > this.remainingQuantity ? this.takeInitRemainingQuantity() : this.takeCheckedAmount(amount);
    this.checkboxesCheckedItemsQuantitySubject.next(quantityTaken);
  }

  private setSelectedQuantity() {
    const items: QuantityItem[] = this.checkedArrayItems.map((item: MaterialItemsSelectionListResponseModel) => ({
      lotId: item.lotId,
      quantity: item.quantity
    }) as QuantityItem);
    this.packagingMaterialsSelectItemsService.setSelectedItems(items);
  }

  get checkboxesChanges(): Observable<any> {
    return this.checkboxes.changes.pipe(
      takeUntil(this.destroy$)
    );
  }
}

