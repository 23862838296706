import { FilterRequestApplyModel, EquipmentModelTypeId, VesselStateTypeId, ExcludedIdsFilterRequestApplyModel } from '../../../core/services/Manufacturing';

export enum VesselSortOptions {
    Name = 'Name',
    HighestCapacity = 'Highest Capacity',
    LowestCapacity = 'Lowest Capacity'
}
export enum VesselTypeOptions {
    All = 'All',
    Empty = 'Empty',
    Filled = 'Filled',
    Active = 'Active'
}
export interface VesselLookupParams {
    filterRequest?: ExcludedIdsFilterRequestApplyModel | null | undefined;
    equipmentModelTypeId: EquipmentModelTypeId;
    facilityExternalId?: string | null | undefined;
    vesselStateTypeId?: VesselStateTypeId | null | undefined;
    batchExternalId?: string | null | undefined;
    processDate?: number | null | undefined;
    x_query?: string | null | undefined;
    x_order?: string | null | undefined;
    x_desc?: any | null | undefined;
    x_pageNumber?: any | null | undefined;
    x_pageSize?: any | null | undefined;
}
