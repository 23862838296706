import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unitOfMeasurementFormat' })
export class UnitOfMeasurementFormatPipe implements PipeTransform {
  transform(unit: string): any {
    switch (unit) {
      case 'Gallon':
      case 'Gallons': {
        return 'gal';
      }
      case 'Liter':
      case 'Liters': {
        return 'l';
      }
      case 'Pound': {
        return 'lb';
      }
      case 'Pounds':
      case 'pounds': {
        return 'lbs';
      }
      case 'Ounce':
      case 'Ounces': {
        return 'oz';
      }
      case 'Kilogram':
      case 'Kilograms': {
        return 'kg';
      }
      case 'Tonnes': {
        return 't';
      }
      case 'Gram':
      case 'Grams': {
        return 'g';
      }
      case 'PerMinute': {
        return 'per minute';
      }
      case 'PerHour': {
        return 'per hour';
      }
      case 'Second': {
        return 'sec';
      }
      case 'Minute': {
        return 'min';
      }
      case 'Hour': {
        return 'h';
      }
      case 'Day': {
        return 'days';
      }
      case 'Celsius': {
        return '°C';
      }
      case 'Fahrenheit': {
        return '°F';
      }
      case 'Proof': {
        return 'Proof';
      }
      case 'Proof Gallons': {
        return 'PG';
      }
      case 'Abv': {
        return 'ABV';
      }
      case 'ProofGallons': {
        return 'Proof gallons';
      }
      case 'Laa': {
        return 'LAA';
      }
      case 'PoundsPerGallon':
      case 'Pounds per gallon':
      case 'Pounds Per Gallon': {
        return 'lbs/gal';
      }
      case 'PoundsPerProofGallon':
      case 'Pounds per proof gallon':
      case 'Pounds Per Proof Gallon': {
        return 'lbs/pg';
      }
      case 'PoundsPerSpiritGallon':
      case 'Pounds per spirit gallon':
      case 'Pounds Per Spirit Gallon': {
        return 'lbs/sgal';
      }
      case 'Liquid Ounces': {
        return 'fl oz';
      }
      case 'Pounds per cubic foot':
      case 'Pound per cubic foot': {
        return 'lbs/ft³';
      }
      case 'Ounce per cubic foot': {
        return 'oz/ft³';
      }
      case 'Ounce per cubic inch': {
        return 'oz/in³';
      }
      case 'Pound per cubic inch': {
        return 'lbs/in³';
      }
      case 'Ounce per gallon': {
        return 'oz/lbs';
      }
      case 'Kilogram per cubic meter': {
        return 'kg/m³';
      }
      case 'Kilogram per cubic centimeter': {
        return 'kg/cm³';
      }
      case 'Kilogram per liter': {
        return 'kg/l';
      }
      case 'Gram per cubic meter': {
        return 'g/m³';
      }
      case 'Gram per cubic centimeter': {
        return 'g/cm³';
      }
      case 'Gram per milliliter': {
        return 'g/ml';
      }
      case 'Grams per liter':
      case 'Gram per liter': {
        return 'g/l';
      }
      case 'Milliliters': {
        return 'ml';
      }
      case 'Unitless': {
        return '';
      }
      case 'Unit':
      case 'Units': {
        return 'units';
      }
      default: {
        return unit;
      }
    }
  }
}
