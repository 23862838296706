import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hover-tooltip',
  templateUrl: './hover-tooltip.component.html'
})
export class HoverTooltipComponent {
  @Input() icon = 'help-tooltip';
  @Input() customIconClass: string;
  @Input() customArrowClass: string;
  @Input() customTooltipClass: string;
  @Input() text = 'Sample tooltip text';

  showTooltip = false;

  constructor() {
  }

  toggleTooltip(): void {
    this.showTooltip = !this.showTooltip;
  }
}
