import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import {
  EnumFilter,
  FilterOptions,
  FilterRequestApplyModel,
  FilterRequestModel,
  FilterRequestModelFilterTypeId,
  FilterResponseModel,
  FilterTypeId2,
  LookUpResponse,
  PaginatedResponseOfLookUpResponse,
  QuantityFilter,
  ValueFilter
} from '../core/services/Inventory';
import { AuthService } from '../core/services/auth.service';
import { FiltersService } from './filters.service';
import { FilterPositionModel, SaveFilterFormModel, SingleFilterToShowModel } from '../shared/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html'
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() activeFilters: any;
  @Input() allFields: FilterOptions[] = [];
  @Input() filtersOpened: boolean;
  @Input() filtersType: string;
  @Input() isFilterLoaded = false;
  @Input() customClass: string;

  @Output() applyFilters: EventEmitter<FilterRequestApplyModel[]> = new EventEmitter<FilterRequestApplyModel[]>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  activeFiltersToShow: SingleFilterToShowModel[][] = [];
  localActiveFilters: any;
  isFilterApplied = false;
  isSavePossible = false;
  templates: LookUpResponse[];
  templateListVisible = false;
  saveModal = false;
  renameModal = false;
  formSubmitStatus = true;
  saveFilterForm: FormGroup;
  renameFilterForm: FormGroup;
  saveFilterFormOptions: SaveFilterFormModel = {
    fieldType: 'input',
    label: 'Filter name',
    name: 'filterName',
    type: 'text',
    placeholder: 'Enter name',
    field: 'filterName'
  };
  templateToEdit: LookUpResponse;
  loadedFilter: FilterResponseModel;
  lastUsedFilter: FilterRequestApplyModel;
  isLastFilterLoaded = false;
  isApplyPossible = false;

  filtersOpenedSubscription$: Subscription;
  filtersLoadedSubscription$: Subscription;
  filtersIsLastFilterLoadedSubscription$: Subscription;
  filtersIsSavePossibleSubscription$: Subscription;
  filtersAppliedSubscription$: Subscription;
  filtersSavedFiltersSubscription$: Subscription;

  userExternalId: string = null;

  @HostBinding('style.width') private width = '100%';

  static compareFilters(
    filter1: ValueFilter | QuantityFilter | EnumFilter,
    filter2: ValueFilter | QuantityFilter | EnumFilter
  ): boolean {
    return (
      filter1.condition === filter2.condition &&
      filter1.value === filter2.value &&
      filter1.fieldName === filter2.fieldName &&
      filter1.orderIndex === filter2.orderIndex
    );
  }

  static compareIndex(index1: number, index2: number): number | boolean {
    if (index1 === index2) {
      return index2;
    } else {
      return false;
    }
  }

  static getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  constructor(
    protected toastr: ToastrService,
    protected filtersService: FiltersService,
    protected fb: FormBuilder,
    protected localStorage: LocalStorage,
    protected authService: AuthService
  ) {
    this.saveFilterForm = this.fb.group({
      filterName: new FormControl('', null)
    });
    this.renameFilterForm = this.fb.group({
      filterName: new FormControl('', null)
    });
    this.filtersOpenedSubscription$ = this.filtersService
      .getFiltersOpened()
      .subscribe((value: boolean) => (this.filtersOpened = value));
    this.filtersLoadedSubscription$ = this.filtersService
      .getFiltersLoaded()
      .subscribe((value: boolean) => (this.isFilterLoaded = value));
    this.filtersAppliedSubscription$ = this.filtersService
      .getFiltersApplied()
      .subscribe((value: boolean) => (this.isFilterApplied = value));
    this.filtersIsLastFilterLoadedSubscription$ = this.filtersService
      .getIsLastFilterLoaded()
      .subscribe((value: boolean) => (this.isLastFilterLoaded = value));
    this.filtersIsSavePossibleSubscription$ = this.filtersService
      .getIsSavePossible()
      .subscribe((value: boolean) => (this.isSavePossible = value));
    this.filtersSavedFiltersSubscription$ = this.filtersService
      .getSavedFilters()
      .pipe(skipWhile((value: PaginatedResponseOfLookUpResponse) => !value))
      .subscribe((value: PaginatedResponseOfLookUpResponse) => (this.templates = value.list));
  }

  ngOnInit(): void {
    this.userExternalId = FiltersComponent.getDecodedAccessToken(this.authService.currentUser.value.access_token).sub;
    this.getLastUsedFilter();
    this.initializeLocalActiveFilters();
    this.setFiltersTypes();
    this.getSavedFilters();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.filtersOpenedSubscription$.unsubscribe();
    this.filtersLoadedSubscription$.unsubscribe();
    this.filtersIsLastFilterLoadedSubscription$.unsubscribe();
    this.filtersIsSavePossibleSubscription$.unsubscribe();
    this.filtersSavedFiltersSubscription$.unsubscribe();
  }

  setFiltersTypes(): void {
    this.activeFiltersToShow = [];
    let itemIndex = 0;
    this.localActiveFilters.map((filtersSection: any, i: number) => {
      filtersSection.valueFilters.map((filter: any) => {
        filter.type = 'String';
        filter.sectionIndex = i;
        filter.orderIndex = itemIndex;
        itemIndex++;
      });
      filtersSection.quantityFilters.map((filter: any) => {
        filter.type = filter.isDate ? 'Date' : 'Int';
        filter.sectionIndex = i;
        filter.orderIndex = itemIndex;
        itemIndex++;
      });
      filtersSection.enumFilters.map((filter: any) => {
        filter.type = 'Enum';
        filter.sectionIndex = i;
        filter.orderIndex = itemIndex;
        itemIndex++;
      });
      this.activeFiltersToShow.push([
        ...filtersSection.valueFilters,
        ...filtersSection.quantityFilters,
        ...filtersSection.enumFilters
      ]);
    });
  }

  addNewRowInSection(sectionIndex: number): void {
    const section = this.activeFiltersToShow[sectionIndex];
    section.push({
      fieldName: null,
      condition: null,
      value: null,
      type: 'String',
      orderIndex: section.length
    });
  }

  removeRow(e: FilterPositionModel): void {
    const section = this.activeFiltersToShow[e.sectionIndex];
    if (section.length === 1) {
      if (e.sectionIndex !== this.activeFiltersToShow.length && this.activeFiltersToShow.length !== 1) {
        this.activeFiltersToShow.splice(e.sectionIndex, 1);
      }
    } else {
      section.splice(e.orderIndex, 1);
    }
    const activeFilterSection = this.localActiveFilters[e.sectionIndex];
    activeFilterSection.valueFilters = activeFilterSection.valueFilters.filter(
      (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
    );
    activeFilterSection.quantityFilters = activeFilterSection.quantityFilters.filter(
      (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
    );
    activeFilterSection.enumFilters = activeFilterSection.enumFilters.filter(
      (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
    );
    if (
      this.localActiveFilters[e.sectionIndex].valueFilters.length === 0 &&
      this.localActiveFilters[e.sectionIndex].quantityFilters.length === 0 &&
      this.localActiveFilters[e.sectionIndex].enumFilters.length === 0
    ) {
      this.localActiveFilters.splice(e.sectionIndex, 1);
      this.isSavePossible = false;
    }
  }

  changeRow(e: FilterPositionModel): void {
    const section = this.localActiveFilters[e.sectionIndex];
    if (section) {
      section.valueFilters = section.valueFilters.filter(
        (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
      );
      section.quantityFilters = section.quantityFilters.filter(
        (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
      );
      section.enumFilters = section.enumFilters.filter(
        (filter: SingleFilterToShowModel) => filter.orderIndex !== e.orderIndex
      );
      if (
        this.localActiveFilters[e.sectionIndex].valueFilters.length === 0 &&
        this.localActiveFilters[e.sectionIndex].quantityFilters.length === 0 &&
        this.localActiveFilters[e.sectionIndex].enumFilters.length === 0
      ) {
        this.localActiveFilters.splice(e.sectionIndex, 1);
      }
    }
  }

  addNewSection(): void {
    this.activeFiltersToShow.push([
      {
        fieldName: null,
        condition: null,
        value: null,
        sectionIndex: this.activeFiltersToShow.length,
        orderIndex: 0,
        type: 'String'
      }
    ]);
  }

  addFilter(filter: SingleFilterToShowModel): void {
    const newFilter: SingleFilterToShowModel = {
      condition: filter.condition,
      value: filter.value,
      label: filter.label,
      fieldName: filter.fieldName,
      orderIndex: filter.orderIndex,
      isDate: null
    };
    if (this.localActiveFilters.length <= filter.sectionIndex) {
      this.localActiveFilters.push({
        valueFilters: [],
        quantityFilters: [],
        enumFilters: [],
        // @ts-ignore
        index: this.localActiveFilters.length
      });
    }
    const section = this.localActiveFilters[filter.sectionIndex];
    if (section.valueFilters.length === 1 && section.valueFilters[0].fieldName === null) {
      section.valueFilters.splice(0, 1);
    }
    switch (filter.type) {
      case 'Enum': {
        // @ts-ignore
        this.checkFilter(new EnumFilter(newFilter), section.enumFilters);
        break;
      }
      case 'Int': {
        // @ts-ignore
        this.checkFilter(new QuantityFilter(newFilter), section.quantityFilters);
        break;
      }
      case 'Date': {
        newFilter.isDate = true;
        // @ts-ignore
        this.checkFilter(new QuantityFilter(newFilter), section.quantityFilters);
        break;
      }
      case 'String': {
        // @ts-ignore
        this.checkFilter(new ValueFilter(newFilter), section.valueFilters);
        break;
      }
    }
    this.filtersService.setIsSavePossible(true);
    this.isApplyPossible = true;
  }

  checkFilter(
    newFilter: EnumFilter | QuantityFilter | ValueFilter,
    section: (EnumFilter | QuantityFilter | ValueFilter)[]
  ): void {
    let isFilterNew = true;
    let isFilterUpdated = false;

    section.map(currentFilter => {
      if (FiltersComponent.compareFilters(currentFilter, newFilter)) {
        isFilterNew = false;
      }
      if (FiltersComponent.compareIndex(currentFilter.orderIndex, newFilter.orderIndex) !== false) {
        isFilterUpdated = true;
        const index = section.indexOf(currentFilter);
        section[index] = newFilter;
      }
    });
    if (isFilterNew && !isFilterUpdated) {
      section.push(newFilter);
    }
  }

  applyFilter(): void {
    if (this.isFilterLoaded || this.isLastFilterLoaded) {
      this.localActiveFilters.map((section: any, i: number) => {
        if (section.quantityFilters) {
          section.quantityFilters.map((quantityFilter: any, j: number) => {
            if (quantityFilter.isDate) {
              this.localActiveFilters[i].quantityFilters[j].value = quantityFilter.value / 1000;
            }
          });
        }
      });
    }
    this.filtersService.setFiltersAsOpened(false);
    this.filtersService.setIsSavePossible(true);
    this.applyFilters.emit(this.localActiveFilters);
    this.lastUsedFilter = this.localActiveFilters;
    this.templateListVisible = true;
    this.localStorage.setItemSubscribe(
      `${this.userExternalId}-last-used-filter-${this.filtersType}`,
      this.localActiveFilters
    );
  }

  discardFilter(): void {
    this.localActiveFilters = [
      {
        valueFilters: [
          {
            fieldName: null,
            condition: null,
            value: null
          }
        ],
        quantityFilters: [],
        enumFilters: [],
        index: 0
      }
    ];
    this.filtersService.setFiltersAsOpened(false);
    this.filtersService.setFiltersAsApplied(false);
    this.filtersService.setFiltersAsLoaded(false);
    this.filtersService.setIsSavePossible(false);
    this.filtersService.setFiltersClosedWithoutAppilation(true);
    this.setFiltersTypes();
    this.applyFilters.emit([]);
  }

  closeFilterBar(): void {
    this.close.emit();
    this.filtersService.setFiltersAsOpened(false);
    this.filtersService.setFiltersClosedWithoutAppilation(true);
    this.setLocalActiveFilters();
    this.setFiltersTypes();
  }

  getSavedFilters(): void {
    if (this.filtersType !== 'ActiveCompany' && this.filtersType !== 'PendingCompany') {
      this.filtersService.getAllFilters(FilterRequestModelFilterTypeId[this.filtersType], null, null, null, 1, 9999);
    } else {
      this.filtersService.getAllFiltersForAdmin(FilterTypeId2[this.filtersType], null, null, null, 1, 9999);
    }
  }

  toggleTemplateList(): void {
    this.templateListVisible = !this.templateListVisible;
  }

  closeTemplateList(event: { value: boolean }): void {
    if (event.value && this.templateListVisible) {
      this.templateListVisible = false;
    }
  }

  toggleSaveModal(): void {
    if (this.isSavePossible) {
      this.saveModal = !this.saveModal;
    }
  }

  saveFilter(): void {
    const filterToSave = new FilterRequestModel();
    filterToSave.filterTypeId = FilterRequestModelFilterTypeId[this.filtersType];
    filterToSave.filters = [];
    this.localActiveFilters.map((filterGroup, index) => {
      filterToSave.filters.push(new FilterRequestApplyModel(filterGroup));
      filterToSave.filters[index].valueFilters = [];
      filterToSave.filters[index].enumFilters = [];
      filterToSave.filters[index].quantityFilters = [];
      filterGroup.valueFilters.map(valueFilter => {
        filterToSave.filters[index].valueFilters.push(new ValueFilter(valueFilter));
      });
      filterGroup.enumFilters.map(enumFilter => {
        filterToSave.filters[index].enumFilters.push(new EnumFilter(enumFilter));
      });
      filterGroup.quantityFilters.map(quantityFilter => {
        filterToSave.filters[index].quantityFilters.push(new QuantityFilter(quantityFilter));
      });
    });
    filterToSave.name = this.saveFilterForm.get('filterName').value;
    this.filtersService
      .saveFilter(filterToSave)
      .then(() => (this.saveModal = false))
      .then(() => this.saveFilterForm.reset())
      .then(() => this.getSavedFilters())
      .then(() => this.toastr.success('Success!', 'Filter saved'));
  }

  toggleRenameModal(template: LookUpResponse): void {
    this.renameModal = !this.renameModal;
    this.renameFilterForm.get('filterName').setValue(template.name);
    this.templateToEdit = template;
  }

  renameFilter(): void {
    this.filtersService.getFilterById(this.templateToEdit.externalId).then((res: FilterResponseModel) => {
      const filterToUpdate: FilterRequestModel = new FilterRequestModel();
      filterToUpdate.filters = res.filters;
      filterToUpdate.name = this.renameFilterForm.get('filterName').value;
      filterToUpdate.filterTypeId = FilterRequestModelFilterTypeId[this.filtersType];
      this.filtersService
        .updateFilter(this.templateToEdit.externalId, filterToUpdate)
        .then(() => (this.renameModal = false))
        .then(() => this.renameFilterForm.reset())
        .then(() => this.getSavedFilters())
        .then(() => this.toastr.success('Success!', 'Filter updated'));
    });
  }

  deleteTemplate(externalId: string): void {
    this.filtersService
      .deleteFilter(externalId)
      .then(() => this.getSavedFilters())
      .then(() => this.toastr.success('Success!', 'Filter removed'));
  }

  loadFilter(externalId: string): void {
    this.filtersService
      .getFilterById(externalId)
      .then((res: FilterResponseModel) => {
        this.localActiveFilters = res.filters;
        this.localActiveFilters.map((section: any, i: number) => {
          if (section.quantityFilters) {
            section.quantityFilters.map((quantityFilter: any, j: number) => {
              if (quantityFilter.isDate) {
                this.localActiveFilters[i].quantityFilters[j].value = quantityFilter.value * 1000;
              }
            });
          }
        });
        this.filtersService.setFiltersAsLoaded(true);
        this.loadedFilter = res;
      })
      .then(() => {
        this.setFiltersTypes();
        this.isApplyPossible = true;
      });
  }

  updateFilter(): void {
    const filterToUpdate: FilterRequestModel = new FilterRequestModel();
    filterToUpdate.filters = [];
    this.localActiveFilters.map((filterGroup, index) => {
      filterToUpdate.filters.push(new FilterRequestApplyModel(filterGroup));
      // @ts-ignore
      filterToUpdate.filters[index].valueFilters = new ValueFilter(filterGroup.valueFilters);
      // @ts-ignore
      filterToUpdate.filters[index].valueFilters = new EnumFilter(filterGroup.enumFilters);
      // @ts-ignore
      filterToUpdate.filters[index].valueFilters = new QuantityFilter(filterGroup.quantityFilters);
    });
    filterToUpdate.name = this.loadedFilter.name;
    filterToUpdate.filterTypeId = FilterRequestModelFilterTypeId[this.filtersType];
    this.filtersService
      .updateFilter(this.loadedFilter.externalId, filterToUpdate)
      .then(() => this.getSavedFilters())
      .then(() => this.toastr.success('Success!', 'Filter updated'));
  }

  getLastUsedFilter(): void {
    this.localStorage
      .getItem(`${this.userExternalId}-last-used-filter-${this.filtersType}`)
      .toPromise()
      .then((val: FilterRequestApplyModel) => {
        if (val) {
          this.lastUsedFilter = val;
        }
      });
  }

  loadLastUsedFilter(): void {
    this.filtersService.setFiltersAsLoaded(false);
    this.filtersService.setIsLastFilterLoaded(true);
    this.isSavePossible = true;
    this.localActiveFilters = this.lastUsedFilter;
    this.setFiltersTypes();
    this.isApplyPossible = true;
  }

  private initializeLocalActiveFilters() {
    this.setLocalActiveFilters();

    if (this.localActiveFilters.length === 0) {
      this.localActiveFilters = [
        {
          valueFilters: [
            {
              fieldName: null,
              condition: null,
              value: null
            }
          ],
          quantityFilters: [],
          enumFilters: [],
          index: 0
        }
      ];
      this.filtersService.setFiltersAsApplied(false);
    } else {
      this.filtersService.setFiltersAsApplied(true);
      this.isApplyPossible = true;
    }
  }

  private setLocalActiveFilters() {
    this.localActiveFilters = cloneDeep(this.activeFilters);
  }
}
