import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Features2, NameExternalIdModel, UserSettingsResponseModel } from '../../core/services/Account';
import { Subscription } from 'rxjs';
import { FormBoxes, HeaderTabs } from '../models';
import { MixpanelService } from '../services/mixpanel.service';
import { MixPanelEvents } from '../constants';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() set title(value: string) {
    this._title = value;
    if (this.formGroup && this.formGroup.controls.title) {
      this.formGroup.controls.title.setValue(value);
    }
  }
  @Input() links: HeaderTabs[];
  @Input() set editableTitle(value: boolean) {
    if (!!value) {
      this.initForm();
    }
    this._editableTitle = value;
  }
  @Output() titleUpdated = new EventEmitter<string>();

  role: string;
  isAdmin: boolean;
  isMenuOpen = false;
  user: UserSettingsResponseModel;
  activeOrganization: NameExternalIdModel;
  currentUserInfo: any;
  isOwner = false;

  formGroup: FormGroup;
  formBoxes: FormBoxes = {
    title: {
      fieldType: 'input',
      name: 'title',
      type: 'text',
      field: 'title'
    }
  };

  isFeatureAvailable = new Features2({
    humanResources_EmployeeManagement: this.subscriptionService.getAccess(SubscriptionFeatures.HumanResourcesEmployeeManagement),
    misc_PersonalPreferences: this.subscriptionService.getAccess(SubscriptionFeatures.MiscPersonalPreferences),
    misc_AccountSettings: this.subscriptionService.getAccess(SubscriptionFeatures.MiscAccountSettings),
  });

  get title(): string {
    return this._title;
  }

  get editableTitle(): boolean {
    return this._editableTitle;
  }

  private currentUserSub: Subscription;
  private _title: string;
  private _editableTitle: boolean;

  constructor(
    private authService: AuthService,
    public modalService: NgxSmartModalService,
    private subscriptionService: SubscriptionService,
    private mixpanelService: MixpanelService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.currentUserSub = this.authService.currentUser.subscribe(_ => this.getUserInfo());
  }

  ngOnDestroy() {
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
    }
  }

  initForm() {
    this.formGroup = this.fb.group({
      title: new FormControl(this.title, Validators.required)
    });
  }

  getUserInfo(): void {
    const user = this.authService.getCurrentUser();
    this.user = user ? user.settings : null;
    this.isAdmin = this.authService.isAdmin.value;
    if (this.user) {
      this.role = this.authService.getClaims().Role;
      this.currentUserInfo = this.authService.getCurrentUser();
      this.isOwner = this.currentUserInfo.profile.IsOwner === 'True';
      this.activeOrganization = this.user.organizations.filter(
        org => org.externalId === this.authService.getCurrentOrganization()
      )[0];
      this.getUserRole();
    } else if (!this.isAdmin) {
      this.authService.completeAuthentication();
    }
  }

  getUserRole(): void {
    this.role = this.authService.getClaims().Role;
  }

  checkIfOrganizationActive(organizationExternalId: string): boolean {
    return this.authService.getCurrentOrganization() === organizationExternalId;
  }

  setActiveOrganization(organization: NameExternalIdModel): void {
    const current = this.authService.getCurrentOrganization();
    if (organization.externalId !== current) {
      this.mixpanelService.logout();
      AuthService.isMixPanelInitiated.next(false);
      const key = this.authService.getStorageKey('onbatch-organizationId');
      localStorage.setItem(key, organization.externalId);
      localStorage.removeItem('currentSubscription');
      window.location.href = '/welcome';
      this.authService.setCountry();
    }
  }

  logOut(): void {
    this.authService.logout();
    this.mixpanelService.track(MixPanelEvents.SIGN_OUT, {});
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  getHelpCenter(): void {    
    window.open("http://docs.onbatch.com", "_blank");
  }

  getFeedbackPortal(): void {
    window.open("https://feedback.onbatch.com/login", "_blank");
  }

  getEULA(): void {
    window.open("https://www.onbatch.com/home/eula", "_blank");
  }

  closeMenu(event: { value: boolean }): void {
    if (event.value) {
      this.isMenuOpen = false;
    }
  }

  setTitle(newTitle: string): void {
    this.titleUpdated.emit(newTitle);
  }
}
