import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SalesOrderItemResponseModel,
  SalesOrderItemResponseModelSalesOrderItemStatusTypeId as SalesOrderStatus,
} from '@onbatch/core/services/Sales';
import { AuthService } from '@onbatch/core/services/auth.service';
import { TransferInBondOutgoingItemResponseModelShippingStatus as TiBStatus} from '@onbatch/core/services/Warehousing';

@Component({
  selector: 'app-single-item-actions',
  templateUrl: './single-item-actions.component.html'
})
export class SingleItemActionsComponent {
  @Input() readonly = false;
  @Input() salesApproval = false;
  @Input() isTransferInBond = false;
  @Input() item: SalesOrderItemResponseModel;
  @Input() salesOrderExternalId: string;
  @Input() status: SalesOrderStatus | TiBStatus;

  @Output() deleteClicked = new EventEmitter();
  @Output() shippingConfirmed = new EventEmitter();

  readonly allStatuses = SalesOrderStatus;
  readonly allStatusesForTiB = TiBStatus;

  constructor(public authService: AuthService) {
  }

  onDeleteClicked() {
    this.deleteClicked.emit();
  }

  onShippingConfirmed() {
    this.shippingConfirmed.emit();
  }

  get isShippingForPickedItemsPossible(): boolean {
    return this.readonly
      && this.salesApproval
      && this.status !== this.allStatuses.FullyFulfilled
      && !!this.item
      && this.item.pickedItems
      && this.item.pickedItems.length
      && this.item.pickedItems.some(el => !el.wasUsed);
  }

  get isShippingPossible(): boolean {
    return this.readonly
      && (![this.allStatuses.FullyFulfilled, this.allStatuses.FullyPicked].includes(SalesOrderStatus[this.status])
      || ![this.allStatusesForTiB.FullyFulfilled, this.allStatusesForTiB.FullyPicked].includes(TiBStatus[this.status]))
      && !!this.item
      && (this.item.masterItem.hasQuantity || this.isTransferInBond);
  }

  get isDeletePossible(): boolean {
    return !this.readonly && this.status === this.allStatuses.Waiting;
  }
}
