import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  SalesOrderItemResponseModel,
  SellableMasterItemResponseModelMasterItemTypeId
} from '@onbatch/core/services/Sales';
import {FinishedGoodsSelectItemsService} from '../../services/finished-goods-select-items.service';
import {PackagingMaterialsSelectItemsService} from '../../services/packaging-materials-select-items.service';
import {QuantityItem} from '../../../sales/shared/components/sales-items/sales-items-interfaces';
import {SemiFinishedGoodsSelectItemsService} from '@onbatch/shared/services/semi-finished-goods-select-items.service';

@Component({
  selector: 'app-manual-selection',
  templateUrl: './manual-selection.component.html'
})
export class ManualSelectionComponent {
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;
  @Input() masterItemTypeId: SellableMasterItemResponseModelMasterItemTypeId;
  @Input() item: SalesOrderItemResponseModel;

  @Output() closeOverlayEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  SellableMasterItemResponseModelMasterItemTypeId = SellableMasterItemResponseModelMasterItemTypeId;
  destroy$: Subject<boolean> = new Subject<boolean>();

  get isMasterItemSFG(): boolean {
    return this.item && this.item.masterItem && this.item.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood;
  }

  constructor(
    private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
    private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService,
    private materialsSelectItemsService: PackagingMaterialsSelectItemsService
  ) {
  }

  closeOverlay(isCanceled?: boolean) {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (isCanceled) {
      if (this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood) {
        this.finishedGoodsSelectItemsService.clearSelectedRanges();
        this.finishedGoodsSelectItemsService.clearSelectedUnitIds();
        this.finishedGoodsSelectItemsService.clearShipping();
      }
      if (this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood) {
        const externalId = this.item && this.item.externalId;
        this.semiFinishedGoodsSelectItemsService.clearSelectedUnitIds();
        this.semiFinishedGoodsSelectItemsService.clearSemiFinishedGoodsShippingToSubmit(externalId);
      }
    }
    this.closeOverlayEvent.emit(true);
  }

  submit() {
    if (this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood) {
      this.finishedGoodsSelectItemsService.moveSelectedUnitIdsToSubmit();
      this.finishedGoodsSelectItemsService.moveSelectedRangesToSubmit();
        this.closeOverlay();
    } else if (this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.RawMaterial || this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.PackagingMaterial) {
      const selectedQuantities = this.materialsSelectItemsService.getSelectedItems();
      selectedQuantities.pipe(
        takeUntil(this.destroy$)
      ).subscribe((quantities: QuantityItem[]) => {
        this.closeOverlay();
        this.materialsSelectItemsService.setSelectedItemsToSubmit(quantities);
      });
    } else if (this.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood) {
      const externalId = this.item && this.item.externalId;
      this.semiFinishedGoodsSelectItemsService.moveSelectedUnitIdsToSubmit();
      this.semiFinishedGoodsSelectItemsService.moveSemiFinishedGoodsShippingToSubmitToSemiFinishedGoodsShipping(externalId);
      this.semiFinishedGoodsSelectItemsService.clearSemiFinishedGoodsShippingToSubmit(externalId);
      this.closeOverlay();
    }
  }

  get isSubmitButtonDisabled$(): Observable<boolean> {
    if (this.masterItemTypeId === this.SellableMasterItemResponseModelMasterItemTypeId.FinishedGood) {
      return this.finishedGoodsSelectItemsService.getIsSubmitDisabled();
    } else if (this.masterItemTypeId === this.SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood) {
      return this.semiFinishedGoodsSelectItemsService.getIsSubmitDisabled();
    } else {
      return this.materialsSelectItemsService.getIsSubmitDisabled();
    }
  }

}
