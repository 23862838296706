import { Component, Input } from '@angular/core';
import {
  InventoryHistoryLogListResponse,
  InventoryHistoryLogResponse,
} from '../../../core/services/Inventory';
import { WarehouseContentMasterItemHistoryListResponseModel } from '../../../core/services/Warehousing';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent {
  @Input() user: WarehouseContentMasterItemHistoryListResponseModel
    | InventoryHistoryLogListResponse
    | InventoryHistoryLogResponse;
}
