import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {
  SalesOrderShippedItemRequestModelShipmentItemSelectionCriteriaId as ShippedCriteriaId,
  ShippedItemRequestModel
} from '../../../../../core/services/Sales';
import {BaseShipForm} from '../base-ship-form';
import {debounce} from 'lodash';
import {ShippingService} from '../../../../services/shipping.service';
import {distinctUntilChanged, filter, map, skip, takeUntil} from 'rxjs/operators';
import {QuantityItem} from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import {PackagingMaterialsSelectItemsService} from '../../../../services/packaging-materials-select-items.service';
import { SettingsService } from 'app/account/settings/settings.service';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { Features2 } from '@onbatch/core/services/Account';

@Component({
  selector: 'app-packaging-materials',
  templateUrl: './packaging-materials.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagingMaterialsComponent extends BaseShipForm implements OnInit, OnDestroy {

  validateLotId = debounce((idx: number) => {
    this.lotIdChanged(idx);
  }, 200);

  validateCount = debounce(() => {
    this.countChanged();
  }, 200);

  isFeatureAvailable = new Features2({
    inventory_LotTraceability: this.subscriptionService.getAccess(SubscriptionFeatures.InventoryLotTraceability)
  });

  quantityOnHand: number = 0;

  constructor(private fb: FormBuilder,
              public shippingService: ShippingService,
              private cdr: ChangeDetectorRef,
              private packagingMaterialsSelectItemsService: PackagingMaterialsSelectItemsService,
              public settingsService: SettingsService,
             private subscriptionService: SubscriptionService,
  ) {
    super(shippingService, settingsService);
  }

  ngOnInit() {
    this.quantityOnHand = this.editMode ? this.item.masterItem.quantityOnHand + this.shippingDetails.quantity : this.item.masterItem.quantityOnHand;
    super.ngOnInit();
    this.shipmentTypeValueChanges$.subscribe(() => {
      for (let idx = 0; idx < this.validShipping.length; idx++) {
        this.removeFormArrayItem(idx);
      }
      this.form.get('shippedQuantity').setValue(null);
      this.emitCurrentShipping(this.form.get(`shipmentType-${this.item.externalId}`).value);
      this.cdr.markForCheck();
    });
    const selectedQuantities = this.packagingMaterialsSelectItemsService.getSelectedItemsToSubmit();
    selectedQuantities.pipe(
      skip(1),
      distinctUntilChanged(),
      map((items: QuantityItem[]) => items),
      filter(items => !!items.length),
      takeUntil(this.destroy$)
    ).subscribe((items: QuantityItem[]) => {
      items.forEach((item: QuantityItem) => {
        this.addLotId(item.lotId, item.quantity);
        const foundControl = this.lotIds.controls.find(control => control.value.lotId === item.lotId);
        const idx = this.unitIds.controls.indexOf(foundControl);
        this.updateQuantity(idx, item);
      });
      this.packagingMaterialsSelectItemsService.setSelectedItemsToSubmit([]);
    });
  }

  addLotId(lotId: string = '', quantity: number = 0, maxQuantity: number = 0) {
    this.lotIds.push(this.fb.group({
      lotId: new FormControl(lotId, Validators.required),
      shippedLotQuantity: new FormControl(quantity, Validators.required)
    }));
    this.shippedQuantity.push({ quantity: maxQuantity, isValid: !!quantity });
    if (!!quantity) {
      this.validShipping.push(new ShippedItemRequestModel({
        lotId,
        quantity
      }));
      this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
    } else {
      this.validShipping.push(null);
    }
  }

  initEditForm() {
    this.shippedQuantity = [];
    while (this.lotIds.length > 0) {
      this.lotIds.removeAt(0);
    }
    if (this.shipmentType.value === this.ShipmentType.ByLotId) {
      this.shippingDetails.details.forEach(shippingDetails => {
        this.addLotId(shippingDetails.lotId, shippingDetails.quantity, shippingDetails.maxLotIdQuantity);
      });
    } else if (this.shipmentType.value === this.ShipmentType.ByCount) {
      this.countChanged();
    }
  }

  initFormBoxes() {
    this.formBoxes = {
      lotId: {
        fieldType: 'input',
        name: 'lotId',
        field: 'lotId',
        placeholder: 'Lot ID'
      },
      shippedLotQuantity: {
        fieldType: 'input-with-mask',
        name: 'shippedLotQuantity',
        field: 'shippedLotQuantity',
        placeholder: '0 units',
        isInteger: true,
      },
      shippedQuantity: {
        fieldType: 'input-with-mask',
        name: 'shippedQuantity',
        label: `${this.salesApproval ? 'Picked' : 'Shipped'} quantity`,
        field: 'shippedQuantity',
        placeholder: '0 units',
        isInteger: true,
      },
    };
  }

  quantityChanged(idx: number): void {
    this.validShipping[idx] = null;

    const form = this.lotIds.controls[idx];
    const quantity = form.get('shippedLotQuantity');
    const lotId = form.get('lotId');

    quantity.setErrors(
      quantity.value
      && quantity.value <= this.getRemainingQuantity
      && quantity.value <= this.shippedQuantity[idx].quantity
        ? null
        : { 'incorrect': true }
    );
    this.formSubmitStatus = true;

    if (this.shippedQuantity[idx].isValid
      && quantity.value && quantity.value <= this.getRemainingQuantity
      && quantity.value <= this.shippedQuantity[idx].quantity) {
      this.validShipping[idx] = new ShippedItemRequestModel({
        quantity: quantity.value,
        lotId: lotId.value,
      });
    } else {
      this.validShipping[idx] = null;
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
  }

  countChanged(): void {
    this.validShipping = [null];

    const quantity = this.form.get('shippedQuantity');
    const quantityValue = +quantity.value;
    const remainingQuantity = this.getRemainingQuantity;
    const isQuantityAvailable = quantityValue <= remainingQuantity && quantityValue <= this.quantityOnHand;
    quantity.setErrors(isQuantityAvailable ? null : { 'incorrect': true });
    this.formSubmitStatus = true;
    if (quantityValue && quantityValue > 0 && isQuantityAvailable) {
      this.validShipping = [new ShippedItemRequestModel({
        quantity: quantity.value,
        unitOfMeasurementExternalId: this.item.masterItem.unitOfMeasurementExternalId
      })];
    } else {
      this.validShipping = [null];
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByCount);
  }

  removeFormArrayItem(idx: number, event?: Event) {
    super.removeFormArrayItem(idx, event);
    this.lotIds.removeAt(idx);
    this.validShipping.splice(idx, 1);

    if (this.shippedQuantity.length) {
      this.shippedQuantity.splice(idx, 1);
    }

    for (let index = 0; index < this.lotIds.length; index++) {
      this.lotIdChanged(index);
    }
  }

  private updateQuantity(idx: number, data: any) {
    this.shippedQuantity[idx] = {
      ...this.shippedQuantity[idx],
      quantity: data.length,
      isValid: true,
    };
    this.formSubmitStatus = true;
    this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
  }

}
