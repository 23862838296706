import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Material, MaterialStatisticsItem } from '../../models/materials.model';
import { UnitOfMeasurementFormatPipe } from '../../../../shared/pipes';
import { IMeasurementUnit } from '../../../../shared/interfaces/unit.interfaces';
import {
  BatchMaterialResponse,
  MeasurementResponse
} from '../../../../core/services/Manufacturing';
import { ClickOutsideData } from '../../../../shared/directives';
import { SpinnerService } from '@onbatch/core/services/spinner.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials-dropdown.component.html'
})
export class MaterialsDropdownComponent implements OnInit {

  @Input('materials') set materials(materials: (Material | BatchMaterialResponse)[]) {
    this._materials = materials;
    this.loadMaterialStatistics();
  }

  @Input() wipMaterials: BatchMaterialResponse[];
  @Input() isMaterialsVisible: boolean;
  @Input() title = `List of materials`;
  @Input() isArrowVisible = false;

  @Output() closeMaterials = new EventEmitter<boolean>();

  materialsStatistic: {
    FermentableMaterials: MaterialStatisticsItem[],
    OtherMaterials: MaterialStatisticsItem[],
    RawMaterial: MaterialStatisticsItem[],
    SemiFinishedGood: MaterialStatisticsItem[]
  };

  chartMaterials: MaterialStatisticsItem[];
  showRatio = false;

  private _materials: (Material | BatchMaterialResponse)[];
  get materials() {
    return this._materials;
  }

  constructor(
    public spinnerService: SpinnerService,
    private uomPipe: UnitOfMeasurementFormatPipe
  ) {
  }

  ngOnInit() {
    this.loadMaterialStatistics();
  }

  loadMaterialStatistics() {
    this.materialsStatistic = {
      FermentableMaterials: [],
      OtherMaterials: [],
      RawMaterial: [],
      SemiFinishedGood: []
    };
    if (this._materials) {
      this._materials.forEach(material => {
        if (material.isFermentable) {
          this.materialsStatistic.FermentableMaterials.push({
            name: material.name,
            uom: this.getMaterialQuantityUnit(material.amount),
            qty: material.amount ? material.amount.value : 0,
            pct: 0
          });
        } else if (!material.type) {
          this.materialsStatistic.OtherMaterials.push({
            name: material.name,
            uom: this.getMaterialQuantityUnit(material.amount),
            qty: material.amount ? material.amount.value : 0,
            pct: 0
          });
        } else {
          if (this.materialsStatistic[material.type]) {
            this.materialsStatistic[material.type].push({
              name: material.name,
              uom: this.getMaterialQuantityUnit(material.amount),
              qty: material.amount ? material.amount.value : 0,
              pct: 0
            });
          }
        }
      });
    }
    this.chart();
  }

  chart(): MaterialStatisticsItem[] {
    if (!this.materialsStatistic) {
      return [];
    }

    if (!this.materialsStatistic.FermentableMaterials) {
      return [];
    }

    // Group by UOM
    const groupedFermentableMaterials = this.materialsStatistic.FermentableMaterials.reduce((grouped, material) => {
      if (!grouped[material.uom]) {
        grouped[material.uom] = {};
      }

      if (!grouped[material.uom].materials) {
        grouped[material.uom].materials = [];
      }

      grouped[material.uom].materials.push(material);

      return grouped;
    }, {});

    this.showRatio = Object.keys(groupedFermentableMaterials).length === 1;
    if (this.showRatio) {
      const chartMaterials = this.materialsStatistic
        .FermentableMaterials
        .filter(material => material.qty > 0)
        .sort((a, b) => (a.qty < b.qty) ? 1 : -1);

      // Map out the percentage of the new list
      this.chartMaterials = chartMaterials.map(material => {
        return {
          name: material.name,
          uom: material.uom,
          qty: material.qty,
          pct: this.getMaterialPercentage(material, chartMaterials)
        };
      });

      const pctSum: number = this.chartMaterials.reduce((sum, m) => sum + m.pct, 0);
      const difference: number = 100 - pctSum;
      if (difference !== 0) {
        this.chartMaterials[this.chartMaterials.length - 1].pct += difference;
      }
    }
  }

  getMaterialQuantityUnit(quantity: (IMeasurementUnit | MeasurementResponse)): string {
    if (!quantity) {
      return '-';
    }

    if (quantity.unitOfMeasurementName) {
      return this.uomPipe.transform(quantity.unitOfMeasurementName);
    }

    return 'pcs';
  }

  getMaterialPercentage(material: MaterialStatisticsItem, materials: MaterialStatisticsItem[]): number {
    if (!material) {
      return 0;
    }

    const sumOfMaterialsQuantity = materials.reduce((sum, m) => sum + +m.qty, 0);
    return Math.round(material.qty * 100 / sumOfMaterialsQuantity);
  }

  onClickOutside(event: ClickOutsideData) {
    if (event.value && this.isMaterialsVisible) {
      this.closeMaterials.emit(false);
    }
  }

  onCloseClick() {
    this.closeMaterials.emit(false);
  }
}
