import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash';
import { LookupProps } from '@onbatch/shared/models';
import { defaultParams } from '@onbatch/shared/components/create-item/create-item-statics';
import { BatchesService } from '../../../../manufacturing/batches/shared/batches.service';
import {
  MaterialDepletionLookupResponseModel,
  PaginatedResponseOfMaterialDepletionLookupResponseModel
} from '@onbatch/core/services/Manufacturing';
import { SpinnerService } from '@onbatch/core/services/spinner.service';

@Component({
  selector: 'app-materials-depletion-modal',
  templateUrl: './materials-depletion-modal.component.html'
})
export class MaterialsDepletionModalComponent implements OnInit {

  @Input() selectedIds: string[] = [];
  @Output() itemSelected = new EventEmitter<MaterialDepletionLookupResponseModel>();

  itemResults: MaterialDepletionLookupResponseModel[] = [];
  paramsForItemsLookup: LookupProps = defaultParams;
  lookupItemsPages: number = null;

  searchTerm = '';

  getItems = debounce((value: string) => {
    if (value && value.length > 2 || !value || value.length === 0) {
      this.fetchMoreItems(value);
    }
  }, 200);

  constructor(
    public spinnerService: SpinnerService,
    private batchService: BatchesService
  ) {
  }

  ngOnInit(): void {
    this.lookupItems();
  }

  fetchMoreItems(value: string = null): void {
    this.paramsForItemsLookup.x_pageNumber++;
    if (value !== null && value !== this.paramsForItemsLookup.x_query) {
      this.paramsForItemsLookup.x_query = value;
      this.itemResults = [];
      this.paramsForItemsLookup.x_pageNumber = 1;
    }
    this.lookupItems();
  }

  lookupItems(): void {
    if (!this.lookupItemsPages || this.paramsForItemsLookup.x_pageNumber <= this.lookupItemsPages) {
      this.batchService.lookupMaterialsDepletionItems(
        this.selectedIds,
        this.paramsForItemsLookup.x_query,
        this.paramsForItemsLookup.x_order,
        this.paramsForItemsLookup.x_desc,
        this.paramsForItemsLookup.x_pageNumber,
        this.paramsForItemsLookup.x_pageSize
      ).subscribe((data: PaginatedResponseOfMaterialDepletionLookupResponseModel) => {
        this.itemResults = [...this.itemResults, ...data.list];
        this.lookupItemsPages = data.pageInfo.totalPages;
      });
    }
  }

  itemClicked(item: MaterialDepletionLookupResponseModel): void {
    this.itemSelected.emit(item);
  }

}
