import { EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SettingsListModel, SortSettingsModel } from '../models';

export abstract class BaseTable {

  @Input() filteredColumns: SettingsListModel[];
  @Input() mode: string;
  @Input() defaultSort: SortSettingsModel;
  @Input() isListEmpty = false;
  @Input() customClass = '';

  @Output() sortByColumn: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() checkedItemsListChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  checkedItems: Array<string> = [];
  checkedAll = false;
  selectedColumnName: string;
  lastMouseEvent: MouseEvent = null;

  @HostBinding('style.width') private width = '100%';

  checkColumn(name: string): boolean {
    if (!this.filteredColumns) {
      return true;
    }
    if (this.mode === 'full') {
      return this.filteredColumns.filter(column => column.name === name && column.status === true).length !== 0;
    } else {
      return true;
    }
  }

  sortTableByColumn(event: Event | any, columnName: string): void {
    document.querySelectorAll('.table__head-label').forEach(el => {
      el.classList.remove('active');
    });
    event.target.classList.add('active');
    if (this.selectedColumnName === columnName) {
      if (this.selectedColumnName === columnName) {
        const target = event.target.classList;
        target.value.indexOf('descending') === -1 ? target.add('descending') : target.remove('descending');
      }
    }
    this.selectedColumnName = columnName;
    this.sortByColumn.emit(columnName);
  }

  tableHeader(columnName: string): string {
    let className = '';
    if (this.defaultSort && this.defaultSort.columnName === columnName) {
      className = 'active';
      if (this.defaultSort.desc) {
        className = 'active descending';
      }
    }
    return className;
  }

  checkAllListItems(items: any[]): void {
    if (items.length) {
      this.checkedAll = !this.checkedAll;
    }
    if (!this.checkedAll) {
      if (this.checkedItems.length === 0) {
        items.forEach(item => {
          this.checkedItems.push(item.externalId);
        });
      } else {
        this.checkedItems = [];
      }
    } else {
      items.forEach(item => {
        this.checkedItems.push(item.externalId);
      });
      this.checkedItems = this.checkedItems.filter((item: string, pos: number) => {
        return this.checkedItems.indexOf(item) === pos;
      });
    }
    this.checkedItemsListChanged.emit(this.checkedItems);
  }

  checkExternalId(id: string): boolean {
    return this.checkedItems.includes(id);
  }

  checkbox(id: string): void {
    if (!this.checkedItems.includes(id)) {
      this.checkedItems.push(id);
      this.checkedAll = true;
    } else {
      this.checkedItems = this.checkedItems.filter(el => el !== id);
      if (this.checkedItems.length === 0) {
        this.checkedAll = false;
      }
    }
    this.checkedItemsListChanged.emit(this.checkedItems);
  }

  toggleContextMenu(e: MouseEvent, show: boolean): void {
    if (e) {
      const child = (<HTMLElement>e.target).childNodes[1] as HTMLElement;
      if (show) {
        child.style.display = 'block';
        this.lastMouseEvent = e;
      } else { 
        child.style.display = 'none';
        this.lastMouseEvent = null;
      }

      if (e.clientY > 480) {
        child.style.bottom = 'calc(50% + 10px)';
        child.style.top = 'auto';
        child.classList.add('options-menu--top');
      } else {
        child.style.bottom = 'auto';
        child.style.top = 'calc(50% + 10px)';
        child.classList.remove('options-menu--top');
      }
    }
  }
}
