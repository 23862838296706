import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { combineLatest, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { FormatsService } from '@onbatch/shared/services/formats.service';
import {
  ConsignorResponseModel,
  ConsignorSchemaResponseModelContractorSchemaTypeId,
  LookUpResponse,
  TransferInBondIncomingItemResponseModel,
  TransferInBondResponseModel,
  WarehousingContainerResponseModelContainerTypeId,
  WarehousingEquipmentLookUpResponseModel,
  WarehousingMasterItemResponseModelMasterItemTypeId
} from '@onbatch/core/services/Warehousing';
import { PaginatedResponseOfLookUpResponse } from '@onbatch/core/services/Inventory';
import { prepareIMaskSchema, prepareRegexpForSchema } from '@onbatch/shared/utils';
import { UnitOfMeasurementService } from '@onbatch/shared/services/unit-of-measurement-settings.service';
import { ManufacturingResponseModel } from '@onbatch/core/services/Account';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import IMask from 'imask';
import { prepareIdSchema } from '@onbatch/shared/components';
import {
  IncomingItemForList,
  MaxAmountDataForSFG
} from '@onbatch/shared/components/receive-items/receive-transfer.interface';
import { UnitOfMeasurementSettings } from '../../../../manufacturing/product-flows/product-flows-interfaces';
import { TransfersService } from '../../../../warehousing/transfers/transfers.service';
import { SettingsService } from '../../../../account/settings/settings.service';
import { NameExternalIdModel } from '@onbatch/core/services/Manufacturing';
import { UnitOfMeasurementFormatPipe } from '@onbatch/shared/pipes';
import * as moment from 'moment';

@Component({
  selector: 'app-add-incoming-item',
  templateUrl: './add-incoming-item.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class AddIncomingItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: IncomingItemForList;
  @Input() isOverlay: boolean;
  @Input() isPurchaseOrder: boolean;
  @Input() formSubmitStatus: boolean;
  @Input() remainingQuantity: number;

  @Input() set receiveDate(data: Date) {    
    if (data) {
      this.receiveDateCopy = data;
      if (!!this.formArray) {
        for (let index = 0; index < this.formArray.length; index++) {
          const dateFilled = this.formArray.controls[index].get('dateFilled');
          if (dateFilled) {
            dateFilled.setValidators([Validators.required,
              this.dateFilledValidator(this.receiveDateCopy)]);
            dateFilled.updateValueAndValidity();
          }
        }
      }
    }
  }

  @Input() set maxAmountValidationForSFG(data: Map<string, MaxAmountDataForSFG>) {
    data.forEach((item: MaxAmountDataForSFG) => {
      const itemData: MaxAmountDataForSFG = this.maxAmountValidationForSFGCopy.get(item.externalId);
      if (!!itemData && (itemData.amount !== item.amount || itemData.proof !== item.proof)) {
        const index: number = this.formArray.controls.filter((el: AbstractControl) => el.get('equipmentExternalId')).findIndex((el: AbstractControl) => el.get('equipmentExternalId').value === item.externalId);
        if (index !== -1) {
          const equipmentVolume: number = this.formArray.controls[index].get('equipment').value.volume;
          if (item.amount > equipmentVolume) {
            this.formArray.controls[index].get('amountPerUnit').setValidators([Validators.required, Validators.max(item.amount)]); // When condition is true then max value should always be exceeded
          } else {
            this.formArray.controls[index].get('amountPerUnit').setValidators([Validators.required, Validators.min(0.000001)]);
          }
          this.formArray.controls[index].updateValueAndValidity();
          this.formArray.controls[index].get('amountPerUnit').updateValueAndValidity();
        }
      }
    });
    this.maxAmountValidationForSFGCopy = new Map(data);
  }

  @Output() formChange = new EventEmitter<FormArray>();

  consignor: ConsignorResponseModel;
  date: moment.Moment;
  formArray: FormArray;
  form: FormGroup;
  formBoxes = {
    amount: {
      inputName: 'amountPerUnit',
      dropdownName: 'equipmentReceivedQuantityUnitOfMeasurementExternalId',
      placeholder: '0',
      label: 'Amount per Unit',
      selectItems: []
    },
    amountWithEquipment: {
      inputName: 'amountPerUnit',
      dropdownName: 'amountPerUnitUnitOfMeasurement',
      placeholder: '0',
      label: 'Amount per Unit',
      selectItems: []
    },
  };

  unitOfMeasurementOptions: LookUpResponse[] = [];

  schema: string;
  schemaRegex: string;
  schemaIMask: IMask.AnyMaskedOptions;

  patternForAmount = this.formatsService.patternForAmount();
  patternForAmountInteger = this.formatsService.patternForAmountInteger();

  excludedEquipment: string[] = [];
  maxAmountValidationForSFGCopy: Map<string, MaxAmountDataForSFG> = new Map();
  receiveDateCopy: Date;

  get mask(): string {
    return this.type === this.masterItemType.FinishedGood || (this.type === this.masterItemType.SemiFinishedGood && this.isPackaged) ?
      this.patternForAmountInteger.precision : this.patternForAmount.precision;
  }

  get suffixForProof(): string {
    return ' ' + this.formatsService.getAlcoholContentUoM(true);
  }

  get formattedSchema(): string {
    return this.schema ? prepareIdSchema(this.schema) : '';
  }

  get type(): WarehousingMasterItemResponseModelMasterItemTypeId {
    return this.item.masterItem.masterItemTypeId;
  }

  get isPackaged(): boolean {
    return !!(this.item.container && this.item.container.externalId);
  }

  readonly masterItemType = WarehousingMasterItemResponseModelMasterItemTypeId;
  readonly containerType = ConsignorSchemaResponseModelContractorSchemaTypeId;

  private unitOfMeasurementSettings: UnitOfMeasurementSettings;
  private manufacturingSettings: ManufacturingResponseModel;
  private subscription = new Subscription();

  constructor(private fb: FormBuilder,
              private formatsService: FormatsService,
              private transfersService: TransfersService,
              private settingsService: SettingsService,
              private unitOfMeasurementService: UnitOfMeasurementService,
              private uomPipe: UnitOfMeasurementFormatPipe,
  ) {
  }

  ngOnDestroy(): void {
    this.transfersService.clearExcludedEquimpmentIds();
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.transfersService.getSingleTransfer().subscribe((transfer: TransferInBondResponseModel) => {
        const observables: [
          Observable<UnitOfMeasurementSettings>,
          Observable<ManufacturingResponseModel>,
          Observable<ConsignorResponseModel>
        ] = [
            this.unitOfMeasurementService.getUnitOfMeasurementSettings(),
            this.settingsService.getManufacturingSettings(),
            this.transfersService.getConsignorDetails(transfer.consignor.externalId)
          ];
        combineLatest(observables).pipe(first())
          .subscribe(res => {
            this.unitOfMeasurementSettings = res[0];
            this.getContainersUnitOfMeasurement();
            this.manufacturingSettings = res[1];
            this.consignor = res[2];
          });
        this.initForm();
        this.unitOfMeasurementService.fetchUnitOfMeasurementSettings();
      })
    );
    this.subscription.add(
      this.transfersService.getExcludedEquimpmentIds().subscribe((ids: Set<string>) => this.excludedEquipment = [...ids])
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.remainingQuantity) {
      this.checkQuantity();
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      items: this.fb.array([])
    });
    this.subscription.add(
      this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.formChange.emit(this.formArray);
      })
    );
    this.formArray = this.form.get('items') as FormArray;

    if (this.item.masterItem.masterItemTypeId !== WarehousingMasterItemResponseModelMasterItemTypeId.SemiFinishedGood) {
      this.addUnits();
    }
  }

  createFormGroup() {
    switch (this.item.masterItem.masterItemTypeId) {
      case WarehousingMasterItemResponseModelMasterItemTypeId.FinishedGood: {
        const group: FormGroup = this.fb.group({
          alcoholContent: new FormControl(null,
            [Validators.required,
            Validators.min(0.000001),
            Validators.max(this.formatsService.getAlcoholContentUoM(true) === 'Proof' ? 200 : 100)]),
          useContractorSchema: new FormControl(false),
          receivedQuantity: new FormControl(null, [Validators.required, Validators.min(1)]),
          schema: new FormControl(null),
          unitId: new FormControl(null, Validators.required)
        });
        return group;
      }
      case WarehousingMasterItemResponseModelMasterItemTypeId.SemiFinishedGood: {
        const group: FormGroup = this.fb.group({
          useContractorSchema: new FormControl(false, Validators.required),
          equipment: new FormControl(null),
          equipmentExternalId: new FormControl(null),
          equipmentReceivedQuantityUnitOfMeasurementExternalId: new FormControl(null),
          receivedQuantity: new FormControl(null, Validators.required),
          schema: new FormControl(null),
          unitIdSchema: new FormControl(null),
          amountPerUnit: new FormControl(null, [
            Validators.required,
            Validators.min(0.000001),
          ]),
          alcoholContent: new FormControl(null,
            [Validators.required,
            Validators.min(0.000001),
            Validators.max(this.formatsService.getAlcoholContentUoM(true) === 'Proof' ? 200 : 100)]),
        });

        if (this.item.container) {
          group.addControl('alcoholContentUnitOfMeasurement', new FormControl(null));
          group.addControl('amountPerUnit', new FormControl(null, Validators.required));
          group.addControl('amountPerUnitUnitOfMeasurement', new FormControl(null, Validators.required));
          group.addControl('dateFilled', new FormControl(null,
            [Validators.required,
              this.dateFilledValidator(this.receiveDateCopy)]));
          group.addControl('unitId', new FormControl(null, Validators.required));
        }
        this.onConsigneeSchemaChange(group);
        return group;
      }
      default:
        throw new Error('Not supported masterItemTypeId');
    }
  }

  onConsigneeSchemaChange(group: FormGroup) {
    const addSchemeToControl = (state: boolean, type: ConsignorSchemaResponseModelContractorSchemaTypeId) => {
      const containerSchema = this.consignor.schemas.find(s => s.contractorSchemaTypeId === type);
      if (state && containerSchema) {
        group.get('unitIdSchema').patchValue(containerSchema.schema);
        group.get('schema').patchValue(prepareIMaskSchema(containerSchema.schema));
        this.schemaIMask = prepareIMaskSchema(containerSchema.schema);
        this.schemaRegex = prepareRegexpForSchema(containerSchema.schema);
        this.schema = containerSchema.schema;
      } else {
        group.get('unitIdSchema').patchValue(null);
        group.get('schema').patchValue(null);
        this.schema = null;
        this.schemaRegex = null;
        this.schemaIMask = null;
      }
      group.get('unitId').setValidators(state
        ? [Validators.required, Validators.pattern(new RegExp(this.schemaRegex))]
        : Validators.required);
      group.get('unitId').updateValueAndValidity();
    };
    this.subscription.add(
      group.get('useContractorSchema').valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(state => {
          if (this.item.container && this.item.container.containerTypeId) {
            const { containerTypeId } = this.item.container;
            switch (containerTypeId) {
              case WarehousingContainerResponseModelContainerTypeId.Barrel:
                addSchemeToControl(state, this.containerType.Barrel);
                break;
              case WarehousingContainerResponseModelContainerTypeId.Drum:
                addSchemeToControl(state, this.containerType.Drum);
                break;
              case WarehousingContainerResponseModelContainerTypeId.IBCTote:
                addSchemeToControl(state, this.containerType.IBC);
                break;
            }
          }
        })
    );
  }

  getUnitIdPlaceholder(i: number): string {
    let placeholder = 'Unit ID';
    const controls = this.getFormGroupAtIndex(i).controls;
    if (controls['useContractorSchema'].value && controls['unitIdSchema'].value) {
      placeholder = controls['unitIdSchema'].value;
    }
    return placeholder;
  }

  addUnits() {
    let newFormGroup = this.createFormGroup();
    if (this.formBoxes && this.formBoxes.amountWithEquipment && this.formBoxes.amountWithEquipment.selectItems) {
      const galItem = this.formBoxes.amountWithEquipment.selectItems.find(i => i.name.toLowerCase() === 'gal');
      if (galItem && galItem.externalId) {
        newFormGroup.controls.amountPerUnitUnitOfMeasurement.setValue(galItem.externalId);
      }
    }
    
    this.formArray.push(newFormGroup);
    this.formChange.emit(this.formArray);
  }

  addEquipment(equipment: WarehousingEquipmentLookUpResponseModel) {
    const group = this.createFormGroup();
    group.get('equipment').patchValue(equipment);
    // Set receivedQuantity to 1 because there can be only one equipment per line
    group.get('receivedQuantity').patchValue(1);
    group.get('equipmentExternalId').patchValue(equipment.externalId);
    group.get('equipmentReceivedQuantityUnitOfMeasurementExternalId').setValidators(Validators.required);
    group.get('equipmentReceivedQuantityUnitOfMeasurementExternalId').patchValue(equipment.unitOfMeasurementExternalId);
    this.formArray.push(group);
    this.transfersService.setExcludedEquimpmentIds(equipment.externalId);
    this.formChange.emit(this.formArray);
  }

  removeUnits($event: MouseEvent, i: number, excludeEquipment: boolean = false) {
    $event.stopPropagation();
    if (excludeEquipment) {
      this.transfersService.removeExcludedEquimpmentIds(this.formArray.controls[i].get('equipmentExternalId').value);
    }
    this.formArray.removeAt(i);
    this.formChange.emit(this.formArray);
  }

  prevent($event: MouseEvent) {
    $event.stopPropagation();
  }

  includeItem(equipment: FormGroup) {
    this.excludedEquipment = this.excludedEquipment.filter(item => item !== equipment.get('equipmentExternalId').value);
  }

  getFormGroupAtIndex(i: number) {
    return this.formArray.at(i) as FormGroup;
  }

  isInputInvalid(i: number, controlName: string): boolean {
    return this.formSubmitStatus && !!this.getFormGroupAtIndex(i).controls[controlName].errors;
  }

  isDateFilledIncorrect(i: number): boolean {
    return this.getFormGroupAtIndex(i) && this.getFormGroupAtIndex(i).controls['dateFilled'].value > this.receiveDateCopy;
  }

  checkQuantity() {
    this.setReceivedQuantityErrors(null);
    if (this.remainingQuantity < 0) {
      this.setReceivedQuantityErrors({ max: true });
    }
  }

  setReceivedQuantityErrors(error: ValidationErrors): void {
    if (this.formArray) {
      this.formArray.controls.forEach((group: FormGroup) => {
        if (!group.value.equipment && !!group.value.receivedQuantity) {
          group.controls['receivedQuantity'].setErrors(error);
        }
      });
    }
  }

  getContainersUnitOfMeasurement(): void {
    this.subscription.add(
      this.transfersService.lookupUnitsOfMeasurement()
        .subscribe((res: PaginatedResponseOfLookUpResponse) => {
          this.unitOfMeasurementOptions = res.pageInfo.pageNumber === 1
            ? res.list
            : [
              ...this.unitOfMeasurementOptions,
              ...res.list
            ];
          this.formBoxes.amount.selectItems = this.unitOfMeasurementOptions.map(unit => {
            return new NameExternalIdModel({
              name: this.uomPipe.transform(unit.name),
              externalId: unit.externalId
            });
          });
          
          this.formBoxes.amountWithEquipment.selectItems = this.formBoxes.amount.selectItems.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          });

        })
    );
  }

  dateFilledValidator(receiveDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.dirty && control.value) {
        return (control.value as Date) > receiveDate ? { errorMessage: 'Incorrect date' } : null;
        } else {
        return;
      }
    };
  }

  onCalendarChange(date: moment.Moment, i: number): void {
    this.getFormGroupAtIndex(i).controls.dateFilled.setValue(date);
  }
}
