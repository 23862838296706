<header class="header">
  <a class="header__logo" routerLink="/welcome">
    <img alt="Logo" src="assets/images/logo.png">
  </a>
  <h1 *ngIf="title && !editableTitle" class="header__title">{{title}}</h1>
  <div class="header__title header__title--edit-mode form__input-wrap">
    <app-form-box
      *ngIf="editableTitle"
      [formControlNameValue]="formBoxes.title.field"
      [formGroup]="formGroup"
      [options]="formBoxes.title"
      (onInputChange)="setTitle($event)"
    >
    </app-form-box>
  </div>
  <div *ngIf="links" class="header__tabs">
    <ng-container *ngIf="!links[0].subtitle && !links[0].status">
      <ng-container *ngFor="let tab of links">
        <a *ngIf="subscriptionService.getAccess(tab.feature)"
          class="header__title" routerLink="{{tab.link}}" routerLinkActive="active">
          {{tab.label}}
          <ng-container *ngIf="tab.notifications && tab.notifications > 0">
            <span class="header__tabs-number">{{tab.notifications}}</span>
          </ng-container>
        </a>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="links[0].subtitle">
      <div class="header__tabs">
        <p class="header__title" *ngFor="let header of links">
          {{header.label}}
          <span>{{header.subtitle}}</span>
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="links[0].status">
      <div class="header__tabs">
        <p class="header__title header__title--flex" *ngFor="let header of links">
          {{header.label}}
          <span class="order-status order-status--header-title {{header.statusClassName}}">
            <span class="order-status__indicator"></span>
            {{header.status | splitCamelCase}}
          </span>
        </p>
      </div>
    </ng-container>
  </div>
  <div class="header__utilities">
<!--    <form action="#" class="form header__search">-->
<!--    </form>-->
<!--    <app-button [class]="'btn btn&#45;&#45;only-icon header__search-btn'" [icon]="'search'"></app-button>-->
    <!--<div *ngIf="!isAdmin" class="feedback-widget header__utility">
      <figure class="header__utility-gfx">
        <svg-icon icon="bullhorn"></svg-icon>
      </figure>     
    </div>-->
<!--    <div class="header__utility">-->
<!--      <figure class="header__utility-gfx">-->
<!--        <svg-icon icon="notification"></svg-icon>-->
<!--      </figure>-->
<!--      <span class="header__utility-number">3</span>-->
<!--    </div>-->
    <div (clickOutside)="closeMenu($event)" click-outside>
      <div (click)="toggleMenu()" class="header__profile">
        <figure class="header__profile-gfx">
          <app-avatar *ngIf="user" [user]="user" placeholderClass="header__profile-gfx--no-image"></app-avatar>
          <div *ngIf="!user" class="header__profile-gfx--no-image"><p></p></div>
        </figure>
        <div *ngIf="isMenuOpen" class="options-menu table__options-menu">
          <div class="options-menu__arrow"></div>
          <div class="options-menu__box">
            <div *ngIf="!isAdmin"
                 class="options-menu__item options-menu__item--has-sub">
              <button class="btn btn--utility options-menu__option header__user-organization">
                <p class="header__user-name">{{activeOrganization.name}}</p>
                <p class="header__user-position">Switch companies</p>
              </button>
              <div class="options-menu__submenu header__user-organization-submenu">
                <div *ngFor="let organization of user?.organizations" class="options-menu__item">
                  <button (click)="setActiveOrganization(organization)"
                          [ngClass]="checkIfOrganizationActive(organization.externalId) ? 'header__user-organization--selected' : ''"
                          class="btn btn--utility options-menu__option header__user-organization-btn">
                    {{organization.name}}
                  </button>
                </div>
              </div>
            </div>
            <ng-container *ngIf="isOwner && isFeatureAvailable.misc_AccountSettings">
              <div class="header__user-button--divider"></div>
              <div class="options-menu__item">
                <button routerLink="/account/settings"
                        class="btn options-menu__option header__user-button">
                  <svg-icon icon="settings"></svg-icon>
                  Global settings
                </button>
              </div>
            </ng-container>
            <div *ngIf="!isAdmin && isFeatureAvailable.misc_PersonalPreferences"
                 class="options-menu__item">
              <button routerLink="/account/user-profile"
                      class="btn options-menu__option header__user-button">
                <svg-icon icon="user"></svg-icon>
                Personal preferences
              </button>
            </div>
            <ng-container *ngIf="isOwner">
              <div routerLink="/account/billing/info/" class="options-menu__item">
                <button class="btn options-menu__option header__user-button">
                  <svg-icon icon="credit-card"></svg-icon>
                  Billing
                </button>
              </div>
              <div routerLink="/account/companies/" class="options-menu__item">
                <button class="btn options-menu__option header__user-button">
                  <svg-icon icon="organizations"></svg-icon>
                  Business units
                </button>
              </div>
              <div *ngIf="isFeatureAvailable.humanResources_EmployeeManagement"
                   routerLink="/account/members/team/" class="options-menu__item">
                <button class="btn options-menu__option header__user-button">
                  <svg-icon icon="users"></svg-icon>
                  Users and roles
                </button>
              </div>
            </ng-container>
          </div>
          <div *ngIf="!isAdmin"
               class="header__user-button--divider"></div>
          <div (click)="getHelpCenter()" class="options-menu__item">
            <button class="btn options-menu__option header__user-button">
              User Documentation
            </button>
          </div>
          <div (click)="getFeedbackPortal()" class="options-menu__item">
            <button class="btn options-menu__option header__user-button">
              Feedback &amp; Updates
            </button>
          </div>
          <div (click)="getEULA()" class="options-menu__item">
            <button class="btn options-menu__option header__user-button">
              End-User Policy
            </button>
          </div>
          <div class="header__user-button--divider"></div>
          <div (click)="logOut()" class="options-menu__item header__user-button--last">
            <button class="btn options-menu__option header__user-button">
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
