import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-kit',
  templateUrl: './user-account.component.html'
})
export class UserAccountComponent {
  constructor() {
  }
}
