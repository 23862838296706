/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/click-outside.directive";
import * as i3 from "./settings-dropdown.component";
var styles_SettingsDropdownComponent = [];
var RenderType_SettingsDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SettingsDropdownComponent, data: {} });
export { RenderType_SettingsDropdownComponent as RenderType_SettingsDropdownComponent };
function View_SettingsDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "settings__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["class", "checkbox__input"], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "label", [["class", "checkbox__label settings__label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.status; var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_3); }); }
function View_SettingsDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "active": 0, "switch settings__switch": 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "switch__box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChange("removed") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "switch__handle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "switch__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Display archived items"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.displayRemoved, true); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SettingsDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "settings"], ["click-outside", ""]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.onClickOutside($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i2.ClickOutsideDirective, [i0.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "settings__arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "settings__text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Display columns of the table:"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "ul", [["class", "settings__list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SettingsDropdownComponent_1)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SettingsDropdownComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.optionList; _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.displayRemoved !== null); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_SettingsDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-settings-dropdown", [], null, null, null, View_SettingsDropdownComponent_0, RenderType_SettingsDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i3.SettingsDropdownComponent, [], null, null)], null, null); }
var SettingsDropdownComponentNgFactory = i0.ɵccf("app-settings-dropdown", i3.SettingsDropdownComponent, View_SettingsDropdownComponent_Host_0, { optionList: "optionList", displayRemoved: "displayRemoved", open: "open" }, { displayChange: "displayChange", close: "close" }, []);
export { SettingsDropdownComponentNgFactory as SettingsDropdownComponentNgFactory };
