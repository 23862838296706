import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'historyEventTitle' })
export class HistoryEventTitlePipe implements PipeTransform {
  transform(content: string): any {
    let title: string;
    switch (content) {

      // Purchase orders
      case 'PurchaseOrderCreated': {
        title = 'Order created';
        break;
      }
      case 'PurchaseOrderItemCreated': {
        title = 'Item added to order';
        break;
      }
      case 'PurchaseOrderItemDeleted': {
        title = 'Item removed from order';
        break;
      }
      case 'PurchaseOrderUpdated': {
        title = 'Information edited';
        break;
      }
      case 'PurchaseOrderReceiveItems': {
        title = 'Received';
        break;
      }
      case 'BillDeleted': {
        title = 'Bill deleted';
        break;
      }
      case 'PurchaseOrderVoided': {
        title = 'Order voided';
        break;
      }
      case 'BillCreated': {
        title = 'Bill created';
        break;
      }
      case 'BillUpdated': {
        title = 'Bill updated';
        break;
      }
      case 'BillItemCreated': {
        title = 'Item added to bill';
        break;
      }
      case 'BillItemUpdated': {
        title = 'Bill item updated';
        break;
      }
      case 'BillItemDeleted': {
        title = 'Bill item deleted';
        break;
      }
      case 'RefundCreated': {
        title = 'Refund created';
        break;
      }
      case 'RefundUpdated': {
        title = 'Refund updated';
        break;
      }
      case 'RefundItemCreated': {
        title = 'Item added to refund';
        break;
      }
      case 'RefundItemUpdated': {
        title = 'Refund item updated';
        break;
      }
      case 'PurchaseOrderClosed': {
        title = 'Order closed';
        break;
      }
      case 'PurchaseOrderBackOrderItems': {
        title = 'Put on back order';
        break;
      }
      case 'PurchaseOrderReturnsItems': {
        title = 'Returned';
        break;
      }

      // End of Purchase orders

      // Sales orders

      case 'SalesOrderCreated': {
        title = 'Sales order created';
        break;
      }
      case 'SalesOrderDeleted': {
        title = 'Sales order deleted';
        break;
      }
      case 'SalesOrderUpdated': {
        title = 'Sales order updated';
        break;
      }
      case 'ShipmentItemsUpdate': {
        title = 'Shipped item updated';
        break;
      }
      case 'ShipmentItemCreated': {
        title = 'Item shipped';
        break;
      }
      case 'ShipmentItemDeleted': {
        title = 'Shipped item removed';
        break;
      }
      case 'BillOfLadingCreated': {
        title = 'Bill of lading added to order';
        break;
      }
      case 'BillOfLadingDeleted': {
        title = 'Bill of lading removed';
        break;
      }
      case 'BillOfLadingUpdated': {
        title = 'Bill of lading updated';
        break;
      }
      case 'SalesOrderItemUpdated': {
        title = 'Item(s) in order updated';
        break;
      }
      case 'SalesOrderItemCreated': {
        title = 'Item(s) added to order';
        break;
      }
      case 'SalesOrderItemDeleted': {
        title = 'Item(s) removed from order';
        break;
      }
      case 'InvoiceItemUpdated': {
        title = 'Invoice updated';
        break;
      }
      case 'InvoiceItemCreated': {
        title = 'Invoice added to order';
        break;
      }
      case 'InvoiceItemDeleted': {
        title = 'Invoice removed from order';
        break;
      }
      case 'InvoiceAttached': {
        title = 'Invoice attached';
        break;
      }
      case 'InvoiceDetached': {
        title = 'Invoice detached';
        break;
      }
      case 'SalesOrderClosed': {
        title = 'Sales order closed';
        break;
      }
      case 'SalesOrderItemPicked': {
        title = 'Item picked';
        break;
      }
      case 'SalesOrderItemPickedUpdated': {
        title = 'Picked item updated';
        break;
      }
      case 'SalesOrderItemPickedDeleted': {
        title = 'Picked item removed';
        break;
      }
      case 'ShipmentFileAdded': {
        title = 'File added to shipment';
        break;
      }
      case 'ShipmentFileDeleted': {
        title = 'File removed from shipment';
        break;
      }
      case 'ShipmentCreated': {
        title = 'Shipment added to order';
        break;
      }
      case 'ShipmentDeleted': {
        title = 'Shipment removed from order';
        break;
      }
      case 'ShipmentUpdated': {
        title = 'Shipment updated';
        break;
      }
      case 'SalesOrderVoided': {
        title = 'Sales order voided';
        break;
      }

      // End of Sales orders

      // CRM Company

      case 'ContractorCreated': {
        title = 'Company created';
        break;
      }

      case 'ContractorUpdated': {
        title = 'Company updated';
        break;
      }

      case 'ContractorDeleted': {
        title = 'Company deleted';
        break;
      }

      case 'ContractorArchived': {
        title = 'Company archived';
        break;
      }

      case 'ContractorContactAssigned': {
        title = 'Contact assigned';
        break;
      }

      case 'ContractorContactUnassigned': {
        title = 'Contact unassigned';
        break;
      }

      case 'ContractorUsedExternally': {
        title = 'Company used externally';
        break;
      }

      case 'ContactMethodSetAsDefault': {
        title = 'Contact method set as default';
        break;
      }

      case 'AddressSetAsDefault': {
        title = 'Address set as default';
        break;
      }

      case 'ContactMethodDeleted': {
        title = 'Contact method deleted';
        break;
      }

      case 'AddressCreated': {
        title = 'Address added to company';
        break;
      }

      case 'AddressUpdated': {
        title = 'Address updated';
        break;
      }

      case 'AddressDeleted': {
        title = 'Address deleted';
        break;
      }

      case 'ContactMethodUpdated': {
        title = 'Contact method updated';
        break;
      }

      case 'ContractorReleasedExternally': {
        title = 'Company released externally';
        break;
      }

      case 'ContactMethodCreated': {
        title = 'Contact method created';
        break;
      }

      // End of CRM Company

      default: {
        title = 'Information edited';
      }
    }
    return title;
  }
}
