import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-header-search',
  templateUrl: './table-header-search.component.html'
})
export class TableHeaderSearchComponent implements OnInit {
  @Input() show = false;
  searchTerm = '';
  @Output() triggerNewTerm: EventEmitter<any> = new EventEmitter<any>();
  @Output() triggerSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  setQuery() {
    this.triggerNewTerm.emit(this.searchTerm);
  }

  searchSubmit() {
    this.triggerSearch.emit(this.searchTerm);
  }
}
