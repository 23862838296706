import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OfTypeOnly,
  WarehousingItemBatchesLookUpResponseModel,
  WarehousingMasterItemLookUpResponseModel
} from '../../core/services/Warehousing';
import { debounce } from 'lodash';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html'
})
export class AdvancedSearchComponent {

  @Input() items: WarehousingItemBatchesLookUpResponseModel[] | WarehousingMasterItemLookUpResponseModel[] = [];
  @Input() tabs: OfTypeOnly[] = [OfTypeOnly.FinishedGood, OfTypeOnly.SemiFinishedGood];
  @Input() activeTab = this.tabs[0];

  @Output() typeChanged: EventEmitter<OfTypeOnly> = new EventEmitter<OfTypeOnly>();
  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() addItemsClicked: EventEmitter<string[]> = new EventEmitter<string[]>();

  searchTerm = '';

  showFilters = false;

  checkedItems: string[] = [];
  checkedAll = false;

  searchSubmit = debounce(() => {
    if (this.searchTerm.length > 2 || this.searchTerm.length === 0) {
      this.searchSubmitted.emit(this.searchTerm);
    }
  }, 200);

  checkExternalId(id: string): boolean {
    return this.checkedItems.includes(id);
  }

  setTab(tab: OfTypeOnly): boolean {
    this.activeTab = tab;
    this.typeChanged.emit(this.activeTab);
    return false;
  }

  addItems(): void {
    this.addItemsClicked.emit(this.checkedItems);
  }

  checkbox(id: string): void {
    if (!this.checkedItems.includes(id)) {
      this.checkedItems.push(id);
      this.checkedAll = true;
    } else {
      this.checkedItems = this.checkedItems.filter(el => el !== id);
      if (this.checkedItems.length === 0) {
        this.checkedAll = false;
      }
    }
  }

  checkAllListItems(items: any[]): void {
    if (items.length) {
      this.checkedAll = !this.checkedAll;
    }
    if (!this.checkedAll) {
      if (this.checkedItems.length === 0) {
        items.map(item => {
          this.checkedItems.push(item.masterItemExternalId ? item.masterItemExternalId : item.externalId);
        });
      } else {
        this.checkedItems = [];
      }
    } else {
      items.map(item => {
        this.checkedItems.push(item.masterItemExternalId ? item.masterItemExternalId : item.externalId);
      });
      const _self = this;
      this.checkedItems = this.checkedItems.filter(function(item: string, pos: number) {
        return _self.checkedItems.indexOf(item) === pos;
      });
    }
  }

}
