<div class="table__acc-box">
  <div (click)="showAcc(item.id)" class="table__acc-head {{item.expanded ? 'active' : ''}}">
    <div class="table__acc-arrow">
      <svg-icon icon="arrow-forward"></svg-icon>
    </div>
    <div class="table__acc-head-text">
      <div class="table__acc-title">{{item.title}}</div>
      <span class="table__acc-count">{{item.count}} items</span>
    </div>
  </div>
  <div [@slideInOut]="animationState" class="table__acc-body">
    <table *ngIf="table" class="table table--small">
      <thead class="table__head">
      <tr class="table__body-row">
        <th class="table__head-label">Number</th>
        <th class="table__head-label">Name</th>
        <th class="table__head-label">Status</th>
        <th class="table__head-label">Type</th>
      </tr>
      </thead>
      <tbody class="table__body">
      <tr *ngFor="let item of table" class="table__body-row">
        <td class="table__body-cell">{{item.number}}</td>
        <td class="table__body-cell">{{item.name}}</td>
        <td class="table__body-cell">{{item.status}}</td>
        <td class="table__body-cell">{{item.type}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
