import { DomSanitizer } from '@angular/platform-browser';
import { EquipmentService } from '../../equipment/equipment.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, share } from 'rxjs/operators';
import { replaceAll } from '../../../shared/utils';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { EquipmentModelTypeId } from '@onbatch/core/services/Manufacturing';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "../../equipment/equipment.service";
import * as i3 from "@ngx-pwa/local-storage";
export class VesselLibraryService {
    constructor(sanitizer, equipmentService, localStorage) {
        this.sanitizer = sanitizer;
        this.equipmentService = equipmentService;
        this.localStorage = localStorage;
        this.imageCache = {};
        this.requestsInFlight = {};
        this.activeTabKey = 'select-vessel-active-tab';
    }
    static calcVesselFill(volume, capacity) {
        if (!capacity) {
            return -1;
        }
        if (volume === capacity) {
            return 0;
        }
        return 100 - (volume / capacity * 100);
    }
    getImageUrl(imageId, fillingPercent = null) {
        if (this.imageCache[imageId]) {
            const svg = this.imageCache[imageId];
            return of(this.prepareSvg(svg, fillingPercent));
        }
        return this.generateVesselLibraryImage(imageId).pipe(map((svg) => {
            if (!this.imageCache[imageId]) {
                this.imageCache[imageId] = svg;
            }
            return svg ? this.prepareSvg(svg, fillingPercent) : null;
        }));
    }
    saveActiveTab(tab) {
        this.localStorage.setItemSubscribe(this.activeTabKey, tab);
    }
    getActiveTab() {
        return this.localStorage.getItem(this.activeTabKey)
            .pipe(map((activeTab) => {
            if (VesselLibraryService.availableCategories.includes(activeTab)) {
                return activeTab;
            }
            return null;
        }));
    }
    generateVesselLibraryImage(imageId) {
        if (!imageId) {
            return of(null);
        }
        const fileReader$ = (blob) => Observable.create(obs => {
            if (!(blob instanceof Blob)) {
                obs.error(new Error('`blob` must be an instance of File or Blob.'));
                return;
            }
            const reader = new FileReader();
            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();
            return reader.readAsText(blob);
        });
        if (!(imageId in this.requestsInFlight)) {
            this.requestsInFlight[imageId] = this.equipmentService.getEquipmentImage(imageId).pipe(switchMap((file) => fileReader$(file.data)), tap(_ => delete this.requestsInFlight[imageId]), share());
        }
        return this.requestsInFlight[imageId];
    }
    prepareSvg(svg, fillingPercentage) {
        if (fillingPercentage !== undefined && fillingPercentage !== -1) {
            svg = replaceAll(svg, '0 50%, 100% 50%, 100% 100%, 0% 100%', `0 ${fillingPercentage}%, 100% ${fillingPercentage}%, 100% 100%, 0% 100%`);
        }
        if (fillingPercentage === -1) {
            svg = replaceAll(svg, '0 50%, 100% 50%, 100% 100%, 0% 100%', `0 100%, 100% 100%, 100% 100%, 0% 100%`);
        }
        const base64Data = btoa(svg);
        const url = `data:image/svg+xml;base64,${base64Data}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
VesselLibraryService.availableCategories = [
    EquipmentModelTypeId.MashTun,
    EquipmentModelTypeId.Fermenter,
    EquipmentModelTypeId.Still,
    EquipmentModelTypeId.SpiritsTank,
    EquipmentModelTypeId.Misc,
];
VesselLibraryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VesselLibraryService_Factory() { return new VesselLibraryService(i0.ɵɵinject(i1.DomSanitizer), i0.ɵɵinject(i2.EquipmentService), i0.ɵɵinject(i3.LocalStorage)); }, token: VesselLibraryService, providedIn: "root" });
