import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ClickOutsideData} from '@onbatch/shared/directives';

@Component({
  selector: 'app-header-action-buttons',
  templateUrl: './header-action-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderActionButtonsComponent {

  @Input() isEditModeActive: boolean;
  @Input() isDropdownEnabled: boolean;
  @Output() createAsDraftEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createAsOpenEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeItemEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  showCreateDropdown: boolean;

  closeItem() {
    this.closeItemEvent.emit(true);
  }

  create() {
    this.createEvent.emit(true);
  }

  createAsOpen() {
    this.createAsOpenEvent.emit(true);
  }

  createAsDraft() {
    this.createAsDraftEvent.emit(true);
  }

  clickedOutsideCreateButton(data: ClickOutsideData) {
    if (data.value) {
      this.showCreateDropdown = false;
    }
  }

  createCaretClicked() {
    this.showCreateDropdown = true;
  }
}
