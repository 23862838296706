import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBoxes } from '@onbatch/shared/models';
import {
  BillOfLadingRequestModel,
  BillOfLadingRequestModelBillToWhomTypeId,
  BillOfLadingRequestModelFreightTermsTypeId, SalesFacilityLookUpResponseModel
} from '@onbatch/core/services/Sales';
import { ShippingService } from '../../../services/shipping.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectOptionWithDisabled } from '@onbatch/shared/interfaces';
import { MixPanelEvents } from '@onbatch/shared/constants';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { checkIfClickedElementHasClass, checkIfDatePickerWasClicked } from '@onbatch/shared/utils';
import { ConfirmationModalService, ModalConfirmationState } from '@onbatch/shared/services/confirmation-modal.service';
import { skip, take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-bill-of-lading-modal',
  templateUrl: './bill-of-lading-modal.component.html'
})
export class BillOfLadingModalComponent implements OnInit {

  @Input() facilities: SalesFacilityLookUpResponseModel[];
  @Input() date: number = null;
  @Input() salesOrderExternalId: string;
  @Input() shipmentExternalId: string;

  @Output() billAdded = new EventEmitter();

  form: FormGroup;
  formSubmitStatus = false;
  showModal = false;
  isDateUpdated = false;
  modalLeftOffset: number;
  shipDate = moment();

  formBoxes: FormBoxes = {
    billToWhomTypeId: {
      fieldType: 'select',
      label: 'Bill to who?',
      placeholder: 'Select an option',
      field: 'billToWhomTypeId',
      noItemsText: 'No items',
      select: {
        items: Object.values(BillOfLadingRequestModelBillToWhomTypeId)
      },
    },
    freightTermsTypeId: {
      fieldType: 'select',
      label: 'Freight terms',
      placeholder: 'Select freight terms',
      field: 'freightTermsTypeId',
      noItemsText: 'No items',
      select: {
        items: Object.values(BillOfLadingRequestModelFreightTermsTypeId)
      },
    },
    carrierName: {
      fieldType: 'input',
      label: 'Carrier Name',
      name: 'carrierName',
      field: 'carrierName',
      placeholder: 'Carrier Name'
    },
    numbersOfPallets: {
      fieldType: 'input',
      label: 'Numbers of pallets',
      name: 'numbersOfPallets',
      field: 'numbersOfPallets',
      placeholder: 'Number of pallets'
    },
    carrierContactPhone: {
      fieldType: 'input',
      label: 'Carrier contact phone',
      name: 'carrierContactPhone',
      placeholder: '(027) 123-4567',
      field: 'carrierContactPhone'
    },
    facilityExternalId: {
      fieldType: 'custom_select',
      label: 'Facility shipped from',
      placeholder: 'Select facility',
      field: 'facilityExternalId',
      select: {
        items: []
      }
    },
    specialInstructions: {
      fieldType: 'textarea',
      label: 'Special instructions (Optional)',
      placeholder: 'Enter the instructions for staff',
      field: 'specialInstructions',
    },
  };

  constructor(private fb: FormBuilder,
              private shippingService: ShippingService,
              private toastr: ToastrService,
              private mixpanelService: MixpanelService,
              private confirmationModalService: ConfirmationModalService,
              private elem: ElementRef
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  reset(): void {
    this.isDateUpdated = false;
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      shipDate: new FormControl(this.date, Validators.required),
      facilityExternalId: new FormControl(null, Validators.required),
      carrierName: new FormControl('', Validators.required),
      carrierContactPhone: new FormControl(null, Validators.required),
      numbersOfPallets: new FormControl(null, Validators.required),
      freightTermsTypeId: new FormControl(null, Validators.required),
      billToWhomTypeId: new FormControl(null, Validators.required),
      specialInstructions: new FormControl(null)
    });

    this.getFacilities();

  }

  getFacilities(): void {
    if (!this.facilities) {
      this.shippingService.lookupFacilities()
        .subscribe((facilities => {
          this.facilities = facilities;
          this.setFacilities();
        }));
    } else {
      this.setFacilities();
    }
  }

  setFacilities(): void {
    this.formBoxes.facilityExternalId.select['items'] = this.facilities.map((item: SalesFacilityLookUpResponseModel) => {
      return <NgSelectOptionWithDisabled>{
        name: item.name, externalId: item.externalId, disabled: item.isBlocked
      };
    });
    if (this.facilities.length === 1) {
      this.form.get('facilityExternalId').setValue(this.facilities[1].externalId);
    }
  }

  submit(): void {
    const requestModel = new BillOfLadingRequestModel({
      shipDate: this.shipDate.unix(),
      facilityExternalId: this.form.get('facilityExternalId').value,
      carrierName: this.form.get('carrierName').value,
      carrierContactPhone: this.form.get('carrierContactPhone').value,
      numbersOfPallets: this.form.get('numbersOfPallets').value,
      freightTermsTypeId: this.form.get('freightTermsTypeId').value,
      billToWhomTypeId: this.form.get('billToWhomTypeId').value,
      specialInstructions: this.form.get('specialInstructions').value,
    });
    this.shippingService.putBillOfLading(this.salesOrderExternalId, this.shipmentExternalId, requestModel)
      .subscribe(() => {
        this.toggleModal();
        this.billAdded.emit();
        this.mixpanelService.track(MixPanelEvents.SALES_ORDERS_BILL_OF_LADING_GENERATED, {
          'Sales order external ID': this.salesOrderExternalId,
          'Shipment external ID': this.shipmentExternalId,
        });
        this.toastr.success('Bill of lading has been generated', 'Success!');
      });
  }

  toggleModal(e?: MouseEvent): void {
    this.showModal = !this.showModal;
    this.setModalPosition(e);
  }

  closeModal(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['btn-close']);
    if (event.value && !checkIfDatePickerWasClicked(event) && this.showModal && !isExcludedElementClicked) {
      if (this.form.dirty || this.isDateUpdated) {
        this.confirmationModalService.showConfirmationModal();
        this.confirmationModalService.getState().pipe(skip(1), take(1)).subscribe((modalState: ModalConfirmationState) => {
          this.showModal = !(modalState === ModalConfirmationState.confirmed);
          if (!this.showModal) {
            this.reset();
          }
        });
      } else {
        this.showModal = false;
        this.reset();
      }
    }
  }

  handleFormClick(event: Event) {
    event.stopPropagation();
  }

  setDate(date: moment.Moment) {
    this.isDateUpdated = true;
    this.shipDate = date;
  }

  get canConfirm(): boolean {
    return this.form.valid;
  }

  private setModalPosition(e: MouseEvent) {
    this.modalLeftOffset = null;
    if (this.showModal) {
      const button = e.composedPath().find((el) => el['className'] === 'sales-orders__add-items-wrapper packaging-process__items');
      const buttonOffsetLeft = button && button['offsetLeft'];
      const buttonWidth = button && button['clientWidth'];

      setTimeout(() => {
        const modal = this.elem.nativeElement.children
                   && this.elem.nativeElement.children[0]
                   && this.elem.nativeElement.children[0].children
                   && this.elem.nativeElement.children[0].children[1];
        const modalWidth = modal && modal.offsetWidth;
        if (button && modal && modalWidth > buttonWidth + buttonOffsetLeft) {
          this.modalLeftOffset = -buttonOffsetLeft;
        }
      });
    }
  }
}
