<div (clickOutside)="onClickOutside($event)" class="settings" click-outside>
  <div class="settings__arrow"></div>
  <p class="settings__text">Display columns of the table:</p>
  <ul class="settings__list">
    <li *ngFor="let item of optionList; index as i" class="settings__item">
      <div class="checkbox">
        <input (change)="onChange(i)" [checked]="item.status" [id]="item.name" class="checkbox__input" type="checkbox"/>
        <label [for]="item.name" class="checkbox__label settings__label">{{item.label}}</label>
      </div>
    </li>
  </ul>
  <div *ngIf="displayRemoved!==null" [ngClass]="{'active': displayRemoved, 'switch settings__switch': true}">
    <div (click)="onChange('removed')" class="switch__box">
      <div class="switch__handle"></div>
    </div>
    <span class="switch__label">Display archived items</span>
  </div>
</div>
