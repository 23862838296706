import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { flatten } from 'lodash';
import { Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { BaseList, changeTableHeight } from '@onbatch/shared/classes/base-list';
import { AuthService } from '@onbatch/core/services/auth.service';
import { HeaderParamsModel } from '@onbatch/shared/models';
import {
  PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel,
  SellableMasterItemResponseModel,
  PageInfo,
  SalesOrderItemResponseModel,
  FinishedGoodItemsSelectionListResponseModel,
  ShipmentLotsQuantityFinishedGoodResponseModel,
  ShipmentUnitIdsResponseModel,
  RemnantCasesSelectionListRequestModel
} from '@onbatch/core/services/Sales';
import { ShippingItemsDataTable, ShippingItemsParamsModel, RangeIndex, Range, RangeItem } from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import { FinishedGoodsSelectItemsService } from '../../../../services/finished-goods-select-items.service';
import { FiltersService } from '../../../../../filters/filters.service';
import { detectRanges } from '../../selected-items-modal/range-detector';
import { GroupPayload, GroupPayloadAction } from '../finished-goods-manual-selection-table/finished-goods-manual-selection-table.component';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { RangeRemoveData } from '../../selected-items-modal/selected-items-modal.component';

@Component({
  selector: 'app-finished-goods-manual-selection-list',
  templateUrl: './finished-goods-manual-selection-list.component.html',
  providers: [FiltersService]
})
export class FinishedGoodsManualSelectionListComponent extends BaseList implements OnInit, OnDestroy {
  @Input() customClass: string;
  @Input() item: SalesOrderItemResponseModel;
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;

  remainingQuantityExceeded = false;
  params: HeaderParamsModel = {
    x_query: undefined,
    x_order: 'unitId',
    x_desc: false,
    x_pageNumber: 1,
    x_pageSize: 10
  };

  masterItem: SellableMasterItemResponseModel;
  finishedGoods: PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel;
  dataTableSettings: ShippingItemsDataTable;

  checkedItems: string[] = [];
  checkedItemsMap: Map<string, Set<string>> = new Map();
  itemRangesMap: Map<string, Range<string>> = new Map();
  itemRangesLotIdMap: Map<string, RangeItem[]> = new Map();
  remainingItemsQuantity: number = 0;
  lotIdWithQuantity: ShipmentLotsQuantityFinishedGoodResponseModel[] = [];
  itemRanges: Range<string>[] = [];
  preSelectedItems$: Subscription;

  get masterItemExternalIdWithNumberOfUnits(): string {
    const numberOfUnits = this.item.masterItem.numberOfUnits;
    const masterItemExternalId = this.item.masterItem.externalId;
    return numberOfUnits ? masterItemExternalId + numberOfUnits : masterItemExternalId;
  }

  private checkedIndices: Map<string, Set<number>> = new Map();

  private subscriptions$: Subscription = new Subscription();
  private indexRanges: RangeItem[] = [];

  constructor(public authService: AuthService,
              public filtersService: FiltersService,
              public finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
              public subscriptionService: SubscriptionService) {
    super(filtersService, subscriptionService);
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.finishedGoodsSelectItemsService.getShippingLotsFinishedGoodByMasterItemExternalIdGet().subscribe(
      (response: Map<string, ShipmentLotsQuantityFinishedGoodResponseModel[]>) => {
        this.lotIdWithQuantity = response.get(this.masterItemExternalIdWithNumberOfUnits);
      })
    );
    this.finishedGoodsSelectItemsService.initSubmitedUnitIds();
    this.finishedGoodsSelectItemsService.initSubmitedSelectedRanges();
    this.remainingItemsQuantity = this.remainingQuantity;
    this.checkedItemsMap.set(this.masterItemExternalIdWithNumberOfUnits, new Set());
    this.finishedGoodsSelectItemsService.setMasterItem(this.item.masterItem);
    this.subscriptions$.add(combineLatest([
      this.finishedGoodsSelectItemsService.getFinishedGoodsPagination(),
      this.finishedGoodsSelectItemsService.getMasterItem(),
      this.finishedGoodsSelectItemsService.getFinishedGoodsDataTableParams(),
      this.finishedGoodsSelectItemsService.getSelectedUnitIds()
    ])
    .subscribe(([item, masterItem, settings, selectedUnitIds]: [
        PageInfo,
        SellableMasterItemResponseModel,
        ShippingItemsDataTable,
        Map<string, string[]>
      ]) => {
        this.pagination = item;
        this.masterItem = masterItem;
        this.dataTableSettings = settings;
        if (this.dataTableSettings.requestModel && this.dataTableSettings.requestModel.filter && this.activeFilters !== this.dataTableSettings.requestModel.filter) {
          this.afterFiltersApplied(this.dataTableSettings.requestModel.filter, false);
        }
        if (selectedUnitIds.has(this.masterItemExternalIdWithNumberOfUnits)) {
          this.checkedItems = selectedUnitIds.get(this.masterItemExternalIdWithNumberOfUnits);
        } else {
          this.checkedItems = [];
        }
    }));
    this.subscriptions$.add(this.finishedGoodsSelectItemsService.getListOfFinishedGoods()
      .subscribe((FGData: PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel) => {
        if (JSON.stringify(FGData) !== JSON.stringify(this.finishedGoods)) {
          this.afterFetchList(FGData);
        }
      }));
  }

  ngOnDestroy(): void {
    this.checkedIndices.clear();
    this.subscriptions$.unsubscribe();
    this.finishedGoodsSelectItemsService.finishedGoodsList.next(null);
    this.resetQueryAndPageNumberFromParams();
    this.setParams();
  }

  setQuery(data: string): void {
    this.params.x_query = data;
    this.searchSubmit();
  }

  setFilters(): void {
    if (this.masterItem.isRemnant) {
      this.finishedGoodsSelectItemsService.setRemnantCaseInfo(new RemnantCasesSelectionListRequestModel({
          filter: this.activeFilters,
          masterItemExternalId: this.masterItem.externalId,
          numberOfUnits: this.masterItem.numberOfUnits
        })
      );
    } else {
      this.finishedGoodsSelectItemsService.setRemnantCaseInfo(null);
    }
    this.finishedGoodsSelectItemsService.setFinishedGoodsDataTableParams({
      params: this.dataTableSettings.params,
      requestModel: {
        filter: this.activeFilters,
        masterItemExternalId: this.masterItem.externalId
      }
    });

    changeTableHeight();
  }

  searchSubmit(): void {
    this.finishedGoodsSelectItemsService.setParamsForFinishedGoods(this.params);
  }

  setParams(): void {
    this.finishedGoodsSelectItemsService.setFinishedGoodsDataTableParams({params: this.dataTableSettings.params});
  }

  setSort(params: ShippingItemsParamsModel): void {
    this.finishedGoodsSelectItemsService.setFinishedGoodsDataTableParams({
      sort: {
        columnName: params.x_order,
        desc: params.x_desc
      }
    });
  }

  resetQueryAndPageNumberFromParams(): void {
    this.dataTableSettings.params.x_pageNumber = 1;
    this.dataTableSettings.params.x_query = null;
  }

  afterFetchList(list: PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel): void {
    this.finishedGoods = list;
    this.preselectItems();
    this.listLoaded = true;
  }

  preselectItems() {
    this.subscriptions$.add(combineLatest([
      this.finishedGoodsSelectItemsService.getSelectedUnitIdsToSubmit(),
      this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit(),
      this.finishedGoodsSelectItemsService.getSelectedUnitIdsData(),
    ]).pipe(
      take(1)).subscribe(
      ([itemsMap, rangeItemsMap, unitIdNumbersMap]: [Map<string, string[]>, Map<string, RangeItem[]>, Map<string, Map<string, ShipmentUnitIdsResponseModel[]>>]) => {
      if (rangeItemsMap.has(this.masterItemExternalIdWithNumberOfUnits) && itemsMap.has(this.masterItemExternalIdWithNumberOfUnits)) {
        let uniqueIds: Set<string> = new Set();
        const rangeMap: RangeItem[] = rangeItemsMap.get(this.masterItemExternalIdWithNumberOfUnits) || [];
        if (rangeMap.length) {
          rangeMap.forEach((range: RangeItem) => {
            this.itemRangesMap.set(range.start, range);
            const unitsIdsAndUnitIdsOrderNumbers: ShipmentUnitIdsResponseModel[] =
              unitIdNumbersMap.get(this.masterItemExternalIdWithNumberOfUnits).get(range.lotId);
            const unitIdNumbersSet: number[] = range.items.map((unitId: string) =>
              unitsIdsAndUnitIdsOrderNumbers.find((item: ShipmentUnitIdsResponseModel) => item.uniId === unitId).unitIdNumber )
              const checkedIndeces: Set<number> = this.getSetOfCheckedIndices(range.lotId);
            if (checkedIndeces) {
              unitIdNumbersSet.forEach((el: number) => checkedIndeces.add(el));
              this.setCheckedIndices(range.lotId, checkedIndeces);
            } else {
              this.setCheckedIndices(range.lotId, new Set(unitIdNumbersSet));
            }

            const itemRangesLotIdArray: RangeItem[] = this.itemRangesLotIdMap.get(range.lotId) || [range];
            const isItemRangeAdded: RangeItem =
              itemRangesLotIdArray.find((rangeByLotId: RangeItem) => rangeByLotId.start === range.start && rangeByLotId.end === range.end);
            if (!isItemRangeAdded) {
              itemRangesLotIdArray.push(range);
            }
            this.itemRangesLotIdMap.set(range.lotId, itemRangesLotIdArray);
            this.itemRanges = flatten([...this.itemRangesLotIdMap.values()]);

            range.items.forEach((item: string) => {
              uniqueIds.add(item);
              this.checkedItemsMap.get(this.masterItemExternalIdWithNumberOfUnits).add(item);
            });
          });
          Array.from(uniqueIds).forEach((item: string) => this.getSetOfCheckedItemsMap().add(item));
        }
      }
    }));
  }

  setSettings(): void {
    this.finishedGoodsSelectItemsService.setFinishedGoodsDataTableParams({settings: this.dataTableSettings.settings});
  }

  getUnitIdDataForLotId(lotId: string) {
    let unitIdsData: ShipmentUnitIdsResponseModel[] = [];
    this.finishedGoodsSelectItemsService.getSelectedUnitIdsData().pipe(take(1)).subscribe((data) => {
      if (data && data.has(this.masterItemExternalIdWithNumberOfUnits)) {
        unitIdsData = data.get(this.masterItemExternalIdWithNumberOfUnits).get(lotId);
      }
    });
    return unitIdsData;
  }

  onItemToggled(item: FinishedGoodItemsSelectionListResponseModel, shouldCheckedAll?: boolean) {
    if (!this.checkedIndices.has(item.lotId)) {
      this.checkedIndices.set(item.lotId, new Set());
    }
    const checkedIndicesSet: Set<number> = this.getSetOfCheckedIndices(item.lotId);
    const removeItem = () => {
      this.checkedItemsMap.get(this.masterItemExternalIdWithNumberOfUnits).delete(item.unitId);
      checkedIndicesSet.delete(item.unitIdNumber);
      this.itemRangesMap.delete(item.unitId);
    }
    const addItem = () => {
      this.checkedItemsMap.get(this.masterItemExternalIdWithNumberOfUnits).add(item.unitId);
      checkedIndicesSet.add(item.unitIdNumber);
      this.finishedGoodsSelectItemsService.setSelectedUnitIdsData(
        this.masterItemExternalIdWithNumberOfUnits, item.lotId, [{ uniId: item.unitId, unitIdNumber: item.unitIdNumber}] as ShipmentUnitIdsResponseModel[]);
    }

    if (shouldCheckedAll) {
      if (!this.getSetOfCheckedItemsMap().has(item.unitId)) {
        addItem();
      }
    } else {
      if (this.getSetOfCheckedItemsMap().has(item.unitId)) {
        removeItem();
      } else {
        addItem();
      }
    }

    this.setCheckedIndices(item.lotId, checkedIndicesSet);

    const newRanges: RangeItem[] = this.createRangesItem(detectRanges(Array.from(this.checkedIndices.get(item.lotId)), item.lotId), item.lotId, this.getUnitIdDataForLotId(item.lotId));
    this.itemRangesLotIdMap.set(item.lotId, newRanges);
    this.itemRanges = flatten([...this.itemRangesLotIdMap.values()]);
    this.updateItemRanges();
  }

  createRangesItem(rangeIndex: RangeIndex[], lotId: string, unitIdsData?: ShipmentUnitIdsResponseModel[]): RangeItem[] {
    const mapFromFGBylotId: Map<string, Map<number, string>> = new Map();
    this.finishedGoods.list.forEach((item) => {
      if (mapFromFGBylotId.has(item.lotId)) {
        const newData: Map<number, string> = mapFromFGBylotId.get(item.lotId);
        newData.set(item.unitIdNumber, item.unitId);
        mapFromFGBylotId.set(item.lotId, newData);
      } else {
        const newData: Map<number, string> = new Map();
        newData.set(item.unitIdNumber, item.unitId);
        mapFromFGBylotId.set(item.lotId, newData);
      }
    });

    unitIdsData.forEach((el: ShipmentUnitIdsResponseModel) => mapFromFGBylotId.get(lotId).set(el.unitIdNumber, el.uniId));

    const itemOrderId: Set<number> = new Set();
    const filteredRangeIndex: RangeIndex[] = rangeIndex.map((range: RangeIndex) => {
      if (!itemOrderId.has(range.start)) {
        range.items.forEach((unitIdNumber: number) => itemOrderId.add(unitIdNumber));
        return range;
      }
    });

    return filteredRangeIndex.map((range: RangeIndex) => {
      const items: string[] = range.items.map((item: number) => mapFromFGBylotId.get(range.lotId).get(item));
      const start: string = mapFromFGBylotId.get(range.lotId).get(range.start)
      const end: string = mapFromFGBylotId.get(range.lotId).get(range.end)

        return {
          start,
          end,
          lotId: range.lotId,
          items,
          length: range.length
        }
    });
  }

  getSetOfCheckedIndices(lotId: string): Set<number> {
    return this.checkedIndices.get(lotId);
  }

  setCheckedIndices(lotId: string, checkedIndecesSet: Set<number>): void {
    this.checkedIndices.set(lotId, checkedIndecesSet);
  }

  onGroupClicked(payload: GroupPayload) {
    const {action, lotId, unitIds} = payload;
    this.finishedGoodsSelectItemsService.setSelectedUnitIdsData(this.masterItemExternalIdWithNumberOfUnits, lotId, unitIds);

    const unitIdsAll: string[] = unitIds.map((element: ShipmentUnitIdsResponseModel) => element.uniId);
    const unitIdNumbersAll: number[] = unitIds.map((element: ShipmentUnitIdsResponseModel) => element.unitIdNumber);
    const setOfCheckedItems: Set<string> = this.getSetOfCheckedItemsMap();
    if (!this.checkedIndices.has(lotId)) {
      this.checkedIndices.set(lotId, new Set());
    }

    if (action === GroupPayloadAction.SELECT) {
      unitIdNumbersAll.forEach((unitIdNumber: number) => {
        const checkedIndicesSet: Set<number> = this.getSetOfCheckedIndices(lotId);
        checkedIndicesSet.add(unitIdNumber);
        this.setCheckedIndices(lotId, checkedIndicesSet);
      });
      unitIdsAll.forEach((unitId: string) => setOfCheckedItems.add(unitId));
    } else {
      unitIdNumbersAll.forEach((unitIdNumber: number) => {
        const checkedIndicesSet: Set<number> = this.getSetOfCheckedIndices(lotId);
        checkedIndicesSet.delete(unitIdNumber);
        this.setCheckedIndices(lotId, checkedIndicesSet);
      });
      unitIdsAll.forEach((unitId: string) => {
        setOfCheckedItems.delete(unitId)
      });
      this.itemRangesMap.delete(unitIds[0].uniId);
    }
    this.checkedItemsMap.set(this.masterItemExternalIdWithNumberOfUnits, setOfCheckedItems);
    this.itemRangesLotIdMap.set(lotId, this.createRangesItem(detectRanges(Array.from(this.checkedIndices.get(lotId)), lotId), lotId, this.getUnitIdDataForLotId(lotId)));
    this.itemRanges = flatten([...this.itemRangesLotIdMap.values()]);
    this.updateItemRanges();
  }

  onRangeRemoved(rangeData: RangeRemoveData) {
    const { index, range } = rangeData;

    const idxRange: number = this.itemRangesLotIdMap.get(range.lotId)
      .findIndex((el: RangeItem) => el.start === range.start && el.end === range.end);
    this.itemRangesLotIdMap.get(range.lotId).splice(idxRange, 1);

    range.items.forEach((unitID: string) => {
      this.getSetOfCheckedItemsMap().delete(unitID);
      const unitIdsWithUnitNumberByLotId: ShipmentUnitIdsResponseModel[] = this.getUnitIdDataForLotId(range.lotId) || [];
      if (unitIdsWithUnitNumberByLotId.length) {
        const element: ShipmentUnitIdsResponseModel =
          unitIdsWithUnitNumberByLotId.find((itemData: ShipmentUnitIdsResponseModel) => itemData.uniId === unitID);
        if (element) {
          this.checkedIndices.get(range.lotId).delete(element.unitIdNumber);
          this.getSetOfCheckedIndices(range.lotId).delete(element.unitIdNumber);
        }
      }
    });
    this.itemRangesMap.delete(range.start);
    this.itemRanges.splice(index,1);
    this.updateItemRanges();
  }

  clearItemsOnCurrentPage(itemRanges: Range<string>[], itemRangesMap: Map<string, Range<string>>) {
    if (!!itemRanges.length) {
      itemRanges.forEach((range: Range<string>) => range.items.forEach((item: string) => {
        if (!!item && itemRangesMap.has(item)) {
          itemRangesMap.delete(item);
        }
      }));
    } else {
      itemRangesMap.clear();
    }
  }

  getSetOfCheckedItemsMap(): Set<string> {
    return this.checkedItemsMap.get(this.masterItemExternalIdWithNumberOfUnits);
  }

  changeRemainQuantity(quantity: number): void {
    this.remainingItemsQuantity = quantity;
  }

  checkedItemsListChanged(shouldCheckedAll: boolean) {
    this.finishedGoods.list.forEach((item: FinishedGoodItemsSelectionListResponseModel) => this.onItemToggled(item, shouldCheckedAll));
  }

  get getCheckedIndices() {
    return Array.from(this.checkedIndices.values()).map(el => Array.from(el));
  }

  private updateItemRanges(preselect?: boolean) {
    this.checkedItems = Array.from(this.checkedItemsMap.get(this.masterItemExternalIdWithNumberOfUnits));
    this.clearItemsOnCurrentPage(this.itemRanges, this.itemRangesMap);
    this.itemRanges.forEach((item: Range<string>) => this.itemRangesMap.set(item.start, item));
    this.finishedGoodsSelectItemsService.setSelectedUnitIds(this.masterItemExternalIdWithNumberOfUnits, this.checkedItems);
    this.finishedGoodsSelectItemsService.setSelectedRanges(this.masterItemExternalIdWithNumberOfUnits, Array.from(this.itemRangesMap.values()));
  }
}
