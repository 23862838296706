import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, MonoTypeOperatorFunction, combineLatest, timer, of} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {falsy} from '@onbatch/shared/utils';

/*
  Let's assume our input Observable goes like this:
  true      |-----------------------------------------|
            |                                         |
  false  ---|                                         |------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  True means that request is in progress, false means that request is done.

  Using `debounceTime` results in a waveform looking something like this:
  true                    |-----------------------------------------|
                          |                                         |
  false  -----------------|                                         |-----------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  That's nice enough, it accounts for very short spikes and spares user from spinner-induced seizure, but we can do better.
  Notice how request ends at t3, yet after debouncing it ends at t4. That's not good - user has to stare at our ugly
  spinner after data is already fetched. We can solve it by defining a custom operator. Result looks like this:
  true                    |---------------------------|
                          |                           |
  false  -----------------|                           |--------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6

  Perfect! We can avoid spinner-fatigue by hiding small spikes (shorter than `delay`),
  but we don't needlessly postpone hiding the spinner.
 */
export function eagerDebounce<T>(startDelay: number): MonoTypeOperatorFunction<T> {
  return input$ => merge(
    input$.pipe(debounceTime(startDelay)),
    input$.pipe(falsy()),
  );
}

/*
  Makes sure that "time on" ([t1..t3]) lasts at least `minimalTimeOn` milliseconds.
  true                    |---------------------------|
                          |                           |
  false  -----------------|                           |--------------------------------------------
  time      t0            t1            t2            t3            t4            t5            t6
 */
export function minTime(minimalTimeOn: number): MonoTypeOperatorFunction<boolean> {
  return input$ =>
    input$.pipe(
      switchMap(value => {
        if (value) {
          return of(value);
        }
        return combineLatest([
          input$.pipe(falsy()),
          timer(minimalTimeOn),
        ]).pipe(map(_ => false));
      }),
    );
}

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private readonly spinnerDelay = 400;
  private readonly routeSpinnerDelay = 100;
  private readonly minimalTimeOn = 300;

  private routeSpinnerSubject = new BehaviorSubject(false);
  private globalSpinnerSubject = new BehaviorSubject(false);
  private filteredGlobalSpinnerSubject = merge(
    this.globalSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay)),
    this.routeSpinnerSubject.pipe(eagerDebounce(this.routeSpinnerDelay)),
  ).pipe(
    distinctUntilChanged(),
    minTime(this.minimalTimeOn),
  );
  private detailSpinnerSubject = new BehaviorSubject(false);
  private doubledDetailSpinnerSubject = new BehaviorSubject(false);
  private filteredDetailSpinnerSubject = this.detailSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay));
  private filteredDoubledDetailSpinnerSubject = this.doubledDetailSpinnerSubject.pipe(eagerDebounce(this.spinnerDelay));

  setGlobalSpinner(show: boolean) {
    this.globalSpinnerSubject.next(show);
  }

  setDetailSpinner(show: boolean) {
    this.detailSpinnerSubject.next(show);
  }

  setDoubledDetailSpinner(show: boolean) {
    this.doubledDetailSpinnerSubject.next(show);
  }

  setRouteSpinner(show: boolean) {
    this.routeSpinnerSubject.next(show);
  }

  getGlobalSpinner() {
    return this.filteredGlobalSpinnerSubject;
  }

  getDetailSpinner() {
    return this.filteredDetailSpinnerSubject;
  }

  getDoubledDetailSpinner() {
    return this.filteredDoubledDetailSpinnerSubject;
  }
}
