import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityBarOptionsModel } from '@onbatch/shared/models';
import { debounce } from 'lodash';
import {
  ExportRequestModel,
  FileResponse,
  FileType2,
  ItemPricingListOfPricingResponseModel,
  LookUpResponse,
  PaginatedResponseOfLookUpResponse,
  PricingRequestModel,
  PricingRequestModelRuleTypeId
} from '@onbatch/core/services/Inventory';
import { HeaderParamsModel } from '@onbatch/shared/models';
import { Subscription } from 'rxjs';
import { AuthService } from '@onbatch/core/services/auth.service';
import { SpinnerService } from '@onbatch/core/services/spinner.service';
import { MixPanelEvents, TabsValues } from '@onbatch/shared/constants';
import { InventoryHistoryLogListResponse } from '@onbatch/core/services/InventoryRx';
import { PricingService } from '@onbatch/shared/components/pricing-by-customer/pricing.service';
import { prepareFileName } from '@onbatch/shared/utils';
import { FileType } from '@onbatch/core/services/Account';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';

@Component({
  selector: 'app-pricing-by-customer-view',
  templateUrl: './view-item.component.html'
})
export class PricingViewItemByCustomerComponent implements OnInit, OnDestroy {
  @Input() singleId: string = null;
  @Input() showCustomerDetails = true;
  @Input() allowChangeContractor = false;
  @Input() extraClass = '';

  itemID: string;
  addNew = false;
  fixedRule = false;
  contractorId: string;
  utilityBarOptions: UtilityBarOptionsModel[] = [
    {
      label: 'Download as .pdf',
      icon: 'import',
      action: 'pdf'
    }
  ];
  masterItemSingle: ItemPricingListOfPricingResponseModel;
  fileTypes: typeof FileType2 = FileType2;
  addMode = false;

  contractorsBuffer: LookUpResponse[] = [];
  contractorsDropdownLoading = false;
  paramsForLookup: HeaderParamsModel = {
    x_pageNumber: 1,
    x_pageSize: 10,
    x_query: undefined,
    x_order: undefined,
    x_desc: false
  };
  lookupPages: number = null;
  getContractors = debounce((value: string) => {
    this.fetchMoreContractors(value);
  }, 200);
  isSelectOpen = false;
  validateContractorId = false;
  name: string;
  newFixedItem: PricingRequestModel;
  validation: PricingByItemValidationModel = {
    salesPrice: null,
    contractorId: null,
    masterItemId: null
  };
  isAddFixedRulePossible = true;

  pricingByCustomerSingleSubscription: Subscription;
  pricingByItemAddModeSubscription: Subscription;
  pricingByCustomerSingleRouterSubscription: Subscription;

  dontRefreshAddMode = false;

  tabs: typeof TabsValues = TabsValues;
  activeTab: TabsValues = this.tabs.PricingDetails;

  historyLog: InventoryHistoryLogListResponse[];

  @HostBinding('style.width') private width = '100%';

  static isObjectEmpty(item: object): boolean {
    return Object.keys(item).length === 0;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _FileSaverService: FileSaverService,
    private pricingService: PricingService,
    public authService: AuthService,
    public spinnerService: SpinnerService,
    private mixpanelService: MixpanelService
  ) {
    this.pricingByCustomerSingleSubscription = this.pricingService.getPricingByCustomerSingle()
      .subscribe(item => (this.masterItemSingle = item));
    this.pricingByItemAddModeSubscription = this.pricingService.getPricingByCustomerAddMode()
      .subscribe(item => (this.addMode = item));
    this.pricingByCustomerSingleRouterSubscription = this.route.params.subscribe(params => {
      if (params.id) {
        this.singleId = params.id;
        this.pricingService.fetchPricingByCustomerSingle(params.id);
        this.pricingService.setPricingByCustomerAddMode(false);
      } else {
        this.singleId = 'new';
        this.pricingService.setPricingByCustomerAddMode(true);
      }
    });
  }

  ngOnInit(): void {
    this.setUpPricing();
  }

  ngOnDestroy(): void {
    this.pricingByCustomerSingleSubscription.unsubscribe();
    this.pricingByCustomerSingleRouterSubscription.unsubscribe();
    this.pricingByItemAddModeSubscription.unsubscribe();
    if (!this.dontRefreshAddMode) {
      this.pricingService.setPricingByCustomerAddMode(false);
    }
  }

  setUpPricing() {
    this.setUpNewRule();
    this.getContractorsLookups();
    if (this.singleId) {
      if (this.singleId !== 'new') {
        this.itemID = this.singleId;
        this.contractorId = this.itemID;
        this.getSingleItem();
        this.fixedRule = false;
      } else {
        this.singleId = null;
        this.allowChangeContractor = true;
        this.addMode = true;
        this.masterItemSingle = undefined;
        this.contractorId = undefined;
        this.fixedRule = true;
      }
    }
  }

  setIsAddPossible(value: boolean) {
    this.isAddFixedRulePossible = value;
  }

  getContractorsLookups(): void {
    if (!this.lookupPages || this.paramsForLookup.x_pageNumber <= this.lookupPages) {
      this.contractorsDropdownLoading = true;
      this.pricingService
        .getAllContractors(
          true,
          this.paramsForLookup.x_pageNumber,
          this.paramsForLookup.x_pageSize,
          this.paramsForLookup.x_query,
          this.paramsForLookup.x_order,
          this.paramsForLookup.x_desc
        )
        .subscribe((masterItems: PaginatedResponseOfLookUpResponse) => {
          this.contractorsBuffer = [...this.contractorsBuffer, ...masterItems.list];
          this.lookupPages = masterItems.pageInfo.totalPages;
          setTimeout(() => (this.contractorsDropdownLoading = false), 500);
        });
    }
  }

  fetchMoreContractors(value: string = null): void {
    this.paramsForLookup.x_pageNumber++;
    if (value !== null && value !== this.paramsForLookup.x_query) {
      this.contractorsBuffer = [];
      this.paramsForLookup.x_pageNumber = 1;
    }
    this.paramsForLookup.x_query = value;
    this.getContractorsLookups();
  }

  toggleSelect(): void {
    this.isSelectOpen = !this.isSelectOpen;
  }

  getSingleItem(): void {
    this.pricingService
      .getSinglePricingByCustomer(this.contractorId)
      .subscribe(masterItemSingle => {
        this.masterItemSingle = masterItemSingle;
        this.contractorId = masterItemSingle.externalId;
        this.fixedRule = PricingViewItemByCustomerComponent.isObjectEmpty(this.masterItemSingle.pricings);
        this.name = masterItemSingle.name;
        this.allowChangeContractor = false;
        this.addNew = false;
      });
  }

  toggleAdd(): void {
    if (this.contractorId) {
      this.addNew = !this.addNew;
      this.validateContractorId = false;
      this.allowChangeContractor = false;
      if (!this.masterItemSingle) {
        this.name = this.contractorsBuffer.filter((contractor: LookUpResponse) => this.contractorId === contractor.externalId)[0]
          ? this.contractorsBuffer.filter((contractor: LookUpResponse) => this.contractorId === contractor.externalId)[0].name
          : null;
      }
    } else {
      this.validateContractorId = true;
    }
  }

  setUpNewRule(): void {
    this.newFixedItem = new PricingRequestModel();
    this.newFixedItem.contractorId = this.contractorId;
    this.newFixedItem.masterItemExternalId = null;
    this.newFixedItem.ruleTypeId = PricingRequestModelRuleTypeId.Fixed;
    this.newFixedItem.salesPrice = 0;
  }

  addFixedRule(): void {
    this.validation.salesPrice = this.validateNumberValue(this.newFixedItem.salesPrice, 0.01, Number.POSITIVE_INFINITY);
    this.newFixedItem.contractorId = this.contractorId;
    if (this.checkValidation()) {
      this.pricingService
        .addSinglePricing(this.newFixedItem)
        .subscribe(() => {
          if (this.masterItemSingle) {
            this.setUpNewRule();
          } else {
            this.pricingService.fetchPricingByCustomerList();
            this.getSingleItem();
            this.router.navigate([`/inventory/pricing-list/by-customer/${this.contractorId}`]);
          }
          this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_ADDED, {
            'Customer name': this.name,
            'Customer external ID': this.newFixedItem.contractorId,
            'Master item name': this.masterItemSingle.name,
            'Master item external ID': this.newFixedItem.masterItemExternalId,
            'Min Quantity': this.newFixedItem.minQuantity || null,
            'Max Quantity': this.newFixedItem.maxQuantity || null,
            'Date from': this.newFixedItem.from || null,
            'Date to': this.newFixedItem.to || null,
            'Sales Price': this.newFixedItem.salesPrice,
            'Rule Type ID': this.newFixedItem.ruleTypeId
          });
          this.toastr.success('Success!', 'Preset added');
        });
    }
  }

  afterPresetAdded(): void {
    this.getSingleItem();
    this.pricingService.fetchPricingByCustomerList();
  }

  validateNumberValue(value: number, min: number, max: number): boolean {
    value = Number(value);
    if (isNaN(value) || value === 0) {
      return true;
    } else {
      return min > value || max < value;
    }
  }

  checkValidation(): boolean {
    let valid = true;
    const _self = this;
    Object.keys(_self.validation).map(function(key: string) {
      if (_self.validation[key]) {
        valid = false;
      }
    });
    return valid;
  }

  clearError(field: string): void {
    if (this.validation[field] !== null) {
      this.validation[field] = null;
    }
  }

  closeAdd(): void {
    this.addNew = false;
  }

  presetAdded(dontRefreshAddMode: boolean = false): void {
    this.dontRefreshAddMode = dontRefreshAddMode;
    this.getSingleItem();
    this.pricingService.fetchContractorsLookup();
    this.pricingService.fetchPricingByCustomerList();
  }

  afterPresetUpdated(): void {
    this.pricingService.fetchPricingByCustomerList();
    this.getSingleItem();
  }

  closeItem(): void {
    this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_ADD_CANCELED, {});
    this.router.navigate([`inventory/pricing-list/by-customer/`]);
    this.itemID = null;
    this.pricingService.setPricingByCustomerAddMode(false);
  }

  triggerAction(action: string): void {
    const data = new ExportRequestModel({ externalIds: [this.singleId], columnNames: [] });
    switch (action) {
      case 'pdf': {
        this.pricingService
          .exportFile('customer', this.fileTypes.Pdf, data)
          .subscribe((res: FileResponse) => {
            this._FileSaverService.save(res.data, prepareFileName(`pricing-${this.singleId}`, FileType.Pdf));
            this.toastr.success('Success!', 'Pricing exported');
          });
        break;
      }
    }
  }

  setTab(tab: TabsValues): boolean {
    if (tab === TabsValues.History) {
      this.fetchHistory();
    }
    this.activeTab = tab;

    return false;
  }

  fetchHistory(): void {
    this.pricingService.getSingleHistoryLogByIdForCustomer(this.itemID)
      .subscribe(item => this.historyLog = item.list);
  }
}

export interface PricingByItemValidationModel {
  salesPrice: boolean;
  contractorId: boolean;
  masterItemId: boolean;
}
