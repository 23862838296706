import { SubscriptionFeatures } from '@onbatch/core/services/subscription.service';
import { SidebarConfigItem } from 'app/sidebar/sidebar.model';
import { isCompanyFromUSA, hideReportsOnProduction } from '../utils';

export function sidebarConfig(): SidebarConfigItem[] {
  return [
    {
      icon: 'menu-inventory',
      title: 'Inventory',
      link: '/inventory',
      isActive: true,
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/inventory/dashboards',
        // },
        {
          text: 'Master Items',
          link: '/inventory/master-items',
          isActive: true,
          feature: SubscriptionFeatures.InventoryMasterItems,
        },
        {
          text: 'Items Pricing',
          link: '/inventory/pricing-list',
        },
        {
          text: 'Stock Count',
          link: '/inventory/stock-counts',
          feature: SubscriptionFeatures.InventoryStockCount,
        },
        // {
        //   text: 'Reports',
        //   link: '/inventory/reports',
        // },
        {
          text: 'History Log',
          link: '/inventory/history',
        },
      ],
    },
    {
      icon: 'menu-purchasing',
      title: 'Purchasing',
      link: '/purchasing',
      isActive: false,
      isOpen: false,
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/purchasing/dashboards',
        //   isActive: false
        // },
        // {
        //   text: 'Requisitions',
        //   link: '/purchasing/requisitions',
        //   isActive: false
        // },
        {
          text: 'Orders',
          link: '/purchasing/orders',
          isActive: false,
          feature: SubscriptionFeatures.PurchasingPurchaseOrders,
        },
        // {
        //   text: 'Reports',
        //   link: '/purchasing/reports',
        //   isActive: false
        // },
        // {
        //   text: 'History Log',
        //   link: '/purchasing/history',
        //   isActive: false
        // },
      ],
    },
    {
      icon: 'menu-warehousing',
      title: 'Warehousing',
      link: '/warehousing',
      isActive: false,
      isOpen: false,
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/warehousing/dashboards',
        // },
        {
          text: 'Warehouses',
          link: '/warehousing/all',
        },
        // {
        //   text: 'Order Picking',
        //   link: '/warehousing/order-picking',
        // },
        {
          text: 'Transfer In Bond',
          link: '/warehousing/transfers',
          feature: SubscriptionFeatures.WarehousingTransferInBond,
        },
        {
          text: 'Remnant cases',
          link: '/warehousing/remnant-cases',
        },
        // {
        //   text: 'Reports',
        //   link: '/warehousing/reports',
        // },
        // {
        //   text: 'History Log',
        //   link: '/warehousing/history',
        // }
      ],
    },
    {
      icon: 'menu-manufacturing',
      title: 'Manufacturing',
      link: '/manufacturing',
      isActive: false,
      isOpen: false,
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/manufacturing/dashboards',
        //   isActive: false
        // },
        {
          text: 'Product Flows',
          link: '/manufacturing/product-flows',
          isActive: false,
          feature: SubscriptionFeatures.ManufacturingProductFlows,
        },
        {
          text: 'Batches',
          link: '/manufacturing/batches',
          isActive: false,
          feature: SubscriptionFeatures.ManufacturingManualBatch,
        },
        // {
        //   text: 'Packaging Runs',
        //   link: '/manufacturing/packaging-runs',
        //   isActive: false
        // },
        {
          text: 'Equipment',
          link: '/manufacturing/equipment',
          isActive: false,
          feature: SubscriptionFeatures.ManufacturingEquipmentManagement,
        },
        // {
        //   text: 'Packaging Lines',
        //   link: '/manufacturing/packaging-lines',
        //   isActive: false
        // },
        {
          text: 'Reports',
          link: '/manufacturing/reports',
          isActive: false,
          isHidden: !isCompanyFromUSA(),
        },
        // {
        //   text: 'History Log',
        //   link: '/manufacturing/history',
        //   isActive: false
        // },
      ],
    },
    {
      icon: 'menu-sales',
      link: '/sales',
      title: 'Sales',
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/sales/dashboards',
        //   isActive: false
        // },
        {
          text: 'Orders',
          link: '/sales/orders',
          isActive: false,
          feature: SubscriptionFeatures.SalesSalesOrders,
        },
        {
          text: 'Invoices',
          link: '/sales/invoices',
          isActive: false,
          feature: SubscriptionFeatures.SalesInvoicing,
        },
        // {
        //   text: 'Reports',
        //   link: '/sales/reports',
        //   isActive: false
        // },
        {
          text: 'History Log',
          link: '/sales/history',
          isActive: false,
        },
      ],
    },
    {
      icon: 'menu-crm',
      link: '/crm',
      title: 'CRM',
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/crm/dashboards',
        // },
        {
          text: 'Companies',
          link: '/crm/companies',
          feature: SubscriptionFeatures.ContactRelationshipCompanies,
        },
        {
          text: 'Contacts',
          link: '/crm/contacts',
          feature: SubscriptionFeatures.ContactRelationshipContacts,
        },
        // {
        //   text: 'Tasks',
        //   link: '/crm/tasks',
        // },
        // {
        //   text: 'Reports',
        //   link: '/crm/reports',
        // },
        {
          text: 'History Log',
          link: '/crm/history',
        },
      ],
    },
    {
      icon: 'menu-reports',
      link: '/reporting',
      title: 'Reports',
      isHidden: hideReportsOnProduction(),
      sublinks: [
        // {
        //   text: 'Dashboards',
        //   link: '/reporting/dashboards',
        // },
        {
          text: 'Reports',
          link: '/reporting/transaction-reports-navigation/list/all',
          feature: SubscriptionFeatures.InventoryMasterItems,
        },
      ],
    },
    // {
    //   icon: 'menu-accounting',
    //   link: 'accounting',
    //   title: 'Accounting',
    // },
    // {
    //   icon: 'menu-reports',
    //   link: 'reports',
    //   title: 'Reporting',
    // },
  ];
}

export const adminSidebarConfig = [
  {
    icon: 'menu-crm',
    title: 'Admin',
    link: '/admin',
    isActive: true,
    sublinks: [
      {
        text: 'Tenants',
        link: '/admin/tenants',
        isActive: true,
      },
      {
        text: 'Industries',
        link: '/admin/industries',
        isActive: false,
      },
    ],
  },
];
