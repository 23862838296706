import {  Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { DatePipe } from '@angular/common';
import { FormattingResponseModel } from '../../core/services/Account';
import * as moment from 'moment-timezone';
import { SettingsService } from 'app/account/settings/settings.service';
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  settings: FormattingResponseModel;
  formattedDate: string;
  formattedTime: string;

  constructor(
    public authService: AuthService,
    public settingsService: SettingsService,
  ) {}

  transform(value: number, onlyDate: boolean = false, onlyTime: boolean = false, timezone?: string,  args?: any): any {
    const formatting = this.authService.getFormattingSettingsObservable();
    if (!timezone && this.settingsService.formattingSettings.value && !!this.settingsService.formattingSettings.value.timezone) {
      timezone = moment.tz(this.settingsService.formattingSettings.value.timezone).format('z');
    }

    if (formatting) {
      this.formattedDate = new DatePipe('en-US').transform(value, formatting.dateFormat, timezone);
      this.formattedTime = new DatePipe('en-US').transform(value, formatting.timeFormat, timezone);
    } else {
      this.formattedDate = new DatePipe('en-US').transform(value, 'M/d/yy', timezone);
      this.formattedTime = new DatePipe('en-US').transform(value, 'h:mm aaa', timezone);
    }
    // this.settingsService.getFormattingSettingsObservable()
    //   .subscribe(res => {
    //     if (res) {
    //       this.formattedDate = new DatePipe('en-US').transform(value, res.dateFormat);
    //       this.formattedTime = new DatePipe('en-US').transform(value, res.timeFormat);
    //     } else {
    //       this.formattedDate = new DatePipe('en-US').transform(value, 'M/d/yy');
    //       this.formattedTime = new DatePipe('en-US').transform(value, 'h:mm aaa');
    //     }
    //   });
    if (onlyDate) {
      return this.formattedDate;
    }
    if (onlyTime) {
      return this.formattedTime;
    }
    if (!onlyTime && !onlyDate) {
      return `${this.formattedDate} ${this.formattedTime}`;
    }
  }
}
