<div
  *ngIf="options"
  [formGroup]="formGroup"
  [id]="id"
  class="form__box {{options.customClass}} {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}} {{formSubmitted && !formGroup.controls[formControlNameValue].errors ? 'form__valid': ''}} {{options.disabled ? 'form__box--disabled' : ''}}">
  <div *ngIf="options.label" class="form__label" [ngClass]="removable ? 'form__label--with-icon' : ''">
   <span>
    {{options.label}}
     <app-tooltip *ngIf="options.tooltip"
                  [ngClass]="'tooltip__wrapper'"
                  [text]="options.tooltipInfo"
                  [customIconClass]="'tooltip--small'">
     </app-tooltip>
   </span>
    <svg-icon
      *ngIf="removable"
      class="form__label__delete-icon"
      icon="delete"
      (click)="onDelete()"
    ></svg-icon>
  </div>
  <div class="form__input-wrap {{options.insideLabel ? 'form__input-wrap--with-in' : ''}}">
    <input *ngIf="options.fieldType === 'input'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [type]="options.type"
           [mask]="options.mask"
           [readonly]="options.readonly"
           [maxlength]="options.maxlength"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (input)="inputValueChange($event.target.value)"
           (blur)="inputBlur($event.target.value)"
           (focusOnInit)="focusField()"
           #fieldRef
    >
    <input *ngIf="options.fieldType === 'input-with-mask'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [readonly]="options.readonly"
           [maxlength]="options.maxlength"
           [prefix]="options.prefix ? options.prefix : ''"
           [suffix]="options.suffix ? options.suffix : ''"
           [mask]="options.isInteger ? patternForAmountInteger.precision : patternForAmount.precision"
           [thousandSeparator]="patternForAmountInteger.separator"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (input)="inputValueChange($event.target.value)"
           (blur)="inputBlur($event.target.value)"
           (focusOnInit)="focusField()"
           #fieldRef
    >

    <input *ngIf="options.fieldType === 'input-number'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [mask]="options.isInteger ? patternForAmountInteger.precision : patternForAmount.precision"
           [thousandSeparator]="patternForAmountInteger.separator"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}"
           (input)="maskedInputValueChange($event.target.value)"
    >

    <input *ngIf="options.fieldType === 'durationInput'"
           type="text"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [readonly]="options.readonly"
           [placeholder]="durationPlaceholder"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (keyup)="onDurationKeyUp($event)"
           (keydown)="onDurationKeyDown($event)"
           #fieldRef
    >

    <input *ngIf="options.fieldType === 'timer'"
           type="text"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [readonly]="options.readonly"
           [imask]="timerMask"
           [value]="timerValue"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (complete)="onTimerInputComplete($event)"
           #fieldRef
    >

    <input *ngIf="options.fieldType === 'decimal-input-with-mask'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [readonly]="options.readonly"
           [maxlength]="options.maxlength"
           [prefix]="options.prefix ? options.prefix : ''"
           [mask]="options.numberOfDecimalPlaces || options.numberOfDecimalPlaces === 0 ? getCustomPrecision : patternForAmount.precision"
           [thousandSeparator]="patternForAmount.separator"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (input)="maskedInputValueChange($event.target.value)"
           (focusOnInit)="focusField()"
           #fieldRef
           appDecimalInput
    >

    <!-- This input is formatted based on decimal precision and thousand separators for COST values from global settings -->
    <input *ngIf="options.fieldType === 'priceInput'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [readonly]="options.readonly"
           [mask]="patternForCost.precision"
           [thousandSeparator]="patternForCost.separator"
           [prefix]="pricePrefix"
           (keydown.enter)="$event.preventDefault()"
           (input)="inputValueChange($event.target.value)"
           (blur)="inputBlur($event.target.value)"
           (focusOnInit)="focusField()"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           appPriceInput
    >

    <input *ngIf="options.fieldType === 'maskedInput' || options.fieldType === 'decimal-input'"
           [formControlName]="formControlNameValue"
           [id]="(!!options.id || options.id === 0) ? options.id : options.name"
           [name]="options.name"
           [placeholder]="options.placeholder"
           [type]="options.type"
           [readonly]="options.readonly"
           [textMask]="{mask: options.mask}"
           [(ngModel)]="maskedInputValue"
           class="form__input
              {{formSubmitted && formGroup.controls[formControlNameValue].errors ? 'form__error': ''}}
              {{ options.insideLabel ? 'form__input--with-in-label' : '' }}
              {{ options.isDecimalInput ? 'form__input--with-decimal-buttons' : ''}}"
           tabindex="{{options.tab}}"
           (input)="inputValueChange($event.target.value)"
           (blur)="inputBlur($event.target.value)"
           (focusOnInit)="focusField()"
           #fieldRef
           appDecimalInput
           [numberOfDecimalPlaces]="options.numberOfDecimalPlaces"
    >

    <ng-select *ngIf="options.fieldType === 'selectValue'"
               (change)="afterOptionSelected($event)"
               (clear)="clearInput(formGroup.controls[formControlNameValue])"
               [formControlName]="formControlNameValue"
               [placeholder]="options.placeholder"
               [selectOnTab]="true"
               class="dropdown"
    >
      <ng-option *ngFor="let option of options.select.items"
                 [value]="option.value">{{option.label | splitCamelCase}}</ng-option>
    </ng-select>

    <ng-select *ngIf="options.fieldType === 'selectEquipmentType'"
               (change)="afterOptionSelected($event)"
               [formControlName]="formControlNameValue"
               [items]="options.select.items"
               bindLabel="equipmentModelType"
               bindValue="externalId"
               groupBy="equipmentType"
               [selectableGroup]="false"
               [searchable]="!!options.searchable"
               [selectOnTab]="true"
               class="dropdown">
      <ng-template ng-optgroup-tmp let-item="item">
        {{(item.equipmentType | splitCamelCase) || 'Unnamed group'}}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        {{item.equipmentModelType | splitCamelCase}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{item.equipmentModelType | splitCamelCase}}
      </ng-template>
    </ng-select>

    <ng-select (change)="selectValueChange($event); afterOptionSelected($event)"
               *ngIf="options.fieldType === 'select'"
               [addTagText]="options.newItemsText ? options.newItemsText : 'Add item: '"
               [notFoundText]="options.noItemsText ? options.noItemsText : 'No items found'"
               [addTag]="options.newItemsPossible"
               [formControlName]="formControlNameValue"
               [placeholder]="options.placeholder"
               [searchable]="!!options.searchable"
               [clearable]="!!options.clearable"
               [selectOnTab]="true"
               (open)="openSelect()"
               (close)="closeSelect()"
               class="dropdown"
    >
      <ng-option *ngFor="let option of options.select.items" [value]="option">{{option | splitCamelCase}}</ng-option>
    </ng-select>

    <ng-select (change)="selectValueChange($event)"
               *ngIf="options.fieldType === 'select-check'"
               [formControlName]="formControlNameValue"
               [items]="options.select.items && options.multiple ? options.select.items : null"
               [multiple]="!!options.multiple"
               [placeholder]="options.placeholder"
               [searchable]="!!options.searchable"
               [selectOnTab]="true"
               class="dropdown"
    >
      <ng-container *ngIf="!options.multiple">
        <ng-option *ngFor="let option of options.select.items" [value]="option">{{option | splitCamelCase}}</ng-option>
      </ng-container>
      <ng-container *ngIf="options.multiple">
        <ng-container *ngFor="let item of options.select.items">
          <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
            <span class="checkbox__label"></span> {{item}}
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-select>

    <ng-select (change)="selectValueChange($event)"
               (scrollToEnd)="getLookup()"
               (search)="getLookup($event.term)"
               (open)="selectOpened()"
               *ngIf="options.fieldType === 'select-with-add'"
               [addTag]="addCustomElement"
               [bindValue]="options.bindId ? 'externalId' : 'name'"
               [formControlName]="formControlNameValue"
               [items]="options.select.items"
               [multiple]="options.multiple"
               [placeholder]="options.placeholder"
               [selectOnTab]="true"
               bindLabel="name"
               class="dropdown"
    >
        <ng-option *ngFor="let option of options.select.items" [value]="option">{{option | splitCamelCase}}</ng-option>
    </ng-select>
    <ng-select (change)="selectValueChange($event)"
               (scrollToEnd)="getLookup()"
               (search)="getLookup($event)"
               *ngIf="options.fieldType === 'select-add'"
               [addTag]="addCustomElement"
               [formControlName]="formControlNameValue"
               [items]="selectOptions"
               [multiple]="false"
               [placeholder]="options.placeholder"
               [virtualScroll]="true"
               [selectOnTab]="true"
               bindLabel="name"
               class="dropdown"
    >
    </ng-select>
    <ng-select *ngIf="options.fieldType === 'custom_select'"
               [formControlName]="formControlNameValue"
               [items]="options.select.items"
               [placeholder]="options.placeholder"
               [selectOnTab]="true"
               bindLabel="name"
               bindValue="externalId"
               class="dropdown"
               (change)="selectValueChange($event)"
               (scrollToEnd)="getLookup()"
               (search)="getLookup($event.term)"
               (open)="openSelect()"
    >
    </ng-select>
    <ng-select *ngIf="options.fieldType === 'select_multiple'"
               [formControlName]="formControlNameValue"
               [items]="options.select.items"
               [placeholder]="options.placeholder"
               [multiple]="options.multiple"
               [selectOnTab]="true"
               class="dropdown"
               bindLabel="name"
               (change)="selectValueChange($event)"
    >
    </ng-select>

    <div *ngIf="options.fieldType === 'textarea'" class="form__textarea-wrap">
      <textarea
        (blur)="toggleWrapFocus($event, false)"
        (focus)="toggleWrapFocus($event, true)"
        [formControlName]="formControlNameValue"
        [placeholder]="options.placeholder"
        [maxlength]="options.maxlength"
        class="form__textarea"
        [class.form__textarea--error]="formSubmitted && formGroup.controls[formControlNameValue].errors"
        (input)="inputValueChange($event.target.value)"
      ></textarea>
    </div>

    <ng-select (scrollToEnd)="getLookup()"
               (search)="getLookup($event)"
               *ngIf="options.fieldType === 'selectKindOfSpirit'"
               [formControlName]="formControlNameValue"
               [items]="selectOptions"
               [placeholder]="options.placeholder"
               [selectableGroup]="true"
               [virtualScroll]="true"
               [selectOnTab]="true"
               bindLabel="name"
               class="dropdown"
               groupBy="spiritClass">
      <ng-template let-item="item" ng-optgroup-tmp>
        {{item.spiritClass}}
      </ng-template>
    </ng-select>

    <div *ngIf="options.fieldType !== 'textarea' && options.fieldType !== 'timer' && options.type !== 'time' && !options.disableControls"
         class="form__controls
                {{ options.readonly ? '' : options.fieldType.includes('select') ? 'form__controls--select' : ''}}
                {{ options.insideLabel ? 'form__controls--with-in-label' : ''}}
                {{ options.isDecimalInput ? 'form__controls--with-decimal-buttons' : ''}}">
      <div *ngIf="!options.readonly && options.fieldType !== 'owlDatePicker' && options.fieldType !== 'timer'"
           (click)="clearInput(formGroup.controls[formControlNameValue], $event)"
           class="form__controls-clear">
        <svg-icon icon="close"></svg-icon>
      </div>
      <div *ngIf="options.searchable" class="form__controls-search" [class.move-left]="options.readonly">
        <svg-icon icon="search"></svg-icon>
      </div>
    </div>

    <div *ngIf="options.insideLabel && !options.isDecimalInput" class="form__in-label">{{ options.insideLabel }}</div>

    <div *ngIf="options.isDecimalInput" class="form__decimal-buttons">
      <button type="button" (click)="decreaseDecimalPlaces(formGroup.controls[formControlNameValue])">
        <div>.0</div>
        <svg-icon [icon]="'arrow-left'"></svg-icon>
      </button>
      <button type="button" (click)="increaseDecimalPlaces(formGroup.controls[formControlNameValue])">
        <div>.00</div>
        <svg-icon [icon]="'arrow-right'"></svg-icon>
      </button>
    </div>

  </div>
  <ng-select (change)="afterOptionSelected($event)"
             (clear)="afterOptionSelected($event)"
             (scrollToEnd)="getLookup()"
             (search)="getLookup($event.term)"
             *ngIf="options.fieldType === 'select_options'"
             [formControlName]="formControlNameValue"
             [items]="selectOptions"
             [placeholder]="options.placeholder"
             [selectOnTab]="true"
             [attr.disabled]="options.disabled"
             bindLabel="name"
             bindValue="externalId"
             class="dropdown"
  >
  </ng-select>
  <app-form-error-message
    [formSubmitted]="formSubmitted"
    [errors]="formGroup.controls[formControlNameValue]?.errors"
    [patternError]="patternError"
  ></app-form-error-message>

  <span *ngIf="options.multiple && tagCount > 0"
        class="form__tags-label">{{tagCount}} {{(options.tagLabel ? options.tagLabel : 'Item')}}{{tagCount > 1 ? 's' : ''}} Selected</span>


  <div *ngIf="options.extraButtons"
       class="form__extra-buttons"
       [class.form__extra-buttons--without-label]="!options.label"
  >
    <button (click)="extraBtnClick(btn.slug, options)"
            *ngFor="let btn of options.extraButtons"
            class="form__extra-btn {{btn.selected ? 'active' : ''}}">{{btn.label}}</button>
  </div>

  <p *ngIf="options.helper" class="form__helper">{{options.helper}}</p>

  <div *ngIf="options.multiple && tagCount > 0" class="form__tags">
    <div *ngFor="let tag of formGroup.controls[formControlNameValue].value" class="form__tag">
      {{tag.name ? tag.name : tag}}
      <button (click)="deleteTag(formGroup.controls[formControlNameValue], tag)" class="form__tag-delete">
        <svg-icon icon="close"></svg-icon>
      </button>
    </div>
  </div>
</div>
