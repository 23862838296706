import {fromEvent, merge, Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {QueryList} from '@angular/core';

export interface SelectionItem {
  getElement: () => Element;
  emitValue: () => void;
  isActive: () => boolean;
}

export class MultiSelection<T extends SelectionItem> {
  private lastIndex: number;
  private subscription: Subscription;

  constructor(private items: QueryList<T>) {
    this.init();
  }

  init() {
    const clicks$ = this.getListeners();

    this.subscription = merge(...clicks$).subscribe(({comp, isShift, index}) => {
      if (isShift === false) {
        this.lastIndex = index;
        comp.emitValue();
      } else {
        const start = index;
        const end = this.lastIndex;
        const inRange = this.items.toArray().slice(Math.min(start, end), Math.max(start, end) + 1);

        for (const currentItem of inRange) {
          if (this.shouldSkip(currentItem, inRange, start, end)) {
            continue;
          }
          currentItem.emitValue();
        }
      }
    });
  }

  destroy() {
    this.subscription.unsubscribe();
  }

  private shouldSkip = (item: T, items: Array<T>, start: number, end: number) =>
    start > end ? item.isActive() && item !== items[items.length - 1] : item.isActive() && item !== items[0]

  private getListeners(): Observable<any>[] {
    return this.items.map((item, index) => {
      return fromEvent(item.getElement(), 'click').pipe(
        map((event: MouseEvent) => {
          return {
            index,
            isShift: event.shiftKey,
            comp: item
          };
        })
      );
    });
  }
}
