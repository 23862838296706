import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounce } from 'lodash';
import { SemiFinishedGoodItemResponseModel, ManufacturingItemBatchesLookUpResponseModel, PaginatedResponseOfManufacturingContainerResponseModel, ManufacturingContainerResponseModel, BatchProcessAttributeResponseBatchSourceType } from '../../../core/services/Manufacturing';
import { ManualBatchContainers } from '../../../manufacturing/batches/shared/batches.enums';
import { PaginatedResponseOfMasterItemLookupResponse } from '../../../core/services/Inventory';
import { SpinnerService } from '@onbatch/core/services/spinner.service';
import { FiltersService } from 'app/filters/filters.service';

@Component({
  selector: 'app-add-source-item-modal',
  templateUrl: './add-source.component.html'
})
export class AddSourceComponent {

  @Input() items: (SemiFinishedGoodItemResponseModel | PaginatedResponseOfMasterItemLookupResponse)[] = [];
  @Input() packagingContainers: PaginatedResponseOfManufacturingContainerResponseModel[] = [];
  @Input() tabs: string[] = [];
  @Input() containers: ManufacturingItemBatchesLookUpResponseModel[] = [];
  @Input() sourceType: BatchProcessAttributeResponseBatchSourceType;
  @Input() placeholder: string;
  @Input() enableFilters = false;

  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<SemiFinishedGoodItemResponseModel> = new EventEmitter<SemiFinishedGoodItemResponseModel>();
  @Output() packContainerSelected: EventEmitter<ManufacturingContainerResponseModel> = new EventEmitter<ManufacturingContainerResponseModel>();
  @Output() activeTabChanged: EventEmitter<ManualBatchContainers> = new EventEmitter<ManualBatchContainers>();
  @Output() scroll = new EventEmitter<Event>();
  @Output() showFilters = new EventEmitter();

  searchTerm = '';
  activeTab: ManualBatchContainers = ManualBatchContainers.Barrels;

  searchSubmit = debounce(() => this.searchSubmitted.emit(this.searchTerm), 250);

  constructor(
    public spinnerService: SpinnerService,
    private filtersService: FiltersService,
  ) {
  }

  itemClicked(item: SemiFinishedGoodItemResponseModel): void {
    this.itemSelected.emit(item);
  }

  containerClicked(container: ManufacturingContainerResponseModel) {
    this.packContainerSelected.emit(container);
  }

  setTab(tab: ManualBatchContainers): void {
    this.activeTab = tab;
    this.activeTabChanged.emit(tab);
  }

  onScroll(event: Event): void {
    this.scroll.emit(event);
  }

  onFiltersClicked() {
    this.showFilters.emit();
    this.filtersService.setFiltersAsOpened(true);
  }
}
