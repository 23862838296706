import { InventoryHistoryLogResponseInventoryHistoryActionTypeId } from '../../../core/services/InventoryRx';

export enum AnimationState {
  In = 'in',
  Out = 'out'
}

export interface HistoryLogBaseModel {
  logEntry: Date;
  actionSubType: InventoryHistoryLogResponseInventoryHistoryActionTypeId;
  fieldName: string;
  prevValue: string;
  currentValue: string;
  facilityName: string;
  stockCountId: string;
  quantity: number;
  unitOfMeasurementName: string;
  basicActionType: ActionType;
  sourceFacilityName: string;
  destinationFacilityName: string;
  sourceWarehouseName: string;
  destinationWarehouseName: string;
  warehouseName: string;
  sourceRegionShortName: string;
  destinationRegionShortName: string;
  withdrawnQuantity: number;
  masterItemName: string;
  backOrderDate: Date;
  billId: string;
  receivedDate: Date;
  details: HistoryLogReceivedQuantityDetails[];
  lotId: string;
  reason: string;
  returnsDate: Date;
  items: NameValueModel[];
}


export interface HistoryLogReceivedQuantityDetails {
  lotId: string;
  quantity: number;
}

export interface NameValueModel {
  name: string;
  value: string;
}

export enum ActionType {
  Added = 1,
  Updated = 2,
  Deleted = 3,
}

export function mapLogs(logs: string): HistoryLogBaseModel[] {
  const mappedLogs: HistoryLogBaseModel[] = [];
  const parsedLogs: any[] = JSON.parse(logs);
  parsedLogs.forEach(log => {
    mappedLogs.push(<HistoryLogBaseModel>{
      logEntry: log['LogEntry'],
      actionSubType: InventoryHistoryLogResponseInventoryHistoryActionTypeId[log['ActionSubType']],
      fieldName: log['FieldName'],
      prevValue: log['PrevValue'],
      currentValue: log['CurrentValue'],
      facilityName: log['FacilityName'],
      stockCountId: log['StockCountId'],
      quantity: log['Quantity'],
      unitOfMeasurementName: log['UnitOfMeasurementName'],
      sourceFacilityName: log['SourceFacilityName'],
      destinationFacilityName: log['DestinationFacitlityName'],
      sourceWarehouseName: log['SourceWarehouseName'],
      destinationWarehouseName: log['DestinationWarehouseName'],
      warehouseName: log['WarehouseName'],
      sourceRegionShortName: log['SourceRegionShortName'],
      destinationRegionShortName: log['DestinationRegionShortName'],
      withdrawnQuantity: log['WithdrawnQuantity'],
      masterItemName: log['MasterItemName'],
      backOrderDate: log['BackOrderDate'],
      billId: log['BillId'],
      receivedDate: log['ReceivedDate'],
      details: log['Details'] && log['Details'].length ? log['Details'].map(item => {
        return <HistoryLogReceivedQuantityDetails>{
          lotId: item['LotId'],
          quantity: item['Quantity'],
        };
      }) : undefined,
      lotId: log['LotId'],
      reason: log['Reason'],
      returnsDate: log['ReturnsDate'],
      items: log['Items'] && log['Items'].length ? log['Items'].map(item => {
        return <NameValueModel>{
          name: item['Name'],
          value: item['Value'],
        };
      }) : undefined,
    });
  });
  return mappedLogs;
}
