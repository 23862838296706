import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'cookie-bar',
  templateUrl: './cookie-bar.component.html'
})
export class CookieBarComponent implements OnInit {
  show = false;
  text =
    'We use cookies on our website. ' +
    'Cookies are small text files, that are stored on your computer or mobile phone when you visit websites. ' +
    'You can change your cookie settings and delete cookies already set at anytime in your browser settings.' +
    ' By using our website you agree to this. You can find further information in our ';
  privacyLabel = 'Data Privacy Agreement';
  privacyLink = '/privacy-policy';
  okLabel = 'OK';

  constructor(public cookieService: CookieService) {
  }

  ngOnInit() {
    this.checkCookies();
  }

  checkCookies() {
    this.show = this.cookieService.get('cookie-bar') !== '1';
  }

  actionOK() {
    this.cookieService.set('cookie-bar', '1');
    this.checkCookies();
  }
}
