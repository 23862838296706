import { HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormBoxes, LookupProps } from '../models';
import {
  defaultContainerBoxes,
  defaultFormBoxes,
  defaultParams,
  defaultParamsForAll,
  defaultSpecificBoxes
} from '../components/create-item/create-item-statics';
import { TabsValues } from '../constants';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  BillOfMaterialsItemUpdateModel,
  BillOfMaterialsResponseModel,
  LookUpResponse,
  LookUpResponseTreeWithDescription,
  MasterItemBillOfMaterialsLookUpRequestModel,
  MasterItemContainerRequestModel,
  MasterItemFullResponseModelMasterItemTypeId,
  MasterItemLookupResponse,
  MasterItemQuantityRequestModel,
  MasterItemRequestModelMasterItemTypeId,
  MasterItemTemplateResponseModel,
  NameExternalIdModel,
  PackageRequestModel,
  PaginatedResponseOfLookUpResponse,
  PaginatedResponseOfLookUpResponseTreeWithDescription,
  SpiritItemRequestModel,
  UnitOfMeasurementResponseModel
} from '../../core/services/Inventory';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { clone, debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { KindOfSpirit } from '@onbatch/shared/components';
import { MasterItemsService } from '../../inventory/master-items/master-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SettingsService } from '../../account/settings/settings.service';
import { FormatsService } from '../services/formats.service';
import { InventoryLookupsService } from '../services/inventory-lookups.service';
import {
  Features2,
  ManufacturingResponseModel,
  ManufacturingResponseModelAlcoholContent
} from '../../core/services/Account';
import { ClickOutsideData } from '../directives';
import {
  InventoryFacilityLookUpResponseModel,
  MasterItemTypeId,
  PaginatedResponseOfInventoryFacilityLookUpResponseModel
} from '@onbatch/core/services/InventoryRx';
import { NgSelectOptionWithDisabled } from '@onbatch/shared/interfaces';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';

export abstract class MasterItemFormClass implements OnDestroy {
  spiritItem: SpiritItemRequestModel = new SpiritItemRequestModel();
  container: MasterItemContainerRequestModel = new MasterItemContainerRequestModel();
  package: PackageRequestModel = new PackageRequestModel();

  // Form boxes
  formBoxes: FormBoxes = defaultFormBoxes;
  specificBoxes: FormBoxes = defaultSpecificBoxes;
  containerBoxes: FormBoxes = defaultContainerBoxes;
  renderFormBoxes = true;

  // Form
  form: FormGroup;
  formSubmitStatus = false;

  // Templates
  loadedTemplate: MasterItemTemplateResponseModel = new MasterItemTemplateResponseModel();
  templates: Array<LookUpResponse>;
  templateListVisible = false;
  templateId: string;
  selectedTemplateId: string;
  renamedTemplateName = '';

  // Locations
  paramsForFacilitiesLookup: LookupProps = defaultParamsForAll;
  locationsDropdownLoading = false;
  lookupFacilitiesPages: number = null;
  locations: MasterItemQuantityRequestModel[] = [];
  lookupLocationResults: Array<NgSelectOptionWithDisabled> = [];
  selectedLocations: Array<NgSelectOptionWithDisabled> = [];
  quantitiesForLocations: Array<number> = [];
  hideAddLocation = false;
  locationValidator: { location: boolean, quantity: boolean, integer: boolean }[] = [];
  paramsForTemplates: LookupProps = defaultParamsForAll;

  // Kind of spirits
  kindOfSpirits: KindOfSpirit[] = [];
  allKindOfSpirits: LookUpResponseTreeWithDescription[] = [];
  paramsForKindOfSpirit: LookupProps = clone(defaultParams);
  lookupKindOfSpiritPages: number = null;

  // Fermentable categories
  isFermentable = false;
  fermentableCat: NameExternalIdModel[] = [];
  fermentableSubCat: NameExternalIdModel[] = [];
  paramsForFermentableLookup: LookupProps = defaultParamsForAll;
  lookupFermentablePages: number = null;


  // Unit of Measurements - UoM
  uom: string = null;
  uomVisible = true;
  masterItemUOM: Array<LookUpResponse> = [];
  containersUOM: Array<LookUpResponse> = [];
  paramsForUoM: LookupProps = defaultParamsForAll;
  lookupUoMPages: number = null;

  // Package
  packageVisible = false;
  isPackageValid = true;

  // Categories
  masterItemCategories: LookUpResponse[] = [];
  paramsForCategories: LookupProps = clone(defaultParams);
  lookupCategoriesPages: number = null;

  // Alcohol
  isAlcohol = false;
  isVariableAlcohol = false;
  variableContentLabel = `Variable Alcohol Content`;

  // Raw materials
  isRawMaterial = true;

  // Barrel
  isBarrel = false;
  isBarrelToasted = false;

  // Container
  containerFormGroup: FormGroup;

  // Specific details
  specificFields;
  specificFormGroup: FormGroup;
  specificDetailsVisible = true;

  // Bill of Materials - BoM
  bomVisible = false;
  addBOMPossible = true;
  isBomModalActive = false;
  addNew = false;
  choosenItem: MasterItemLookupResponse = null;
  isAlcoholInBOM = false;
  alcoholVolume: number;
  itemLookup: Array<MasterItemLookupResponse> = [];
  bill: Array<BillOfMaterialsResponseModel> = [];
  newBill: Array<BillOfMaterialsItemUpdateModel> = [];
  newBillItem: BillOfMaterialsItemUpdateModel = new BillOfMaterialsItemUpdateModel();
  bomValidation: boolean[] = [];
  bomInvalid = false;
  paramsForLookup: LookupProps = defaultParamsForAll;
  lookupPages: number = null;

  // Lookups
  getKindOfSpirit = debounce((value: string) => {
    this.fetchMoreKindOfSpirits(value);
  }, 200);
  getCategories = debounce((value: string) => {
    this.fetchMoreCategories(value);
  }, 200);
  getLocations = debounce((value: string) => {
    this.fetchMoreLocations(value);
  }, 200);

  // UI
  @Input() isOverlay = false;
  masterItemSidebarSubscription: Subscription;
  locationsSubscription: Subscription;
  masterItemSide = false;

  tabs: typeof TabsValues = TabsValues;
  activeTab: TabsValues = this.tabs.GeneralInformation;

  checkedItems: Array<string> = [];

  isFeatureAvailable = new Features2({
    inventory_CriticalStockAlert: this.subscriptionService.getAccess(SubscriptionFeatures.InventoryCriticalStockAlert)
  });

  // Inputs masks
  patternForAmountInteger = this.formatsService.patternForAmountInteger();
  patternForAmount = this.formatsService.patternForAmount();

  readonly itemTypes: typeof MasterItemFullResponseModelMasterItemTypeId = MasterItemFullResponseModelMasterItemTypeId;
  readonly masterItemTypes: typeof MasterItemRequestModelMasterItemTypeId = MasterItemRequestModelMasterItemTypeId;

  routerSubscription: Subscription;
  subscription = new Subscription();

  @ViewChild('typeSelect', { static: false }) select: NgSelectComponent;
  @HostBinding('style.width') private width = '100%';

  protected constructor(public masterItemService: MasterItemsService,
                        public router: Router,
                        public route: ActivatedRoute,
                        public fb: FormBuilder,
                        public toastr: ToastrService,
                        public modalService: NgxSmartModalService,
                        public settingsService: SettingsService,
                        public formatsService: FormatsService,
                        public lookupsService: InventoryLookupsService,
                        public subscriptionService: SubscriptionService,
  ) {
    this.subscription
      .add(this.masterItemService
        .getLocationsAddedToMasterItem()
        .subscribe(locations => {
          this.hideAddLocation = locations.length > 0 && locations.length === this.lookupLocationResults.length;
        }));
  }

  abstract onFormSubmit();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setAlcoholByVolumeValidation(): void {
    this.settingsService.getManufacturingSettings()
      .subscribe((data: ManufacturingResponseModel) => {
        if (data) {
          this.specificFormGroup.controls['alcoholByVolume']
            .setValidators(this.isVariableAlcohol ? null : [
              Validators.required,
              Validators.min(0),
              Validators.max(data.alcoholContent && data.alcoholContent === ManufacturingResponseModelAlcoholContent.Proof ? 200 : 100)
            ]);
          if (data.alcoholContent) {
            this.specificBoxes['alcoholByVolume'].suffix = ` ${data.alcoholContent}`;
            this.specificBoxes['alcoholByVolume'].placeholder = `0.00 ${data.alcoholContent}`;
            this.variableContentLabel = `Variable ${data.alcoholContent}`;
          }
          this.specificFormGroup.controls['alcoholByVolume'].updateValueAndValidity();
        }
      });
  }

  fetchMoreItems(value: string = null): void {
    if (!value || value !== this.paramsForLookup.x_query) {
      this.paramsForLookup.x_query = value;
      this.lookupPages = null;
      this.itemLookup = [];
      this.lookUpItems();
    }
  }

  lookUpItems(): void {
    if (!this.lookupPages || this.paramsForLookup.x_pageNumber <= this.lookupPages) {
      const payload = new MasterItemBillOfMaterialsLookUpRequestModel();
      payload.withoutAlcohol = this.isAlcoholInBOM;
      payload.masterItemType = this.form.controls['masterItemTypeId'].value;
      payload.masterItemToExclude = this.bill.map(bill => bill.externalId);

      this.masterItemService
        .lookUpBillOfMaterials(
          payload,
          this.paramsForLookup.x_query,
          this.paramsForLookup.x_order,
          this.paramsForLookup.x_desc,
          this.paramsForLookup.x_pageNumber,
          this.paramsForLookup.x_pageSize
        )
        .subscribe((res: PaginatedResponseOfLookUpResponse) => {
          this.itemLookup = [...this.itemLookup, ...res.list];
          this.lookupPages = res.pageInfo.totalPages;
          this.addBOMPossible = this.itemLookup.length > 0;
          this.itemLookup.forEach(item => {
            if (this.isAlcoholInBOM) {
              item.quantity = item.quantity * this.alcoholVolume;
            }
          });
        });
    }
  }

  lookupVolumeUoM(): void {
    this.lookupsService
      .lookupUnitsOfMeasurementForContainer(
        this.paramsForUoM.x_pageNumber,
        this.paramsForUoM.x_pageSize,
        this.paramsForUoM.x_query,
        this.paramsForUoM.x_order,
        this.paramsForUoM.x_desc
      )
      .subscribe((res: PaginatedResponseOfLookUpResponse) => {
        this.containersUOM = res.list;
        this.containerBoxes.capacity.label = `Capacity (${this.containersUOM[0].name})`;
      });
  }

  fetchMoreKindOfSpirits(searchValue: string = null): void {
    this.paramsForKindOfSpirit.x_pageNumber++;
    if (searchValue === '' || searchValue && searchValue !== this.paramsForKindOfSpirit.x_query) {
      this.paramsForKindOfSpirit.x_pageNumber = 1;
      this.allKindOfSpirits = [];
    }
    this.paramsForKindOfSpirit.x_query = searchValue;
    this.lookupKindOfSpirit();
  }

  lookupKindOfSpirit(): void {
    if (!this.lookupKindOfSpiritPages || this.paramsForKindOfSpirit.x_pageNumber <= this.lookupKindOfSpiritPages) {
      this.lookupsService
        .lookupKindOfSpirits(
          true,
          this.paramsForKindOfSpirit.x_query,
          this.paramsForKindOfSpirit.x_order,
          this.paramsForKindOfSpirit.x_desc,
          this.paramsForKindOfSpirit.x_pageNumber,
          this.paramsForKindOfSpirit.x_pageSize
        )
        .subscribe((res: PaginatedResponseOfLookUpResponseTreeWithDescription) => {
          this.allKindOfSpirits = [...this.allKindOfSpirits, ...res.list];
          this.kindOfSpirits = this.lookupsService.flattenKindOfSpiritResponse(this.allKindOfSpirits);
          this.lookupKindOfSpiritPages = res.pageInfo.totalPages;
        });
    }
  }

  fetchMoreCategories(searchValue: string = null): void {
    this.paramsForCategories.x_pageNumber++;
    if (searchValue === '' || searchValue && searchValue !== this.paramsForCategories.x_query) {
      this.paramsForCategories.x_pageNumber = 1;
      this.masterItemCategories = [];
      this.formBoxes.category.select['items'] = [];
    }
    this.paramsForCategories.x_query = searchValue;
    this.lookupCategories();
  }

  lookupCategories(): void {
    if (!this.lookupCategoriesPages || this.paramsForKindOfSpirit.x_pageNumber <= this.lookupCategoriesPages) {
      this.masterItemService
        .lookupCategories(
          this.paramsForCategories.x_pageNumber,
          this.paramsForCategories.x_pageSize,
          this.paramsForCategories.x_query,
          this.paramsForCategories.x_order,
          this.paramsForCategories.x_desc
        )
        .subscribe((res: PaginatedResponseOfLookUpResponse) => {
          this.masterItemCategories = [...this.masterItemCategories, ...res.list];
          this.formBoxes.category.select['items'] = this.masterItemCategories;
          this.lookupCategoriesPages = res.pageInfo.totalPages;
        });
    }
  }

  fetchMoreLocations(value: string = null): void {
    this.paramsForFacilitiesLookup.x_query = value;
    this.paramsForFacilitiesLookup.x_pageNumber++;
    if (value && value !== this.paramsForFacilitiesLookup.x_query) {
      this.paramsForFacilitiesLookup.x_pageSize = 1;
    }
    this.lookupLocations();
  }

  lookupLocations(): void {
    if (!this.lookupFacilitiesPages || this.paramsForFacilitiesLookup.x_pageNumber <= this.lookupFacilitiesPages) {
      this.locationsDropdownLoading = true;
      this.lookupsService
        .lookupLocations(
          this.form.controls['masterItemTypeId'].value as MasterItemTypeId,
          this.paramsForFacilitiesLookup.x_query,
          this.paramsForFacilitiesLookup.x_order,
          this.paramsForFacilitiesLookup.x_desc,
          this.paramsForFacilitiesLookup.x_pageNumber,
          this.paramsForFacilitiesLookup.x_pageSize
        )
        .subscribe((locations: PaginatedResponseOfInventoryFacilityLookUpResponseModel) => {
          const setLocations = [];
          this.form.get('locations').value.map(item => setLocations.push(item.location));
          this.lookupLocationResults = [...locations.list.map((item: InventoryFacilityLookUpResponseModel) => {
            return <NgSelectOptionWithDisabled>{
              name: item.name, externalId: item.externalId, disabled: item.isBlocked
            };
          })].filter(item => !setLocations.includes(item.externalId));
          this.lookupFacilitiesPages = locations.pageInfo.totalPages;
          setTimeout(() => (this.locationsDropdownLoading = false), 500);
        });
    }
  }

  removePackErrors() {
    this.isPackageValid = true;
  }

  validateItemQuantity(quantity: number, isEach: boolean): boolean {
    if (isEach) {
      return !Number.isInteger(Number(quantity)) || quantity <= 0;
    } else {
      return isNaN(quantity) || quantity <= 0 || quantity >= 1e10;
    }
  }

  setTab(tab: TabsValues): boolean {
    this.activeTab = tab;
    return false;
  }

  changeFieldState(field: string): boolean {
    if ((field === 'isFermentable' && this.isBarrel) || (field === 'barrel' && this.isFermentable)) {
      return false;
    } else {
      this[field] = !this[field];
      this.updateSpecificValidators(field);
    }
  }

  changeGroupFieldState(field: string): void {
    const newValue = !this.form.controls[field].value;
    this.form.controls[field].setValue(newValue);
  }

  validateBoM(): void {
    this.bomInvalid = false;
    this.bill.forEach((singleBill: BillOfMaterialsResponseModel, index: number) => {
      singleBill.quantity = this.newBill[index].quantity;
      if (this.validateItemQuantity(singleBill.quantity, singleBill.unitOfMeasurement.name === 'Each')) {
        this.bomValidation[index] = true;
        this.bomInvalid = true;
      } else {
        this.bomValidation[index] = false;
      }
    });
  }

  validatePackage(isPacked: boolean): void {
    if (isPacked) {
      if (this.package.numberOfUnits === undefined || this.package.numberOfUnits < 1) {
        this.isPackageValid = false;
        return;
      } else {
        this.isPackageValid = true;
      }
    }
  }

  validateLocations(): void {
    this.locations.map((location, i) => {
      if (!location.facilityExternalId) {
        this.locationValidator[i].location = true;
        return;
      }
      if (!location.quantity) {
        this.locationValidator[i].quantity = true;
        return;
      } else {
        if (this.uom === 'Each') {
          this.locationValidator[i].integer = false;
          if (!Number.isInteger(Number(location.quantity)) || location.quantity <= 0) {
            this.locationValidator[i].integer = true;
            return;
          }
        }
      }
    });
  }

  setKindOfSpiritValue(): void {
    const kindOfSpiritValue = this.specificFormGroup.controls['kindOfSpirit'].value;
    this.kindOfSpirits.forEach(kindOfSpirit => {
      if (kindOfSpirit.externalId === kindOfSpiritValue) {
        this.spiritItem.kindOfSpiritId = kindOfSpirit.originalExternalId;
        this.spiritItem.customKindOfSpiritExternalId = kindOfSpirit.customExternalId;
      }
    });
  }

  onKeydown(event: KeyboardEvent): boolean {
    if (event.keyCode === 13) {
      this.onFormSubmit();
      return false;
    }
  }

  updateSpecificValidators(section: string): void {
    switch (section) {
      case 'isFermentable': {
        const cat = this.specificFormGroup.get('fermentableMaterialCategoryId');
        const subCat = this.specificFormGroup.get('fermentableMaterialSubCategoryId');
        cat.setValidators(this.isFermentable ? Validators.required : null);
        subCat.setValidators(null);
        cat.updateValueAndValidity();
        subCat.updateValueAndValidity();
        break;
      }
      case 'isAlcohol': {
        const kindOfSpirit = this.specificFormGroup.get('kindOfSpirit');
        kindOfSpirit.setValidators(this.isAlcohol ? Validators.required : null);
        kindOfSpirit.updateValueAndValidity();
        const alcoholByVolume = this.specificFormGroup.controls.alcoholByVolume;
        alcoholByVolume.setValidators(this.isAlcohol ? [Validators.min(0.01), Validators.max(100)] : null);
        alcoholByVolume.updateValueAndValidity();
        break;
      }
      case 'isVariableAlcohol': {
        this.specificFormGroup.get('alcoholByVolume').setValue(null);
        this.setAlcoholByVolumeValidation();
        break;
      }
      default:
        return;
    }
  }

  addLocation(): void {
    this.formSubmitStatus = false;
    this.locations.push(new MasterItemQuantityRequestModel);
    this.locationValidator.push({ location: false, quantity: false, integer: false });
  }

  onDeleteLocation(i: number): void {
    this.locations.splice(i, 1);
    this.locationValidator.splice(i, 1);
    this.selectedLocations.splice(i, 1);
    this.masterItemService.setLocationsAddedToMasterItem(this.locations);
  }

  afterLocationChanged(choosenLocation: NgSelectOptionWithDisabled, index: number) {
    if (this.selectedLocations.indexOf(choosenLocation) === -1) {
      this.selectedLocations.push(choosenLocation);
    }
    this.locations[index].facilityExternalId = choosenLocation.externalId;
    this.masterItemService.setLocationsAddedToMasterItem(this.locations);
    if (choosenLocation.externalId) {
      this.locationValidator[index].location = false;
    }
  }


  afterQuantityChanged(quantity: number, index: number, uom: string) {
    this.locations[index].quantity = quantity;
    this.quantitiesForLocations[index] = quantity;
    if (quantity > 0) {
      this.locationValidator[index].quantity = false;
    }
    if (uom === 'Each') {
      this.locationValidator[index].integer = !Number.isInteger(Number(quantity)) || quantity <= 0;
    } else {
      this.locationValidator[index].integer = false;
    }
  }

  lookupFermentable(externalId: string = ''): void {
    this.lookupsService
      .lookupFermentable(externalId, ...Object.values(this.paramsForFermentableLookup))
      .subscribe((res: PaginatedResponseOfLookUpResponse) => {
        if (!externalId) {
          res.list.map(category => {
            this.fermentableCat.push(new NameExternalIdModel({
              name: category.name,
              externalId: category.externalId
            }));
          });
          this.lookupFermentablePages = res.pageInfo.totalPages;
        } else {
          this.specificFormGroup.get('fermentableMaterialSubCategoryId').setValue(null);
          this.fermentableSubCat = [];
          res.list.map(category => {
            this.fermentableSubCat.push(new NameExternalIdModel({
              name: category.name,
              externalId: category.externalId
            }));
          });
        }
      });
  }

  afterFermentableSelected(event: { name: string; externalId: string } | undefined) {
    if (event) {
      this.lookupFermentable(event.externalId);
    }
  }

  totalQty(): number {
    const arrayLoc = this.form.get('locations') as FormArray;
    let arrayValue = 0;
    if (arrayLoc && arrayLoc.controls.length) {
      arrayLoc.controls.map((item: AbstractControl) => {
        arrayValue += item.value.locQuantity;
      });
    } else if (this.locations) {
      this.locations.map((item: MasterItemQuantityRequestModel) => {
        if (item.quantity) {
          arrayValue += +item.quantity;
        }
      });
    }
    return arrayValue;
  }

  focus(): void {
    this.select.focus();
  }

  toggleAddBillItem(isPacked: boolean): boolean {
    this.addNew = true;
    this.newBill = [];
    this.bill.map(item => {
      if (this.isAlcoholInBOM && isPacked && this.package.numberOfUnits) {
        this.newBill.push(<BillOfMaterialsItemUpdateModel>{
          externalId: item.toJSON().externalId,
          quantity: this.package.numberOfUnits
        });
      } else {
        this.newBill.push(<BillOfMaterialsItemUpdateModel>{
          externalId: item.toJSON().externalId,
          quantity: item.quantity
        });
      }
    });
    return false;
  }

  removeSingleBill(id: string): void {
    this.bill = this.bill.filter(item => item.externalId !== id);
    this.newBill = this.bill.map(item => new BillOfMaterialsItemUpdateModel({
      externalId: item.externalId,
      quantity: item.quantity
    }));
  }

  onContainerTypeChange(type: string): void {
    this.isBarrel = type === 'Barrel';
    const species = this.containerFormGroup.get('barrelWoodSpeciesId');
    const dry = this.containerFormGroup.get('barrelDryMethodId');
    const condition = this.containerFormGroup.get('barrelConditionId');

    switch (type) {
      case 'Barrel': {
        species.setValidators(null);
        dry.setValidators(null);
        condition.setValidators(Validators.required);
        break;
      }
      default: {
        species.setValidators(null);
        dry.setValidators(null);
        condition.setValidators(null);
        break;
      }
    }
    this.containerFormGroup.updateValueAndValidity();
  }

  // BOM functions

  openBOMModal(): void {
    if (this.addBOMPossible) {
      this.fetchMoreItems('');
      this.isBomModalActive = true;
    }
  }

  closeAddItem(event: ClickOutsideData): void {
    if (event.value && (event.target as HTMLElement).tagName !== 'use') {
      this.isBomModalActive = false;
    }
  }

  validateBill(item: MasterItemLookupResponse, isPacked: boolean): void {
    if (item) {
      this.choosenItem = item;
      this.newBillItem.externalId = item.externalId;
      if (isPacked && this.choosenItem.isAlcohol || this.isAlcoholInBOM && isPacked && this.package.numberOfUnits) {
        this.newBillItem.quantity = this.package.numberOfUnits;
      } else {
        if (this.package && !!this.package.numberOfUnits) {
          this.newBillItem.quantity = this.package.numberOfUnits;
        } else {
          this.newBillItem.quantity = 0;
        }
      }
    }
  }

  addNewBillItem(selectedItem: MasterItemLookupResponse, isPacked: boolean): void {
    this.validateBill(selectedItem, isPacked);
    if (this.choosenItem.isAlcohol) {
      this.isAlcoholInBOM = this.choosenItem.isAlcohol;
    }
    if (this.newBillItem.quantity <= 0) {
      this.bomValidation.push(true);
    } else {
      this.bomValidation.push(false);
    }

    this.masterItemService
      .getSingle(this.newBillItem.externalId)
      .subscribe(item => {
        const populatedItem = new BillOfMaterialsResponseModel();
        populatedItem.externalId = item.externalId;
        populatedItem.name = item.name;
        populatedItem.itemNumber = item.itemNumberText;
        populatedItem.description = item.description;
        populatedItem.quantity = this.newBillItem.quantity;
        populatedItem.isAlcohol = this.choosenItem.isAlcohol;
        populatedItem.volume = this.choosenItem.volume;
        populatedItem.unitOfMeasurement = new UnitOfMeasurementResponseModel({ name: this.choosenItem.unitOfMeasurement });

        this.bill.push(populatedItem);
        this.newBill.push(this.newBillItem);
        this.newBillItem = new BillOfMaterialsItemUpdateModel();
        if (this.choosenItem.isAlcohol) {
          this.isAlcoholInBOM = this.choosenItem.isAlcohol;
        }
        this.fetchMoreItems();
        this.choosenItem = null;
      });
  }

  removeBillItem(id: string): void {
    const billToRemove = this.bill.find(obj => {
      return obj.externalId === id;
    });
    if (billToRemove.isAlcohol) {
      this.isAlcoholInBOM = false;
    }
    this.removeSingleBill(id);
    this.fetchMoreItems();
  }

  isUnit(uom: string): boolean {
    return uom === 'Unit';
  }
}
