import { Pipe, PipeTransform } from '@angular/core';
import { AuthService, IFormattingSettings } from '../../core/services/auth.service';
import { numberToString } from '@onbatch/shared/utils';

@Pipe({
  name: 'priceWithoutPrecisionFormat'
})
export class PriceWithoutPrecisionFormatPipe implements PipeTransform {
  settings: IFormattingSettings;

  constructor(public authService: AuthService) {
  }

  transform(value: number): string {
    this.settings = this.authService.getFormattingSettingsObservable();

    if (value === undefined) {
      return '';
    }

    const rx = /(\d+)(\d{3})/;
    const _this = this;

    return numberToString(value).replace(/^\d+/, function(w: string) {
      let res = w;
      while (rx.test(res)) {
        res = res.replace(rx, `$1${_this.settings.costPriceFormat.thousands}$2`);
      }
      return res;
    });

  }
}
