import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatsService } from '../../../../shared/services/formats.service';
import { MasterItemService } from '../../../../inventory/master-items/master-items.service';
import { closeCalendar } from '../../../utils';
import { NgSelectOptionWithDisabled } from '@onbatch/shared/interfaces';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import * as moment from 'moment';


@Component({
  selector: 'app-master-item-location',
  templateUrl: './location.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class LocationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formSubmitStatus: boolean;
  @Input() isError: any;
  @Input() index: number;
  @Input() isQuantityError: any;
  @Input() locationsBuffer: Array<NgSelectOptionWithDisabled> = [];
  @Input() locationValidator: { location: boolean, quantity: boolean, integer: boolean }[] = [];
  @Input() selectedLocations: Array<NgSelectOptionWithDisabled> = [];
  @Input() quantitiesForLocations: Array<number> = [];
  @Input() datesForLocations: Array<number> = [];
  @Input() isRawMaterial = false;
  @Input() isSemiFinishedGood: boolean;
  @Input() isFinishedGood: boolean;

  @Output() locationChanged = new EventEmitter<NgSelectOptionWithDisabled>();
  @Output() quantityChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() locationDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() expirationDateChanged: EventEmitter<number> = new EventEmitter<number>();

  dropdownLoading = false;
  isSelectVisible = true;
  choosenLocation: NgSelectOptionWithDisabled;

  patternForAmount = this.formatsService.patternForAmount();

  quantity: number = null;
  expDate: moment.Moment;

  locationsSubscription: Subscription;

  closeDatepicker = closeCalendar;

  @HostBinding('style.width') private width = '100%';

  constructor(private masterItemService: MasterItemService,
              private formatsService: FormatsService
  ) {
  }

  ngOnInit(): void {
    this.locationsSubscription = this.masterItemService
      .getLocationsAddedToMasterItem()
      .subscribe(locations => {
        locations.map(selectedLocation => {
          this.locationsBuffer = this.locationsBuffer.filter(location => location.externalId !== selectedLocation.facilityExternalId);
        });
        if (this.choosenLocation) {
          this.locationsBuffer.push(this.choosenLocation);
        }
      });
  }

  ngOnDestroy(): void {
    this.locationsSubscription.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.selectedLocations.length !== 0 && this.selectedLocations[this.index] && this.selectedLocations[this.index].externalId) {
      this.isSelectVisible = false;
      this.choosenLocation = this.selectedLocations[this.index];
      this.quantity = this.quantitiesForLocations[this.index];
      if (this.datesForLocations[this.index] > 0) {
        this.expDate = moment(this.datesForLocations[this.index] * 1000);
      } else {
        this.expDate = null;
      }
    }
  }


  onLocationChange(choosenLocation: NgSelectOptionWithDisabled) {
    this.choosenLocation = choosenLocation;
    this.isSelectVisible = false;
    this.locationChanged.emit(choosenLocation);
  }

  setQuantity() {
    this.quantityChanged.emit(this.quantity);
  }

  setExpirationDate(date: moment.Moment) {
    this.expDate = date;
    this.expirationDateChanged.emit(this.expDate.unix());
  }
}
