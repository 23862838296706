import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'app-global-small-search',
  templateUrl: './global-small-search.component.html'
})
export class GlobalSmallSearchComponent {

  @Input() name = 'top-search';
  @Input() formClass = 'form master-items__head-form';
  @Input() inputClass = 'form__input form__input--search';
  @Input() wrapperClass = 'master-items__head-search form__search-box';
  @Input() placeholder = 'Table Search';
  @Input() set query(i: string) {
    this.searchTerm = i;
  }

  @Output() triggerNewTerm = new EventEmitter<string>();
  @Output() triggerSearch = new EventEmitter<string>();

  searchTerm = '';
  setSearchQuery = debounce(() => {
    this.setQuery();
  }, 200);

  setQuery(): void {
    if (this.searchTerm && this.searchTerm.length > 1) {
      this.triggerNewTerm.emit(this.searchTerm);
    } else if (this.searchTerm.length === 0 || !this.searchTerm) {
      this.clearSearchInput();
    }
  }

  searchSubmit(): void {
    if (this.searchTerm.length > 1 || (this.searchTerm.length === 0 && this.searchTerm !== this.query)) {
      this.triggerSearch.emit();
    }
  }

  clearSearchInput(event: MouseEvent = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.searchTerm = '';
    this.triggerNewTerm.emit(this.searchTerm);
    this.triggerSearch.emit();
  }
}
