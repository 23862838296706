import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { BaseList, changeTableHeight } from '@onbatch/shared/classes/base-list';
import { AuthService } from '@onbatch/core/services/auth.service';
import { HeaderParamsModel } from '@onbatch/shared/models';
import {
  PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel,
  SellableMasterItemResponseModel,
  PageInfo,
  SalesOrderItemResponseModel,
  PaginatedResponseOfSemiFinishedGoodItemsSelectionListResponseModel,
  SemiFinishedGoodItemsSelectionListResponseModel, SalesOrderItemTransactionResponseModel, ShippedItemRequestModel,
} from '@onbatch/core/services/Sales';
import {
  ShippingItemsDataTable,
  ShippingItemsParamsModel
} from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import { FiltersService } from '../../../../../filters/filters.service';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { SemiFinishedGoodsSelectItemsService } from '@onbatch/shared/services/semi-finished-goods-select-items.service';
import { SFGShipping } from '@onbatch/shared/components/shipping/ship-order-modal/shipment-type.enum';

@Component({
  selector: 'app-semi-finished-goods-manual-selection-list',
  templateUrl: './semi-finished-goods-manual-selection-list.component.html',
  providers: [FiltersService]
})
export class SemiFinishedGoodsManualSelectionListComponent extends BaseList implements OnInit, OnDestroy {
  @Input() customClass: string;
  @Input() item: SalesOrderItemResponseModel;
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;
  @Input() remainingQuantityExceeded: boolean;

  params: HeaderParamsModel = {
    x_query: undefined,
    x_order: 'unitId',
    x_desc: false,
    x_pageNumber: 1,
    x_pageSize: 10
  };

  masterItem: SellableMasterItemResponseModel;
  semiFinishedGoods: PaginatedResponseOfSemiFinishedGoodItemsSelectionListResponseModel;
  dataTableSettings: ShippingItemsDataTable;

  checkedItems: string[] = [];
  remainingItemsQuantity: number = 0;

  get shippedItems(): string[] {
    return this.item
      && this.item.shippedItems
      && this.item.shippedItems.map((item: SalesOrderItemTransactionResponseModel) => item.details[0].itemBatchExternalId);
  }

  private checkedIndices: Set<string> = new Set();
  private subscriptions$: Subscription = new Subscription();

  constructor(public authService: AuthService,
              public filtersService: FiltersService,
              private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService,
              public subscriptionService: SubscriptionService) {
    super(filtersService, subscriptionService);
  }

  ngOnInit(): void {
    this.remainingItemsQuantity = this.getRemainingQuantity();
    this.semiFinishedGoodsSelectItemsService.setMasterItem(this.item.masterItem);
    this.moveSFGShippingToSFGShippingToSubmit();
    this.subscriptions$.add(combineLatest([
      this.semiFinishedGoodsSelectItemsService.getSemiFinishedGoodsPagination(),
      this.semiFinishedGoodsSelectItemsService.getMasterItem(),
      this.semiFinishedGoodsSelectItemsService.getSemiFinishedGoodsDataTableParams(),
      this.semiFinishedGoodsSelectItemsService.getSemiFinishedGoodsShippingToSubmit()
    ])
    .subscribe(([item, masterItem, settings, semiFinishedGoodsShippingToSubmit]: [
        PageInfo,
        SellableMasterItemResponseModel,
        ShippingItemsDataTable,
        Map<string, SFGShipping[]>
      ]) => {
        const shipping = semiFinishedGoodsShippingToSubmit.get(this.item.externalId) || [];
        const itemBatchExternalIds = shipping.map(ship => ship.itemBatchExternalId);
        this.pagination = item;
        this.masterItem = masterItem;
        this.dataTableSettings = settings;
        if (this.dataTableSettings.requestModel && this.dataTableSettings.requestModel.filter && this.activeFilters !== this.dataTableSettings.requestModel.filter) {
          this.afterFiltersApplied(this.dataTableSettings.requestModel.filter, false);
        }
        this.checkedItems = itemBatchExternalIds;
        this.checkedIndices = new Set(itemBatchExternalIds);
        // this.remainingItemsQuantity = this.initRemainingQuantity - this.checkedItems.length;
    }));
    this.subscriptions$.add(this.semiFinishedGoodsSelectItemsService.getListOfSemiFinishedGoods()
      .subscribe((FGData: PaginatedResponseOfSemiFinishedGoodItemsSelectionListResponseModel) => {
        if (JSON.stringify(FGData) !== JSON.stringify(this.semiFinishedGoods)) {
          this.afterFetchList(FGData);
        }
      }));
  }

  ngOnDestroy(): void {
    this.checkedIndices.clear();
    this.subscriptions$.unsubscribe();
    this.semiFinishedGoodsSelectItemsService.semiFinishedGoodsList.next(null);
    this.resetQueryAndPageNumberFromParams();
    this.setParams();
  }

  setQuery(data: string): void {
    this.params.x_query = data;
    this.searchSubmit();
  }

  setFilters(): void {
    this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsDataTableParams(this.item.externalId, {
      params: this.dataTableSettings.params,
      requestModel: {
        filter: this.activeFilters,
        masterItemExternalId: this.item.masterItem.externalId,
        itemsAlreadySelected: [],
        containerMasterItemExternalId: this.item.container.externalId,
        inProcessingOnly: false
      }
    });

    changeTableHeight();
  }

  searchSubmit(): void {
    this.semiFinishedGoodsSelectItemsService.setParamsForSemiFinishedGoods(this.item.externalId, this.params);
  }

  setParams(): void {
    this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsDataTableParams(this.item.externalId, {params: this.dataTableSettings.params});
  }

  setSort(params: ShippingItemsParamsModel): void {
    this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsDataTableParams(this.item.externalId, {
      sort: {
        columnName: params.x_order,
        desc: params.x_desc
      }
    });
  }

  resetQueryAndPageNumberFromParams(): void {
    this.dataTableSettings.params.x_pageNumber = 1;
    this.dataTableSettings.params.x_query = null;
  }

  afterFetchList(list: PaginatedResponseOfFinishedGoodItemsSelectionListResponseModel): void {
    this.semiFinishedGoods = list;
    this.listLoaded = true;
  }

  setSettings(): void {
    this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsDataTableParams(this.item.externalId,{settings: this.dataTableSettings.settings});
  }

  onItemToggled(item: SemiFinishedGoodItemsSelectionListResponseModel, shouldCheckedAll?: boolean) {
    if (shouldCheckedAll) {
      if (!this.checkedIndices.has(item.externalId)) {
        this.checkedIndices.add(item.externalId);
      }
    } else {
      if (this.checkedIndices.has(item.externalId)) {
        this.checkedIndices.delete(item.externalId);
        this.semiFinishedGoodsSelectItemsService.removeSemiFinishedGoodsShippingToSubmit(this.item.externalId, item.externalId);
        return;
      } else {
        this.checkedIndices.add(item.externalId);
      }
    }

    this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsShippingToSubmit(
      this.item.externalId,
      <SFGShipping>{unitId: item.unitId, itemBatchExternalId: item.externalId, itemIndex: this.item.itemIndex}
    );
  }

  changeRemainQuantity(quantity: number): void {
    this.remainingItemsQuantity = quantity;
  }

  checkedItemsListChanged(shouldCheckedAll: boolean) {
    this.semiFinishedGoods.list.forEach((item: SemiFinishedGoodItemsSelectionListResponseModel) => this.onItemToggled(item, shouldCheckedAll));
  }

  private getRemainingQuantity(): number {
    const items = this.item.shippedItems;
    return (!!items && items.length)
      ? items.reduce((quantity: number, item: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel) => {
        return quantity - Number(item.quantity);
      }, this.item.quantity)
      : this.item.quantity;
  }

  private moveSFGShippingToSFGShippingToSubmit() {
    this.semiFinishedGoodsSelectItemsService.moveSemiFinishedGoodsShippingToSemiFinishedGoodsShippingToSubmit(this.item.externalId);
  }
}
