/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./callback-page.component";
import * as i2 from "../../core/services/auth.service";
import * as i3 from "../../shared/services/mixpanel.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-toastr";
import * as i6 from "../../core/services/subscription.service";
var styles_CallbackPageComponent = [];
var RenderType_CallbackPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CallbackPageComponent, data: {} });
export { RenderType_CallbackPageComponent as RenderType_CallbackPageComponent };
export function View_CallbackPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Redirecting..."]))], null, null); }
export function View_CallbackPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-callback-page", [], null, null, null, View_CallbackPageComponent_0, RenderType_CallbackPageComponent)), i0.ɵdid(1, 114688, null, 0, i1.CallbackPageComponent, [i2.AuthService, i3.MixpanelService, i4.Router, i5.ToastrService, i6.SubscriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallbackPageComponentNgFactory = i0.ɵccf("app-callback-page", i1.CallbackPageComponent, View_CallbackPageComponent_Host_0, {}, {}, []);
export { CallbackPageComponentNgFactory as CallbackPageComponentNgFactory };
