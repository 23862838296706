import * as mixpanel from 'mixpanel-browser';
import { AuthService } from '../../core/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class MixpanelService {
    get mixPanelInstance() {
        return MixpanelService.mixPanelInstanceSubject.value;
    }
    init(token, config, name) {
        this.mixPanelInstance.init(token, config, name);
    }
    identify(unique_id) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            this.mixPanelInstance.identify(unique_id);
        }
    }
    register(props, days) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.register(props, days);
            }
            catch (e) { }
        }
    }
    register_once(props, default_value, days) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.register_once(props, default_value, days);
            }
            catch (e) { }
        }
    }
    reset() {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.reset();
            }
            catch (e) { }
        }
    }
    setPeople(prop, callback) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.people.set(prop, callback);
            }
            catch (e) { }
        }
    }
    setGroup(group_key, group_ids, callback) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.set_group(group_key, group_ids, callback);
            }
            catch (e) { }
        }
    }
    track(event_name, properties, optionsOrCallback, callback) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.track(event_name, properties, optionsOrCallback, callback);
            }
            catch (e) { }
        }
    }
    unregister(property) {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            try {
                this.mixPanelInstance.unregister(property);
            }
            catch (e) { }
        }
    }
    logout() {
        if (AuthService.isMixPanelActivated && !AuthService.isImpersonate) {
            this.mixPanelInstance.reset();
        }
    }
}
MixpanelService.mixPanelInstanceSubject = new BehaviorSubject(mixpanel);
MixpanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MixpanelService_Factory() { return new MixpanelService(); }, token: MixpanelService, providedIn: "root" });
