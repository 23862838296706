import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IUser } from '../../core/services/auth.service';
import { CompanySubscriptionResponseModel, UserSettingsResponseModel } from '../../core/services/Account';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { MixPanelEvents } from '@onbatch/shared/constants';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';

@Component({
  selector: 'app-callback-page',
  templateUrl: './callback-page.component.html'
})
export class CallbackPageComponent implements OnInit {
  userSettings: UserSettingsResponseModel = null;

  constructor(public authService: AuthService,
              private mixpanelService: MixpanelService,
              private router: Router,
              private toastr: ToastrService,
              private subscriptionService: SubscriptionService,
  ) {
  }

  ngOnInit() {
    this.authService.completeAuthentication().then(() => {
      this.userSettings = this.authService.currentSettings.value;
      const isAdmin: boolean = this.authService.isAdmin.value;
      const currentSubscription: CompanySubscriptionResponseModel = this.subscriptionService.currentSubscription.value;
      const isSubscriptionPaid: boolean = currentSubscription ? currentSubscription.subscriptionPaid : null;
      const user: IUser = this.authService.getCurrentUser();

      if (isAdmin) {
        return this.router.navigateByUrl('/admin/tenants');
      } else if (this.userSettings && (this.userSettings.firstName || this.userSettings.lastName)) {
        if ((!currentSubscription.externalId || !isSubscriptionPaid) && user.profile.IsOwner !== 'False') {
          this.toastr.warning(`You don't have an active subscription. Please provide the valid billing information and choose one of our plans.`, 'Access denied');
          return this.router.navigateByUrl('/account/billing');
        } else {
          this.mixpanelService.track(MixPanelEvents.SIGN_IN, {});
          return this.router.navigateByUrl('/welcome');
        }
      } else {
        return this.router.navigateByUrl('/account/user-profile/details');
      }
    });
  }
}
