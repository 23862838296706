import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  SalesFacilityLookUpResponseModel,
  ShipmentResponseModel
} from '@onbatch/core/services/Sales';
import { ShippingService } from '../../../services/shipping.service';
import { AuthService } from '@onbatch/core/services/auth.service';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html'
})
export class ShipmentListComponent implements OnInit {
  @Input() shipments: ShipmentResponseModel[];
  @Input() salesOrderExternalId: string;
  @Input() canAddShipment = true;
  @Output() addNewShipment = new EventEmitter<MouseEvent>();
  @Output() filesChanged = new EventEmitter();
  @Output() shipmentRemoved = new EventEmitter();
  @Output() editClicked = new EventEmitter<ShipmentResponseModel>();

  facilities: SalesFacilityLookUpResponseModel[];

  constructor(private shippingService: ShippingService,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.getFacilities();
  }

  onAddNewShipmentClicked(e: MouseEvent) {
    this.addNewShipment.emit(e);
  }

  getFacilities(): void {
    this.shippingService.lookupFacilities()
      .subscribe((facilities => {
        this.facilities = facilities;
      }));
  }
}
