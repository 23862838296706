import * as cytoscape from 'cytoscape';
import {Stylesheet} from 'cytoscape';
import {memoize} from 'lodash';
import {
  ProcessInfoResponseModel,
  ProcessInfoResponseModelFlowMapTransitionProcessType,
  StageInfoResponseModel,
  StageInfoResponseModelOperationAccountType,
} from '../../../core/services/Manufacturing';

interface SVGData {
  svg: string;
  width: number;
  height: number;
}

export interface IFlowMapConfig {
  margin: number;
  nodeWidth: number;
  nodeHeight: number;
  clusterHeight: number;
  splitMergeHeight: number;
  splitMergeWidth: number;
  splitMergeGap: number;
  crossTransferWidth: number;
  crossTransferHeight: number;
}

export const FlowMapConfig: IFlowMapConfig = {
  nodeWidth: 120,
  nodeHeight: 56,
  clusterHeight: 92,
  margin: 8,
  splitMergeHeight: 32,
  splitMergeWidth: 32,
  splitMergeGap: 160,
  crossTransferWidth: 120,
  crossTransferHeight: 40,
};

enum StageState {
  FINISHED,
  IN_PROGRESS,
  PENDING
}

function getStageState(startTime?: number, endTime?: number): StageState {
  if (endTime) {
    return StageState.FINISHED;
  }

  if (!startTime && !endTime) {
    return StageState.PENDING;
  }

  return StageState.IN_PROGRESS;
}

function getStageColours(state: StageState, type?: StageInfoResponseModelOperationAccountType): [string, string] {
  const [background, border] = getOperationAccountColours(type);

  switch (state) {
    case StageState.PENDING: return [background, border];
    case StageState.IN_PROGRESS: return ['#f5f5f5', border];
    case StageState.FINISHED: return ['#f5f5f5', '#d5d5d5'];
  }
}

function getBranchColours(state: StageState, type?: StageInfoResponseModelOperationAccountType): [string, string] {
  const [background, border] = getOperationAccountColours(type);

  switch (state) {
    case StageState.PENDING: return [background, '#d5d5d5'];
    case StageState.IN_PROGRESS: return ['#f5f5f5', border];
    case StageState.FINISHED: return ['#f5f5f5', '#d5d5d5'];
  }
}

function getOperationAccountColours(type?: StageInfoResponseModelOperationAccountType): [string, string] {
  switch (type) {
    case StageInfoResponseModelOperationAccountType.Processing:
      return ['#CFEDD4', '#62C370'];
    case StageInfoResponseModelOperationAccountType.Storage:
      return ['#DFD0ED', '#9663C4'];
    default:
      return ['#FFBF89', '#DC783C'];
  }
}

function getStageTextColours(state: StageState): string {
  if (state === StageState.FINISHED) {
    return '#848484';
  }
  return '#323232';
}

function getProcessInfoSvg(processesInfo: ProcessInfoResponseModel[], textColour: string): string {
  const transformLookup: string[] = [
    'translate(8 49)',
    'translate(40 49)',
    'translate(72 49)',
  ];

  const transferTemplate = (n: number, transform: string) =>
`<g transform="${transform}" fill="${textColour}">
  <path d="m8.5988 0.10069a0.334 0.334 0 0 0 -0.573 0.234v1.529h-7.333a0.667 0.667 0 1 0 0 1.333h7.333v1.527a0.33 0.33 0 0 0 0.567 0.233l2.193-2.193a0.328 0.328 0 0 0 7e-3 -0.467z" transform="translate(9 -7)"/>
  <text font-family="IBM Plex Sans, sans-serif" font-size="12">
    <tspan x="0" y="0">${n}</tspan>
  </text>
</g>`;

  const splitTemplate = (n: number, transform: string) =>
`<g transform="${transform}" fill="${textColour}">
  <path d="m0.667 4.667h4.4l3.133-3.141-0.959-0.959a0.33 0.33 0 0 1 0.233 -0.567h2.86a0.33 0.33 0 0 1 0.333 0.333v2.86a0.329 0.329 0 0 1 -0.56 0.24l-0.96-0.96-3.34 3.334a0.7 0.7 0 0 1 -0.473 0.193h-4.667a0.667 0.667 0 1 1 0 -1.334zm6.553 1.606 1.921 1.921 0.959-0.961a0.333 0.333 0 0 1 0.567 0.241v2.859a0.33 0.33 0 0 1 -0.333 0.334h-2.86a0.334 0.334 0 0 1 -0.24 -0.567l0.96-0.959-1.92-1.921z" transform="translate(9 -9)"/>
  <text font-family="IBM Plex Sans, sans-serif" font-size="12">
    <tspan x="0" y="0">${n}</tspan>
  </text>
</g>`;

  const mergeTemplate = (n: number, transform: string) =>
`<g transform="${transform}" fill="${textColour}">
  <path d="M.197 6.859l1.8-1.8.94.939-1.8 1.8a.665.665 0 01-.94 0 .65.65 0 01-.195-.468.667.667 0 01.195-.471zm0-6.667a.665.665 0 01.94 0l3.133 3.14h3.727V1.806a.333.333 0 01.567-.24l2.193 2.193a.329.329 0 010 .473L8.564 6.426a.33.33 0 01-.567-.233V4.666H4.27a1.322 1.322 0 01-.94-.394L.197 1.133a.665.665 0 010-.94z" transform="translate(9 -8)"/>
  <text font-family="IBM Plex Sans, sans-serif" font-size="12">
    <tspan x="0" y="0">${n}</tspan>
  </text>
</g>`;

  return processesInfo.map((processInfo: ProcessInfoResponseModel, index: number) => {
    const transform: string = transformLookup[index];
    const count: number = processInfo.count;
    switch (processInfo.flowMapTransitionProcessType) {
      case ProcessInfoResponseModelFlowMapTransitionProcessType.Transfer:
        return transferTemplate(count, transform);
      case ProcessInfoResponseModelFlowMapTransitionProcessType.Split:
        return splitTemplate(count, transform);
      case ProcessInfoResponseModelFlowMapTransitionProcessType.Merge:
        return mergeTemplate(count, transform);
      default:
        return '';
    }
  }).join('');
}

const makeNodeSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const w = FlowMapConfig.nodeWidth, h = FlowMapConfig.nodeHeight;
  const processesInfo: ProcessInfoResponseModel[] = elem.data().processesInfo;
  const stageInfo: StageInfoResponseModel = elem.data().stageInfo;
  const processesCount: number = stageInfo.processesCount;
  const stageState = getStageState(stageInfo.startTime, stageInfo.endTime);
  const [stageBackgroundColour, stageBorderColour] = getStageColours(stageState, stageInfo.operationAccountType);
  const textColour = getStageTextColours(stageState);
  let inProgressModifier = '';

  if (stageState === StageState.IN_PROGRESS) {
    const [backgroundColour, borderColour] = getOperationAccountColours(stageInfo.operationAccountType);
    inProgressModifier =
      `<rect x="58" width="62" height="56" rx="4" fill="${backgroundColour}"></rect>
      <rect fill="${borderColour}" x="58" y="1" width="1.5" height="54"></rect>`;
  }

  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.997 55.999" height="${h}" width="${w}">
      <g>
        <rect width="120" height="56" rx="4" fill="${stageBackgroundColour}" />
        <g stroke-miterlimit="10" fill="none" stroke="${stageBorderColour}">
            <rect width="120" height="56" rx="4" stroke="none" />
            ${inProgressModifier}
            <rect x=".5" y=".5" width="119" height="55" rx="3.5" />
        </g>
        <text transform="translate(8 17)" font-size="12" font-weight="600" font-family="IBM Plex Sans, sans-serif" fill="${textColour}">
            <tspan x="0" y="0">${elem.data().name}</tspan>
        </text>
        <text transform="translate(8 33)" font-size="12" font-family="IBM Plex Sans, sans-serif" fill="${textColour}">
            <tspan x="0" y="0">${processesCount} ${processesCount > 1 || processesCount === 0 ? 'processes' : 'process'}</tspan>
        </text>
        ${getProcessInfoSvg(processesInfo, textColour)}
      </g>
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: w, height: h};
});

const makeClusterSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const nStages = elem.children().length;
  const margin = FlowMapConfig.margin;
  const stageWidth = FlowMapConfig.nodeWidth;
  const width = 2 * margin + nStages * stageWidth + (nStages - 1) * margin;
  const height = FlowMapConfig.clusterHeight;

  const startTime: number = elem.data().startTime;
  const endTime: number = elem.data().endTime;
  const operationAccount: StageInfoResponseModelOperationAccountType = elem.data().operationAccount;
  const stageState = getStageState(startTime, endTime);
  const [stageBackgroundColour, stageBorderColour] = getBranchColours(stageState, operationAccount);

  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} 88.117" width="${width}" height="${height}">
      <g transform="translate(0 16.12)" fill="none" stroke="${stageBorderColour}" stroke-miterlimit="10">
          <rect width="${width}" height="72" rx="4" stroke="none" />
          <rect x=".5" y=".5" width="${width - 1}" height="71" rx="3.5" />
      </g>
      <text transform="translate(16 9.12)" fill="#848484" font-family="IBMPlexSans, 'IBM Plex Sans', sans-serif" font-size="12">
          <tspan x="0" y="0">${elem.data().name}</tspan>
      </text>
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: width, height: height};
});

const makeGroupSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const width = 100;
  const height = 100;
  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} 88.117" width="${width}" height="${height}">
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: width, height: height};
});

const makeSplitSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const w = FlowMapConfig.splitMergeWidth, h = FlowMapConfig.splitMergeHeight;
  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg viewBox="0 0 ${w} ${h}" xmlns="http://www.w3.org/2000/svg" width="${w}" height="${h}">
      <defs>
        <clipPath id="a">
            <path d="M0 0h16v16H0z" fill="none" />
        </clipPath>
      </defs>
      <circle cx="16" cy="16" r="16" fill="#fff" />
      <g stroke-miterlimit="10" fill="none" stroke="#d5d5d5">
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15.5" />
      </g>
      <path d="M8 8h16v16H8z" fill="none" />
      <g clip-path="url(#a)" transform="translate(8 8)">
        <path d="M3.333 7.334h4.4l3.133-3.141-.959-.959a.33.33 0 01.233-.567H13a.33.33 0 01.333.333v2.86a.329.329 0 01-.56.24l-.96-.96-3.34 3.334A.7.7 0 018 8.667H3.333a.667.667 0 110-1.334zM9.886 8.94l1.921 1.921.959-.961a.333.333 0 01.567.241V13a.33.33 0 01-.333.334h-2.86a.334.334 0 01-.24-.567l.96-.959-1.92-1.921z" fill="#848484" />
      </g>
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: w, height: h};
});

const makeMergeSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const w = FlowMapConfig.splitMergeWidth, h = FlowMapConfig.splitMergeHeight;
  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg viewBox="0 0 ${w} ${h}" xmlns="http://www.w3.org/2000/svg" width="${w}" height="${h}">
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" fill="none"/>
        </clipPath>
      </defs>
      <circle r="16" cy="16" cx="16" fill="#fff"/>
      <g stroke-miterlimit="10" fill="none" stroke="#d5d5d5">
        <circle r="16" cy="16" cx="16" stroke="none"/>
        <circle r="15.5" cy="16" cx="16"/>
      </g>
      <path d="M8 8h16v16H8z" fill="none"/>
      <g clip-path="url(#a)" transform="translate(8 8)">
        <path d="M2.867 10.86l1.8-1.8.94.938-1.8 1.8a.665.665 0 01-.94 0 .651.651 0 01-.2-.468.667.667 0 01.2-.47zm0-6.662a.663.663 0 01.94 0l3.133 3.14h3.727V5.806a.333.333 0 01.567-.239l2.193 2.193a.33.33 0 010 .473l-2.193 2.194a.33.33 0 01-.567-.234V8.666H6.94A1.325 1.325 0 016 8.273l-3.133-3.14a.663.663 0 010-.94z" fill="#848484"/>
      </g>
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: w, height: h};
});

const makeCrossTransferSvg = memoize((elem: cytoscape.NodeSingular): SVGData => {
  const width = FlowMapConfig.crossTransferWidth, height = FlowMapConfig.crossTransferHeight;
  const batchName = elem.data().name;
  const vesselName = elem.data().equipmentName;
  const borderColour = '#176DE6';
  const batchNameColour = '#323232';
  const vesselNameColour = '#848484';

  const s =
    `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}">
      <g transform="translate(0 0)" fill="none" stroke="${borderColour}" stroke-miterlimit="10">
          <rect width="${width}" height="${height}" rx="4" stroke="none" />
          <rect x=".5" y=".5" width="${width - 1}" height="${height - 1}" rx="3.5" />
      </g>
      <text transform="translate(8 16)" font-size="12" font-family="IBM Plex Sans, sans-serif" fill="${batchNameColour}">
         <tspan x="0" y="0">${batchName}</tspan>
      </text>
      <text transform="translate(8 32)" font-size="12" font-family="IBM Plex Sans, sans-serif" fill="${vesselNameColour}">
         <tspan x="0" y="0">${vesselName}</tspan>
      </text>
    </svg>`;
  const data: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(s);
  return {svg: data, width: width, height: height};
});

export const flowMapStyle: Stylesheet[] = [
  {
    selector: 'node',
    css: {
      'background-color': 'white',
      'background-image': (ele: cytoscape.NodeSingular) => makeNodeSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeNodeSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeNodeSvg(ele).height,
      'background-fit': 'cover cover',
      'shape': 'rectangle',
    },
  },
  {
    selector: 'node[type="cluster"]',
    css: {
      'background-color': 'white',
      'background-image': (ele: cytoscape.NodeSingular) => makeClusterSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeClusterSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeClusterSvg(ele).height,
      'background-fit': 'cover cover',
      'background-clip': 'none',
      'background-offset-y': '50%',
      'border-opacity': '0',
      'bounds-expansion': '20px'
    },
  },
  {
    selector: 'node[type="group"]',
    css: {
      'background-color': 'white',
      'background-image': (ele: cytoscape.NodeSingular) => makeGroupSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeGroupSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeGroupSvg(ele).height,
      'padding': '0px',
      'border-opacity': '0',
    },
  },
  {
    selector: 'node[type="split"]',
    css: {
      'background-image': (ele: cytoscape.NodeSingular) => makeSplitSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeSplitSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeSplitSvg(ele).height,
      'background-clip': 'none',
      'background-offset-y': '50%',
      'border-opacity': '0',
    },
  },
  {
    selector: 'node[type="merge"]',
    css: {
      'background-image': (ele: cytoscape.NodeSingular) => makeMergeSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeMergeSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeMergeSvg(ele).height,
      'background-clip': 'none',
      'background-offset-y': '50%',
      'border-opacity': '0',
    },
  },
  {
    selector: 'node[type="cross-transfer"]',
    css: {
      'background-image': (ele: cytoscape.NodeSingular) => makeCrossTransferSvg(ele).svg,
      'width': (ele: cytoscape.NodeSingular) => makeCrossTransferSvg(ele).width,
      'height': (ele: cytoscape.NodeSingular) => makeCrossTransferSvg(ele).height,
      'background-clip': 'none',
      'background-offset-y': '50%',
      'border-opacity': '0',
    },
  },
  {
    selector: 'edge',
    css: {
      'curve-style': 'taxi',
      // 'taxi-turn': '-8px',
      'taxi-direction': 'horizontal',
      'target-arrow-shape': 'triangle',
      'width': '1px',
      'line-color': '#d5d5d5',
      'target-arrow-color': '#d5d5d5',
    }
  },
  {
    selector: 'edge[type="same-branch"]',
    css: {
      'curve-style': 'taxi',
      'target-arrow-shape': 'none',
      'taxi-direction': 'horizontal',
      'width': '1px',
      'line-color': '#d5d5d5',
    }
  },
  {
    selector: 'edge[type="cross-transfer"]',
    css: {
      'curve-style': 'taxi',
      'target-arrow-shape': 'triangle',
      // @ts-ignore
      'taxi-direction': 'horizontal',
      'width': '1px',
      'line-color': '#1E78DC',
      'target-arrow-color': '#1E78DC',
      'arrow-scale': 0.75,
    }
  }
] as Stylesheet[];
