<div *ngIf="formSubmitted && errors" class="form__error-msg">
  <div *ngIf="errors.required">This field is required</div>
  <div *ngIf="errors.min">Value cannot be lower than {{errors.min.min}}</div>
  <div *ngIf="errors.max">Value cannot be greater than {{errors.max.max}}</div>
  <div *ngIf="errors.requiredIf">This field is required</div>
  <div *ngIf="errors.email">This email is not valid</div>
  <div *ngIf="errors.pattern">{{patternError || 'Value is not valid'}}</div>
  <div *ngIf="errors.errorMessage">{{ errors.errorMessage }}</div>
  <div *ngIf="errors.minlength">Minimum length of value is {{errors.minlength.requiredLength}}</div>
  <div *ngIf="errors.maxlength">Maximum length of value is {{errors.maxlength.requiredLength}}</div>
  <div *ngIf="errors.unitIdExists">Unit ID already exists</div>
</div>
