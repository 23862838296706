import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from '@onbatch/core/services/auth.service';
import { EnvService } from '@onbatch/core/services/Env';
import {
  EquipmentFullResponse,
  EquipmentModelResponse,
  EquipmentModelTypeId,
  EquipmentResponse,
  EquipmentStatisticResponse,
  ExcludedIdsFilterRequestApplyModel,
  FileResponse,
  InsertEquipmentRequest,
  ManufacturingClient,
  ManufacturingFacilityLookUpResponseModel,
  PageInfo,
  PaginatedResponseOfEquipmentResponse,
  PaginatedResponseOfVesselResponse,
  UpdateEquipmentRequest, VesselResponse, VesselStateTypeId,
} from '@onbatch/core/services/Manufacturing';
import { VesselLookupParams } from '@onbatch/shared/components/equipment-library/equipment-library.helpers';
import { ObjectDeepCopyService } from '@onbatch/shared/services/object-deep-copy.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ManagementEquipmentDataTableDefaults, RetiredEquipmentDataTableDefaults } from './equipment.constants';
import { IEquipmentDataTable } from './equipment.interfaces';
import { AvailableEquipmentResponse } from '../batches/shared/batches.interfaces';

@Injectable({ providedIn: 'root' })
export class EquipmentService {
  equipmentOverlayProps = new Subject<EquipmentOverlayProps>();
  selectedEquipment = new Subject<VesselResponse | AvailableEquipmentResponse>();

  private equipmentList$ = new BehaviorSubject<PaginatedResponseOfEquipmentResponse>(null);
  private equipment$ = new BehaviorSubject<EquipmentFullResponse>(null);
  private equipmentDuplicated$ = new BehaviorSubject<EquipmentFullResponse>(null);
  private managementEquipmentDataTableParams$ = new BehaviorSubject<IEquipmentDataTable>(null);
  private retiredEquipmentDataTableParams$ = new BehaviorSubject<IEquipmentDataTable>(null);
  private managementEquipmentPagination$ = new BehaviorSubject<PageInfo>(null);
  private retiredEquipmentPagination$ = new BehaviorSubject<PageInfo>(null);
  private equipmentStatistics$ = new BehaviorSubject<EquipmentStatisticResponse>(null);

  equipmentList = this.equipmentList$.asObservable();
  equipment = this.equipment$.asObservable();
  equipmentDuplicated = this.equipmentDuplicated$.asObservable();
  managementEquipmentDataTableParams = this.managementEquipmentDataTableParams$.asObservable();
  retiredEquipmentDataTableParams = this.retiredEquipmentDataTableParams$.asObservable();
  managementEquipmentPagination = this.managementEquipmentPagination$.asObservable();
  retiredEquipmentPagination = this.retiredEquipmentPagination$.asObservable();
  equipmentStatistics = this.equipmentStatistics$.asObservable();

  readonly defaultLookupParams = {
    equipmentModelTypeId: EquipmentModelTypeId.MashTun,
    filterRequest: new ExcludedIdsFilterRequestApplyModel({
      filters: [],
      externalIdsToExclude: [],
    }),
    facilityExternalId: '',
    vesselStateTypeId: VesselStateTypeId.Empty,
    batchExternalId: undefined,
    processDate: undefined,
    x_query: '',
    x_order: 'equipmentName',
    x_desc: false,
    x_pageNumber: null,
    x_pageSize: null,
  };

  private vesselLookupParams$ = new BehaviorSubject<VesselLookupParams>(this.defaultLookupParams);
  private vesselList$ = new BehaviorSubject<PaginatedResponseOfVesselResponse>(null);

  vesselLookupParams = this.vesselLookupParams$.asObservable();
  vesselList = this.vesselList$.asObservable();

  private equipmentClient: ManufacturingClient;

  constructor(
    http: HttpClient,
    envService: EnvService,
    private localStorage: LocalStorage,
    private deepCopyService: ObjectDeepCopyService,
    private authService: AuthService
  ) {
    this.equipmentClient = new ManufacturingClient(http, envService.apiUrl);
    this.getInitialManagementEquipment();
    this.getInitialRetiredEquipment();
    this.getInitialVessel();
  }

  getInitialManagementEquipment() {
    this.localStorage
      .getItem(this.authService.getStorageKey('management-equipment-data-table'))
      .subscribe((data: IEquipmentDataTable | null) => {
        this.setManagementDataTableParams(data || ManagementEquipmentDataTableDefaults, true);
      });
  }

  getInitialRetiredEquipment() {
    this.localStorage
      .getItem(this.authService.getStorageKey('retired-equipment-data-table'))
      .subscribe((data: IEquipmentDataTable | null) => {
        this.setRetiredDataTableParams(data || RetiredEquipmentDataTableDefaults, true);
      });
  }

  getInitialVessel() {
    this.vesselLookupParams
      .pipe(switchMap((params: VesselLookupParams) => this.lookupEquipment(params)))
      .subscribe((equipments: PaginatedResponseOfVesselResponse) => this.vesselList$.next(equipments));
  }

  getLookupEquipment(): Observable<PaginatedResponseOfVesselResponse> {
    return this.vesselList;
  }

  getVesselLookupParams(): Observable<VesselLookupParams> {
    return this.vesselLookupParams;
  }

  setLookupParams(params: VesselLookupParams) {
    this.vesselLookupParams$.next(params);
  }

  resetLookupParams() {
    const batchId = this.vesselLookupParams$.value.batchExternalId;
    const excludedIds = this.vesselLookupParams$.value.filterRequest.externalIdsToExclude;
    this.setLookupParams({
      ...this.defaultLookupParams,
      batchExternalId: batchId,
      filterRequest: new ExcludedIdsFilterRequestApplyModel({
        ...this.defaultLookupParams.filterRequest,
        externalIdsToExclude: excludedIds,
      }),
    });
  }

  resetVesselExcludedIds(): void {
    const vesselLookupParams = this.vesselLookupParams$.value;
    vesselLookupParams.filterRequest.externalIdsToExclude = [];
    this.vesselLookupParams$.next(vesselLookupParams);
  }

  fetchManagementEquipment() {
    const params = this.managementEquipmentDataTableParams$.value.params;
    const filters = this.managementEquipmentDataTableParams$.value.filters;
    const requestParams: (any | string | null | undefined)[] = [
      params.x_query,
      params.x_order,
      params.x_desc,
      params.x_pageNumber,
      params.x_pageSize,
    ];
    this.equipmentClient
      .manufacturingEquipmentFilterPut(filters, true, ...requestParams)
      .subscribe((data: PaginatedResponseOfEquipmentResponse) => {
        this.setEquipmentList(data);
        this.setManagementEquipmentPagination(data.pageInfo);
        this.fetchEquipmentStatistic();
      });
  }

  fetchRetiredEquipment() {
    const params = this.retiredEquipmentDataTableParams$.value.params;
    const filters = this.retiredEquipmentDataTableParams$.value.filters;
    const requestParams: (any | string | null | undefined)[] = [
      params.x_query,
      params.x_order,
      params.x_desc,
      params.x_pageNumber,
      params.x_pageSize,
    ];
    this.equipmentClient
      .manufacturingEquipmentFilterPut(filters, false, ...requestParams)
      .subscribe((data: PaginatedResponseOfEquipmentResponse) => {
        this.setEquipmentList(data);
        this.setRetiredEquipmentPagination(data.pageInfo);
        this.fetchEquipmentStatistic(true);
      });
  }

  fetchEquipment(id: string) {
    this.equipmentClient
      .manufacturingEquipmentByEquipmentExternalIdGet(id)
      .subscribe((equipment) => this.setSingleEquipment(equipment));
  }

  addNewEquipment() {
    const newEquipment = new EquipmentFullResponse();
    this.setSingleEquipment(newEquipment);
  }

  setDuplicateEquipment(equipment: EquipmentFullResponse) {
    const newEquipment = this.deepCopyService.deepCopy(equipment);

    newEquipment.externalId = null;
    newEquipment.assetNumber = null;
    newEquipment.serialNumber = null;
    newEquipment.purchaseDate = null;
    newEquipment.placeInServiceDate = null;

    this.equipmentDuplicated$.next(newEquipment);
  }

  setSingleEquipment(data: EquipmentFullResponse) {
    this.equipment$.next(data);
  }

  setEquipmentList(equipmentList: PaginatedResponseOfEquipmentResponse) {
    this.equipmentList$.next(equipmentList);
  }

  clearSingleEquipment() {
    this.equipment$.next(null);
  }

  removeEquipment(equipmentId: string) {
    return this.equipmentClient.manufacturingEquipmentDeleteEquipmentPut(equipmentId);
  }

  insertEquipment(equipment: InsertEquipmentRequest): Observable<EquipmentResponse> {
    return this.equipmentClient.manufacturingEquipmentInsertEquipmentPost(equipment);
  }

  updateEquipment(externalId: string, equipment: UpdateEquipmentRequest): Observable<void> {
    return this.equipmentClient.manufacturingEquipmentUpdateEquipmentPut(externalId, equipment);
  }

  getEquipmentModels(): Observable<EquipmentModelResponse[]> {
    return this.equipmentClient.manufacturingEquipmentGetEquipmentModelsGet();
  }

  retireEquipment(externalId: string): Observable<void> {
    return this.equipmentClient.manufacturingEquipmentRetireEquipmentPut(externalId);
  }

  unretireEquipment(externalId: string): Observable<void> {
    return this.equipmentClient.manufacturingEquipmentUnRetireEquipmentPut(externalId);
  }

  setOutOfOrderEquipment(externalId: string, outOfOrderStatus: boolean): Observable<void> {
    return this.equipmentClient.manufacturingEquipmentSetOutOfOrderStatusPut(externalId, outOfOrderStatus);
  }

  clearDuplicateEquipment() {
    this.equipmentDuplicated$.next(null);
  }

  fetchEquipmentStatistic(isRetired?: boolean) {
    this.equipmentClient
      .manufacturingEquipmentGetEquipmentStatisticGet(!isRetired)
      .subscribe((statistic: EquipmentStatisticResponse) => this.equipmentStatistics$.next(statistic));
  }

  getEquipmentImage(externalId: string): Observable<FileResponse> {
    return this.equipmentClient.manufacturingEquipmentEquipmentModelImageByExternalIdGet(externalId);
  }

  /* Data Table params */

  setManagementDataTableParams(dataTableParams: any, initialTable?: boolean) {
    const newParamsValue = { ...this.managementEquipmentDataTableParams$.value, ...dataTableParams };
    this.managementEquipmentDataTableParams$.next(newParamsValue);
    const key = this.authService.getStorageKey('management-equipment-data-table');
    this.localStorage.setItemSubscribe(key, newParamsValue);
    if (!initialTable) {
      this.fetchManagementEquipment();
    }
  }

  setRetiredDataTableParams(dataTableParams: any, initialTable?: boolean) {
    const newParamsValue = { ...this.retiredEquipmentDataTableParams$.value, ...dataTableParams };
    this.retiredEquipmentDataTableParams$.next(newParamsValue);
    const key = this.authService.getStorageKey('management-equipment-data-table');
    this.localStorage.setItemSubscribe(key, newParamsValue);
    if (!initialTable) {
      this.fetchRetiredEquipment();
    }
  }

  setManagementEquipmentPagination(pagination: PageInfo) {
    this.managementEquipmentPagination$.next(pagination);
  }

  setRetiredEquipmentPagination(pagination: PageInfo) {
    this.retiredEquipmentPagination$.next(pagination);
  }

  getFacilities(): Observable<ManufacturingFacilityLookUpResponseModel[]> {
    return this.equipmentClient.manufacturingLookUpFacilitiesGet();
  }

  generateLabel(externalId: string): Observable<FileResponse> {
    return this.equipmentClient.manufacturingEquipmentGenerateLabelByExternalIdGet(externalId);
  }

  private lookupEquipment(params: VesselLookupParams): Observable<PaginatedResponseOfVesselResponse> {
    return this.equipmentClient.manufacturingLookUpVesselsByEquipmentModelTypeIdPut(
      params.equipmentModelTypeId,
      params.filterRequest,
      params.facilityExternalId,
      params.vesselStateTypeId,
      params.batchExternalId,
      params.processDate,
      params.x_query,
      params.x_order,
      params.x_desc,
      params.x_pageNumber,
      params.x_pageSize
    );
  }
}

export interface EquipmentOverlayProps {
  isVesselsLibraryOpen: boolean;
  disableActiveVessels: boolean;
  disableWipVessels: boolean;
}
