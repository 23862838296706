
<div class="page">
  <app-sidebar></app-sidebar>
  <main role="main" class="page__content">
    <router-outlet></router-outlet>
    <form *ngIf="createItemForm" name="form" class="create-item__form" [formGroup]="createItemForm">
      <div class="ui-kit__wrapper">
        <div class="ui-kit__col">
          <h2>Buttons</h2>
          <h3>Ghost button</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--blank'" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank'" [isActive]="true" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank'" [disabled]="true" [label]="'Label'"></app-button>
          </div>
          <h3>Ghost button icon left</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--blank btn--with-icon'" [icon]="'edit'" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon'" [icon]="'edit'" [isActive]="true"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon'" [icon]="'edit'" [disabled]="true"
                        [label]="'Label'"></app-button>
          </div>
          <h3>Ghost button icon right</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--blank btn--with-icon-right'" [iconRight]="true" [icon]="'edit'"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon-right'" [iconRight]="true" [icon]="'edit'"
                        [isActive]="true" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon-right'" [iconRight]="true" [icon]="'edit'"
                        [disabled]="true" [label]="'Label'"></app-button>
          </div>
          <h3>Ghost button with dropdown</h3>
          <div class="ui-kit__row">
            <div class="options-menu__wrap">
              <app-button [class]="'btn btn--blank'" [drop]="true" [label]="'Label'"
                          (click)=openExampleDropdown()></app-button>
              <div class="options__menu create-item__template-menu" *ngIf="exDropVisible">
                <div class="options-menu__box">
                  <div class="options-menu__item options-menu__item--with-actions options-menu__item--bd-bot">
                    <app-button [class]="'btn btn--menu-item options-menu__option'"
                                [label]="'Action Bot Line'"></app-button>
                    <div class="options-menu__template-actions">
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'edit'"></app-button>
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'delete-outline'"></app-button>
                    </div>
                  </div>
                  <div class="options-menu__item options-menu__item--with-actions">
                    <app-button [class]="'btn btn--menu-item options-menu__option'" [label]="'Action 1'"></app-button>
                    <div class="options-menu__template-actions">
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'edit'"></app-button>
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'delete-outline'"></app-button>
                    </div>
                  </div>
                  <div class="options-menu__item options-menu__item--with-actions">
                    <app-button [class]="'btn btn--menu-item options-menu__option'" [label]="'Action 2'"></app-button>
                    <div class="options-menu__template-actions">
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'edit'"></app-button>
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'delete-outline'"></app-button>
                    </div>
                  </div>
                  <div class="options-menu__item options-menu__item--with-actions">
                    <app-button [class]="'btn btn--menu-item options-menu__option'" [label]="'Action 3'"></app-button>
                    <div class="options-menu__template-actions">
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'edit'"></app-button>
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'delete-outline'"></app-button>
                    </div>
                  </div>
                  <div class="options-menu__item options-menu__item--with-actions options-menu__item--bd-top">
                    <app-button [class]="'btn btn--menu-item options-menu__option'"
                                [label]="'Action Top Line'"></app-button>
                    <div class="options-menu__template-actions">
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'edit'"></app-button>
                      <app-button [class]="'btn btn--only-icon options-menu__template-action'"
                                  [icon]="'delete-outline'"></app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-button [class]="'btn btn--blank'" [drop]="true" [isActive]="true" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank'" [drop]="true" [disabled]="true" [label]="'Label'"></app-button>
          </div>
          <h3>Ghost button with icon and dropdown</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--blank btn--with-icon'" [drop]="true" [icon]="'edit'"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon'" [drop]="true" [icon]="'edit'" [isActive]="true"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--blank btn--with-icon'" [drop]="true" [icon]="'edit'" [disabled]="true"
                        [label]="'Label'"></app-button>
          </div>
          <h3>Icon only</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--only-icon'" [icon]="'filter'"></app-button>
            <app-button [class]="'btn btn--only-icon'" [icon]="'filter'" [isActive]="true"></app-button>
            <app-button [class]="'btn btn--only-icon'" [icon]="'filter'" [disabled]="true"></app-button>
          </div>
          <h3>Primary button</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--orange'" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange'" [isActive]="true" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange'" [disabled]="true" [label]="'Label'"></app-button>
          </div>
          <h3>Primary button icon left</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--orange btn--with-icon'" [icon]="'plus'" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange btn--with-icon'" [icon]="'plus'" [isActive]="true"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange btn--with-icon'" [icon]="'plus'" [disabled]="true"
                        [label]="'Label'"></app-button>
          </div>
          <h3>Primary button icon right</h3>
          <div class="ui-kit__row">
            <app-button [class]="'btn btn--orange btn--with-icon-right'" [iconRight]="true" [icon]="'plus'"
                        [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange btn--with-icon-right'" [iconRight]="true" [icon]="'plus'"
                        [isActive]="true" [label]="'Label'"></app-button>
            <app-button [class]="'btn btn--orange btn--with-icon-right'" [iconRight]="true" [icon]="'plus'"
                        [disabled]="true" [label]="'Label'"></app-button>
          </div>
          <h2>Input Fields</h2>
          <h3>Standard inpult field with label</h3>
          <div class="ui-kit__col">
            <app-form-box [options]="formBoxes.email" [formControlNameValue]="formBoxes.email.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <app-form-box [options]="formBoxes.emailExtra" [formControlNameValue]="formBoxes.emailExtra.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <app-form-box [options]="formBoxes.emailDis" [formControlNameValue]="formBoxes.emailDis.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <h3>Standard inpult field with label and helpers</h3>
            <app-form-box [options]="formBoxes.withhelper" [formControlNameValue]="formBoxes.withhelper.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <app-form-box [options]="formBoxes.withhelperDis" [formControlNameValue]="formBoxes.withhelperDis.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <h3>Textarea</h3>
            <app-form-box [options]="formBoxes.textarea" [formControlNameValue]="formBoxes.textarea.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <h2>Input Fields With Extras</h2>
            <app-form-box [options]="formBoxes.timer" [formControlNameValue]="formBoxes.timer.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"
                          (onExtraBtnClick)="changeExtraProp($event)"></app-form-box>
          </div>
        </div>
        <div class="ui-kit__col">
          <h2>Checkbox</h2>
          <h2>Radio Button</h2>
          <h2>Toggle</h2>
          <ng-container *ngFor="let switch of toggleGroup">
            <div class="ui-kit__row">
              <app-toggle [toggleOptions]="switch" (toggleChangeState)="toggle($event)"></app-toggle>
            </div>
          </ng-container>
          <h2>Notification Buttons</h2>
          <app-button [class]="'btn btn--blank'" [label]="'Positive notification'"
                      (click)="positiveNotification()"></app-button>
          <app-button [class]="'btn btn--blank'" [label]="'Negative notification'"
                      (click)="negativeNotification()"></app-button>
          <app-button [class]="'btn btn--blank'" [label]="'Info notification'"
                      (click)="infoNotification()"></app-button>
          <h2>Table Settings</h2>
          <div class="ui-kit__anchor">
            <div class="settings__wrap">
              <app-button [class]="'btn btn--only-icon btn--util master-items__head-btn'"
                          [icon]="'settings'" [isActive]="settingsPanel" click-outside
                          (clickOutside)="openSettings($event)"></app-button>
            </div>
            <app-settings-dropdown *ngIf="settingsPanel"
                                   [optionList]="settingsList"
                                   [open]="settingsPanel"
                                   [displayRemoved]="displayRemovedStatus"
                                   (displayChange)="changeDisplay($event)"
                                   (close)="toggleSettings()"
            ></app-settings-dropdown>
          </div>
          <h2>Inline Actions</h2>
          <div class="table__body-cell table__body-cell--options"
               (mouseenter)="toggleContextMenu($event, true)"
               (mouseleave)="toggleContextMenu($event, false)">
            <app-button icon="more-vertical" [class]="'btn btn--only-icon'"></app-button>
            <div class="options-menu table__options-menu">
              <div class="options-menu__arrow"></div>
              <div class="options-menu__box">
                <div class="options-menu__item">
                  <app-button (click)="triggerAction('edit', '5432-fda45a-t4qwv')"
                              [class]="'btn btn--utility options-menu__option'" [label]="'Edit'"
                              icon="edit"></app-button>
                </div>
                <div class="options-menu__item">
                  <app-button (click)="triggerAction('duplicate', '5432-fda45a-t4qwv')"
                              [class]="'btn btn--utility options-menu__option'" [label]="'Duplicate'"
                              icon="duplicate"></app-button>
                </div>
                <div class="options-menu__item">
                  <app-button (click)="triggerAction('transfer', '5432-fda45a-t4qwv')"
                              [class]="'btn btn--utility options-menu__option'" [label]="'Transfer Qty'"
                              icon="transfer-arrows"></app-button>
                </div>
                <div class="options-menu__item options-menu__item--has-sub">
                  <app-button [class]="'btn btn--utility options-menu__option'" [label]="'Download as'"
                              icon="import"></app-button>
                  <div class="options-menu__submenu">
                    <div class="options-menu__box">
                      <div class="options-menu__item">
                        <app-button (click)="triggerAction('pdf', '5432-fda45a-t4qwv')"
                                    [class]="'btn btn--utility options-menu__option'"
                                    [label]="'PDF document (.pdf)'"></app-button>
                      </div>
                      <div class="options-menu__item">
                        <app-button (click)="triggerAction('xlsx', '5432-fda45a-t4qwv')"
                                    [class]="'btn btn--utility options-menu__option'"
                                    [label]="'Microsoft Excel (.xlsx)'"></app-button>
                      </div>
                      <div class="options-menu__item">
                        <app-button (click)="triggerAction('csv', '5432-fda45a-t4qwv')"
                                    [class]="'btn btn--utility options-menu__option'"
                                    [label]="'Comma Separated Values (.csv)'"></app-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="options-menu__box">
                <div class="options-menu__item">
                  <app-button (click)="triggerAction('archive', '5432-fda45a-t4qwv', itemActive)"
                              [class]="'btn btn--utility options-menu__option'"
                              [label]="itemActive ? 'Archive' : 'Restore'" icon="delete-outline"></app-button>
                </div>
                <div class="options-menu__item">
                  <app-button (click)="triggerAction('delete', '5432-fda45a-t4qwv')"
                              [class]="'btn btn--utility options-menu__option'" [label]="'Remove'"
                              icon="delete"></app-button>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-kit__col">
            <h2>Select Fields</h2>
            <h3>Simple Select</h3>
            <app-form-box [options]="formBoxes.simpleSelect" [formControlNameValue]="formBoxes.simpleSelect.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <h3>Simple Select Search</h3>
            <app-form-box [options]="formBoxes.simpleSearchSelect"
                          [formControlNameValue]="formBoxes.simpleSearchSelect.field" [formGroup]="createItemForm"
                          [formSubmitted]="formSubmitStatus"></app-form-box>
            <h3>Simple Select Search with Adding</h3>
            <app-form-box [options]="formBoxes.simpleSearchAddSelect"
                          [formControlNameValue]="formBoxes.simpleSearchSelect.field" [formGroup]="createItemForm"
                          [formSubmitted]="formSubmitStatus"></app-form-box>
            <h3>Multiple Checkbox Select</h3>
            <app-form-box [options]="formBoxes.multiselect" [formControlNameValue]="formBoxes.multiselect.field"
                          [formGroup]="createItemForm" [formSubmitted]="formSubmitStatus"></app-form-box>
            <h2>Toolbar Actions</h2>
            <div class="ui-kit__anchor">
              <app-button (click)="toggleMaterials()" [class]="'btn btn--utility'"
                          [label]="toolbar.label" [icon]="toolbar.icon"></app-button>
            </div>
            <h2>Calendars</h2>
            <h3>Single Date</h3>
            <div class="calendar__wrap">
              <input placeholder="Select Date" [selectMode]="'single'" [owlDateTimeTrigger]="dateSimple"
                     [rangeSeparator]="'-'" [class]="'form__input'" (dateTimeChange)="selectDateRange($event)"
                     [owlDateTime]="dateSimple">
              <owl-date-time click-outside (clickOutside)="closeDatepicker($event, dateSimple)"
                             #dateSimple [pickerType]="'calendar'"></owl-date-time>
              <figure class="calendar__icon">
                <svg-icon icon="calendar"></svg-icon>
              </figure>
            </div>
            <h3>Range Date</h3>
            <div class="calendar__wrap">
              <input placeholder="Select Date Range" [selectMode]="'range'" [owlDateTimeTrigger]="dateRange"
                     [rangeSeparator]="'-'" [class]="'form__input'" (dateTimeChange)="selectDateRange($event)"
                     [owlDateTime]="dateRange">
              <owl-date-time click-outside (clickOutside)="closeDatepicker($event, dateRange)"
                             #dateRange [pickerType]="'calendar'"></owl-date-time>
              <figure class="calendar__icon">
                <svg-icon icon="calendar"></svg-icon>
              </figure>
            </div>
          </div>
        </div>
        <div class="ui-kit__col">
          <h2>Expandable Tables</h2>
          <ng-container *ngFor="let tableAcc of tableAccList">
            <app-table-acc [item]="tableAcc" [table]="tableExampleData" (onAccToggle)="showAcc($event)"></app-table-acc>
          </ng-container>
        </div>
        <div class="ui-kit__row">
          <h2>Table sizes</h2>
        </div>
        <div class="ui-kit__row">
          <h3>Small table - 32 px</h3>
        </div>
        <div class="ui-kit__row">
          <table class="table table--small">
            <thead class="table__head">
            <tr class="table__body-row">
              <th class="table__head-label">Number</th>
              <th class="table__head-label">Name</th>
              <th class="table__head-label">Status</th>
              <th class="table__head-label">Type</th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr class="table__body-row" *ngFor="let item of tableExampleData">
              <td class="table__body-cell">{{item.number}}</td>
              <td class="table__body-cell">{{item.name}}</td>
              <td class="table__body-cell">{{item.status}}</td>
              <td class="table__body-cell">{{item.type}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="ui-kit__row">
          <h3>medium table - 40 px - Default</h3>
        </div>
        <div class="ui-kit__row">
          <table class="table">
            <thead class="table__head">
            <tr class="table__body-row">
              <th class="table__head-label">Number</th>
              <th class="table__head-label">Name</th>
              <th class="table__head-label">Status</th>
              <th class="table__head-label">Type</th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr class="table__body-row" *ngFor="let item of tableExampleData">
              <td class="table__body-cell">{{item.number}}</td>
              <td class="table__body-cell">{{item.name}}</td>
              <td class="table__body-cell">{{item.status}}</td>
              <td class="table__body-cell">{{item.type}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="ui-kit__row">
          <h3>Large table - 48 px</h3>
        </div>
        <div class="ui-kit__row">
          <table class="table table--large">
            <thead class="table__head">
            <tr class="table__body-row">
              <th class="table__head-label">Number</th>
              <th class="table__head-label">Name</th>
              <th class="table__head-label">Status</th>
              <th class="table__head-label">Type</th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr class="table__body-row" *ngFor="let item of tableExampleData">
              <td class="table__body-cell">{{item.number}}</td>
              <td class="table__body-cell">{{item.name}}</td>
              <td class="table__body-cell">{{item.status}}</td>
              <td class="table__body-cell">{{item.type}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="ui-kit__row">
          <h2>Table with head search</h2>
        </div>
        <div class="ui-kit__row">
          <table class="table table--large">
            <thead class="table__head">
            <tr class="table__body-row">
              <th class="table__head-label">Number</th>
              <th class="table__head-label table__head-label--search">Name
                <input type="text" class="form__input table__head-search-input" placeholder="Search"
                       *ngIf="tableHeadSearch" (keydown)="onKeydown($event)">
                <div class="form__controls-clear table__head-search-clear" *ngIf="tableHeadSearch"
                     (click)="clearHeadInput($event)">
                  <svg-icon icon="close"></svg-icon>
                </div>
                <app-button [class]="'btn btn--only-icon table__head-search-btn'" [icon]="'search'"
                            (click)="toggleHeadSearch()"></app-button>
              </th>
              <th class="table__head-label">Status</th>
              <th class="table__head-label">Type</th>
            </tr>
            </thead>
          </table>
        </div>
        <div class="ui-kit__row">
          <div class="ui-kit__col">
            <h2>Table Cell Interactions</h2>
            <table class="table table--large">
              <thead class="table__head">
              <tr class="table__body-row">
                <th class="table__head-label">Number</th>
                <th class="table__head-label">Name</th>
                <th class="table__head-label">Status</th>
                <th class="table__head-label">Type</th>
              </tr>
              </thead>
              <tbody class="table__body">
<!--              <tr class="table__body-row" *ngFor="let item of tableExampleData">-->
<!--                <td class="table__body-cell">{{item.number}}</td>-->
<!--                <td class="table__body-cell">{{item.name}}</td>-->
<!--                <td class="table__body-cell">{{item.status}}</td>-->
<!--              </tr>-->
<!--              <tr class="table__body-row table__body-row&#45;&#45;edit" formGroup="tableNewRow">-->
<!--                <td class="table__body-cell">-->
<!--                  <app-form-box [options]="tableNewRowBoxes.name" [formControlNameValue]="tableNewRowBoxes.name.field"-->
<!--                                [formGroup]="tableNewRow" [formSubmitted]="formSubmitStatus"></app-form-box>-->
<!--                </td>-->
<!--                <td class="table__body-cell">-->
<!--                  <app-form-box [options]="tableNewRowBoxes.worker"-->
<!--                                [formControlNameValue]="tableNewRowBoxes.worker.field" [formGroup]="tableNewRow"-->
<!--                                [formSubmitted]="formSubmitStatus"></app-form-box>-->
<!--                </td>-->
<!--                <td class="table__body-cell">-->
<!--                  <app-form-box [options]="tableNewRowBoxes.number"-->
<!--                                [formControlNameValue]="tableNewRowBoxes.number.field" [formGroup]="tableNewRow"-->
<!--                                [formSubmitted]="formSubmitStatus"></app-form-box>-->
<!--                </td>-->
<!--              </tr>-->
              </tbody>
            </table>
            <a href="#" class="btn--add table__row-add" (click)="onNewRowAdd($event)">
              <svg-icon icon="plus"></svg-icon>
              New table row</a>
          </div>
        </div>
      </div>
    </form>
  </main>
</div>
