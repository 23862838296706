import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalInput]'
})
export class DecimalInputDirective {

  @Input() numberOfDecimalPlaces: number;

  private regex: RegExp = new RegExp(/^[\d,]*\.?\d{0,8}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (this.numberOfDecimalPlaces && this.numberOfDecimalPlaces !== 8) {
      this.regex = new RegExp(`^\\d*\\.?\\d{0,${this.numberOfDecimalPlaces}}$`, 'g');
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
