/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/components/loader/loader.component.ngfactory";
import * as i2 from "./shared/components/loader/loader.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./shared/components/confirm-modal/confirm-modal.component.ngfactory";
import * as i6 from "./shared/components/confirm-modal/confirm-modal.component";
import * as i7 from "./core/services/spinner.service";
import * as i8 from "ngx-smart-modal";
import * as i9 from "./app.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "ngx-toastr";
import * as i12 from "./core/services/auth.service";
import * as i13 from "./core/services/Env";
import * as i14 from "./core/services/subscription.service";
import * as i15 from "./shared/services/confirmation-modal.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "confirm-modal-wrapper"]], [[2, "isOpen", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-confirm-modal", [], null, [[null, "cancelButtonClicked"], [null, "confirmButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelButtonClicked" === en)) {
        var pd_0 = (_co.cancelConfirmModal() !== false);
        ad = (pd_0 && ad);
    } if (("confirmButtonClicked" === en)) {
        var pd_1 = (_co.confirmConfirmModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ConfirmModalComponent_0, i5.RenderType_ConfirmModalComponent)), i0.ɵdid(7, 49152, null, 0, i6.ConfirmModalComponent, [i7.SpinnerService, i8.NgxSmartModalService], { title: [0, "title"], text: [1, "text"], cancelButtonLabel: [2, "cancelButtonLabel"], confirmButtonLabel: [3, "confirmButtonLabel"], identifier: [4, "identifier"], customBody: [5, "customBody"], dismissable: [6, "dismissable"] }, { cancelButtonClicked: "cancelButtonClicked", confirmButtonClicked: "confirmButtonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.spinnerService.getGlobalSpinner())); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 4, 0, currVal_1); var currVal_3 = ((_co.modalConfiguration == null) ? null : _co.modalConfiguration.title); var currVal_4 = ((_co.modalConfiguration == null) ? null : _co.modalConfiguration.text); var currVal_5 = ((_co.modalConfiguration == null) ? null : _co.modalConfiguration.cancelButtonLabel); var currVal_6 = ((_co.modalConfiguration == null) ? null : _co.modalConfiguration.confirmButtonLabel); var currVal_7 = _co.modalId; var currVal_8 = false; var currVal_9 = false; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isModalVisible; _ck(_v, 5, 0, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4308992, null, 0, i9.AppComponent, [i10.TranslateService, i3.Router, i11.ToastrService, i12.AuthService, i13.EnvService, i7.SpinnerService, i14.SubscriptionService, i15.ConfirmationModalService, i8.NgxSmartModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
