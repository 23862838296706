import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayFormat' })
export class DayFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (value === 0 || value >= 2) {
      return value + ' days';
    }
    return value + ' day';
  }
}
