/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./loader.component";
var styles_LoaderComponent = [];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "spinner-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "bounce1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "bounce2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "bounce3"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "dot1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "dot2"]], null, null, null, null, null))], null, null); }
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-wrapper"]], [[2, "loading-wrapper--dim-bg", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["globalSpinner", 2]], null, 0, null, View_LoaderComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.vertical; var currVal_2 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.vertical; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i0.ɵccf("app-loader", i2.LoaderComponent, View_LoaderComponent_Host_0, { vertical: "vertical" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
