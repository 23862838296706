import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  FileParameter, SalesFacilityLookUpResponseModel,
  SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId,
  ShipmentResponseModel
} from '@onbatch/core/services/Sales';
import { ShippingService } from '../../../../services/shipping.service';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { FileResponse } from '@onbatch/core/services/Reporting';
import { AuthService } from '@onbatch/core/services/auth.service';
import { Features2, FileType } from '@onbatch/core/services/Account';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { prepareFileName } from '@onbatch/shared/utils';

@Component({
  selector: 'app-shipment-item',
  templateUrl: './shipment-item.component.html'
})
export class ShipmentItemComponent {
  @Input() shipment: ShipmentResponseModel;
  @Input() facilities: SalesFacilityLookUpResponseModel[];
  @Input() salesOrderExternalId: string;
  @Output() filesChanged = new EventEmitter();
  @Output() shipmentRemoved = new EventEmitter();
  @Output() editClicked = new EventEmitter<ShipmentResponseModel>();

  files: File[] = [];
  maxFiles = 5;

  readonly shipmentCriteria = SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId;

  isFeatureAvailable = new Features2({
    sales_ShippingDocuments: this.subscriptionService.getAccess(SubscriptionFeatures.SalesShippingDocuments),
  });

  @HostBinding('style.width') private width = '100%';

  constructor(private shippingService: ShippingService,
              private fileSaverService: FileSaverService,
              private toastr: ToastrService,
              public authService: AuthService,
              private subscriptionService: SubscriptionService,
              ) {
  }

  onSelect(data: UploadedFiles, shipmentExternalId: string, shipmentFilesLength: number) {
    const requests = [];
    if (data.addedFiles) {
      data.addedFiles.forEach((el: File) => {
        if (shipmentFilesLength < this.maxFiles) {
          // 5e+6 = 5MB
          if (el.size <= 5e+6) {
            requests.push(this.shippingService.uploadFile(shipmentExternalId, <FileParameter>({
              data: el, fileName: el.name
            })));
            shipmentFilesLength++;
          } else {
            this.toastr.error(`File ${el.name} is too big. You cannot upload a file larger than 5MB`, 'Error!');
          }
        }
      });
    }
    if (data.rejectedFiles) {
      data.rejectedFiles.forEach((el: RejectedFile) => {
        this.toastr.error(`File ${el.name} has an unsupported type`, 'Error!');
      });
    }
    forkJoin(requests).pipe()
      .subscribe(() => {
        this.filesChanged.emit();
        this.toastr.success('File(s) has been uploaded', 'Success!');
      });
  }

  openEditOverlay(e: MouseEvent, shipment: ShipmentResponseModel) {
    e.preventDefault();
    e.stopPropagation();
    this.editClicked.emit(shipment);
  }

  deleteShipping(e: MouseEvent, shipmentExternalId: string) {
    e.preventDefault();
    e.stopPropagation();

    this.shippingService.deleteShipment(this.salesOrderExternalId, shipmentExternalId)
      .subscribe(() => {
        this.toastr.success('Success!', `Shipping removed`);
        this.shipmentRemoved.emit();
      });
  }

  deleteFile(e: MouseEvent, shipmentExternalId: string, fileExternalId: string) {
    e.stopPropagation();
    this.shippingService.deleteFile(shipmentExternalId, fileExternalId)
      .subscribe(() => {
        this.filesChanged.emit();
        this.toastr.success('File has been removed', 'Success!');
      });
  }

  downloadFile(shipmentExternalId: string, fileExternalId: string, fileName: string) {
    this.shippingService.downloadFile(shipmentExternalId, fileExternalId)
      .subscribe((res: FileResponse) => {
        return this.fileSaverService.save(res.data, fileName);
      });
  }

  downloadBill(shipmentExternalId: string, fileExternalId: string, fileName: string) {
    this.shippingService.downloadBillOfLading(this.salesOrderExternalId, shipmentExternalId, fileExternalId)
      .subscribe((res: FileResponse) => {
        return this.fileSaverService.save(res.data, fileName);
      });
  }

  deleteBill(e: MouseEvent, shipmentExternalId: string, fileExternalId: string) {
    e.stopPropagation();
    this.shippingService.deleteBillOfLading(this.salesOrderExternalId, shipmentExternalId, fileExternalId)
      .subscribe(() => {
        this.filesChanged.emit();
        this.toastr.success('Bill of lading has been removed', 'Success!');
      });
  }

  downloadPackingSlip(shipmentExternalId: string): void {
    this.shippingService.downloadPackingSlip(this.salesOrderExternalId, shipmentExternalId)
      .subscribe((res: FileResponse) => {
        return this.fileSaverService.save(res.data, prepareFileName(`packing-slip-${this.shipment.shipmentNumber}`, FileType.Pdf));
      });
  }
}

interface RejectedFile {
  name: string;
  lastModified: number;
  lastModifiedDate: Date;
  webkitRelativePath: string;
  size: number;
  type: string;
  reason: string;
}

interface UploadedFiles {
  addedFiles: File[];
  rejectedFiles: RejectedFile[];
}
