import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SidebarConfigItem } from '../../sidebar/sidebar.model';
import { adminSidebarConfig, sidebarConfig } from '../constants';
import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class SidebarService {
  sidebarConfig$ =  new BehaviorSubject<Array<SidebarConfigItem>>(sidebarConfig());
  sidebarActiveSub = new BehaviorSubject<string | null>(null);

  constructor(private authService: AuthService) {
    this.updateSidebarConfig();
  }

  setSidebarConfig(data: Array<SidebarConfigItem>) {
    this.sidebarConfig$.next(data);
  }

  updateSidebarConfig(): void {
    this.authService.isAdmin.subscribe((isAdmin: boolean) =>
      this.sidebarConfig$.next(isAdmin ? adminSidebarConfig : sidebarConfig()));
  }

  getSidebarConfig(): Observable<Array<SidebarConfigItem>> {
    return this.sidebarConfig$.asObservable();
  }
  getActiveSidebarItem(): Observable<string> {
    return this.sidebarActiveSub.asObservable();
  }
  setSidebarActiveSub(item: string | null) {
    if (this.sidebarActiveSub.value === item) {
      this.sidebarActiveSub.next(null);
    } else {
      this.sidebarActiveSub.next(item);
    }
  }
}
