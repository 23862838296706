import { Pipe, PipeTransform } from '@angular/core';
import { CostPriceFormatResponseModel } from '@onbatch/core/services/Account';
import { AuthService, IFormattingSettings } from '../../core/services/auth.service';

@Pipe({
  name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {
  settings: IFormattingSettings;
  precision: number;

  constructor(public authService: AuthService) {
  }

  transform(value: number | string, precision: number = null): any {
    function setCharAt(str: string, index: number, chr: string) {
      if (index > str.length - 1) {
        return str;
      }
      return str.substr(0, index) + chr + str.substr(index + 1);
    }

    this.settings = this.authService.getFormattingSettingsObservable();

    if (!this.settings) {
      this.settings = <IFormattingSettings>{
        costPricePrecision: 2,
        costPriceFormat: new CostPriceFormatResponseModel({
          decimals: '.',
          thousands: ','
        }),
      }
    }

    if (value === undefined) {
      return '';
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    this.precision = precision || this.settings.costPricePrecision;

    let newValue = value.toFixed(this.precision);

    const rx = /(\d+)(\d{3})/;
    const _this = this;
    if (this.precision > 0) {
      newValue = setCharAt(newValue, newValue.length - _this.precision - 1, _this.settings.costPriceFormat.decimals);
    }
    return String(newValue).replace(/^\d+/, function(w: string) {
      let res = w;
      while (rx.test(res)) {
        res = res.replace(rx, `$1${_this.settings.costPriceFormat.thousands}$2`);
      }
      return res;
    });

  }
}
