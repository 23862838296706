import * as Rollbar from 'rollbar';
import { environment } from 'environments/environment';
import { InjectionToken, ErrorHandler } from '@angular/core';
const ɵ0 = function () {
    if (localStorage.getItem('currentUser') === null)
        return '';
    const url = JSON.parse(localStorage.getItem('currentUser')).profile.iss;
    if (url.includes('localhost')) {
        return 'localhost-angular';
    }
    if (url.includes('dev')) {
        return 'stage-angular';
    }
    return 'production-angular';
}, ɵ1 = localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).externalId : null;
const rollbarConfig = {
    accessToken: '8f54a39597fd4fe083c0d04b581dd063',
    host: `${environment.production ? 'production' : 'localhost'}-angular`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    nodeSourceMaps: false,
    inspectAnonymousErrors: true,
    ignoreDuplicateErrors: true,
    wrapGlobalEventHandlers: false,
    exitOnUncaughtException: false,
    stackTraceLimit: 20,
    verbose: true,
    reportLevel: 'error',
    payload: {
        url: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).profile.iss : null,
        environment: (ɵ0)(),
        person: {
            id: ɵ1,
            email: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).email : null,
            userExternalId: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).externalId : null,
            organizationName: localStorage.getItem('currentUser') !== null && JSON.parse(localStorage.getItem('currentUser')).organizations !== undefined && JSON.parse(localStorage.getItem('currentUser')).organizations[0].name !== undefined ? JSON.parse(localStorage.getItem('currentUser')).organizations[0].name : null,
            organizationExternalId: localStorage.getItem('currentUser') !== null && JSON.parse(localStorage.getItem('currentUser')).organizations !== undefined && JSON.parse(localStorage.getItem('currentUser')).organizations[0].externalId !== undefined ? JSON.parse(localStorage.getItem('currentUser')).organizations[0].externalId : null
        }
    }
};
export const RollbarService = new InjectionToken('rollbar');
export class RollbarErrorHandler {
    constructor(rollbar) {
        this.rollbar = rollbar;
    }
    handleError(err) {
        // temporarily ignore rollbar - over monthly free plan limit
        //this.rollbar.error(err.originalError || err);
        console.error(err);
    }
}
export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
export { ɵ0, ɵ1 };
