import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IMaskModule } from 'angular-imask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PopoverModule } from 'ngx-smart-popover';

import { SharedInventoryModule } from '../inventory/shared';
import {
  ClickOutsideDirective,
  DecimalInputDirective,
  IntegerInputDirective,
  MatchHeightDirective,
  PriceInputDirective,
  PriceWithoutPrecisionInputDirective,
} from './directives';
import { MultiSelectionDirective } from './components/manual-selection/multi-selection.directive';
import { FooterComponent, HeaderComponent } from './layout';
import * as c from './components';

const Components = [
  c.ActionBarComponent,
  c.ActionBarItemComponent,
  c.AddEquipmentComponent,
  c.AddSourceComponent,
  c.AdvancedFiltersComponent,
  c.AdvancedSearchComponent,
  c.AdvancedSearchComponent,
  c.AvatarComponent,
  c.BillOfLadingModalComponent,
  c.ButtonComponent,
  c.ButtonSwitchComponent,
  c.ConfirmModalComponent,
  c.ContainersComponent,
  c.CookieBarComponent,
  c.CreateItemComponent,
  c.CustomerAddressesComponent,
  c.CustomerDetailsComponent,
  c.CustomLinkLineComponent,
  c.DateTimePickerComponent,
  c.DateRangeInputComponent,
  c.EquipmentLibraryComponent,
  c.FiltersComponent,
  c.FiltersItemComponent,
  c.FiltersBarComponent,
  c.FinishedGoodComponent,
  c.FinishedGoodsManualSelectionListComponent,
  c.FinishedGoodsManualSelectionTableComponent,
  c.FormBoxComponent,
  c.FormErrorMessageComponent,
  c.GlobalModalComponent,
  c.GlobalSmallSearchComponent,
  c.HeaderActionButtonsComponent,
  c.HistoryLogComponent,
  c.HistoryLogDetailsComponent,
  c.HoverTooltipComponent,
  c.ListItemComponent,
  c.LoaderComponent,
  c.LocationComponent,
  c.LocationInputComponent,
  c.LocationsTableComponent,
  c.ButtonLongComponent,
  c.ManualSelectionComponent,
  c.MasterItemPackageComponent,
  c.MaterialsDropdownComponent,
  c.ModalGenericComponent,
  c.ModalNewTemplateComponent,
  c.MoreEmailsComponent,
  c.MultipleItemsValueComponent,
  c.OrderStatusComponent,
  c.PackagingMaterialsComponent,
  c.PackagingMaterialsManualSelectionListComponent,
  c.PackagingMaterialsManualSelectionTableComponent,
  c.PaginationComponent,
  c.PricingByCustomerSingleComponent,
  c.PricingViewItemByCustomerComponent,
  c.ReceiveTransferItemsModalComponent,
  c.ReceiveTransferItemsOverlayComponent,
  c.SelectedItemsModalComponent,
  c.SalesItemsComponent,
  c.SemiFinishedGoodComponent,
  c.SemiFinishedGoodsManualSelectionTableComponent,
  c.SemiFinishedGoodsManualSelectionListComponent,
  c.SettingsDropdownComponent,
  c.SettingsSchemaHelperComponent,
  c.ShipItemsOverlayComponent,
  c.ShipItemsOverlayTiBComponent,
  c.ShipOrderModalComponent,
  c.ShipmentListComponent,
  c.ShipmentItemComponent,
  c.SingleItemActionsComponent,
  c.SingleSalesItemActionsDetailsComponent,
  c.SingleSalesItemComponent,
  c.SingleItemTableComponent,
  c.SinglePresetRuleByCustomerComponent,
  c.SvgIconComponent,
  c.TableHeaderSearchComponent,
  c.TableNoResultsComponent,
  c.TimeLogComponent,
  c.ToggleComponent,
  c.TooltipComponent,
  c.UserInfoComponent,
  c.WarningModalComponent,
  c.AddIncomingItemComponent,
  c.AddEquipmentInTransfersComponent,
  c.MaterialsDepletionComponent,
  c.MaterialsDepletionModalComponent,
  c.FlowMapComponent,
  c.InputMicroActionComponent,
  c.AddContainerComponent,
];

import { FiltersService } from 'app/filters/filters.service';
import { FormatsService } from './services/formats.service';
import { InventoryLookupsService } from './services/inventory-lookups.service';
import { MasterItemService } from '../inventory/master-items/master-items.service';
import { MasterItemsService } from '../inventory/master-items/master-item.service';
import { MixpanelService } from './services/mixpanel.service';

import * as p from './pipes';

const Pipes = [
  p.AmountFormatPipe,
  p.AreaNamePipe,
  p.ByCategoryPipe,
  p.CheckUrlPipe,
  p.DateFormatPipe,
  p.FailureReasonPipe,
  p.FilterTypePipe,
  p.FormatCurrencyPipe,
  p.HistoryEventIconPipe,
  p.HistoryEventTitlePipe,
  p.ItemTransactionPipe,
  p.PriceFormatPipe,
  p.SearchPipe,
  p.SplitCamelCasePipe,
  p.SubscriptionTypePipe,
  p.TimestampDatePipe,
  p.UnitOfMeasurementFormatPipe,
  p.MeasurementFormatPipe,
  p.PriceWithoutPrecisionFormatPipe,
  p.NameInitialsPipe,
  p.MasterItemTypePipe,
  p.DayFormatPipe,
  p.MoneyFormatPipe,
  p.SeasonedLengthPipe,
  p.ContainerAgePipe,
  p.YearlyQuarterlySemiMonthlyPipe,
];

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ...Components,
    DecimalInputDirective,
    FooterComponent,
    HeaderComponent,
    IntegerInputDirective,
    MatchHeightDirective,
    MultiSelectionDirective,
    PriceInputDirective,
    PriceWithoutPrecisionInputDirective,
    ...Pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IMaskModule,
    NgSelectModule,
    NgxDropzoneModule,
    NgxMaskModule.forChild(),
    NgxSmartModalModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    SharedInventoryModule,
    TextMaskModule,
    TranslateModule,
  ],
  exports: [
    ClickOutsideDirective,
    ...Components,
    FooterComponent,
    MatchHeightDirective,
    PriceInputDirective,
    PriceWithoutPrecisionInputDirective,
    HeaderComponent,
    ...Pipes,
    ReactiveFormsModule,
    TextMaskModule,
  ],
  providers: [
    FormatsService,
    MasterItemService,
    MasterItemsService,
    InventoryLookupsService,
    p.ByCategoryPipe,
    p.PriceFormatPipe,
    p.PriceWithoutPrecisionFormatPipe,
    p.UnitOfMeasurementFormatPipe,
    p.AmountFormatPipe,
    p.MeasurementFormatPipe,
    p.NameInitialsPipe,
    p.YearlyQuarterlySemiMonthlyPipe,
    MixpanelService,
    FiltersService,
    ]
})
export class SharedModule {}
