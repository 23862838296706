import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-bar-item',
  templateUrl: './action-bar-item.component.html'
})
export class ActionBarItemComponent {
  @Output() clickedIcon: EventEmitter<any> = new EventEmitter<any>();
  @Input() item: any;
  isOpen = false;
  @HostBinding('style.width') private width = '100%';

  constructor() {
  }

  getClickedItem(item: any) {
    if (item.disabled) {
      return;
    }
    if (item.dropdown && !item.disabled) {
      this.isOpen = true;
    }
    if (typeof item === 'string') {
      this.clickedIcon.emit(item);
      this.isOpen = false;
    } else {
      this.clickedIcon.emit(item.action ? item.action : item.icon);
    }
  }

  closeDropdown(event: any) {
    if (event.value && this.isOpen) {
      this.isOpen = false;
    }
  }
}
