import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {ClickOutsideData} from '@onbatch/shared/directives';
import {
  ExcludedIdsFilterRequestApplyModel,
  PaginatedResponseOfWarehousingEquipmentLookUpResponseModel,
  WarehousingEquipmentLookUpResponseModel
} from '@onbatch/core/services/Warehousing';
import { debounce } from 'lodash';
import {take} from 'rxjs/operators';
import { TransfersService } from '../../../../../warehousing/transfers/transfers.service';

@Component({
  selector: 'app-add-equipment-in-transfers',
  templateUrl: './add-equipment.component.html'
})
export class AddEquipmentInTransfersComponent implements OnInit, OnChanges {
  @Input() itemExternalId: string;
  @Input() addedEquipment: string[] = [];
  @Input() excludedEquipment: string[];
  @Output() itemSelected = new EventEmitter<WarehousingEquipmentLookUpResponseModel>();

  equipment: WarehousingEquipmentLookUpResponseModel[];
  showModal = false;

  paramsForVesselsLookup = {
    filterRequest: [],
    x_desc: undefined,
    x_order: undefined,
    x_pageNumber: 1,
    x_pageSize: 20,
    x_query: undefined,
  };
  searchTerm = '';
  searchSubmit = debounce(() => {
    if (this.searchTerm.length > 2 || this.searchTerm.length === 0) {
      this.lookUpVessels(this.searchTerm);
    }
  }, 200);

  constructor(private transfersService: TransfersService) {
  }

  ngOnInit(): void {
    this.lookUpVessels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshEquipment) {
      this.lookUpVessels();
    }
    if (changes.excludedEquipment) {
      this.lookUpVessels();
    }
  }

  lookUpVessels(searchTerm?: string): void {
    this.paramsForVesselsLookup.x_query = searchTerm;
    this.transfersService.lookUpVessels(
      new ExcludedIdsFilterRequestApplyModel({
        filters: this.paramsForVesselsLookup.filterRequest,
        externalIdsToExclude: this.excludedEquipment
      }),
      this.paramsForVesselsLookup.x_query,
      this.paramsForVesselsLookup.x_order,
      this.paramsForVesselsLookup.x_desc,
      this.paramsForVesselsLookup.x_pageNumber,
      this.paramsForVesselsLookup.x_pageSize)
      .pipe(take(1))
      .subscribe((response: PaginatedResponseOfWarehousingEquipmentLookUpResponseModel) => {
        this.equipment = response.list;
      });
  }

  onItemSelect(item: WarehousingEquipmentLookUpResponseModel): void {
    this.itemSelected.emit(item);
    this.showModal = false;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
  }

  closeModal(event: ClickOutsideData): void {
    if (event.value) {
      this.showModal = false;
    }
  }
}
