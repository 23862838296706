import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId, ExciseTaxReportResponseModelExciseTaxReportPeriodTypeId } from '@onbatch/core/services/Reporting';

@Pipe({
  name: 'yearlyQuarterlySemiMonthly',
})
export class YearlyQuarterlySemiMonthlyPipe implements PipeTransform {
  readonly periodType = ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId;

  constructor(private datePipe: DatePipe) {}

  transform(
    period: ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId | ExciseTaxReportResponseModelExciseTaxReportPeriodTypeId,
    dateStart: number,
    dateEnd?: number
  ): any {
    switch (period) {
      case this.periodType.Yearly:
        return this.getYearly(dateStart);

      case this.periodType.Quarterly:
        return this.getQuarterly(dateStart, dateEnd);

      case this.periodType.SemiMonthly:
        return this.getSemiMonthly(dateStart, dateEnd);

      default:
        return this.getYearly(dateStart);
    }
  }

  private getYearly(dateStart: number) {
    return this.datePipe.transform(dateStart * 1000, 'y', 'GMT');
  }

  private getQuarterly(dateStart: number, dateEnd: number) {
    const dateStartTransformed = this.datePipe.transform(dateStart * 1000, 'MMMM d', 'GMT');
    const dateEndTransformed = this.datePipe.transform(dateEnd * 1000, 'MMMM d, y', 'GMT');
    return `${dateStartTransformed} - ${dateEndTransformed}`;
  }

  private getSemiMonthly(dateStart: number, dateEnd: number) {
    const dateStartTransformed = this.datePipe.transform(dateStart * 1000, 'MMMM d', 'GMT');
    const dateEndTransformed = this.datePipe.transform(dateEnd * 1000, 'd, y', 'GMT');
    return `${dateStartTransformed} - ${dateEndTransformed}`;
  }
}
