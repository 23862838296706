import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../core/services/Env';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountClient } from '../../core/services/Account';
import { tap } from 'rxjs/operators';
import * as timezone from 'moment-timezone';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/services/Env";
export class SettingsService {
    constructor(http, envService) {
        this.http = http;
        this.envService = envService;
        this.manufacturingSettings = new BehaviorSubject(null);
        this.salesSettings = new BehaviorSubject(null);
        this.formattingSettings = new BehaviorSubject(null);
        this.settingsClient = new AccountClient(this.http, envService.apiUrl);
    }
    // Formatting
    getSettingsForFormatting() {
        return this.settingsClient.settingsFormattingGet()
            .pipe(tap((data) => {
            this.formattingSettings.next(data);
            timezone.tz.setDefault(data.timezone);
        }));
    }
    setSettingsForFormatting(model) {
        return this.settingsClient.settingsFormattingPut(model);
    }
    // Inventory module
    getSettingsForInventory() {
        return this.settingsClient.settingsInventoryGet();
    }
    setSettingsForInventory(model) {
        return this.settingsClient.settingsInventoryPut(model);
    }
    // Manufacturing module
    getSettingsForManufacturing() {
        this.settingsClient.settingsManufacturingGet().subscribe(data => {
            this.manufacturingSettings.next(data);
        });
    }
    setSettingsForManufacturing(model) {
        return this.settingsClient.settingsManufacturingPut(model)
            .pipe(tap(response => this.manufacturingSettings.next(response)));
    }
    getManufacturingSettings() {
        if (!this.manufacturingSettings.value) {
            this.manufacturingSettings.next(null);
            this.getSettingsForManufacturing();
        }
        return this.manufacturingSettings.asObservable();
    }
    // Warehouse module
    getSettingsForWarehouse() {
        return this.settingsClient.settingsWarehousingGet();
    }
    setSettingsForWarehouse(model) {
        return this.settingsClient.settingsWarehousingPut(model);
    }
    // Purchasing module
    getSettingsForPurchasing() {
        return this.settingsClient.settingsPurchasingGet();
    }
    setSettingsForPurchasing(model) {
        return this.settingsClient.settingsPurchasingPut(model);
    }
    sendTestVendorEmail(message) {
        return this.settingsClient.settingsSendPurchasingEmailTemplatePut(message);
    }
    // Sales module
    sendTestPurchasingEmail(message) {
        return this.settingsClient.settingsSendSalesEmailTemplatePut(message);
    }
    getSettingsForSales() {
        this.settingsClient.settingsSalesGet().subscribe(data => {
            this.salesSettings.next(data);
        });
    }
    setSettingsForSales(model) {
        return this.settingsClient.settingsSalesPut(model)
            .pipe(tap(response => this.salesSettings.next(response)));
    }
    getSalesSettings() {
        if (!this.salesSettings.value) {
            this.getSettingsForSales();
        }
        return this.salesSettings;
    }
    // Accounting
    getSettingsForAccounting() {
        return this.settingsClient.settingsAccountingGet();
    }
    setSettingsForAccounting(model) {
        return this.settingsClient.settingsAccountingPut(model);
    }
    // Taxes
    getSettingsForTaxes() {
        return this.settingsClient.settingsTaxesGet();
    }
    setSettingsForTaxes(model) {
        return this.settingsClient.settingsTaxesPut(model);
    }
}
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvService)); }, token: SettingsService, providedIn: "root" });
