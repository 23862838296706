import { AuthService } from '../../core/services/auth.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { EnvService } from '../../core/services/Env';
import { HttpClient } from '@angular/common/http';
import { InventoryClient, BillOfMaterialsItemUpdateModel, BillOfMaterialsResponseModel, ExportRequestModel, FileResponse, FileType, FilterOptions, FilterRequestApplyModel, InventoryHistoryLogResponse, MasterItemBillOfMaterialsLookUpRequestModel, MasterItemContainerRequestModel, MasterItemContainerResponseModel, MasterItemFullResponseModel, MasterItemListResponseModel, MasterItemQuantityRequestModel, MasterItemRequestModel, MasterItemResponseModel, MasterItemTemplateRequestModel, MasterItemTemplateResponseModel, MasterItemType, PackageRequestModel, PackageResponseFullModel, PageInfo, PaginatedResponseOfInventoryHistoryLogListResponse, PaginatedResponseOfLookUpResponse, PaginatedResponseOfMasterItemListResponseModel, PaginatedResponseOfMasterItemLookupResponse, PaginatedResponseOfMasterItemLotsListResponse, PaginatedResponseOfMasterItemPurchaseListResponse, PaginatedResponseOfMasterItemSalesListResponse, RemovalResponseModel, SpiritItemRequestModel, SpiritItemResponseModel, PaginatedResponseOfInventoryFacilityLookUpResponseModel, MasterItemTypeId } from '../../core/services/InventoryRx';
import { BehaviorSubject, Subject } from 'rxjs';
import { MasterItemDataTableDefaults } from './master-item-statics.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/auth.service";
import * as i2 from "@ngx-pwa/local-storage";
import * as i3 from "../../core/services/Env";
import * as i4 from "@angular/common/http";
/*
 * This is new version of Inventory client
 */
export class MasterItemsService {
    constructor(authService, localStorage, envService, httpClient) {
        this.authService = authService;
        this.localStorage = localStorage;
        this.envService = envService;
        this.httpClient = httpClient;
        this.locationsAddedToMasterItem = new BehaviorSubject([]);
        this.masterItemSidebar = new BehaviorSubject(false);
        this.itemID = new Subject();
        this.masterItemDataTableParams = new BehaviorSubject(null);
        this.masterItemList = new Subject();
        this.masterItemPagination = new BehaviorSubject(null);
        this.masterItemFiltersOptions = new BehaviorSubject(null);
        this.masterItemSingle = new BehaviorSubject(null);
        this.inventoryClient = new InventoryClient(httpClient, envService.apiUrl);
        this.localStorage.getItem(this.authService.getStorageKey('master-item-data-table')).subscribe((data) => {
            this.masterItemDataTableParams.next(data || MasterItemDataTableDefaults);
        });
    }
    postMasterItem(model) {
        return this.inventoryClient.masterItemPost(model);
    }
    putMasterItem(externalId, model) {
        return this.inventoryClient.masterItemByExternalIdPut(externalId, model);
    }
    putSpiritItem(masterItemExternalId, model) {
        return this.inventoryClient.spiritItemByMasterItemExternalIdPut(masterItemExternalId, model);
    }
    putContainer(externalId, model) {
        return this.inventoryClient.masterItemByExternalIdContainerPut(externalId, model);
    }
    putPackage(externalId, model) {
        return this.inventoryClient.masterItemByExternalIdPackagePut(externalId, model);
    }
    putBillOfMaterials(externalId, updateModel) {
        return this.inventoryClient.masterItemByExternalIdBillOfMaterialsPut(externalId, updateModel);
    }
    // Single
    fetchMasterItemSingle(externalId) {
        this.getSingle(externalId).subscribe(data => this.setMasterItemSingle(data));
    }
    getSingle(externalId) {
        return this.inventoryClient.masterItemByExternalIdGet(externalId);
    }
    getBillOfMaterials(externalId) {
        return this.inventoryClient.masterItemByExternalIdBillOfMaterialsGet(externalId);
    }
    getSpirit(masterItemExternalId) {
        return this.inventoryClient.spiritItemByMasterItemExternalIdGet(masterItemExternalId);
    }
    setMasterItemSingle(item) {
        this.masterItemSingle.next(item);
        this.masterItemSidebar.next(true);
    }
    getMasterItemSingle() {
        return this.masterItemSingle.asObservable();
    }
    deleteItems(externalIds) {
        return this.inventoryClient.masterItemDelete(externalIds);
    }
    // Locations
    getLocationsAddedToMasterItem() {
        return this.locationsAddedToMasterItem.asObservable();
    }
    setLocationsAddedToMasterItem(value) {
        this.locationsAddedToMasterItem.next(value);
    }
    // UI
    setMasterItemSide(item) {
        this.masterItemSidebar.next(item);
    }
    getMasterItemSide() {
        return this.masterItemSidebar.asObservable();
    }
    setItemID(data) {
        this.itemID.next(data);
    }
    clearID() {
        this.itemID.next(null);
    }
    masterItemSingleClear() {
        this.masterItemSingle.next(null);
        this.masterItemSidebar.next(false);
    }
    // Templates
    getSingleMasterItemTemplate(externalId) {
        return this.inventoryClient.templateMasterItemByExternalIdGet(externalId);
    }
    updateTemplate(externalId, model) {
        return this.inventoryClient.templateMasterItemByExternalIdPut(externalId, model);
    }
    deleteTemplate(externalId) {
        return this.inventoryClient.templateMasterItemByExternalIdDelete(externalId);
    }
    postMasterItemTemplate(model) {
        return this.inventoryClient.templateMasterItemPost(model);
    }
    // Create
    getLastNumber() {
        return this.inventoryClient.masterItemLastItemNumberGet();
    }
    validateItemNumber(itemNumber) {
        return this.inventoryClient.masterItemItemNumberByItemNumberGet(itemNumber);
    }
    // List
    getMasterItemList() {
        return this.masterItemList.asObservable();
    }
    filterMasterItems(filterRequest, includeInactive = false, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.masterItemFilterPut(filterRequest, includeInactive, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    fetchNewList() {
        if (!this.masterItemDataTableParams) {
            return;
        }
        const params = this.masterItemDataTableParams.value.params;
        this.filterMasterItems(this.masterItemDataTableParams.value.filters, params.includeInactive, params.x_pageNumber, params.x_pageSize, params.x_query, params.x_order, params.x_desc).subscribe(data => {
            this.masterItemList.next(data.list);
            this.setMasterItemPagination(data.pageInfo);
            this.setMasterItemFiltersOptions(data.filterColumns);
        });
    }
    setMasterItemPagination(item) {
        this.masterItemPagination.next(item);
    }
    setMasterItemFiltersOptions(item) {
        this.masterItemFiltersOptions.next(item);
    }
    getMasterItemPagination() {
        return this.masterItemPagination.asObservable();
    }
    getMasterItemFiltersOptions() {
        return this.masterItemFiltersOptions.asObservable();
    }
    getDataTableParams() {
        return this.masterItemDataTableParams.asObservable();
    }
    setDataTableParams(data) {
        const newVal = Object.assign({}, this.masterItemDataTableParams.value, data);
        this.masterItemDataTableParams.next(newVal);
        const key = this.authService.getStorageKey('master-item-data-table');
        this.localStorage.setItemSubscribe(key, newVal);
        this.fetchNewList();
    }
    // Lookups
    lookUpItems(withoutPricing, withoutRawMaterials = false, withoutFinishedGoods = true, withoutSemiFinishedGoods = false, withoutAlcohol = false, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpMasteritemsGet(withoutPricing, withoutRawMaterials, withoutFinishedGoods, withoutSemiFinishedGoods, withoutAlcohol, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupCategories(x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpCategoriesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupFermentable(categoryExternalId, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpFermentablematerialGet(categoryExternalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookUpBillOfMaterials(request, x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpMasteritemsBillOfMaterialsPut(request, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupUoM(masterItemType, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpUnitofmeasurementGet(masterItemType, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupLocations(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpFacilitiesGet(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    exportFile(fileType, exportRequestModel) {
        return this.inventoryClient.masterItemDownloadByFileTypePut(fileType, exportRequestModel);
    }
    changeMasterItemStatus(externalId, status) {
        return this.inventoryClient.masterItemByExternalIdSetstatusByStatusPut(externalId, status);
    }
    removeItem(externalIds) {
        return this.inventoryClient.masterItemDelete(externalIds);
    }
    // History
    getSingleHistory(externalId, from, to, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.inventoryHistoryLogMasterItemByExternalIdGet(externalId, from, to, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    getSingleHistoryLogById(externalId) {
        return this.inventoryClient.inventoryHistoryLogByExternalIdGet(externalId);
    }
    // Lots
    getLots(externalId, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.masterItemByExternalIdLotsGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    // Purchases
    getPurchases(externalId, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.masterItemByExternalIdPurchaseGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    // Sales
    getSales(externalId, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.masterItemByExternalIdSalesGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
}
MasterItemsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MasterItemsService_Factory() { return new MasterItemsService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LocalStorage), i0.ɵɵinject(i3.EnvService), i0.ɵɵinject(i4.HttpClient)); }, token: MasterItemsService, providedIn: "root" });
