/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./custom-toast.component";
import * as i4 from "ngx-toastr";
var styles_CustomToastComponent = [i0.styles];
var RenderType_CustomToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomToastComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(-30deg)", opacity: 0 }, offset: null }, { type: 6, styles: { transform: "skewX(20deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "skewX(-5deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }] }, timings: "400ms ease-out" }, options: null }, { type: 1, expr: "active => removed", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(30deg)", opacity: 0 }, offset: null }] }, timings: "400ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_CustomToastComponent as RenderType_CustomToastComponent };
function View_CustomToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 0, 0, currVal_0); }); }
function View_CustomToastComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "toast-inner_wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "toast-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "toast-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "toast-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 6, 0, currVal_2); }); }
function View_CustomToastComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["class", "toast-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_CustomToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "toast-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "toast-inner"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomToastComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomToastComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomToastComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.message && _co.options.enableHtml); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.message && !_co.options.enableHtml); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.options.closeButton; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.value === "inactive") ? "none" : ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-toast", [], [[8, "className", 0], [40, "@flyInOut", 0], [4, "display", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_CustomToastComponent_0, RenderType_CustomToastComponent)), i1.ɵdid(1, 180224, null, 0, i3.CustomToastComponent, [i4.ToastrService, i4.ToastPackage], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toastClasses; var currVal_1 = i1.ɵnov(_v, 1).state; var currVal_2 = i1.ɵnov(_v, 1).displayStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var CustomToastComponentNgFactory = i1.ɵccf("app-custom-toast", i3.CustomToastComponent, View_CustomToastComponent_Host_0, {}, {}, []);
export { CustomToastComponentNgFactory as CustomToastComponentNgFactory };
