import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'containerAge'
})
export class ContainerAgePipe implements PipeTransform {
  transform(containerAge: number): string {
    const dateFilled = moment().startOf('day').subtract(containerAge, 'days');
    const diff = moment().diff(dateFilled);
    const duration = moment.duration(diff);

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    const map = ['y', 'm', 'd'];
    return [years, months, days].reduce((acc: string, value: number, idx: number) => {
      return value === 0
        ? acc
        : `${acc} ${value}${map[idx]}`;
    }, '') || '0d';
  }
}
