import {Component, Input, HostBinding} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-link-line',
  templateUrl: './custom-link-line.component.html',
  styleUrls: ['./custom-link-line.component.scss']
})
export class CustomLinkLineComponent {
  @Input() color: string;
  @Input() type: string;
  @Input() isReversedLine: false;

  @Input() showArrow = true;
  @Input() showCircle = true;
  @Input() curvedTop = true;
  @Input() curvedBottom = true;

  @Input() lineWeight = '1px';
  @Input() horizontalLineStart = '50px';
  @Input() horizontalLineEnd = '50px';
  @Input() lineSize: string;
  @Input() curveSize = '40px';
  @Input() arrowWidthAndSize = '5px';
  @Input() curvedAngle = '40%';
  @Input() topLinePosition = '0px';

  @HostBinding('attr.style')
  public get customVariables(): any {
    return this.sanitizer
      .bypassSecurityTrustStyle(`
        --line-size: ${this.lineSize};
        --curve-size: ${this.curveSize};
        --arrow-width-and-height: ${this.arrowWidthAndSize};
        --line-weight: ${this.lineWeight};
        --horizontal-line-start: ${this.horizontalLineStart};
        --horizontal-line-end: ${this.horizontalLineEnd};
        --curved-angle: ${this.curvedAngle};
        --top-line-position: ${this.topLinePosition}`);
  }

  constructor(private sanitizer: DomSanitizer) {
  }
}
