import { ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId } from '@onbatch/core/services/Reporting';
import { NgSelectOptionWithDisabled } from '../../shared/';
import { ExciseTaxResponseModel, FormattingRequestModelMeasurementSystem, IExciseTaxResponseModel } from '../../core/services/Account';

export enum priceFormat {
  Dot = '.',
  Comma = ',',
  Space = ' ',
}

export const priceFormatValues = [
  {
    id: 0,
    thousands: priceFormat.Comma,
    decimals: priceFormat.Dot
  },
  {
    id: 1,
    thousands: priceFormat.Dot,
    decimals: priceFormat.Comma
  },
  {
    id: 2,
    thousands: priceFormat.Space,
    decimals: priceFormat.Dot
  },
  {
    id: 3,
    thousands: priceFormat.Space,
    decimals: priceFormat.Comma
  }
];

export enum dateFormats {
  format1 = 'M/d/yy',
  format2 = 'MMM d, y',
  format3 = 'MM/dd/yy',
  format4 = 'EEEE, MMMM d, y'
}

export enum timeFormats {
  format1 = 'h:mm aaa',
  format2 = 'H:mm'
}

export const dateFormatOptions = [
  {
    label: '6/7/15',
    value: dateFormats.format1
  },
  {
    label: 'Jun 7, 2015',
    value: dateFormats.format2
  },
  {
    label: '06/07/15',
    value: dateFormats.format3
  },
  {
    label: 'Monday, June 7, 2015',
    value: dateFormats.format4
  }
];

export const timeFormatOptions = [
  {
    label: '9:03 PM',
    value: timeFormats.format1
  },
  {
    label: '21:03',
    value: timeFormats.format2
  }
];
export const systemOfMeasurementOptions = [
  {
    label: 'Imperial System',
    value: FormattingRequestModelMeasurementSystem.Imperial
  },
  {
    label: 'Metric System',
    value: FormattingRequestModelMeasurementSystem.Metric
  }
];
export const costFormatFormatOptions = [
  {
    label: '1,000.00',
    value: 0
  },
  {
    label: '1.000,00',
    value: 1
  },
  {
    label: '1 000.00',
    value: 2
  },
  {
    label: '1 000,00',
    value: 3
  }
];
export const waterUnitOfMeasurementOptions = [
  {
    label: 'Galon',
    value: 'galon'
  },
  {
    label: 'Liter',
    value: 'liter'
  }
];
export const accountsOptions = [
  {
    label: 'Default account',
    value: 'default'
  }
];

export const exciseTaxPeriodTypeOptions: NgSelectOptionWithDisabled[] = [
  {
    name: 'Yearly',
    externalId: ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId.Yearly,
    disabled: false
  },
  {
    name: 'Quarterly',
    externalId: ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId.Quarterly,
    disabled: false
  },
  {
    name: 'Semi Monthly',
    externalId: ExciseTaxReportListResponseModelExciseTaxReportPeriodTypeId.SemiMonthly,
    disabled: false
  },
];

export const defaultExciseTaxReportingRange: ExciseTaxResponseModel[] = [
  new ExciseTaxResponseModel({
    class: exciseTaxPeriodTypeOptions[0].name,
    rate: 1000
  }),
  new ExciseTaxResponseModel({
    class: exciseTaxPeriodTypeOptions[1].name,
    rate: 5000
  }),
  new ExciseTaxResponseModel({
    class: exciseTaxPeriodTypeOptions[2].name,
    rate: 5000
  })
]