import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerService } from '@onbatch/core/services/spinner.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {

  @Input() title = `Remove Region 3`;
  @Input() text = `The region your trying to remove contains sub-regions. All of the sub-regions will be removed too. Are you sure you want to remove it?`;
  @Input() cancelButtonLabel = `Cancel`;
  @Input() confirmButtonLabel = `Confirm`;
  @Input() identifier = `confirmRemoveTemplate`;
  @Input() extraClass: string;
  @Input() customBody = false;
  @Input() hideConfirmButton = false;
  @Input() dismissable = false;
  @Input() checkboxText: string;

  @Output() cancelButtonClicked = new EventEmitter<null>();
  @Output() confirmButtonClicked = new EventEmitter<boolean>();

  checkboxState = false;

  constructor(
    public spinnerService: SpinnerService,
    private ngxSmartModal: NgxSmartModalService,
  ) {
  }

  onCancelButtonClick(): void {
    this.ngxSmartModal.close(this.identifier);
    this.cancelButtonClicked.emit();
  }

  onConfirmButtonClick(): void {
    this.ngxSmartModal.close(this.identifier);
    this.confirmButtonClicked.emit(this.checkboxState);
  }

  toggleCheckbox(): void {
    this.checkboxState = !this.checkboxState;
  }
}
