import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, skip, take, takeUntil } from 'rxjs/operators';

import { ClickOutsideData } from '@onbatch/shared/directives';
import {
  SalesOrderItemResponseModel, SalesOrderItemTransactionDetailsResponseModel,
  SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId,
  SalesOrderItemTransactionResponseModel,
  SalesOrderShippedItemRequestModel,
  SalesOrderShippedItemUpdateRequestModel,
  SalesOrderShippedItemUpdateRequestModelShipmentItemSelectionCriteriaId as ShipmentType,
  SellableMasterItemResponseModelMasterItemTypeId,
  ShippedItemRequestModel,
  ShipPickedItemsRequestModel,
} from '../../../../core/services/Sales';
import { SFGShipping, Shipping } from './shipment-type.enum';
import { ShippingService } from '../../../services/shipping.service';
import { FinishedGoodsSelectItemsService } from '../../../services/finished-goods-select-items.service';
import { PackagingMaterialsSelectItemsService } from '../../../services/packaging-materials-select-items.service';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { Features2 } from '@onbatch/core/services/Account';
import { TransfersService } from 'app/warehousing/transfers/transfers.service';
import {
  TransferInBondShippedItemRequestModel,
  TransferInBondShippedItemRequestModelShipmentItemSelectionCriteriaId
} from '@onbatch/core/services/Warehousing';
import {
  checkIfClickedElementHasClass,
  checkIfClickedParentElementHasClass,
  checkIfDatePickerWasClicked
} from '@onbatch/shared/utils';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { MixPanelEvents } from '@onbatch/shared/constants';
import { ConfirmationModalService, ModalConfirmationState } from '@onbatch/shared/services/confirmation-modal.service';
import {SemiFinishedGoodsSelectItemsService} from '@onbatch/shared/services/semi-finished-goods-select-items.service';

@Component({
  selector: 'app-ship-order-modal',
  templateUrl: './ship-order-modal.component.html'
})
export class ShipOrderModalComponent implements OnInit, OnDestroy {
  @Input() item: SalesOrderItemResponseModel;
  @Input() salesOrderExternalId: string;
  @Input() editMode = false;
  @Input() salesApproval = false;
  @Input() pickStrategy = false;
  @Input() shippingDetails: SalesOrderItemTransactionResponseModel;
  @Input() customClass: string;

  @Output() shippingConfirmed = new EventEmitter();

  showOverlay = false;
  showModal = false;

  form: FormGroup;
  formSubmitStatus: boolean;

  shipping: Shipping;
  canConfirmShipping = false;
  remainingQuantity: number = null;
  initRemainingQuantity: number = null;

  checkedItems: string[] = [];
  isSubmiting = false;
  isWarehouseView = false;
  isDateChanged = false;

  isFeatureAvailable = new Features2({
    inventory_LotTraceability: this.subscriptionService.getAccess(SubscriptionFeatures.InventoryLotTraceability)
  });

  get isMasterItemSFG(): boolean {
    return this.item && this.item.masterItem && this.item.masterItem.masterItemTypeId === this.masterItemType.SemiFinishedGood;
  }

  get isSFGContainer(): boolean {
    return !!(this.item && this.item.container && this.item.container.externalId);
  }

  readonly masterItemType = SellableMasterItemResponseModelMasterItemTypeId;
  readonly shipmentCriteria = SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId;

  private subscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private shippingService: ShippingService,
              private transfersService: TransfersService,
              private toastr: ToastrService,
              private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
              private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService,
              private packagingMaterialsSelectItemsService: PackagingMaterialsSelectItemsService,
              private subscriptionService: SubscriptionService,
              private mixpanelService: MixpanelService,
              private confirmationModalService: ConfirmationModalService,
  ) {
  }

  ngOnDestroy(): void {
    this.finishedGoodsSelectItemsService.clearAllManualSelectingState();
    this.semiFinishedGoodsSelectItemsService.clearAllManualSelectingState();
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.isWarehouseView = this.router.url.includes('warehousing/');
    this.finishedGoodsSelectItemsService.fetchShippingLotsFinishedGoodByMasterItemExternalIdGet(
      this.item.masterItem.externalId,
      this.item.masterItem.isRemnant,
      this.item.masterItem.numberOfUnits
    );

    if (!this.editMode) {
      this.initForm();
      this.patchShipmentType();
    } else {
      this.initEditForm();
    }
    this.subscription.add(this.finishedGoodsSelectItemsService.getShipping()
      .pipe(filter(data => !!data))
      .subscribe((data: Map<string, Shipping>) => {
        if (data.has(this.item.externalId)) {
          this.shipping = data.get(this.item.externalId);
          this.remainingQuantity = this.shipping.remainingQuantity;
          if (!this.initRemainingQuantity) {
            this.initRemainingQuantity = this.remainingQuantity;
          }
          this.canConfirmShipping = this.shipping.isValid;
          this.cdr.markForCheck();
        }
      }));

    if (this.isMasterItemSFG) {
      this.subscription.add(
        this.semiFinishedGoodsSelectItemsService.getShipping()
          .subscribe((data: Map<string, Shipping>) => {
            this.shipping = data.get(this.item.externalId);
            if (this.shipping) {
              this.remainingQuantity = this.shipping && this.shipping.remainingQuantity;
              if (!this.initRemainingQuantity) {
                this.initRemainingQuantity = this.remainingQuantity;
              }
              this.canConfirmShipping = this.shipping.isValid;
              this.cdr.markForCheck();
            }
          })
      );
    }
  }

  closeModal(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['add-item-modal', 'ng-option-marked', 'ng-option-label', 'owl', 'btn-close'])
    || checkIfClickedParentElementHasClass(event.target as HTMLElement, 'ship-order-modal__btn--small');
    if (event.value && !checkIfDatePickerWasClicked(event) && this.showModal && !isExcludedElementClicked && !this.showOverlay) {
      if (this.form.dirty || this.isDateChanged) {
        this.confirmationModalService.showConfirmationModal();
        this.confirmationModalService.getState().pipe(skip(1), take(1)).subscribe((modalState: ModalConfirmationState) => {
          this.showModal = !(modalState === ModalConfirmationState.confirmed);
          if (!this.showModal) {
            this.reset();
          }
        });
      } else {
        this.showModal = false;
        this.reset();
      }
    }
  }

  reset() {
    this.isDateChanged = false;
    this.formSubmitStatus = false;
    this.editMode ? this.initEditForm() : this.initForm();
    this.remainingQuantity = null;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
    if (this.showModal && !this.editMode) {
      this.initForm();
      this.patchShipmentType();
    }
    if (this.showModal && this.editMode) {
      this.addShippedItems();
    }
    if (!this.showModal) {
      this.finishedGoodsSelectItemsService.clearSelectedRangesToSubmit();
      this.finishedGoodsSelectItemsService.clearSelectedUnitIdsToSubmit();
      this.semiFinishedGoodsSelectItemsService.clearSelectedUnitIdsToSubmit();
      this.semiFinishedGoodsSelectItemsService.clearSemiFinishedGoodsShipping2(this.item.externalId);
      this.semiFinishedGoodsSelectItemsService.setIsSubmitDisabled(false);
      this.reset();
    }
    this.cdr.markForCheck();
  }

  initForm() {
    this.form = this.fb.group({
      shippedDate: new FormControl(this.currentDate, Validators.required),
      [this.shipmentTypeControlName]: new FormControl(ShipmentType.ByUnitIds, Validators.required),
      unitIds: new FormArray([]),
      equipment: new FormArray([]),
      containerIds: new FormArray([]),
      lotIds: new FormArray([]),
      shippedLotQuantity: new FormControl(''),
      shippedVolume: new FormControl(''),
      shippedQuantity: new FormControl('')
    });
  }

  initEditForm() {
    this.form = this.fb.group({
      shippedDate: new FormControl(this.shippingDetails.dateCreated, Validators.required),
      [this.shipmentTypeControlName]: new FormControl(ShipmentType[this.shippingDetails.details[0].shipmentItemSelectionCriteriaId], Validators.required),
      unitIds: new FormArray([]),
      equipment: new FormArray([]),
      containerIds: new FormArray([]),
      lotIds: new FormArray([]),
      shippedLotQuantity: new FormControl(''),
      shippedVolume: new FormControl(Number(this.shippingDetails.quantity)),
      shippedQuantity: new FormControl(Number(this.shippingDetails.quantity))
    });
  }

  patchShipmentType() {
    let value: ShipmentType;
    switch (this.item.masterItem.masterItemTypeId) {
      case this.masterItemType.FinishedGood:
        value = ShipmentType.ByUnitIds;
        break;
      case this.masterItemType.SemiFinishedGood:
        value = ShipmentType.ByEquipmentId;
        break;
      case this.masterItemType.PackagingMaterial:
      case this.masterItemType.RawMaterial:
        if (!this.isFeatureAvailable.inventory_LotTraceability) {
          value = ShipmentType.ByCount;
        } else {
          value = ShipmentType.ByLotId;
        }
        break;
      default:
        value = null;
    }
    this.shipmentType.patchValue(value);
  }

  setDate(date: Moment) {
    this.isDateChanged = true;
    this.shippedDate.patchValue(date.format('X'));
  }

  shippingChanged(data: Shipping): void {
    this.finishedGoodsSelectItemsService.setShipping(this.item.externalId, data);
    if (this.isMasterItemSFG && this.isSFGContainer) {
      this.semiFinishedGoodsSelectItemsService.setShipping(this.item.externalId, data);
    }
  }

  confirmShipping(): void {
    this.isSubmiting = true;
    const onSuccess = () => {
      this.toggleModal();
      this.isSubmiting = false;
      this.shippingConfirmed.emit();
      this.toastr.success('Success!',
        `${this.salesApproval
          ? `${this.pickStrategy ? 'Shipping' : 'Pick'}`
          : 'Shipping'} ${this.editMode ? 'edited' : 'confirmed'}`);
      this.mixpanelService.track(
        this.salesApproval
          ? (this.pickStrategy ? (
            this.editMode ? MixPanelEvents.SALES_ORDERS_SHIPPING_UPDATED : MixPanelEvents.SALES_ORDERS_SHIPPING_ADDED)
          : (this.editMode ? MixPanelEvents.SALES_ORDERS_PICKING_UPDATED : MixPanelEvents.SALES_ORDERS_PICKING_ADDED))
          : this.editMode ? MixPanelEvents.SALES_ORDERS_SHIPPING_UPDATED : MixPanelEvents.SALES_ORDERS_SHIPPING_ADDED, {
          'Sales order external ID': this.salesOrderExternalId,
          'Shipment Items': this.shipping.shipmentItems,
        });
    };
    const onError = () => {
      this.isSubmiting = false;
    };
    if (!this.pickStrategy) {
      if (!this.editMode) {
        let request;
        if (this.isWarehouseView) {
          request = new TransferInBondShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            transferInBondItemExternalId: this.item.externalId,
            shipmentItemSelectionCriteriaId: TransferInBondShippedItemRequestModelShipmentItemSelectionCriteriaId[this.shipping.shipmentItemSelectionCriteriaId],
            shipmentItems: this.shipping.shipmentItems
          });
        } else {
          request = new SalesOrderShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            salesOrderItemExternalId: this.item.externalId,
            shipmentItemSelectionCriteriaId: this.shipping.shipmentItemSelectionCriteriaId,
            shipmentItems: this.shipping.shipmentItems
          });
        }

        if (this.salesApproval) {
          if (this.isWarehouseView) {
            // @ts-ignore ToDo Backend needs to implement picking for TiB
            this.transfersService.postPicking(this.salesOrderExternalId, [request])
              .subscribe(onSuccess, onError);
          } else {
            this.shippingService.postPicking(this.salesOrderExternalId, [request])
              .subscribe(onSuccess, onError);
          }
        } else {
          if (this.isWarehouseView) {
            this.transfersService.postShipping(this.salesOrderExternalId, [request])
              .subscribe(onSuccess, onError);
          } else {
            this.shippingService.postShipping(this.salesOrderExternalId, [request])
              .subscribe(onSuccess, onError);
          }
        }
      } else {
        let editRequest;
        if (this.isWarehouseView) {
          editRequest = new TransferInBondShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            transferInBondItemExternalId: this.item.externalId,
            shipmentItemSelectionCriteriaId: TransferInBondShippedItemRequestModelShipmentItemSelectionCriteriaId[this.shipping.shipmentItemSelectionCriteriaId],
            shipmentItems: this.shipping.shipmentItems
          });
        } else {
          editRequest = new SalesOrderShippedItemUpdateRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            salesOrderItemExternalId: this.item.externalId,
            shipmentItemSelectionCriteriaId: ShipmentType[this.shipping.shipmentItemSelectionCriteriaId],
            shipmentItems: this.shipping.shipmentItems
          });
        }
        if (this.salesApproval) {
          if (this.isWarehouseView) {
            // @ts-ignore ToDo Backend needs to implement picking for TiB
            this.transfersService.putPicking(this.salesOrderExternalId, this.shippingDetails.transactionExternalId, [editRequest])
              .subscribe(onSuccess, onError);
          } else {
            this.shippingService.putPicking(this.salesOrderExternalId, this.shippingDetails.transactionExternalId, [editRequest])
              .subscribe(onSuccess, onError);
          }
        } else {
          if (this.isWarehouseView) {
            this.transfersService.putShipping(this.salesOrderExternalId, this.shippingDetails.shipmentExternalId, [editRequest])
              .subscribe(onSuccess, onError);
          } else {
            this.shippingService.putShipping(this.salesOrderExternalId, this.shippingDetails.shipmentExternalId, [editRequest])
              .subscribe(onSuccess, onError);
          }
        }
      }
    } else {
      const pickedRequest = new ShipPickedItemsRequestModel({
        dateTime: Math.floor(this.shippedDate.value),
        transactionExternalIds: this.checkedItems
      });
      if (this.isWarehouseView) {
        // @ts-ignore ToDo Backend needs to implement picking for TiB
        this.transfersService.putShipPickedItems(this.salesOrderExternalId, pickedRequest)
          .subscribe(onSuccess, onError);
      } else {
        this.shippingService.putShipPickedItems(this.salesOrderExternalId, pickedRequest)
          .subscribe(onSuccess, onError);
      }
    }
  }

  checkItem(externalId: string): void {
    if (this.isItemChecked(externalId)) {
      this.checkedItems.splice(this.checkedItems.findIndex(el => el === externalId), 1);
    } else {
      this.checkedItems.push(externalId);
    }
  }

  isItemChecked(externalId: string): boolean {
    return !!this.checkedItems.includes(externalId);
  }

  handleOverlayTrigger(event: boolean) {
    this.showOverlay = event;
  }

  toggleOverlay() {
    /** TODO
     * Workaround for wrong code execution order
     * This function should be related with clickOutside.
     * Click events emitted from manual selection overlay triggers clickOutside directive,
     * to avoid closing modal checking showOverlay property is required.
     */
    setTimeout(() => {
      this.showOverlay = !this.showOverlay;
    }, 300);
  }

  private addShippedItems() {
    if (this.item && this.item.container && this.item.container.externalId) {
      this.shippingDetails.details.forEach(
        (ship: SalesOrderItemTransactionDetailsResponseModel) => {
          this.semiFinishedGoodsSelectItemsService.setSemiFinishedGoodsShipping2(
            this.item.externalId,
            <SFGShipping>{itemBatchExternalId: ship.itemBatchExternalId, unitId: `${ship.startId}`, itemIndex: this.item.itemIndex}
          );
        }
      );
    }
  }

  get shipmentType(): AbstractControl {
    return this.form.get(this.shipmentTypeControlName);
  }

  get shippedDate(): AbstractControl {
    return this.form.get('shippedDate');
  }

  get unitIds(): FormArray {
    return this.form.get('unitIds') as FormArray;
  }

  get currentDate(): number {
    return Math.floor(Date.now() / 1000);
  }

  get getRemainingQuantityUpdated(): number {
    return (!!this.remainingQuantity || this.remainingQuantity === 0) ? this.remainingQuantity : this.getRemainingQuantity;
  }

  get getRemainingQuantity(): number {
    const items: SalesOrderItemTransactionResponseModel[] =
      this.salesApproval ? this.item.pickedItems : this.item.shippedItems;
    return (!!items && items.length)
      ? items.reduce((quantity: number, item: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel) => {
        return quantity - Number(item.quantity);
      }, this.item.quantity)
      : this.item.quantity;
  }

  get getCanConfirmShipping(): boolean {
    if (!this.pickStrategy) {
      return !this.canConfirmShipping;
    } else {
      return !this.checkedItems.length;
    }
  }

  get modalTitle(): string {
    if (!this.editMode) {
      return `${this.salesApproval ? 'Pick' : 'Ship'} items`;
    } else {
      return `Editing ${this.salesApproval ? 'picked' : 'shipped'} items`;
    }
  }

  get transactionId(): string {
    if (this.shippingDetails) {
      return this.shippingDetails.shipmentExternalId ? this.shippingDetails.shipmentExternalId : this.shippingDetails.transactionExternalId;
    }
  }

  get shipmentTypeControlName(): string {
    if (this.item.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood && this.item.masterItem.numberOfUnits) {
      return `shipmentType-${this.item.externalId + this.item.numberOfUnits}`;
    } else {
      return `shipmentType-${this.item.externalId}`;
    }
  }

}
