import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  LookUpResponse,
  MasterItemRequestModel,
  MasterItemResponseModelMasterItemTypeId,
  MasterItemTemplateRequestModel,
  MasterItemTemplateResponseModel,
  MasterItemType,
  PaginatedResponseOfLookUpResponse,
  SpiritItemFermentableMaterialModel,
  MasterItemResponseModel
} from '../../../core/services/Inventory';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { barcodeRegex, isInteger, MixPanelEvents } from '../..';
import { SettingsService } from '../../../account/settings/settings.service';
import { FormatsService } from '../../services/formats.service';
import { MasterItemsService } from '../../../inventory/master-items/master-item.service';
import { InventoryLookupsService } from '../../services/inventory-lookups.service';
import { MasterItemFormClass } from '../../classes/master-item-form';
import { defaultParams } from './create-item-statics';
import { clone } from 'lodash';
import { prependZeros } from '../../utils';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { MasterItemQuantityRequestModel } from '@onbatch/core/services/InventoryRx';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, concat } from 'rxjs';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html'
})
export class CreateItemComponent extends MasterItemFormClass implements OnInit, OnDestroy {

  masterItem: MasterItemRequestModel = new MasterItemRequestModel();
  isAddQtyDisabled: boolean;

  @Output() onCloseIconClick = new EventEmitter();


  constructor(
    public masterItemService: MasterItemsService,
    public router: Router,
    public route: ActivatedRoute,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public modalService: NgxSmartModalService,
    public settingsService: SettingsService,
    public formatsService: FormatsService,
    public lookupsService: InventoryLookupsService,
    public subscriptionService: SubscriptionService,
    private mixpanelService: MixpanelService
  ) {
    super(masterItemService, router, route, fb, toastr, modalService, settingsService, formatsService, lookupsService, subscriptionService);
  }

  ngOnInit(): void {
    this.masterItem.barcode = '';
    this.masterItem.isPacked = false;
    // `Create from template` functionality
    // this.checkIfTemplate();
    this.initForms();
    this.setAlcoholByVolumeValidation();

    this.masterItemService.setMasterItemSide(true);
    this.masterItemService.setItemID('create');
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.paramsForKindOfSpirit = clone(defaultParams);
    this.paramsForCategories = clone(defaultParams);
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    this.masterItemService.setMasterItemSide(false);
    this.masterItemService.setLocationsAddedToMasterItem([]);
  }

  checkIfTemplate(): void {
    this.lookupTemplates();
    this.routerSubscription = this.route.params.subscribe(params => {
      if (params['templateId']) {
        this.renderFormBoxes = false;
        this.templateId = params['templateId'];
        this.masterItemService
          .getSingleMasterItemTemplate(this.templateId)
          .subscribe((template: MasterItemTemplateResponseModel) => {
            try {
              this.loadedTemplate = JSON.parse(template.templates[0].value);
            } catch (error) {
              this.toastr.error('Not a valid template.', 'Error');
              return;
            } finally {
              if (this.loadFromTemplate) {
                Object.entries(this.loadedTemplate).map(entry => {
                  if (entry[0] === 'masterItemTypeId') {
                    this.onTypeChange(entry[1]);
                  }
                  if (this.form.controls[entry[0]] !== undefined) {
                    this.form.controls[entry[0]].setValue(entry[1]);
                    if (entry[0] === 'categories') {
                      this.form.controls[entry[0]].setValue(entry[1]);
                    }
                  }
                  this.form.controls['itemNumber'].setValue(prependZeros(this.loadedTemplate['itemNumber']));
                  this.form.controls['name'].setValue('');
                });
              }
              this.renderFormBoxes = true;
            }
          });
      }
    });
  }

  initForms(): void {
    this.masterItemService.getLastNumber()
      .subscribe((number: number) => {
        this.form = this.fb.group({
          name: new FormControl('', [Validators.required, Validators.maxLength(128)]),
          barcode: new FormControl('', [Validators.pattern(barcodeRegex)]),
          itemNumber: new FormControl(prependZeros(number + 1), [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
          inventoryStrategyId: new FormControl('Fifo', Validators.required),
          unitOfMeasurementExternalId: new FormControl(null, Validators.required),
          description: new FormControl(''),
          isSellable: new FormControl(false, Validators.required),
          masterItemTypeId: new FormControl(MasterItemResponseModelMasterItemTypeId.RawMaterial, Validators.required),
          industryId: new FormControl(null),
          criticalStockLevel: new FormControl(null,
            this.isFeatureAvailable.inventory_CriticalStockAlert ? Validators.min(0) : null
          ),
          volume: new FormControl(null, Validators.min(0)),
          categories: new FormControl(null),
          locations: this.fb.array([])
        });
        if (this.form.get('masterItemTypeId')) {
          this.form.get('masterItemTypeId').valueChanges.subscribe(() => {
            this.lookupUoM(true);
            this.lookupLocations();
          });
        }
        this.lookupKindOfSpirit();
        this.lookupFermentable();
        this.lookupUoM();
        this.lookupVolumeUoM();
        this.lookupLocations();
        this.lookupCategories();
      });

    this.specificFormGroup = new FormGroup({
      kindOfSpirit: new FormControl(null),
      alcoholByVolume: new FormControl(null, this.isAlcohol ? [Validators.min(0.01), Validators.max(100)] : null),
      fermentableMaterialCategoryId: new FormControl(null),
      isVariableAlcohol: new FormControl(null),
      fermentableMaterialSubCategoryId: new FormControl(null)
    });

    this.containerFormGroup = new FormGroup({
      capacityUnitOfMeasurementExternalId: new FormControl(null),
      masterItemContainerTypeId: new FormControl('Barrel'),
      barrelConditionId: new FormControl(null),
      capacity: new FormControl(null),
      barrelCharLevel: new FormControl(null),
      barrelWoodSpeciesId: new FormControl(null),
      barrelWoodOrigin: new FormControl(null),
      barrelCooperage: new FormControl(null),
      barrelDryMethodId: new FormControl(null),
      barrelSeasonedLengthYear: new FormControl(null),
      barrelSeasonedLengthMonth: new FormControl(null)
    });
  }

  prepareLocations(): void {
    this.masterItem.facilityQuantities = this.locations;
    Object.entries(this.form.controls).map(entry => {
      if (entry[0] === 'criticalStockLevel') {
        this.masterItem[entry[0]] = parseFloat(entry[1].value);
      } else {
        this.masterItem[entry[0]] = entry[1].value;
      }
    });
  }

  prepareNonRawMaterialItems(): void {
    if (this.form.controls['masterItemTypeId'].value !== 'RawMaterial') {
      this.isBarrel = false;
      this.isFermentable = false;
      this.spiritItem.fermentableMaterial = null;
    }
  }

  prepareNonPackagingMaterialItems(): void {
    if (this.form.controls['masterItemTypeId'].value !== 'PackagingMaterial') {
      this.spiritItem.isAlcohol = this.isAlcohol;
      this.spiritItem.variableAlcohol = this.isVariableAlcohol;
      this.spiritItem.alcoholContent = this.specificFormGroup.controls['alcoholByVolume'].value;
      if (!this.isFermentable) {
        this.setKindOfSpiritValue();
      }
      this.spiritItem.fermentableMaterial = this.isFermentable
        ? new SpiritItemFermentableMaterialModel({
          categoryExternalId: this.specificFormGroup.controls['fermentableMaterialCategoryId'].value,
          subCategoryExternalId: this.specificFormGroup.controls['fermentableMaterialSubCategoryId'].value.externalId ? this.specificFormGroup.controls['fermentableMaterialSubCategoryId'].value.externalId : null,
          subCategoryName: this.specificFormGroup.controls['fermentableMaterialSubCategoryId'].value.name ? this.specificFormGroup.controls['fermentableMaterialSubCategoryId'].value.name : null
        })
        : null;

      if (this.form.controls['masterItemTypeId'].value === 'Container') {
        Object.entries(this.containerFormGroup.controls).map(entry => {
          this.container[entry[0]] = entry[1].value;
        });
        this.container.barrelIsToasted = this.isBarrelToasted;
        this.container.barrelSeasonedLength = this.containerFormGroup.controls['barrelSeasonedLengthYear'].value * 12 + this.containerFormGroup.controls['barrelSeasonedLengthMonth'].value;
      }
    }
  }

  onFormSubmit(): void {
    this.masterItem.itemNumber = parseInt(this.form.controls['itemNumber'].value, 10);
    this.masterItemService
      .validateItemNumber(this.masterItem.itemNumber)
      .subscribe((itemNumberValid: boolean) => {
        if (itemNumberValid) {
          this.formSubmitStatus = true;

          this.validateBoM();
          this.validateLocations();
          this.validatePackage(this.masterItem.isPacked);

          if (this.form.invalid ||
            this.specificFormGroup.invalid ||
            !this.isPackageValid ||
            this.containerFormGroup.invalid ||
            this.bomInvalid) {
            return;
          }

          this.prepareLocations();
          this.prepareNonRawMaterialItems();
          this.prepareNonPackagingMaterialItems();

          this.masterItemService
            .postMasterItem(this.masterItem)
            .pipe(
              switchMap((masterItem: MasterItemResponseModel) => {
                const masterItemType = this.form.controls['masterItemTypeId'].value;
                const additionalRequests: Array<Observable<unknown>> = [];
                if (masterItemType !== this.itemTypes.PackagingMaterial && masterItemType !== this.itemTypes.Container) {
                  additionalRequests.push(
                    this.masterItemService.putSpiritItem(masterItem.externalId, this.spiritItem)
                  );
                }
                if (masterItemType === this.itemTypes.Container) {
                  additionalRequests.push(
                    this.masterItemService.putContainer(masterItem.externalId, this.container)
                  );
                }
                if (this.masterItem.isPacked) {
                  additionalRequests.push(
                    this.masterItemService.putPackage(masterItem.externalId, this.package)
                  );
                }
                if (this.bomVisible && this.newBill.length > 0) {
                  additionalRequests.push(
                    this.masterItemService.putBillOfMaterials(masterItem.externalId, this.newBill)
                  );
                }

                if (additionalRequests.length) {
                  return concat(...additionalRequests.map((req) => req.pipe(
                    map(_ => masterItem.externalId)
                  )));
                }

                return of(masterItem.externalId);
              }))
            .subscribe((masterItemExternalId: string) => {
              this.mixpanelService.track(MixPanelEvents.INVENTORY_MASTER_ITEM_ADDED, {
                'Master item name': this.masterItem.name,
                'Master item external ID': masterItemExternalId,
              });
              this.navigateAfterAdd(masterItemExternalId);
            });
        } else {
          this.formSubmitStatus = true;
          this.form.controls['itemNumber'].setErrors({ 'errorMessage': 'Number is not unique' });
          return;
        }
      });
  }

  navigateAfterAdd(id: string): void {
    if (this.isOverlay) {
      this.onCloseIconClick.emit();
    } else {
      this.masterItemService.fetchNewList();
      this.router.navigateByUrl(`/inventory/master-items/items/${id}`);
    }
  }

  afterExpirationDateChanged(date: number, index: number) {
    this.locations[index].expirationDate = date;
  }

  lookupTemplates(): void {
    this.lookupsService
      .lookupTemplates(
        this.paramsForTemplates.x_query,
        this.paramsForTemplates.x_order,
        this.paramsForTemplates.x_desc,
        this.paramsForTemplates.x_pageNumber,
        this.paramsForTemplates.x_pageSize
      )
      .subscribe((templates: PaginatedResponseOfLookUpResponse) => {
        this.templates = templates.list;
      });
  }

  lookupUoM(clear: boolean = false): void {
    let lookupType: MasterItemType;
    if (this.form) {
      lookupType = this.form.get('masterItemTypeId').value.toString() as MasterItemType;
    } else {
      return;
    }
    this.lookupsService
      .lookupUnitsOfMeasurement(
        lookupType,
        this.paramsForUoM.x_pageNumber,
        this.paramsForUoM.x_pageSize,
        this.paramsForUoM.x_query,
        this.paramsForUoM.x_order,
        this.paramsForUoM.x_desc
      )
      .subscribe((res: PaginatedResponseOfLookUpResponse) => {
        if (lookupType !== MasterItemType.Container) {
          if (clear) {
            this.masterItemUOM.length = 0;
          }
          this.masterItemUOM = [...this.masterItemUOM, ...res.list];
          this.formBoxes['uom'].select['items'] = this.masterItemUOM;
        } else {
          this.masterItemUOM = res.list;
        }
        this.lookupUoMPages = res.pageInfo.totalPages;
        if (this.masterItemUOM.length === 1) {
          this.form.get('unitOfMeasurementExternalId').setValue(this.masterItemUOM[0].externalId);
        }
        this.onTypeChange(this.form.get('masterItemTypeId').value);
      });

  }

  toggleTemplateList(): void {
    this.templateListVisible = !this.templateListVisible;
  }

  closeTemplateList(event: { value: boolean }): void {
    if (event.value && this.templateListVisible) {
      this.templateListVisible = false;
    }
  }

  openRenameModal(id: string): void {
    this.selectedTemplateId = id;
    this.modalService.getModal('renameTemplate').open();
    this.templateListVisible = false;
  }

  onRenameTemplate(): void {
    let currentTemplate: MasterItemTemplateResponseModel = new MasterItemTemplateResponseModel();
    this.masterItemService
      .getSingleMasterItemTemplate(this.selectedTemplateId)
      .subscribe((template: MasterItemTemplateResponseModel) => {
        currentTemplate = template;
        currentTemplate.name = this.renamedTemplateName;
        this.masterItemService
          .updateTemplate(
            this.selectedTemplateId,
            new MasterItemTemplateRequestModel({ templates: currentTemplate.templates, name: currentTemplate.name })
          )
          .subscribe(() => {
            this.toastr.info(`Template: ${this.selectedTemplateId} updated`, 'Info');
            this.modalService.getModal('renameTemplate').close();
            this.lookupTemplates();
          });
      });
  }

  onDeleteTemplate(id: string): void {
    this.masterItemService
      .deleteTemplate(id)
      .subscribe(() => {
        this.toastr.info(`Template: ${id} deleted`, 'Info');
        this.templateListVisible = false;
        this.lookupTemplates();
      });
  }

  loadFromTemplate(id: string): void {
    this.router.navigateByUrl(`/inventory/master-items/items/create/${id}`);
    this.templateListVisible = false;
  }

  clearValidator(control: AbstractControl): void {
    if (control) {
      control.setValidators(null);
      control.setErrors(null);
    }
  }

  onTypeChange(event: string): void {
    const cat = this.specificFormGroup.get('fermentableMaterialCategoryId');
    const subCat = this.specificFormGroup.get('fermentableMaterialSubCategoryId');
    const species = this.containerFormGroup.get('barrelWoodSpeciesId');
    const dry = this.containerFormGroup.get('barrelDryMethodId');
    const condition = this.containerFormGroup.get('barrelConditionId');
    const capacity = this.containerFormGroup.get('capacity');
    const containerType = this.containerFormGroup.get('masterItemContainerTypeId');
    const kindOfSpirit = this.specificFormGroup.get('kindOfSpirit');
    const alcoholByVolume = this.specificFormGroup.get('alcoholByVolume');
    const volume = this.form.get('volume');
    const containerUom = this.containerFormGroup.get('capacityUnitOfMeasurementExternalId');
    const uom = this.form.get('unitOfMeasurementExternalId');
    const isSellable = this.form.get('isSellable');
    const barcode = this.form.get('barcode');
    this.clearValidator(containerUom);
    this.clearValidator(species);
    this.clearValidator(dry);
    this.clearValidator(condition);
    this.clearValidator(capacity);
    this.clearValidator(cat);
    this.clearValidator(subCat);
    this.clearValidator(containerType);
    this.clearValidator(volume);
    this.setAlcoholByVolumeValidation();
    kindOfSpirit.setValidators(Validators.required);
    isSellable.setValue(false);
    containerUom.setValue(null);
    containerType.setValue(null);
    barcode.setValue(null);
    barcode.setErrors(null);
    this.isVariableAlcohol = false;
    this.isRawMaterial = event === 'RawMaterial';
    uom.setValue(null);
    if (this.lookupUoMPages === 1 && this.masterItemUOM.length === 1) {
      uom.setValue(this.masterItemUOM[0].externalId);
      this.uom = this.masterItemUOM[0].name;
    }
    this.packageVisible = false;
    this.masterItem.isPacked = false;
    this.isAlcohol = true;
    this.specificDetailsVisible = true;
    this.bomVisible = false;
    this.uomVisible = true;
    switch (event) {
      case 'RawMaterial': {
        this.isAddQtyDisabled = false;
        alcoholByVolume.setValue(null);
        this.clearValidator(alcoholByVolume);
        this.isPackageValid = true;
        this.isAlcohol = false;
        this.clearValidator(kindOfSpirit);
        break;
      }
      case 'FinishedGood': {
        this.isAddQtyDisabled = true;
        this.bomVisible = true;
        this.packageVisible = true;
        this.masterItem.isPacked = true;
        this.isPackageValid = false;
        this.isFermentable = false;
        this.spiritItem.fermentableMaterial = null;
        volume.setValidators([Validators.required, Validators.min(0)]);
        volume.updateValueAndValidity();
        isSellable.setValue(true);
        kindOfSpirit.setValidators(Validators.required);
        kindOfSpirit.setErrors({ invalid: true });
        break;
      }
      case 'PackagingMaterial': {
        this.isAddQtyDisabled = false;
        this.specificDetailsVisible = false;
        this.clearValidator(kindOfSpirit);
        alcoholByVolume.setValue(null);
        this.clearValidator(alcoholByVolume);
        this.isPackageValid = true;
        break;
      }
      case 'Container': {
        this.isAddQtyDisabled = false;
        this.bomVisible = true;
        this.uomVisible = false;
        this.onContainerTypeChange('Barrel');
        this.clearValidator(kindOfSpirit);
        alcoholByVolume.setValue(null);
        this.clearValidator(alcoholByVolume);
        capacity.setValidators(Validators.required);
        containerUom.setValidators(Validators.required);
        capacity.setErrors({ 'incorrect': true });
        containerType.setValidators(Validators.required);
        this.containerFormGroup.get('capacityUnitOfMeasurementExternalId').setValue(this.containersUOM[0].externalId);
        break;
      }
      case 'SemiFinishedGood': {
        this.isAddQtyDisabled = true;
        this.uomVisible = false;
        this.isPackageValid = true;
        this.isVariableAlcohol = true;
        this.specificFormGroup.controls['alcoholByVolume'].setValidators(null);
        this.specificFormGroup.controls['alcoholByVolume'].updateValueAndValidity();
        break;
      }
      default: {
        break;
      }
    }
    this.containerFormGroup.updateValueAndValidity();
    this.specificFormGroup.updateValueAndValidity();

    // Clear Bill of Materials
    this.addNew = false;
    this.bill = [];
    this.itemLookup = [];
    this.lookUpItems();

    if (this.isAddQtyDisabled) {
      this.locations = [];
    }
  }

  onUOMchange(uom: LookUpResponse): void {
    this.uom = uom.name;
    if (!this.isFeatureAvailable.inventory_CriticalStockAlert) {
      this.form.controls['criticalStockLevel'].setValidators(null);
    } else {
      if (uom.name === 'Each') {
        this.form.controls['criticalStockLevel'].setValidators([Validators.min(1), isInteger()]);
      } else {
        this.form.controls['criticalStockLevel'].setValidators([Validators.min(0)]);
      }
    }
    this.form.controls['criticalStockLevel'].updateValueAndValidity();
    this.locations.forEach((location: MasterItemQuantityRequestModel, i: number) => {
      this.afterQuantityChanged(location.quantity, i, this.uom);
    });
  }

  close(): void {
    if (this.isOverlay) {
      this.onCloseIconClick.emit();
    } else {
      this.mixpanelService.track(MixPanelEvents.INVENTORY_MASTER_ITEM_ADD_CANCELED, {});
      this.router.navigateByUrl('/inventory/master-items/items');
    }
  }
}

export interface KindOfSpirit {
  name: string;
  // merges both originalExternalId and customExternalId
  externalId: string;
  originalExternalId: string;
  customExternalId: string;
  isCustom?: boolean;
}
