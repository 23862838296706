import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { CompanySubscriptionResponseModel, Features2 } from '@onbatch/core/services/Account';

@Injectable()
export class SubscriptionGuard implements CanActivate {
  constructor(private subscriptionService: SubscriptionService, private toastr: ToastrService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;
    const currentSubscription: CompanySubscriptionResponseModel = this.subscriptionService.currentSubscription.value;
    if (currentSubscription) {
      const featuresInSubscription: Features2 = currentSubscription.features;

      if (featuresInSubscription) {
        canActivate = featuresInSubscription[next.data.feature];
      }

      if (!canActivate) {
        this.toastr.warning(`Your subscription doesn't include this feature.`, 'Access denied');
      }
    }
    return canActivate;
  }
}
