import {RangeIndex, Range} from '../../../../sales/shared/components/sales-items/sales-items-interfaces';

export function detectRanges(indices: number[], lotId: string): RangeIndex[] {
  return indices
    .sort((a: number, b: number) => a - b)
    .reduce((acc: RangeIndex[], indexValue: number, index: number): RangeIndex[] => {
      if (index === 0) {
        return [
          {
            start: indexValue,
            end: indexValue,
            items: [indexValue],
            length: 1,
            lotId
          }
        ];
      }

      const lastRange: RangeIndex = acc[acc.length - 1];
      if (indexValue === lastRange.end + 1) {
        const allButLast: RangeIndex[] = acc.slice(0, acc.length - 1);
        return [
          ...allButLast,
          {
            ...lastRange,
            end: indexValue,
            items: [...lastRange.items, indexValue],
            length: lastRange.length + 1,
            lotId
          }
        ];
      } else {
        return [
          ...acc,
          {
            start: indexValue,
            end: indexValue,
            items: [indexValue],
            length: 1,
            lotId
          }
        ];
      }
  }, []);
}

export function mapIndexRangesToItems<T>(indexRanges: RangeIndex[], items: T[]): Range<T>[] {
  return indexRanges.map((range: RangeIndex): Range<T> => ({
      start: items[range.start],
      end: items[range.end],
      items: range.items.map(idx => items[idx]),
      length: range.length,
      lotId: null
    })
  );
}
