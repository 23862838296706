import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ClickOutsideData } from '@onbatch/shared/directives';
import { checkIfClickedElementHasClass, checkIfClickedParentElementHasClass } from '@onbatch/shared/utils';

export interface ModalGenericSettings {
  cancelButton?: boolean;
  cancelButtonLabel?: string;
  clickOutside?: boolean;
  scroll?: boolean;
  submitButton?: boolean;
  submitButtonLabel?: string;
  withLeftArrow?: boolean;
  customClass?: string;
}

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html'
})
export class ModalGenericComponent {
  @Input() description: string;
  @Input() title: string;
  @Input() disabledSubmit: boolean;
  @Input() set options (data: ModalGenericSettings) {
    this.defaultOptions = {...this.defaultOptions, ...data};
  }

  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter();

  defaultOptions: ModalGenericSettings = {
    cancelButton: true,
    cancelButtonLabel: 'Cancel',
    clickOutside: false,
    scroll: false,
    submitButton: true,
    submitButtonLabel: 'Save',
    withLeftArrow: false,
    customClass: '',
  };

  onCancelClick(): void {
    this.cancel.emit();
  }

  onSaveClick() {
    if (!this.disabledSubmit) {
      this.submit.emit();
    }
  }

  onClickOutside(data: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(data, ['ng-option', 'owl']);
    let parentElementHasClassOwl = false;
    if (!isExcludedElementClicked) {
      parentElementHasClassOwl = checkIfClickedParentElementHasClass((data.target as HTMLElement), 'owl');
    }
    if (data.value && !isExcludedElementClicked && !parentElementHasClassOwl) {
      this.cancel.emit();
    }
  }
}
