import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
  TemplateRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { PageInfo } from '../../../core/services/Inventory';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnChanges {
  @HostBinding('style.width') public width: '100%';
  @Input() options: PageInfo;
  @Input() total_text: string;
  @Input() is_short = false;
  @Input() total_text_secondary: string;
  @Input() custom_class = '';
  @Input() paginationTotalTemplate: TemplateRef<any>;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  pagination_options = [
    { name: '10 items', value: 10 },
    { name: '20 items', value: 20 },
    { name: '50 items', value: 50 },
    { name: '100 items', value: 100 },
    { name: '200 items', value: 200 }
  ];

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.total_text = this.options.totalCount ? `${this.options.totalCount} items` : '';
    this.total_text_secondary = this.options.customCount ? `${this.options.customCount} other items` : '';
  }

  ngOnChanges(): void {
    this.total_text = this.options.totalCount ? `${this.options.totalCount} items` : '';
    this.total_text_secondary = this.options.customCount ? `${this.options.customCount} other items` : '';

    if (!!(this.options.totalCount > 0)) {
      this.validateInput();
    }
  }

  prevPage() {
    this.options.pageNumber--;
    this.changed.emit(this.options);
  }

  nextPage() {
    this.options.pageNumber++;
    this.changed.emit(this.options);
  }

  setItems(items: any) {
    this.options.pageSize = items.value;
    this.options.pageNumber = 1;
    this.changed.emit(this.options);
  }

  validateInput() {
    if (this.options.pageNumber > this.options.totalPages) {
      this.options.pageNumber = this.options.totalPages;
      this.changed.emit(this.options);
    }
    if (this.options.pageNumber < 1) {
      this.options.pageNumber = 1;
      this.changed.emit(this.options);
    }
    this.cd.detectChanges();
  }

  pageChanged() {
    this.changed.emit(this.options);
  }
}
