import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';

import { checkIfDatePickerWasClicked, closeCalendar } from '@onbatch/shared/utils';
import {
  SalesOrderItemResponseModel,
  SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId,
  SalesOrderItemTransactionResponseModel,
  SellableMasterItemResponseModelMasterItemTypeId,
  ShipmentItemResponseModel,
  ShipmentResponseModel,
  ShippedItemRequestModel,
  ShipPickedItemsRequestModel,
  SalesOrderShippedItemUpdateRequestModelShipmentItemSelectionCriteriaId as ShipmentType
} from '@onbatch/core/services/Sales';
import { pricePrefix } from '@onbatch/shared/constants';
import { Shipping } from '../ship-order-modal/shipment-type.enum';
import { ClickOutsideData } from '@onbatch/shared/directives';
import {
  TransferInBondIncomingItemResponseModel,
  TransferInBondOutgoingItemResponseModel,
  TransferInBondResponseModel,
  TransferInBondShippedItemRequestModel,
  WarehousingMasterItemResponseModelMasterItemTypeId
} from '@onbatch/core/services/Warehousing';
import { TransfersService } from 'app/warehousing/transfers/transfers.service';
import { FinishedGoodsSelectItemsService } from '@onbatch/shared/services/finished-goods-select-items.service';
import { SemiFinishedGoodsSelectItemsService } from '@onbatch/shared/services/semi-finished-goods-select-items.service';

@Component({
  selector: 'app-ship-items-overlay-tib',
  templateUrl: './ship-items-overlay-tib.component.html'
})
export class ShipItemsOverlayTiBComponent implements OnInit {

  @Input() opened: boolean;
  @Input() transfer: TransferInBondResponseModel;
  @Input() salesApproval = false;
  @Input() pickStrategy = false;
  @Input() shipmentToEdit: ShipmentResponseModel = null;

  @Output() closeOverlay = new EventEmitter();
  @Output() shippingConfirmed = new EventEmitter();

  form: FormArray = new FormArray([]);
  globalForm: FormGroup;

  remainingQuantity: RemainingQuantity[] = [];
  formSubmitStatus = false;
  closeDatepicker = closeCalendar;

  checkedItems: ItemState[] = [];
  checkedTransactions: string[] = [];

  requestInFlight = false;
  canConfirmShipping = false;

  showOverlay = false;
  selectedItemIndex = 0;

  readonly pricePrefix = pricePrefix;
  readonly masterItemType = WarehousingMasterItemResponseModelMasterItemTypeId;
  readonly shipmentCriteria = SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId;
  currentItem: SalesOrderItemResponseModel;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private transfersService: TransfersService,
    private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
    private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    if (!!this.transfer.incomingItems) {
      this.transfer.incomingItems.forEach(
        (item: TransferInBondIncomingItemResponseModel) => this.finishedGoodsSelectItemsService.fetchShippingLotsFinishedGoodByMasterItemExternalIdGet(item.masterItem.externalId)
      );
    }
    if (!!this.transfer.outgoingItems) {
      this.transfer.outgoingItems.forEach(
        (item: TransferInBondOutgoingItemResponseModel) => this.finishedGoodsSelectItemsService.fetchShippingLotsFinishedGoodByMasterItemExternalIdGet(item.masterItem.externalId)
      );
    }
  }

  doCloseOverlay(event: ClickOutsideData) {
    if (event) {
      const isSelect = event.target
        && (event.target as HTMLElement).className
        && (event.target as HTMLElement).className.includes
        && ((event.target as HTMLElement).className.includes('ng-option-marked') || (event.target as HTMLElement).className.includes('ng-option-label'));
      if (event.value && this.opened && !checkIfDatePickerWasClicked(event) && !isSelect && !this.showOverlay) {
        this.closeOverlayModal();
      }
    } else {
      this.closeOverlayModal();
    }
  }

  closeOverlayModal(): void {
    this.closeOverlay.emit();
    this.finishedGoodsSelectItemsService.clearAllManualSelectingState();
    this.semiFinishedGoodsSelectItemsService.clearAllManualSelectingState();
    this.selectedItemIndex = 0;
  }

  checkItem(item: SalesOrderItemResponseModel, idx: number, shipmentType?: ShipmentType, quantity?: number): void {
    if (!this.pickStrategy) {
      if (!this.isItemChecked(item.externalId, item.itemIndex)) {
        this.form.push(this.fb.group({
          externalId: new FormControl(item.externalId),
          itemIndex: new FormControl(item.itemIndex),
          shippedDate: new FormControl(null),
          [`shipmentType-${item.externalId}`]: new FormControl(shipmentType || this.getDefaultShipmentType(item.masterItem.masterItemTypeId, item), Validators.required),
          unitIds: new FormArray([]),
          equipment: new FormArray([]),
          containerIds: new FormArray([]),
          lotIds: new FormArray([]),
          shippedLotQuantity: new FormControl(''),
          shippedVolume: new FormControl(quantity || ''),
          shippedQuantity: new FormControl(quantity || ''),
          items: new FormControl(null),
        }));
        this.remainingQuantity.push({
          quantity: this.getRemainingQuantity(item.externalId, idx),
          externalId: item.externalId,
          itemIndex: item.itemIndex,
        });
        this.checkedItems.push({
          externalId: item.externalId,
          isValid: false,
          itemIndex: item.itemIndex
        });
      } else {
        const formIdx: number = this.form.controls.findIndex((form: FormGroup) => form.controls['externalId'].value === item.externalId && form.controls['itemIndex'].value === item.itemIndex);
        const remainingQuantityIdx: number = this.remainingQuantity.findIndex(el => el.externalId === item.externalId && el.itemIndex === item.itemIndex);
        const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === item.externalId && el.itemIndex === item.itemIndex);

        this.form.removeAt(formIdx);
        this.remainingQuantity.splice(remainingQuantityIdx, 1);
        this.checkedItems.splice(checkedItemIdx, 1);
      }
    } else {
      this.multicheckTransactions(item);
    }
  }

  getDefaultShipmentType(type: SellableMasterItemResponseModelMasterItemTypeId, item?: SalesOrderItemResponseModel): ShipmentType {
    switch (type) {
      case SellableMasterItemResponseModelMasterItemTypeId.FinishedGood: {
        return ShipmentType.ByUnitIds;
      }
      case SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood: {
        if (item && item.container && item.container.externalId) {
          return ShipmentType.ByItemBatchExternalId;
        }
        return ShipmentType.ByEquipmentId;
      }
      case SellableMasterItemResponseModelMasterItemTypeId.RawMaterial:
      case SellableMasterItemResponseModelMasterItemTypeId.PackagingMaterial: {
        return ShipmentType.ByLotId;
      }
      default: {
        return null;
      }
    }
  }

  initForm() {
    const date: number = this.shipmentToEdit ? this.shipmentToEdit.shippedDate : Math.floor(Date.now() / 1000);
    this.globalForm = this.fb.group({
      shippedDate: new FormControl(date, Validators.required),
    });
    if (this.shipmentToEdit) {
      this.initShipmentToEdit();
    }
  }

  initShipmentToEdit() {
    this.shipmentToEdit.shipmentItems.forEach((item: ShipmentItemResponseModel, index: number) => {
      this.checkItem(new SalesOrderItemResponseModel({
        externalId: item.salesOrderItemExternalId,
        masterItem: item.masterItem
      }), index, ShipmentType[item.details[0].shipmentItemSelectionCriteriaId], item.totalQuantity);
    });
  }

  setDate(date: Moment) {
    if (!!date) {
      this.shippedDate.patchValue(date.format('X'));
    }
  }

  getRemainingQuantityUpdated(externalId: string, idx: number, itemIndex: number): number {
    if (externalId && itemIndex) {
      const remainingQuantity: RemainingQuantity = this.remainingQuantity.find((el) => el.externalId === externalId && el.itemIndex === itemIndex);
      if (!!remainingQuantity && remainingQuantity.quantity >= 0) {
        return remainingQuantity.quantity;
      } else {
        return this.getRemainingQuantity(externalId, idx);
      }
    }
  }

  getRemainingQuantity(externalId: string, idx: number): number {
    if (!!externalId) {
      const items: SalesOrderItemTransactionResponseModel[] =
        this.salesApproval
          // @ts-ignore Once picked Items will be implemented for TiB this comment can be removed
          ? this.transfer.outgoingItems[idx].pickedItems
          : this.transfer.outgoingItems[idx].shippedItems;

      if (this.selectedItemIndex > 0) {
        const outgoingItem = this.transfer && this.transfer.outgoingItems && this.transfer.outgoingItems.find(item => item.itemIndex === this.selectedItemIndex);
        return outgoingItem ? outgoingItem.quantity : 0;
      }

      return items.length
        ? items.reduce((quantity: number, item: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel) => {
            return quantity - (item.quantity
              ? item.quantity
              : (item as ShippedItemRequestModel).volume);
          }, this.transfer.outgoingItems[idx].quantity)
        : this.transfer.outgoingItems[idx].quantity;
    }
  }

  isItemChecked(externalId: string, itemIndex: number): boolean {
    return this.checkedItems.some(el => el.externalId === externalId && el.itemIndex === itemIndex);
  }

  isTransactionChecked(externalId: string): boolean {
    return this.checkedTransactions.includes(externalId);
  }

  checkTransaction(externalId: string): void {
    if (this.isTransactionChecked(externalId)) {
      this.checkedTransactions.splice(this.checkedTransactions.findIndex(el => el === externalId), 1);
    } else {
      this.checkedTransactions.push(externalId);
    }
  }

  multicheckTransactions(item: SalesOrderItemResponseModel): void {
    if (!this.isItemChecked(item.externalId, item.itemIndex)) {
      this.checkedItems.push({
        externalId: item.externalId,
        isValid: true,
        itemIndex: item.itemIndex,
      });
      if (item.pickedItems) {
        item.pickedItems.forEach(pickedItems => {
          if (!pickedItems.wasUsed) {
            if (!this.isTransactionChecked(pickedItems.transactionExternalId)) {
              this.checkedTransactions.push(pickedItems.transactionExternalId);
            }
          }
        });
      }
    } else {
      const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === item.externalId && el.itemIndex === item.itemIndex);
      this.checkedItems.splice(checkedItemIdx, 1);
      if (item.pickedItems) {
        item.pickedItems.forEach(pickedItems => {
          const pickedItemsIdx = this.checkedTransactions.findIndex(el => el === pickedItems.transactionExternalId);
          if (this.checkedTransactions.findIndex(el => el === pickedItems.transactionExternalId) >= 0) {
            this.checkedTransactions.splice(pickedItemsIdx, 1);
          }
        });
      }
    }
  }

  getRemainingPickedItems(item: SalesOrderItemResponseModel): boolean {
    return item.pickedItems.some(el => !el.wasUsed);
  }

  shippingChanged(data: Shipping, externalId: string, itemIndex: number): void {
    const remainingQuantityIdx = this.remainingQuantity.findIndex(el => el.externalId === externalId && el.itemIndex === itemIndex);
    const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === externalId && el.itemIndex === itemIndex);
    const formIdx: number = this.form.controls.findIndex((form: FormGroup) => form.controls['externalId'].value === externalId && form.controls['itemIndex'].value === itemIndex);
    const shipmentType = (this.form.controls[formIdx] as FormGroup).get(`shipmentType-${externalId}`);

    (this.form.controls[formIdx] as FormGroup).get('items').setValue(data.shipmentItems);
    if (!shipmentType.value) {
      shipmentType.setValue(data.shipmentItemSelectionCriteriaId);
    }
    this.remainingQuantity[remainingQuantityIdx].quantity = data.remainingQuantity;
    this.checkedItems[checkedItemIdx].isValid = data.isValid;
    this.canConfirmShipping = this.checkedItems.every(e => e.isValid) && this.globalForm.valid;
  }

  handleOverlayTrigger(event: boolean, item: SalesOrderItemResponseModel) {
    this.currentItem = item;
    this.selectedItemIndex = item.itemIndex;
    this.showOverlay = event;
  }

  confirmShipping(): void {
    const onSuccess = () => {
      this.requestInFlight = false;
      this.closeOverlayModal();
      this.shippingConfirmed.emit();
      this.toastr.success('Success!',
        `${this.salesApproval
          ? `${this.pickStrategy ? 'Shipping' : 'Pick'}`
          : 'Shipping'} confirmed`);
    };

    if (!this.pickStrategy) {
      if (!this.shipmentToEdit) {
        const request: TransferInBondShippedItemRequestModel[] = this.form.controls.map((form: FormGroup) => {
          const itemId: string = form.get('externalId').value;
          return new TransferInBondShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            transferInBondItemExternalId: itemId,
            shipmentItemSelectionCriteriaId: form.get(`shipmentType-${itemId}`).value,
            shipmentItems: form.get('items').value
          });
        });
        this.requestInFlight = true;

        if (this.salesApproval) {
           // @ts-ignore ToDo Backend needs to implement picking for TiB
          this.transfersService.postPicking(this.transfer.externalId, request)
            .subscribe(onSuccess);
        } else {
          this.transfersService.postShipping(this.transfer.externalId, request)
            .subscribe(onSuccess);
        }
      } else {
        const editRequest: TransferInBondShippedItemRequestModel[] = this.form.controls.map((form: FormGroup) => {
          const itemId: string = form.get('externalId').value;
          return new TransferInBondShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            transferInBondItemExternalId: itemId,
            shipmentItemSelectionCriteriaId: form.get(`shipmentType-${itemId}`).value,
            shipmentItems: form.get('items').value
          });
        });
        this.requestInFlight = true;

        if (this.salesApproval) {
          // @ts-ignore ToDo Backend needs to implement picking for TiB
          this.transfersService.putPicking(this.transfer.externalId, this.shipmentToEdit.externalId, editRequest)
            .subscribe(onSuccess);
        } else {
          this.transfersService.putShipping(this.transfer.externalId, this.shipmentToEdit.externalId, editRequest)
            .subscribe(onSuccess);
        }
      }
    } else {
      const pickedRequest = new ShipPickedItemsRequestModel({
        dateTime: Math.floor(this.shippedDate.value),
        transactionExternalIds: this.checkedTransactions
      });
      // @ts-ignore ToDo Backend needs to implement picking for TiB
      this.transfersService.putShipPickedItems(this.transfer.externalId, pickedRequest)
        .subscribe(onSuccess);
    }

  }

  toggleOverlay() {
    /** TODO
     * Workaround for wrong code execution order
     * This function should be related with clickOutside.
     * Click events emitted from manual selection overlay triggers clickOutside directive,
     * to avoid closing modal checking showOverlay property is required.
     */
    setTimeout(() => {
      this.showOverlay = !this.showOverlay;
      if (this.currentItem) {
        this.currentItem = null;
      }
    }, 300);
  }

  getFormById(externalId: string, itemIndex: number): FormGroup {
    return this.form.controls.find((form: FormGroup) => form.controls['externalId'].value === externalId && form.controls['itemIndex'].value === itemIndex) as FormGroup;
  }

  get shippedDate(): AbstractControl {
    return this.globalForm.get('shippedDate');
  }

  get getCanConfirmShipping(): boolean {
    return (this.pickStrategy ? this.checkedTransactions.length <= 0 : !this.canConfirmShipping) || this.requestInFlight;
  }

}

interface RemainingQuantity {
  quantity: number;
  externalId: string;
  itemIndex: number;
}

interface ItemState {
  externalId: string;
  isValid: boolean;
  itemIndex: number;
}
