import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
})
export class OrderStatusComponent {
  @Input() status: AllStatuses;
  readonly allStatuses = AllStatuses;
}

enum AllStatuses {
  Draft = 'Draft',
  Open = 'Open',
  Closed = 'Closed',
  Voided = 'Voided',
  PartiallyShipped = 'PartiallyShipped',
  PartiallyReceived = 'PartiallyReceived',
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Published = 'Published',
  Archived = 'Archived',
  PartiallyFulfilled = 'PartiallyFulfilled',
  FullyFulfilled = 'FullyFulfilled',
  Waiting = 'Waiting',
  Pending = 'Pending',
  Overdue = 'Overdue',
  PartiallyPicked = 'PartiallyPicked',
  FullyPicked = 'FullyPicked',
  FullyReceived = 'FullyReceived',
  FullyShipped = 'FullyShipped',
  InProgress = 'InProgress',
  Completed = 'Completed',
}
