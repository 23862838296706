import {
  MasterItemContainerRequestModelBarrelConditionId,
  MasterItemContainerRequestModelBarrelDryMethodId,
  MasterItemContainerRequestModelBarrelWoodSpeciesId, MasterItemContainerRequestModelMasterItemContainerTypeId,
  MasterItemRequestModelIndustryId,
  MasterItemResponseModelInventoryStrategyId,
  MasterItemResponseModelMasterItemTypeId
} from '../../../core/services/InventoryRx';
import { FormBoxes, LookupProps } from '../../models';
import { charLevels } from '../../constants';

export const masterItemInvStrat: Array<string> = Object.values(MasterItemResponseModelInventoryStrategyId);
export const masterItemType: Array<string> = Object.values(MasterItemResponseModelMasterItemTypeId);
export const masterItemIndustry: Array<string> = Object.values(MasterItemRequestModelIndustryId);
export const barrelWoodSpecies: Array<string> = Object.values(MasterItemContainerRequestModelBarrelWoodSpeciesId);
export const barrelDryMethod: Array<string> = Object.values(MasterItemContainerRequestModelBarrelDryMethodId);
export const barrelCondition: Array<string> = Object.values(MasterItemContainerRequestModelBarrelConditionId);
export const containerTypes: Array<string> = Object.values(MasterItemContainerRequestModelMasterItemContainerTypeId);

export const  yesNoStatus = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];

export const defaultFormBoxes: FormBoxes = {
  itemNumber: {
    fieldType: 'input',
    label: 'SKU Number',
    name: 'itemNumber',
    type: 'text',
    placeholder: 'Item number',
    field: 'itemNumber'
  },
  barcode: {
    fieldType: 'input',
    label: 'Barcode/UPC',
    name: 'barcode',
    type: 'text',
    placeholder: 'Example barcode',
    field: 'barcode',
    mask: '9{12}'
  },
  strategy: {
    fieldType: 'select',
    label: 'Inventory Strategy',
    placeholder: 'Choose Strategy',
    field: 'inventoryStrategyId',
    select: {
      items: masterItemInvStrat
    }
  },
  uom: {
    fieldType: 'custom_select',
    label: 'Unit of Measurement',
    placeholder: 'Select UOM',
    field: 'unitOfMeasurementExternalId',
    select: {
      items: []
    }
  },
  description: {
    fieldType: 'textarea',
    label: 'Description',
    placeholder: 'Some text description',
    field: 'description'
  },
  sellable: {
    fieldType: 'selectValue',
    label: 'Is Item Sellable?',
    placeholder: 'Is Item Sellable?',
    field: 'isSellable',
    select: {
      items: yesNoStatus,
      bindValue: 'value'
    }
  },
  itemType: {
    fieldType: 'select',
    label: 'Type',
    placeholder: 'Select Item Type',
    field: 'masterItemTypeId',
    select: {
      items: masterItemType
    }
  },
  category: {
    fieldType: 'select-with-add',
    label: 'Category',
    placeholder: 'Select Item Category',
    field: 'categories',
    multiple: true,
    select: {
      items: [],
    }
  },
  critStock: {
    fieldType: 'input-with-mask',
    label: 'Critical Stock Level (e.g. Reorder Point)',
    placeholder: 'Critical Stock Level',
    isInteger: false,
    field: 'criticalStockLevel'
  },
  location: {
    fieldType: 'select',
    label: 'Location',
    placeholder: 'Select location',
    field: 'criticalStockLevel',
    select: { items: [] },
    customClass: 'form__box--search-icon'
  },
  locQuantity: {
    fieldType: 'input',
    label: 'Quantity',
    name: 'locQuantity',
    type: 'number',
    value: 100,
    placeholder: '0.00',
    field: 'locQuantity'
  },
  volume: {
    fieldType: 'input-with-mask',
    isInteger: false,
    label: 'Unit volume',
    name: 'volume',
    type: 'number',
    placeholder: '0.00',
    field: 'volume'
  }
};

export const defaultSpecificBoxes: FormBoxes = {
  kindOfSpirit: {
    fieldType: 'select_options',
    label: 'Kind of Spirit',
    placeholder: 'None',
    field: 'kindOfSpirit',
  },
  alcoholByVolume: {
    fieldType: 'input-with-mask',
    label: 'Alcohol Content',
    name: 'alcoholByVolume',
    isInteger: false,
    placeholder: '0.00',
    field: 'alcoholByVolume'
  },
  fermentableMaterialCategoryId: {
    fieldType: 'select_options',
    label: 'Fermentable Material Category',
    placeholder: 'None',
    field: 'fermentableMaterialCategoryId'
  },
  fermentableMaterialSubCategoryId: {
    fieldType: 'select-add',
    label: 'Fermentable Material Sub-Category',
    placeholder: 'None',
    field: 'fermentableMaterialSubCategoryId'
  }
};

export const defaultContainerBoxes: FormBoxes = {
  masterItemContainerTypeId: {
    fieldType: 'select',
    label: 'Container Type',
    placeholder: 'Select type',
    field: 'masterItemContainerTypeId',
    select: {
      items: containerTypes
    }
  },
  barrelConditionId: {
    fieldType: 'select',
    label: 'Condition',
    placeholder: 'Select condition',
    field: 'barrelConditionId',
    select: {
      items: barrelCondition
    }
  },
  capacity: {
    fieldType: 'input-with-mask',
    label: 'Capacity',
    name: 'capacity',
    isInteger: false,
    placeholder: '0.00',
    field: 'capacity',
    customClass: 'form__box--half'
  },
  containerUom: {
    fieldType: 'custom_select',
    label: 'Volume (Unit of Measurement)',
    placeholder: 'Select UOM',
    field: 'capacityUnitOfMeasurementExternalId',
    select: {
      items: []
    }
  },
  barrelCharLevel: {
    fieldType: 'select',
    label: 'Char level',
    placeholder: 'Select level',
    field: 'barrelCharLevel',
    select: {
      items: charLevels
    },
    customClass: 'form__box--half'
  },
  barrelWoodSpeciesId: {
    fieldType: 'select',
    label: 'Wood species',
    placeholder: 'Select Species',
    field: 'barrelWoodSpeciesId',
    select: {
      items: barrelWoodSpecies
    }
  },
  barrelWoodOrigin: {
    fieldType: 'input',
    label: 'Wood Origin',
    name: 'woodOrigin',
    type: 'text',
    placeholder: 'Enter description',
    field: 'barrelWoodOrigin'
  },
  barrelCooperage: {
    fieldType: 'input',
    label: 'Barrel Cooperage',
    name: 'cooperage',
    type: 'text',
    placeholder: 'Enter description',
    field: 'barrelCooperage'
  },
  barrelDryMethodId: {
    fieldType: 'select',
    label: 'Dry method',
    placeholder: 'Dry method',
    field: 'barrelDryMethodId',
    select: {
      items: barrelDryMethod
    }
  },
  barrelSeasonedLengthYear: {
    fieldType: 'select',
    name: 'barrelSeasonedLengthYear',
    placeholder: 'Years',
    field: 'barrelSeasonedLengthYear',
    customClass: 'form__box--half',
    select: {
      items: Array.from({ length: 99 }, (v, k) => k + 1)
    }
  },
  barrelSeasonedLengthMonth: {
    fieldType: 'select',
    name: 'barrelSeasonedLengthMonth',
    placeholder: 'Months',
    field: 'barrelSeasonedLengthMonth',
    customClass: 'form__box--half',
    select: {
      items: Array.from({ length: 11 }, (v, k) => k + 1)
    }
  }
};

export const defaultParamsForAll: LookupProps = {
  x_pageNumber: 1,
  x_pageSize: 9999,
  x_query: undefined,
  x_order: undefined,
  x_desc: undefined
};

export const defaultParams: LookupProps = {
  x_pageNumber: 1,
  x_pageSize: 10,
  x_query: undefined,
  x_order: undefined,
  x_desc: undefined
};
