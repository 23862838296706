import { Pipe, PipeTransform } from '@angular/core';
import {Money} from 'ts-money';

@Pipe({
  name: 'moneyFormat'
})
export class MoneyFormatPipe implements PipeTransform {
  transform(money: Money, args?: any): string {
    return `${money.toDecimal()}`;
  }
}
