import { OwlDateTimeFormats, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { AuthService } from '../services/auth.service';

export function OWL_DATE_TIME_FORMAT_SETTINGS(): OwlDateTimeFormats {
  return AuthService.getOwlDatePickerSettings;
}

export const OWL_DTPICKER_SETTINGS_PROVIDER = {
  provide: OWL_DATE_TIME_FORMATS,
  useFactory: OWL_DATE_TIME_FORMAT_SETTINGS
};
