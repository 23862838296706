import {Component, Input} from '@angular/core';
import {LocationsGroupByOptions} from '@onbatch/shared/enums/locations-group-by-options.enum';
import {BaseTable} from '@onbatch/shared/classes/base-table';
import {SalesItemLocationResponseModel} from '@onbatch/core/services/Sales';
import {TransferInBondItemLocationResponseModel} from '@onbatch/core/services/Warehousing';

@Component({
  selector: 'app-locations-table',
  templateUrl: './locations-table.component.html'
})
export class LocationsTableComponent extends BaseTable {

  @Input() groupByOption = LocationsGroupByOptions.ByLocation;
  @Input() locations: Locations[];
  @Input() openedItems: number[] = [];

  readonly locationsGroupByOptions = LocationsGroupByOptions;

  constructor() {
    super();
  }

  toggleItem(index: number): void {
    if (this.locations[index].items.length > 1) {
      this.openedItems.includes(index)
        ? this.openedItems.splice(this.openedItems.findIndex((el: number) => el === index), 1)
        : this.openedItems.push(index);
    }
  }
}

export interface Locations extends TransferInBondItemLocationResponseModel, SalesItemLocationResponseModel {
}
