import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounce} from 'lodash';

import {SalesOrdersService} from '../../../../orders/orders.service';
import {ItemTransactionType} from '../../../../../purchasing/orders/orders-interfaces';
import {FormBoxes, LookupProps} from '../../../../../shared/models';
import {
  PaginatedResponseOfSalesItemLocationResponseModel, SalesContainerMasterItemResponseModel,
  SalesItemLocationResponseModel,
  SalesOrderItemRequestModel,
  SalesOrderItemResponseModel,
  SalesOrderItemsRequestModel,
  SellableMasterItemResponseModelMasterItemTypeId,
} from '@onbatch/core/services/Sales';
import { defaultParamsForAll } from '@onbatch/shared/components/create-item/create-item-statics';
import {BaseTable} from '@onbatch/shared/classes/base-table';
import {pricePrefix} from '@onbatch/shared/constants';
import {ClickOutsideData} from '@onbatch/shared/directives';
import {LocationsGroupByOptions} from '@onbatch/shared/enums/locations-group-by-options.enum';
import {FormatsService} from '@onbatch/shared/services/formats.service';
import {
  PaginatedResponseOfWarehousingContainerLookUpResponseModel,
  WarehousingContainerLookUpResponseModel
} from '@onbatch/core/services/Warehousing';
import {take} from 'rxjs/operators';
import {TransfersService} from '../../../../../warehousing/transfers/transfers.service';
import { InvoiceResponseModelWithItems } from '../../../../invoices/invoices-interfaces';

@Component({
  selector: 'app-single-sales-item',
  templateUrl: './single-sales-item.component.html'
})
export class SingleSalesItemComponent extends BaseTable implements OnChanges, OnInit {

  @Input() masterItem: SalesOrderItemResponseModel;
  @Input() salesOrderExternalId: string;
  @Input() payload: SalesOrderItemsRequestModel;
  @Input() formSubmitted = false;
  @Input() readonly = false;
  @Input() hideInfo = false;
  @Input() salesApproval = false;
  @Input() pickStrategy = false;
  @Input() hideDetails = false;
  @Input() createdFromTransferInBond: boolean;
  @Input() showProofGallons = true;
  @Input() editing = false;
  @Input() index: number;
  @Input() invoices: InvoiceResponseModelWithItems[];
  @Input() excludedContainersMap = new Map<string, string[]>();

  @Output() formChanged = new EventEmitter<SalesOrderItemsRequestModel>();
  @Output() deleteClicked = new EventEmitter();
  @Output() shippingConfirmed = new EventEmitter();
  @Output() validationChanged = new EventEmitter<boolean>();
  @Output() addedContainer = new EventEmitter<SalesContainerMasterItemResponseModel>();
  @Output() removedContainer = new EventEmitter<SalesContainerMasterItemResponseModel>();

  showLocationsModal = false;
  isModalAddContainerVisible = false;

  form: FormGroup;

  formBoxes: FormBoxes = {
    quantity: {
      fieldType: 'input-with-mask',
      isInteger: false,
      name: 'quantity',
      type: 'text',
      placeholder: '0',
      field: 'quantity',
      disableControls: true
    },
    price: {
      fieldType: 'priceInput',
      name: 'price',
      type: 'text',
      placeholder: '$0.00',
      field: 'price',
      disableControls: true
    },
    discount: {
      fieldType: 'priceInput',
      name: 'discount',
      type: 'text',
      placeholder: '$0.00',
      field: 'discount',
      disableControls: true
    }
  };

  readonly pricePrefix = pricePrefix;
  readonly locationsGroupByOptions = LocationsGroupByOptions;
  readonly transactionType = ItemTransactionType;

  groupByOption = LocationsGroupByOptions.ByLocation;
  openedItems: number[] = [];
  locationsPages: number;
  locationsResults: SalesItemLocationResponseModel[] = [];
  locationsLoaded = false;
  paramsForLocationsLookup: LookupProps = defaultParamsForAll;
  excludedContainers: WarehousingContainerLookUpResponseModel[] = [];
  containersPages = 1;

  recalculate = debounce(() => {
    this.itemChanged();
  }, 800);
  recalculateManually = debounce(() => {
    this.itemChanged(false);
  }, 800);

  searchLocation = debounce(() => {
    if (this.paramsForLocationsLookup.x_query.length > 1 || this.paramsForLocationsLookup.x_query.length === 0) {
      this.locationsResults = [];
      this.fetchLocations();
    }
  }, 200);

  get qoh(): number {
    if (this.masterItem && this.masterItem.masterItem) {
      return +this.masterItem.masterItem.quantityOnHand;
    }
    return 0;
  }

  get isUnits(): boolean {
    return this.masterItem
      && this.masterItem.masterItem
      && this.masterItem.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood
      || this.masterItem.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.Container;
  }

  get isSFG(): boolean {
    return this.masterItem
      && this.masterItem.masterItem
      && this.masterItem.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood;
  }

  constructor(private fb: FormBuilder,
              private ordersService: SalesOrdersService,
              private formatsService: FormatsService,
              private transfersService: TransfersService
              ) {
    super();
  }

  ngOnInit() {
    if (Number.isInteger(this.index)) {
      this.setIdsAndFieldsInForm();
    }
    this.initForm();
    if (this.createdFromTransferInBond) {
      this.form.controls['quantity'].disable({ onlySelf: true });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.masterItem && this.form) {
      this.form.get(`quantity`).setValue(this.masterItem.quantity);
      this.form.get('price').setValue(this.masterItem.price);
      this.form.get('discount').setValue(this.masterItem.discount);
      this.form.get('isTaxable').setValue(this.masterItem.isTaxable);
      this.validationChanged.emit(this.form.valid);
    }
    if (changes.index) {
      this.setIdsAndFieldsInForm();
    }
  }

  setIdsAndFieldsInForm(): void {
    this.formBoxes.quantity.id = this.formBoxes.quantity.name + this.index;
    this.formBoxes.price.id = this.formBoxes.price.name + this.index;
    this.formBoxes.discount.id = this.formBoxes.discount.name + this.index;
  }

  initForm(): void {
    this.form = this.fb.group({
      quantity: new FormControl(this.masterItem ? this.masterItem.quantity : null, Validators.required),
      price: new FormControl(this.masterItem ? this.masterItem.price : null, Validators.required),
      discount: new FormControl(this.masterItem ? this.masterItem.discount : null, Validators.required),
      isTaxable: new FormControl(this.masterItem ? this.masterItem.isTaxable : false, Validators.required),
      searchQuery: new FormControl(null),
    });
  }

  itemChanged(byRules: boolean = true): void {
    const changedMasterItem = this.getMasterItemFromPayload();

    changedMasterItem.price = this.form.get('price').value;
    changedMasterItem.quantity = this.form.get('quantity').value;
    changedMasterItem.discount = this.form.get('discount').value;
    changedMasterItem.isTaxable = this.form.get('isTaxable').value;
    changedMasterItem.recalculatePrice = byRules;
    this.formChanged.emit(this.payload);
    this.validationChanged.emit(this.form.valid);
  }

  switchTaxes(): void {
    this.form.controls['isTaxable'].setValue(!this.form.controls['isTaxable'].value);
    this.itemChanged(false);
  }

  toggleLocationsModal(): void {
    this.showLocationsModal = !this.showLocationsModal;
    if (this.showLocationsModal && !this.locationsResults.length) {
      this.fetchLocations();
    }
  }

  closeLocationsModal(event: ClickOutsideData): void {
    if (event.value) {
      this.showLocationsModal = false;
    }
  }

  setLocationsGroupBy(groupByOption: LocationsGroupByOptions): void {
    if (groupByOption !== this.groupByOption) {
      this.groupByOption = groupByOption;
      this.clearLocations();
      this.fetchLocations();
    }
  }

  clearLocations(): void {
    this.paramsForLocationsLookup.x_pageNumber = 1;
    this.locationsResults = [];
    this.openedItems = [];
    this.paramsForLocationsLookup.x_order = this.groupByOption === LocationsGroupByOptions.ByLocation ? 'locationAcronymName' : 'lotId';
    this.paramsForLocationsLookup.x_desc = false;
    this.paramsForLocationsLookup.x_query = null;
  }

  fetchLocations(): void {
    this.locationsLoaded = false;
    const onSuccess = (res: PaginatedResponseOfSalesItemLocationResponseModel) => {
      this.locationsPages = res.pageInfo.totalPages;
      this.locationsResults = [...this.locationsResults, ...res.list];
      this.locationsLoaded = true;
    };
    if (!this.masterItem.isRemnant) {
      this.ordersService.getLocationsForMasterItem(
        this.masterItem.masterItem.externalId,
        this.groupByOption === LocationsGroupByOptions.ByLot,
        this.paramsForLocationsLookup.x_query,
        this.paramsForLocationsLookup.x_order,
        this.paramsForLocationsLookup.x_desc,
        this.paramsForLocationsLookup.x_pageNumber,
        this.paramsForLocationsLookup.x_pageSize,
      ).subscribe(onSuccess);
    } else {
      this.ordersService.getLocationsForRemnantCases(
        this.masterItem.masterItem.externalId,
        this.masterItem.numberOfUnits,
        this.groupByOption === LocationsGroupByOptions.ByLot,
        this.paramsForLocationsLookup.x_query,
        this.paramsForLocationsLookup.x_order,
        this.paramsForLocationsLookup.x_desc,
        this.paramsForLocationsLookup.x_pageNumber,
        this.paramsForLocationsLookup.x_pageSize,
      ).subscribe(onSuccess);
    }
  }

  sortTableByColumn(event: Event | any, columnName: string): void {
    super.sortTableByColumn(event, columnName);
    if (columnName === this.paramsForLocationsLookup.x_order) {
      this.paramsForLocationsLookup.x_desc = !this.paramsForLocationsLookup.x_desc;
    } else {
      this.paramsForLocationsLookup.x_desc = false;
      this.paramsForLocationsLookup.x_order = columnName;
    }
    this.clearLocations();
    this.fetchLocations();
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      if (this.paramsForLocationsLookup.x_pageNumber + 1 <= this.locationsPages) {
        this.paramsForLocationsLookup.x_pageNumber++;
        this.fetchLocations();
      }
    }
  }

  onDeleteClicked(): void {
    this.deleteClicked.emit();
  }

  onShippingConfirmed(): void {
    this.shippingConfirmed.emit();
  }

  onAddContainer() {
    this.toggleContainersModal();
    this.fetchContainers();
  }

  onItemUpdatedWithContainer(container: SalesContainerMasterItemResponseModel) {
    this.resetItemQuantity();
    this.updateMasterItemWithContainer(container);
    this.toggleContainersModal();
    this.addedContainer.emit(container);
  }

  onDeleteContainer() {
    this.removedContainer.emit(this.masterItem.container);
    this.updateMasterItemWithContainer(null);
  }

  private getMasterItemFromPayload(): SalesOrderItemRequestModel {
    return this.payload.salesOrderItems.find(item => {
      return item.masterItemExternalId === this.masterItem.masterItem.externalId
        && item.itemIndex === this.masterItem.itemIndex
        && item.isRemnant === this.masterItem.masterItem.isRemnant
        && (!item.numberOfUnits || item.numberOfUnits === this.masterItem.masterItem.numberOfUnits);
    });
  }

  private toggleContainersModal() {
    this.isModalAddContainerVisible = !this.isModalAddContainerVisible;
  }

  private fetchContainers() {
    this.transfersService
      .lookUpContainers(
        [],
        this.paramsForLocationsLookup.x_query,
        this.paramsForLocationsLookup.x_order,
        this.paramsForLocationsLookup.x_desc,
        this.paramsForLocationsLookup.x_pageNumber,
        this.paramsForLocationsLookup.x_pageSize
      )
      .pipe(take(1))
      .subscribe((res: PaginatedResponseOfWarehousingContainerLookUpResponseModel) => {
        const excludedContainers = this.excludedContainersMap.get(this.masterItem.masterItem.externalId) || [];
        const filteredListByExcludedContainers = excludedContainers ? res.list.filter(item => !excludedContainers.includes(item.externalId)) : res.list;
        this.containersPages = res.pageInfo.totalPages;
        this.excludedContainers = filteredListByExcludedContainers;
      });
  }

  private updateMasterItemWithContainer(container: SalesContainerMasterItemResponseModel) {
    const changedMasterItem = this.getMasterItemFromPayload();
    changedMasterItem.containerExternalId = container ? container.externalId : null;
    this.formChanged.emit(this.payload);
  }

  private resetItemQuantity() {
    const changedMasterItem = this.getMasterItemFromPayload();
    changedMasterItem.quantity = 0;
    this.masterItem.quantity = 0;
    this.formChanged.emit(this.payload);
  }
}

