import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackPageComponent } from './user/callback-page/callback-page.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { AdminGuardService } from './core/guards/admin-guard.service';
import { WelcomePageComponent } from './user/welcome-page/welcome-page.component';
import { ActiveSubscriptionGuardsService } from '@onbatch/core/guards/active-subscription-guards.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AdminGuardService]
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'manufacturing',
        loadChildren: () => import('./manufacturing/manufacturing.module').then(m => m.ManufacturingModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'purchasing',
        loadChildren: () => import('./purchasing/purchasing.module').then(m => m.PurchasingModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'warehousing',
        loadChildren: () => import('./warehousing/warehousing.module').then(m => m.WarehousingModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'sales',
        loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'callback',
        component: CallbackPageComponent
      },
      {
        path: 'reporting',
        loadChildren: () => import('./adhoc-reporting/reporting.module').then((m) => m.ReportingModule),
        canActivate: [AuthGuardService, ActiveSubscriptionGuardsService]
      },
      {
        path: 'welcome',
        component: WelcomePageComponent,
        canActivate: [AuthGuardService]
      },
      { path: '', redirectTo: 'welcome', pathMatch: 'full' }

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
