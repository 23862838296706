import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../core/services/Env';
import { InventoryClient } from '../../core/services/InventoryRx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/services/Env";
export class InventoryLookupsService {
    constructor(http, envService) {
        this.http = http;
        this.envService = envService;
        this.inventoryClient = new InventoryClient(http, envService.apiUrl);
    }
    lookupTemplates(x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpMasteritemsTemplatesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupLocations(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpFacilitiesGet(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupUnitsOfMeasurement(masterItemType, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpUnitofmeasurementGet(masterItemType, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupUnitsOfMeasurementForContainer(x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpContainerUnitofmeasurementGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupFermentable(categoryExternalId, x_pageNumber, x_pageSize, x_query, x_order, x_desc) {
        return this.inventoryClient.lookUpFermentablematerialGet(categoryExternalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupMasterItems(withoutPricing, withoutRawMaterials, withoutFinishedGoods, withoutSemiFinishedGoods, withoutAlcohol, x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpMasteritemsGet(withoutPricing, withoutRawMaterials, withoutFinishedGoods, withoutSemiFinishedGoods, withoutAlcohol, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    lookupKindOfSpirits(filterBySettings, x_query, x_order, x_desc, x_pageNumber, x_pageSize) {
        return this.inventoryClient.lookUpKindofspiritGet(filterBySettings, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
    }
    flattenKindOfSpiritResponse(list) {
        const kindOfSpirits = [];
        list.forEach(spiritClass => {
            if (spiritClass.list) {
                if (spiritClass.list.length === 0) {
                    kindOfSpirits.push({
                        name: spiritClass.name,
                        externalId: mergeKindOfSpiritIds(spiritClass.externalId, spiritClass.customKindOfSpiritExternalId),
                        originalExternalId: spiritClass.externalId,
                        customExternalId: spiritClass.customKindOfSpiritExternalId,
                    });
                }
                spiritClass.list.forEach(spiritType => {
                    kindOfSpirits.push({
                        name: spiritType.name,
                        externalId: mergeKindOfSpiritIds(spiritType.externalId, spiritType.customKindOfSpiritExternalId),
                        originalExternalId: spiritType.externalId,
                        customExternalId: spiritType.customKindOfSpiritExternalId,
                    });
                });
            }
            else {
                kindOfSpirits.push({
                    name: spiritClass.name,
                    externalId: mergeKindOfSpiritIds(spiritClass.externalId, spiritClass.customKindOfSpiritExternalId),
                    originalExternalId: spiritClass.externalId,
                    customExternalId: spiritClass.customKindOfSpiritExternalId,
                    isCustom: !!spiritClass.customKindOfSpiritExternalId,
                });
            }
        });
        return kindOfSpirits;
    }
}
InventoryLookupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InventoryLookupsService_Factory() { return new InventoryLookupsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvService)); }, token: InventoryLookupsService, providedIn: "root" });
export function mergeKindOfSpiritIds(externalId, customExternalId) {
    return `${externalId}:${customExternalId}`;
}
