/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../svg-icon/svg-icon.component.ngfactory";
import * as i2 from "../svg-icon/svg-icon.component";
import * as i3 from "@angular/common";
import * as i4 from "./button.component";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "figure", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i1.View_SvgIconComponent_0, i1.RenderType_SvgIconComponent)), i0.ɵdid(2, 114688, null, 0, i2.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "figure", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i1.View_SvgIconComponent_0, i1.RenderType_SvgIconComponent)), i0.ɵdid(2, 114688, null, 0, i2.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [], [[8, "disabled", 0], [8, "className", 0], [8, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.icon && !_co.iconRight); _ck(_v, 2, 0, currVal_3); var currVal_6 = (_co.icon && _co.iconRight); _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = i0.ɵinlineInterpolate(4, "", _co.class, " ", (_co.isActive ? "active" : ""), " ", (_co.drop ? "btn--drop" : ""), " ", (_co.disabled ? "btn--disabled" : ""), ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.type, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.labelClass, ""); _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.label; _ck(_v, 4, 0, currVal_5); }); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], [[4, "pointerEvents", null], [4, "width", null]], null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.ButtonComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).PointerEventValue; var currVal_1 = i0.ɵnov(_v, 1).width; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ButtonComponentNgFactory = i0.ɵccf("app-button", i4.ButtonComponent, View_ButtonComponent_Host_0, { label: "label", labelClass: "labelClass", class: "class", clicked: "clicked", icon: "icon", type: "type", isActive: "isActive", disabled: "disabled", drop: "drop", iconRight: "iconRight", expand: "expand" }, { clickInner: "clickInner" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
