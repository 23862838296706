import {
  MasterItemFullResponseModel,
  MasterItemFullResponseModelMasterItemTypeId,
} from '@onbatch/core/services/Inventory';
import { UnitOfMeasurementFormatPipe } from '@onbatch/shared/pipes';
import { MasterItemPricing, MasterItemPricingMasterItemTypeId } from '@onbatch/core/services/InventoryRx';

export function getUnitOfMeasurement(item: MasterItemFullResponseModel | MasterItemPricing): string {
  // Returns UoM as `10/750 ml` for Finished Good items and full UoM name for others

  const masterItem = item as MasterItemFullResponseModel;
  const masterItemFromPricing = item as MasterItemPricing;

  const justUom = (): string => {
    if (masterItem.unitOfMeasurement) {
      return masterItem.unitOfMeasurement.name;
    } else if (masterItemFromPricing.unitOfMeasurementName) {
      return masterItemFromPricing.unitOfMeasurementName;
    } else {
      return '-';
    }
  };

  const uomWithVolume = (numberOfUnits: number, volume: number, uomName: string): string => {
    return `${numberOfUnits}/${volume} ${uomName ? new UnitOfMeasurementFormatPipe().transform(uomName) : `-`}`;
  };

  if (item.masterItemTypeId !== MasterItemFullResponseModelMasterItemTypeId.FinishedGood
    && item.masterItemTypeId !== MasterItemPricingMasterItemTypeId.FinishedGood) {
    return justUom();
  } else {
    if (masterItem.package && masterItem.package.numberOfUnits && masterItem.volume) {
      return uomWithVolume(masterItem.package.numberOfUnits, masterItem.volume, masterItem.unitOfMeasurement ? masterItem.unitOfMeasurement.name : null);
    } else if (masterItemFromPricing.numberOfUnits && masterItemFromPricing.volume) {
      return uomWithVolume(masterItemFromPricing.numberOfUnits, masterItemFromPricing.volume, masterItemFromPricing.unitOfMeasurementName);
    } else {
      return justUom();
    }
  }
}
