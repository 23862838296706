import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

import { ClickOutsideData, SidebarService } from '../shared';
import { SidebarConfigItem } from './sidebar.model';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarOpen = false;
  render = true;

  sidebarItems: Array<SidebarConfigItem>;
  currentItem: null | string = null;
  refreshing = false;
  subscriptions$: Subscription = new Subscription();

  constructor(private localStorage: LocalStorage,
              private sidebarService: SidebarService,
              public subscriptionService: SubscriptionService) {
  }

  ngOnInit() {
    this.subscriptions$.add(this.localStorage.getItem('companiesCountry').subscribe(() => this.sidebarService.updateSidebarConfig()));
    this.subscriptions$.add(this.sidebarService.getActiveSidebarItem().subscribe((item: string) => this.currentItem = item));
    this.subscriptions$.add(this.sidebarService.getSidebarConfig().subscribe((items: SidebarConfigItem[]) => {
      const newItems: SidebarConfigItem[] = [...items];
      this.sidebarItems = newItems.filter((item: SidebarConfigItem) => {
        if (!item.isHidden) {
          item.sublinks = item.sublinks.filter(subItem => !subItem.isHidden);
          return item;
        }
      });
    }));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  onClickOutside(event: ClickOutsideData) {
    if (event && event['value'] === true) {
      this.sidebarOpen = false;
      setTimeout(() => this.sidebarService.setSidebarActiveSub(null), 10);
    }
  }

  openSidebar(title: string) {
    this.sidebarOpen = true;
    this.sidebarService.setSidebarActiveSub(title);
  }

  closeSidebar() {
    this.sidebarOpen = false;
    this.sidebarService.setSidebarActiveSub(null);
  }
}
