<img *ngIf="user?.profilePicture" [src]="user?.profilePicture">
<img *ngIf="user?.userProfilePicture" [src]="user?.userProfilePicture">
<div *ngIf="!user?.profilePicture && !user?.userProfilePicture"
    [ngClass]="placeholderClass">
  <ng-container *ngIf="user?.firstName && user?.lastName">
    {{user?.firstName.charAt(0) | titlecase}}{{user?.lastName.charAt(0) | titlecase}}
  </ng-container>
  <ng-container *ngIf="user?.name && !user?.userFullName">
    {{user?.name | nameInitials}}
  </ng-container>
  <ng-container *ngIf="user?.userFullName">
    {{user?.userFullName | nameInitials}}
  </ng-container>
  <ng-container *ngIf="!user?.firstName && !user?.name && !user?.userFullName && user?.email">
    {{user?.email.charAt(0) | titlecase}}
  </ng-container>
  <ng-container *ngIf="user?.finalizedBy">
    {{user?.finalizedBy | nameInitials}}
  </ng-container>
</div>
