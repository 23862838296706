export enum GanttChartTypes {
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day'
}

export enum ManualBatchContainers {
    Barrels = 'Barrels',
    Other = 'Other container'
}

export enum PackagingContainers {
    Drums = 'Drums',
    Barrels = 'Barrels',
    Plastics = 'Plastics',
    Misc = 'Misc'
}

export enum StagesNames {
    SemiFinishedGood = 'SemiFinishedGood'
}

export enum PackagingProcessesTypes {
    FinishedGoodPackaging,
    SemiFinishedGoodPackaging,
}
