import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { EnvService } from '../../core/services/Env';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  FormattingRequestModel,
  FormattingResponseModel,
  InventoryRequestModel,
  InventoryResponseModel,
  ManufacturingRequestModel,
  ManufacturingResponseModel,
  PurchasingResponseModel,
  WarehousingRequestModel,
  WarehousingResponseModel,
  PurchasingRequestModel,
  EmailRequestModel,
  SalesResponseModel,
  SalesRequestModel,
  AccountingRequestModel,
  AccountingResponseModel,
  TaxesResponseModel, TaxesRequestModel, AccountClient
} from '../../core/services/Account';
import {tap} from 'rxjs/operators';
import * as timezone from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  manufacturingSettings: BehaviorSubject<ManufacturingResponseModel> = new BehaviorSubject(null);
  salesSettings: BehaviorSubject<SalesResponseModel> = new BehaviorSubject(null);
  formattingSettings = new BehaviorSubject<FormattingResponseModel>(null);

  private settingsClient: AccountClient;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.settingsClient = new AccountClient(this.http, envService.apiUrl);
  }

  // Formatting

  getSettingsForFormatting(): Observable<FormattingResponseModel> {
    return this.settingsClient.settingsFormattingGet()
      .pipe(
        tap((data: FormattingResponseModel) => {
          this.formattingSettings.next(data);
          timezone.tz.setDefault(data.timezone);
        })
      );
  }

  setSettingsForFormatting(model?: FormattingRequestModel | null | undefined): Observable<FormattingResponseModel> {
    return this.settingsClient.settingsFormattingPut(model);
  }

  // Inventory module

  getSettingsForInventory(): Observable<InventoryResponseModel> {
    return this.settingsClient.settingsInventoryGet();
  }

  setSettingsForInventory(model?: InventoryRequestModel | null | undefined): Observable<InventoryResponseModel> {
    return this.settingsClient.settingsInventoryPut(model);
  }

  // Manufacturing module

  getSettingsForManufacturing() {
    this.settingsClient.settingsManufacturingGet().subscribe(data => {
      this.manufacturingSettings.next(data);
    });
  }

  setSettingsForManufacturing(model?: ManufacturingRequestModel | null | undefined): Observable<ManufacturingResponseModel> {
    return this.settingsClient.settingsManufacturingPut(model)
      .pipe(
        tap(response => this.manufacturingSettings.next(response))
      );
  }

  getManufacturingSettings(): Observable<ManufacturingResponseModel> {
    if (!this.manufacturingSettings.value) {
      this.manufacturingSettings.next(null);
      this.getSettingsForManufacturing();
    }
    return this.manufacturingSettings.asObservable();
  }

  // Warehouse module

  getSettingsForWarehouse(): Observable<WarehousingResponseModel> {
    return this.settingsClient.settingsWarehousingGet();
  }

  setSettingsForWarehouse(model?: WarehousingRequestModel | null | undefined): Observable<WarehousingResponseModel> {
    return this.settingsClient.settingsWarehousingPut(model);
  }

  // Purchasing module

  getSettingsForPurchasing(): Observable<PurchasingResponseModel> {
    return this.settingsClient.settingsPurchasingGet();
  }

  setSettingsForPurchasing(model?: PurchasingRequestModel | null | undefined): Observable<PurchasingResponseModel> {
    return this.settingsClient.settingsPurchasingPut(model);
  }

  sendTestVendorEmail(message?: EmailRequestModel | null | undefined): Observable<WarehousingResponseModel> {
    return this.settingsClient.settingsSendPurchasingEmailTemplatePut(message);
  }

  // Sales module

  sendTestPurchasingEmail(message?: EmailRequestModel | null | undefined): Observable<WarehousingResponseModel> {
    return this.settingsClient.settingsSendSalesEmailTemplatePut(message);
  }

  getSettingsForSales() {
    this.settingsClient.settingsSalesGet().subscribe(data => {
      this.salesSettings.next(data);
    });
  }

  setSettingsForSales(model?: SalesRequestModel | null | undefined): Observable<SalesResponseModel> {
    return this.settingsClient.settingsSalesPut(model)
      .pipe(
        tap(response => this.salesSettings.next(response))
      );
  }

  getSalesSettings(): Observable<SalesResponseModel> {
    if (!this.salesSettings.value) {
      this.getSettingsForSales();
    }
    return this.salesSettings;
  }

  // Accounting

  getSettingsForAccounting(): Observable<AccountingResponseModel> {
    return this.settingsClient.settingsAccountingGet();
  }

  setSettingsForAccounting(model?: AccountingRequestModel | null | undefined): Observable<AccountingResponseModel> {
    return this.settingsClient.settingsAccountingPut(model);
  }

  // Taxes

  getSettingsForTaxes(): Observable<TaxesResponseModel> {
    return this.settingsClient.settingsTaxesGet();
  }

  setSettingsForTaxes(model?: TaxesRequestModel | null | undefined): Observable<TaxesResponseModel> {
    return this.settingsClient.settingsTaxesPut(model);
  }
}
