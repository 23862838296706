import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EquipmentService } from '../../equipment/equipment.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, share } from 'rxjs/operators';
import { FileResponse } from '../../../core/services/Admin';
import { Dictionary, replaceAll } from '../../../shared/utils';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { EquipmentModelTypeId } from '@onbatch/core/services/Manufacturing';

export interface ImageCache {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class VesselLibraryService {
  static readonly availableCategories: string[] = [
    EquipmentModelTypeId.MashTun,
    EquipmentModelTypeId.Fermenter,
    EquipmentModelTypeId.Still,
    EquipmentModelTypeId.SpiritsTank,
    EquipmentModelTypeId.Misc,
  ];

  imageCache: ImageCache = {};

  private requestsInFlight: Dictionary<Observable<string | null>> = {};

  private activeTabKey = 'select-vessel-active-tab';

  static calcVesselFill(volume: number, capacity: number): number {
    if (!capacity) {
      return -1;
    }
    if (volume === capacity) {
      return 0;
    }
    return 100 - (volume / capacity * 100);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private equipmentService: EquipmentService,
    private localStorage: LocalStorage,
  ) {
  }

  getImageUrl(imageId: string, fillingPercent: number = null): Observable<SafeResourceUrl | null> {
    if (this.imageCache[imageId]) {
      const svg = this.imageCache[imageId];
      return of(this.prepareSvg(svg, fillingPercent));
    }

    return this.generateVesselLibraryImage(imageId).pipe(
      map((svg: string | null) => {
        if (!this.imageCache[imageId]) {
          this.imageCache[imageId] = svg;
        }
        return svg ? this.prepareSvg(svg, fillingPercent) : null;
      })
    );
  }

  saveActiveTab(tab: EquipmentModelTypeId) {
    this.localStorage.setItemSubscribe(this.activeTabKey, tab);
  }

  getActiveTab(): Observable<EquipmentModelTypeId | null> {
    return this.localStorage.getItem(this.activeTabKey)
      .pipe(
        map((activeTab: string) => {
          if (VesselLibraryService.availableCategories.includes(activeTab)) {
            return activeTab as EquipmentModelTypeId;
          }
          return null;
        })
      );
  }

  private generateVesselLibraryImage(imageId: string): Observable<string | null> {
    if (!imageId) {
      return of(null);
    }

    const fileReader$ = (blob: Blob): Observable<string> => Observable.create(obs => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });

    if (!(imageId in this.requestsInFlight)) {
      this.requestsInFlight[imageId] = this.equipmentService.getEquipmentImage(imageId).pipe(
        switchMap((file: FileResponse) => fileReader$(file.data)),
        tap(_ => delete this.requestsInFlight[imageId]),
        share(),
      );
    }
    return this.requestsInFlight[imageId];
  }

  private prepareSvg(svg: string, fillingPercentage: number | undefined): SafeResourceUrl {
    if (fillingPercentage !== undefined && fillingPercentage !== -1) {
      svg = replaceAll(svg, '0 50%, 100% 50%, 100% 100%, 0% 100%', `0 ${fillingPercentage}%, 100% ${fillingPercentage}%, 100% 100%, 0% 100%`);
    }
    if (fillingPercentage === -1) {
      svg = replaceAll(svg, '0 50%, 100% 50%, 100% 100%, 0% 100%', `0 100%, 100% 100%, 100% 100%, 0% 100%`);
    }

    const base64Data = btoa(svg);
    const url = `data:image/svg+xml;base64,${base64Data}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
