<button [disabled]="disabled"
        class="{{class}} {{isActive ? 'active' : ''}} {{drop ? 'btn--drop' : ''}} {{disabled ? 'btn--disabled' : ''}}"
        type="{{type}}"
        (click)="onClick($event)">
  <figure *ngIf="icon && !iconRight">
    <svg-icon icon="{{icon}}"></svg-icon>
  </figure>
  <span class="{{labelClass}}">{{label}}</span>
  <figure *ngIf="icon && iconRight">
    <svg-icon icon="{{icon}}"></svg-icon>
  </figure>
</button>
