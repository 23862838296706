import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ModalConfirmationState {
  confirmed = 'Confirmed',
  canceled = 'Canceled',
  noState = 'No state'
}

export interface ConfirmModalConfiguration {
  text: string;
  title: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
}

@Injectable()
export class ConfirmationModalService {
  initConfiguration: ConfirmModalConfiguration = {
    text: 'You already made some changes in form are you sure you want to close modal?',
    title: 'Confirmation',
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm'
  };
  private configuration$: BehaviorSubject<ConfirmModalConfiguration> = new BehaviorSubject<ConfirmModalConfiguration>({...this.initConfiguration});
  private modalState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private state$: BehaviorSubject<ModalConfirmationState> =  new BehaviorSubject<ModalConfirmationState>(ModalConfirmationState.noState);

  constructor() {}

  getOptions(): Observable<ConfirmModalConfiguration> {
    return this.configuration$;
  }

  getModalState(): Observable<boolean> {
    return this.modalState$;
  }

  getCurrentModalState(): boolean {
    return this.modalState$.value;
  }

  getState(): Observable<ModalConfirmationState> {
    return this.state$;
  }

  showConfirmationModal(configuration?: ConfirmModalConfiguration): void {
    if (configuration) {
      this.configuration$.next({...configuration});
    }
    this.modalState$.next(true);
  }

  setState(state: ModalConfirmationState): void {
    this.state$.next(state);
    this.reset();
  }

  setOpen(): void {
    this.modalState$.next(true);
  }

  reset(): void {
    this.modalState$.next(false);
    this.state$.next(ModalConfirmationState.noState);
  }
}
