import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  SalesOrderShippedItemRequestModelShipmentItemSelectionCriteriaId as ShippedCriteriaId,
  SellableMasterItemResponseModelBarrelConditionId,
  ShipmentContainerIdRequestModel,
  ShippedItemRequestModel
} from '../../../../../core/services/Sales';
import { BaseShipForm } from '../base-ship-form';
import { ShippingService } from '../../../../services/shipping.service';
import { debounce } from 'lodash';
import { SettingsService } from 'app/account/settings/settings.service';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainersComponent extends BaseShipForm implements OnInit, OnDestroy {

  readonly barrelConditionId = SellableMasterItemResponseModelBarrelConditionId;

  validateLotId = debounce((idx: number) => {
    this.lotIdChanged(idx);
  }, 200);

  validateContainerId = debounce((idx: number) => {
    this.containerIdChanged(idx);
  }, 200);

  constructor(private fb: FormBuilder,
              public shippingService: ShippingService,
              public settingsService: SettingsService
  ) {
    super(shippingService, settingsService);
  }

  addEquipment(containerId: string = '') {
    this.containerIds.push(this.fb.group({
      shippedContainerId: new FormControl(containerId, Validators.required),
    }));
    if (!!containerId) {
      this.validShipping.push(new ShippedItemRequestModel({
        containerId,
      }));
      this.emitCurrentShipping(ShippedCriteriaId.ByContainerId);
    } else {
      this.validShipping.push(null);
    }
  }

  addLotId(lotId: string = '', quantity: number = 0, maxQuantity: number = 0) {
    this.lotIds.push(this.fb.group({
      lotId: new FormControl(lotId, Validators.required),
      shippedLotQuantity: new FormControl(quantity, Validators.required)
    }));
    this.shippedQuantity.push({ quantity: maxQuantity, isValid: !!quantity });
    if (!!quantity) {
      this.validShipping.push(new ShippedItemRequestModel({
        lotId,
        quantity
      }));
      this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
    } else {
      this.validShipping.push(null);
    }
  }

  initEditForm() {
    this.shippedQuantity = [];
    while (this.lotIds.length > 0) {
      this.lotIds.removeAt(0);
    }
    while (this.containerIds.length > 0) {
      this.containerIds.removeAt(0);
    }
    if (this.shipmentType.value === this.ShipmentType.ByLotId) {
      this.shippingDetails.details.forEach(shippingDetails => {
        this.addLotId(shippingDetails.lotId, shippingDetails.quantity, shippingDetails.maxLotIdQuantity);
      });
    } else if (this.shipmentType.value === this.ShipmentType.ByContainerId) {
      this.shippingDetails.details.forEach(shippingDetails => {
        this.addEquipment(shippingDetails.containerId);
      });
    }
  }

  initFormBoxes() {
    this.formBoxes = {
      lotId: {
        fieldType: 'input',
        name: 'lotId',
        field: 'lotId',
        placeholder: 'Lot ID'
      },
      shippedLotQuantity: {
        fieldType: 'input-with-mask',
        name: 'shippedLotQuantity',
        field: 'shippedLotQuantity',
        placeholder: 'Quantity',
        isInteger: true,
      },
      shippedContainerId: {
        fieldType: 'input',
        name: 'shippedContainerId',
        field: 'shippedContainerId',
        placeholder: 'Container ID'
      },
    };
  }

  removeFormArrayItem(idx: number, event: Event, formArrayName?: string) {
    super.removeFormArrayItem(idx, event);
    this[formArrayName].removeAt(idx);
    this.validShipping.splice(idx, 1);

    if (this.shippedQuantity.length) {
      this.shippedQuantity.splice(idx, 1);
    }

    for (let index = 0; index < this[formArrayName].length; index++) {
      if (formArrayName === 'lotIds') {
        this.lotIdChanged(index);
      } else {
        this.containerIdChanged(index);
      }
    }
  }

  containerIdChanged(idx: number): void {
    const form = this.containerIds.controls[idx];
    const containerId = form.get('shippedContainerId');

    if (containerId.value) {
      this.shippingService.validateContainerId(new ShipmentContainerIdRequestModel({
        containerId: containerId.value,
        masterItemExternalId: this.item.masterItem.externalId
      })).subscribe((isValid: boolean) => {
        this.formSubmitStatus = true;
        containerId.setErrors(isValid ? null : { 'incorrect': true });
        if (isValid) {
          this.validShipping[idx] = new ShippedItemRequestModel({
            containerId: containerId.value,
            quantity: 1,
          });
        } else {
          this.validShipping[idx] = null;
        }
        this.emitCurrentShipping(ShippedCriteriaId.ByContainerId);
      });
    } else {
      this.validShipping[idx] = null;
      this.emitCurrentShipping(ShippedCriteriaId.ByContainerId);
    }
  }

  quantityChanged(idx: number): void {
    this.validShipping[idx] = null;

    const form = this.lotIds.controls[idx];
    const quantity = form.get('shippedLotQuantity');
    const lotId = form.get('lotId');

    quantity.setErrors(
      quantity.value
      && quantity.value <= this.getRemainingQuantity
      && quantity.value <= this.shippedQuantity[idx].quantity
        ? null
        : { 'incorrect': true }
    );
    this.formSubmitStatus = true;

    if (this.shippedQuantity[idx].isValid
      && quantity.value && quantity.value <= this.getRemainingQuantity
      && quantity.value <= this.shippedQuantity[idx].quantity) {
      this.validShipping[idx] = new ShippedItemRequestModel({
        quantity: quantity.value,
        lotId: lotId.value,
      });
    } else {
      this.validShipping[idx] = null;
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByLotId);
  }
}
