<div class="loading-wrapper"
     (click)="onClick($event)"
     [class.loading-wrapper--dim-bg]="!vertical">
  <div *ngIf="vertical; else globalSpinner"
       class="spinner-vertical">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>

  <ng-template #globalSpinner>
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </ng-template>
</div>
