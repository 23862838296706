import { Pipe, PipeTransform } from '@angular/core';
import { UnitOfMeasurementFormatPipe } from '@onbatch/shared/pipes/unit-of-measurement-format.pipe';
import { AmountFormatPipe } from '@onbatch/shared/pipes/amount-format.pipe';
import { MeasurementResponse } from '@onbatch/core/services/Manufacturing';
import { IMeasurementUnit } from '@onbatch/shared/interfaces/unit.interfaces';

@Pipe({
  name: 'measurementFormat'
})
export class MeasurementFormatPipe implements PipeTransform {
  constructor(private uomPipe: UnitOfMeasurementFormatPipe,
              private amountPipe: AmountFormatPipe) {
  }

  transform(value: IMeasurementUnit | MeasurementResponse, args?: any): any {
    if (!value) {
      return '';
    }

    const amount: string = this.amountPipe.transform(value.value);

    if (value.unitOfMeasurementName) {
      const uom: string = this.uomPipe.transform(value.unitOfMeasurementName);
      return `${amount} ${uom}`;
    }
    return `${amount}`;
  }

}
