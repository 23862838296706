import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { closeCalendar } from '../../utils';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import { dateFormats } from 'app/account/settings/settings-statics.enum';
import { SettingsService } from 'app/account/settings/settings.service';
import { Subscription } from 'rxjs';
import * as timezone from 'moment-timezone';

@Component({
  selector: 'app-date-range-input',
  templateUrl: './date-range-input.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class DateRangeInputComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() placeholder: string;
  @Input() wrapperClasses: string;

  @Output() inputChanged = new EventEmitter<timezone.Moment | null>();

  dateFormat: string;
  closeDatepicker = closeCalendar;
  calendarInputClass: string;
  settingsSubscription: Subscription;

  @HostBinding('style.width') private width = '100%';

  constructor(
    private settingsService: SettingsService,
  ) {
  }

  ngOnInit() {
    this.settingsSubscription = this.settingsService.getSettingsForFormatting()
      .subscribe(data => {
        this.dateFormat = data.dateFormat;
      }
    );
  }

  ngOnDestroy() {
    this.settingsSubscription.unsubscribe();
  }

  selectDateRange(event): void {
    const localTimeZone = timezone.tz.guess();
    this.setDateInputClass();
    if (event.value && event.value.length && event.value.length > 1) {
      const date1 = timezone(event.value[0]).tz(localTimeZone).startOf('d');
      const date2 = timezone(timezone(event.value[1]).tz(localTimeZone).endOf('d').format()).endOf('d').format();
      event.value[0] = timezone(date1.format('YYYY-MM-DD')).unix();
      event.value[1] = timezone(date2).unix();
    }
    this.inputChanged.emit(event);
  }

  setDateInputClass(): void {
    switch (this.dateFormat) {
      case dateFormats.format1:
      case dateFormats.format3:
        this.calendarInputClass = 'calendar--normal-width';
        break;
      case dateFormats.format2:
        this.calendarInputClass = 'calendar--medium-width';
        break;
      case dateFormats.format4:
        this.calendarInputClass = 'calendar--large-width';
        break;
      default:
        this.calendarInputClass = '';
        break;
    }
  }
}
