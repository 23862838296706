import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToggleOption } from './toggle-option';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() toggleOptions: ToggleOption;
  @Output() toggleChangeState: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  toggle(val: any) {
    this.toggleChangeState.emit(val);
  }
}
