import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  MasterItemFullResponseModel,
  PackageResponseFullModel
} from '../../../core/services/Inventory';
import { FormGroup } from '@angular/forms';
import { FormatsService } from '../../../shared/services/formats.service';
import { UnitOfMeasurementFormatPipe } from '../../pipes';
import { FormattingResponseModelMeasurementSystem } from '@onbatch/core/services/Account';

@Component({
  selector: 'app-master-item-package',
  templateUrl: './master-item-package.component.html'
})
export class MasterItemPackageComponent {
  @Input() masterItem: MasterItemFullResponseModel;
  @Input() editable: boolean;
  @Input() volumeLabel: string;
  @Input() volume: string;
  @Input() packageFormGroup: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() unitsValid: boolean;
  @Input() package: PackageResponseFullModel;

  @Output() clearPackError: EventEmitter<string> = new EventEmitter<string>();

  patternForAmount = this.formatsService.patternForAmount();

  get getVolume(): string {
    if (!this.package.numberOfUnits) {
      return `Number of units x ${this.volume}`;
    } else {
      return `${this.package.numberOfUnits} x ${this.volume}${this.getUOM} (${this.package.numberOfUnits * Number(this.volume)}${this.getUOM})`;
    }
  }

  get getUOM(): string {
    return this.volumeLabel ? ` ${this.uomPipe.transform(this.volumeLabel)}` : ``;
  }

  get lengthUnit(): string {
    return ` ${this.formatsService.getLengthUoM(false)}`;
  }
  get weightUnit(): string {
    return ` ${this.formatsService.getWeightUoM(false)}`;
  }

  @HostBinding('style.width') private width = '100%';

  constructor(private formatsService: FormatsService,
              private uomPipe: UnitOfMeasurementFormatPipe) {
  }

  changeState() {
    if (this.editable) {
      this.masterItem.isPacked = !this.masterItem.isPacked;
    }
  }

  removeError() {
    this.clearPackError.emit();
  }
}
