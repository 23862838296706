import { AfterViewInit, Component, enableProdMode, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';
import { SpinnerService } from './core/services/spinner.service';
import { EnvService } from '@onbatch/core/services/Env';
import { combineLatest, pipe, Subscription } from 'rxjs';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { skip } from 'rxjs/operators';
import { ConfirmationModalService, ConfirmModalConfiguration, ModalConfirmationState } from './shared/services/confirmation-modal.service';

declare let gtag: Function;

@Component({ selector: 'app-root', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit, AfterViewInit {
  loaded = false;
  modalConfiguration: ConfirmModalConfiguration = {
    text: 'You already made some changes in form are you sure you want to close modal?',
    title: 'Confirmation',
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm'
  };
  modalId = 'generalConfirmModal';
  isModalVisible = false;

  constructor(private translate: TranslateService,
              private router: Router,
              private toastr: ToastrService,
              private authService: AuthService,
              private envService: EnvService,
              public spinnerService: SpinnerService,
              public subscriptionService: SubscriptionService,
              private confirmationModalService: ConfirmationModalService,
              private ngxSmartModal: NgxSmartModalService,
              ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', envService.env.googleAnalytics.id,
          {
            'page_path': event.urlAfterRedirects
          }
        );
        if ((window as any).Appcues) {
          (window as any).Appcues.page();
        }
      } else if (event instanceof RouteConfigLoadStart) {
        this.spinnerService.setRouteSpinner(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.spinnerService.setRouteSpinner(false);
      }
    });
  }

  ngOnInit(): void {
    this.authService.silentRenew()
      .then(() => this.loaded = true);
  }

  ngAfterViewInit(): void {
    combineLatest([this.confirmationModalService.getOptions(), this.confirmationModalService.getModalState()])
      .subscribe(([configuration, isModalVisible]: [ConfirmModalConfiguration, boolean]) => {
        if (configuration) {
          this.modalConfiguration = {...configuration};
        }
        if (isModalVisible) {
          this.ngxSmartModal.open(this.modalId);
        } else if (this.ngxSmartModal.getModal(this.modalId) && this.ngxSmartModal.getModal(this.modalId).isVisible) {
          this.ngxSmartModal.close(this.modalId);
        }
        this.isModalVisible = isModalVisible;
      });
  }

  onActivate(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      pos > 0 ? window.scrollTo(0, pos - 20) : window.clearInterval(scrollToTop);
    }, 16);
  }

  cancelConfirmModal(): void {
    this.confirmationModalService.setState(ModalConfirmationState.canceled);
  }

  confirmConfirmModal(): void {
    this.confirmationModalService.setState(ModalConfirmationState.confirmed);
  }
}
