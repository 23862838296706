import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subscriptionType'
})
export class SubscriptionTypePipe implements PipeTransform {
  transform(value: string): any {
    if (value === 'Starter') {
      return 'btn--red';
    } else if (value === 'Growth') {
      return 'btn--orange';
    } else if (value === 'Premium') {
      return 'btn--violet';
    } else if (value === 'Enterprise') {
      return 'btn--blue';
    } else {
      return 'btn--red';
    }
  }
}
