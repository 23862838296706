import {Component, Input, HostBinding, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input() label: string;
  @Input() labelClass: string;
  @Input() class: string;
  @Input() clicked: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() isActive: boolean;
  @Input() disabled = false;
  @Input() drop = false;
  @Input() iconRight: boolean;
  @Input() expand = false;
  @Output() clickInner = new EventEmitter<MouseEvent>();

  constructor() {
  }
  @HostBinding('style.pointerEvents') get PointerEventValue() {
    return this.disabled ? 'none' : 'all';
  }
  @HostBinding('style.width') get width() {
    return this.expand ? '100%' : '';
  }

  onClick(e: MouseEvent) {
    if (!this.disabled) {
      this.clickInner.emit(e);
    }
  }
}
