import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
export class EnvService {
    constructor() {
        this.apiUrl = '';
        const browserWindow = window || {};
        const browserWindowEnv = browserWindow['__env'] || {};
        // this.apiUrl = browserWindowEnv.apiUrl === '' ? environment.baseUrl : browserWindowEnv.apiUrl; // do not override baseUrl value with window object value
        this.apiUrl = environment.baseUrl; // use environment.ts file instead
        this.env = browserWindowEnv; // TODO - this one should be made obsolete
        this.env.intercomAppId = environment.intercomAppId;
        this.env.intercomIdentitySecret = environment.intercomIdentitySecret;
    }
}
EnvService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvService_Factory() { return new EnvService(); }, token: EnvService, providedIn: "root" });
