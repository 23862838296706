import { Pipe, PipeTransform } from '@angular/core';
import { FailureReason } from '../../core/services/InventoryRx';

@Pipe({
  name: 'failureReason'
})
export class FailureReasonPipe implements PipeTransform {
  transform(value: FailureReason): any {
    switch (value) {
      case FailureReason.IsBom: {
        return `it was used as Bill of Materials`;
      }
      case FailureReason.HasLocations: {
        return `it has locations added`;
      }
      case FailureReason.IsOwner: {
        return `he is owner of the organization`;
      }
      case FailureReason.CannotDeleteYourself: {
        return `you cannot delete yourself`;
      }
      case FailureReason.UserAlreadyExist: {
        return `user already exist`;
      }
      case FailureReason.HasQuantity: {
        return `it has quantity`;
      }
      case FailureReason.StockCountExists: {
        return `stock count for this item exists`;
      }
      case FailureReason.JustBecause: {
        return `it has been used in a transaction`;
      }
      case FailureReason.HasPurchaseOrders: {
        return `it has Purchase Orders`;
      }
      case FailureReason.HasSalesOrders: {
        return `it has Sales Orders`;
      }
    }
  }
}
