import { Component, EventEmitter, Output } from '@angular/core';
import { FiltersComponent } from '../filters.component';
import { ToastrService } from 'ngx-toastr';
import { FiltersService } from '../filters.service';
import { FormBuilder } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html'
})
export class AdvancedFiltersComponent extends FiltersComponent {

  @Output() closeIconClicked: EventEmitter<null> = new EventEmitter<null>();

  constructor(public toastr: ToastrService,
              public filtersService: FiltersService,
              public fb: FormBuilder,
              public localStorage: LocalStorage,
              public authService: AuthService) {
    super(toastr, filtersService, fb, localStorage, authService);
  }

  closeFilterBar(): void {
    this.closeIconClicked.emit();
  }

  discardFilter(): void {
    this.activeFilters = [
      {
        valueFilters: [
          {
            fieldName: null,
            condition: null,
            value: null
          }
        ],
        quantityFilters: [],
        enumFilters: [],
        index: 0
      }
    ];
    this.setFiltersTypes();
    this.applyFilters.emit([]);
  }

  applyFilter(): void {
    this.applyFilters.emit(this.activeFilters);
  }
}
