import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { MasterItemPricingCurrencyTypeId } from '../../core/services/Inventory';
import { FormattingResponseModel } from '../../core/services/Account';

@Pipe({
  name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {
  settings: FormattingResponseModel = new FormattingResponseModel();

  constructor(public authService: AuthService) {
  }

  transform(value: string, args?: any): string {
    switch (value) {
      case (MasterItemPricingCurrencyTypeId.USD): {
        return `$`;
      }
      default: {
        return `$`;
      }
    }
  }
}
