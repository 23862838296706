import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormatsService, TimeFormat } from '../../services/formats.service';
import { DurationInputValue, pricePrefix, TimerInputValue } from '../..';
import { closeCalendar, formatTimer, pad } from '../../utils';
import { formatDuration } from 'app/manufacturing/shared/utils/common';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import IMask from 'imask';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';

@Component({
  selector: 'app-form-box',
  templateUrl: './form-box.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class FormBoxComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() options: any;
  @Input() formControlNameValue: string;
  @Input() formGroup: FormGroup;
  @Input() formSubmitted: boolean;
  @Input() id = '';
  @Input() selectOptions: any[];
  @Input() focusOnInit: boolean;
  @Input() mask: string;
  @Input() removable: boolean;
  @Input() clearAfterConfirm = false;
  @Input() patternError: string;

  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() onExtraBtnClick: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onInputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInputBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDecimalLengthChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClearInput = new EventEmitter<Event>();
  @Output() durationInputChange: EventEmitter<DurationInputValue> = new EventEmitter<DurationInputValue>();
  @Output() timerInputChange = new EventEmitter<TimerInputValue>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() close = new EventEmitter();
  @Output() open = new EventEmitter();

  tagCount: number;
  showTooltip = false;
  maskedInputValue: '';

  term = '';

  readonly pricePrefix = pricePrefix;
  patternForAmountInteger = this.formatsService.patternForAmountInteger();
  patternForAmount = this.formatsService.patternForAmount();
  patternForCost = this.formatsService.patternForCost();

  durationUnmaskedValue = '';
  durationPlaceholder = '00d:00h:00m';

  readonly timeFormat = this.formatsService.getTimeFormat();
  readonly timerMask: IMask.AnyMaskedOptions = {
    overwrite: true,
    autofix: true,
    lazy: false,
    mask: 'H{:}`M`',
    blocks: {
      H: {
        mask: IMask.MaskedRange,
        placeholderChar: '-',
        from: 1,
        to: this.timeFormat === TimeFormat.TwelveHours ? 12 : 24,
        maxLength: 2
      },
      M: {
        mask: IMask.MaskedRange,
        placeholderChar: '-',
        from: 0,
        to: 59,
        maxLength: 2
      }
    }
  };
  timerValue: string;
  timerAmOrPm: 'AM' | 'PM' = 'AM';

  closeDatepicker = closeCalendar;

  @ViewChild('fieldRef', { static: false }) fieldRef: ElementRef;

  @HostBinding('style.width') private width = '100%';

  get getCustomPrecision(): string {
    return `separator.${this.options.numberOfDecimalPlaces}`;
  }

  private static getDays(value: string): number {
    return FormBoxComponent.getSanitizedTimeValue(value, 'd');
  }

  private static getHours(value: string): number {
    return FormBoxComponent.getSanitizedTimeValue(value, 'h');
  }

  private static getMinutes(value: string): number {
    return FormBoxComponent.getSanitizedTimeValue(value, 'm');
  }

  private static getSanitizedTimeValue(value: string, suffix: string) {
    if (!value) {
      return 0;
    }

    const sanitizedValue = value.replace(suffix, '').replace(new RegExp('_', 'g'), '');

    if (!sanitizedValue) {
      return 0;
    }

    return +sanitizedValue;
  }

  private static getDuration(value: string): DurationInputValue | null {
    if (!value) {
      return null;
    }

    const parts = value.split(':');

    return <DurationInputValue>{
      days: FormBoxComponent.getDays(parts[0]),
      hours: FormBoxComponent.getHours(parts[1]),
      minutes: FormBoxComponent.getMinutes(parts[2])
    };
  }

  constructor(private formatsService: FormatsService) {
  }

  addCustomElement = (term: string) => ({ name: term });

  selectValueChange(event: string) {
    if (this.formControlNameValue === 'masterItemTypeId'
      || this.formControlNameValue === 'country'
      || this.formControlNameValue === 'masterItemContainerTypeId'
      || this.formControlNameValue === 'uoM'
      || this.formControlNameValue === 'UoM'
      || this.formControlNameValue === 'unitOfMeasurementExternalId'
      || this.formControlNameValue === 'masterItems'
      || this.formControlNameValue === 'productFlowExternalId'
      || this.formControlNameValue === 'defaultAmountTypeId'
      || this.formControlNameValue === 'timezone'
    ) {
      this.change.emit(event);
    }
    if (this.options.multiple) {
      this.tagCount = this.formGroup.get(this.formControlNameValue).value.length;
    }
  }

  openSelect() {
    this.open.emit();
  }

  closeSelect() {
    this.close.emit();
  }

  inputValueChange(event: string) {
    this.onInputChange.emit(event);
  }

  inputBlur(event: string) {
    this.onInputBlur.emit(event);
  }

  maskedInputValueChange(value: string) {
    const newValue = value.replace(new RegExp(',', 'g'), '');
    this.onInputChange.emit(newValue);
  }

  deleteTag(control: FormControl, tag: string) {
    const newTags = control.value.filter((item: string) => item !== tag);
    control.setValue(newTags);
    this.tagCount = this.formGroup.get(this.formControlNameValue).value.length;
  }

  getLookup(value: string = null): void {
    this.term = value;
    this.search.emit(value);
  }

  selectOpened(): void {
    if (this.term !== '') {
      this.getLookup('');
    }
  }

  afterOptionSelected(event: string) {
    this.optionSelected.emit(event);
  }

  toggleWrapFocus(event: FocusEvent, flag: boolean) {
    const parent = <HTMLElement>(<HTMLElement>event.target).parentNode;
    if (flag) {
      parent.classList.add('form__textarea-wrap--focused');
    } else {
      parent.classList.remove('form__textarea-wrap--focused');
    }
  }

  clearInput(control: FormControl, event?: Event): void {
    if (!this.clearAfterConfirm) {
      control.setValue(null);
    }

    if (this.options.multiple) {
      if (this.formGroup.get(this.formControlNameValue).value) {
        this.tagCount = this.formGroup.get(this.formControlNameValue).value.length;
      } else {
        this.tagCount = 0;
      }
    }

    if (this.options.fieldType === 'durationInput') {
      this.durationUnmaskedValue = '';
      this.durationInputChange.emit(null);
    }
    this.onClearInput.emit(event);
  }

  extraBtnClick(slug: string): void {
    const payload = {
      slug,
      inputName: this.formControlNameValue
    };

    if (this.options.fieldType === 'timer') {
      this.timerAmOrPm = slug === 'setAm' ? 'AM' : 'PM';
      this.timerUpdateAmPm();
    } else {
      this.onExtraBtnClick.emit(payload);
    }
  }

  ngOnInit() {
    if (this.options.multiple && this.formGroup.get(this.formControlNameValue).value) {
      this.tagCount = this.formGroup.get(this.formControlNameValue).value.length;
    }

    if (this.options.fieldType === 'timer') {
      if (this.timeFormat === TimeFormat.TwelveHours) {
        this.options = {
          ...this.options,
          extraButtons: [
            {
              slug: 'setAm',
              label: 'AM',
              selected: true
            },
            {
              slug: 'setPm',
              label: 'PM',
              selected: false
            }
          ]
        };
      }

      const updateTimer = (value) => {
        if (value) {
          const timer: TimerInputValue = this.getTimerValue(value);
          this.timerValue = formatTimer(timer);
          this.timerUpdateAmPm();
        }
      };

      const currentValue = this.formGroup.get(this.options.field).value;
      if (currentValue) {
        updateTimer(currentValue);
      } else {
        this.formGroup.get(this.options.field).valueChanges
          .pipe(first())
          .subscribe(updateTimer);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.focusField();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options.fieldType !== 'durationInput') {
      return;
    }
    const optionsChange: SimpleChange = changes['options'];
    if (!optionsChange) {
      return;
    }

    const prevName: string = optionsChange.isFirstChange() ? '' : optionsChange.previousValue.name;
    const currName: string = optionsChange.currentValue.name;
    if (prevName !== currName) {
      const currentValue = this.formGroup.get(this.formControlNameValue).value;
      if (currentValue) {
        const duration: DurationInputValue = FormBoxComponent.getDuration(currentValue);
        this.durationUnmaskedValue = duration ? this.durationToUnmaskedInput(duration) : '';
      } else {
        this.durationUnmaskedValue = '';
      }
    }
  }

  addClosePrevent(): void {
    document.querySelector('.owl-dt-container-row').addEventListener('click', function(event: Event) {
      event.stopPropagation();
    });
  }

  focusField(): void {
    this.fieldRef.nativeElement.focus();
  }

  toggleTooltip(event?: any): void {
    // console.log(event)
    // if (event) {
    //   if (event.value) {
    //     this.showTooltip = true;
    //   }
    // } else {
    this.showTooltip = !this.showTooltip;
    // }
  }

  openTooltip(event: { value: boolean }): void {
    if (!event.value) {
      this.showTooltip = true;
    }
  }

  decreaseDecimalPlaces(control: FormControl) {
    control.setValue(this.addDecimalPlaces(control.value, -1));
  }

  increaseDecimalPlaces(control: FormControl) {
    control.setValue(this.addDecimalPlaces(control.value, 1));
  }

  addDecimalPlaces(currentValue: string, toAdd: number): string {
    const values = currentValue.split('.');
    const decimalPlaces = values[1].length;
    let newDecimalPlaces = decimalPlaces + toAdd;
    if (newDecimalPlaces < 1) {
      newDecimalPlaces = 1;
    }

    let newValue = '0.';
    for (let i = 0; i < newDecimalPlaces; i++) {
      newValue += '0';
    }

    this.onDecimalLengthChange.emit(newDecimalPlaces);
    return newValue;
  }

  onDelete() {
    this.delete.emit(true);
  }

  onDurationKeyUp(event: KeyboardEvent) {
    this.processDurationInput(event, false);
  }

  onDurationKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Backspace') {
      return;
    }

    this.processDurationInput(event, true);
  }

  onTimerInputComplete(value: string) {
    this.timerValue = value;
    this.timerEmitChange();
  }

  private processDurationInput(event: KeyboardEvent, keyDown: boolean = false) {
    const totalLength = 11; // DDd:HHh:MMm
    const unmaskedValue = this.preprocessDurationInput(this.durationUnmaskedValue, event, totalLength, keyDown);
    let duration: DurationInputValue = this.parseUnmaskedDurationInput(unmaskedValue);

    if (this.isDurationValid(duration)) {
      this.durationUnmaskedValue = unmaskedValue;
      this.durationInputChange.emit(duration);
    } else {
      duration = this.parseUnmaskedDurationInput(this.durationUnmaskedValue);
    }

    this.fieldRef.nativeElement.value = formatDuration(duration);
  }

  private inputSelectionLength(): number {
    if (!this.fieldRef.nativeElement) {
      return -1;
    }

    return this.fieldRef.nativeElement.selectionEnd - this.fieldRef.nativeElement.selectionStart;
  }

  private preprocessDurationInput(unmasked: string, event: KeyboardEvent, maxLength: number, keyDown: boolean = false): string {
    const numbers = Array.from(Array(10).keys()).map((i: number) => i.toString());

    if (event.key in numbers) {
      return unmasked + event.key;
    } else if (keyDown && event.key === 'Backspace') {
      const wholeInputSelected = this.inputSelectionLength() === maxLength;
      if (wholeInputSelected) {
        return '';
      }
      return unmasked.slice(0, -1);
    }

    return unmasked;
  }

  private parseUnmaskedDurationInput(unmasked: string): DurationInputValue {
    let duration: number[] = Array.from(unmasked).map(char => +char);
    while (duration.length !== 6) {
      if (duration.length < 6) {
        duration.unshift(0);
      } else if (duration.length > 6) {
        duration = duration.slice(0, 6);
      }
    }
    return {
      days: +duration.slice(0, 2).join(''),
      hours: +duration.slice(2, 4).join(''),
      minutes: +duration.slice(4, 6).join(''),
    };
  }

  private isDurationValid(duration: DurationInputValue): boolean {
    return duration.days >= 0 && duration.days <= 99
      && duration.hours >= 0 && duration.hours <= 99
      && duration.minutes >= 0 && duration.minutes <= 99;
  }

  private durationToUnmaskedInput(duration: DurationInputValue): string {
    const unmasked: string = pad(duration.days) + pad(duration.hours) + pad(duration.minutes);
    // remove leading zeroes
    const firstIdx = Array.from(unmasked).findIndex(char => char !== '0');
    return unmasked.slice(firstIdx);
  }

  private getTimerValue(value: string): TimerInputValue | null {
    if (!value) {
      return null;
    }

    const m = moment(value, 'HH:mm');
    if (this.timeFormat === TimeFormat.TwelveHours) {
      this.timerAmOrPm = m.format('A') === 'PM' ? 'PM' : 'AM';
    }

    return <TimerInputValue>{
      hours: +m.format(this.timeFormat === TimeFormat.TwelveHours ? 'hh' : 'HH'),
      minutes: +m.format('mm'),
    };
  }

  private timerUpdateAmPm() {
    if (this.options.extraButtons) {
      if (this.timerAmOrPm === 'PM') {
        this.options.extraButtons[0].selected = false;
        this.options.extraButtons[1].selected = true;
      } else {
        this.options.extraButtons[0].selected = true;
        this.options.extraButtons[1].selected = false;
      }
    }
    this.timerEmitChange();
  }

  private timerEmitChange() {
    if (!this.timerValue) {
      return;
    }

    const [hours, minutes] = this.timerValue.split(':');
    const value: TimerInputValue = {
      hours: +hours,
      minutes: +minutes,
    };
    if (this.timerAmOrPm === 'PM') {
      if (value.hours >= 1 && (value.hours <= 11 && value.minutes <= 59)) {
        value.hours += 12;
      }
    }
    this.formGroup.controls[this.formControlNameValue].setValue(formatTimer(value));
    this.timerInputChange.emit(value);
  }
}
