import { Injectable } from '@angular/core';
import { User } from 'oidc-client';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './Env';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as jwt_decode from 'jwt-decode';
import { map, take } from 'rxjs/operators';
import {
  AccountClient,
  CompanyExtendedResponseModel,
  CompanyExtendedResponseModelIndustryTypeId, CompanySubscriptionResponseModel,
  CompanyWithCountryResponseModel,
  CostPriceFormatResponseModel,
  Features2,
  FormattingResponseModel,
  FormattingResponseModelMeasurementSystem,
  IndustryTypeId,
  ManufacturingResponseModel,
  ManufacturingResponseModelAlcoholContent,
  ManufacturingResponseModelPureAlcohol,
  NameExternalIdModel,
  SubscriptionCompanyResponse,
  UserSettingsResponseModel
} from './Account';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { dateFormats, timeFormats } from '../../account/settings/settings-statics.enum';
import { OwlDateTimeFormats } from 'ng-pick-datetime';
import smartlookClient from 'smartlook-client';
import { SettingsService } from 'app/account/settings/settings.service';
import * as crypto from "crypto-js";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static isMixPanelInitiated = new BehaviorSubject<boolean>(false);
  static isImpersonatePerson = new BehaviorSubject<boolean>(false);

  formattingSettings = new BehaviorSubject<IFormattingSettings | null>(null);
  currentUser = new BehaviorSubject(null);
  currentUserRoles = new BehaviorSubject(null);
  currentSettings = new BehaviorSubject(null);
  currentOrg = new BehaviorSubject('');
  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAdmin = new BehaviorSubject<boolean>(false);
  organizationsResponse: CompanyWithCountryResponseModel[] = [];

  currentSubscription = new BehaviorSubject<SubscriptionCompanyResponse>(null);

  private user: User = null;
  private adminClient: AccountClient;

  static getUser(): IUser {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  static getUserRoles() {
    const user = AuthService.getUser();
    if (!user) {
      return null;
    }
    return user.profile.TenantRoles;
  }

  static getAuthorizationHeaderValue(): string {
    const user = AuthService.getUser();
    if (!user) {
      return null;
    }
    return `Bearer ${user.access_token}`;
  }

  static startAuthentication() {
    window.location.href = '/account/signin';
    return;
  }

  static getCurrentSettings() {
    const user = AuthService.getUser();
    if (!user) {
      return null;
    }
    return user.settings;
  }

  static get isMixPanelActivated() {
    return AuthService.isMixPanelInitiated.getValue();
  }

  static get isImpersonate() {
    return AuthService.isImpersonatePerson.getValue();
  }

  static getFormattingSettingsFromLocalStorage(): IFormattingSettings | null {
    const settings: string | null = localStorage.getItem('formatting-settings');
    if (settings) {
      return JSON.parse(settings);
    }
    return settings as null;
  }

  static get getOwlDatePickerSettings(): OwlDateTimeFormats {
    const owlSettings: OwlDateTimeFormats = {
      parseInput: { hour: 'numeric', minute: 'numeric' },
      fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
      datePickerInput: { month: 'short', day: 'numeric', year: 'numeric' },
      timePickerInput: { hour: 'numeric', minute: 'numeric' },
      monthYearLabel: { year: 'numeric', month: 'short' },
      dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'long' }
    };
    const settings: IFormattingSettings = AuthService.getFormattingSettingsFromLocalStorage();
    if (settings) {
      const { dateFormat } = settings;
      switch (dateFormat) {
        case dateFormats.format1: {
          owlSettings.parseInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
          owlSettings.datePickerInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
          break;
        }
        case dateFormats.format2: {
          owlSettings.parseInput = { month: 'short', day: 'numeric', year: 'numeric' };
          owlSettings.datePickerInput = { month: 'short', day: 'numeric', year: 'numeric' };
          break;
        }
        case dateFormats.format3: {
          owlSettings.parseInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
          owlSettings.datePickerInput = { month: 'numeric', day: 'numeric', year: 'numeric' };
          break;
        }
        case dateFormats.format4: {
          owlSettings.parseInput = { month: 'long', day: 'numeric', year: 'numeric' };
          owlSettings.datePickerInput = { month: 'long', day: 'numeric', year: 'numeric' };
          break;
        }
        default:
      }
    }
    return owlSettings as OwlDateTimeFormats;
  }

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private envService: EnvService,
    private localStorage: LocalStorage,
    private mixpanelService: MixpanelService,
    private subscriptionService: SubscriptionService,
    private settingsService: SettingsService) {
    this.checkLocalStorageVersion();
    this.adminClient = new AccountClient(this.http, envService.apiUrl);
    const key = this.getStorageKey('onbatch-permissions');
    this.localStorage.setItemSubscribe(key, null);
    this.initUser();
  }

  initUser() {
    const current = AuthService.getUser();
    this.currentUser.next(current);
    if (current && current.access_token) {
      this.isAuthenticatedSubject.next(true);
    }
  }

  setUser(data: IUser) {
    data.profile = jwt_decode(data.access_token);
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.isAdmin.next(data.profile.Role === 'OnBatchAdmin');
  }

  getCurrentOrganization() {
    return this.currentOrg.value;
  }

  static get isReportingAvailable() {

    if (AuthService.getUser().profile.ReportingAvailable[0] == "False") {
      return false
    }
    else if (AuthService.getUser().profile.ReportingAvailable[0] == "True") {
      return true
    }
    return false;
  }

  getFormattingSettingsObservable() {
    return this.formattingSettings.value;
  }

  updateLocalUser(data: IUser) {
    const current = AuthService.getUser();
    const details = { ...current, ...data };
    this.currentUser.next(details);
    if (details.access_token && details.access_token.length > 0) {
      this.isAuthenticatedSubject.next(true);
    } else {
      this.isAuthenticatedSubject.next(false);
    }
    this.setUser(details);
  }


  setUserRoles() {
    const roles = JSON.parse(this.currentUser.value.profile.TenantRoles);
    const currentOrg = this.getCurrentOrganization();
    if (roles.hasOwnProperty(currentOrg)) {
      this.currentUserRoles.next(roles[currentOrg]);
    } else {
      this.currentUserRoles.next({});
    }
  }

  getAccess(module: string, permissions: string): boolean {
    let shouldBeVisible = false;
    const isFeatureAvailable = new Features2({
      misc_RolesPermissions: this.subscriptionService.getAccess(SubscriptionFeatures.MiscRolesPermissions)
    });
    if (!isFeatureAvailable.misc_RolesPermissions) {
      shouldBeVisible = true;
    } else {
      const roles = JSON.parse(AuthService.getUserRoles());
      if (roles) {
        const rolesForCurrentOrganization = roles[this.getOrgFromStorage()];
        if (rolesForCurrentOrganization[module]) {
          rolesForCurrentOrganization[module].map((permission: string) => {
            if (permission === permissions) {
              shouldBeVisible = true;
            }
          });
        }
      }
    }
    return shouldBeVisible;
  }

  setFormattingSettings() {
    return forkJoin([
      this.settingsService.getSettingsForFormatting(),
      this.adminClient.settingsManufacturingGet(),
    ]).subscribe((formatting: [FormattingResponseModel, ManufacturingResponseModel]) => {
      this.setFormattingSettingsObservable(<IFormattingSettings>{
        ...formatting[0], alcoholContent: formatting[1].alcoholContent, pureAlcohol: formatting[1].pureAlcohol
      });
    });
  }

  setSubscription() {
    return this.subscriptionService.getSubscription();
  }

  getSettingsForFormatting(): Observable<IFormattingSettings> {
    return this.adminClient.settingsFormattingGet();
  }

  setFormattingSettingsObservable(data: IFormattingSettings): void {
    localStorage.setItem('formatting-settings', JSON.stringify({ ...this.formattingSettings.value, ...data }));
    this.formattingSettings.next({ ...this.formattingSettings.value, ...data });
  }

  getUserInfo(companyExternalId: string): Observable<UserSettingsResponseModel> {
    return this.adminClient.accountSettingsByCompanyExternalIdGet(companyExternalId);
  }

  getCurrentUser() {
    return this.currentUser.value;
  }

  isLoggedIn() {
    return this.isAuthenticatedSubject.value;
  }

  getClaims(): any {
    return this.currentUser.value;
  }

  setUserInfo(user: any) {
    this.currentUser.next(user);
  }

  setCountry(): void {
    const key: string = this.getStorageKey('onbatch-organizationId');
    const lsid: string = localStorage.getItem(key);
    const currentUser: IUser = this.currentUser.value;
    try {
      const organization: string = currentUser.organizations.find((org) => org.externalId === lsid).externalId;
      const countryID: string = this.organizationsResponse.find(
        (org: CompanyWithCountryResponseModel) => org.externalId === organization
      ).country.externalId;
      localStorage.setItem('companiesCountry', countryID);
    } catch (e) {
      localStorage.setItem('companiesCountry', null);
      throw new Error(`Issue with getting company's country information`);
    }
  }

  setOrg(organizations: NameExternalIdModel[]) {
    this.organizationsResponse = organizations;
    const key = this.getStorageKey('onbatch-organizationId');
    const lsid = localStorage.getItem(key);
    localStorage.setItem(key, organizations[0].externalId);
    this.currentOrg.next(organizations[0].externalId);
    organizations.map((permission: NameExternalIdModel) => {
      if (permission.externalId === lsid) {
        this.currentOrg.next(lsid);
        localStorage.setItem(key, lsid);
      }
    });
  }

  getOrgFromStorage(): string {
    return localStorage.getItem(this.getStorageKey('onbatch-organizationId'));
  }

  getTokens() {
    return this.http.get('/account/tokens').toPromise().then((response) => response);
  }

  getUserSettings(companyExternalId: string): Promise<UserSettingsResponseModel> {
    return this.adminClient.accountSettingsByCompanyExternalIdGet(companyExternalId).toPromise();
  }

  setUserSettings(settings: any) {
    const current = AuthService.getUser();
    current.settings = settings;
    this.currentUser.next(current);
    this.setUser(current);
    this.currentSettings.next(settings);
    this.updateLocalUser(settings);
    this.setUserRoles();
  }

  completeAuthentication() {
    return this.getTokens()
      .then((user: IUser) => this.updateLocalUser(user))
      .then(() => !this.isAdmin.value && this.completeUserAuthentication())
      .catch(e => console.log(e));
  }

  silentRenew() {
    return this.getTokens()
      .then((user: IUser) => this.updateLocalUser(user))
      .then(() => !this.isAdmin.value && this.completeSilentRenew())
      .catch(e => console.log(e));
  }

  completeSilentRenew() {
    return this.getOrganizations()
      .then((organizations) => this.setOrg(organizations))
      .then(() => this.getUserSettings(this.currentOrg.value))
      .then((settings) => this.setUserSettings(settings))
      .then(() => this.setSubscription())
      .then(() => this.setFormattingSettings())
      .then(() => this.setIntercom())      
      .then(() => this.initMixPanel())
      .catch(e => console.log(e));
  }

  completeUserAuthentication() {
    return this.getOrganizations()
      .then((organizations) => this.setOrg(organizations))
      .then(() => this.getUserSettings(this.currentOrg.value))
      .then((settings) => this.setUserSettings(settings))
      .then(() => this.setCountry())
      .then(() => this.setSubscription())
      .then(() => this.setFormattingSettings())
      .then(() => this.setHubSpot())
      //.then(() => this.setSmartLook())
      .then(() => this.setIntercom())      
      .then(() => this.initMixPanel())
      .catch(e => console.log(e));
  }

  getOrganizations(): Promise<NameExternalIdModel[]> {
    return this.adminClient.accountSettingsCompaniesGet().toPromise();
  }

  logout() {
    this.user = null;
    this.currentSettings.next(null);
    this.currentUser.next(null);
    this.currentOrg.next(null);
    this.currentUserRoles.next(null);
    this.currentSubscription.next(null);
    this.mixpanelService.logout();
    AuthService.isMixPanelInitiated.next(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentSubscription');
    localStorage.removeItem(this.getStorageKey('onbatch-permissions'));
    localStorage.removeItem(this.getStorageKey('onbatch-organizationId'));
    localStorage.removeItem('formatting-settings');
    this.localStorage.removeItem('select-vessel-active-tab').subscribe(() => {
      window.location.href = '/account/signout';
    });
    return false;
  }

  getStorageKey(prefix: string): string {
    const user = this.getCurrentUser();
    if (user !== null && user.profile) {
      return `${prefix}-${user.profile.sub}`;
    }
    return prefix;
  }

  refreshToken() {
    const updatedUser = AuthService.getUser();
    return this.http.get(`/account/tokens/refresh/${updatedUser.refresh_token}`)
      .pipe(
        map((user: any) => {
          updatedUser.access_token = user.access_token;
          updatedUser.refresh_token = user.refresh_token;
          this.setUser(updatedUser);
          return <User>user;
        }));
  }

  getSubscriptionForUserSettings() {
    return this.currentUser;
  }

  getCurrentOrganizationName(): string {
    const currentOrganization = this.currentUser.getValue().organizations.find(el => el.externalId === this.currentOrg.getValue());
    if (currentOrganization) {
      return currentOrganization.name;
    }
  }
   

  setIntercom() {
    if (!AuthService.isImpersonate && !this.isAdmin.value) {
      const user: IUser = this.currentUser.value;    
      const currentOrgIndustry = user.organizations.find(el => el.externalId === this.currentOrg.value).industryTypeId;
      if (user) {    
        (window as any).Intercom('boot', {
          app_id: this.envService.env.intercomAppId,
          hide_default_launcher: false,
          user_id: user.externalId,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          phone: user.phoneNumber,
          user_hash: this.signKey(user.externalId, this.envService.env.intercomIdentitySecret),
          companies: user.organizations.map(organization => {
            return {
              company_id: organization.externalId,
              name: organization.name
            };
          }),
          company: {
            company_id: this.currentOrg.value,
            name: this.getCurrentOrganizationName(),
            industry: currentOrgIndustry,
            'plan': this.subscriptionService.currentSubscription.value ? this.subscriptionService.currentSubscription.value.subscriptionType : null,
          }          
        });
      }
    }
  }

  setSmartLook() {
    const user: IUser = this.currentSettings.value;
    smartlookClient.init(this.envService.env.smartLookToken);
    if (user) {
      smartlookClient.identify(AuthService.isImpersonate ? 999 : user.externalId, {
        name: AuthService.isImpersonate ? 'Impersonated User' : user.firstName,
        email: user.email,
      });
    }
  }

  setMixpanelUser(): void {
    const user: IUser = this.currentSettings.value;
    const currentOrgIndustry = user.organizations.find(el => el.externalId === this.currentOrg.value).industryTypeId;
    const setUserData = () => {
      const userData = {
        $distinct_id: user.externalId,
        $email: user.email,
        $name: `${user.firstName} ${user.lastName}`,
        $phone: user.phoneNumber,
        Companies: user.organizations.map(organization => {
          return organization.name;
        }).join(', '),
        Company: `${this.getCurrentOrganizationName()} (Industry: ${currentOrgIndustry})`,
        Plan: this.subscriptionService.currentSubscription.value.subscriptionType
      };
      this.mixpanelService.register_once(userData);
      this.mixpanelService.setPeople(userData);
    };

    if (!localStorage.getItem('currentSubscription')) {
      this.adminClient.paymentSubscriptionCurrentGet().subscribe(
        (subscription: CompanySubscriptionResponseModel) => {
          this.subscriptionService.currentSubscription.next(subscription);
          localStorage.setItem('currentSubscription', JSON.stringify(subscription));
          setUserData();
        });
    } else {
      setUserData();
    }
  }

  initMixPanel() {
    const user: IUser = this.currentSettings.value;
    if (user && !AuthService.isMixPanelActivated && this.envService.env.mixPanelToken.length) {
      const isImpersonate: boolean = !!this.getClaims().profile.OnBatchImpersonation;
      try {
        AuthService.isImpersonatePerson.next(isImpersonate);
        const config = {
          batch_requests: true
        };
        if (isImpersonate) {
          config['opt_out_tracking_by_default'] = isImpersonate;
        } else {
          for (const key in localStorage) {
            if (key.includes('__mp_opt_in_out_')) {
              localStorage.removeItem(key);
            }
          }
          const allCookies = this.cookieService.getAll();
          for (const key in allCookies) {
            if (key.includes('__mp_opt_in_out_')) {
              this.cookieService.delete(key);
            }
          }
        }
        this.mixpanelService.init(this.envService.env.mixPanelToken, config);
        AuthService.isMixPanelInitiated.next(true);
        this.mixpanelService.identify(user.externalId);
        this.setMixpanelUser();
        this.mixpanelService.setGroup('company_id', this.getCurrentOrganizationName())
      } catch (e) {
      }
    }
  }

  setHubSpot() {
    const user: UserSettingsResponseModel = this.currentSettings.value;
    return this.http.post(
      `${this.envService.env.hubspot.url}`,
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      }
    ).subscribe((response: { token: string }) => {

        window['hsConversationsSettings'] = {
          identificationEmail: user.email,
          identificationToken: response.token
        };

        if(window['HubSpotConversations'] !== undefined){
          window['HubSpotConversations'].widget.load();
        }
        
    });
  }

  private checkLocalStorageVersion() {
    if (!this.isDifferentLocalstorageVersion()) {
      return;
    }
    this.clearLocalstorageVersion();
  }

  private isDifferentLocalstorageVersion() {
    return localStorage.getItem('localStorageVersion') !== this.envService.env.localStorageVersion;
  }

  private clearLocalstorageVersion() {
    localStorage.clear();
    this.setLocalStorageVersionFromEnv();
  }

  private setLocalStorageVersionFromEnv() {
    localStorage.setItem('localStorageVersion', this.envService.env.localStorageVersion);
  }

  private signKey(msg: string, clientKey: string) {
    return crypto.HmacSHA256(msg, clientKey).toString(crypto.enc.Hex);
  }

}

export interface IUser {
  access_token: string;
  email: string;
  expires_in: number;
  externalId: string;
  firstName: string;
  lastName: string;
  organizations: {
    externalId: string;
    name: string;
    industryTypeId: string;
  }[];
  phoneNumber: string;
  position: string;
  profile: {
    IsOwner: string;
    LastLoginDate: string;
    Role: string;
    ReportingAvailable: string
    TenantExternalId: string;
    TenantRoles: string;
    OnBatchImpersonation: string;
    OnBatchImpersonationUserId: string;
    amr: string[];
    aud: string[];
    auth_time: number;
    client_id: string;
    exp: number;
    idp: string;
    iss: string;
    nbf: number;
    scope: string[];
    sub: string;
  };
  profilePicture: string;
  refresh_token: string;
  settings: UserSettingsResponseModel;
  token_type: string;
}

export interface IFormattingSettings {
  timezone?: string | null;
  dateFormat?: string | null;
  timeFormat?: string | null;
  measurementSystem?: FormattingResponseModelMeasurementSystem | null;
  costPriceFormat?: CostPriceFormatResponseModel | null;
  costPricePrecision?: number | null;
  amountsPrecision?: number | null;
  alcoholContent?: ManufacturingResponseModelAlcoholContent | null;
  pureAlcohol?: ManufacturingResponseModelPureAlcohol | null;
}
