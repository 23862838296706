import * as moment from 'moment';
import {pad} from '../../../shared/utils';
import {DurationInputValue, TimerInputValue} from '../../../shared/models';
import {
  ICurrentProcess,
  ICurrentProcessVesselInfo,
  IProcessAttribute,
  IProcessMergeTransfer,
  IProcessRegularTransfer,
  IProcessSourceBranchAttribute,
  IProcessSplitTransfer,
  IProcessTargetBranchAttribute
} from '../../../shared/interfaces/process.interfaces';
import {
  AddRawMaterialAttributeRequest,
  AlcoholContentAttributeRequest,
  AlcoholSourceAttributeReadingRequest,
  AlcoholSourceAttributeRequest,
  AlcoholSourceAttributeRequestContainerTypeId,
  AmountAttributeRequest,
  AttributeRequest,
  BaseBatchAttributeRequest,
  BatchAttributeReadingRequest,
  BatchProcessAttributeResponse,
  BatchProcessAttributeResponseAllowedAmountTypeId,
  BatchProcessAttributeResponseAttributeTypeId,
  BatchProcessAttributeResponseBatchSourceType,
  CustomAttributeRequestOfDateTime,
  CustomAttributeRequestOfDecimal,
  CustomAttributeRequestOfInt32,
  CustomAttributeRequestOfString,
  CutTypeAttributeRequest,
  CutTypeAttributeRequestCutTypeId,
  FinalGravityAttributeRequest,
  FinalProofAttributeRequest,
  FinalVolumeAttributeRequest,
  FinishedGoodPackagingAttributeRequest,
  GravityAttributeRequest,
  KindOfSpiritAttributeRequest,
  LossVolumeAttributeRequest,
  ManufacturingItemBatchesLookUpResponseModelMeasurementTypeId,
  MasterItemResponseModelMasterItemTechnicalTypeId,
  MasterItemType,
  MaterialAttributeResponse,
  MaterialResponse,
  MeasurementRequest,
  MeasurementResponse,
  MergeAttributeRequest,
  MergeAttributeResponse,
  NameExternalIdModel,
  PhValueAttributeRequest,
  ProcessTypeId,
  PureAlcoholAttributeRequest,
  SemiFinishedGoodPackagingAttributeRequest,
  SemiFinishedGoodsAttributeRequest,
  SemiFinishedGoodsAttributeRequestBatchSourceType,
  SingleMaterialRequest,
  SingleSemiFinishedGoodAttributeRequest,
  SingleSemiFinishedGoodAttributeResponse,
  SourceBranchAttributeRequest,
  SourceBranchAttributeResponse,
  SplitAttributeRequest,
  SplitAttributeResponse,
  TareWeightAttributeRequest,
  TargetBranchAttributeRequest,
  TargetBranchAttributeResponse,
  TemperatureBatchAttributeRequest,
  TransferAttributeRequest,
  TransferAttributeResponse,
  WaterAttributeReadingRequest,
  WaterAttributeRequest,
  AddWipMaterialRequest,
  WipSourceVesselAttributeRequest,
  WipMaterialAttributeResponse,
  BatchProcessResponse,
  SingleSemiFinishedGoodAttributeRequestContainerTypeId,
} from '../../../core/services/Manufacturing';
import {IMaterialUnit, IMeasurementUnit} from '../../../shared/interfaces/unit.interfaces';
import {IAttributeReading} from '../../product-flows/product-flows-interfaces';
import {CutType, EProcessAttributeType} from '../../../shared/manufacturing.enum';
import {AttributeRequestAllowedAmountType} from 'app/core/services/Admin';
import {ValidatorFn, Validators} from '@angular/forms';

export function isNgSelectOption(element: HTMLElement): boolean {
  if (!element.classList) {
    return false;
  }

  return element.classList.contains('ng-option') || element.classList.contains('ng-option-label');
}

export function parseProcessDuration(duration: number): string {
  let durationMoment = moment.duration(duration, 'ms');
  let days = 0, hours = 0, minutes = 0;

  if (durationMoment.asDays() > 1) {
    days = Math.floor(Math.min(durationMoment.asDays(), 99));
    durationMoment = durationMoment.subtract(days, 'days');
  }

  if (durationMoment.asHours() > 1) {
    hours = Math.floor(Math.min(durationMoment.asHours(), 99));
    durationMoment = durationMoment.subtract(hours, 'hours');
  }

  if (durationMoment.asMinutes() > 0) {
    minutes = Math.floor(Math.min(durationMoment.asMinutes(), 99));
  }

  return formatDuration({days, hours, minutes});
}

export function durationInputToMs(data: DurationInputValue): number {
  const duration = moment.duration()
    .add(data.days, 'days')
    .add(data.hours, 'hours')
    .add(data.minutes, 'minutes');
  return duration.asMilliseconds();
}

export function formatDuration(duration: DurationInputValue): string {
  const days =    pad(duration.days);
  const hours =   pad(duration.hours);
  const minutes = pad(duration.minutes);
  return `${days}d:${hours}h:${minutes}m`;
}

const setWeightAttribute = (attribute: IProcessAttribute): number => {
  if (attribute.allowedAmountTypeId === AttributeRequestAllowedAmountType.WeightOnly) {
    return attribute.weight ? attribute.weight['value'] : null;
  } else {
    return null;
  }
};

const setVolumeAttribute = (attribute: IProcessAttribute): number => {
  if ([AttributeRequestAllowedAmountType.VolumeOnly, AttributeRequestAllowedAmountType.Any].includes(attribute.allowedAmountTypeId as AttributeRequestAllowedAmountType)) {
    return attribute.volume ? attribute.volume['value'] : null;
  } else {
    return null;
  }
};

export function mapAttribute(attr: IProcessAttribute, isManual: boolean = true, branchId?: string): AttributeRequest {
  const getMeasurementRequest = (x: IMeasurementUnit | MeasurementResponse | number): MeasurementRequest | null => {
    if (typeof x === 'number') {
      return new MeasurementRequest({
        value: x,
      });
    }

    if (
      !x || (x && x.value === null)
      || !Object.entries(x).length
      || (x && (!x.unitOfMeasurementExternalId
            || (x.unitOfMeasurementExternalId && !x.unitOfMeasurementExternalId.length)
          )
        )
    ) {
      return null;
    } else {
      return new MeasurementRequest({
        value: x.value,
        unitOfMeasurementExternalId: x.unitOfMeasurementExternalId
      });
    }
  };

  const baseBatch = new BaseBatchAttributeRequest({
    name: attr.name,
    canBeModifiedByWorker: attr.canBeModifiedByWorker,
    readings: attr.readings
      ? attr.readings.map((reading: IAttributeReading) => new BatchAttributeReadingRequest({
        date: reading.date,
        value: reading.value
      }))
      : null,
    industryProcessAttributeExternalId: attr.industryProcessAttributeExternalId,
    canBeMonitored: attr.canBeMonitored,
    duration: attr.duration,
  });

  switch (attr.attributeType) {
    case EProcessAttributeType.Amount:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        amountAttribute: new AmountAttributeRequest({
          volume: setVolumeAttribute(attr),
          weight: setWeightAttribute(attr)
        })
      });
    case EProcessAttributeType.FinalVolume:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        finalVolumeAttribute: new FinalVolumeAttributeRequest({
          volume: getMeasurementRequest(attr.finalVolume),
        })
      });
    case EProcessAttributeType.LossVolume:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        lossVolumeAttribute: new LossVolumeAttributeRequest({
          volume: getMeasurementRequest(attr.lossVolume),
        })
      });
    case EProcessAttributeType.TareWeight:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        tareWeightAttribute: new TareWeightAttributeRequest({
          weight: getMeasurementRequest(attr.tareWeight),
        })
      });
    case EProcessAttributeType.BatchTemperature:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        temperatureAttribute: new TemperatureBatchAttributeRequest({
          temperature: getMeasurementRequest(attr.temperature)
        })
      });
    case EProcessAttributeType.Materials:
      if (!attr.materials) {
        return new AttributeRequest({
          baseBatchAttribute: baseBatch
        });
      }
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        addRawMaterialAttribute: new AddRawMaterialAttributeRequest({
          materialRequests:  (isManual ? attr.materials : attr.batchMaterials).map((material: IMaterialUnit) => new SingleMaterialRequest({
            quantity: getMeasurementRequest(material.quantity),
            density: material.density ? getMeasurementRequest(material.density) : null,
            masterItemExternalId: material.masterItemExternalId,
            lotId: material.lotId,
            facilityExternalId: material.facilityExternalId,
            purchaseUnitPrice: material.purchaseUnitPrice,
            locationExternalId: material.location ? material.location.externalId : null,
          }))
        })
      });
    case EProcessAttributeType.CustomDecimal:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        customDecimalAttribute: new CustomAttributeRequestOfDecimal({
          value: attr.customDecimal || 0
        })
      });
    case EProcessAttributeType.CustomInteger:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        customIntegerAttribute: new CustomAttributeRequestOfInt32({
          value: attr.customInteger || 0
        })
      });
    case EProcessAttributeType.CustomText:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        customTextAttribute: new CustomAttributeRequestOfString({
          value: attr.customText
        })
      });
    case EProcessAttributeType.CustomDate:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        customDateAttribute: new CustomAttributeRequestOfDateTime({
          value: attr.customDate
        })
      });
    case EProcessAttributeType.AlcoholContent:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        alcoholContentAttribute: new AlcoholContentAttributeRequest({
          alcoholContent: getMeasurementRequest(attr.alcoholContent)
        })
      });
    case EProcessAttributeType.FinalProof:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        finalProofAttribute: new FinalProofAttributeRequest({
          alcoholContent: getMeasurementRequest(attr.alcoholContent)
        })
      });
    case EProcessAttributeType.PureAlcohol:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        pureAlcoholAttribute: new PureAlcoholAttributeRequest({
          pureAlcohol: getMeasurementRequest(attr.pureAlcohol)
        })
      });
    case EProcessAttributeType.KindOfSpirits:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        kindOfSpiritAttribute: new KindOfSpiritAttributeRequest({
          spiritItemExternalId: attr.spiritItem ? attr.spiritItem.externalId : null,
          customKindOfSpiritExternalId: attr.spiritItem ? attr.spiritItem.customKindOfSpiritExternalId : null,
        })
      });
    case EProcessAttributeType.CutType:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        cutTypeAttribute: new CutTypeAttributeRequest({
          cutTypeId: <CutTypeAttributeRequestCutTypeId><unknown>attr.cutTypeId
        })
      });
    case EProcessAttributeType.Gravity:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        gravityAttribute: new GravityAttributeRequest({
          gravity: typeof attr.gravity === 'number' ? attr.gravity : attr.gravity.value
        })
      });
    case EProcessAttributeType.FinalGravity:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        finalGravityAttribute: new FinalGravityAttributeRequest({
          gravity: typeof attr.finalGravity === 'number' ? attr.finalGravity : attr.finalGravity.value
        })
      });
    case EProcessAttributeType.BatchPhValue:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        phValueAttribute: new PhValueAttributeRequest({
          phValue: attr.phValue
        })
      });
    case EProcessAttributeType.SemiFinishedGoodsPackaging:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        semiFinishedGoodPackagingAttribute: new SemiFinishedGoodPackagingAttributeRequest({
          isVesselEmpty: attr.isVesselEmpty,
          fillByWeight: attr.fillByWeight,
          name: attr.name,
          semiFinishedGoodsExternalId: attr.semiFinishedGoodsExternalId
        })
      });
    case EProcessAttributeType.FinishedGoodsPackaging:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        finishedGoodPackagingAttribute: new FinishedGoodPackagingAttributeRequest({
          isVesselEmpty: attr.isVesselEmpty,
          fillByWeight: attr.fillByWeight,
          name: attr.name,
          packagingLineExternalId: attr.packagingLine ? attr.packagingLine.externalId : null
        })
      });
    case EProcessAttributeType.SemiFinishedGoods:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        semiFinishedGoodsAttribute: new SemiFinishedGoodsAttributeRequest({
          batchSourceType: SemiFinishedGoodsAttributeRequestBatchSourceType[attr.batchSourceType],
          totalAmount: getMeasurementRequest(attr.totalAmount),
          totalAlcoholContent: getMeasurementRequest(attr.totalAlcoholContent),
          groupUnits: attr.groupUnits ? attr.groupUnits : false,
          semiFinishedGoods: attr.batchSemiFinishedGoods
            .map((semiFinishedGoodItem:  SingleSemiFinishedGoodAttributeResponse) => new SingleSemiFinishedGoodAttributeRequest({
              proof: new MeasurementRequest({
                unitOfMeasurementExternalId: semiFinishedGoodItem.proof.unitOfMeasurementExternalId,
                value: semiFinishedGoodItem.proof.value
              }),
              originalQuantity: getMeasurementRequest(semiFinishedGoodItem.originalQuantity),
              itemBatchExternalId: semiFinishedGoodItem.externalId,
              masterItemExternalId: semiFinishedGoodItem.masterItem.externalId,
              facilityExternalId: semiFinishedGoodItem.facility.externalId,
              locationExternalId: semiFinishedGoodItem.location ? semiFinishedGoodItem.location.externalId : null,
              containerExternalId: semiFinishedGoodItem.container ? semiFinishedGoodItem.container.externalId : null,
              equipmentExternalId: semiFinishedGoodItem.equipment ? semiFinishedGoodItem.equipment.externalId : null,
              amount: semiFinishedGoodItem.quantity.value,
              lotId: semiFinishedGoodItem.lotId,
              purchaseUnitPrice: semiFinishedGoodItem.purchaseUnitPrice,
              unitId: semiFinishedGoodItem.unitId || '',
              kindOfSpiritExternalId: semiFinishedGoodItem.kindOfSpirit ? semiFinishedGoodItem.kindOfSpirit.externalId : null,
              isEmpty: semiFinishedGoodItem.isEmpty ? semiFinishedGoodItem.isEmpty : false,
              dateFilled: semiFinishedGoodItem.dateFilled,
              containerTypeId: semiFinishedGoodItem.container && SingleSemiFinishedGoodAttributeRequestContainerTypeId[semiFinishedGoodItem.container.containerTypeId],
              spiritAgeInMilliseconds: semiFinishedGoodItem.spiritAgeInMilliseconds,
              customKindOfSpiritExternalId: semiFinishedGoodItem.kindOfSpirit ? semiFinishedGoodItem.kindOfSpirit.customKindOfSpiritExternalId : null,
            })),
        })
      });
    case EProcessAttributeType.Water:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        waterAttribute: new WaterAttributeRequest({
          waterReadings: attr.water.waterReadings.map((reading) => new WaterAttributeReadingRequest({
            date: reading.date,
            weight: getMeasurementRequest(reading.weight),
            alcoholContent: getMeasurementRequest(reading.alcoholContent),
            temperature: getMeasurementRequest(reading.temperature),
            volume: getMeasurementRequest(reading.volume),
          })),
          materials: attr.water.materials.map((material) => new SingleMaterialRequest({
            quantity: getMeasurementRequest(material.quantity),
            masterItemExternalId: material.masterItemExternalId,
            facilityExternalId: material.facilityExternalId,
          })),
          targetAlcoholContent: getMeasurementRequest(attr.water.targetAlcoholContent),
        })
      });
    case EProcessAttributeType.AlcoholSource:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        alcoholSourceAttribute: new AlcoholSourceAttributeRequest({
          equipmentExternalId: attr.alcoholSource.equipmentExternalId,
          equipmentId: attr.alcoholSource.equipmentId,
          equipmentName: attr.alcoholSource.equipmentName,
          masterItemExternalId: attr.alcoholSource.masterItemExternalId,
          containerExternalId: attr.alcoholSource.containerExternalId,
          lotId: attr.alcoholSource.lotId,
          containerId: attr.alcoholSource.containerId,
          containerTypeId: AlcoholSourceAttributeRequestContainerTypeId[attr.alcoholSource.containerTypeId],
          facilityExternalId: attr.alcoholSource.facility.externalId,
          locationExternalId: attr.alcoholSource.location ? attr.alcoholSource.location.externalId : null,
          kindOfSpiritExternalId: attr.alcoholSource.kindOfSpirit ? attr.alcoholSource.kindOfSpirit.externalId : null,
          alcoholContent: getMeasurementRequest(attr.alcoholSource.alcoholContent),
          temperature: getMeasurementRequest(attr.alcoholSource.temperature),
          totalVolume: getMeasurementRequest(attr.alcoholSource.totalVolume),
          targetAlcoholContent: getMeasurementRequest(attr.alcoholSource.targetAlcoholContent),
          sourceReadings: attr.alcoholSource.sourceReadings.map((reading) => new AlcoholSourceAttributeReadingRequest({
            date: reading.date,
            amount: getMeasurementRequest(reading.amount),
            alcoholContent: getMeasurementRequest(reading.alcoholContent),
          })),
          unitId: attr.alcoholSource.unitId,
        })
      });
    case EProcessAttributeType.WipMaterials:
      return new AttributeRequest({
        baseBatchAttribute: baseBatch,
        addWipMaterialAttribute: new AddWipMaterialRequest({
          sourceVessels: attr.batchWipMaterials.map((source: WipMaterialAttributeResponse) => new WipSourceVesselAttributeRequest({
            isEmpty: source.isEmpty,
            branchExternalId: source.branchExternalId,
            vesselExternalId: source.vessel.externalId,
            batchExternalId: source.batch ? source.batch.externalId : null,
            kindOfSpiritExternalId: source.kindOfSpirit ? source.kindOfSpirit.externalId : null,
            alcoholContent: getMeasurementRequest(source.alcoholContent),
            amount: getMeasurementRequest(source.amount),
            originalVolume: getMeasurementRequest(source.originalVolume),
            customKindOfSpiritExternalId: source.kindOfSpirit ? source.kindOfSpirit.customKindOfSpiritExternalId : null,
          }))
        })
      });
  }

  const mapSourceBranch = (sourceBranch: IProcessSourceBranchAttribute) =>
  new SourceBranchAttributeRequest({
    isEmpty: sourceBranch.isEmpty,
      fillByWeight: sourceBranch.fillByWeight,
      externalId: sourceBranch.externalId || branchId,
      batchExternalId: sourceBranch.batchExternalId,
      vesselExternalId: sourceBranch.vesselExternalId,
      volume: getMeasurementRequest(sourceBranch.volume),
      weight: getMeasurementRequest(sourceBranch.weight),
      alcoholContent: getMeasurementRequest(sourceBranch.alcoholContent),
      amountTransferred: sourceBranch.amountTransferred ? getMeasurementRequest(sourceBranch.amountTransferred) : null,
      temperature: getMeasurementRequest(sourceBranch.temperature),
  });

  const mapTargetBranch = (targetBranch: IProcessTargetBranchAttribute) =>
    new TargetBranchAttributeRequest({
      externalId: targetBranch.externalId || (attr.transfer ? branchId : null),
      vesselExternalId: targetBranch.vesselExternalId,
      volume: getMeasurementRequest(targetBranch.volume),
      weight: getMeasurementRequest(targetBranch.weight),
      amountTransferred: getMeasurementRequest(targetBranch.amountTransferred),
      alcoholContent: getMeasurementRequest(targetBranch.alcoholContent),
      temperature: getMeasurementRequest(targetBranch.temperature),
      fillByWeight: targetBranch.fillByWeight || false,
      branchName: targetBranch.branchName || '',
    });

  // transfers don't have `attributeTypeId`
  if (attr.transfer) {
    return new AttributeRequest({
      baseBatchAttribute: null,
      transferAttribute: new TransferAttributeRequest({
        sourceBranch: mapSourceBranch(attr.transfer.sourceBranch),
        targetBranches: [mapTargetBranch(attr.transfer.targetBranch)],
      })
    });
  } else if (attr.splitTransfer) {
    return new AttributeRequest({
      baseBatchAttribute: null,
      splitAttribute: new SplitAttributeRequest({
        sourceBranch: mapSourceBranch(attr.splitTransfer.sourceBranch),
        targetBranches: attr.splitTransfer.targetBranch.map(mapTargetBranch),
      })
    });
  } else if (attr.mergeTransfer) {
    return new AttributeRequest({
      baseBatchAttribute: null,
      mergeAttribute: new MergeAttributeRequest({
        sourceBranches: attr.mergeTransfer.sourceBranch.map(mapSourceBranch),
        targetBranch: mapTargetBranch(attr.mergeTransfer.targetBranch),
      })
    });
  }

  throw new Error(`Unsupported attribute: ${attr.attributeType}`);
}

export function mapProcess(process: BatchProcessResponse, manualBatch: boolean = false) {
  const mapMeasurement = (attr: BatchProcessAttributeResponse, name: string): IMeasurementUnit => ({
    unitOfMeasurementExternalId: attr[name] ? attr[name].unitOfMeasurementExternalId : null,
    unitOfMeasurementName: attr[name] ? attr[name].unitOfMeasurementName : null,
    value: attr[name] ? attr[name].value : null
  });

  const mapMaterials = (materials: MaterialResponse[]): IMaterialUnit[] =>
    materials.map(material => ({
      name: material.masterItemName,
      masterItemExternalId: material.masterItemExternalId,
      masterItemTechnicalTypeId: <MasterItemResponseModelMasterItemTechnicalTypeId><unknown>material.masterItemTechnicalTypeId,
      quantity: mapMeasurement(material, 'quantity')
    }));

  const mapBatchMaterials = (materials: MaterialAttributeResponse[]): IMaterialUnit[] =>
    materials.map(material => (<IMaterialUnit>{
      name: material.masterItemName,
      masterItemExternalId: material.masterItemExternalId,
      quantity: mapMeasurement(material, 'quantity'),
      density: material.density ? mapMeasurement(material, 'density') : null,
      lotId: material.lotId,
      facilityName: material.facilityName,
      facilityExternalId: material.facilityExternalId,
      purchaseUnitPrice: material.purchaseUnitPrice,
      measurementType: material.density
        ? ManufacturingItemBatchesLookUpResponseModelMeasurementTypeId.Weight
        : ManufacturingItemBatchesLookUpResponseModelMeasurementTypeId.Volume,
      masterItemTechnicalTypeId: <MasterItemResponseModelMasterItemTechnicalTypeId><unknown>material.masterItemTechnicalTypeId,
      masterItemTypeId: <MasterItemType><unknown>material.masterItemTypeId,
      quantityOnHand: material.quantityOnHand,
      quantityOnHandUnitOfMeasurement: material.quantityOnHandUnitOfMeasurement,
      location: material.locationName && material.locationExternalId
        ? new NameExternalIdModel({externalId: material.locationExternalId, name: material.locationName})
        : null,
    }));

  const mapBranchName = (branch: TargetBranchAttributeResponse): string => {
    return branch.branchName ? branch.branchName : null;
  };

  const mapBranch = (branch: SourceBranchAttributeResponse | TargetBranchAttributeResponse): IProcessSourceBranchAttribute | IProcessTargetBranchAttribute => {
    const attr: IProcessSourceBranchAttribute | IProcessTargetBranchAttribute = {
      externalId: branch.externalId,
      vesselExternalId: branch.vesselExternalId,
      fillByWeight: branch.fillByWeight,
      volume: mapMeasurement(branch, 'volume'),
      weight: mapMeasurement(branch, 'weight'),
      alcoholContent: mapMeasurement(branch, 'alcoholContent'),
      branchName: mapBranchName(branch),
      temperature: mapMeasurement(branch, 'temperature'),
      isBranchFinished: (branch as TargetBranchAttributeResponse).isBranchFinished,
      hasAnyProcessSaved: (branch as TargetBranchAttributeResponse).hasAnyProcessSaved,
    };

    if (branch instanceof SourceBranchAttributeResponse) {
      const sourceAttr = attr as IProcessSourceBranchAttribute;
      sourceAttr.isEmpty = branch.isEmpty;
      sourceAttr.fillByWeight = branch.fillByWeight;
      sourceAttr.batchExternalId = branch.batchExternalId;
      sourceAttr.amountTransferred = branch.amountTransferred ? mapMeasurement(branch, 'amountTransferred') : null;
    } else {
      const targetAttr = attr as IProcessTargetBranchAttribute;
      targetAttr.amountTransferred = mapMeasurement(branch, 'amountTransferred');
    }

    return attr;
  };

  const mapTransfer = (transfer: TransferAttributeResponse): IProcessRegularTransfer => {
    return <IProcessRegularTransfer>{
      sourceBranch: mapBranch(transfer.sourceBranch),
      targetBranch: mapBranch(transfer.targetBranches[0])
    };
  };

  const mapSplit = (transfer: SplitAttributeResponse): IProcessSplitTransfer => {
    return <IProcessSplitTransfer>{
      sourceBranch: mapBranch(transfer.sourceBranch),
      targetBranch: transfer.targetBranches.map(mapBranch),
    };
  };

  const mapMerge = (transfer: MergeAttributeResponse): IProcessMergeTransfer => {
    return <IProcessMergeTransfer>{
      sourceBranch: transfer.sourceBranches.map(mapBranch),
      targetBranch: mapBranch(transfer.targetBranch),
    };
  };

  const mapAttributeResponse = (attr: BatchProcessAttributeResponse): IProcessAttribute => {
    const attribute = <IProcessAttribute>{
      externalId: attr.externalId,
      orderNumber: attr.orderNumber,
      name: attr.name,
      isMandatory: attr.isMandatory,
      attributeType: <EProcessAttributeType><unknown>attr.attributeTypeId,
      gravity: mapMeasurement(attr, 'gravity'),
      finalGravity: mapMeasurement(attr, 'finalGravity'),
      industryProcessAttributeExternalId: attr.industryProcessAttributeExternalId,
      canBeModifiedByWorker: attr.canBeModifiedByWorker,
      canBeMonitored: attr.canBeMonitored,
      pureAlcohol: mapMeasurement(attr, 'pureAlcohol'),
      quantity: mapMeasurement(attr, 'quantity'),
      alcoholContent: mapMeasurement(attr, 'alcoholContent'),
      weight: mapMeasurement(attr, 'weight'),
      volume: mapMeasurement(attr, 'volume'),
      temperature: mapMeasurement(attr, 'temperature'),
      finalVolume: mapMeasurement(attr, 'finalVolume'),
      tareWeight: mapMeasurement(attr, 'tareWeight'),
      lossVolume: mapMeasurement(attr, 'lossVolume'),
      cutTypeId: <CutType><unknown>attr.cutTypeId,
      phValue: attr.phValue,
      materials: mapMaterials(attr.materials),
      batchMaterials: mapBatchMaterials(attr.batchMaterials),
      batchWipMaterials: attr.batchWipMaterials,
      customText: attr.customText,
      customDate: attr.customDate,
      customInteger: attr.customInteger,
      customDecimal: attr.customDecimal,
      transfer: attr.transfer ? mapTransfer(attr.transfer) : null,
      splitTransfer: attr.splitTransfer ? mapSplit(attr.splitTransfer) : null,
      mergeTransfer: attr.mergeTransfer ? mapMerge(attr.mergeTransfer) : null,
      allowedAmountTypeId: AttributeRequestAllowedAmountType[attr.allowedAmountTypeId],
      allowAny: attr.allowedAmountTypeId === BatchProcessAttributeResponseAllowedAmountTypeId.Any,
      semiFinishedGoodsExternalId: attr.semiFinishedGoodsExternalId,
      fillByWeight: attr.fillByWeight,
      isVesselEmpty: attr.isVesselEmpty,
      packagingLine: attr.packagingLine,
      spiritItem: attr.spiritItem,
      readings: attr.readings,
      semiFinishedGoods: attr.semiFinishedGoods,
      batchSourceType: attr.batchSourceType,
      totalAmount: mapMeasurement(attr, 'totalAmount'),
      totalAlcoholContent: mapMeasurement(attr, 'totalAlcoholContent'),
      finishedGoods: attr.finishedGoods,
      batchSemiFinishedGoods: attr.batchSemiFinishedGoods,
      batchFinishedGoods: attr.batchFinishedGoods,
      water: attr.waterAttribute ? {
        targetAlcoholContent: attr.waterAttribute.targetAlcoholContent,
        materials: attr.waterAttribute.materials.map(material => ({
          masterItemExternalId: material.masterItemExternalId,
          quantity: material.quantity,
          facilityExternalId: material.facilityExternalId,
        })),
        waterReadings: attr.waterAttribute.waterReadings,
      } : null,
      alcoholSource: attr.alcoholSourceAttribute ? {
        ...attr.alcoholSourceAttribute,
      } : null,
      groupUnits: attr.groupUnits
    };

    if (attr.attributeTypeId === BatchProcessAttributeResponseAttributeTypeId.Materials) {
      if (manualBatch) {
        attribute.materials = attribute.batchMaterials;
      }
    }
    return attribute;
  };
  return <ICurrentProcess>{
    externalId: process.externalId,
    industryProcessExternalId: process.industryProcessExternalId,
    isCompleted: false,
    isOptional: process.isOptional,
    isSkipped: process.isSkipped,
    isDraft: process.isDraft,
    name: process.name,
    startDate: process.startDate,
    endDate: process.endDate,
    duration: process.duration,
    isAdHoc: process.isAdHoc,
    vesselInfoBefore: <ICurrentProcessVesselInfo>process.vesselInfoBefore,
    vesselInfoAfter: <ICurrentProcessVesselInfo>process.vesselInfoAfter,
    processType: <ProcessTypeId><unknown>process.processType,
    vesselAmountOverride: process.vesselAmountOverride,
    attributes: process.attributes.map(mapAttributeResponse),
    isTemperatureInputRequired: process.isTemperatureInputRequired,
    isAlcoholContentInputRequired: process.isAlcoholContentInputRequired,
    instructions: process.instructions,
    isTransferByWeightPossible: process.isTransferByWeightPossible,
    adHocProcessesAvailability: process.adHocProcessesAvailability,
    savedAsDraft: process.savedAsDraft,
    shouldAlcoholContentBePrefilled: process.shouldAlcoholContentBePrefilled,
  };
}

export function validateAlcoholContent(alcoholContent: IMeasurementUnit): boolean {
  if (!alcoholContent || alcoholContent.value === null || alcoholContent.value === undefined || !alcoholContent.unitOfMeasurementName) {
    return false;
  }

  const maxAlcoholContent = alcoholContent.unitOfMeasurementName === 'Proof' ? 200 : 100;
  return alcoholContent.value <= maxAlcoholContent;
}

export function getAlcoholContentValidators(uomName: string, isAlcoholRequired?: boolean): ValidatorFn[] {
  return [
    Validators.min(isAlcoholRequired ? 0.01 : 0),
    Validators.max(uomName === 'Proof' ? 200 : 100)
  ];
}
