import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], query?: string): any {
    return query ? value.filter((i: any) => i.name.toLowerCase().indexOf(query.toLowerCase()) > -1) : value;
  }

}
