/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/form-box/form-box.component.ngfactory";
import * as i2 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i3 from "../../core/factories/datePicker.factory";
import * as i4 from "@angular/forms";
import * as i5 from "../components/form-box/form-box.component";
import * as i6 from "../services/formats.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../components/avatar/avatar.component.ngfactory";
import * as i10 from "../components/avatar/avatar.component";
import * as i11 from "../components/svg-icon/svg-icon.component.ngfactory";
import * as i12 from "../components/svg-icon/svg-icon.component";
import * as i13 from "../pipes/split-camel-case.pipe";
import * as i14 from "../directives/click-outside.directive";
import * as i15 from "./header.component";
import * as i16 from "../../core/services/auth.service";
import * as i17 from "ngx-smart-modal";
import * as i18 from "../../core/services/subscription.service";
import * as i19 from "../services/mixpanel.service";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-form-box", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [4, "width", null]], [[null, "onInputChange"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("onInputChange" === en)) {
        var pd_2 = (_co.setTitle($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_FormBoxComponent_0, i1.RenderType_FormBoxComponent)), i0.ɵprd(5120, null, i2.OWL_DATE_TIME_FORMATS, i3.OWL_DATE_TIME_FORMAT_SETTINGS, []), i0.ɵdid(2, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), i0.ɵdid(5, 4833280, null, 0, i5.FormBoxComponent, [i6.FormatsService], { options: [0, "options"], formControlNameValue: [1, "formControlNameValue"], formGroup: [2, "formGroup"] }, { onInputChange: "onInputChange" })], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.formGroup; _ck(_v, 2, 0, currVal_8); var currVal_9 = _co.formBoxes.title; var currVal_10 = _co.formBoxes.title.field; var currVal_11 = _co.formGroup; _ck(_v, 5, 0, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; var currVal_7 = i0.ɵnov(_v, 5).width; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_HeaderComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "header__tabs-number"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.notifications; _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "header__title"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[2, 4]], 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i7.RouterLinkActive, [i7.Router, i0.ElementRef, i0.Renderer2, [2, i7.RouterLink], [2, i7.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i0.ɵdid(7, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); _ck(_v, 1, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 2, 0, currVal_3); var currVal_5 = (_v.parent.context.$implicit.notifications && (_v.parent.context.$implicit.notifications > 0)); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_HeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionService.getAccess(_v.context.$implicit.feature); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "header__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.subtitle; _ck(_v, 3, 0, currVal_1); }); }
function View_HeaderComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "header__tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_9)), i0.ɵdid(3, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HeaderComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p", [["class", "header__title header__title--flex"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "order-status__indicator"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "order-status order-status--header-title ", _v.context.$implicit.statusClassName, ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.status)); _ck(_v, 4, 0, currVal_2); }); }
function View_HeaderComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "header__tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_11)), i0.ɵdid(3, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "header__tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_10)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.links[0].subtitle && !_co.links[0].status); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.links[0].subtitle; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.links[0].status; _ck(_v, 6, 0, currVal_2); }, null); }
function View_HeaderComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-avatar", [["placeholderClass", "header__profile-gfx--no-image"]], null, null, null, i9.View_AvatarComponent_0, i9.RenderType_AvatarComponent)), i0.ɵdid(1, 49152, null, 0, i10.AvatarComponent, [], { user: [0, "user"], placeholderClass: [1, "placeholderClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = "header__profile-gfx--no-image"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "header__profile-gfx--no-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "p", [], null, null, null, null, null))], null, null); }
function View_HeaderComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "options-menu__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn--utility options-menu__option header__user-organization-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveOrganization(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn--utility options-menu__option header__user-organization-btn"; var currVal_1 = (_co.checkIfOrganizationActive(_v.context.$implicit.externalId) ? "header__user-organization--selected" : ""); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_HeaderComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "options-menu__item options-menu__item--has-sub"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "btn btn--utility options-menu__option header__user-organization"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "header__user-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["class", "header__user-position"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Switch companies"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "options-menu__submenu header__user-organization-submenu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_16)), i0.ɵdid(8, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.user == null) ? null : _co.user.organizations); _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeOrganization.name; _ck(_v, 3, 0, currVal_0); }); }
function View_HeaderComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "header__user-button--divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "options-menu__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "button", [["class", "btn options-menu__option header__user-button"], ["routerLink", "/account/settings"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "svg-icon", [["icon", "settings"]], null, null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(6, 114688, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Global settings "]))], function (_ck, _v) { var currVal_0 = "/account/settings"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "settings"; _ck(_v, 6, 0, currVal_1); }, null); }
function View_HeaderComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "options-menu__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "btn options-menu__option header__user-button"], ["routerLink", "/account/user-profile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "svg-icon", [["icon", "user"]], null, null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(4, 114688, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Personal preferences "]))], function (_ck, _v) { var currVal_0 = "/account/user-profile"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "user"; _ck(_v, 4, 0, currVal_1); }, null); }
function View_HeaderComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "options-menu__item"], ["routerLink", "/account/members/team/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "svg-icon", [["icon", "users"]], null, null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(4, 114688, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Users and roles "]))], function (_ck, _v) { var currVal_0 = "/account/members/team/"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "users"; _ck(_v, 4, 0, currVal_1); }, null); }
function View_HeaderComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "options-menu__item"], ["routerLink", "/account/billing/info/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "svg-icon", [["icon", "credit-card"]], null, null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(5, 114688, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Billing "])), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "options-menu__item"], ["routerLink", "/account/companies/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "svg-icon", [["icon", "organizations"]], null, null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(11, 114688, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Business units "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_20)), i0.ɵdid(14, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/account/billing/info/"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "credit-card"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "/account/companies/"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "organizations"; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.isFeatureAvailable.humanResources_EmployeeManagement; _ck(_v, 14, 0, currVal_4); }, null); }
function View_HeaderComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "header__user-button--divider"]], null, null, null, null, null))], null, null); }
function View_HeaderComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 25, "div", [["class", "options-menu table__options-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "options-menu__arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "options-menu__box"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_15)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_17)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_18)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_19)), i0.ɵdid(10, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_21)), i0.ɵdid(12, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 2, "div", [["class", "options-menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHelpCenter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" User Documentation "])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "div", [["class", "options-menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getFeedbackPortal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Feedback & Updates "])), (_l()(), i0.ɵeld(19, 0, null, null, 2, "div", [["class", "options-menu__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getEULA() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" End-User Policy "])), (_l()(), i0.ɵeld(22, 0, null, null, 0, "div", [["class", "header__user-button--divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 2, "div", [["class", "options-menu__item header__user-button--last"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "button", [["class", "btn options-menu__option header__user-button"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Log out "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isAdmin; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.isOwner && _co.isFeatureAvailable.misc_AccountSettings); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.isAdmin && _co.isFeatureAvailable.misc_PersonalPreferences); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isOwner; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.isAdmin; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i13.SplitCamelCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 21, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "header__logo"], ["routerLink", "/welcome"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "header__title header__title--edit-mode form__input-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i0.ɵdid(11, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 10, "div", [["class", "header__utilities"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 9, "div", [["click-outside", ""]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.closeMenu($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 212992, null, 0, i14.ClickOutsideDirective, [i0.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i0.ɵeld(15, 0, null, null, 7, "div", [["class", "header__profile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 4, "figure", [["class", "header__profile-gfx"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_12)), i0.ɵdid(18, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_13)), i0.ɵdid(20, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_14)), i0.ɵdid(22, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/welcome"; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.title && !_co.editableTitle); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.editableTitle; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.links; _ck(_v, 11, 0, currVal_5); _ck(_v, 14, 0); var currVal_6 = _co.user; _ck(_v, 18, 0, currVal_6); var currVal_7 = !_co.user; _ck(_v, 20, 0, currVal_7); var currVal_8 = _co.isMenuOpen; _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i15.HeaderComponent, [i16.AuthService, i17.NgxSmartModalService, i18.SubscriptionService, i19.MixpanelService, i4.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i0.ɵccf("app-header", i15.HeaderComponent, View_HeaderComponent_Host_0, { title: "title", links: "links", editableTitle: "editableTitle" }, { titleUpdated: "titleUpdated" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
