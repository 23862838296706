import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent {
  @Input() icon = 'help-tooltip';
  @Input() customIconClass: string;
  @Input() customTooltipClass: string;
  @Input() customArrowClass: string;
  @Input() text = '';
  @Input() customContent = false;

  showTooltip = false;

  constructor() {
  }

  toggleTooltip(): void {
    this.showTooltip = (this.text || this.customContent) ? !this.showTooltip : false;
  }

  openTooltip(event: any): void {
    if (!event.value) {
      this.showTooltip = !!(this.text || this.customContent);
    }
  }
}
