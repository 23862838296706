import {EdgeDataDefinition, EdgeDefinition, NodeDataDefinition, NodeDefinition} from 'cytoscape';
import {Dictionary} from '../../utils';
import {flatten} from 'lodash';
import {getEdgeId, getMergeNodeId, getNodeId, getSplitNodeId, getCrossTransferId} from './flow-graph.utils';
import {
  DstStageResponseModel,
  DstStageResponseModelFlowMapTransitionProcessType,
  FlowMapResponseModel, IncomingTransferResponseModel, OutgoingTransferResponseModel,
  ProcessInfoResponseModel,
  StageInfoResponseModel,
  StageNodeResponseModel
} from '../../../core/services/Manufacturing';
import {generateConstraints, updateMergeConstraints, constraintsForCrossTransfers} from './flow-graph-constraints';

export interface NodeOffset {
  nodeId: string;
  offset: string | number;
}

export interface AlignmentConstraint {
  axis: 'x' | 'y';
  offsets: NodeOffset[];
}

export interface GapConstraint {
  axis: 'x' | 'y';
  left: string;
  right: string;
  gap: number;
}

export interface Constraints {
  alignment: AlignmentConstraint[];
  gap: GapConstraint[];
}

export interface NodesEdgesConstraints {
  nodes: NodeDefinition[];
  edges: EdgeDefinition[];
  constraints: AlignmentConstraint[];
  gapConstraints: GapConstraint[];
}

interface Edge {
  source?: string;
  sourceBranch: string;
  target?: string;
  targetBranch?: string;
  type?: string;
}

interface Node {
  id: string;
  name?: string;
  equipmentName?: string;
  type: string;
  parent?: string;
  branchStartTime?: number;
  branchEndTime?: number;
  stageInfo?: StageInfoResponseModel;
  processesInfo?: ProcessInfoResponseModel[];
}

function getEdgeSrc(splitNodeId: string, mergeNodeId: string, src: StageNodeResponseModel, dst: DstStageResponseModel): string {
  if (mergeNodeId) {
    return src.branchExternalId;
  } else if (dst.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Transfer) {
    return src.stageInfo.externalId;
  }
  return splitNodeId || src.stageInfo.externalId;
}

function getEdgeDst(splitNodeId: string, mergeNodeId: string, dst: DstStageResponseModel): string {
  if (dst.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Merge) {
    return mergeNodeId;
  } else if (dst.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Transfer) {
    return dst.externalId;
  }
  return splitNodeId ? dst.branchExternalId : dst.externalId;
}

function getBranchParents(graph: FlowMapResponseModel): Dictionary<string> {
  const parents: Dictionary<string> = {};

  for (const vertex of graph.adjacencyList) {
    const branchingStages: DstStageResponseModel[] = vertex.dstStages
      .filter(stage => stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Split
      || stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Merge);

    for (const dstStage of branchingStages) {
      parents[dstStage.branchExternalId] = vertex.branchExternalId;
    }
  }

  return parents;
}

export type MergeConstraints = Dictionary<{
  alignment?: AlignmentConstraint[];
  gap?: GapConstraint[],
}>;

export function traverse(graph: FlowMapResponseModel): NodesEdgesConstraints {
  const nodes: NodeDefinition[] = [];
  const compoundNodes: Dictionary<NodeDefinition> = {};
  const mergeNodes: Dictionary<NodeDefinition> = {};
  const edges: EdgeDefinition[] = [];
  const alignConstraints: AlignmentConstraint[] = [];
  const gapConstraints: GapConstraint[] = [];
  const mergeConstraints: MergeConstraints = {};
  const branchParents: Dictionary<string> = getBranchParents(graph);

  const addNode = (n: Node) => {
    const id: string = n.parent && n.type !== 'split' ? getNodeId(n.parent, n.id) : getNodeId(n.id);
    const node: NodeDataDefinition = {id};
    const addProperty = (name: string) => n[name] ? node[name] = n[name] : {};
    addProperty('parent');
    addProperty('name');
    addProperty('equipmentName');
    addProperty('type');
    addProperty('processesInfo');
    addProperty('stageInfo');
    addProperty('branchStartTime');
    addProperty('branchEndTime');
    nodes.push({data: node});
  };
  const addCompoundNode = (branchExternalId: string, data: NodeDataDefinition) => compoundNodes[branchExternalId] = {data};
  const addMergeNode = (id: string, data: NodeDataDefinition) => mergeNodes[id] = {data};
  const addEdge = (e: Edge) => {
    const src: string = getNodeId(e.sourceBranch, e.source);
    const dst: string = getNodeId(e.targetBranch, e.target);
    const id: string = getEdgeId(src, dst);
    const edge: EdgeDataDefinition = {
      source: src,
      target: dst,
      id: id,
    };
    if (e.type) {
      edge.type = e.type;
    }

    edges.push({data: edge});
  };
  const addConstraint = (data: Constraints | null) => {
    if (!data) {
      return;
    }

    alignConstraints.push(...data.alignment);
    gapConstraints.push(...data.gap);
  };

  const getGroupId = (branchId: string) => `${branchId}_group`;

  function assertParentExists(vertex: StageNodeResponseModel) {
    if (!(vertex.branchExternalId in compoundNodes)) {
      const groupId: string = getGroupId(vertex.branchExternalId);
      const groupParent: string = branchParents[vertex.branchExternalId];

      addCompoundNode(groupId, {
        id: groupId,
        type: 'group',
        parent: groupParent ? getGroupId(groupParent) : undefined,
      });

      addCompoundNode(vertex.branchExternalId, {
        id: vertex.branchExternalId,
        name: vertex.branchName,
        type: 'cluster',
        startTime: vertex.branchStartTime,
        endTime: vertex.branchEndTime,
        parent: groupId,
        operationAccount: vertex.stageInfo.operationAccountType,
      });
    }
  }

  function handleSplit(vertex: StageNodeResponseModel, splitNodeId: string, hasTransferStage: boolean) {
    const groupId = getGroupId(vertex.branchExternalId);

    addNode({
      id: getNodeId(vertex.branchExternalId, splitNodeId),
      type: 'split',
      parent: groupId,
    });

    if (hasTransferStage) {
      addEdge({
        sourceBranch: vertex.branchExternalId,
        source: vertex.stageInfo.externalId,
        targetBranch: getNodeId(vertex.branchExternalId, splitNodeId),
      });
    } else {
      addEdge({
        sourceBranch: vertex.branchExternalId,
        targetBranch: getNodeId(vertex.branchExternalId, splitNodeId),
      });
    }
  }

  function handleMerge(mergeNodeId: string, mergeStage: DstStageResponseModel) {
    addMergeNode(mergeNodeId, {
      id: getNodeId(mergeStage.branchExternalId, mergeNodeId),
      type: 'merge'
    });

    addEdge({
      sourceBranch: getNodeId(mergeStage.branchExternalId, mergeNodeId),
      targetBranch: mergeStage.branchExternalId,
    });
  }

  function handleEdge(src: StageNodeResponseModel, dst: DstStageResponseModel, splitNodeId: string, mergeNodeId: string) {
    const srcId = getEdgeSrc(splitNodeId, mergeNodeId, src, dst);
    const dstId = getEdgeDst(splitNodeId, mergeNodeId, dst);
    const edge: Edge = {
      sourceBranch: src.branchExternalId,
      targetBranch: dst.branchExternalId,
    };
    if (srcId !== src.branchExternalId) {
      edge.source = srcId;
    }
    if (dstId !== dst.branchExternalId) {
      edge.target = dstId;
    }

    if (src.branchExternalId === dst.branchExternalId) {
      edge.type = 'same-branch';
    }

    addEdge(edge);
  }

  function handleCrossTransfer(stage: StageNodeResponseModel, transfer: IncomingTransferResponseModel | OutgoingTransferResponseModel) {
    addNode({
      id: getCrossTransferId(transfer),
      name: transfer.batchId,
      equipmentName: transfer.vesselName,
      type: 'cross-transfer',
    });

    if (transfer instanceof IncomingTransferResponseModel) {
      addEdge({
        sourceBranch: getCrossTransferId(transfer),
        targetBranch: stage.branchExternalId,
        target: stage.stageInfo.externalId,
        type: 'cross-transfer',
      });
    } else {
      addEdge({
        sourceBranch: stage.branchExternalId,
        source: stage.stageInfo.externalId,
        targetBranch: getCrossTransferId(transfer),
        type: 'cross-transfer',
      });
    }
  }

  for (const vertex of graph.adjacencyList) {
    assertParentExists(vertex);

    addNode({
      id: vertex.stageInfo.externalId,
      name: vertex.stageInfo.name,
      type: 'stage',
      parent: vertex.branchExternalId,
      branchStartTime: vertex.branchStartTime,
      branchEndTime: vertex.branchEndTime,
      processesInfo: vertex.processesInfo,
      stageInfo: vertex.stageInfo,
    });

    const hasTransferStage: boolean = !!vertex.dstStages.find(stage => stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Transfer);

    const splitStage: DstStageResponseModel = vertex.dstStages.find(stage => stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Split);
    const splitNodeId: string = splitStage ? getSplitNodeId(vertex.stageInfo.externalId) : null;

    const mergeStage: DstStageResponseModel = vertex.dstStages.find(stage => stage.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Merge);
    const mergeNodeId: string = mergeStage ? getMergeNodeId(mergeStage.externalId) : null;

    if (splitNodeId) {
      handleSplit(vertex, splitNodeId, hasTransferStage);
    } else if (mergeNodeId && !(mergeNodeId in mergeNodes)) {
      handleMerge(mergeNodeId, mergeStage);
    }

    for (const dst of vertex.dstStages) {
      handleEdge(vertex, dst, splitNodeId, mergeNodeId);
      if (dst.flowMapTransitionProcessType === DstStageResponseModelFlowMapTransitionProcessType.Merge) {
        updateMergeConstraints(vertex, dst, mergeConstraints);
      }
    }

    addConstraint(generateConstraints(vertex, hasTransferStage));

    for (const transfer of vertex.incomingTransfers) {
      handleCrossTransfer(vertex, transfer);
    }
    addConstraint(constraintsForCrossTransfers(vertex, vertex.incomingTransfers));

    for (const transfer of vertex.outgoingTransfers) {
      handleCrossTransfer(vertex, transfer);
    }
    addConstraint(constraintsForCrossTransfers(vertex, vertex.outgoingTransfers));
  }

  const mergeConstraintsValues: {alignment?: AlignmentConstraint[], gap?: GapConstraint[]}[] = flatten(Object.values(mergeConstraints));
  const mergeAlignmentConstraint: AlignmentConstraint[] = flatten(mergeConstraintsValues.map(v => v.alignment));
  const mergeGapConstraint: GapConstraint[] = flatten(mergeConstraintsValues.map(v => v.gap)).filter(v => !!v.right);

  nodes.push(...Object.values(compoundNodes), ...Object.values(mergeNodes));
  alignConstraints.push(...mergeAlignmentConstraint);
  gapConstraints.push(...mergeGapConstraint);

  return {
    nodes,
    edges,
    constraints: alignConstraints,
    gapConstraints: gapConstraints,
  };
}

