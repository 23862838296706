import { Injectable } from '@angular/core';
import {
  AccountClient,
  CompanySubscriptionResponseModel,
  Features2
} from '@onbatch/core/services/Account';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@onbatch/core/services/Env';
import { AuthService } from '@onbatch/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  currentSubscription = new BehaviorSubject<CompanySubscriptionResponseModel>(null);

  private accountClient: AccountClient;

  constructor(private http: HttpClient,
              private envService: EnvService,
              ) {
    this.accountClient = new AccountClient(this.http, envService.apiUrl);
    this.initSubscription();
  }

  initSubscription(): void {
    this.currentSubscription.next(JSON.parse(localStorage.getItem('currentSubscription')));
  }

  setSubscription(): void {
    this.accountClient.paymentSubscriptionCurrentGet().subscribe(
      (subscription: CompanySubscriptionResponseModel) => {
        this.currentSubscription.next(subscription);
        localStorage.setItem('currentSubscription', JSON.stringify(subscription));
      });
  }

  getSubscription(): void {
    if (!this.currentSubscription.value) {
      this.setSubscription();
    }
  }

  getAccess(feature: SubscriptionFeatures): boolean {

    const user = AuthService.getUser();

    if (user !== null && user.profile !== null && user.profile.Role === 'OnBatchAdmin') {
      return true;
    }
    if (!feature) {
      return true;
    }
    let shouldBeVisible = false;
    const featuresInSubscription: Features2 = this.currentSubscription.value.features;
    if (featuresInSubscription) {
      shouldBeVisible = featuresInSubscription[feature];
    }
    return shouldBeVisible;
  }

  checkLimit(limitedFeature: Feature, currentValue: number): boolean {
    switch (limitedFeature) {
      case Feature.Companies:
        if (!this.currentSubscription.value.unlimitedCompanies) {
          return currentValue < this.currentSubscription.value.maxNumberOfCompanies;
        } else {
          return true;
        }
      case Feature.Facilities:
        if (!this.currentSubscription.value.unlimitedFacilitiesWarehouses) {
          return currentValue < this.currentSubscription.value.maxNumberOfFacilitiesWarehouses;
        } else {
          return true;
        }
      case Feature.Users:
        if (!this.currentSubscription.value.unlimitedUsers) {
          return currentValue < this.currentSubscription.value.maxNumberOfUsers;
        } else {
          return true;
        }
    }
  }
}

export enum Feature {
  Companies,
  Facilities,
  Users
}

export enum SubscriptionFeatures {
  InventoryMasterItems = 'inventory_MasterItems',
  InventoryCriticalStockAlert = 'inventory_CriticalStockAlert',
  InventoryStockCount = 'inventory_StockCount',
  InventoryTransferItems = 'inventory_TransferItems',
  InventorySalesPurchaseHistory = 'inventory_SalesPurchaseHistory',
  InventoryTieredVolumePricing = 'inventory_TieredVolumePricing',
  InventoryLotTraceability = 'inventory_LotTraceability',
  InventoryReports = 'inventory_Reports',
  InventoryDashboard = 'inventory_Dashboard',
  PurchasingPurchaseOrders = 'purchasing_PurchaseOrders',
  PurchasingDraftVoidOrders = 'purchasing_DraftVoidOrders',
  PurchasingPartialFullReceive = 'purchasing_PartialFullReceive',
  PurchasingEmailOrders = 'purchasing_EmailOrders',
  PurchasingReturns = 'purchasing_Returns',
  PurchasingBackOrders = 'purchasing_BackOrders',
  PurchasingCreateBills = 'purchasing_CreateBills',
  PurchasingRefunds = 'purchasing_Refunds',
  PurchasingPurchaseRequisitions = 'purchasing_PurchaseRequisitions',
  // TODO: Add access checking based on subscription tier when feature will be available
  PurchasingVendorPerformance = 'purchasing_VendorPerformance',
  PurchasingAutoGeneratedOrders = 'purchasing_AutoGeneratedOrders',
  WarehousingTransferInBond = 'warehousing_TransferInBond',
  // No changes required on FE side
  WarehousingBarrelManagement = 'warehousing_BarrelManagement',
  WarehousingContainerLabeling = 'warehousing_ContainerLabeling',
  WarehousingFloorLayoutDesign = 'warehousing_FloorLayoutDesign',
  WarehousingMovementTracking = 'warehousing_MovementTracking',
  WarehousingMaterialGoodsCosting = 'warehousing_MaterialGoodsCosting',
  // TODO: Add access checking based on subscription tier when feature will be available
  WarehousingLotRating = 'warehousing_LotRating',
  // TODO: Add access checking based on subscription tier when feature will be available
  WarehousingTastingNotes = 'warehousing_TastingNotes',
  // TODO: Add access checking based on subscription tier when feature will be available
  WarehousingQRCodeScanning = 'warehousing_QRCodeScanning',
  WarehousingOrderPicking = 'warehousing_OrderPicking',
  Warehousing3rdPartyWarehousing = 'warehousing_3rdPartyWarehousing',
  WarehousingRepackaging = 'warehousing_Repackaging',
  // TODO: Add access checking based on subscription tier when feature will be available
  WarehousingPalletizing = 'warehousing_Palletizing',
  ManufacturingEquipmentManagement = 'manufacturing_EquipmentManagement',
  ManufacturingFederalGovernmentReports = 'manufacturing_FederalGovernmentReports',
  // As long as Manual Batch is available for each subscription tier, no changes are required on FE side
  ManufacturingManualBatch = 'manufacturing_ManualBatch',
  // If this feature is unavailable backend shouldn't return SFG/FG Packaging stages from /manufacturing/Batch/{id}/getAvailableStages
  // No changes required on FE side
  ManufacturingGoodsPackaging = 'manufacturing_GoodsPackaging',
  ManufacturingProductFlows = 'manufacturing_ProductFlows',
  ManufacturingProductFlowBatch = 'manufacturing_ProductFlowBatch',
  ManufacturingBatchCosting = 'manufacturing_BatchCosting',
  ManufacturingReports = 'manufacturing_Reports',
  ManufacturingBatchScheduling = 'manufacturing_BatchScheduling',
  ManufacturingGenerateLabels = 'manufacturing_GenerateLabels',
  // TODO: Add access checking based on subscription tier when feature will be available
  ManufacturingPackagingLines = 'manufacturing_PackagingLines',
  // TODO: Add access checking based on subscription tier when feature will be available
  ManufacturingQRCodeScanning = 'manufacturing_QRCodeScanning',
  // TODO: Add access checking based on subscription tier when feature will be available
  ManufacturingWorkOrders = 'manufacturing_WorkOrders',
  // TODO: Add access checking based on subscription tier when feature will be available
  ManufacturingProductionPlanning = 'manufacturing_ProductionPlanning',
  // TODO: Add access checking based on subscription tier when feature will be available
  ManufacturingDemandForecasting = 'manufacturing_DemandForecasting',
  SalesSalesOrders = 'sales_SalesOrders',
  SalesDraftVoidOrders = 'sales_DraftVoidOrders',
  // No changes required on FE side
  SalesPartialFullProcessing = 'sales_PartialFullProcessing',
  SalesEmailOrders = 'sales_EmailOrders',
  SalesInvoicing = 'sales_Invoicing',
  // TODO: Add access checking based on subscription tier when feature will be available
  SalesEmailInvoice = 'sales_EmailInvoice',
  SalesPayments = 'sales_Payments',
  SalesShippingDocuments = 'sales_ShippingDocuments',
  // TODO: Add access checking based on subscription tier when feature will be available
  SalesBackOrders = 'sales_BackOrders',
  // TODO: Add access checking based on subscription tier when feature will be available
  SalesReturnOrders = 'sales_ReturnOrders',
  // TODO: Add access checking based on subscription tier when feature will be available
  SalesQRCodeScanning = 'sales_QRCodeScanning',
  SalesOrderPicking = 'sales_OrderPicking',
  SalesInvoiceConsolidation = 'sales_InvoiceConsolidation',
  ContactRelationshipCompanies = 'contactRelationship_Companies',
  ContactRelationshipContacts = 'contactRelationship_Contacts',
  ContactRelationshipPurchaseSalesHistory = 'contactRelationship_PurchaseSalesHistory',
  ContactRelationshipTieredVolumePricing = 'contactRelationship_TieredVolumePricing',
  // TODO: Add access checking based on subscription tier when feature will be available
  ContactRelationshipTasks = 'contactRelationship_Tasks',
  HumanResourcesEmployeeManagement = 'humanResources_EmployeeManagement',
  // TODO: Add access checking based on subscription tier when feature will be available
  ReportingAnalyticsDashboard = 'reportingAnalytics_Dashboard',
  // TODO: Add access checking based on subscription tier when feature will be available
  ReportingAnalyticsTransactionReporting = 'reportingAnalytics_TransactionReporting',
  // TODO: Add access checking based on subscription tier when feature will be available
  ReportingAnalyticsChartsGraphsKPIs = 'reportingAnalytics_ChartsGraphsKPIs',
  // TODO: Add access checking based on subscription tier when feature will be available
  ReportingAnalyticsAdHocReporting = 'reportingAnalytics_AdHocReporting',
  // TODO: Add access checking based on subscription tier when feature will be available
  ReportingAnalyticsMultiBusinessUnit = 'reportingAnalytics_MultiBusinessUnit',
  // TODO: Add access checking based on subscription tier when feature will be available
  IntegrationsQuickBooksOnline = 'integrations_QuickBooksOnline',
  // TODO: Add access checking based on subscription tier when feature will be available
  IntegrationsXero = 'integrations_Xero',
  IntegrationsPaygov = 'integrations_Paygov',
  // TODO: Add access checking based on subscription tier when feature will be available
  IntegrationsQuickBooksDesktopServer = 'integrations_QuickBooksDesktopServer',
  MiscPersonalPreferences = 'misc_PersonalPreferences',
  MiscAccountSettings = 'misc_AccountSettings',
  MiscRolesPermissions = 'misc_RolesPermissions',
  MiscAdvancedFiltering = 'misc_AdvancedFiltering',
  MiscImportCSVExcel = 'misc_ImportCSVExcel',
  // TODO: Add access checking based on subscription tier when feature will be available
  MiscExportCSVExcel = 'misc_ExportCSVExcel',
  // TODO: Add access checking based on subscription tier when feature will be available
  MiscMobileApp = 'misc_MobileApp',
  // TODO: Add access checking based on subscription tier when feature will be available
  SupportOnBatchAcademy = 'support_OnBatchAcademy',
  // TODO: Add access checking based on subscription tier when feature will be available
  SupportEmail = 'support_Email',
  // TODO: Add access checking based on subscription tier when feature will be available
  SupportPhone = 'support_Phone',
  // TODO: Add access checking based on subscription tier when feature will be available
  SupportInAppChat = 'support_InAppChat',
  // TODO: Add access checking based on subscription tier when feature will be available
  SupportOnSite = 'support_OnSite',
  Reporting = 'reporting',

}
