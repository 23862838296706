import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FilterRequestApplyModel,
  FilterRequestModel,
  FilterRequestModelFilterTypeId,
  FilterResponseModel,
  LookUpResponse,
  PaginatedResponseOfLookUpResponse, QuantityFilter, ValueFilter
} from '../../core/services/Inventory';
import { FiltersService } from '../filters.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SaveFilterFormModel } from '../../shared/models';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { EnumFilter, UserSettingsResponseModel } from '../../core/services/Account';

@Component({
  selector: 'app-filters-bar',
  templateUrl: './filters-bar.component.html'
})
export class FiltersBarComponent implements OnInit, OnDestroy {
  @Input() activeFilters: FilterRequestApplyModel[];
  @Input() filtersType: string;
  @Input() showUtilityBar = true;


  @Output() filterLoaded: EventEmitter<FilterRequestApplyModel[]> = new EventEmitter<FilterRequestApplyModel[]>();
  @Output() filtersDiscard: EventEmitter<null> = new EventEmitter<null>();

  @Input() isFilterLoaded;
  activeFiltersToShow: any[] = [];
  saveModal = false;
  saveFilterForm: FormGroup;
  renameFilterForm: FormGroup;
  templates: LookUpResponse[];
  saveFilterFormOptions: SaveFilterFormModel = {
    fieldType: 'input',
    label: 'Filter name',
    name: 'filterName',
    type: 'text',
    placeholder: 'Enter name',
    field: 'filterName'
  };
  formSubmitStatus = true;
  templateListVisible = false;
  lastUsedFilter: FilterRequestApplyModel;
  loadedFilter: FilterRequestApplyModel;
  renameModal = false;
  templateToEdit: LookUpResponse;
  isLastFilterLoaded = false;

  filtersIsLastFilterLoadedSubscription: Subscription;
  filtersLoadedSubscription: Subscription;
  filtersSavedFiltersSubscription: Subscription;

  userExternalId: string = null;
  currentUser;
  @HostBinding('style.width') private width = '100%';

  constructor(
    private filtersService: FiltersService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private localStorage: LocalStorage,
    private authSerivce: AuthService
  ) {
    this.saveFilterForm = this.fb.group({
      filterName: new FormControl('', null)
    });
    this.renameFilterForm = this.fb.group({
      filterName: new FormControl('', null)
    });
    this.filtersLoadedSubscription = this.filtersService
      .getFiltersLoaded()
      .subscribe((value: boolean) => (this.isFilterLoaded = value));
    this.filtersIsLastFilterLoadedSubscription = this.filtersService
      .getIsLastFilterLoaded()
      .subscribe((value: boolean) => (this.isLastFilterLoaded = value));
    this.filtersSavedFiltersSubscription = this.filtersService
      .getSavedFilters()
      .subscribe((value: PaginatedResponseOfLookUpResponse) => (this.templates = value.list));
  }

  ngOnInit(): void {
    this.currentUser = this.authSerivce.getCurrentUser();
    if (this.currentUser) {
      this.userExternalId = this.authSerivce.getCurrentUser().settings.externalId;
      this.getLastUsedFilter();
    }
    this.setFiltersToShow();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.filtersLoadedSubscription.unsubscribe();
    this.filtersIsLastFilterLoadedSubscription.unsubscribe();
    this.filtersSavedFiltersSubscription.unsubscribe();
  }

  setFiltersToShow(): void {
    this.activeFiltersToShow = [];
    this.activeFilters.map((filter, i) => {
      if (this.activeFiltersToShow.length <= i) {
        this.activeFiltersToShow.push([]);
      }
      filter.valueFilters.map(activeFilter => {
        this.activeFiltersToShow[i].push(activeFilter);
      });
      filter.enumFilters.map(activeFilter => {
        this.activeFiltersToShow[i].push(activeFilter);
      });
      filter.quantityFilters.map(activeFilter => {
        this.activeFiltersToShow[i].push(activeFilter);
      });
    });
  }

  toggleSaveModal(): void {
    this.saveModal = !this.saveModal;
  }

  saveFilter(): void {
    const filterToSave = new FilterRequestModel();
    filterToSave.filterTypeId = FilterRequestModelFilterTypeId[this.filtersType];
    filterToSave.filters = [];
    this.activeFilters.map((filterGroup, index) => {
      filterToSave.filters.push(new FilterRequestApplyModel(filterGroup));
      filterToSave.filters[index].valueFilters = [];
      filterToSave.filters[index].enumFilters = [];
      filterToSave.filters[index].quantityFilters = [];
      filterGroup.valueFilters.map((valueFilter) => {
        filterToSave.filters[index].valueFilters.push(new ValueFilter(valueFilter));
      });
      filterGroup.enumFilters.map((enumFilter) => {
        filterToSave.filters[index].enumFilters.push(new EnumFilter(enumFilter));
      });
      filterGroup.quantityFilters.map((quantityFilter) => {
        filterToSave.filters[index].quantityFilters.push(new QuantityFilter(quantityFilter));
      });
    });
    filterToSave.name = this.saveFilterForm.get('filterName').value;
    this.filtersService
      .saveFilter(filterToSave)
      .then(() => (this.saveModal = false))
      .then(() => this.saveFilterForm.reset())
      .then(() => this.getSavedFilters())
      .then(() => this.toastr.success('Success!', 'Filter saved'));
  }

  getSavedFilters(): void {
    this.filtersService.getAllFilters(
      FilterRequestModelFilterTypeId[this.filtersType],
      null,
      null,
      null,
      1,
      9999
    );
  }

  toggleTemplateList(): void {
    this.templateListVisible = !this.templateListVisible;
  }

  closeTemplateList(event: { value: boolean }): void {
    if (event.value && this.templateListVisible) {
      this.templateListVisible = false;
    }
  }

  getLastUsedFilter(): void {
    this.localStorage
      .getItem(`${this.userExternalId}-last-used-filter-${this.filtersType}`)
      .toPromise()
      .then((val: FilterRequestApplyModel) => {
        if (val) {
          this.lastUsedFilter = val;
        }
      });
  }

  loadLastUsedFilter(): void {
    // @ts-ignore
    this.activeFilters = this.lastUsedFilter;
    this.filtersService.setIsLastFilterLoaded(true);
    this.setFiltersToShow();
    this.filterLoaded.emit(this.activeFilters);
  }

  toggleRenameModal(template: LookUpResponse): void {
    this.renameModal = !this.renameModal;
    this.renameFilterForm.get('filterName').setValue(template.name);
    this.templateToEdit = template;
  }

  renameFilter(): void {
    this.filtersService.getFilterById(this.templateToEdit.externalId).then((res: FilterResponseModel) => {
      const filterToUpdate: FilterRequestModel = new FilterRequestModel();
      filterToUpdate.filters = res.filters;
      filterToUpdate.name = this.renameFilterForm.get('filterName').value;
      filterToUpdate.filterTypeId = FilterRequestModelFilterTypeId[this.filtersType];
      this.filtersService
        .updateFilter(this.templateToEdit.externalId, filterToUpdate)
        .then(() => (this.renameModal = false))
        .then(() => this.renameFilterForm.reset())
        .then(() => this.getSavedFilters())
        .then(() => this.toastr.success('Success!', 'Filter updated'));
    });
  }

  deleteTemplate(externalId: string): void {
    this.filtersService
      .deleteFilter(externalId)
      .then(() => this.getSavedFilters())
      .then(() => this.toastr.success('Success!', 'Filter removed'));
  }

  loadFilter(externalId: string): void {
    this.filtersService
      .getFilterById(externalId)
      .then((res: FilterResponseModel) => {
        this.activeFilters = res.filters;
        this.filtersService.setFiltersAsLoaded(true);
        this.loadedFilter = res;
      })
      .then(() => {
        this.setFiltersToShow();
        this.filterLoaded.emit(this.activeFilters);
      });
  }

  discardFilter(): void {
    this.activeFiltersToShow = [];
    this.activeFilters = [];
    this.filtersService.setFiltersAsOpened(false);
    this.filtersService.setFiltersAsApplied(false);
    this.filtersService.setFiltersAsLoaded(false);
    this.filtersService.setIsSavePossible(false);
    this.filterLoaded.emit(this.activeFilters);
  }

  removeFilter(sectionIndex: number, filterIndex: number): void {
    const section = this.activeFilters[sectionIndex];
    section.valueFilters = section.valueFilters.filter(filter => filter.orderIndex !== filterIndex);
    section.quantityFilters = section.quantityFilters.filter(filter => filter.orderIndex !== filterIndex);
    section.enumFilters = section.enumFilters.filter(filter => filter.orderIndex !== filterIndex);
    if (
      this.activeFilters[sectionIndex].valueFilters.length === 0 &&
      this.activeFilters[sectionIndex].quantityFilters.length === 0 &&
      this.activeFilters[sectionIndex].enumFilters.length === 0
    ) {
      this.activeFilters.splice(sectionIndex, 1);
    }
    if (this.activeFilters.length === 0) {
      this.discardFilter();
    } else {
      this.filterLoaded.emit(this.activeFilters);
    }
  }
}
