import { Component, HostBinding, Input } from '@angular/core';
import { ManufacturingItemBatchesLookUpResponseModel, ManufacturingContainerResponseModel, BatchProcessAttributeResponseBatchSourceType, LookUpResponse } from '../../../core/services/Manufacturing';
import { IMeasurementUnit } from '@onbatch/shared/interfaces/unit.interfaces';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html'
})
export class ListItemComponent {
  @Input() item: ManufacturingItemBatchesLookUpResponseModel;
  @Input() containerItem: ManufacturingContainerResponseModel;
  @Input() groupedUnits: boolean;
  @Input() sourceType: BatchProcessAttributeResponseBatchSourceType;
  @Input() unitOfMeasurement: IMeasurementUnit;
  @Input() currentUnitPropertyType: LookUpResponse;

  batchSourceType = BatchProcessAttributeResponseBatchSourceType;

  @HostBinding('style.display') private display = 'block';
}
