import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterType'
})
export class FilterTypePipe implements PipeTransform {
  transform(value: any): any {
    if (value === 'Int') {
      return 'number';
    } else if (value === 'String') {
      return 'text';
    } else {
      return 'text';
    }
  }
}
