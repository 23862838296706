/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directives/click-outside.directive";
import * as i2 from "@angular/common";
import * as i3 from "../svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../svg-icon/svg-icon.component";
import * as i5 from "./tooltip.component";
var styles_TooltipComponent = [];
var RenderType_TooltipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
function View_TooltipComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_TooltipComponent_3(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_TooltipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["click-outside", ""]], [[8, "className", 0]], [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.toggleTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ClickOutsideDirective, [i0.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = !_co.customContent; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.customContent; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "form__tooltip ", _co.customTooltipClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "form__tooltip-arrow ", _co.customArrowClass, ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_TooltipComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "svg-icon", [["click-outside", ""]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.openTooltip($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active": 0 }), i0.ɵdid(4, 212992, null, 0, i1.ClickOutsideDirective, [i0.ElementRef], null, { clickOutside: "clickOutside" }), i0.ɵdid(5, 114688, null, 0, i4.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "tooltip ", _co.customIconClass, ""); var currVal_1 = _ck(_v, 3, 0, _co.showTooltip); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); var currVal_2 = _co.icon; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showTooltip; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_TooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i0.ɵdid(1, 49152, null, 0, i5.TooltipComponent, [], null, null)], null, null); }
var TooltipComponentNgFactory = i0.ɵccf("app-tooltip", i5.TooltipComponent, View_TooltipComponent_Host_0, { icon: "icon", customIconClass: "customIconClass", customTooltipClass: "customTooltipClass", customArrowClass: "customArrowClass", text: "text", customContent: "customContent" }, {}, ["*"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
