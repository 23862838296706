import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PricingRequestModel,
  PricingRequestModelRuleTypeId,
  PricingResponseModel,
  PricingResponseModelRuleTypeId,
  PricingUpdateRequestModel,
  PricingUpdateRequestModelRuleTypeId
} from '@onbatch/core/services/Inventory';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@onbatch/core/services/auth.service';
import { FormatsService } from '@onbatch/shared/services/formats.service';
import { closeCalendar } from '@onbatch/shared/utils';
import { MixPanelEvents, pricePrefix as importedPricePrefix } from '../../../../shared/constants';
import { PricingValidation } from '../../../../inventory/master-items/view-item/item-pricing/single-preset-rule/single-preset-rule.component';
import { PricingService } from '@onbatch/shared/components/pricing-by-customer/pricing.service';
import { mode } from '@onbatch/shared/enums/general.enum';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';

@Component({
  selector: '[single-preset-rule-by-customer]',
  templateUrl: './single-preset-rule.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class SinglePresetRuleByCustomerComponent implements OnInit {
  @Input() rule: PricingResponseModel;
  @Input() pricing: Array<PricingResponseModel>;
  @Input() contractorId: string;
  @Input() masterItemId: string;
  @Input() mode: mode = mode.View;
  @Output() presetAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() presetUpdated: EventEmitter<any> = new EventEmitter<any>();

  presetRules = [{ value: 'QuantityRange', name: 'Quantity Range' }, { value: 'DateRange', name: 'Date Range' }];
  PricingResponseModelRuleTypeId = PricingResponseModelRuleTypeId;
  modeTypes = mode;
  validation: PricingValidation;
  date: { from: any; to: any } = {
    from: null,
    to: null
  };
  newRule: PricingRequestModel;
  prevRule: any;
  patternForCost = this.formatsService.patternForCost();
  patternForAmountInteger = this.formatsService.patternForAmountInteger();

  closeDatepicker = closeCalendar;

  readonly pricePrefix = importedPricePrefix;

  @HostBinding('style.width') private width = '100%';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private pricingService: PricingService,
    public authService: AuthService,
    public formatsService: FormatsService,
    private mixpanelService: MixpanelService,
  ) {
    this.validation = {
      salesPrice: null,
      minQuantity: null,
      maxQuantity: null,
      to: null,
      from: null,
      datesOverlap: false,
      numbersOverlap: false
    };
  }

  ngOnInit() {
    this.setUpNewRule();
    if (this.mode === this.modeTypes.View) {
      this.prevRule = {
        externalId: this.rule.externalId,
        ruleTypeId: this.rule.ruleTypeId,
        from: this.rule.from,
        to: this.rule.to,
        maxQuantity: this.rule.maxQuantity,
        minQuantity: this.rule.minQuantity,
        salesPrice: this.rule.salesPrice
      };
    }
  }

  setUpNewRule() {
    this.newRule = new PricingRequestModel();
    this.newRule.masterItemExternalId = this.masterItemId;
    this.newRule.contractorId = this.contractorId;
    this.newRule.ruleTypeId = PricingRequestModelRuleTypeId.QuantityRange;
    this.newRule.from = null;
    this.newRule.to = null;
    this.newRule.maxQuantity = null;
    this.newRule.minQuantity = null;
    this.newRule.salesPrice = 0;
    this.date.to = null;
    this.date.from = null;
  }

  change() {
    this.mode = this.modeTypes.Edit;
  }

  updatePresetRule() {
    this.validation.salesPrice = this.validateNumberValue(this.rule.salesPrice, 0.01, Number.POSITIVE_INFINITY);
    if (this.rule.ruleTypeId === PricingResponseModelRuleTypeId.QuantityRange) {
      this.validation.minQuantity = this.validateNumberValue(this.rule.minQuantity, 0, this.rule.maxQuantity);
      this.validation.maxQuantity = this.validateNumberValue(this.rule.maxQuantity, 0, Number.POSITIVE_INFINITY);
      if (this.pricing[0].unitOfMeasurement.name === 'Each') {
        this.validation.minInteger = !Number.isInteger(Number(this.rule.minQuantity));
        this.validation.maxInteger = !Number.isInteger(Number(this.rule.maxQuantity));
      }
    }
    if (this.rule.ruleTypeId === PricingResponseModelRuleTypeId.DateRange) {
      this.validation.from = this.compareDates(this.date.to, this.date.from);
      this.validation.to = this.compareDates(this.date.to, this.date.from);
      this.validation.datesOverlap = this.checkDatesOverlap(this.date.to, this.date.from);
      this.rule.to = new Date(this.date.to).getTime() / 1000;
      this.rule.from = new Date(this.date.from).getTime() / 1000;
    }
    if (this.checkValidation()) {
      let rule;
      if (this.rule.ruleTypeId === this.PricingResponseModelRuleTypeId.DateRange) {
        rule = PricingUpdateRequestModelRuleTypeId.DateRange;
      } else if (this.rule.ruleTypeId === this.PricingResponseModelRuleTypeId.QuantityRange) {
        rule = PricingUpdateRequestModelRuleTypeId.QuantityRange;
      }
      this.pricingService
        .updateSinglePricing(
          this.rule.externalId,
          new PricingUpdateRequestModel({
            minQuantity: this.rule.minQuantity ? this.rule.minQuantity : null,
            maxQuantity: this.rule.maxQuantity ? this.rule.maxQuantity : null,
            from: this.rule.from ? this.rule.from : null,
            to: this.rule.to ? this.rule.to : null,
            salesPrice: this.rule.salesPrice,
            ruleTypeId: rule
          })
        )
        .subscribe(() => {
          this.presetUpdated.emit();
          this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_UPDATED, {
            'Customer external ID': this.contractorId,
            'Master item external ID': this.masterItemId,
            'Pricing external ID': this.rule.externalId,
            'Min Quantity': this.rule.minQuantity || null,
            'Max Quantity': this.rule.maxQuantity || null,
            'Date from': this.rule.from || null,
            'Date to': this.rule.to || null,
            'Sales Price': this.rule.salesPrice,
            'Rule Type ID': this.rule.ruleTypeId
          });
          this.toastr.success('Success!', 'Preset updated');
        });
    }
  }

  addNewPresetRule() {
    this.validation.salesPrice = this.validateNumberValue(this.newRule.salesPrice, 0.01, Number.POSITIVE_INFINITY);
    if (this.newRule.ruleTypeId === PricingRequestModelRuleTypeId.QuantityRange) {
      this.validation.minQuantity = this.validateNumberValue(this.newRule.minQuantity, 0, this.newRule.maxQuantity);
      this.validation.maxQuantity = this.validateNumberValue(this.newRule.maxQuantity, 0, Number.POSITIVE_INFINITY);
      this.validation.numbersOverlap = this.checkNumbersOverlap(this.newRule.minQuantity, this.newRule.maxQuantity);
      if (this.pricing[0].unitOfMeasurement.name === 'Each') {
        this.validation.minInteger = !Number.isInteger(Number(this.newRule.minQuantity));
        this.validation.maxInteger = !Number.isInteger(Number(this.newRule.maxQuantity));
      }
    }
    if (this.newRule.ruleTypeId === PricingRequestModelRuleTypeId.DateRange) {
      this.validation.from = this.compareDates(this.date.to, this.date.from);
      this.validation.to = this.compareDates(this.date.to, this.date.from);
      this.validation.datesOverlap = this.checkDatesOverlap(this.date.to, this.date.from);
      this.newRule.to = new Date(this.date.to).getTime() / 1000;
      this.newRule.from = new Date(this.date.from).getTime() / 1000;
    }
    if (this.checkValidation()) {
      this.pricingService
        .addSinglePricing(this.newRule)
        .subscribe((res: PricingResponseModel) => {
          this.setUpNewRule();
          this.presetAdded.emit();
          this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_ADDED, {
            'Customer external ID': this.contractorId,
            'Master item external ID': this.masterItemId,
            'Pricing external ID': res.externalId,
            'Min Quantity': this.newRule.minQuantity || null,
            'Max Quantity': this.newRule.maxQuantity || null,
            'Date from': this.newRule.from || null,
            'Date to': this.newRule.to || null,
            'Sales Price': this.newRule.salesPrice,
            'Rule Type ID': this.newRule.ruleTypeId
          });
          this.toastr.success('Success!', 'Preset added');
        });
    }
  }

  validateNumberValue(value: number, min: number, max: number) {
    value = Number(value);
    if (isNaN(value) || value === 0) {
      return true;
    } else {
      return min > value || max < value;
    }
  }

  compareDates(time1: number, time2: number) {
    if (time1 && time2) {
      return new Date(time1) < new Date(time2);
    } else {
      return true;
    }
  }

  checkDatesOverlap(from: any, to: any) {
    let areDatesOverlap = false;
    this.pricing.map((pricing: PricingResponseModel) => {
      if (pricing.from !== null && pricing.to !== null) {
        const dateFrom = new Date(from).getTime() / 1000;
        const dateTo = new Date(to).getTime() / 1000;
        if (!(dateFrom >= pricing.from && dateFrom <= pricing.to)) {
          if (!(dateTo >= pricing.from && dateTo <= pricing.to)) {
            if (!(dateFrom <= pricing.from && dateTo >= pricing.to)) {
            } else {
              areDatesOverlap = true;
            }
          } else {
            areDatesOverlap = true;
          }
        } else {
          areDatesOverlap = true;
        }
      }
    });
    if (areDatesOverlap) {
      this.toastr.error('Dates are overlapped.', 'Error');
    }
    return areDatesOverlap;
  }

  checkNumbersOverlap(from: any, to: any) {
    let areNumbersOverlap = false;
    this.pricing.map(pricing => {
      const minQuantity = pricing.minQuantity;
      const maxQuantity = pricing.maxQuantity;
      if (maxQuantity !== null && minQuantity !== null) {
        if (!(from >= minQuantity && from <= maxQuantity)) {
          if (!(to >= minQuantity && to <= maxQuantity)) {
            if (!(from <= minQuantity && to >= maxQuantity)) {
            } else {
              areNumbersOverlap = true;
            }
          } else {
            areNumbersOverlap = true;
          }
        } else {
          areNumbersOverlap = true;
        }
      }
    });
    if (areNumbersOverlap) {
      this.toastr.error('Ranges are overlapped.', 'Error');
    }
    return areNumbersOverlap;
  }

  checkValidation(): boolean {
    let valid = true;
    const _self = this;
    Object.keys(_self.validation).map(function(key: string) {
      if (_self.validation[key]) {
        valid = false;
      }
    });
    return valid;
  }

  removePricingItem(id: string) {
    this.pricingService
      .deleteSinglePricing(id)
      .subscribe(() => {
        this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_DELETED, {
          'Customer external ID': this.contractorId,
          'Master item external ID': this.masterItemId,
          'Pricing external ID': this.rule.externalId,
          'Min Quantity': this.rule.minQuantity || null,
          'Max Quantity': this.rule.maxQuantity || null,
          'Date from': this.rule.from || null,
          'Date to': this.rule.to || null,
          'Sales Price': this.rule.salesPrice,
          'Rule Type ID': this.rule.ruleTypeId
        });
        this.presetUpdated.emit();
      });
  }

  clearValidation() {
    this.validation = {
      salesPrice: null,
      minQuantity: null,
      maxQuantity: null,
      to: null,
      from: null,
      datesOverlap: null,
      numbersOverlap: null
    };
  }

  changeToViewMode() {
    this.mode = this.modeTypes.View;
    this.rule = this.prevRule;
  }

  closeAddMode() {
    this.setUpNewRule();
    this.presetAdded.emit(true);
  }
}
