import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemTransactionType } from '../../../../../purchasing/orders/orders-interfaces';
import {
  InvoiceSalesOrderItemResponseModel,
  InvoiceSalesOrderResponseModel,
  SalesOrderItemResponseModel,
  SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId,
  SalesOrderItemTransactionResponseModel
} from '@onbatch/core/services/Sales';
import { ShippingService } from '@onbatch/shared/services/shipping.service';
import { SalesOrdersService } from '../../../../orders/orders.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TransfersService } from 'app/warehousing/transfers/transfers.service';
import { MixPanelEvents } from '@onbatch/shared/constants';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { InvoiceResponseModelWithItems } from '../../../../invoices/invoices-interfaces';
import { flatMap } from 'lodash';

@Component({
  selector: 'app-single-sales-item-actions-details',
  templateUrl: './single-sales-item-actions-details.component.html'
})
export class SingleSalesItemActionsDetailsComponent {

  @Input() type: ItemTransactionType;
  @Input() details: SalesOrderItemTransactionResponseModel;
  @Input() forceExpanded: boolean;
  @Input() salesOrderExternalId: string;
  @Input() transferExternalId: string;
  @Input() masterItem: SalesOrderItemResponseModel;
  @Input() salesApproval = false;
  @Input() invoices: InvoiceResponseModelWithItems[];

  @Output() detailsExpanded = new EventEmitter<boolean>();
  @Output() shippingConfirmed = new EventEmitter();

  readonly transactionType = ItemTransactionType;
  readonly shipmentType = SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId;

  expandDetails = false;
  modalOpened = false;
  modalOnIdx: number;
  isWarehouseView: boolean = false;

  get haveInvoiceInShipment(): boolean {
    const shippedExternalIds = flatMap(this.invoices, ((invoice: InvoiceResponseModelWithItems) =>
        flatMap(invoice.salesOrders, (salesOrder: InvoiceSalesOrderResponseModel) =>
          flatMap(salesOrder.salesOrderItems, (salesOrderItem: InvoiceSalesOrderItemResponseModel) =>
            salesOrderItem.shipments.map(shipment => shipment.externalId)
          )
        )
    ));
    return shippedExternalIds && shippedExternalIds.length && shippedExternalIds.includes(this.details && this.details.transactionExternalId);
  }

  constructor(private router: Router,
              private shippingService: ShippingService,
              private transfersService: TransfersService,
              private salesOrdersService: SalesOrdersService,
              private toastr: ToastrService,
              private mixpanelService: MixpanelService,
              ) {
    this.isWarehouseView = this.router.url.includes('warehousing/');
  }

  isExpanded(): boolean {
    return this.forceExpanded || this.expandDetails;
  }

  onDetailsClicked() {
    this.expandDetails = !this.isExpanded();
    this.detailsExpanded.emit(this.expandDetails);
  }

  getIcon(): string {
    switch (this.type) {
      case ItemTransactionType.SHIPPED:
        return 'ship-order';
      case ItemTransactionType.PICKED:
        return 'pick';
    }
  }

  deleteShipping(): void {
    const onSuccess = () => {
      this.mixpanelService.track(
        this.type === ItemTransactionType.PICKED ? MixPanelEvents.SALES_ORDERS_PICKING_DELETED : MixPanelEvents.SALES_ORDERS_SHIPPING_DELETED, {
        'Sales order external ID': this.salesOrderExternalId,
      });
      this.toastr.success('Success!', `${this.type === ItemTransactionType.PICKED ? 'Pick' : 'Shipping'} removed`);
      if (this.isWarehouseView) {
        this.transfersService.fetchSingleTransfer(this.transferExternalId);
      } else {
        this.salesOrdersService.fetchSingleOrder(this.salesOrderExternalId);
      }
    };
    if (this.type === ItemTransactionType.PICKED) {
      if (this.isWarehouseView) {
        // @ts-ignore ToDo Backend needs to implement picking for TiB
        this.transfersService.deletePicking(this.transferExternalId, this.details.shipmentExternalId)
          .subscribe(() => onSuccess());
      } else {
        this.shippingService.deletePicking(this.salesOrderExternalId, this.details.transactionExternalId)
          .subscribe(() => onSuccess());
      }
    } else {
      if (this.isWarehouseView) {
        this.transfersService.deleteShipping(this.transferExternalId, this.details.shipmentExternalId)
          .subscribe(() => onSuccess());
      } else {
        this.shippingService.deleteShipping(this.salesOrderExternalId, this.details.transactionExternalId)
          .subscribe(() => onSuccess());
      }
    }
  }

  onShippingConfirmed(): void {
    this.shippingConfirmed.emit();
  }
}
