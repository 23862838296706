/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toggle.component";
var styles_ToggleComponent = [i0.styles];
var RenderType_ToggleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleComponent, data: {} });
export { RenderType_ToggleComponent as RenderType_ToggleComponent };
export function View_ToggleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0, "switch create-item__switch": 1, "switch--locked": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "switch__box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_co.toggleOptions.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "switch__handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "switch__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.toggleOptions.value, true, _co.toggleOptions.disabled); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toggleOptions.label; _ck(_v, 7, 0, currVal_1); }); }
export function View_ToggleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toggle", [], null, null, null, View_ToggleComponent_0, RenderType_ToggleComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToggleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToggleComponentNgFactory = i1.ɵccf("app-toggle", i3.ToggleComponent, View_ToggleComponent_Host_0, { toggleOptions: "toggleOptions" }, { toggleChangeState: "toggleChangeState" }, []);
export { ToggleComponentNgFactory as ToggleComponentNgFactory };
