import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FinishedGoodsSelectItemsService } from '../../../services/finished-goods-select-items.service';
import { RangeItem } from '../../../../sales/shared/components/sales-items/sales-items-interfaces';
import { Subscription } from 'rxjs';

export interface RangeRemoveData {
  index: number;
  range: RangeItem;
}
@Component({
  selector: 'app-selected-items-modal',
  templateUrl: './selected-items-modal.component.html'
})
export class SelectedItemsModalComponent implements OnInit, OnDestroy {
  @Input() externalId: string;

  @Output() rangeRemoveEvent = new EventEmitter<RangeRemoveData>();
  ranges: RangeItem[] = [];
  selectedRangesSubscription$: Subscription;

  constructor(private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService) {}

  ngOnInit(): void {
    this.selectedRangesSubscription$ = this.finishedGoodsSelectItemsService.getSelectedRanges()
    .subscribe((rangesMap: Map<string, RangeItem[]>) => {
      this.ranges = rangesMap.get(this.externalId)
    });
  }

  ngOnDestroy(): void {
    this.selectedRangesSubscription$.unsubscribe();
  }

  removeRangeItem(index: number, range: RangeItem) {
    this.rangeRemoveEvent.emit({index, range});
  }
}
