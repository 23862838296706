import * as cytoscape from 'cytoscape';
import * as cola from 'cytoscape-cola';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {flowMapStyle} from './flow-map-style';
import {LayoutOptions} from 'cytoscape';
import {AlignmentConstraint, GapConstraint, NodeOffset, NodesEdgesConstraints, traverse} from './flow-graph';
import {FlowMapResponseModel} from '../../../core/services/Manufacturing';

@Component({
  selector: 'app-flow-map',
  templateUrl: './flow-map.component.html',
})
export class FlowMapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('flowMapRoot', { static: false }) flowMapRoot: ElementRef;
  @Input() set flowMap(value: FlowMapResponseModel) {
    this._flowMap = value;
    this.initCytoscape();
  }

  loading = true;
  flowMapError = false;
  flowMapSuccess = false;

  private cyto: cytoscape.Core;
  private _flowMap: FlowMapResponseModel;

  constructor() {
    cytoscape.use(cola);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initCytoscape();
  }

  ngOnDestroy(): void {
    if (this.cyto) {
      this.cyto.destroy();
    }
  }

  private initCytoscape() {
    if (!this._flowMap) {
      return;
    }

    const elements = traverse(this._flowMap);

    try {
      this.cyto = cytoscape({
        container: this.flowMapRoot.nativeElement,
        boxSelectionEnabled: false,
        autolock: false,
        zoom: 1,
        minZoom: 1,
        maxZoom: 3,
        pixelRatio: 2,

        style: flowMapStyle,
        elements: elements,

        layout: {
          name: 'cola',
          animate: false,
          ready: (e) => {
            this.applyLayoutWithConstraints(e.cy, elements);
          }
        },
      });
    } catch (err) {
      this.flowMapError = true;
      this.loading = false;
      console.error(err);
    }

    if (this.flowMapError) {
      return;
    }
  }

  private applyLayoutWithConstraints(cyto: cytoscape.Core, elements: NodesEdgesConstraints) {
    const getNodeIdx = (nodeId: string) => cyto.$id(nodeId).scratch().cola.index;
    const mapAlignmentConstraint = (constraint: AlignmentConstraint) => ({
      axis: constraint.axis,
      offsets: constraint.offsets.map((node: NodeOffset) => ({
        node: getNodeIdx(node.nodeId),
        offset: node.offset,
      }))
    });

    const mapGapConstraint = (constraint: GapConstraint) => ({
      axis: constraint.axis,
      left: cyto.$id(constraint.left),
      right: cyto.$id(constraint.right),
      gap: constraint.gap,
    });

    const layout = cyto.makeLayout(<LayoutOptions>{
      name: 'cola',
      animate: false,
      flow: { axis: 'x', minSeparation: 30 },
      nodeDimensionsIncludeLabels: true,
      rawAlignment: elements.constraints.map(mapAlignmentConstraint),
      gapInequalities: elements.gapConstraints.map(mapGapConstraint),
      maxSimulationTime: 4000,
      ready: (e) => {
        setTimeout(() => {
          this.loading = false;
          this.flowMapSuccess = true;

          e.cy.fit(undefined, 30);
          const currentZoom: number = e.cy.zoom();
          e.cy.zoom(Math.min(currentZoom, 1.25));
          e.cy.center();
        });
      }
    });

    layout.run();
  }
}
