import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SubscriptionService } from '@onbatch/core/services/subscription.service';
import { Observable } from 'rxjs';
import { CompanySubscriptionResponseModel } from '@onbatch/core/services/Account';
import { ToastrService } from 'ngx-toastr';
import { AuthService, IUser } from '@onbatch/core/services/auth.service';

@Injectable()
export class ActiveSubscriptionGuardsService implements CanActivate {
  constructor(private subscriptionService: SubscriptionService,
              private authService: AuthService,
              private router: Router,
              private toastr: ToastrService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = true;
    const currentSubscription: CompanySubscriptionResponseModel = this.subscriptionService.currentSubscription.value;
    const isSubscriptionPaid: boolean = currentSubscription.subscriptionPaid;
    const user: IUser = this.authService.getCurrentUser();

    if (!currentSubscription.externalId || !isSubscriptionPaid) {
      if (user.profile.IsOwner !== 'False') {
        canActivate = false;
        this.toastr.warning(`You don't have an active subscription. Please provide the valid billing information and choose one of our plans.`, 'Access denied');
        this.router.navigateByUrl('/account/billing');
      } else {
        this.toastr.warning(`You don't have an active subscription.`, 'Access denied');
        this.router.navigateByUrl('/welcome');
      }
    }
    return canActivate;
  }
}
