import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBoxes } from '@onbatch/shared/models';
import {
  CommonMeasurementRequest,
  CommonMeasurementResponse,
  MeasurementRecalculationRequest,
  NameExternalIdModel,
  TransferInBondIncomingItemResponseModel,
  TransferInBondIncomingItemResponseModelReceptionStatus, TransferInBondIncomingItemTransactionResponseModel,
  TransferInBondReceivedItemRequestModel,
  TransferInBondReceptionRequestModel,
  TransferInBondResponseModel,
  WarehousingContainerResponseModel,
  WarehousingEquipmentLookUpResponseModel,
  WarehousingMasterItemResponseModel,
  WarehousingMasterItemResponseModelMasterItemTypeId
} from '@onbatch/core/services/Warehousing';
import { IncomingItemForList, MaxAmountDataForSFG } from '../receive-transfer.interface';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { getRemainingQuantity } from '../receive-items-utils';
import { checkIfClickedElementHasClass, checkIfDatePickerWasClicked, getDateTime, prepareDate } from '@onbatch/shared/utils';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { SpinnerService } from '@onbatch/core/services/spinner.service';
import { TransfersService } from '../../../../warehousing/transfers/transfers.service';
import { PurchaseOrderItemResponseModel, PurchaseOrderResponseModel } from '@onbatch/core/services/Purchasing';

interface TransferInBondReceivedItemRequestModelExtended extends TransferInBondReceivedItemRequestModel {
  equipment: WarehousingEquipmentLookUpResponseModel;
}

@Component({
  selector: 'app-receive-transfer-items-overlay',
  templateUrl: './receive-transfer-items-overlay.component.html'
})
export class ReceiveTransferItemsOverlayComponent implements OnInit, OnDestroy {
  @Input() transfer: TransferInBondResponseModel;
  @Input() purchaseOrder: PurchaseOrderResponseModel;
  @Input() isPurchaseOrder = false;
  @Input() opened: boolean;
  @Output() closeOverlay = new EventEmitter();
  @Output() submitClicked = new EventEmitter<FormGroup>();
  @Output() addReceive: EventEmitter<void> = new EventEmitter<void>();

  canFormBeSubmitted: boolean[] = [];
  emptyReceivedItems: boolean[] = [];
  incomingItemsForList: IncomingItemForList[] = [];
  formSubmitStatus = false;
  formArray: FormArray;
  form: FormGroup;
  date = moment();

  remainingQuantities = [];
  maxAmountValidationForSFG: Map<string, MaxAmountDataForSFG> = new Map();

  readonly masterItemType = WarehousingMasterItemResponseModelMasterItemTypeId;

  constructor(private fb: FormBuilder,
              private transfersService: TransfersService,
              private toastr: ToastrService,
              public spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    this.mapData();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.maxAmountValidationForSFG.clear();
  }

  mapData(): void {
    const items: (PurchaseOrderItemResponseModel | TransferInBondIncomingItemResponseModel)[] = this.isPurchaseOrder ? this.purchaseOrder.purchaseOrderItems : this.transfer.incomingItems;
    this.incomingItemsForList = items.map((item: PurchaseOrderItemResponseModel | TransferInBondIncomingItemResponseModel) => {
      return <IncomingItemForList>{
        isSelected: false,
        externalId: item.externalId,
        masterItem: item.masterItem,
        container: item.container,
        unitOfMeasurement: item.unitOfMeasurement,
        quantity: item.quantity,
        receptionStatus: TransferInBondIncomingItemResponseModelReceptionStatus[this.isPurchaseOrder ? (item as PurchaseOrderItemResponseModel).purchaseOrderItemStatusId : (item as TransferInBondIncomingItemResponseModel).receptionStatus],
        receivedItems: item.receivedItems,
      };
    });
    this.setInitialRemainingQuantity();
  }

  setInitialRemainingQuantity(): void {
    this.incomingItemsForList.forEach((item: IncomingItemForList, idx: number) => {
      this.remainingQuantities.push(item.quantity);
      this.setQuantity(idx, item.externalId);
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      transfers: this.fb.array([]),
      receiveDate: new FormControl(getDateTime().date, Validators.required),
      receiveDateTimer: new FormControl(getDateTime().time)
    });
    this.formArray = this.form.get('transfers') as FormArray;
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      receiveDate: new FormControl(null),
      useContractorSchema: new FormControl(false),
      containerExternalId: new FormControl(null),
      transferInBondItemExternalId: new FormControl(null),
      receivedItems: new FormControl(null)
    });
  }

  add(item: IncomingItemForList): void {
    const group = this.createFormGroup();
    group.get('transferInBondItemExternalId').patchValue(item.externalId);
    if (item.container && item.container.externalId) {
      group.get('containerExternalId').patchValue(item.container.externalId);
    }
    this.formArray.push(group);
  }

  remove(i: number): void {
    this.formArray.removeAt(i);
    this.remainingQuantities[i] = getRemainingQuantity(this.incomingItemsForList[i], []);
    this.canFormBeSubmitted[i] = false;
    this.setEmptyReceivedItemsFromCanFormBeSubmitted();
  }

  getFormGroupAtIndex(itemExternalId: string): FormGroup {
    return this.formArray.controls.find(control => control.get('transferInBondItemExternalId').value === itemExternalId) as FormGroup;
  }

  onReceiveItemsChange(form: FormArray, i: number, itemExternalId: string): void {
    const haveFormControls = !!(form.controls && form.controls.length);
    this.canFormBeSubmitted[i] = form && form.valid && haveFormControls;
    this.setEmptyReceivedItemsFromCanFormBeSubmitted();
    this.emptyReceivedItems[i] = haveFormControls;
    const items = form.controls.map(item => {
      const value: any = (item as FormGroup).value;
      return new TransferInBondReceivedItemRequestModel({
        ...value,
        dateFilled: value.dateFilled ? moment(value.dateFilled).unix() : null
      });
    });
    const group = this.getFormGroupAtIndex(itemExternalId);
    if (group) {
      group.get('receivedItems').patchValue(items);
    }
    this.setQuantity(i, itemExternalId);
  }

  submit(): void {
    this.formSubmitStatus = true;
    if (this.form.invalid || !this.canFormBeSubmitted.every((item: boolean) => !!item)) {
      return;
    }
    this.save();
  }

  save(): void {
    this.formArray.controls.forEach(control =>
      control.get('receiveDate').patchValue(this.date.unix())
    );
    this.submitClicked.emit(this.form);
  }

  onCheckBoxChanged(item: IncomingItemForList, i: number): void {
    item.isSelected = !item.isSelected;
    item.isSelected
      ? this.add(item)
      : this.remove(i);
    if (item.isSelected) {
      this.canFormBeSubmitted[i] = false;
      this.setEmptyReceivedItemsFromCanFormBeSubmitted();
    }
  }

  doCloseOverlay(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['add-item-modal', 'ng-option', 'owl']);
    if (this.opened && event.value && !isExcludedElementClicked && !checkIfDatePickerWasClicked(event)) {
      this.closeOverlay.emit();
    }
  }

  getItemName(item: IncomingItemForList): string {
    const name: string[] = [item.masterItem.name];
    if (item.masterItem.masterItemTypeId === this.masterItemType.SemiFinishedGood) {
      (item.container && item.container.externalId)
        ? name.push('(Packaged)')
        : name.push('(Bulk)');
    }
    return name.join(' ');
  }

  validateSizeOfEquipment(form: FormGroup): void {
    if (!!form && form.get('receivedItems')) {
      form.get('receivedItems').value.forEach((item: TransferInBondReceivedItemRequestModelExtended) => {
        if (item.equipment && !!item.alcoholContent) {
          const request: MeasurementRecalculationRequest[] = [new MeasurementRecalculationRequest({
            amount: new CommonMeasurementRequest({
              value: item.amountPerUnit || 0,
              unitOfMeasurementExternalId: item.equipmentReceivedQuantityUnitOfMeasurementExternalId
            }),
            alcoholContent: item.alcoholContent || 0
          })];
          this.transfersService.calculateRemainingQuantity(request, item.equipment.unitOfMeasurementExternalId)
            .subscribe((data: CommonMeasurementResponse) => {
              this.maxAmountValidationForSFG = new Map(this.maxAmountValidationForSFG);
              this.maxAmountValidationForSFG.set(item.equipmentExternalId, {
                amount: data.value,
                externalId: item.equipmentExternalId,
                proof: +item.alcoholContent
              });
            });
        }
      });
    }
  }

  setQuantity(i: number, itemExternalId: string): void {
    if (this.formArray) {
      const form = this.getFormGroupAtIndex(itemExternalId);
      if (form && form.get('receivedItems')) {
        this.validateSizeOfEquipment(form);
        const request: MeasurementRecalculationRequest[] = [];
        if (form.get('receivedItems').value) {
          form.get('receivedItems').value.forEach((item: TransferInBondReceivedItemRequestModelExtended) => {
            if (item.equipment && item.equipmentReceivedQuantityUnitOfMeasurementExternalId) {
              request.push(new MeasurementRecalculationRequest({
                amount: new CommonMeasurementRequest({
                  value: item.amountPerUnit || 0,
                  unitOfMeasurementExternalId: item.equipmentReceivedQuantityUnitOfMeasurementExternalId
                }),
                alcoholContent: item.alcoholContent || 0
              }));
            }
          });
          if (request.length) {
            this.transfersService.calculateRemainingQuantity(request, this.incomingItemsForList[i].unitOfMeasurement.externalId)
              .subscribe((data: CommonMeasurementResponse) => {
                this.remainingQuantities[i] = getRemainingQuantity(this.incomingItemsForList[i], [], true, data.value);
              });
          } else {
            this.remainingQuantities[i] = getRemainingQuantity(this.incomingItemsForList[i], form.get('receivedItems').value);
          }
        }
      }
    } else {
      this.remainingQuantities[i] = getRemainingQuantity(this.incomingItemsForList[i], []);
    }
  }

  areQuantitiesValid(): boolean {
    return this.remainingQuantities.every(quantity => quantity >= 0);
  }

  onCalendarChange(date: moment.Moment): void {
    this.date = date;
  }

  private setEmptyReceivedItemsFromCanFormBeSubmitted() {
    this.emptyReceivedItems = [...this.canFormBeSubmitted];
  }
}
