import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byCategory'
})
export class ByCategoryPipe implements PipeTransform {

  transform(value: any[], category?: any): any {
    return value.filter(item => item.equipmentModelType === category);
  }

}
