import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkUrl'
})
export class CheckUrlPipe implements PipeTransform {

  transform(url: string): any {
    if (!url || url === 'null') {
      return '#';
    } else {
      return url;
    }
  }

}
