import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public apiUrl = '';
  public env: any;

  constructor() {
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};
    // this.apiUrl = browserWindowEnv.apiUrl === '' ? environment.baseUrl : browserWindowEnv.apiUrl; // do not override baseUrl value with window object value
    this.apiUrl = environment.baseUrl; // use environment.ts file instead
    this.env = browserWindowEnv; // TODO - this one should be made obsolete
    this.env.intercomAppId = environment.intercomAppId;
    this.env.intercomIdentitySecret = environment.intercomIdentitySecret;
  }
}
