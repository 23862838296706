import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-no-results',
  templateUrl: './table-no-results.component.html',
  styleUrls: ['./table-no-results.component.scss']
})
export class TableNoResultsComponent {
  @Input() message = 'No results';

  constructor() {
  }
}
