import {
  FilterRequestApplyModel,
  NameExternalIdModel,
  PurchaseOrderItemResponseModelPurchaseOrderItemStatusId, PurchaseOrderItemTransactionResponseModel,
  PurchasingMasterItemResponseModelMasterItemTypeId
} from '../../core/services/Purchasing';

export interface PurchaseOrdersSortSettingsModel {
  columnName: string;
  desc: boolean;
}

export interface PurchaseOrdersSettingsListModel {
  label: string;
  status: boolean;
  name: string;
}

export interface PurchaseOrdersParamsModel {
  filterRequest: FilterRequestApplyModel[] | null | undefined | [];
  x_query: string;
  x_order: string;
  x_desc: boolean;
  x_pageNumber: number;
  x_pageSize: number;
}

export interface PurchaseOrderSettingsList extends Array<PurchaseOrdersSettingsListModel> {
}

export interface PurchaseOrderDataTable {
  params: PurchaseOrdersParamsModel;
  sort: PurchaseOrdersSortSettingsModel;
  settings: PurchaseOrderSettingsList;
  filters: FilterRequestApplyModel[];
}

export interface SelectedMasterItem {
  quantity?: number | null;
  purchaseUnitPrice?: number | null;
  discount?: number | null;
  taxes?: number | null;
  purchaseOrderItemStatusId?: PurchaseOrderItemResponseModelPurchaseOrderItemStatusId | null;
  backOrders?: PurchaseOrderItemTransactionResponseModel[];
  name?: string | null;
  masterItemExternalId?: string | null;
  masterItemTypeId?: PurchasingMasterItemResponseModelMasterItemTypeId | null;
  categories?: string[] | null;
  unitOfMeasurement?: NameExternalIdModel | null;
  containerExternalId?: string | null;
}

export enum ItemTransactionType {
  RECEIVED,
  RETURNED,
  BACKORDER,
  REFUND,
  SHIPPED,
  PICKED,
}

export interface ItemTransactionModel {
  sumQty: number;
  lastReceived: number;
  items: PurchaseOrderItemTransactionResponseModel[];
}

export interface ItemReturnedModel {
  sumQty: number;
  lastReceived: number;
  items: PurchaseOrderItemTransactionResponseModel[];
}
