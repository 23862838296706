/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../loader/loader.component.ngfactory";
import * as i2 from "../loader/loader.component";
import * as i3 from "../button/button.component.ngfactory";
import * as i4 from "../button/button.component";
import * as i5 from "../../../../../node_modules/ngx-smart-modal/ngx-smart-modal.ngfactory";
import * as i6 from "ngx-smart-modal";
import * as i7 from "@angular/common";
import * as i8 from "./confirm-modal.component";
import * as i9 from "../../../core/services/spinner.service";
var styles_ConfirmModalComponent = [];
var RenderType_ConfirmModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
function View_ConfirmModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "popup__text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmModalComponent_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ConfirmModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [["vertical", "true"]], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { vertical: [0, "vertical"] }, null)], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ConfirmModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "checkbox__input"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "checkbox__label"]], [[8, "htmlFor", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCheckbox() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkboxState; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.checkboxState; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.checkboxText; _ck(_v, 3, 0, currVal_2); }); }
function View_ConfirmModalComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], [[4, "pointerEvents", null], [4, "width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.ButtonComponent, [], { label: [0, "label"], class: [1, "class"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.confirmButtonLabel; var currVal_3 = "btn btn--red"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).PointerEventValue; var currVal_1 = i0.ɵnov(_v, 1).width; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ConfirmModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "ngx-smart-modal", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).targetPlacement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NgxSmartModalComponent_0, i5.RenderType_NgxSmartModalComponent)), i0.ɵdid(1, 245760, [["{{identifier}}", 4]], 0, i6.NgxSmartModalComponent, [i0.Renderer2, i0.ChangeDetectorRef], { closable: [0, "closable"], dismissable: [1, "dismissable"], identifier: [2, "identifier"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 17, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "popup__head"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h3", [["class", "popup__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_1)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_2)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ConfirmModalComponent_3)), i0.ɵdid(11, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_4)), i0.ɵdid(14, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["class", "popup__btn-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-button", [["labelClass", "btn--cancel"]], [[4, "pointerEvents", null], [4, "width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(17, 49152, null, 0, i4.ButtonComponent, [], { label: [0, "label"], labelClass: [1, "labelClass"], class: [2, "class"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_5)), i0.ɵdid(19, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.dismissable; var currVal_2 = _co.identifier; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = !_co.customBody; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.customBody; _ck(_v, 9, 0, currVal_6); var currVal_7 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.spinnerService.getDetailSpinner())); _ck(_v, 11, 0, currVal_7); var currVal_8 = !!_co.checkboxText; _ck(_v, 14, 0, currVal_8); var currVal_11 = _co.cancelButtonLabel; var currVal_12 = "btn--cancel"; var currVal_13 = "btn btn--blank btn--cancel"; _ck(_v, 17, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = !_co.hideConfirmButton; _ck(_v, 19, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵinlineInterpolate(1, "popup__box popup__confirm-remove ", _co.extraClass, ""); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.title; _ck(_v, 5, 0, currVal_4); var currVal_9 = i0.ɵnov(_v, 17).PointerEventValue; var currVal_10 = i0.ɵnov(_v, 17).width; _ck(_v, 16, 0, currVal_9, currVal_10); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i0.ɵdid(1, 49152, null, 0, i8.ConfirmModalComponent, [i9.SpinnerService, i6.NgxSmartModalService], null, null)], null, null); }
var ConfirmModalComponentNgFactory = i0.ɵccf("app-confirm-modal", i8.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, { title: "title", text: "text", cancelButtonLabel: "cancelButtonLabel", confirmButtonLabel: "confirmButtonLabel", identifier: "identifier", extraClass: "extraClass", customBody: "customBody", hideConfirmButton: "hideConfirmButton", dismissable: "dismissable", checkboxText: "checkboxText" }, { cancelButtonClicked: "cancelButtonClicked", confirmButtonClicked: "confirmButtonClicked" }, ["*"]);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
