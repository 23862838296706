import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormBoxes } from '@onbatch/shared/models';
import { ClickOutsideData } from '@onbatch/shared/directives';
import {
  CustomerResponseModel,
  SalesOrderCustomerResponseModel,
  SalesOrderSendEmailRequestModel
} from '@onbatch/core/services/Sales';
import {
  ConsigneeResponseModel,
  ConsignorResponseModel,
  TransferInBondSendEmailRequestModel
} from '@onbatch/core/services/Warehousing';
import { Features2 } from '@onbatch/core/services/Account';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { emailsValidator } from '@onbatch/shared/utils';
import { NgxSmartModalService } from 'ngx-smart-modal';

interface CustomerDetails
  extends CustomerResponseModel,
    SalesOrderCustomerResponseModel,
    ConsignorResponseModel,
    ConsigneeResponseModel {
}

interface SendEmailRequestModel extends TransferInBondSendEmailRequestModel, SalesOrderSendEmailRequestModel {
}

@Component({
  selector: 'app-more-emails',
  templateUrl: './more-emails.component.html'
})
export class MoreEmailsComponent {
  @Input() set details(value: CustomerDetails) {
    this._details = value;
    this.formBoxes.emails.select['items'] = this._details ? this._details.emails : null;
    if (this.form.contains('emails')) {
      setTimeout(() => this.refreshEmailsDropdown = false, 0);
      this.refreshEmailsDropdown = true;
      this.form.get('emails').setValue([]);
    }
  }

  @Input() orderExternalId: string;
  @Input() readonly = false;
  @Input() orderName = 'order';

  @Input('refreshEmailsDropdown') set refreshEmailsDropdown(value: boolean) {
    this._refreshEmailsDropdown = value;
  }

  @Output() sendEmailEvent: EventEmitter<SendEmailRequestModel> = new EventEmitter<SendEmailRequestModel>();

  form: FormGroup;
  formBoxes: FormBoxes = {
    emails: {
      fieldType: 'select-with-add',
      label: `Customer's emails`,
      placeholder: 'Select customer email',
      field: 'emails',
      multiple: true,
      tagLabel: 'Email',
      select: {
        items: []
      }
    }
  };
  _refreshEmailsDropdown = false;
  showMoreContactsModal = false;
  showSendEmailModal = false;

  isFeatureAvailable = new Features2({
    sales_EmailOrders: this.subscriptionService.getAccess(SubscriptionFeatures.SalesEmailOrders),
  });

  get details(): CustomerDetails {
    return this._details;
  }

  get areCustomerDetailsAvailable(): boolean {
    return !!this.details && (
      (this.details.phones && !!this.details.phones.length)
      || (this.details.emails && !!this.details.emails.length)
      || (this.details.agents && !!this.details.agents.length)
    );
  }

  get sendButtonLabel(): string {
    return this.details && this.details.emails && this.details.emails.length ?
      `Send ${this.orderName} to: [${this.details.emails[0]}]` : `Send ${this.orderName}`;
  }

  get modalSendButtonLabel(): string {
    return `Send ${this.orderName}`;
  }

  private _details: CustomerDetails;

  constructor(private toastr: ToastrService,
              private fb: FormBuilder,
              private modalService: NgxSmartModalService,
              private subscriptionService: SubscriptionService) {
    this.form = this.fb.group({
      emails: new FormControl(null, emailsValidator)
    });
  }

  clickedOutsideSendButton(data: ClickOutsideData) {
    if (data.value) {
      this.showSendEmailModal = false;
    }
  }

  closeMoreContactsModal(event: ClickOutsideData): void {
    if (event.value) {
      this.showMoreContactsModal = false;
    }
  }

  sendEmail(emails: string[]): void {
    if (this.isFeatureAvailable.sales_EmailOrders) {
      if (emails && emails.length) {
        this.sendEmailEvent.emit(<SendEmailRequestModel>{
          emails: emails,
          externalId: this.orderExternalId
        });
      } else {
        this.modalService.open(`sendEmailModal`);
      }
    }
  }

  toggleMoreContactsModal(): void {
    this.showSendEmailModal = false;
    this.showMoreContactsModal = !this.showMoreContactsModal;
  }

  get refreshEmailsDropdown() {
    return this._refreshEmailsDropdown;
  }

  get isSendPossible(): boolean {
    if (this.form.get('emails')) {
      return (
        !this.form.get('emails').value || (this.form.get('emails').value && this.form.get('emails').value.length === 0)
      );
    }
  }
}
