import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { difference, flatten, groupBy as _groupBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  FinishedGoodItemsSelectionListResponseModel,
  SalesOrderItemResponseModel,
  ShipmentIdsRequestModel,
  ShipmentIdsResponseModel,
  ShipmentLotsQuantityFinishedGoodResponseModel,
  ShipmentUnitIdsResponseModel
} from '@onbatch/core/services/Sales';
import { AuthService } from '@onbatch/core/services/auth.service';
import { BaseTable } from '@onbatch/shared/classes/base-table';
import { MultiSelectionDirective } from '../../multi-selection.directive';
import { MultiSelection } from '../../multi-selection';
import { FinishedGoodsSelectItemsService } from '../../../../services/finished-goods-select-items.service';
import { groupBy } from '@onbatch/shared/utils';
import { RangeItem } from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import { RangeRemoveData } from '../../selected-items-modal/selected-items-modal.component';
import { ShippingService } from '@onbatch/shared/services/shipping.service';
import { FormatsService } from '@onbatch/shared/services/formats.service';

export interface GroupPayload {
  action: 'select' | 'deselect';
  lotId: string;
  unitIds: ShipmentUnitIdsResponseModel[],
}

export enum GroupPayloadAction {
  SELECT = 'select',
  DESELECT = 'deselect'
}

@Component({
  selector: 'app-finished-goods-manual-selection-table',
  templateUrl: './finished-goods-manual-selection-table.component.html',
  styleUrls: ['./finished-goods-manual-selection-table.component.scss'],
  animations: [
    trigger(
      'showHide', [
        state('show', style({
          visibility: 'visible',
        })),
        state('hide', style({
          visibility: 'hidden',
        })),
        transition('show => hide', [
          animate('300ms')
        ]),
        transition('hide => show', [
          animate('300ms')
        ])
      ]
    )
  ],
})
export class FinishedGoodsManualSelectionTableComponent extends BaseTable implements OnInit, AfterViewInit, OnDestroy {
  get checkboxesChanges(): Observable<any> {
    return this.checkboxes.changes.pipe(
      takeUntil(this.destroy$)
    );
  }
  @Input() set finishedGoods(value: FinishedGoodItemsSelectionListResponseModel[]) {
    this._finishedGoods = value;
    this.groupByKey();
  }
  get finishedGoods(): FinishedGoodItemsSelectionListResponseModel[] {
    return this._finishedGoods;
  }
  @Input() set setCheckedItems(checkedItems: string[]) {
    this.checkedItems = checkedItems;
    this.calculateRemainingQuantity();
  };

  @ViewChildren(MultiSelectionDirective) checkboxes: QueryList<MultiSelectionDirective>;
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;
  @Input() remainingQuantityExceeded: boolean;
  @Input() lotIdWithQuantity: ShipmentLotsQuantityFinishedGoodResponseModel[];
  @Input() item: SalesOrderItemResponseModel;

  @Output() remainingQuantityChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() remainingQuantityExceededChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemToggled: EventEmitter<FinishedGoodItemsSelectionListResponseModel> = new EventEmitter<FinishedGoodItemsSelectionListResponseModel>();
  @Output() rangeRemoveEvent: EventEmitter<RangeRemoveData> = new EventEmitter<RangeRemoveData>();
  @Output() groupClicked: EventEmitter<GroupPayload> = new EventEmitter<GroupPayload>();
  @Output() checkedAllEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkedItems: string[]
  checkboxList: MultiSelection<MultiSelectionDirective>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  groupedByLotIdKeys: string[];
  groupedByLotIdValues: FinishedGoodItemsSelectionListResponseModel[][];
  checkedGroupsLotIds: Set<string> = new Set<string>();

  private _finishedGoods: FinishedGoodItemsSelectionListResponseModel[] = [];
  private ranges: RangeItem[];

  constructor(public authService: AuthService,
              public finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
              public shippingService: ShippingService,
              public formatsService: FormatsService) {
    super();
    this.getItemIdx = this.getItemIdx.bind(this);
  }

  ngOnInit() {
    this.finishedGoodsSelectItemsService.getSelectedRanges()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((rangesMap: Map<string, RangeItem[]>) => {
        this.ranges = rangesMap.get(this.getFGExternalId());
        const groupedByLotId: { [id: string]: RangeItem[]} = _groupBy(this.ranges, 'lotId');
        for (let key in groupedByLotId) {
          const lotIdWithQuantityElement: ShipmentLotsQuantityFinishedGoodResponseModel = this.lotIdWithQuantity.find((el: ShipmentLotsQuantityFinishedGoodResponseModel) => el.lotId === key);
          const quantityOfLotId: number = !!lotIdWithQuantityElement ? lotIdWithQuantityElement.quantity : 0;
          const quantityChecked: number = groupedByLotId[key].map((range: RangeItem) => range.length).reduce((prev: number, current: number) => prev + current, 0);
          if (quantityOfLotId === quantityChecked && quantityChecked > 0) {
            this.checkedGroupsLotIds.add(key);
          } else {
            this.checkedGroupsLotIds.delete(key);
          }
        }
      });
    this.groupByKey();
  }

  ngAfterViewInit() {
    this.checkboxList = new MultiSelection<MultiSelectionDirective>(this.checkboxes);
    this.checkboxesChanges.subscribe(() => {
      this.checkboxList = new MultiSelection<MultiSelectionDirective>(this.checkboxes);
    });
  }

  ngOnDestroy() {
    this.checkboxList.destroy();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  calculateRemainingQuantity(): void {
    if (!!this.remainingQuantity) {
      const remainQuantity: number = this.remainingQuantity - this.checkedItems.length;
      const quantityExceeded = remainQuantity < 0;
      this.remainingQuantityExceeded = quantityExceeded;
      this.remainingQuantityChange.emit(remainQuantity);
      this.remainingQuantityExceededChange.emit(quantityExceeded);
      this.finishedGoodsSelectItemsService.setIsSubmitDisabled(quantityExceeded);
    }
  }

  checkAllShippingItems(items: any[], checkIfItemInfinite: boolean = false): void {
    if (items.length) {
      this.checkedAll = !this.checkedAll;
    }
    if (!this.checkedAll) {
      if (this.checkedItems.length === 0) {
        items.map(item => {
          this.checkedItems = [...this.checkedItems, item.unitId];
        });
      } else {
        this.checkedItems = [];
      }
    } else {
      if (checkIfItemInfinite) {
        this.checkedItems = [...this.checkedItems, ...items.map(item => {
          if (!item.isBlocked) {
            return item.unitId;
          }
        })
        ];
      }
      this.checkedItems = this.checkedItems.filter((item: string, pos: number) => {
        return this.checkedItems.indexOf(item) === pos;
      });
    }
    this.checkedItems = this.checkedItems.filter(el => el);
    this.checkedItemsListChanged.emit(this.checkedItems);
    this.checkedAllEvent.emit(this.checkedAll);
  }

  checkShippingItemsGroup(lotId: string) {
    const isDeselecting: boolean = this.checkedGroupsLotIds.has(lotId);
    if (isDeselecting) {
      this.checkedGroupsLotIds.delete(lotId);
    } else {
      this.checkedGroupsLotIds.add(lotId);
    }
    this.shippingService.validateUnitId(new ShipmentIdsRequestModel({
      startId: null,
      endId: null,
      lotId,
      masterItemExternalId: this.getFGExternalId(false),
      isRemnant: this.item.isRemnant,
      numberOfUnits: this.item.numberOfUnits
    })).subscribe((data: ShipmentIdsResponseModel) => {
      this.groupClicked.emit({
        action: isDeselecting ? GroupPayloadAction.DESELECT : GroupPayloadAction.SELECT,
        lotId: data.lotId,
        unitIds: data.unitIds
      });
    });
  }

  checkItem(item: FinishedGoodItemsSelectionListResponseModel): void {
    if (item.isBlocked) {
      return;
    }
    this.itemToggled.emit(item);

    const unitId = item.unitId;
    if (!this.checkedItems.includes(unitId)) {
      this.checkedItems = [...this.checkedItems, unitId];
    } else {
      this.checkedItems = this.checkedItems.filter(el => el !== unitId);
    }

    const unitIds: string[] = this._finishedGoods.map((item: FinishedGoodItemsSelectionListResponseModel) => item.unitId) || [];
    if (this.checkedItems && this.checkedItems.length === unitIds.length) {
      this.checkedAll = this.checkedItems.every((unitId: string) => unitIds.includes(unitId));
    } else {
      this.checkedAll = false;
    }
  }

  handleRangeRemoveEvent(rangeData: RangeRemoveData) {
    this.rangeRemoveEvent.emit(rangeData);
    if (!this.checkedItems.length) {
      this.checkedAll = false;
    }
  }

  groupByKey(): void {
    const groupKey = 'lotId';
    const groupByMasterItem = groupBy(groupKey);
    const groupedData: Record<string, FinishedGoodItemsSelectionListResponseModel[]> = groupByMasterItem(this.finishedGoods);

    this.groupedByLotIdValues = Object.values(groupedData);
    this.groupedByLotIdKeys = Object.keys(groupedData);

    const unitsIds: string[] =
      flatten(this.groupedByLotIdValues.map(
        (el, index) => this.groupedByLotIdValues[index].map((item) => item.unitId)));
    const diff: string[] = difference(unitsIds, this.checkedItems);
    this.checkedAll = !diff.length;
  }

  getLotQuantity(lotId: string): number | void {
    if (this.lotIdWithQuantity) {
      const lotData: ShipmentLotsQuantityFinishedGoodResponseModel = this.lotIdWithQuantity.find(lotIdData => lotIdData.lotId === lotId)
      return lotData ? lotData.quantity : 0;
    }
  }

  getFGExternalId(withNumberOfUnits: boolean = true): string {
    const masterItemExternalId = this._finishedGoods[0].masterItemExternalId;
    const externalId = `${masterItemExternalId}${withNumberOfUnits ? this._finishedGoods[0].packageNumberOfUnits : ''}`;

    if (!!this._finishedGoods.length) {
      if (!this.item.numberOfUnits) {
        return masterItemExternalId;
      }
      return externalId;
    }
  }

  private getItemIdx(item: any): number {
    return this.finishedGoods.indexOf(item);
  }
}

