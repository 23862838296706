<div class="toast-line"></div>
<div class="toast-inner" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div *ngIf="message && options.enableHtml" [innerHTML]="message"></div>
  <div class="toast-inner_wrap" *ngIf="message && !options.enableHtml">
    <span class="toast-date">{{date}}</span>
    <span class="toast-title">{{title}}</span>
    <span class="toast-subtitle">{{message}}</span>
  </div>
  <!-- <app-button [class]="'btn btn--blank toast__undo'" [label]="'Undo'" (click)="action($event, 'undo')"></app-button> -->
  <a class="toast-close" *ngIf="options.closeButton" (click)="remove()"></a>
</div>