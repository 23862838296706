import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { AuthService, IFormattingSettings } from '../../core/services/auth.service';
import {
  CostPriceFormatResponseModel,
  FormattingResponseModelMeasurementSystem,
  ManufacturingResponseModelAlcoholContent,
  ManufacturingResponseModelPureAlcohol
} from '../../core/services/Account';
import {pricePrefix} from '@onbatch/shared/constants';
import {PriceFormatPipe} from '@onbatch/shared/pipes';
import {numberToString} from '@onbatch/shared/utils';

enum PatternType {
  Amount,
  Cost
}

type RealMeasurementSystem = FormattingResponseModelMeasurementSystem.Imperial | FormattingResponseModelMeasurementSystem.Metric;

@Injectable()
export class FormatsService {
  formattingSettings = new BehaviorSubject<IFormattingSettings>({
    amountsPrecision: 2,
    costPricePrecision: 2,
    costPriceFormat: new CostPriceFormatResponseModel({
      decimals: '.',
      thousands: ','
    }),
    measurementSystem: FormattingResponseModelMeasurementSystem.Imperial,
    pureAlcohol: ManufacturingResponseModelPureAlcohol.ProofGallons,
    alcoholContent: ManufacturingResponseModelAlcoholContent.Proof,
  });

  constructor(private authService: AuthService,
              private priceFormatPipe: PriceFormatPipe) {
    this.authService.formattingSettings.subscribe(
      (formattingSettings: IFormattingSettings) => this.formattingSettingsHandler(formattingSettings)
    );
  }

  getPattern(isInteger: boolean, type: PatternType): MaskPattern {
    const amountsPrecision = isInteger ? 0 : this.formattingSettings.value.amountsPrecision;
    const costPricePrecision = isInteger ? 0 : this.formattingSettings.value.costPricePrecision;
    if (type === PatternType.Cost) {
      return {
        separator: this.formattingSettings.value.costPriceFormat.thousands,
        decimalMarker: this.formattingSettings.value.costPriceFormat.decimals,
        precision: `separator.${costPricePrecision}`,
        precisionNumber: costPricePrecision,
      };
    } else {
      return {
        separator: this.formattingSettings.value.costPriceFormat.thousands,
        decimalMarker: this.formattingSettings.value.costPriceFormat.decimals,
        precision: `separator.${amountsPrecision}`,
        precisionNumber: amountsPrecision,
      };
    }
  }

  patternForCost(): MaskPattern {
    return this.getPattern(false, PatternType.Cost);
  }

  patternForCostInteger(): MaskPattern {
    return this.getPattern(true, PatternType.Cost);
  }

  patternForAmount(): MaskPattern {
    return this.getPattern(false, PatternType.Amount);
  }

  patternForAmountInteger(): MaskPattern {
    return this.getPattern(true, PatternType.Amount);
  }

  costPrecision(): number {
    return this.formattingSettings.value.costPricePrecision;
  }

  getTimeFormat(): TimeFormat {
    return this.formattingSettings.value.timeFormat === TimeFormat.TwelveHours ? TimeFormat.TwelveHours : TimeFormat.TwentyFourHours;
  }

  getDateTimeFormat(): string {
    return this.formattingSettings.value.dateFormat;
  }

  getMeasurementSystem(): FormattingResponseModelMeasurementSystem {
    return this.formattingSettings.value.measurementSystem;
  }

  getWeightUoM(fullName: boolean = false): string {
    const table: Record<RealMeasurementSystem, Record<string, string>> = {
      [FormattingResponseModelMeasurementSystem.Metric]: {
        'full': 'Kilograms',
        'short': 'kg',
      },
      [FormattingResponseModelMeasurementSystem.Imperial]: {
        'full': 'Pounds',
        'short': 'lbs',
      },
    };
    const measurementSystem: RealMeasurementSystem = this.omitCommon(this.formattingSettings.value.measurementSystem);
    return table[measurementSystem][fullName ? 'full' : 'short'];
  }

  getVolumeUoM(fullName: boolean = false): string {
    const table: Record<RealMeasurementSystem, Record<string, string>> = {
      [FormattingResponseModelMeasurementSystem.Metric]: {
        'full': 'Liters',
        'short': 'l',
      },
      [FormattingResponseModelMeasurementSystem.Imperial]: {
        'full': 'Gallons',
        'short': 'gal',
      },
    };
    const measurementSystem: RealMeasurementSystem = this.omitCommon(this.formattingSettings.value.measurementSystem);
    return table[measurementSystem][fullName ? 'full' : 'short'];
  }

  getAlcoholContentUoM(swaggerFormatted: boolean = false): string {
    const table: Record<ManufacturingResponseModelAlcoholContent, Record<string, string>> = {
      [ManufacturingResponseModelAlcoholContent.ABV]: {
        'full': 'Abv',
        'short': 'ABV',
      },
      [ManufacturingResponseModelAlcoholContent.Proof]: {
        'full': 'Proof',
        'short': 'Proof',
      },
    };
    return table[this.formattingSettings.value.alcoholContent][swaggerFormatted ? 'full' : 'short'];
  }

  getPureAlcoholUoM(fullName: boolean = false): string {
    const table: Record<ManufacturingResponseModelPureAlcohol, Record<string, string>> = {
      [ManufacturingResponseModelPureAlcohol.LAA]: {
        'full': 'LAA',
        'short': 'LAA',
      },
      [ManufacturingResponseModelPureAlcohol.ProofGallons]: {
        'full': 'Proof Gallons',
        'short': 'PG',
      },
    };
    return table[this.formattingSettings.value.pureAlcohol][fullName ? 'full' : 'short'];
  }

  getLengthUoM(fullName: boolean = false): string {
    const table: Record<RealMeasurementSystem, Record<string, string>> = {
      [FormattingResponseModelMeasurementSystem.Metric]: {
        'full': 'Centimeters',
        'short': 'cm',
      },
      [FormattingResponseModelMeasurementSystem.Imperial]: {
        'full': 'Inches',
        'short': 'inches',
      },
    };
    const measurementSystem: RealMeasurementSystem = this.omitCommon(this.formattingSettings.value.measurementSystem);
    return table[measurementSystem][fullName ? 'full' : 'short'];
  }

  getTemperatureUoM(): string {
    return this.formattingSettings.value.measurementSystem === FormattingResponseModelMeasurementSystem.Metric ? '°C' : '°F';
  }

  getObservedTemperature(): string {
    return this.formattingSettings.value.measurementSystem === FormattingResponseModelMeasurementSystem.Metric ? '20' : '60';
  }

  getPriceWithTrailingZeros(value: string, separator: string = null, precision: number = null): string {
    if (value) {
      return this.preparePriceValue(value, separator, precision);
    } else {
      return value;
    }
  }

  getPriceWithTrailingZerosWithoutPricePrecision(value: string, separator: string = null): string {
    if (value) {
      return this.preparePriceValue(value, separator);
    } else {
      return value;
    }
  }

  getSmallestValueWithPrecision(precision?: number) {
    const value = 0;
    const settings = this.authService.getFormattingSettingsObservable();
    const amountPrecision = precision || settings.amountsPrecision;
    const valueWithPrecision = value.toFixed(amountPrecision);
    return amountPrecision > 0 ? Number(`${valueWithPrecision.slice(0, -1)}1`) : value;
  }

  private omitCommon(formattingSystem: FormattingResponseModelMeasurementSystem): RealMeasurementSystem {
    return formattingSystem === FormattingResponseModelMeasurementSystem.Common
      ? FormattingResponseModelMeasurementSystem.Imperial
      : formattingSystem;
  }

  private preparePriceValue(value: string, separator: string = null, precision: number = null): string {
    const includesPrefix = value.includes(pricePrefix);
    const preparedValue: string = value
      // Get numeric value without prefix and thousand separators
      // Set decimal separator to `.`
      .replace(pricePrefix, '')
      .replace(separator || this.formattingSettings.value.costPriceFormat.thousands, '')
      .replace(',', '.');
    const num: string = numberToString(+preparedValue);
    if (precision) {
      return `${includesPrefix ? pricePrefix : ''}${this.priceFormatPipe.transform(num, precision)}`;
    }
    return `${includesPrefix ? pricePrefix : ''}${num}`;
  }

  private formattingSettingsHandler(formattingSettings: IFormattingSettings): void {
    let formatting: IFormattingSettings = {} as IFormattingSettings;
    if (formattingSettings) {
      formatting = {
        timezone: formattingSettings.timezone,
        dateFormat: formattingSettings.dateFormat,
        timeFormat: formattingSettings.timeFormat,
        measurementSystem: formattingSettings.measurementSystem,
        costPriceFormat: formattingSettings.costPriceFormat,
        costPricePrecision: formattingSettings.costPricePrecision,
        amountsPrecision: formattingSettings.amountsPrecision,
        alcoholContent: formattingSettings.alcoholContent,
        pureAlcohol: formattingSettings.pureAlcohol
      };
    }
    this.formattingSettings.next({...this.formattingSettings.value, ...formatting});
  }
}

export enum TimeFormat {
  TwentyFourHours = 'H:mm',
  TwelveHours = 'h:mm aaa'
}

export interface MaskPattern {
  precision: string;
  precisionNumber?: number;
  decimalMarker: string;
  separator: string;
}
