import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'timestampDate' })
export class TimestampDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, format?: string, timezone?: string, locale?: string): string {
        const timestampSeconds = value * 1000;
        return super.transform(timestampSeconds, format, timezone, locale);
    }
}
