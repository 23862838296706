import { Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { EnvService } from '../../core/services/Env';
import { HttpClient } from '@angular/common/http';
import {
  InventoryClient,
  BillOfMaterialsItemUpdateModel,
  BillOfMaterialsResponseModel,
  ExportRequestModel,
  FileResponse,
  FileType,
  FilterOptions,
  FilterRequestApplyModel,
  InventoryHistoryLogResponse,
  MasterItemBillOfMaterialsLookUpRequestModel,
  MasterItemContainerRequestModel,
  MasterItemContainerResponseModel,
  MasterItemFullResponseModel,
  MasterItemListResponseModel,
  MasterItemQuantityRequestModel,
  MasterItemRequestModel,
  MasterItemResponseModel,
  MasterItemTemplateRequestModel,
  MasterItemTemplateResponseModel,
  MasterItemType,
  PackageRequestModel,
  PackageResponseFullModel,
  PageInfo,
  PaginatedResponseOfInventoryHistoryLogListResponse,
  PaginatedResponseOfLookUpResponse,
  PaginatedResponseOfLookUpResponseTreeWithDescription,
  PaginatedResponseOfMasterItemListResponseModel,
  PaginatedResponseOfMasterItemLookupResponse,
  PaginatedResponseOfMasterItemLotsListResponse,
  PaginatedResponseOfMasterItemPurchaseListResponse, PaginatedResponseOfMasterItemSalesListResponse,
  RemovalResponseModel,
  SpiritItemRequestModel,
  SpiritItemResponseModel, PaginatedResponseOfInventoryFacilityLookUpResponseModel, MasterItemTypeId
} from '../../core/services/InventoryRx';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MasterItemDataTable } from '../../shared/models';
import { MasterItemDataTableDefaults } from './master-item-statics.enum';


@Injectable({
  providedIn: 'root'
})

/*
 * This is new version of Inventory client
 */

export class MasterItemsService {

  locationsAddedToMasterItem = new BehaviorSubject<MasterItemQuantityRequestModel[]>([]);
  masterItemSidebar = new BehaviorSubject<boolean>(false);
  itemID: Subject<string> = new Subject<string>();

  masterItemDataTableParams = new BehaviorSubject<MasterItemDataTable>(null);
  masterItemList: Subject<Array<MasterItemListResponseModel>> = new Subject<Array<MasterItemListResponseModel>>();
  masterItemPagination = new BehaviorSubject<PageInfo>(null);
  masterItemFiltersOptions = new BehaviorSubject<FilterOptions[]>(null);

  masterItemSingle = new BehaviorSubject<MasterItemFullResponseModel | null>(null);

  private inventoryClient: InventoryClient;

  constructor(private authService: AuthService,
              private localStorage: LocalStorage,
              private envService: EnvService,
              private httpClient: HttpClient) {
    this.inventoryClient = new InventoryClient(httpClient, envService.apiUrl);

    this.localStorage.getItem(
      this.authService.getStorageKey('master-item-data-table')
    ).subscribe((data: MasterItemDataTable | null) => {
      this.masterItemDataTableParams.next(data || MasterItemDataTableDefaults);
    });
  }

  postMasterItem(model?: MasterItemRequestModel | null | undefined): Observable<MasterItemResponseModel> {
    return this.inventoryClient.masterItemPost(model);
  }

  putMasterItem(externalId: string, model?: MasterItemRequestModel | null | undefined): Observable<MasterItemResponseModel> {
    return this.inventoryClient.masterItemByExternalIdPut(externalId, model);
  }

  putSpiritItem(masterItemExternalId: string, model?: SpiritItemRequestModel | null | undefined): Observable<SpiritItemResponseModel> {
    return this.inventoryClient.spiritItemByMasterItemExternalIdPut(masterItemExternalId, model);
  }

  putContainer(externalId: string, model?: MasterItemContainerRequestModel | null | undefined): Observable<MasterItemContainerResponseModel> {
    return this.inventoryClient.masterItemByExternalIdContainerPut(externalId, model);
  }

  putPackage(externalId: string, model?: PackageRequestModel | null | undefined): Observable<PackageResponseFullModel> {
    return this.inventoryClient.masterItemByExternalIdPackagePut(externalId, model);
  }

  putBillOfMaterials(externalId: string, updateModel?: BillOfMaterialsItemUpdateModel[] | null | undefined): Observable<BillOfMaterialsResponseModel[]> {
    return this.inventoryClient.masterItemByExternalIdBillOfMaterialsPut(externalId, updateModel);
  }

  // Single

  fetchMasterItemSingle(externalId: string): void {
    this.getSingle(externalId).subscribe(data => this.setMasterItemSingle(data));
  }

  getSingle(externalId: string): Observable<MasterItemFullResponseModel> {
    return this.inventoryClient.masterItemByExternalIdGet(externalId);
  }

  getBillOfMaterials(externalId: string): Observable<BillOfMaterialsResponseModel[]> {
    return this.inventoryClient.masterItemByExternalIdBillOfMaterialsGet(externalId);
  }

  getSpirit(masterItemExternalId: string): Observable<SpiritItemResponseModel> {
    return this.inventoryClient.spiritItemByMasterItemExternalIdGet(masterItemExternalId);
  }

  setMasterItemSingle(item: MasterItemFullResponseModel) {
    this.masterItemSingle.next(item);
    this.masterItemSidebar.next(true);
  }

  getMasterItemSingle(): Observable<MasterItemFullResponseModel> {
    return this.masterItemSingle.asObservable();
  }

  deleteItems(externalIds?: string[] | null | undefined): Observable<RemovalResponseModel> {
    return this.inventoryClient.masterItemDelete(externalIds);
  }

  // Locations

  getLocationsAddedToMasterItem(): Observable<MasterItemQuantityRequestModel[]> {
    return this.locationsAddedToMasterItem.asObservable();
  }

  setLocationsAddedToMasterItem(value: MasterItemQuantityRequestModel[]) {
    this.locationsAddedToMasterItem.next(value);
  }

  // UI

  setMasterItemSide(item: boolean) {
    this.masterItemSidebar.next(item);
  }

  getMasterItemSide(): Observable<boolean> {
    return this.masterItemSidebar.asObservable();
  }

  setItemID(data: string) {
    this.itemID.next(data);
  }

  clearID() {
    this.itemID.next(null);
  }

  masterItemSingleClear() {
    this.masterItemSingle.next(null);
    this.masterItemSidebar.next(false);
  }

  // Templates

  getSingleMasterItemTemplate(externalId: string): Observable<MasterItemTemplateResponseModel> {
    return this.inventoryClient.templateMasterItemByExternalIdGet(externalId);
  }

  updateTemplate(
    externalId: string,
    model?: MasterItemTemplateRequestModel | null | undefined
  ): Observable<MasterItemTemplateResponseModel> {
    return this.inventoryClient.templateMasterItemByExternalIdPut(externalId, model);
  }

  deleteTemplate(externalId: string): Observable<void> {
    return this.inventoryClient.templateMasterItemByExternalIdDelete(externalId);
  }

  postMasterItemTemplate(
    model?: MasterItemTemplateRequestModel | null | undefined
  ): Observable<MasterItemTemplateResponseModel> {
    return this.inventoryClient.templateMasterItemPost(model);
  }

  // Create

  getLastNumber(): Observable<number> {
    return this.inventoryClient.masterItemLastItemNumberGet();
  }

  validateItemNumber(itemNumber: number): Observable<boolean> {
    return this.inventoryClient.masterItemItemNumberByItemNumberGet(itemNumber);
  }

  // List

  getMasterItemList(): Observable<Array<MasterItemListResponseModel>> {
    return this.masterItemList.asObservable();
  }

  filterMasterItems(
    filterRequest?: FilterRequestApplyModel[] | null | undefined,
    includeInactive: boolean = false,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemListResponseModel> {
    return this.inventoryClient.masterItemFilterPut(filterRequest, includeInactive, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  fetchNewList() {
    if (!this.masterItemDataTableParams) {
      return;
    }

    const params = this.masterItemDataTableParams.value.params;
    this.filterMasterItems(
      this.masterItemDataTableParams.value.filters,
      params.includeInactive,
      params.x_pageNumber,
      params.x_pageSize,
      params.x_query,
      params.x_order,
      params.x_desc
    ).subscribe(data => {
      this.masterItemList.next(data.list);
      this.setMasterItemPagination(data.pageInfo);
      this.setMasterItemFiltersOptions(data.filterColumns);
    });
  }

  setMasterItemPagination(item: PageInfo) {
    this.masterItemPagination.next(item);
  }

  setMasterItemFiltersOptions(item: FilterOptions[]) {
    this.masterItemFiltersOptions.next(item);
  }

  getMasterItemPagination(): Observable<PageInfo> {
    return this.masterItemPagination.asObservable();
  }

  getMasterItemFiltersOptions(): Observable<FilterOptions[]> {
    return this.masterItemFiltersOptions.asObservable();
  }

  getDataTableParams(): Observable<MasterItemDataTable> {
    return this.masterItemDataTableParams.asObservable();
  }

  setDataTableParams(data: any) {
    const newVal = { ...this.masterItemDataTableParams.value, ...data };
    this.masterItemDataTableParams.next(newVal);
    const key = this.authService.getStorageKey('master-item-data-table');
    this.localStorage.setItemSubscribe(key, newVal);
    this.fetchNewList();
  }

  // Lookups

  lookUpItems(
    withoutPricing?: boolean | null | undefined,
    withoutRawMaterials: boolean = false,
    withoutFinishedGoods: boolean = true,
    withoutSemiFinishedGoods: boolean = false,
    withoutAlcohol: boolean = false,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemLookupResponse> {
    return this.inventoryClient.lookUpMasteritemsGet(withoutPricing, withoutRawMaterials, withoutFinishedGoods, withoutSemiFinishedGoods, withoutAlcohol, x_query, x_order, x_desc, x_pageNumber, x_pageSize
    );
  }

  lookupCategories(
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpCategoriesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupFermentable(
    categoryExternalId?: string | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpFermentablematerialGet(categoryExternalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookUpBillOfMaterials(
    request?: MasterItemBillOfMaterialsLookUpRequestModel | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemLookupResponse> {
    return this.inventoryClient.lookUpMasteritemsBillOfMaterialsPut(request, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupUoM(
    masterItemType?: MasterItemType | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfLookUpResponse> {
    return this.inventoryClient.lookUpUnitofmeasurementGet(masterItemType, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupLocations(masterItemTypeId?: MasterItemTypeId | null | undefined,
                  x_query?: string | null | undefined,
                  x_order?: string | null | undefined,
                  x_desc?: any | null | undefined,
                  x_pageNumber?: any | null | undefined,
                  x_pageSize?: any | null | undefined): Observable<PaginatedResponseOfInventoryFacilityLookUpResponseModel> {
    return this.inventoryClient.lookUpFacilitiesGet(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  exportFile(fileType: FileType, exportRequestModel?: ExportRequestModel | null | undefined): Observable<FileResponse> {
    return this.inventoryClient.masterItemDownloadByFileTypePut(fileType, exportRequestModel);
  }

  changeMasterItemStatus(externalId: string, status: boolean): Observable<RemovalResponseModel> {
    return this.inventoryClient.masterItemByExternalIdSetstatusByStatusPut(externalId, status);
  }

  removeItem(externalIds?: string[] | null | undefined): Observable<RemovalResponseModel> {
    return this.inventoryClient.masterItemDelete(externalIds);
  }

  // History

  getSingleHistory(
    externalId: string,
    from?: number | null | undefined,
    to?: number | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfInventoryHistoryLogListResponse> {
    return this.inventoryClient.inventoryHistoryLogMasterItemByExternalIdGet(externalId, from, to, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  getSingleHistoryLogById(externalId: string): Observable<InventoryHistoryLogResponse> {
    return this.inventoryClient.inventoryHistoryLogByExternalIdGet(externalId);
  }

  // Lots

  getLots(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemLotsListResponse> {
    return this.inventoryClient.masterItemByExternalIdLotsGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  // Purchases

  getPurchases(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemPurchaseListResponse> {
    return this.inventoryClient.masterItemByExternalIdPurchaseGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  // Sales

  getSales(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Observable<PaginatedResponseOfMasterItemSalesListResponse> {
    return this.inventoryClient.masterItemByExternalIdSalesGet(externalId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }
}
