import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomerAddress } from '@onbatch/shared/interfaces/customer-address.interface';
import { CustomerAddressType } from '@onbatch/shared/enums/customer-address-type.enum';
import { ConsigneeResponseModel, ConsignorResponseModel } from '@onbatch/core/services/Warehousing';
import {
  CustomerAddressResponseModel,
  CustomerResponseModel,
  SalesOrderCustomerAddressRequestModel,
  SalesOrderCustomerResponseModel,
} from '@onbatch/core/services/Sales';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
})
export class CustomerDetailsComponent {
  @Input() customBillingAddress: SalesOrderCustomerAddressRequestModel;
  @Input() customShippingAddress: SalesOrderCustomerAddressRequestModel;
  @Input() isBillingAddressRequired = false;
  @Input() isShippingAddressRequired = false;
  @Input() formSubmitted = false;
  @Input() details: CustomerDetails;
  @Input() readonly = false;

  @Input('customerShippingAddressExternalId') set customerShippingAddressExternalId(externalId: string) {
    this.customerShippingAddress = !this.readonly
      ? this.details.shippingAddresses.find((address: CustomerAddressResponseModel) => {
          return address.externalId === externalId;
        })
      : this.details.shippingAddress;
  }

  @Input('customerBillingAddressExternalId') set customerBillingAddressExternalId(externalId: string) {
    this.customerBillingAddress = !this.readonly
      ? this.details.billingAddresses.find((address: CustomerAddressResponseModel) => address.externalId === externalId)
      : this.details.billingAddress;
  }

  @Output() addressChanged = new EventEmitter<CustomerAddress>();
  @Output() newAddressClicked = new EventEmitter<CustomerAddressType>();

  customerShippingAddress: CustomerAddressResponseModel;
  customerBillingAddress: CustomerAddressResponseModel;

  get shippingAddress(): CustomerAddressResponseModel | SalesOrderCustomerAddressRequestModel {
    return this.customShippingAddress || this.customerShippingAddress;
  }

  get billingAddress(): CustomerAddressResponseModel | SalesOrderCustomerAddressRequestModel {
    return this.customBillingAddress || this.customerBillingAddress;
  }

  readonly customerAddressType = CustomerAddressType;

  constructor() {}

  onAddressChange(externalId: string, type: CustomerAddressType): void {
    this.addressChanged.emit(<CustomerAddress>{
      type: type,
      externalId: externalId,
    });
  }

  onNewAddressClick(type: CustomerAddressType): void {
    this.newAddressClicked.emit(type);
  }
}

interface CustomerDetails
  extends CustomerResponseModel,
    SalesOrderCustomerResponseModel,
    ConsignorResponseModel,
    ConsigneeResponseModel {}
