import {
  Component, EventEmitter, Input, Output, QueryList, ViewChildren,
} from '@angular/core';
import {BaseTable} from '@onbatch/shared/classes/base-table';
import {
  SalesOrderItemResponseModel, SalesOrderItemTransactionResponseModel,
  SemiFinishedGoodItemsSelectionListResponseModel, ShippedItemRequestModel,
} from '@onbatch/core/services/Sales';
import {FormatsService} from '@onbatch/shared/services/formats.service';
import {MultiSelectionDirective} from '@onbatch/shared/components/manual-selection/multi-selection.directive';
import {SemiFinishedGoodsSelectItemsService} from '@onbatch/shared/services/semi-finished-goods-select-items.service';
import {Subject} from 'rxjs';
import { uniq } from 'lodash';

@Component({
  selector: 'app-semi-finished-goods-manual-selection-table',
  templateUrl: './semi-finished-goods-manual-selection-table.component.html',
  styleUrls: ['./semi-finished-goods-manual-selection-table.component.scss'],
})
export class SemiFinishedGoodsManualSelectionTableComponent extends BaseTable {
  @Input() set semiFinishedGoods(value: SemiFinishedGoodItemsSelectionListResponseModel[]) {
    this._semiFinishedGoods = value;
  }
  @Input() set setCheckedItems(checkedItems: string[]) {
    this.checkedItems = checkedItems;
    this.shippedItems = [];
    this.removedShippedItems = [];
    if (!this.localCheckedItems.length) {
      this.localCheckedItems = checkedItems;
    }
    this.calculateRemainingQuantity();
  }
  get semiFinishedGoods(): SemiFinishedGoodItemsSelectionListResponseModel[] {
    return this._semiFinishedGoods;
  }

  @ViewChildren(MultiSelectionDirective) checkboxes: QueryList<MultiSelectionDirective>;
  @Input() remainingQuantity: number;
  @Input() initRemainingQuantity: number;
  @Input() remainingQuantityExceeded: boolean;
  @Input() item: SalesOrderItemResponseModel;

  @Output() checkedAllEvent = new EventEmitter<boolean>();
  @Output() itemToggled = new EventEmitter<SemiFinishedGoodItemsSelectionListResponseModel>();
  @Output() remainingQuantityChange = new EventEmitter<number>();
  @Output() remainingQuantityExceededChange = new EventEmitter<boolean>();

  checkedItems: Array<string> = [];
  checkedAll = false;
  destroy$ = new Subject<boolean>();
  shippedItems: string[] = [];
  removedShippedItems: string[] = [];

  private _semiFinishedGoods: SemiFinishedGoodItemsSelectionListResponseModel[] = [];
  private localCheckedItems: string[] = [];
  private localCheckedItemsCount = 0;

  constructor(
    public formatsService: FormatsService,
    private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService
  ) {
    super();
  }

  checkAllShippingItems(items: SemiFinishedGoodItemsSelectionListResponseModel[], checkIfItemInfinite: boolean = false): void {
    if (items.length) {
      this.checkedAll = !this.checkedAll;
    }
    if (!this.checkedAll) {
      if (this.checkedItems.length === 0) {
        items.map(item => {
          this.checkedItems = [...this.checkedItems, item.externalId];
        });
      } else {
        this.checkedItems = [];
      }
    } else {
      if (checkIfItemInfinite) {
        this.checkedItems = [...this.checkedItems, ...items.map(item => {
          if (!item.isBlocked) {
            return item.externalId;
          }
        })
        ];
      }
      this.checkedItems = this.checkedItems.filter((item: string, pos: number) => {
        return this.checkedItems.indexOf(item) === pos;
      });
    }
    this.checkedItems = this.checkedItems.filter(el => el);
    this.checkedItemsListChanged.emit(this.checkedItems);
    this.checkedAllEvent.emit(this.checkedAll);
    this.calculateRemainingQuantity();
  }

  checkItem(item: SemiFinishedGoodItemsSelectionListResponseModel): void {
    if (item.isBlocked) {
      return;
    }
    this.itemToggled.emit(item);

    const unitId = item.externalId;
    if (!this.checkedItems.includes(unitId)) {
      this.checkedItems = [...this.checkedItems, unitId];
    } else {
      this.checkedItems = this.checkedItems.filter(el => el !== unitId);
    }

    if (!this.localCheckedItems.includes(unitId)) {
      this.localCheckedItems = [...this.localCheckedItems, unitId];
      this.localCheckedItemsCount = this.localCheckedItemsCount + 1;
    } else {
      this.localCheckedItems = this.localCheckedItems.filter(el => el !== unitId);
      this.localCheckedItemsCount = this.localCheckedItemsCount - 1;
    }

    if (this.shippedItems.includes(unitId)) {
      this.removedShippedItems = this.shippedItems.filter(el => el !== unitId);
    }

    const unitIds: string[] = this._semiFinishedGoods.map((item: SemiFinishedGoodItemsSelectionListResponseModel) => item.externalId) || [];
    if (this.checkedItems && this.checkedItems.length === unitIds.length) {
      this.checkedAll = this.checkedItems.every((unitId: string) => unitIds.includes(unitId));
    } else {
      this.checkedAll = false;
    }

    this.calculateRemainingQuantity();
  }

  private calculateRemainingQuantity(): void {
    if (this.item) {
      const items: SalesOrderItemTransactionResponseModel[] = this.item.shippedItems;

      if ((!!items && items.length)) {
        items.forEach(item => item.details.forEach(detail => !this.removedShippedItems.includes(detail.itemBatchExternalId) && this.shippedItems.push(detail.itemBatchExternalId)));
      }

      const uniqShippedItems = uniq([...this.shippedItems, ...this.checkedItems]);
      const remainingQuantityLocal = this.item.quantity - uniqShippedItems.length;
      const quantityExceeded = remainingQuantityLocal < 0;
      this.remainingQuantityExceeded = quantityExceeded;
      this.remainingQuantityChange.emit(remainingQuantityLocal);
      this.remainingQuantityExceededChange.emit(quantityExceeded);
      this.semiFinishedGoodsSelectItemsService.setIsSubmitDisabled(quantityExceeded);
      return;
    }

    if (!!this.remainingQuantity) {
      const remainQuantity: number = this.initRemainingQuantity - this.localCheckedItemsCount;
      const quantityExceeded = remainQuantity < 0;
      this.remainingQuantityExceeded = quantityExceeded;
      this.remainingQuantityChange.emit(remainQuantity);
      this.remainingQuantityExceededChange.emit(quantityExceeded);
      this.semiFinishedGoodsSelectItemsService.setIsSubmitDisabled(quantityExceeded);
    }
  }
}

