import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBoxes } from '@onbatch/shared/models';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { pricePrefix } from '@onbatch/shared/constants';
import { checkIfClickedElementHasClass, checkIfDatePickerWasClicked } from '@onbatch/shared/utils';
import { skip, take } from 'rxjs/operators';
import { ConfirmationModalService, ModalConfirmationState } from '@onbatch/shared/services/confirmation-modal.service';
import * as moment from 'moment';

export interface PaymentInfo<TPaymentTypeId> {
  externalId: string;
  paymentTypeId: TPaymentTypeId;
  paymentDate: moment.Moment;
  authorisationNumber: string;
  checkNumber: string;
  referenceNumber: string;
  paymentAmount: number;
}

export abstract class PaymentModalBaseComponent<TInvoice, TPayment, TPaymentTypeId> implements OnInit {
  @Output() addPayment = new EventEmitter<PaymentInfo<TPaymentTypeId>>();
  @Input() invoice: TInvoice;
  @Input() payment: TPayment;
  form: FormGroup;
  formSubmitStatus = false;
  showModal = false;
  date = moment();

  formBoxes: FormBoxes = {
    authorisationNumber: {
      fieldType: 'input',
      label: 'Authorisation number',
      name: 'authorisationNumber',
      type: 'text',
      placeholder: 'Enter number here',
      field: 'authorisationNumber'
    },
    checkNumber: {
      fieldType: 'input',
      label: 'Check number',
      name: 'checkNumber',
      type: 'text',
      placeholder: 'Enter number here',
      field: 'checkNumber'
    },
    referenceNumber: {
      fieldType: 'input',
      label: 'Reference number',
      name: 'referenceNumber',
      type: 'text',
      placeholder: 'Enter number here',
      field: 'referenceNumber'
    },
    paymentAmount: {
      fieldType: 'priceInput',
      label: 'Payment amount',
      name: 'paymentAmount',
      type: 'text',
      placeholder: '0.00',
      field: 'paymentAmount',
      disableControls: true
    }
  };

  get paymentTypeId(): TPaymentTypeId {
    return this.form.get('paymentTypeId').value;
  }

  abstract readonly paymentTypes;
  readonly pricePrefix = pricePrefix;

  abstract get missingPayment(): number;

  constructor(private fb: FormBuilder, private confirmationModalService: ConfirmationModalService) {
  }

  abstract setPaymentToEdit(payment: TPayment);

  ngOnInit() {
    this.initForm();
  }

  closeModal(event: ClickOutsideData): void {
    const isExcludedElementClicked = checkIfClickedElementHasClass(event, ['btn-close']);
    if (event.value && !checkIfDatePickerWasClicked(event) && this.showModal && !isExcludedElementClicked) {
      if (this.form.dirty) {
        this.confirmationModalService.showConfirmationModal();
        this.confirmationModalService.getState().pipe(skip(1), take(1)).subscribe((modalState: ModalConfirmationState) => {
          this.showModal = !(modalState === ModalConfirmationState.confirmed);
          if (!this.showModal) {
            this.reset();
          }
        });
      } else {
        this.showModal = false;
        this.reset();
      }
    }
  }

  reset() {
    this.form.reset();
    this.date = moment();
    this.form.controls.paymentDate.setValue(this.date);
    this.setPaymentType(this.paymentTypes.Card);
    this.formSubmitStatus = false;
    if (this.payment) {
      this.setPaymentToEdit(this.payment);
    }
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
    this.reset();
  }

  submit() {
    if (this.form.invalid) {
      this.formSubmitStatus = true;
      return;
    }
    this.addPayment.emit({
      ...this.form.value,
    });
    this.toggleModal();
  }

  initForm() {
    this.form = this.fb.group({
      externalId: new FormControl(null),
      paymentTypeId: new FormControl(null),
      paymentDate: new FormControl(this.date, Validators.required),
      authorisationNumber: new FormControl(null, Validators.required),
      checkNumber: new FormControl(null, Validators.required),
      referenceNumber: new FormControl(null, Validators.required),
      paymentAmount: new FormControl(0, Validators.required)
    });
    this.setPaymentType(this.paymentTypes.Card);
  }

  handleFormClick(event: Event) {
    event.stopPropagation();
  }

  getRemaining(): number {
    if (!this.form) {
      return;
    }
    const missingPayment: number = this.missingPayment;
    (missingPayment < 0)
      ? this.form.get('paymentAmount').setErrors({incorrect: true})
      : this.form.get('paymentAmount').setErrors(null);
    return missingPayment;
  }

  setPaymentType(type: TPaymentTypeId) {
    this.form.get('paymentTypeId').setValue(type);
    const nonCardControls = [
      this.form.get('checkNumber'),
      this.form.get('referenceNumber')
    ];
    const nonCheckControls = [
      this.form.get('authorisationNumber'),
      this.form.get('referenceNumber')
    ];
    const nonCashControls = [
      this.form.get('checkNumber'),
      this.form.get('authorisationNumber'),
      this.form.get('referenceNumber')
    ];
    const nonBankControls = [
      this.form.get('checkNumber'),
      this.form.get('authorisationNumber')
    ];
    switch (type) {
      case this.paymentTypes.Card:
        this.form.get('authorisationNumber').enable();
        nonCardControls.forEach(control => control.disable());
        break;
      case this.paymentTypes.Check:
        this.form.get('checkNumber').enable();
        nonCheckControls.forEach(control => control.disable());
        break;
      case this.paymentTypes.Cash:
        nonCashControls.forEach(control => control.disable());
        break;
      case this.paymentTypes.Bank:
        this.form.get('referenceNumber').enable();
        nonBankControls.forEach(control => control.disable());
        break;
    }
  }

  onCalendarChange(date: moment.Moment): void {
    this.date = date;
    this.form.controls.paymentDate.setValue(date);
  }
}
