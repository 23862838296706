import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlideInOutAnimation } from '../../shared';

@Component({
  selector: 'app-table-acc',
  templateUrl: './table-acc.component.html',
  styleUrls: ['./table-acc.component.scss'],
  animations: [SlideInOutAnimation]
})
export class TableAccComponent implements OnInit {
  @Input() item: {
    title: string;
    expanded: boolean;
    count: number;
    id: string;
  };
  @Input() table: [];

  @Output() onAccToggle: EventEmitter<string> = new EventEmitter<string>();

  animationState = 'out';

  constructor() {
  }

  showAcc(id: string) {
    this.onAccToggle.emit(id);
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  ngOnInit() {
  }
}
