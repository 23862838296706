import { Component, HostBinding, Input } from '@angular/core';
import { slideToggle } from '../../../animations';
import { HistoryLogBaseModel } from '../utils';
import { InventoryHistoryLogListResponseInventoryHistoryActionTypeId } from '../../../../core/services/InventoryRx';

@Component({
  selector: 'app-history-log-details',
  templateUrl: './history-log-details.component.html',
  animations: [slideToggle]
})
export class HistoryLogDetailsComponent {
  @Input() log: HistoryLogBaseModel;
  @Input() type: InventoryHistoryLogListResponseInventoryHistoryActionTypeId;
  @Input() extended = false;

  readonly actionTypeId = InventoryHistoryLogListResponseInventoryHistoryActionTypeId;

  get isLogBasic(): boolean {
    return this.type !== this.actionTypeId.MasterTransferred
      && this.type !== this.actionTypeId.MasterItemPurchased
      && this.type !== this.actionTypeId.MasterItemSold
      && this.type !== this.actionTypeId.MasterItemUsedInBatch
      && this.type !== this.actionTypeId.MasterItemReturned
      && this.type !== this.actionTypeId.MasterItemManufactured
      && this.type !== this.actionTypeId.MasterItemPricingCreated
      && this.type !== this.actionTypeId.MasterItemPricingByCustomerCreated
      && this.type !== this.actionTypeId.MasterItemPricingAssigmentDeleted
      && this.type !== this.actionTypeId.MasterItemPricingCustomerAssigmentDeleted;
  }

  get isLogWithoutDateAndBasicValues(): boolean {
    return this.type !== this.actionTypeId.MasterItemPricingCreated
      && this.type !== this.actionTypeId.MasterItemPricingByCustomerCreated
      && this.type !== this.actionTypeId.MasterItemPricingAssigmentDeleted
      && this.type !== this.actionTypeId.MasterItemPricingCustomerAssigmentDeleted;
  }

  get isLogWithoutBasicValues(): boolean {
    return [
      this.actionTypeId.MasterItemUsedInRepackaging,
      this.actionTypeId.MasterItemRepackaged,
      this.actionTypeId.MasterItemStockCountedWithDiscrepancy
    ].includes(this.type);
  }

  get isLogWithoutSubHeading(): boolean {
    return [
      this.actionTypeId.MasterItemUsedInRepackaging,
      this.actionTypeId.MasterItemRepackaged,
      this.actionTypeId.MasterItemStockCountedWithDiscrepancy
    ].includes(this.type);
  }

  @HostBinding('style.width') private width = '100%';
}
