import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FileSaverModule } from 'ngx-filesaver';

import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app.routing.module';
import { ErrorInterceptor, JwtInterceptor, SpinnerInterceptor } from './core/interceptors';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomToastComponent } from './shared/components/custom-toast/custom-toast.component';
import { PopoverModule } from 'ngx-smart-popover';
import { UiKitModule } from './ui-kit/ui-kit.module';
import { ToastrModule } from 'ngx-toastr';
import { InViewportModule } from 'ng-in-viewport';
import { UserAccountModule } from './user/user-account.module';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { AuthService } from './core/services/auth.service';
import { RoleGuard } from './core/guards/role-guard.service';
import { CanDeactivateGuard } from './core/guards/can-deactivate-guard.service';
import { AdminGuardService } from './core/guards/admin-guard.service';
import { NgxMaskModule } from 'ngx-mask';
import { DndModule } from 'ngx-drag-drop';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SubscriptionGuard } from '@onbatch/core/guards/subscription-guard.service';
import { ActiveSubscriptionGuardsService } from '@onbatch/core/guards/active-subscription-guards.service';
import { IsCompanyFromUSAGuard } from '@onbatch/core/guards/isUSA-guard.service';
import { ConfirmationModalService } from './shared/services/confirmation-modal.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from "./rollbar";
import { environment } from 'environments/environment';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export interface IConfig {
  suffix: string;
  prefix: string;
  clearIfNotMatch: boolean;
  showTemplate: boolean;
  showMaskTyped: boolean;
  shownMaskExpression: string;
  dropSpecialCharacters: boolean | string[];
  specialCharacters: string[];
  hiddenInput: boolean | undefined;
  validation: boolean;
  patterns: {
    [character: string]: {
      pattern: RegExp;
      optional?: boolean;
      symbol?: string;
    };
  };
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [AppComponent, CustomToastComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    UiKitModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      toastClass: 'toast',
      positionClass: 'onbatch-toast',
      closeButton: true,
      newestOnTop: true,
      preventDuplicates: true
    }),
    AppRoutingModule,
    FileSaverModule,
    PopoverModule,
    UserAccountModule,
    InViewportModule,
    NgxMaskModule.forRoot(options),
    DndModule,
    SwiperModule,
    NgxDropzoneModule
  ],
  entryComponents: [CustomToastComponent],
  providers: [
    CanDeactivateGuard,
    AuthGuardService,
    ActiveSubscriptionGuardsService,
    AdminGuardService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    IsCompanyFromUSAGuard,
    CookieService,
    RoleGuard,
    SubscriptionGuard,
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    ConfirmationModalService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: 'API_BASE_URL', useValue: environment.baseUrl } // API_BASE_URL used as token throughout src\app\core\services\*.ts files
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}

// required for AOT compilation
// @ts-ignore
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
