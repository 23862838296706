import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

import { FormatsService } from '@onbatch/shared/services/formats.service';

@Directive({
  selector: '[appPriceWithoutPrecisionInput]'
})
export class PriceWithoutPrecisionInputDirective implements OnInit {

  get element() {
    return  this.el.nativeElement;
  }

  constructor(private el: ElementRef,
              private formatsService: FormatsService
  ) {
  }

  @HostListener('blur') onBlur() {
    this.formatValue();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.element.value = this.formatsService.getPriceWithTrailingZeros(this.element.value);
    }, 0);
  }

  formatValue(): void {
    this.element.value = this.formatsService.getPriceWithTrailingZerosWithoutPricePrecision(this.element.value);
  }
}
