import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  SalesEquipmentLookupResponseModel,
  SalesOrderShippedItemRequestModelShipmentItemSelectionCriteriaId as ShippedCriteriaId,
  SellableMasterItemResponseModelMasterItemTypeId,
  ShippedItemRequestModel
} from '../../../../../core/services/Sales';
import { UnitOfMeasurementFormatPipe } from '@onbatch/shared/pipes';
import { debounce } from 'lodash';
import { BaseShipForm } from '../base-ship-form';
import { ShippingService } from '../../../../services/shipping.service';
import { SettingsService } from 'app/account/settings/settings.service';
import {SemiFinishedGoodsSelectItemsService} from "@onbatch/shared/services/semi-finished-goods-select-items.service";
import {takeUntil} from "rxjs/operators";
import { SFGShipping } from '@onbatch/shared/components/shipping/ship-order-modal/shipment-type.enum';

@Component({
  selector: 'app-semi-finished-good',
  templateUrl: './semi-finished-good.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SemiFinishedGoodComponent extends BaseShipForm implements OnInit, OnDestroy {
  @Input() remainingQuantity = 0;

  selectedEquipmentExternalIds: string[] = [];
  refreshEquipment = false;

  quantityOnHand: number = 0;

  validateVolume = debounce((idx: number) => {
    this.equipmentVolumeChanged(idx);
  }, 200);

  get isMasterItemSFG(): boolean {
    return this.item && this.item.masterItem && this.item.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood;
  }

  get isSFGContainer(): boolean {
    return !!(this.item && this.item.container && this.item.container.externalId);
  }

  get unitsToShip(): number {
    return this.validShipping && this.validShipping.length || 0;
  }

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private uomPipe: UnitOfMeasurementFormatPipe,
              public shippingService: ShippingService,
              public settingsService: SettingsService,
              private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService
  ) {
    super(shippingService, settingsService);
  }

  ngOnInit() {
    this.quantityOnHand = this.editMode ? this.item.masterItem.quantityOnHand + this.shippingDetails.quantity : this.item.masterItem.quantityOnHand;
    super.ngOnInit();
    this.shipmentTypeValueChanges$.subscribe(() => {
      for (let idx = 0; idx < this.validShipping.length; idx++) {
        this.removeFormArrayItem(idx);
      }
      this.form.get('shippedVolume').setValue(null);
      this.emitCurrentShipping(this.form.get(`shipmentType-${this.item.externalId}`).value);
      this.cdr.markForCheck();
    });

    this.semiFinishedGoodsSelectItemsService.getSemiFinishedGoodsShipping2().pipe(
      takeUntil(this.destroy$)
    ).subscribe((semiFinishedGoodsShipping: Map<string, SFGShipping[]>) => {
      if (this.isMasterItemSFG && this.isSFGContainer) {
        this.addUnitId(semiFinishedGoodsShipping);
        this.updateQuantity(semiFinishedGoodsShipping);
      }
    });
  }

  addEquipment(equipment: SalesEquipmentLookupResponseModel) {
    this.equipment.push(this.fb.group({
      shippedVolume: new FormControl('', Validators.required),
      equipment: new FormControl(equipment)
    }));
    this.validShipping.push(null);
    this.selectedEquipmentExternalIds.push(equipment.externalId);
    this.refreshEquipment = !this.refreshEquipment;
  }

  addUnitId(semiFinishedGoodsShipping: Map<string, SFGShipping[]>) {
    const semiFinishedGoodsShippingByExternalId = semiFinishedGoodsShipping.get(this.item.externalId) || [];
    this.validShipping = semiFinishedGoodsShippingByExternalId.map((shipping: SFGShipping) => new ShippedItemRequestModel({
      itemBatchExternalId: shipping.itemBatchExternalId,
      startId: shipping.unitId,
      endId: shipping.unitId,
      quantity: 1
    }));
    this.emitCurrentShipping(ShippedCriteriaId.ByItemBatchExternalId);
  }

  initFormBoxes() {
    this.formBoxes = {
      shippedVolume: {
        fieldType: 'input-with-mask',
        name: 'shippedVolume',
        type: 'text',
        field: 'shippedVolume',
        placeholder: `Set ${this.salesApproval ? 'picked' : 'shipped'} Volume`,
        label: `${this.salesApproval ? 'Picked' : 'Shipped'} Volume, ${this.uomPipe.transform(this.item.masterItem.unitOfMeasurementName)}`
      },
      shippedVolumeNoLabel: {
        fieldType: 'input-with-mask',
        name: 'shippedVolume',
        type: 'text',
        field: 'shippedVolume',
        placeholder: `Set ${this.salesApproval ? 'picked' : 'shipped'} Volume`,
      }
    };
  }

  initEditForm() {
    this.shippedQuantity = [];
    if (this.shipmentType.value === this.ShipmentType.ByVolume) {
      this.volumeChanged();
    }
  }

  removeFormArrayItem(idx: number, event?: Event) {
    super.removeFormArrayItem(idx, event);

    this.equipment.removeAt(idx);
    this.validShipping.splice(idx, 1);
    this.selectedEquipmentExternalIds.splice(idx, 1);
    this.emitCurrentShipping(ShippedCriteriaId.ByEquipmentId);
    this.refreshEquipment = !this.refreshEquipment;
  }

  removeFormArrayItemAllItems() {
    this.validShipping = [];
    this.semiFinishedGoodsSelectItemsService.clearSelectedUnitIdsToSubmit();
    this.semiFinishedGoodsSelectItemsService.clearShipping();
    this.semiFinishedGoodsSelectItemsService.clearSemiFinishedGoodsShipping2(this.item.externalId);
    this.emitCurrentShipping(ShippedCriteriaId.ByItemBatchExternalId);
  }

  equipmentVolumeChanged(idx: number): void {
    this.validShipping[idx] = null;

    const form = this.equipment.controls[idx];
    const volume = form.get('shippedVolume');
    const equipment = form.get('equipment');
    volume.setErrors(volume.value <= this.getRemainingQuantity && volume.value <= equipment.value.volume ? null : { 'incorrect': true });
    this.formSubmitStatus = true;

    if (volume.value && volume.value > 0 && volume.value <= this.getRemainingQuantity && volume.value <= equipment.value.volume) {
      this.validShipping[idx] = new ShippedItemRequestModel({
        volume: volume.value,
        unitOfMeasurementExternalId: this.item.masterItem.unitOfMeasurementExternalId,
        equipmentExternalId: equipment.value.externalId
      });
    } else {
      this.validShipping[idx] = null;
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByEquipmentId);
  }

  volumeChanged(): void {
    this.validShipping = [null];

    const volume = this.form.get('shippedVolume');
    const quantityValue = +volume.value;
    const remainingQuantity = this.getRemainingQuantity;
    const isQuantityAvailable = quantityValue <= remainingQuantity && quantityValue <= this.quantityOnHand;
    volume.setErrors(isQuantityAvailable ? null : { 'incorrect': true });
    this.formSubmitStatus = true;
    if (quantityValue && quantityValue > 0 && isQuantityAvailable) {
      this.validShipping = [new ShippedItemRequestModel({
        volume: volume.value,
        unitOfMeasurementExternalId: this.item.masterItem.unitOfMeasurementExternalId
      })];
    } else {
      this.validShipping = [null];
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByVolume);
  }

  onClickAddContainer() {
    this.openContainerSelectionOverlay();
  }

  private openContainerSelectionOverlay() {
    this.overlayTriggered.emit(true);
  }

  private updateQuantity(semiFinishedGoodsShipping: Map<string, SFGShipping[]>) {
    const shipping = semiFinishedGoodsShipping.get(this.item.externalId) || [];
    this.shippedQuantity[0] = {
      quantity: shipping.length,
      isValid: true,
    };
    this.formSubmitStatus = true;
    this.emitCurrentShipping(ShippedCriteriaId.ByItemBatchExternalId);
  }
}
