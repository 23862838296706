import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounce } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import {
  ItemPricingListOfPricingResponseModel,
  LookUpResponse,
  MasterItemContractorPricingList,
  MasterItemFullResponseModel,
  PaginatedResponseOfLookUpResponse,
  PricingRequestModel,
  PricingRequestModelRuleTypeId,
  PricingResponseModel,
  PricingResponseModelRuleTypeId
} from '@onbatch/core/services/Inventory';
import { HeaderParamsModel } from '@onbatch/shared/models';
import { AuthService } from '@onbatch/core/services/auth.service';
import { FormatsService } from '@onbatch/shared/services/formats.service';
import { PricingService } from '@onbatch/shared/components/pricing-by-customer/pricing.service';
import { mode } from '@onbatch/shared/enums/general.enum';
import { getUnitOfMeasurement } from '../../../../inventory/shared/inventory.utils';
import { Features2 } from '@onbatch/core/services/Account';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { MixPanelEvents, pricePrefix } from '@onbatch/shared/constants';
import { MasterItemLookupResponse } from '@onbatch/core/services/InventoryRx';
import { PriceFormatPipe } from '@onbatch/shared/pipes';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';

@Component({
  selector: 'app-single-item-table',
  templateUrl: './single-item-table.component.html'
})
export class SingleItemTableComponent implements OnInit {
  @Input() singleItem: MasterItemContractorPricingList;
  @Input() pricing: ItemPricingListOfPricingResponseModel;
  @Input() contractorId: string;
  @Input() mode: mode = mode.View;

  @Output() presetAdded: EventEmitter<boolean | null> = new EventEmitter<boolean | null>();
  @Output() setIsAddPossible: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() presetUpdated: EventEmitter<null> = new EventEmitter<null>();
  @Output() closeAddMode: EventEmitter<null> = new EventEmitter<null>();

  masterItem: MasterItemFullResponseModel;
  modeTypes = mode;
  fixedRule: PricingResponseModel;
  addNew = false;
  newFixedItem: any;
  chosenMasterItem: MasterItemFullResponseModel;
  validation: any;
  basePrice: number;

  masterItemsBuffer: LookUpResponse[] = [];
  masterItemsDropdownLoading = false;
  paramsForMasterItems: HeaderParamsModel = {
    x_pageNumber: 1,
    x_pageSize: 10,
    x_query: undefined,
    x_order: undefined,
    x_desc: false
  };
  lookupMasterItemsPages: number = null;
  getMasterItemsLookup = debounce((value: string) => {
    this.fetchMore(value);
  }, 200);

  patternForCost = this.formatsService.patternForCost();
  readonly pricePrefix = pricePrefix;

  isFeatureAvailable = new Features2({
    contactRelationship_TieredVolumePricing: this.subscriptionService.getAccess(SubscriptionFeatures.ContactRelationshipTieredVolumePricing)
  });

  @HostBinding('style.width') private width = '100%';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private pricingService: PricingService,
    public authService: AuthService,
    public formatsService: FormatsService,
    private subscriptionService: SubscriptionService,
    public priceFormatPipe: PriceFormatPipe,
    private mixpanelService: MixpanelService,
  ) {
    this.validation = {
      salesPrice: null,
      contractorId: null,
      masterItemId: null
    };
  }

  ngOnInit(): void {
    this.getMasterItem();
    this.getMasterItemsLookups();
    this.setUpNewRule();
  }

  getMasterItem(): void {
    if (this.singleItem) {
      this.pricingService
        .getMasterItem(this.singleItem.externalId)
        .subscribe((res: MasterItemFullResponseModel) => {
          this.masterItem = res;
          this.singleItem.pricings.forEach(pricing => {
            if (pricing.ruleTypeId === PricingResponseModelRuleTypeId.Fixed) {
              this.fixedRule = pricing;
            }
          });
        });
    }
  }

  getMasterItemsLookups(): void {
    if (!this.lookupMasterItemsPages || this.paramsForMasterItems.x_pageNumber <= this.lookupMasterItemsPages) {
      const addedMasterItems: string[] = this.pricing && this.pricing.pricings ? this.pricing.pricings.map(item => item.externalId) : [];
      this.pricingService
        .getAllMasterItems(
          addedMasterItems,
          this.paramsForMasterItems.x_pageNumber,
          this.paramsForMasterItems.x_pageSize,
          this.paramsForMasterItems.x_query,
          this.paramsForMasterItems.x_order,
          this.paramsForMasterItems.x_desc
        )
        .subscribe((masterItems: PaginatedResponseOfLookUpResponse) => {
          this.masterItemsBuffer = [
            ...this.masterItemsBuffer,
            ...masterItems.list
          ];
          this.setIsAddPossible.emit(this.masterItemsBuffer.length !== 0);
          setTimeout(() => (this.masterItemsDropdownLoading = false), 500);
        });
    }
  }

  fetchMore(value: string = null): void {
    this.paramsForMasterItems.x_query = value;
    this.paramsForMasterItems.x_pageNumber++;
    if (value && value !== this.paramsForMasterItems.x_query) {
      this.paramsForMasterItems.x_pageSize = 1;
    }
    this.getMasterItemsLookups();
  }

  toggleAdd(): void {
    this.addNew = true;
  }

  afterPresetAdd(preventFetchPresets: boolean) {
    preventFetchPresets ? (this.addNew = false) : this.presetAdded.emit();
  }

  afterPresetUpdate(): void {
    this.presetUpdated.emit();
  }

  setUpNewRule(): void {
    this.newFixedItem = new PricingRequestModel();
    this.newFixedItem.masterItemExternalId = null;
    this.newFixedItem.contractorId = this.contractorId;
    this.newFixedItem.ruleTypeId = PricingRequestModelRuleTypeId.Fixed;
    this.newFixedItem.salesPrice = 0;
  }

  chooseMasterItem(masterItem: MasterItemLookupResponse): void {
    this.basePrice = masterItem.fixedPrice;
    this.pricingService
      .getMasterItem(this.newFixedItem.masterItemExternalId)
      .subscribe((masterItemSingle: MasterItemFullResponseModel) => {
        this.chosenMasterItem = masterItemSingle;
      });
  }

  addFixedRule(): void {
    this.validation.salesPrice = this.validateNumberValue(this.newFixedItem.salesPrice, 1, Number.POSITIVE_INFINITY);
    this.validation.contractorId = this.newFixedItem.contractorId === undefined;
    if (this.checkValidation()) {
      this.pricingService
        .addSinglePricing(this.newFixedItem)
        .subscribe(() => {
          if (this.pricing) {
            this.setUpNewRule();
            this.presetAdded.emit();
          } else {
            this.router.navigate([`/inventory/pricing-list/by-customer/${this.newFixedItem.contractorId}`]);
            this.presetAdded.emit(true);
          }
          this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_ADDED, {
            'Customer external ID': this.newFixedItem.contractorId,
            'Master item external ID': this.newFixedItem.masterItemExternalId,
            'Min Quantity': this.newFixedItem.minQuantity || null,
            'Max Quantity': this.newFixedItem.maxQuantity || null,
            'Date from': this.newFixedItem.from || null,
            'Date to': this.newFixedItem.to || null,
            'Sales Price': this.newFixedItem.salesPrice,
            'Rule Type ID': this.newFixedItem.ruleTypeId
          });
          this.setIsAddPossible.emit(this.masterItemsBuffer.length !== 1);
          this.toastr.success('Success!', 'Preset added');
        });
    }
  }

  validateNumberValue(value: number, min: number, max: number): boolean {
    value = Number(value);
    if (isNaN(value) || value === 0) {
      return true;
    } else {
      return min > value || max < value;
    }
  }

  checkValidation(): boolean {
    let valid = true;
    const _self = this;
    Object.keys(_self.validation).map(function(key: string) {
      if (_self.validation[key]) {
        valid = false;
      }
    });
    return valid;
  }

  removePricingItem(): void {
    this.pricingService
      .deleteSinglePricing(this.fixedRule.externalId)
      .subscribe(() => {
        this.mixpanelService.track(MixPanelEvents.INVENTORY_PRICING_BY_CUSTOMER_DELETED, {
          'Customer name': this.pricing.name,
          'Customer external ID': this.pricing.externalId,
          'Pricing external ID': this.fixedRule.externalId,
        });
        this.setIsAddPossible.emit(true);
        if (this.pricing.pricings.length === 1) {
          this.pricingService.setPricingByCustomerSide(false);
          this.pricingService.setPricingByCustomerSingle(null);
          this.pricingService.setPricingByCustomerIsAddPossible(true);
          this.router.navigate([`inventory/pricing-list/by-customer/`]);
        }
        this.presetUpdated.emit();
        this.toastr.success('Success!', 'Pricing removed');
      });
  }

  closeAdd(): void {
    this.mode = this.modeTypes.View;
    this.closeAddMode.emit();
  }

  getUnitOfMeasurement(item: MasterItemFullResponseModel): string {
    return getUnitOfMeasurement(item);
  }

  get isAddPossible(): boolean {
    return !this.addNew
      && this.authService.getAccess('Inventory_Pricing', 'Create')
      && (this.isFeatureAvailable.contactRelationship_TieredVolumePricing || !this.singleItem.pricings.length);
  }

  get addRuleLabel(): string {
    return this.singleItem && this.singleItem.pricings.length ? 'Add Preset Rule' : 'Add Base Price';
  }
}
