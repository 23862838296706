import * as Rollbar from 'rollbar';
import { environment } from 'environments/environment';
import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';

const rollbarConfig:Rollbar.Configuration = {
  accessToken: '8f54a39597fd4fe083c0d04b581dd063',
  host:`${environment.production ? 'production': 'localhost'}-angular`,
  captureUncaught: true,
  captureUnhandledRejections: true,
  nodeSourceMaps: false,
  inspectAnonymousErrors: true,
  ignoreDuplicateErrors: true,
  wrapGlobalEventHandlers: false,
  exitOnUncaughtException: false,
  stackTraceLimit: 20,
  verbose: true,
  reportLevel: 'error',
  payload: {
    url: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).profile.iss : null,
    environment: (function(){

      if(localStorage.getItem('currentUser') === null)
        return '';

      const url = JSON.parse(localStorage.getItem('currentUser')).profile.iss;

      if(url.includes('localhost')){
        return 'localhost-angular';
      }

      if(url.includes('dev')){
        return 'stage-angular';
      }

      return 'production-angular';
      
    })(),
    person: {
      id: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).externalId : null,
      email: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).email : null,
      userExternalId: localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')).externalId : null,
      organizationName: localStorage.getItem('currentUser') !== null && JSON.parse(localStorage.getItem('currentUser')).organizations !== undefined && JSON.parse(localStorage.getItem('currentUser')).organizations[0].name !== undefined ? JSON.parse(localStorage.getItem('currentUser')).organizations[0].name : null,
      organizationExternalId: localStorage.getItem('currentUser') !== null && JSON.parse(localStorage.getItem('currentUser')).organizations !== undefined && JSON.parse(localStorage.getItem('currentUser')).organizations[0].externalId !== undefined ? JSON.parse(localStorage.getItem('currentUser')).organizations[0].externalId : null
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    // temporarily ignore rollbar - over monthly free plan limit
    //this.rollbar.error(err.originalError || err);
    console.error(err);  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
