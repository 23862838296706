import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WarehouseLookupResponse } from 'app/core/services/Warehousing';
import { ClickOutsideData, FormBoxes } from 'app/shared';
import { KeyCodes } from 'app/warehousing/warehousing.helpers';
import { InventoryWarehouseLookupResponse } from '@onbatch/core/services/InventoryRx';

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html'
})
export class LocationInputComponent implements OnInit, OnDestroy {
  @Input() set locations(value: Locations[]) {
    this._locations = value;
    if (this.chosenLocationIdsArray.length) {
      this.setSelectedLocations();
    }
  }
  get locations(): Locations[] {
    return this._locations;
  }
  @Input() formSubmitted: boolean;
  @Input() defaultLocation: string;
  @Input() blockRemovingDefaultLocation = false;
  @Input() fullWidth = false;

  @Input() universalUsage: boolean;
  @Input() label: boolean;

  // this is need only to show saved data
  @Input() set chosenLocationIds(data: string[] | WarehouseLookupResponse[]) {
    this.chosenLocationIdsArray = data;
    if (this.chosenLocationIdsArray.length) {
      this.setSelectedLocations();
    }
  }
  @Input() formBoxDisabled = false;

  @Output() selectedLocationExternalId = new EventEmitter<WarehouseLookupResponse>();
  @Output() selectedLocationAccumulated = new EventEmitter<string>();

  accumulatedString = '';
  defaultAccumulatedString = '';
  isDefaultAccumulatedStringInit = false;
  chosenLocationIdsArray = [];
  results: WarehouseLookupResponse[] = [];
  precedentRegionExternalId: string;
  selectedWarehouseIndex: number;

  form: FormGroup;
  formBoxes: FormBoxes;

  subscription = new Subscription();
  readonly keyCodes = KeyCodes;

  resultsIterationByKeyboardIndex = null;

  private _locations: Locations[] = [];

  @ViewChild('locations', { static: false }) locationsInput: ElementRef;
  @ViewChild('locationResults', { static: false }) locationResults: ElementRef;

  @HostBinding('style.width') private width = '100%';

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.code) {
      case this.keyCodes.Esc:
        this.locationsInput.nativeElement.blur();
        this.results = [];
        this.resultsIterationByKeyboardIndex = null;
        return;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      locations: new FormControl(null, !this.blockRemovingDefaultLocation ? Validators.required : null),
    });
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  formInit() {
    this.formBoxes = {
      locations: {
        fieldType: 'input',
        name: 'locations',
        placeholder: 'Select location',
        field: 'locations',
      }
    };
    if (this.chosenLocationIdsArray.length) {
      this.setSelectedLocations();
    }
  }

  onLocationInputChange(event?: KeyboardEvent, defaultValue: string = ''): void {
    let filterArg: string;
    if (event && event.target as HTMLInputElement) {
      filterArg = (event.target as HTMLInputElement).value;
    } else {
      filterArg = defaultValue;
    }

    if (!event && this.locations && this.locations.length) {
      if (!this.selectedWarehouseIndex && this.selectedWarehouseIndex !== 0) {
        this.results = this.locations.map(warehouse => warehouse);
      } else {
        this.onListCheck(filterArg);
      }
    } else if (this.locations && this.locations.length) {
      this.onListCheck(filterArg);
    }
  }

  // results filtering

  onListCheck(enteredString: string): void {
    this.results = [];
    if (!this.selectedWarehouseIndex && this.selectedWarehouseIndex !== 0) {
      this.locations.filter(element => {
        if (this.includesEnteredString(enteredString, element.acronymName, element.name)) {
          this.results.push(element);
        }
      });
    } else {
      this.results = this.findRegions(this.precedentRegionExternalId, true)
        .filter(element => this.includesEnteredString(enteredString, element.acronymName, element.name));
    }
  }

  includesEnteredString(enteredString: string, acronymName?: string, name?: string): boolean {
    return (name || acronymName).includes(enteredString) ||
      (name || acronymName).toLowerCase().includes(enteredString.toLocaleLowerCase());
  }

  // selecting result from dropdown

  onSearchResultClick(selectedRegion: WarehouseLookupResponse): void {
    this.results = [];
    if (!this.selectedWarehouseIndex && this.selectedWarehouseIndex !== 0) {
      this.selectedWarehouseIndex = this.locations.findIndex(warehouse => warehouse.externalId === selectedRegion.externalId);
      this.accumulatedString += this.locations[this.selectedWarehouseIndex].acronymName;
      this.precedentRegionExternalId = selectedRegion.externalId;
    } else {
      this.precedentRegionExternalId = selectedRegion.externalId;
      this.accumulatedString += '/' + this.findRegion(selectedRegion.externalId).acronymName;
    }
    this.selectedLocationExternalId.emit(selectedRegion);
    this.selectedLocationAccumulated.emit(this.accumulatedString);
    this.onLocationInputChange();
    this.resultsIterationByKeyboardIndex = null;
    this.form.controls.locations.reset();
  }

  // deletion of single selected region by backspace

  onLocationDelete() {
    const newPrecedentRegion = this.findRegion(this.precedentRegionExternalId);
    if (!this.selectedWarehouseIndex
      && this.selectedWarehouseIndex !== 0
      || (this.blockRemovingDefaultLocation
      && (!newPrecedentRegion || this.defaultLocation === newPrecedentRegion.externalId))) {
      return;
    }
    if (this.precedentRegionExternalId.length) {
      this.accumulatedString = this.accumulatedString.substring(0, this.accumulatedString.lastIndexOf('/'));
      this.selectedLocationAccumulated.emit(this.accumulatedString);
      if (newPrecedentRegion && newPrecedentRegion.parentExternalId) {
        this.precedentRegionExternalId = newPrecedentRegion.parentExternalId;
        this.selectedLocationExternalId.emit(newPrecedentRegion);
      } else {
        this.precedentRegionExternalId = '';
        this.selectedWarehouseIndex = null;
        this.selectedLocationExternalId.emit(null);
      }
      this.onLocationInputChange();
    } else {
      return;
    }
    this.resultsIterationByKeyboardIndex = null;
  }

  // approving previously selected value

  onLocationInputEnter() {
    this.locationsInput.nativeElement.blur();
    this.results = [];
    this.resultsIterationByKeyboardIndex = null;
  }

  onClick(e: MouseEvent) {
    e.stopPropagation();
  }

  onClickOutside(event: ClickOutsideData) {
    if (event.value
      && !(event.target as HTMLElement).classList.contains('assign-location__results-item_paragraph')
      && !(event.target as HTMLElement).classList.contains('assign-location__results-item')
    ) {
      this.onLocationInputEnter();
      this.resultsIterationByKeyboardIndex = null;
    }
  }

  // showing saved data fetched from api

  setSelectedLocations() {
    if (!this.locations || (this.locations && !this.locations.length)) {
      return;
    }

    this.selectedWarehouseIndex = this.locations.findIndex(warehouse => warehouse.externalId === this.chosenLocationIdsArray[0]);
    this.accumulatedString = this.locations[this.selectedWarehouseIndex].acronymName;
    if (this.blockRemovingDefaultLocation && !this.isDefaultAccumulatedStringInit) {
      this.defaultAccumulatedString = this.locations[this.selectedWarehouseIndex].acronymName;
    }
    this.precedentRegionExternalId = this.chosenLocationIdsArray[0];
    this.findPrecedentRegions();
  }

  findPrecedentRegions() {
    const warehouseRegions = this.locations[this.selectedWarehouseIndex].regions;
    const findPrecedentRegion = () => {
      const precedentRegion = warehouseRegions.find(region => region.externalId === this.chosenLocationIdsArray[1].parentExternalId);
      this.chosenLocationIdsArray.splice(1, 0, precedentRegion.externalId);
      this.findPrecedentRegions();
    };

    if (this.chosenLocationIdsArray.length > 1) {
      const finalLocation = warehouseRegions.find(region => region.externalId === this.chosenLocationIdsArray[1]);
      if (finalLocation && finalLocation.parentExternalId !== this.locations[this.selectedWarehouseIndex].externalId) {
        findPrecedentRegion();
      } else {
        const regionsToAdd = this.chosenLocationIdsArray.slice(1);
        regionsToAdd.forEach(regionId => {
          const region: Region = this.findRegion(regionId);
          if (region && region.acronymName) {
            this.accumulatedString += '/' + region.acronymName;
            if (this.blockRemovingDefaultLocation && !this.isDefaultAccumulatedStringInit) {
              this.defaultAccumulatedString += '/' + region.acronymName;
            }
          }
        });
        this.precedentRegionExternalId = this.chosenLocationIdsArray[this.chosenLocationIdsArray.length - 1];
      }
    }
    if (!this.isDefaultAccumulatedStringInit) {
      this.isDefaultAccumulatedStringInit = true;
    }
    this.accumulatedString = this.accumulatedString.replace(this.defaultAccumulatedString, '');
  }

  // controlling input by keyboard

  onArrowDown() {
    if (!this.results || (this.results && !this.results.length)) {
      return;
    }
    const results = this.locationResults.nativeElement.children;

    if (this.resultsIterationByKeyboardIndex < this.results.length - 1 && this.resultsIterationByKeyboardIndex !== null) {
      this.resultsIterationByKeyboardIndex += 1;
    } else {
      this.resultsIterationByKeyboardIndex = 0;
    }

    results[this.resultsIterationByKeyboardIndex].classList.add('active');
    if (this.results.length === 1) {
      return;
    }
    if (this.resultsIterationByKeyboardIndex > 0) {
      results[this.resultsIterationByKeyboardIndex - 1].classList.remove('active');
    } else {
      results[this.results.length - 1].classList.remove('active');
    }
  }

  onArrowUp() {
    if (!this.results || (this.results && !this.results.length)) {
      return;
    }
    const results = this.locationResults.nativeElement.children;

    if (!this.resultsIterationByKeyboardIndex) {
      this.resultsIterationByKeyboardIndex = this.results.length - 1;
    } else {
      this.resultsIterationByKeyboardIndex -= 1;
    }

    results[this.resultsIterationByKeyboardIndex].classList.add('active');
    if (this.results.length === 1) {
      return;
    }
    if (this.resultsIterationByKeyboardIndex === this.results.length - 1) {
      results[0].classList.remove('active');
    } else {
      results[this.resultsIterationByKeyboardIndex + 1].classList.remove('active');
    }
  }

  onTab(event: KeyboardEvent) {
    event.preventDefault();
    if (this.resultsIterationByKeyboardIndex === null) {
      return;
    }

    const selectedRegion = this.results[this.resultsIterationByKeyboardIndex];
    this.precedentRegionExternalId = selectedRegion.externalId;

    if (this.selectedWarehouseIndex || this.selectedWarehouseIndex === 0) {
      this.accumulatedString += '/' + selectedRegion.acronymName;
    } else {
      this.accumulatedString += selectedRegion.acronymName;
      this.selectedWarehouseIndex = this.locations.findIndex(warehouse => warehouse.externalId === selectedRegion.externalId);
    }

    this.selectedLocationExternalId.emit(selectedRegion);
    this.results = [];
    this.resultsIterationByKeyboardIndex = null;
    this.onLocationInputChange();
  }

  private findRegions(selectedRegionId: string, searchByParent: boolean = false): Region[] {
    const search = (regions: Region[]): Region[] =>
      regions.filter(region => searchByParent ? region.parentExternalId === selectedRegionId : region.externalId === selectedRegionId);
    const descend = (regions: Region[]) => {
      let foundRegions = search(regions);
      if (foundRegions.length) {
        return foundRegions;
      }
      for (const region of regions) {
        foundRegions = descend(region.regions);
        if (foundRegions.length) {
          return foundRegions;
        }
      }
      return [];
    };

    return descend(this.locations[this.selectedWarehouseIndex].regions);
  }

  private findRegion(selectedRegionId: string) {
    const regions = this.findRegions(selectedRegionId);
    return regions.length ? regions[0] : null;
  }
}

type Region = WarehouseLookupResponse | InventoryWarehouseLookupResponse;
interface Locations extends WarehouseLookupResponse, InventoryWarehouseLookupResponse {
  regions: Region[] | null;
}
