import { Directive, ElementRef, EventEmitter, Output, HostListener, OnDestroy, OnInit, Input } from '@angular/core';
import { checkIfClickedElementHasClass } from '@onbatch/shared/utils';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

export interface ClickOutsideData {
  value: boolean;
  target: EventTarget | null;
}

@Directive({
  selector: '[click-outside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Input() delayClick = 0;
  @Output() clickOutside: EventEmitter<ClickOutsideData>;

  private subscription: Subscription;
  private clicks = new Subject<ClickOutsideData>();

  constructor(private _elRef: ElementRef) {
    this.clickOutside = new EventEmitter();
  }

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.delayClick))
      .subscribe(e => this.clickOutside.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const isExcludedElementClicked = checkIfClickedElementHasClass(null, ['btn--cancel'], target);
    if (!isExcludedElementClicked) {
      const clickedInside = this._elRef.nativeElement.contains(target);
      this.clicks.next({
        target: event.target || null,
        value: !clickedInside
      });
    }
  }
}
