import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-long',
  templateUrl: './button-long.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonLongComponent {
  @Input() label: string;
  @Input() isActive: boolean;
  @Input() isDisbaled: boolean;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();


  click(): void {
    if (this.isDisbaled) {
      return;
    }
    this.clicked.emit();
  }
}
