import { MasterItemDataTable } from '../../shared/models';

export const MasterItemDefaultSettings = [
  {
    label: 'Name',
    status: true,
    name: 'name'
  },
  {
    label: 'Qty On-Hand',
    status: true,
    name: 'qty'
  },
  {
    label: 'UOM',
    status: true,
    name: 'uom'
  },
  {
    label: 'Location',
    status: true,
    name: 'location'
  },
  {
    label: 'Type',
    status: true,
    name: 'type'
  }
];
export const MasterItemDefaultParams = {
  filterRequest: [],
  includeInactive: false,
  x_query: undefined,
  x_order: 'itemNumberText',
  x_desc: false,
  x_pageNumber: 1,
  x_pageSize: 20
};
export const MasterItemDefaultSort = { columnName: 'itemNumberText', desc: false };

export const MasterItemDataTableDefaults: MasterItemDataTable = {
  params: MasterItemDefaultParams,
  sort: MasterItemDefaultSort,
  showRemoved: true,
  settings: MasterItemDefaultSettings,
  filters: []
};

export interface CheckedItems {
  externalIds: string[];
  canBeDeleted: boolean;
  canBeArchived: boolean;
}
