import * as i0 from "@angular/core";
export class ObjectDeepCopyService {
    constructor() { }
    deepCopy(obj) {
        if (null == obj || 'object' !== typeof obj) {
            return obj;
        }
        let copy = null;
        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }
        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = this.deepCopy(obj[attr]);
                }
            }
            return copy;
        }
        throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }
}
ObjectDeepCopyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectDeepCopyService_Factory() { return new ObjectDeepCopyService(); }, token: ObjectDeepCopyService, providedIn: "root" });
