import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

import { FormatsService } from '@onbatch/shared/services/formats.service';

@Directive({
  selector: '[appPriceInput]'
})
export class PriceInputDirective implements OnInit {

  constructor(private el: ElementRef,
              private formatsService: FormatsService
  ) {
  }

  @HostListener('blur') onBlur() {
    this.formatValue();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.formatValue();
    }, 0);
  }

  formatValue(): void {
    const element = this.el.nativeElement;
    element.value = this.formatsService.getPriceWithTrailingZeros(element.value);
  }
}
