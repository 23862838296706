import { IncomingItemForList } from './receive-transfer.interface';
import { TransferInBondIncomingItemResponseModel } from '@onbatch/core/services/Warehousing';

export function getRemainingQuantity(
  item: IncomingItemForList | TransferInBondIncomingItemResponseModel,
  items: { receivedQuantity?: number, amountPerUnit?: number }[],
  checkAmount: boolean = false,
  sumOfItems: number = 0): number {
  let selectedQuantity = 0;
  let quantityFromInputs = 0;

  if (item.receivedItems.length) {
    selectedQuantity = item.receivedItems
      .map(x => x.quantity)
      .reduce((a: number, b: number) => a + b, 0);
  }
  if (items && items.length) {
    quantityFromInputs = items
      .map(x => {
        return !checkAmount ? Number(x.receivedQuantity) : Number(x.amountPerUnit) || 0;
      })
      .reduce((a: number, b: number) => a + b, 0);
  }

  return item.quantity - selectedQuantity - quantityFromInputs - sumOfItems;
}
