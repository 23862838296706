import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { closeCalendar } from '../shared/utils';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';

@Component({
  selector: 'app-ui-kit',
  templateUrl: './ui-kit.component.html',
  styleUrls: ['./ui-kit.component.scss'],
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class UiKitComponent implements OnInit {
  // Checkbox
  checkedItems: Array<string> = [];
  checkboxOptions = [
    {
      id: '74234-432-432',
      label: 'Label',
      isChecked: true
    },
    {
      id: '5432-543-53',
      label: 'Label 2',
      isChecked: false,
      interdeterminate: true
    },
    {
      id: '5432-6663-53',
      label: 'Label 3',
      isChecked: false,
      disabled: true
    }
  ];

  // Radio
  radioValue = '';

  radioGroup = {
    name: 'labels',
    options: [
      {
        id: '4324-432-423-4-2',
        label: 'First label',
        value: '1'
      },
      {
        id: '4324-666-423-4-2',
        label: 'Second label',
        value: '2'
      },
      {
        id: '4324-999-423-4-2',
        label: 'Third label',
        value: '3'
      },
      {
        id: '4324-111-423-4-2',
        label: 'fourth label',
        value: '4',
        disabled: true
      }
    ]
  };

  // Toggles
  toggleGroup = [
    {
      id: '1',
      label: 'Label 1',
      value: false
    },
    {
      id: '2',
      label: 'Label 2',
      value: false
    },
    {
      id: '3',
      label: 'Label 3',
      value: false,
      disabled: true
    }
  ];

  // Settings

  settingsList = [
    {
      label: 'Name',
      status: true,
      name: 'name'
    },
    {
      label: 'Qty On-Hand',
      status: true,
      name: 'qty'
    },
    {
      label: 'UOM',
      status: true,
      name: 'uom'
    },
    {
      label: 'Location',
      status: true,
      name: 'location'
    },
    {
      label: 'Type',
      status: true,
      name: 'type'
    },
    {
      label: 'Total Cost',
      status: true,
      name: 'cost'
    }
  ];

  settingsPanel = false;
  displayRemovedStatus = false;

  // Inputs

  createItemForm: FormGroup;
  formSubmitStatus = true;

  tableNewRowBoxes = {
    name: {
      fieldType: 'input',
      name: 'name',
      type: 'text',
      placeholder: 'Enter name',
      field: 'name',
      disableControls: true,
      customClass: 'form__box--table'
    },
    worker: {
      fieldType: 'select',
      name: 'worker',
      searchable: true,
      placeholder: 'Worker',
      field: 'worker',
      select: {
        items: ['Sample', 'Items', 'For', 'Example', 'Select']
      },
      disableControls: true,
      customClass: 'form__box--table'
    },
    number: {
      fieldType: 'input',
      name: 'number',
      type: 'text',
      placeholder: 'Number',
      field: 'number',
      disableControls: true,
      customClass: 'form__box--table'
    }
  };

  formBoxes = {
    email: {
      fieldType: 'input',
      label: 'Email',
      name: 'email',
      type: 'text',
      placeholder: 'Example email',
      field: 'email'
    },
    emailExtra: {
      fieldType: 'input',
      label: 'Email 2nd variation',
      name: 'emailExtra',
      type: 'text',
      placeholder: 'Example email',
      field: 'emailExtra',
      customClass: 'form__box--extra'
    },
    emailDis: {
      fieldType: 'input',
      label: 'Email',
      name: 'emailDis',
      type: 'text',
      placeholder: 'Example email',
      field: 'emailDis',
      disabled: true
    },
    withhelper: {
      fieldType: 'input',
      label: 'Email with helper',
      name: 'withhelper',
      type: 'text',
      placeholder: 'Example email',
      field: 'withhelper',
      helper: 'This is email field helper'
    },
    withhelperDis: {
      fieldType: 'input',
      label: 'Email with helper',
      name: 'withhelperDis',
      type: 'text',
      placeholder: 'Example email',
      field: 'withhelperDis',
      helper: 'This is email field helper',
      disabled: true
    },
    textarea: {
      fieldType: 'textarea',
      label: 'Description',
      placeholder: 'Some text description',
      field: 'textarea'
    },
    simpleSelect: {
      fieldType: 'select',
      label: 'Unit of Measurement',
      placeholder: 'Simple Select',
      searchable: false,
      field: 'simpleSelect',
      select: {
        items: ['Sample', 'Items', 'For', 'Example', 'Select']
      }
    },
    simpleSearchSelect: {
      fieldType: 'select',
      label: 'Unit of Measurement',
      placeholder: 'Simple Select',
      searchable: true,
      field: 'simpleSearchSelect',
      select: {
        items: ['Sample', 'Items', 'For', 'Example', 'Select']
      }
    },
    simpleSearchAddSelect: {
      fieldType: 'select',
      label: 'Unit of Measurement',
      placeholder: 'Simple Select',
      searchable: true,
      newItemsPossible: true,
      newItemsText: 'Add create-batch item',
      field: 'simpleSearchSelect',
      select: {
        items: ['Sample', 'Items', 'For', 'Example', 'Select']
      }
    },
    multiselect: {
      fieldType: 'select-check',
      label: 'Multiselect with checkboxes',
      placeholder: 'Select Item Type',
      field: 'multiselect',
      multiple: true,
      searchable: true,
      select: {
        items: ['Sample', 'Items', 'For', 'Example', 'Select']
      }
    },
    timer: {
      fieldType: 'input',
      label: 'Start time',
      name: 'timer',
      type: 'time',
      placeholder: '00:00',
      field: 'timer',
      customClass: 'form__box--timer',
      extraButtons: [
        {
          slug: 'setAm',
          label: 'AM',
          selected: true
        },
        {
          slug: 'setPm',
          label: 'PM',
          selected: false
        }
      ]
    }
  };

  // Selects
  // Content Switcher

  contentSwitcher = {
    disabled: false,
    list: [
      {
        id: '1',
        label: 'Content One',
        active: true
      },
      {
        id: '2',
        label: 'Content Two',
        active: false
      },
      {
        id: '3',
        label: 'Content Three',
        active: false
      }
    ]
  };

  contentSwitcherDis = {
    disabled: true,
    list: [
      {
        id: '1',
        label: 'Content One',
        active: true
      },
      {
        id: '2',
        label: 'Content Two',
        active: false
      },
      {
        id: '3',
        label: 'Content Three',
        active: false
      }
    ]
  };

  // Inline Actions

  itemActive = true;

  // Toolbar

  toolbar = {
    label: 'Materials',
    icon: 'create-batch'
  };
  materialsPanel = false;
  materialsConfig = {
    sections: [
      {
        title: 'Fermentable materials-dropdown',
        items: [
          {
            name: 'Malt',
            uom: 'lbs',
            qty: 540
          },
          {
            name: 'Corn',
            uom: 'lbs',
            qty: 320
          },
          {
            name: 'Glucose',
            uom: 'gal',
            qty: 213
          }
        ],
        ratio: [41, 16, 4]
      },
      {
        title: 'Other materials-dropdown',
        items: [
          {
            name: 'Prepared Water',
            uom: 'gal',
            qty: 1000
          },
          {
            name: 'Card box',
            uom: 'pcs',
            qty: 50
          }
        ]
      }
    ]
  };

  // Tables

  tableExampleData = [
    {
      number: '01234',
      name: 'Name Example',
      status: 'Status Example',
      type: 'Type Example'
    },
    {
      number: '01234',
      name: 'Name Example',
      status: 'Status Example',
      type: 'Type Example'
    },
    {
      number: '01234',
      name: 'Name Example',
      status: 'Status Example',
      type: 'Type Example'
    },
    {
      number: '01234',
      name: 'Name Example',
      status: 'Status Example',
      type: 'Type Example'
    }
  ];

  tableAccList = [
    {
      id: '1',
      title: 'Warehouse A',
      count: 6,
      expanded: false
    },
    {
      id: '2',
      title: 'Warehouse B',
      count: 2,
      expanded: false
    },
    {
      id: '3',
      title: 'Warehouse C',
      count: 8,
      expanded: false
    }
  ];

  animationState = 'out';

  tableHeadSearch = false;
  tableNewRow: FormGroup;

  exDropVisible = false;

  closeDatepicker = closeCalendar;

  constructor(private toastr: ToastrService, private fb: FormBuilder) {
  }

  // Checkbox

  checkbox(id: string) {
    console.log(id);
    if (!this.checkedItems.includes(id)) {
      this.checkedItems.push(id);
    } else {
      this.checkedItems = this.checkedItems.filter(el => el !== id);
    }
  }

  checkExternalId(id: string): boolean {
    return this.checkedItems.includes(id);
  }

  // Radio

  radio(val: string) {
    this.radioValue = val;
  }

  // Notifications
  positiveNotification() {
    this.toastr.success(`Some description text of the notification is displayed here`, 'Positive notification title', {
      // 'undo': true
    });
  }

  negativeNotification() {
    this.toastr.error(`Some description text of the notification is displayed here`, 'Negative notification title');
  }

  infoNotification() {
    this.toastr.info(`Some description text of the notification is displayed here`, 'Info notification title');
  }

  // Toggle
  toggle(id: string | number) {
    const toggleIndex = this.toggleGroup.findIndex(toggle => toggle.id === id);
    this.toggleGroup[toggleIndex].value = !this.toggleGroup[toggleIndex].value;
  }

  // Settings

  toggleSettings() {
    this.settingsPanel = !this.settingsPanel;
  }

  openSettings(event: OpenSettingsObj) {
    if (!event.value) {
      this.settingsPanel = true;
    }
  }

  changeDisplay(val: string): void {
    if (val === 'removed') {
      this.displayRemovedStatus = !this.displayRemovedStatus;
      return;
    }
    this.settingsList[val].status = !this.settingsList[val].status;
  }

  // Inputs

  // ContentSwitcher

  switchContent(id: string) {
    const currentItem = this.contentSwitcher.list.filter(item => item.id === id);
    this.contentSwitcher.list.map(item => (item.active = false));
    currentItem[0].active = true;
  }

  // Inline actions
  triggerAction(input: any) {
    console.log(input);
    let ids = null;
    let action = null;
    if (typeof input === 'string') {
      action = input;
      ids = this.checkedItems;
    } else {
      ids = [input.id];
      action = input.action;
    }
    this.toastr.info(`Triggered action '${action}' for items: '${ids}'`, 'Info');
  }

  changeExtraProp(payload: ExtraInputControls) {
    switch (payload.slug) {
      case 'setAm': {
        this.formBoxes[payload.inputName].extraButtons.map((item: any) => (item.selected = false));
        const prop = this.formBoxes[payload.inputName].extraButtons.filter((item: any) => item.slug === payload.slug);
        prop[0].selected = true;
      }
        break;
      case 'setPm': {
        this.formBoxes[payload.inputName].extraButtons.map((item: any) => (item.selected = false));
        const prop = this.formBoxes[payload.inputName].extraButtons.filter((item: any) => item.slug === payload.slug);
        prop[0].selected = true;
      }
        break;
      default: {
        this.toastr.info(`Triggered action '${payload.slug}'`, 'Info');
      }
    }
  }

  toggleContextMenu(e: MouseEvent, show: boolean): void {
    const childNode = (e.target as HTMLTextAreaElement).childNodes[1] as HTMLTextAreaElement;
    show ? (childNode.style.display = 'block') : (childNode.style.display = 'none');
    if (e.clientY > 480) {
      childNode.style.bottom = '29px';
      childNode.style.top = 'auto';
      childNode.classList.add('options-menu--top');
    } else {
      childNode.style.bottom = 'auto';
      childNode.style.top = '34px';
      childNode.classList.remove('options-menu--top');
    }
  }

  // Toolbar

  toggleMaterials() {
    this.materialsPanel = !this.materialsPanel;
  }

  // Calendars

  selectDateRange($event: number) {
    this.toastr.info(`Date selected`, 'Info');
  }

  // Tables
  showAcc(id: string): void {
    const accItem = this.tableAccList.filter(item => item.id === id);
    accItem[0].expanded = !accItem[0].expanded;
    console.log(accItem[0]);
  }

  toggleHeadSearch() {
    this.tableHeadSearch = !this.tableHeadSearch;
  }

  clearHeadInput($event: number) {
    alert('clearing input');
  }

  onKeydown(event: KeyboardEvent): boolean {
    if (event.keyCode === 13) {
      alert('Submit table search!');
      this.toggleHeadSearch();
      return false;
    }
  }

  onNewRowAdd() {
    alert('Add create-batch row');
    return false;
  }

  openExampleDropdown() {
    this.exDropVisible = !this.exDropVisible;
  }

  // Init

  ngOnInit() {
    this.createItemForm = this.fb.group({
      email: new FormControl('', Validators.email),
      emailExtra: new FormControl('', Validators.email),
      withhelper: new FormControl(''),
      emailDis: new FormControl(''),
      withhelperDis: new FormControl(''),
      textarea: new FormControl(''),
      simpleSearchSelect: new FormControl(null),
      simpleSearchAddSelect: new FormControl(null),
      simpleSelect: new FormControl(null),
      multiselect: new FormControl(null),
      timer: new FormControl(null)
    });

    this.tableNewRow = this.fb.group({
      name: new FormControl(''),
      worker: new FormControl(null),
      number: new FormControl('')
    });
  }
}

export interface OpenSettingsObj {
  target: Element;
  value: boolean;
}

export interface ExtraInputControls {
  slug: string;
  inputName: string;
}
