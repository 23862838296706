import { IEquipmentDataTable } from "./equipment.interfaces";

/* Equipment settings */
export const EquipmentSettings = [
    {
      label: 'Equipment Type',
      status: true,
      name: 'equipmentModelType'
    },
    {
      label: 'Equipment Id',
      status: true,
      name: 'equipmentId'
    },
    {
      label: 'Max volume',
      status: true,
      name: 'maxVolume'
    },
    {
      label: 'Asset number',
      status: true,
      name: 'assetNumber'
    },
    {
      label: 'Energy cons.',
      status: true,
      name: 'energyConsumption'
    },
    {
      label: 'Purchase date',
      status: true,
      name: 'purchaseDate'
    },
    {
      label: 'Purchase price',
      status: true,
      name: 'purchasePrice'
    }
  ];

/* Utility bar options */
export const EquipmentUtilityBarOptions = [
  {
    label: 'Edit',
    icon: 'edit',
    action: 'edit'
  },
  {
    label: 'Duplicate',
    icon: 'duplicate',
    action: 'duplicate'
  },
  {
    label: 'Print equipment card',
    icon: 'print',
    action: 'print'
  },
  {
    label: 'Remove',
    icon: 'delete',
    extraClass: 'utility-bar__item--pull-right',
    action: 'delete',
  },
  {
    label: 'Retire',
    icon: 'delete',
    extraClass: 'utility-bar__item--pull-right',
    action: 'retire',
  },
  {
    label: 'Unretire',
    icon: 'delete',
    extraClass: 'utility-bar__item--pull-right',
    action: 'unretire',
  },
  {
    label: 'Out of order',
    icon: 'delete',
    action: 'outoforder',
  },
  {
    label: 'Reactivate',
    icon: 'delete',
    action: 'reactivate',
  }
];

/* Data Table settings */
export const EquipmentDataTableDefaultParams = {
  filterRequest: [],
  active: true,
  x_query: undefined,
  x_order: 'equipmentName',
  x_desc: false,
  x_pageNumber: 1,
  x_pageSize: 20
};

export const EquipmentDataTableDefaultSort = { columnName: 'equipmentName', desc: false };

export const ManagementEquipmentDataTableDefaults: IEquipmentDataTable = {
  params: EquipmentDataTableDefaultParams,
  sort: EquipmentDataTableDefaultSort,
  showRetired: false,
  settings: EquipmentSettings,
  filters: [],
};

export const RetiredEquipmentDataTableDefaults: IEquipmentDataTable = {
  params: EquipmentDataTableDefaultParams,
  sort: EquipmentDataTableDefaultSort,
  showRetired: false,
  settings: EquipmentSettings,
  filters: [],
};
