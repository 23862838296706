export function detectRanges(indices, lotId) {
    return indices
        .sort((a, b) => a - b)
        .reduce((acc, indexValue, index) => {
        if (index === 0) {
            return [
                {
                    start: indexValue,
                    end: indexValue,
                    items: [indexValue],
                    length: 1,
                    lotId
                }
            ];
        }
        const lastRange = acc[acc.length - 1];
        if (indexValue === lastRange.end + 1) {
            const allButLast = acc.slice(0, acc.length - 1);
            return [
                ...allButLast,
                Object.assign({}, lastRange, { end: indexValue, items: [...lastRange.items, indexValue], length: lastRange.length + 1, lotId })
            ];
        }
        else {
            return [
                ...acc,
                {
                    start: indexValue,
                    end: indexValue,
                    items: [indexValue],
                    length: 1,
                    lotId
                }
            ];
        }
    }, []);
}
export function mapIndexRangesToItems(indexRanges, items) {
    return indexRanges.map((range) => ({
        start: items[range.start],
        end: items[range.end],
        items: range.items.map(idx => items[idx]),
        length: range.length,
        lotId: null
    }));
}
