import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seasonedLength'
})
export class SeasonedLengthPipe implements PipeTransform {
  transform(seasonedLength: number): any {
    const years = Math.floor(seasonedLength / 12);
    const months = seasonedLength % 12;
    return years > 0
      ? `${years} Year(s) ${months} Month(s)`
      : `${months} Month(s)`;
  }
}
