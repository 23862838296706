import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-by-customer-single',
  templateUrl: './single-item.component.html'
})
export class PricingByCustomerSingleComponent {
  @HostBinding('style.width') private width = '100%';

  constructor() {
  }
}
