import { Component, Input, Output, EventEmitter } from '@angular/core';
import { debounce } from 'lodash';
import { take } from 'rxjs/operators';

import { TransfersService } from 'app/warehousing/transfers/transfers.service';
import {
  OfTypeOnly2,
  PaginatedResponseOfWarehousingContainerLookUpResponseModel,
  WarehousingContainerLookUpResponseModel
} from '@onbatch/core/services/Warehousing';
import { checkIfClickedParentElementHasClass } from '@onbatch/shared/utils';
import { SalesContainerResponseModel } from '@onbatch/core/services/Sales';

@Component({
  selector: 'app-add-container',
  templateUrl: './add-container.component.html'
})
export class AddContainerComponent {
  @Input() hideFilters = false;
  @Input() containers: WarehousingContainerLookUpResponseModel[] = [];
  @Input() containersPages = 1;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemSelected = new EventEmitter<WarehousingContainerLookUpResponseModel | SalesContainerResponseModel>();

  paramsForContainersLookup = {
    filterRequest: [],
    type: OfTypeOnly2.Container,
    x_desc: undefined,
    x_order: undefined,
    x_pageNumber: 1,
    x_pageSize: 10,
    x_query: undefined
  };
  searchTerm = '';
  searchSubmit = debounce(() => {
    if (this.searchTerm.length > 2 || this.searchTerm.length === 0) {
      this.lookUpContainers(this.searchTerm);
    }
  }, 200);

  constructor(private transfersService: TransfersService) {}

  closeAddItem(event: { target: HTMLElement; value: boolean }): void {
    const isExcludedElementClicked = checkIfClickedParentElementHasClass(event.target, 'trigger-button');
    if (event.value && !['use', 'svg'].includes(event.target.localName) && !isExcludedElementClicked) {
      this.closeModal.emit(false);
    }
  }

  itemClicked(item: WarehousingContainerLookUpResponseModel): void {
    this.itemSelected.emit(item as WarehousingContainerLookUpResponseModel);
  }

  lookUpContainers(searchTerm: string): void {
    this.paramsForContainersLookup.x_query = searchTerm;
    if (searchTerm && searchTerm.length) {
      this.paramsForContainersLookup.x_pageNumber = 1;
    }
    this.transfersService
      .lookUpContainers(
        this.paramsForContainersLookup.filterRequest,
        this.paramsForContainersLookup.x_query,
        this.paramsForContainersLookup.x_order,
        this.paramsForContainersLookup.x_desc,
        this.paramsForContainersLookup.x_pageNumber,
        this.paramsForContainersLookup.x_pageSize
      )
      .pipe(take(1))
      .subscribe((response: PaginatedResponseOfWarehousingContainerLookUpResponseModel) => {
        this.containers = searchTerm ? response.list : [...this.containers, ...response.list];
      });
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      if (this.paramsForContainersLookup.x_pageNumber + 1 <= this.containersPages) {
        this.paramsForContainersLookup.x_pageNumber++;
        this.lookUpContainers('');
      }
    }
  }
}
