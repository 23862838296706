import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameInitials' })
export class NameInitialsPipe implements PipeTransform {
  transform(content: string): string {
    const index = content.indexOf(' ');
    if (index > -1) {
      return (content[0] + content[index + 1]).toUpperCase();
    }
    return content[0].toUpperCase();
  }
}
