import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from 'app/core/services/spinner.service';
import {
  MaterialDepletionItemRequestModel,
  MaterialDepletionItemResponseModel,
  MaterialDepletionLookupResponseModel,
  MaterialDepletionRequestModel,
  MaterialDepletionResponseModel
} from 'app/core/services/Manufacturing';
import { FormatsService } from '@onbatch/shared/services/formats.service';
import { pricePrefix } from '@onbatch/shared/constants';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-materials-depletion',
  templateUrl: './materials-depletion.component.html'
})

export class MaterialsDepletionComponent {
  @Input() isOpen = false;
  @Input() set materialDepletionRequest(items: MaterialDepletionRequestModel[]) {
    this.tempMaterialDepletionRequest = items ? cloneDeep(items) : [];
    this.initialMaterialDepletionRequest = items ? cloneDeep(items) : [];
  };
  @Input() set materialDepletionResponse(items: MaterialDepletionResponseModel[]) {
    this.tempMaterialDepletionResponse = items ? cloneDeep(items) : [];
    this.initialMaterialDepletionResponse = items ? cloneDeep(items) : [];
  };
  @Input() description: string;
  @Input() title: string;
  @Input() isRepackagingTarget = false;
  @Input() isRepackagingSource = false;

  @Output() materialDepletionClosed: EventEmitter<void> = new EventEmitter();
  @Output() materialDepletionUpdated: EventEmitter<MaterialsDepletionData> = new EventEmitter();

  tempMaterialDepletionRequest: MaterialDepletionRequestModel[] = [];
  tempMaterialDepletionResponse: MaterialDepletionResponseModel[] = [];

  isAddMaterialActive = false;
  formSubmitted = false;
  isFormValid = true;

  readonly patternForAmount = this.formatsService.patternForAmount();
  readonly pricePrefix = pricePrefix;

  get selectedMasterItemsExternalIds(): string[] {
    return this.tempMaterialDepletionRequest.map(item => item.masterItemExternalId);
  }

  private initialMaterialDepletionRequest: MaterialDepletionRequestModel[] = [];
  private initialMaterialDepletionResponse: MaterialDepletionResponseModel[] = [];

  constructor(
    public spinnerService: SpinnerService,
    private formatsService: FormatsService
  ) {
  }

  removeItem(index: number, innerIndex: number): void {
    this.tempMaterialDepletionRequest[index].items.splice(innerIndex, 1);
    this.tempMaterialDepletionResponse[index].items.splice(innerIndex, 1);
    if (this.tempMaterialDepletionRequest[index].items.length === 0) {
      this.tempMaterialDepletionRequest.splice(index, 1);
      this.tempMaterialDepletionResponse.splice(index, 1);
    }
  }

  addItem(item: MaterialDepletionLookupResponseModel): void {
    const index = this.tempMaterialDepletionRequest.findIndex(requestItem => requestItem.masterItemExternalId === item.externalId);
    if (index !== -1) {
      this.tempMaterialDepletionRequest[index].items.push(new MaterialDepletionItemRequestModel({
        lotId: item.lotId,
        facilityExternalId: item.facility.externalId,
        quantity: null,
        cost: item.cost,
      }));
      this.tempMaterialDepletionResponse[index].items.push(new MaterialDepletionItemResponseModel({
        facility: item.facility,
        lotId: item.lotId,
        lotQuantity: item.quantity,
        availableQuantity: item.quantity,
        cost: item.cost,
        unitPrice: item.unitPrice,
        unitOfMeasurement: item.unitOfMeasurement
      }));
    } else {
      this.tempMaterialDepletionRequest.push(new MaterialDepletionRequestModel({
        masterItemExternalId: item.externalId,
        minimumRequiredQuantity: 0,
        items: [new MaterialDepletionItemRequestModel({
          lotId: item.lotId,
          cost: item.cost,
          facilityExternalId: item.facility.externalId,
          quantity: null,
        })]
      }));
      this.tempMaterialDepletionResponse.push(new MaterialDepletionResponseModel({
        minimumRequiredQuantity: 0,
        externalId: item.externalId,
        name: item.name,
        items: [new MaterialDepletionItemResponseModel({
          facility: item.facility,
          lotId: item.lotId,
          lotQuantity: item.quantity,
          availableQuantity: item.quantity,
          cost: item.cost,
          unitPrice: item.unitPrice,
          unitOfMeasurement: item.unitOfMeasurement
        })]
      }));
    }
    this.isAddMaterialActive = false;
  }

  confirmMaterialsDepletion(): void {
    this.formSubmitted = true;
    this.isFormValid = true;
    this.tempMaterialDepletionRequest.forEach(item => {
      item.items.forEach(innerItem => {
        if (innerItem.quantity === null) {
          this.isFormValid = false;
        }
      });
    });
    if (this.isFormValid) {
      this.materialDepletionUpdated.emit({
        currentMaterialDepletion: cloneDeep(this.tempMaterialDepletionRequest),
        defaultMaterialDepletion: cloneDeep(this.tempMaterialDepletionResponse)
      });
    }
  }

  closeMaterialDepletion(): void {
    this.tempMaterialDepletionRequest = cloneDeep(this.initialMaterialDepletionRequest);
    this.tempMaterialDepletionResponse = cloneDeep(this.initialMaterialDepletionResponse);
    this.materialDepletionClosed.emit();
  }

  closeAddItemModal(event: { value: boolean }): void {
    if (event.value) {
      this.isAddMaterialActive = false;
    }
  }

  toggleAddItemModal(): void {
    this.isAddMaterialActive = !this.isAddMaterialActive;
  }

  getMaterialCost(unitPrice: number, itemModel: MaterialDepletionItemRequestModel) {
    itemModel.cost = unitPrice * +itemModel.quantity;
    return itemModel.cost;
  }
}

export interface MaterialsDepletionData {
  currentMaterialDepletion: MaterialDepletionRequestModel[];
  defaultMaterialDepletion: MaterialDepletionResponseModel[];
}
