import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { MixpanelService } from '@onbatch/shared/services/mixpanel.service';
import { MixPanelEvents } from '@onbatch/shared/constants';

@Component({
  selector: 'app-welcome-page',
  styleUrls: ['./welcome-page.component.scss'],
  templateUrl: './welcome-page.component.html'
})
export class WelcomePageComponent implements OnInit {
  constructor(public authService: AuthService, private mixpanelService: MixpanelService) {
  }

  ngOnInit(): void {
    this.mixpanelService.track(MixPanelEvents.WELCOME_PAGE, {});
  }
}
