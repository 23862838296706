import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BillOfMaterialsItemUpdateModel,
  BillOfMaterialsResponseModel,
  ExportRequestModel,
  FileResponse,
  FileType, FilterOptions,
  FilterRequestApplyModel, InventoryClientFetch,
  InventoryHistoryLogResponse, MasterItemBillOfMaterialsLookUpRequestModel,
  MasterItemContainerRequestModel, MasterItemContainerResponseModel,
  MasterItemFullResponseModel,
  MasterItemListResponseModel, MasterItemQuantityRequestModel,
  MasterItemRequestModel,
  MasterItemResponseModel,
  MasterItemTemplateRequestModel,
  MasterItemTemplateResponseModel,
  MasterItemType,
  PackageRequestModel,
  PackageResponseFullModel,
  PageInfo,
  PaginatedResponseOfInventoryHistoryLogListResponse,
  PaginatedResponseOfLookUpResponse, PaginatedResponseOfLookUpResponseTreeWithDescription,
  PaginatedResponseOfMasterItemListResponseModel, PaginatedResponseOfMasterItemLookupResponse,
  PaginatedResponseOfMasterItemLotsListResponse,
  PaginatedResponseOfMasterItemPurchaseListResponse,
  PaginatedResponseOfMasterItemSalesListResponse,
  RemovalResponseModel,
  SpiritItemRequestModel,
  SpiritItemResponseModel
} from '../../core/services/Inventory';
import { EnvService } from '../../core/services/Env';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MasterItemDataTable } from '../../shared';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MasterItemDefaultParams, MasterItemDefaultSettings, MasterItemDefaultSort } from './master-item-statics.enum';
import { AuthService } from '../../core/services/auth.service';
import {MasterItemTypeId} from '@onbatch/core/services/InventoryRx';

const MasterItemDataTableDefaults: MasterItemDataTable = {
  params: MasterItemDefaultParams,
  sort: MasterItemDefaultSort,
  showRemoved: true,
  settings: MasterItemDefaultSettings,
  filters: []
};

@Injectable()
export class MasterItemService {
  masterItemDataTableParams = new BehaviorSubject<MasterItemDataTable>(null);

  itemID: Subject<string> = new Subject<string>();
  duplicateID: Subject<string> = new Subject<string>();
  masterItemList: Subject<Array<MasterItemListResponseModel>> = new Subject<Array<MasterItemListResponseModel>>();
  masterItemSingle = new BehaviorSubject<MasterItemFullResponseModel | null>(null);
  masterItemSidebar = new BehaviorSubject<boolean>(false);
  masterItemPagination = new BehaviorSubject<PageInfo>(null);
  masterItemFiltersOptions = new BehaviorSubject<FilterOptions[]>(null);

  locationsAddedToMasterItem = new BehaviorSubject<MasterItemQuantityRequestModel[]>([]);

  private masterItemClient: InventoryClientFetch;

  constructor(private http: HttpClient, private envService: EnvService, private localStorage: LocalStorage, private authService: AuthService) {
    this.masterItemClient = new InventoryClientFetch(envService.apiUrl);

    this.localStorage.getItem(
      this.authService.getStorageKey('master-item-data-table')
    ).subscribe((data: MasterItemDataTable | null) => {
      this.masterItemDataTableParams.next(data || MasterItemDataTableDefaults);
    });
  }

  setMasterItemSingle(item: MasterItemFullResponseModel) {
    this.masterItemSingle.next(item);
    this.masterItemSidebar.next(true);
  }

  getMasterItemSingle(): Observable<MasterItemFullResponseModel> {
    return this.masterItemSingle.asObservable();
  }

  fetchMasterItemSingle(externalId: string): void {
    this.masterItemClient.masterItemByExternalIdGet(externalId).then(data => this.setMasterItemSingle(data));
  }

  setMasterItemSide(item: boolean) {
    this.masterItemSidebar.next(item);
  }

  getMasterItemSide(): Observable<boolean> {
    return this.masterItemSidebar.asObservable();
  }

  getMasterItemPagination(): Observable<PageInfo> {
    return this.masterItemPagination.asObservable();
  }

  setMasterItemPagination(item: PageInfo) {
    this.masterItemPagination.next(item);
  }

  setMasterItemFiltersOptions(item: FilterOptions[]) {
    this.masterItemFiltersOptions.next(item);
  }

  getMasterItemFiltersOptions(): Observable<FilterOptions[]> {
    return this.masterItemFiltersOptions.asObservable();
  }

  setDataTableParams(data: any) {
    const newVal = { ...this.masterItemDataTableParams.value, ...data };
    this.masterItemDataTableParams.next(newVal);
    const key = this.authService.getStorageKey('master-item-data-table');
    this.localStorage.setItemSubscribe(key, newVal);
    this.fetchNewList();
  }

  getDataTableParams(): Observable<MasterItemDataTable> {
    return this.masterItemDataTableParams.asObservable();
  }

  index(filterRequest?: FilterRequestApplyModel[] | null | undefined, includeInactive?: boolean | null | undefined, x_query?: string | null | undefined, x_order?: string | null | undefined, x_desc?: any | null | undefined, x_pageNumber?: any | null | undefined, x_pageSize?: any | null | undefined): Promise<PaginatedResponseOfMasterItemListResponseModel> {
    return this.masterItemClient.masterItemFilterPut(filterRequest, includeInactive, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  post(model?: MasterItemRequestModel | null | undefined): Promise<MasterItemResponseModel> {
    return this.masterItemClient.masterItemPost(model);
  }

  put(externalId: string, model?: MasterItemRequestModel | null | undefined): Promise<MasterItemResponseModel> {
    return this.masterItemClient.masterItemByExternalIdPut(externalId, model);
  }

  getSingle(externalId: string): Promise<MasterItemFullResponseModel> {
    return this.masterItemClient.masterItemByExternalIdGet(externalId);
  }

  getSpirit(masterItemExternalId: string): Promise<SpiritItemResponseModel> {
    return this.masterItemClient.spiritItemByMasterItemExternalIdGet(masterItemExternalId);
  }

  putSpirit(
    masterItemExternalId: string,
    model?: SpiritItemRequestModel | null | undefined
  ): Promise<SpiritItemResponseModel> {
    return this.masterItemClient.spiritItemByMasterItemExternalIdPut(masterItemExternalId, model);
  }

  putContainer(externalId: string, model?: MasterItemContainerRequestModel | null | undefined): Promise<MasterItemContainerResponseModel> {
    return this.masterItemClient.masterItemByExternalIdContainerPut(externalId, model);
  }

  getSingleHistory(
    externalId: string,
    from?: number | null | undefined,
    to?: number | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfInventoryHistoryLogListResponse> {
    return this.masterItemClient.inventoryHistoryLogMasterItemByExternalIdGet(
      externalId,
      from,
      to,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  getSingleHistoryLogById(externalId: string): Promise<InventoryHistoryLogResponse> {
    return this.masterItemClient.inventoryHistoryLogByExternalIdGet(externalId);
  }

  putPackage(externalId: string, model?: PackageRequestModel | null | undefined): Promise<PackageResponseFullModel> {
    return this.masterItemClient.masterItemByExternalIdPackagePut(externalId, model);
  }

  getBillOfMaterials(externalId: string): Promise<BillOfMaterialsResponseModel[]> {
    return this.masterItemClient.masterItemByExternalIdBillOfMaterialsGet(externalId);
  }

  putBillOfMaterials(
    externalId: string,
    updateModel?: BillOfMaterialsItemUpdateModel[] | null | undefined
  ): Promise<BillOfMaterialsResponseModel[]> {
    return this.masterItemClient.masterItemByExternalIdBillOfMaterialsPut(externalId, updateModel);
  }

  lookUpItems(
    withoutPricing?: boolean | null | undefined,
    withoutRawMaterials: boolean = false,
    withoutFinishedGoods: boolean = true,
    withoutSemiFinishedGoods: boolean = false,
    withoutAlcohol: boolean = false,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfMasterItemLookupResponse> {
    return this.masterItemClient.lookUpMasteritemsGet(
      withoutPricing,
      withoutRawMaterials,
      withoutFinishedGoods,
      withoutSemiFinishedGoods,
      withoutAlcohol,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  lookUpBillOfMaterials(request?: MasterItemBillOfMaterialsLookUpRequestModel | null | undefined, x_query?: string | null | undefined, x_order?: string | null | undefined, x_desc?: any | null | undefined, x_pageNumber?: any | null | undefined, x_pageSize?: any | null | undefined): Promise<PaginatedResponseOfMasterItemLookupResponse> {
    return this.masterItemClient.lookUpMasteritemsBillOfMaterialsPut(
      request,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  removeItem(externalIds?: string[] | null | undefined): Promise<RemovalResponseModel> {
    return this.masterItemClient.masterItemDelete(externalIds);
  }

  lookupTemplates(
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined
  ): Promise<PaginatedResponseOfLookUpResponse> {
    return this.masterItemClient.lookUpMasteritemsTemplatesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupUoM(
    masterItemType?: MasterItemType | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfLookUpResponse> {
    return this.masterItemClient.lookUpUnitofmeasurementGet(
      masterItemType,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  getMasterItemTemplates(): Promise<MasterItemTemplateResponseModel[]> {
    return this.masterItemClient.templateMasterItemGet();
  }

  getSingleMasterItemTemplate(externalId: string): Promise<MasterItemTemplateResponseModel> {
    return this.masterItemClient.templateMasterItemByExternalIdGet(externalId);
  }

  postMasterItemTemplate(
    model?: MasterItemTemplateRequestModel | null | undefined
  ): Promise<MasterItemTemplateResponseModel> {
    return this.masterItemClient.templateMasterItemPost(model);
  }

  updateTemplate(
    externalId: string,
    model?: MasterItemTemplateRequestModel | null | undefined
  ): Promise<MasterItemTemplateResponseModel> {
    return this.masterItemClient.templateMasterItemByExternalIdPut(externalId, model);
  }

  deleteTemplate(externalId: string): Promise<void> {
    return this.masterItemClient.templateMasterItemByExternalIdDelete(externalId);
  }

  changeMasterItemStatus(externalId: string, status: boolean): Promise<RemovalResponseModel> {
    return this.masterItemClient.masterItemByExternalIdSetstatusByStatusPut(externalId, status);
  }

  lookupLocations(masterItemTypeId?: MasterItemTypeId | null | undefined,
                  x_query?: string | null | undefined,
                  x_order?: string | null | undefined,
                  x_desc?: any | null | undefined,
                  x_pageNumber?: any | null | undefined,
                  x_pageSize?: any | null | undefined): Promise<PaginatedResponseOfLookUpResponse> {
    return this.masterItemClient.lookUpFacilitiesGet(masterItemTypeId, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  deleteItems(externalIds?: string[] | null | undefined): Promise<RemovalResponseModel> {
    return this.masterItemClient.masterItemDelete(externalIds);
  }

  getSales(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfMasterItemSalesListResponse> {
    return this.masterItemClient.masterItemByExternalIdSalesGet(
      externalId,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  getPurchases(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfMasterItemPurchaseListResponse> {
    return this.masterItemClient.masterItemByExternalIdPurchaseGet(
      externalId,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  getLots(
    externalId: string,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfMasterItemLotsListResponse> {
    return this.masterItemClient.masterItemByExternalIdLotsGet(
      externalId,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  duplicate(externalIds?: string[] | null | undefined): Promise<MasterItemResponseModel[]> {
    return this.masterItemClient.masterItemDuplicatePut(externalIds);
  }

  filterMasterItems(
    filterRequest?: FilterRequestApplyModel[] | null | undefined,
    includeInactive: boolean = false,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfMasterItemListResponseModel> {
    return this.masterItemClient.masterItemFilterPut(filterRequest, includeInactive, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupKindOfSpirits(
    filterBySettings: boolean | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined
  ): Promise<PaginatedResponseOfLookUpResponseTreeWithDescription> {
    return this.masterItemClient.lookUpKindofspiritGet(filterBySettings, x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  exportFile(fileType: FileType, exportRequestModel?: ExportRequestModel | null | undefined): Promise<FileResponse> {
    return this.masterItemClient.masterItemDownloadByFileTypePut(fileType, exportRequestModel);
  }

  lookupCategories(
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfLookUpResponse> {
    return this.masterItemClient.lookUpCategoriesGet(x_query, x_order, x_desc, x_pageNumber, x_pageSize);
  }

  lookupFermentable(
    categoryExternalId?: string | null | undefined,
    x_pageNumber?: any | null | undefined,
    x_pageSize?: any | null | undefined,
    x_query?: string | null | undefined,
    x_order?: string | null | undefined,
    x_desc?: any | null | undefined
  ): Promise<PaginatedResponseOfLookUpResponse> {
    return this.masterItemClient.lookUpFermentablematerialGet(
      categoryExternalId,
      x_query,
      x_order,
      x_desc,
      x_pageNumber,
      x_pageSize
    );
  }

  fetchNewList() {
    if (!this.masterItemDataTableParams) {
      return;
    }

    const params = this.masterItemDataTableParams.value.params;
    this.filterMasterItems(
      this.masterItemDataTableParams.value.filters,
      params.includeInactive,
      params.x_pageNumber,
      params.x_pageSize,
      params.x_query,
      params.x_order,
      params.x_desc
    ).then(data => {
      this.masterItemList.next(data.list);
      this.setMasterItemPagination(data.pageInfo);
      this.setMasterItemFiltersOptions(data.filterColumns);
    });
  }

  setItemID(data: string) {
    this.itemID.next(data);
  }

  setDuplicateID(data: string) {
    this.duplicateID.next(data);
  }

  getMasterItemList(): Observable<Array<MasterItemListResponseModel>> {
    return this.masterItemList.asObservable();
  }

  clearID() {
    this.itemID.next(null);
  }

  clearDuplicateID() {
    this.duplicateID.next(null);
  }

  masterItemSingleClear() {
    this.masterItemSingle.next(null);
    this.masterItemSidebar.next(false);
  }

  getLocationsAddedToMasterItem(): Observable<MasterItemQuantityRequestModel[]> {
    return this.locationsAddedToMasterItem.asObservable();
  }

  setLocationsAddedToMasterItem(value: MasterItemQuantityRequestModel[]) {
    this.locationsAddedToMasterItem.next(value);
  }

  getLastNumber(): Promise<number> {
    return this.masterItemClient.masterItemLastItemNumberGet();
  }

  validateItemNumber(itemNumber: number): Promise<boolean> {
    return this.masterItemClient.masterItemItemNumberByItemNumberGet(itemNumber);
  }
}
