import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};
    if (this.authService.isLoggedIn()) {
      headersConfig['Authorization'] = AuthService.getAuthorizationHeaderValue();
      if (this.authService.getOrgFromStorage()) {
        headersConfig['x-company'] = this.authService.getOrgFromStorage();
      }  else {
        headersConfig['x-company'] = this.authService.currentOrg.value;
      }
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
