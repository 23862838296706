import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { OWL_DTPICKER_SETTINGS_PROVIDER } from '@onbatch/core/factories/datePicker.factory';
import {FormBoxes} from '../../index';
import { closeCalendar } from '../../utils';

@Component({
  selector: 'app-time-log',
  templateUrl: './time-log.component.html',
  providers: [OWL_DTPICKER_SETTINGS_PROVIDER]
})
export class TimeLogComponent implements OnInit {
  @Input() isTimeLogVisible: boolean;
  @Output() closeTimeLog: EventEmitter<boolean> = new EventEmitter<boolean>();

  formSubmitStatus = false;
  form: FormGroup;
  formBoxes: FormBoxes;

  calendar: { date: any; time: any } = {
    date: null,
    time: null
  };

  closeDatepicker = closeCalendar;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formBoxes = {
      timeSpent: {
        fieldType: 'input',
        type: 'number',
        label: 'Time spent',
        name: 'TimeSpent',
        placeholder: '0',
        field: 'timeSpent'
      }
    };

    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      timeSpent: new FormControl(null, Validators.required),
      startDate: new FormControl(this.calendar.date, Validators.required)
    });
  }

  onFormSubmit() {
    const startDate = new Date(this.calendar.date).getTime() / 1000;
    this.form.controls['startDate'].setValue(startDate);

    this.closeTimeLog.emit(false);
  }

  onCloseClick() {
    this.closeTimeLog.emit(false);
  }

  onCalendarChange() {
    const currentDate = new Date().setHours(0, 0, 0, 0) / 1000;

    if (this.calendar.time && this.calendar.date) {
      const currentTimeOnly = Math.round((new Date(this.calendar.time).getTime() / 1000) - currentDate);
      const currentDateOnly = new Date(this.calendar.date).setHours(0, 0, 0, 0) / 1000;
      this.calendar.date = new Date((currentDateOnly + currentTimeOnly) * 1000);
    }

    // Set startDate
    const startDate = new Date(this.calendar.date).getTime() / 1000;
    this.form.controls['startDate'].setValue(startDate);
  }
}
