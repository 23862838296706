import { ChangeDetectionStrategy, Component } from '@angular/core';
import has = Reflect.has;

@Component({
  selector: 'app-settings-schema-helper',
  templateUrl: './settings-schema-helper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsSchemaHelperComponent {
}

export function prepareIdSchema(value: string): string {
  if (!value) {
    return null;
  }
  let schema = value;
  const currentYear = new Date().getFullYear().toString();
  let currentMonth = new Date().getMonth().toString();
  if (currentMonth.length === 1) {
    currentMonth = `0${currentMonth}`;
  }
  let currentDay = new Date().getDay().toString();
  if (currentDay.length === 1) {
    currentDay = `0${currentDay}`;
  }

  if (value.includes('YYYY')) {
    schema = schema.replace('YYYY', currentYear);
  }
  if (value.includes('YY')) {
    schema = schema.replace('YY', currentYear.replace('20', ''));
  }
  if (value.includes('MM')) {
    schema = schema.replace('MM', currentMonth);
  }
  if (value.includes('DD')) {
    schema = schema.replace('DD', currentDay);
  }
  if (value.includes('####')) {
    schema = schema.replace('####', '0001');
  }
  if (value.includes('###')) {
    schema = schema.replace('###', '001');
  }
  if (value.includes('##')) {
    schema = schema.replace('##', '01');
  }
  if (value.includes('#')) {
    schema = schema.replace('#', '1');
  }
  return schema;
}
