export const Prefixes = {
  po: 'PO-',
  so: 'SO-',
  tib: 'TIB-',
};

export enum MixPanelEvents {
  // Account Routing
  'ACCOUNT_MODULE' = 'Account: Module',
  'ACCOUNT_BUSINESS_STRUCTURE' = 'Account: Business Structure',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_EDIT' = 'Account: Business Structure company edit',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_NEW' = 'Account: Business Structure company new',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_VIEW' = 'Account: Business Structure company view',
  'ACCOUNT_GLOBAL' = 'Account: Global settings',
  'ACCOUNT_GLOBAL_ACCOUNTING' = 'Account: Global settings accounting',
  'ACCOUNT_GLOBAL_ADD_CARD' = 'Account: Global settings add card',
  'ACCOUNT_GLOBAL_BILLING_INFORMATION' = 'Account: Global settings billing information',
  'ACCOUNT_GLOBAL_FORMATTING' = 'Account: Global settings formatting',
  'ACCOUNT_GLOBAL_INVENTORY_MODULE' = 'Account: Global settings inventory module',
  'ACCOUNT_GLOBAL_MANUFACTURING_MODULE' = 'Account: Global settings manufacturing module',
  'ACCOUNT_GLOBAL_PAYMENT_HISTORY' = 'Account: Global settings payment history',
  'ACCOUNT_GLOBAL_PURCHASING_MODULE' = 'Account: Global settings purchasing module',
  'ACCOUNT_GLOBAL_SALES_MODULE' = 'Account: Global settings sales module',
  'ACCOUNT_GLOBAL_SELECT_SUBSCRIPTION_PLAN' = 'Account: Global settings subscription plan',
  'ACCOUNT_GLOBAL_TAXES' = 'Account: Global settings taxes',
  'ACCOUNT_GLOBAL_WAREHOUSE_MODULE' = 'Account: Global settings warehouse module',
  'ACCOUNT_NOTIFICATIONS' = 'Account: Notifications',
  'ACCOUNT_PAYMENT_HISTORY' = 'ACCOUNT_PAYMENT_HISTORY: Payment history',
  'ACCOUNT_PERSONAL_INFORMATION_ACCOUNT_DETAILS' = 'Account: Personal information account details',
  'ACCOUNT_PERSONAL_INFORMATION_NOTIFICATIONS' = 'Account: Personal information notifications',
  'ACCOUNT_ROLES' = 'Account: Member roles',
  'ACCOUNT_ROLES_NEW' = 'Account: Member roles new',
  'ACCOUNT_TEAM_MEMBERS' = 'Account: Team members',
  'ACCOUNT_TEAM_MEMBERS_INVITE' = 'Account: Team members invite',
  // Account Events: Billing
  'ACCOUNT_BILLING_CARD_ADD' = 'Account: Billing - card added',
  'ACCOUNT_BILLING_CARD_UPDATED' = 'Account: Billing - card updated',
  'ACCOUNT_BILLING_CARD_DELETED' = 'Account: Billing - card deleted',
  // Account Events: Subscription plans
  'ACCOUNT_SUBSCRIPTION_CANCELED' = 'Account: Subscription canceled',
  // Account Events: Business structure - companies
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_ADDED' = 'Account: Company added',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_ADD_CANCELED' = 'Account: Company add - canceled',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_UPDATED' = 'Account: Company edit - changes saved',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_EDIT_CANCELED' = 'Account: Company edit - canceled',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_USER_ASSIGNED' = 'Account: Company - user assigned',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_USER_UNASSIGNED' = 'Account: Company - user unassigned',
  'ACCOUNT_BUSINESS_STRUCTURE_COMPANY_DELETED' = 'Account: Company deleted',
  'ACCOUNT_BUSINESS_STRUCTURE_FACILITY_ADDED' = 'Account: Facility added',
  'ACCOUNT_BUSINESS_STRUCTURE_FACILITY_UPDATED' = 'Account: Facility edit - changes saved',
  'ACCOUNT_BUSINESS_STRUCTURE_FACILITY_DELETED' = 'Account: Facility deleted',
  // Account Events: Roles
  'ACCOUNT_ROLE_ADDED' = 'Account: Role added',
  'ACCOUNT_ROLE_ADD_CANCELED' = 'Account: Role add - canceled',
  'ACCOUNT_ROLE_UPDATED' = 'Account: Role edit - changes saved',
  'ACCOUNT_ROLE_EDIT_CANCELED' = 'Account: Role edit - canceled',
  'ACCOUNT_ROLE_DELETED' = 'Account: Role(s) deleted',
  'ACCOUNT_ROLE_ARCHIVED' = 'Account: Role(s) archived',
  'ACCOUNT_ROLE_DUPLICATED' = 'Account: Role(s) duplicated',
  'ACCOUNT_ROLE_USER_ASSIGNED' = 'Account: Role - user assigned',
  'ACCOUNT_ROLE_USER_UNASSIGNED' = 'Account: Role - user unassigned',
  // Account Events: Team members
  'ACCOUNT_TEAM_MEMBER_SUSPENDED' = 'Account: Team member suspended',
  'ACCOUNT_TEAM_MEMBER_UNSUSPENDED' = 'Account: Team member unsuspended',
  'ACCOUNT_TEAM_MEMBER_DELETED' = 'Account: Team member(s) deleted',
  'ACCOUNT_TEAM_MEMBER_ARCHIVED' = 'Account: Team member(s) archived',
  'ACCOUNT_TEAM_MEMBER_INVITED' = 'Account: Team member(s) invited',
  'ACCOUNT_TEAM_MEMBER_ROLE_ASSIGNED' = 'Account: Team member - role assigned',
  'ACCOUNT_TEAM_MEMBER_ROLE_UNASSIGNED' = 'Account: Team member - role unassigned',
  // Account Events: Global settings
  'ACCOUNT_GLOBAL_ACCOUNTING_SETTINGS_UPDATED' = 'Account: Accounting settings - changes saved',
  'ACCOUNT_GLOBAL_FORMATTING_SETTINGS_UPDATED' = 'Account: Formatting settings - changes saved',
  'ACCOUNT_GLOBAL_INVENTORY_SETTINGS_UPDATED' = 'Account: Inventory settings - changes saved',
  'ACCOUNT_GLOBAL_MANUFACTURING_SETTINGS_UPDATED' = 'Account: Manufacturing settings - changes saved',
  'ACCOUNT_GLOBAL_PURCHASING_SETTINGS_UPDATED' = 'Account: Purchasing settings - changes saved',
  'ACCOUNT_GLOBAL_SALES_SETTINGS_UPDATED' = 'Account: Sales settings - changes saved',
  'ACCOUNT_GLOBAL_TAXES_SETTINGS_UPDATED' = 'Account: Taxes settings - changes saved',
  'ACCOUNT_GLOBAL_WAREHOUSE_SETTINGS_UPDATED' = 'Account: Warehouse settings - changes saved',
  'ACCOUNT_GLOBAL_SETTINGS_CANCELED' = 'Account: Global settings - canceled',
  // Account Events: Personal information
  'ACCOUNT_PERSONAL_INFORMATION_UPDATED' = 'Account: Personal information edit - changes saved',
  'ACCOUNT_PERSONAL_INFORMATION_NOTIFICATIONS_UPDATED' = 'Account: Personal information - notification settings updated',
  'ACCOUNT_PERSONAL_INFORMATION_EDIT_CANCELED' = 'Account: Personal information edit - canceled',
  'ACCOUNT_PERSONAL_INFORMATION_PASSWORD_CHANGED' = 'Account: Personal information password changed',
  // 'ACCOUNTING_MODULE' = 'Accounting: Module', // ToDo Module doesn't exist yet
  // CRM Routing
  'CRM_MODULE' = 'CRM: Module',
  'CRM_DASHBOARDS' = 'CRM: Dashboards',
  'CRM_COMPANIES' = 'CRM: Companies',
  'CRM_COMPANIES_EDIT' = 'CRM: Companies edit',
  'CRM_COMPANIES_NEW' = 'CRM: Companies new',
  'CRM_COMPANIES_VIEW' = 'CRM: Companies view',
  'CRM_CONTACTS' = 'CRM: Contacts',
  'CRM_CONTACTS_EDIT' = 'CRM: Contacts edit',
  'CRM_CONTACTS_NEW' = 'CRM: Contacts new',
  'CRM_CONTACTS_VIEW' = 'CRM: Contacts view',
  'CRM_TASKS' = 'CRM: Tasks',
  'CRM_REPORTS' = 'CRM: Reports',
  'CRM_HISTORY_LOG' = 'CRM: History log',
  'CRM_HISTORY_LOG_LIST' = 'CRM: History log list',
  'CRM_HISTORY_LOG_ITEM' = 'CRM: History log item',
  // CRM Events: Contacts
  'CRM_CONTACT_ADDED' = 'CRM: Contact added',
  'CRM_CONTACT_ADD_CANCELED' = 'CRM: Contact add - canceled',
  'CRM_CONTACT_UPDATED' = 'CRM: Contact edit - changes saved',
  'CRM_CONTACT_EDIT_CANCELED' = 'CRM: Contact edit - canceled',
  'CRM_CONTACT_DELETED' = 'CRM: Contact deleted',
  // CRM Events: Companies
  'CRM_COMPANY_ADDED' = 'CRM: Company added',
  'CRM_COMPANY_ADD_CANCELED' = 'CRM: Company add - canceled',
  'CRM_COMPANY_UPDATED' = 'CRM: Company edit - changes saved',
  'CRM_COMPANY_EDIT_CANCELED' = 'CRM: Company edit - canceled',
  'CRM_COMPANY_DELETED' = 'CRM: Company deleted',
  'CRM_COMPANY_ARCHIVED' = 'CRM: Company archived',
  // Inventory Routing
  'INVENTORY_MODULE' = 'Inventory: Module',
  'INVENTORY_DASHBOARDS' = 'Inventory: Dashboards',
  'INVENTORY_MASTER_ITEM_EDIT' = 'Inventory: Master item edit',
  'INVENTORY_MASTER_ITEM_VIEW' = 'Inventory: Master item view',
  'INVENTORY_MASTER_ITEMS' = 'Inventory: Master items',
  'INVENTORY_MASTER_ITEMS_BILL_OF_MATERIALS' = 'Inventory: Master items bill of materials',
  'INVENTORY_MASTER_ITEMS_GENERAL_INFORMATION' = 'Inventory: Master items general information',
  'INVENTORY_MASTER_ITEMS_HISTORY' = 'Inventory: Master items history',
  'INVENTORY_MASTER_ITEMS_LIST' = 'Inventory: Master items list',
  'INVENTORY_MASTER_ITEMS_LOTS' = 'Inventory: Master items lots',
  'INVENTORY_MASTER_ITEMS_PRICE' = 'Inventory: Master items price',
  'INVENTORY_MASTER_ITEMS_PURCHASES' = 'Inventory: Master items purchases',
  'INVENTORY_MASTER_ITEMS_SALES' = 'Inventory: Master items sales',
  'INVENTORY_MASTER_ITEMS_SPECIFIC_DETAILS' = 'Inventory: Master items specific details',
  'INVENTORY_ITEMS_PRICING' = 'Inventory: Items pricing',
  'INVENTORY_STOCK_COUNT' = 'Inventory: Stock count',
  'INVENTORY_STOCK_COUNT_ITEMS' = 'Inventory: Stock count items',
  'INVENTORY_STOCK_COUNT_VIEW' = 'Inventory: Stock count view',
  'INVENTORY_STOCK_COUNT_VIEW_CONTAINERS' = 'Inventory: Stock count view containers',
  'INVENTORY_STOCK_COUNT_VIEW_FINISHED_GOODS' = 'Inventory: Stock count view finished goods',
  'INVENTORY_STOCK_COUNT_VIEW_PACKAGING_MATERIALS' = 'Inventory: Stock count view packaging materials',
  'INVENTORY_STOCK_COUNT_VIEW_RAW_MATERIALS' = 'Inventory: Stock count view raw materials',
  'INVENTORY_STOCK_COUNT_VIEW_SEMI_FINISHED_GOODS' = 'Inventory: Stock count view semi finished goods',
  'INVENTORY_REPORTS' = 'Inventory: Reports',
  'INVENTORY_HISTORY_LOG' = 'Inventory: History log',
  'INVENTORY_HISTORY_LOG_LIST' = 'Inventory: History log list',
  'INVENTORY_HISTORY_LOG_ITEM' = 'Inventory: History log item',
  // Inventory Events: Master Items
  'INVENTORY_MASTER_ITEM_ADDED' = 'Inventory: Master item added',
  'INVENTORY_MASTER_ITEM_ADD_CANCELED' = 'Inventory: Master item add - canceled',
  'INVENTORY_MASTER_ITEM_EDITED' = 'Inventory: Master item edit - changes saved',
  'INVENTORY_MASTER_ITEM_EDIT_CANCEL' = 'Inventory: Master item edit - canceled',
  'INVENTORY_MASTER_ITEM_TRANSFERRED' = 'Inventory: Master item - transferred',
  'INVENTORY_MASTER_ITEM_TRANSFER_CANCELED' = 'Inventory: Master item - transfer canceled',
  'INVENTORY_MASTER_ITEM_DELETED' = 'Inventory: Master item - deleted',
  'INVENTORY_MASTER_ITEM_NOT_DELETED' = 'Inventory: Master item - not deleted',
  'INVENTORY_MASTER_ITEM_ARCHIVED' = 'Inventory: Master item - archived',
  'INVENTORY_MASTER_ITEM_NOT_ARCHIVED' = 'Inventory: Master item - not archived',
  'INVENTORY_MASTER_ITEM_RESTORED' = 'Inventory: Master item - restored',
  'INVENTORY_MASTER_ITEM_DUPLICATED' = 'Inventory: Master item - duplicated',
  'INVENTORY_MASTER_ITEMS_BILL_OF_MATERIALS_ADDED' = 'Inventory: Master item - bill of materials added',
  'INVENTORY_MASTER_ITEMS_BILL_OF_MATERIALS_UPDATED' = 'Inventory: Master item - bill of materials updated',
  'INVENTORY_MASTER_ITEMS_BILL_OF_MATERIALS_DELETED' = 'Inventory: Master item - bill of materials deleted',
  // Inventory Events: Pricing
  'INVENTORY_PRICING_BY_ITEM_ADDED' = 'Inventory: Pricing by item added',
  'INVENTORY_PRICING_BY_ITEM_ADD_CANCELED' = 'Inventory: Pricing by item add - canceled',
  'INVENTORY_PRICING_BY_ITEM_DELETED' = 'Inventory: Pricing by item - deleted',
  'INVENTORY_PRICING_BY_ITEM_PRESET_RULE_ADDED' = 'Inventory: Pricing by item - preset rule added',
  'INVENTORY_PRICING_BY_ITEM_PRESET_RULE_UPDATED' = 'Inventory: Pricing by item - preset rule updated',
  'INVENTORY_PRICING_BY_ITEM_PRESET_RULE_DELETED' = 'Inventory: Pricing by item - preset rule deleted',
  'INVENTORY_PRICING_BY_CUSTOMER_ADDED' = 'Inventory: Pricing by customer added',
  'INVENTORY_PRICING_BY_CUSTOMER_ADD_CANCELED' = 'Inventory: Pricing by customer add - canceled',
  'INVENTORY_PRICING_BY_CUSTOMER_DELETED' = 'Inventory: Pricing by customer - deleted',
  'INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_ADDED' = 'Inventory: Pricing by customer - preset rule added',
  'INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_UPDATED' = 'Inventory: Pricing by customer - preset rule updated',
  'INVENTORY_PRICING_BY_CUSTOMER_PRESET_RULE_DELETED' = 'Inventory: Pricing by customer - preset rule deleted',
  // Inventory Events: Stock count
  'INVENTORY_STOCK_COUNT_ADDED' = 'Inventory: Stock count added',
  'INVENTORY_STOCK_COUNT_CANCELED' = 'Inventory: Stock count canceled',
  'INVENTORY_STOCK_COUNT_ADD_CANCELED' = 'Inventory: Stock count add - canceled',
  'INVENTORY_STOCK_COUNT_FINISHED' = 'Inventory: Stock count finished',
  'INVENTORY_STOCK_COUNT_DISCREPANCY_SAVED' = 'Inventory: Stock count discrepancy saved',
  // Manufacturing Routing
  'MANUFACTURING_MODULE' = 'Manufacturing: Module',
  'MANUFACTURING_BATCHES' = 'Manufacturing: Batches',
  'MANUFACTURING_BATCHES_ALL' = 'Manufacturing: Batches all',
  'MANUFACTURING_BATCHES_ACTIVE_VESSELS' = 'Manufacturing: Batches active-vessels',
  'MANUFACTURING_BATCHES_ACTIVE_VESSELS_VIEW' = 'Manufacturing: Batches active-vessels view',
  'MANUFACTURING_BATCHES_FLOW' = 'Manufacturing: Batches flow',
  'MANUFACTURING_BATCHES_FLOW_VIEW' = 'Manufacturing: Batches flow view',
  'MANUFACTURING_BATCHES_FLOW_VIEW_MANUAL' = 'Manufacturing: Batches flow manual',
  'MANUFACTURING_BATCHES_FLOW_VIEW_SIMPLIFIED' = 'Manufacturing: Batches flow simplified',
  'MANUFACTURING_BATCHES_FLOW_VIEW_REGULAR' = 'Manufacturing: Batches flow regular',
  'MANUFACTURING_BATCHES_HISTORY' = 'Manufacturing: Batches history',
  'MANUFACTURING_BATCHES_HISTORY_VIEW' = 'Manufacturing: Batches history view',
  'MANUFACTURING_BATCHES_START' = 'Manufacturing: Batches start',
  'MANUFACTURING_BATCHES_START_FLOW' = 'Manufacturing: Batches start flow',
  'MANUFACTURING_BATCHES_START_BATCH' = 'Manufacturing: Batches start batch',
  'MANUFACTURING_BATCHES_START_BATCH_MANUAL' = 'Manufacturing: Batches start batch manual',
  'MANUFACTURING_BATCHES_START_SELECT_PROCESS' = 'Manufacturing: Batches start select process',
  'MANUFACTURING_DASHBOARDS' = 'Manufacturing: Dashboards',
  'MANUFACTURING_EQUIPMENT' = 'Manufacturing: Equipment',
  'MANUFACTURING_EQUIPMENT_MANAGEMENT' = 'Manufacturing: Equipment management',
  'MANUFACTURING_EQUIPMENT_MANAGEMENT_EDIT' = 'Manufacturing: Equipment management edit',
  'MANUFACTURING_EQUIPMENT_MANAGEMENT_NEW' = 'Manufacturing: Equipment management new',
  'MANUFACTURING_EQUIPMENT_MANAGEMENT_VIEW' = 'Manufacturing: Equipment management view',
  'MANUFACTURING_EQUIPMENT_RETIRED_LIST' = 'Manufacturing: Equipment retired',
  'MANUFACTURING_EQUIPMENT_RETIRED_VIEW' = 'Manufacturing: Equipment retired view',
  'MANUFACTURING_HISTORY_LOG' = 'Manufacturing: History log',
  'MANUFACTURING_PACKAGING_LINES' = 'Manufacturing: Packaging lines',
  'MANUFACTURING_PACKAGING_RUNS' = 'Manufacturing: Packaging runs',
  'MANUFACTURING_PRODUCT_FLOWS' = 'Manufacturing: Product flows',
  'MANUFACTURING_PRODUCT_FLOWS_NEW' = 'Manufacturing: Product flows new',
  'MANUFACTURING_PRODUCT_FLOWS_EDIT' = 'Manufacturing: Product flows edit',
  'MANUFACTURING_REPORTS' = 'Manufacturing: Reports',
  'MANUFACTURING_REPORTS_TTB' = 'Manufacturing: Reports TTB',
  'MANUFACTURING_REPORTS_TTB_VIEW' = 'Manufacturing: Reports TTB view',
  'MANUFACTURING_REPORTS_TTB_VIEW_DETAILS' = 'Manufacturing: Reports TTB view details',
  'MANUFACTURING_REPORTS_EXCISE_TAX' = 'Manufacturing: Reports Excise Tax',
  'MANUFACTURING_REPORTS_EXCISE_TAX_VIEW' = 'Manufacturing: Reports Excise Tax view',
  'MANUFACTURING_REPORTS_EXCISE_TAX_VIEW_DETAILS' = 'Manufacturing: Reports Excise Tax view details',
  // Manufacturing Events: Batches
  'MANUFACTURING_MANUAL_BATCH_ADDED' = 'Manufacturing: Manual batch added',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_ADDED' = 'Manufacturing: Batch from product flow added',
  'MANUFACTURING_SIMPLIFIED_BATCH_ADDED' = 'Manufacturing: Simplified batch added',
  'MANUFACTURING_BATCH_UPDATED' = 'Manufacturing: Batch - changes saved',
  'MANUFACTURING_BATCH_DELETED' = 'Manufacturing: Batch deleted',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_BRANCH_FINISHED' = 'Manufacturing: Batch from product flow - branch finished',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_PROCESS_UPDATED' = 'Manufacturing: Batch from product flow - process updated',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_PROCESS_STARTED' = 'Manufacturing: Batch from product flow - process started',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_PROCESS_END' = 'Manufacturing: Batch from product flow - process end',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_STARTED' = 'Manufacturing: Batch from product flow - started',
  'MANUFACTURING_MANUAL_BATCH_PROCESS_STARTED' = 'Manufacturing: Manual batch - process started',
  'MANUFACTURING_MANUAL_BATCH_PROCESS_END' = 'Manufacturing: Manual batch - process end',
  'MANUFACTURING_BATCH_FROM_PRODUCT_FLOW_FINISHED' = 'Manufacturing: Batch from product flow finished',
  'MANUFACTURING_MANUAL_BATCH_PROCESS_ADDED' = 'Manufacturing: Manual batch - process added',
  'MANUFACTURING_MANUAL_BATCH_PROCESS_UPDATED' = 'Manufacturing: Manual batch - process updated',
  'MANUFACTURING_MANUAL_BATCH_PROCESS_DELETED' = 'Manufacturing: Manual batch - process deleted',
  'MANUFACTURING_MANUAL_BATCH_STARTED' = 'Manufacturing: Manual batch - started',
  'MANUFACTURING_MANUAL_BATCH_BRANCH_SAVED' = 'Manufacturing: Manual batch - branch saved',
  'MANUFACTURING_MANUAL_BATCH_SAVED_AS_PRODUCT_FLOW' = 'Manufacturing: Manual batch saved as product flow',
  'MANUFACTURING_MANUAL_BATCH_FINISHED' = 'Manufacturing: Manual batch finished',
  // Manufacturing Events: Equipment
  'MANUFACTURING_EQUIPMENT_ADDED' = 'Manufacturing: Equipment added',
  'MANUFACTURING_EQUIPMENT_ADD_CANCELED' = 'Manufacturing: Equipment add - canceled',
  'MANUFACTURING_EQUIPMENT_UPDATED' = 'Manufacturing: Equipment - changes saved',
  'MANUFACTURING_EQUIPMENT_EDIT_CANCELED' = 'Manufacturing: Equipment edit - canceled',
  'MANUFACTURING_EQUIPMENT_OUT_OF_ORDER' = 'Manufacturing: Equipment set as out of order',
  'MANUFACTURING_EQUIPMENT_REACTIVATED' = 'Manufacturing: Equipment reactivated',
  'MANUFACTURING_EQUIPMENT_DELETED' = 'Manufacturing: Equipment deleted',
  'MANUFACTURING_EQUIPMENT_RETIRED' = 'Manufacturing: Equipment retired',
  'MANUFACTURING_EQUIPMENT_UNRETIRED' = 'Manufacturing: Equipment unretired',
  // Manufacturing Events: Product flow
  'MANUFACTURING_PRODUCT_FLOW_ADDED' = 'Manufacturing: Product flow added',
  'MANUFACTURING_PRODUCT_FLOW_ADD_CANCELED' = 'Manufacturing: Product flow add - canceled',
  'MANUFACTURING_PRODUCT_FLOW_UPDATED' = 'Manufacturing: Product flow - changes saved',
  'MANUFACTURING_PRODUCT_FLOW_PUBLISHED' = 'Manufacturing: Product flow published',
  'MANUFACTURING_PRODUCT_FLOW_DELETED' = 'Manufacturing: Product flow deleted',
  'MANUFACTURING_PRODUCT_FLOW_ARCHIVED' = 'Manufacturing: Product flow archived',
  // Manufacturing Events: Reports
  'MANUFACTURING_REPORTS_TTB_SEND_TO_TTB' = 'Manufacturing: TTB Report - send to TTB',
  'MANUFACTURING_REPORTS_TTB_SEND_TO_PAYGOV' = 'Manufacturing: TTB Report - send to pay.gov',
  'MANUFACTURING_REPORTS_TTB_FINALIZED' = 'Manufacturing: TTB Report finalized',
  // Purchasing Routing
  'PURCHASING_MODULE' = 'Purchasing: Module',
  'PURCHASING_DASHBOARDS' = 'Purchasing: Dashboards',
  'PURCHASING_REQUISITIONS' = 'Purchasing: Requisitions',
  'PURCHASING_ORDERS' = 'Purchasing: Orders',
  'PURCHASING_ORDERS_ACTIVE_LIST' = 'Purchasing: Orders list',
  'PURCHASING_ORDERS_ACTIVE_ITEM_EDIT' = 'Purchasing: Orders active item edit',
  'PURCHASING_ORDERS_ACTIVE_ITEM_NEW' = 'Purchasing: Orders active item new',
  'PURCHASING_ORDERS_ACTIVE_ITEM_VIEW' = 'Purchasing: Orders active item view',
  'PURCHASING_ORDERS_CLOSED_ITEM_VIEW' = 'Purchasing: Orders closed item',
  'PURCHASING_ORDERS_CLOSED_LIST' = 'Purchasing: Orders closed list',
  'PURCHASING_ORDERS_RETURNS_ITEM' = 'Purchasing: Orders returns item',
  'PURCHASING_ORDERS_RETURNS_LIST' = 'Purchasing: Orders returns list',
  'PURCHASING_REPORTS' = 'Purchasing: Reports',
  'PURCHASING_HISTORY_LOG' = 'Purchasing: History log',
  // Purchasing Events: Orders
  'PURCHASING_ORDERS_ADDED' = 'Purchasing: Purchase order added',
  'PURCHASING_ORDERS_ADD_CANCELED' = 'Purchasing: Purchase order add - canceled',
  'PURCHASING_ORDERS_UPDATED' = 'Purchasing: Purchase order edit - changes saved',
  'PURCHASING_ORDERS_EDIT_CANCELED' = 'Purchasing: Purchase order edit - canceled',
  'PURCHASING_ORDERS_VOIDED' = 'Purchasing: Purchase order voided',
  'PURCHASING_ORDERS_DELETED' = 'Purchasing: Purchase order deleted',
  'PURCHASING_ORDERS_CLOSED' = 'Purchasing: Purchase order closed',
  'PURCHASING_ORDERS_BACK_ORDER_ADDED' = 'Purchasing: Purchase order - back order added',
  'PURCHASING_ORDERS_BACK_ORDER_UPDATED' = 'Purchasing: Purchase order - back order updated',
  'PURCHASING_ORDERS_BACK_ORDER_CANCELED' = 'Purchasing: Purchase order - back order canceled',
  'PURCHASING_ORDERS_PAYMENT_UPDATED' = 'Purchasing: Purchase order - payment updated',
  'PURCHASING_ORDERS_PAYMENT_DELETED' = 'Purchasing: Purchase order - payment deleted',
  'PURCHASING_ORDERS_BILL_ADDED' = 'Purchasing: Purchase order - bill added',
  'PURCHASING_ORDERS_BILL_UPDATED' = 'Purchasing: Purchase order - bill updated',
  'PURCHASING_ORDERS_BILL_CANCELED' = 'Purchasing: Purchase order - bill canceled',
  'PURCHASING_ORDERS_ITEMS_RECEIVED' = 'Purchasing: Purchase order - items received',
  'PURCHASING_ORDERS_RECEIVED_ITEMS_UPDATED' = 'Purchasing: Purchase order - received items updated',
  'PURCHASING_ORDERS_RECEIVED_ITEMS_CANCELED' = 'Purchasing: Purchase order - received items canceled',
  'PURCHASING_ORDERS_REFUND_ADDED' = 'Purchasing: Purchase order - refund added',
  'PURCHASING_ORDERS_REFUND_UPDATED' = 'Purchasing: Purchase order - refund updated',
  'PURCHASING_ORDERS_REFUND_CANCELED' = 'Purchasing: Purchase order - refund canceled',
  'PURCHASING_ORDERS_RETURN_ADDED' = 'Purchasing: Purchase order - return added',
  'PURCHASING_ORDERS_RETURN_UPDATED' = 'Purchasing: Purchase order - return updated',
  'PURCHASING_ORDERS_RETURN_CANCELED' = 'Purchasing: Purchase order - return canceled',
  // 'REPORTING_MODULE' = 'Reporting: Module', // ToDo Module doesn't exist yet
  // Sales Routing
  'SALES_MODULE' = 'Sales: Module',
  'SALES_DASHBOARDS' = 'Sales: Dashboards',
  'SALES_ORDERS' = 'Sales: Orders',
  'SALES_ORDERS_ACTIVE_LIST' = 'Sales: Orders list',
  'SALES_ORDERS_ACTIVE_ITEM_EDIT' = 'Sales: Orders active item edit',
  'SALES_ORDERS_ACTIVE_ITEM_NEW' = 'Sales: Orders active item new',
  'SALES_ORDERS_ACTIVE_ITEM_VIEW' = 'Sales: Orders active item view',
  'SALES_ORDERS_CLOSED_ITEM_EDIT' = 'Sales: Orders closed item edit',
  'SALES_ORDERS_CLOSED_ITEM_VIEW' = 'Sales: Orders closed item view',
  'SALES_ORDERS_CLOSED_LIST' = 'Sales: Orders closed list',
  'SALES_ORDERS_RETURNS_ITEM' = 'Sales: Orders returns item',
  'SALES_ORDERS_RETURNS_LIST' = 'Sales: Orders returns list',
  'SALES_INVOICES' = 'Sales: Invoices',
  'SALES_REPORTS' = 'Sales: Reports',
  'SALES_HISTORY_LOG' = 'Sales: History log',
  'SALES_HISTORY_LOG_LIST' = 'Sales: History log list',
  'SALES_HISTORY_LOG_ITEM' = 'Sales: History log item',
  // Sales Events: Orders
  'SALES_ORDERS_ADDED' = 'Sales: Sales order added',
  'SALES_ORDERS_ADD_CANCELED' = 'Sales: Sales order add - canceled',
  'SALES_ORDERS_UPDATED' = 'Sales: Sales order edit - changes saved',
  'SALES_ORDERS_EDIT_CANCELED' = 'Sales: Sales order edit - canceled',
  'SALES_ORDERS_VOIDED' = 'Sales: Sales order voided',
  'SALES_ORDERS_DELETED' = 'Sales: Sales order deleted',
  'SALES_ORDERS_SHIPPING_ADDED' = 'Sales: Sales order - shipping added',
  'SALES_ORDERS_SHIPPING_UPDATED' = 'Sales: Sales order - shipping updated',
  'SALES_ORDERS_SHIPPING_DELETED' = 'Sales: Sales order - shipping deleted',
  'SALES_ORDERS_BILL_OF_LADING_GENERATED' = 'Sales: Sales order - bill of lading generated',
  'SALES_ORDERS_PICKING_ADDED' = 'Sales: Sales order - picking added',
  'SALES_ORDERS_PICKING_UPDATED' = 'Sales: Sales order - picking updated',
  'SALES_ORDERS_PICKING_DELETED' = 'Sales: Sales order - picking deleted',
  'SALES_INVOICE_ADDED' = 'Sales: Sales order -invoice added',
  'SALES_INVOICE_ADD_CANCELED' = 'Sales: Invoice add - canceled',
  'SALES_INVOICE_UPDATED' = 'Sales: Invoice edit - changes saved',
  'SALES_INVOICE_EDIT_CANCELED' = 'Sales: Invoice edit - canceled',
  'SALES_INVOICE_DELETED' = 'Sales: Invoice deleted',
  'SALES_INVOICE_PAYMENT_ADDED' = 'Sales: Invoice - payment added',
  'SALES_INVOICE_PAYMENT_UPDATED' = 'Sales: Invoice - payment updated',
  'SALES_INVOICE_PAYMENT_DELETED' = 'Sales: Invoice - payment deleted',
  'SALES_INVOICE_REFUND_ADDED' = 'Sales: Invoice - refund added',
  'SALES_INVOICE_REFUND_DELETED' = 'Sales: Invoice - refund deleted',
  // Sign in/out Routing
  'SIGN_IN' = 'Sign in',
  'SIGN_OUT' = 'Sign out',
  // Warehousing Routing
  'WAREHOUSING_MODULE' = 'Warehousing: Module',
  'WAREHOUSING_DASHBOARDS' = 'Warehousing: Dashboards',
  'WAREHOUSING_WAREHOUSES' = 'Warehousing: Warehouses',
  'WAREHOUSING_WAREHOUSES_BUILDER_EDIT' = 'Warehousing: Warehouses builder edit',
  'WAREHOUSING_WAREHOUSES_BUILDER_NEW' = 'Warehousing: Warehouses builder new',
  'WAREHOUSING_WAREHOUSES_BUILDER_VIEW' = 'Warehousing: Warehouses builder view',
  'WAREHOUSING_WAREHOUSES_LIST' = 'Warehousing: Warehouses list',
  'WAREHOUSING_WAREHOUSES_ITEM_EDIT' = 'Warehousing: Warehouses item edit',
  'WAREHOUSING_WAREHOUSES_ITEM_NEW' = 'Warehousing: Warehouses item new',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW' = 'Warehousing: Warehouses item view',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW_CONTAINERS' = 'Warehousing: Warehouses item view containers',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW_FINISHED_GOODS' = 'Warehousing: Warehouses item view finished goods',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW_PACKAGING_MATERIALS' = 'Warehousing: Warehouses item view packaging materials',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW_RAW_MATERIAL' = 'Warehousing: Warehouses item view raw material',
  'WAREHOUSING_WAREHOUSES_ITEM_VIEW_SEMI_FINISHED_GOODS' = 'Warehousing: Warehouses item view semi finished goods',
  'WAREHOUSING_ORDER_PICKING' = 'Warehousing: Order picking',
  'WAREHOUSING_TRANSFER_IN_BOND' = 'Warehousing: Transfer in bond',
  'WAREHOUSING_TRANSFER_IN_BOND_ACTIVE_ITEM_EDIT' = 'Warehousing: Transfer in bond active item edit',
  'WAREHOUSING_TRANSFER_IN_BOND_ACTIVE_ITEM_NEW' = 'Warehousing: Transfer in bond active item new',
  'WAREHOUSING_TRANSFER_IN_BOND_ACTIVE_ITEM_VIEW' = 'Warehousing: Transfer in bond active item view',
  'WAREHOUSING_TRANSFER_IN_BOND_ACTIVE_LIST' = 'Warehousing: Transfer in bond active list',
  'WAREHOUSING_TRANSFER_IN_BOND_CLOSED_LIST' = 'Warehousing: Transfer in bond closed list',
  'WAREHOUSING_TRANSFER_IN_BOND_CLOSED_ITEM_VIEW' = 'Warehousing: Transfer in bond closed item view',
  'WAREHOUSING_REMNANT_CASES' = 'Warehousing: Remnant cases',
  'WAREHOUSING_REPORTS' = 'Warehousing: Reports',
  'WAREHOUSING_HISTORY_LOG' = 'Warehousing: History log',
// Warehousing Events: Warehouses
  'WAREHOUSING_WAREHOUSE_ADDED' = 'Warehousing: Warehouse added',
  'WAREHOUSING_WAREHOUSE_ADD_CANCELED' = 'Warehousing: Warehouse add - canceled',
  'WAREHOUSING_WAREHOUSE_UPDATED' = 'Warehousing: Warehouse - changes saved',
  'WAREHOUSING_WAREHOUSE_EDIT_CANCELED' = 'Warehousing: Warehouse edit - canceled',
  'WAREHOUSING_WAREHOUSES_ITEMS_TRANSFERRED' = 'Warehousing: Warehouses - Items transferred',
  'WAREHOUSING_WAREHOUSES_LOTS_TRANSFERRED' = 'Warehousing: Warehouses - Lots transferred',
  'WAREHOUSING_WAREHOUSES_ITEMS_REPACKAGED' = 'Warehousing: Warehouses - Items repackaged',
// Warehousing Events: Transfers in bond
  'WAREHOUSING_TRANSFER_IN_BOND_ADDED' = 'Warehousing: Transfer in bond added',
  'WAREHOUSING_TRANSFER_IN_BOND_ADD_CANCELED' = 'Warehousing: Transfer in bond add - canceled',
  'WAREHOUSING_TRANSFER_IN_BOND_UPDATED' = 'Warehousing: Transfer in bond - changes saved',
  'WAREHOUSING_TRANSFER_IN_BOND_EDIT_CANCELED' = 'Warehousing: Transfer in bond edit - canceled',
  'WAREHOUSING_TRANSFER_IN_BOND_DELETED' = 'Warehousing: Transfer in bond deleted',
  'WAREHOUSING_TRANSFER_IN_BOND_ITEMS_RECEIVED' = 'Warehousing: Transfer in bond - items received',

  'WELCOME_PAGE' = 'Welcome page',
}
