import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-new-template',
  templateUrl: './modal-new-template.component.html'
})
export class ModalNewTemplateComponent {
  @Input() title: string;
  @Input() popupType: string;

  @Output() savePressed = new EventEmitter();

  newTemplateName = '';

  constructor() {
  }

  onSaveClick(name: string) {
    this.newTemplateName = '';
    this.savePressed.emit(name);
  }
}
