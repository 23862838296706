import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DropdownOption, UtilityBarOptionsModel } from '../../models';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html'
})
export class ActionBarComponent {
  @Output() clickedIcon: EventEmitter<any> = new EventEmitter<any>();
  @Input() options: UtilityBarOptionsModel[];
  @HostBinding('style.width') private width = '100%';

  constructor() {
  }

  getClickedIcon(icon: DropdownOption) {
    this.clickedIcon.emit(icon);
  }
}
