import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {
  MasterItemLocationPipe,
  MasterItemPricePipe,
  MasterItemStatusPipe,
  MasterItemTypeNumberPipe,
  NumberOfPricesPipe,
  PricePipe,
  PricingRuleTypePipe
} from './pipes';

@NgModule({
  declarations: [
    NumberOfPricesPipe,
    PricingRuleTypePipe,
    MasterItemStatusPipe,
    PricePipe,
    MasterItemPricePipe,
    MasterItemTypeNumberPipe,
    MasterItemLocationPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxSmartModalModule.forRoot(),
  ],
  exports: [
    NumberOfPricesPipe,
    PricingRuleTypePipe,
    PricePipe,
    MasterItemStatusPipe,
    MasterItemPricePipe,
    MasterItemTypeNumberPipe,
    MasterItemLocationPipe,
  ]
})
export class SharedInventoryModule {
}
