import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masterItemType'
})
export class MasterItemTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'FinishedGood':
        return 'Finished Good';
      case 'RawMaterial':
        return 'Raw Material';
      case 'SemiFinishedGood':
        return 'Semi-Finished Good';
      case 'PackagingMaterial':
        return 'Packaging Material';
      case 'Container':
        return 'Container';
      default:
        return '';
    }
  }
}
