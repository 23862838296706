/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../shared/components/svg-icon/svg-icon.component";
import * as i5 from "../shared/directives/click-outside.directive";
import * as i6 from "./sidebar.component";
import * as i7 from "@ngx-pwa/local-storage";
import * as i8 from "../shared/services/sidebar.service";
import * as i9 from "../core/services/subscription.service";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "sidebar-sub__menu-link"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeSidebar() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.text; _ck(_v, 5, 0, currVal_4); }); }
function View_SidebarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "sidebar-sub__menu-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionService.getAccess(_v.context.$implicit.feature); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "sidebar-sub"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn--close sidebar-sub__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "#plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "sidebar-sub__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "ul", [["class", "sidebar-sub__menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i0.ɵdid(8, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.sublinks; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 5, 0, currVal_0); }); }
function View_SidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "li", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "span", [["class", "sidebar--icon-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSidebar(_v.context.$implicit.title) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "sidebar--blocker"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "a", [["class", "sidebar__link"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(5, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(8, 0, null, null, 2, "i", [["class", "sidebar__gfx"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i0.ɵdid(10, 114688, null, 0, i4.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "sidebar__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 5, 0, currVal_4); var currVal_5 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); _ck(_v, 10, 0, currVal_5); var currVal_7 = (_co.currentItem === _v.context.$implicit.title); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "sidebar__item ", ((_co.currentItem === _v.context.$implicit.title) ? "active" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).target; var currVal_2 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_6 = _v.context.$implicit.title; _ck(_v, 12, 0, currVal_6); }); }
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "sidebar__item-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sidebarItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "aside", [["click-outside", ""]], [[8, "className", 0]], [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.onClickOutside($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i5.ClickOutsideDirective, [i0.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.render; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "sidebar ", (_co.sidebarOpen ? "open" : ""), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 245760, null, 0, i6.SidebarComponent, [i7.LocalStorage, i8.SidebarService, i9.SubscriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i6.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
