import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html'
})
export class GlobalModalComponent {
  selectedValue: any;
  error = false;

  @Input() title: string;
  @Input() label: string;
  @Input() cancelLabel = 'Cancel';
  @Input() saveLabel = 'Save';
  @Input() placeholder = 'Please choose';
  @Input() options: any[];

  @Output() savePressed = new EventEmitter();

  constructor() {
  }

  onSaveClick(name: string) {
    this.error = false;
    name ? this.savePressed.emit(name) : (this.error = true);
    this.selectedValue = undefined;
  }
}
