import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, UrlTree} from '@angular/router';
import {AllowSaveService} from '../services';
import {Observable} from 'rxjs';
import {IAllowSave} from '../interfaces/allow-save.interface';

@Injectable({
  providedIn: 'root',
})
export class NotSavedGuard implements CanDeactivate<IAllowSave> {
  constructor(private allowSaveService: AllowSaveService) {
  }

  canDeactivate(component: IAllowSave,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.allowSaveService.isEnabledFeature) {
      return component.canDeactivate ? component.canDeactivate(nextState) : true;
    }

    return true;
  }
}
