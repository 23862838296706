import { Component, EventEmitter, Input, Output } from '@angular/core';
import { pricePrefix } from '@onbatch/shared/constants';
import { SalesOrderItemsRequestModel, SalesOrderResponseModel } from '@onbatch/core/services/Sales';

@Component({
  selector: 'app-sales-items',
  templateUrl: './sales-items.component.html'
})
export class SalesItemsComponent {

  @Input() summary: SalesOrderResponseModel;
  @Input() payload: SalesOrderItemsRequestModel;
  @Input() formSubmitted = false;
  @Input() readonly = false;
  @Input() showProofGallons = true;

  @Output() formChanged = new EventEmitter<SalesOrderItemsRequestModel>();
  @Output() validationChanged = new EventEmitter<boolean>();

  readonly pricePrefix = pricePrefix;
}
