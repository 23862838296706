import { AfterContentChecked, HostBinding, OnDestroy } from '@angular/core';
import { FileType2, FilterTypeId2, PageInfo } from '../../core/services/Inventory';
import { SortSettingsModel } from '../models';
import { TransfersDataTable } from '../../warehousing/transfers/transfers-interfaces';
import { Subscription } from 'rxjs';
import { FiltersService } from '../../filters/filters.service';
import { Features2, FilterRequestApplyModel } from '../../core/services/Account';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';

  // Helpers
  export function changeTableHeight(): void {
    const pagination: HTMLElement = document.getElementsByClassName('pagination')[0] as HTMLElement;
    const tableBody: HTMLElement = document.getElementsByClassName('table__body')[0] as HTMLElement;
    if (tableBody && pagination) {
      const tableBodyPosition = tableBody.getBoundingClientRect();
      const paginationPosition = pagination.getBoundingClientRect();
      tableBody.style.maxHeight = `calc(100vh - (${tableBodyPosition.top}px + ${paginationPosition.bottom - paginationPosition.top}px))`;
    } else if (tableBody) {
      const tableBodyPosition = tableBody.getBoundingClientRect();
      tableBody.style.maxHeight = `calc(100vh - ${tableBodyPosition.top}px))`;
    }
  }

export abstract class BaseList implements AfterContentChecked, OnDestroy {

  pagination: PageInfo;

  itemID: string;
  settingsPanel = false;

  activeFilters: any[] = [];
  filtersOpened = false;
  showFilters = false;
  filterType = FilterTypeId2;
  resetFilters = true;
  filtersApplied = false;
  resetFiltersBar = false;
  isFiltersLoaded = false;
  sortSettings: SortSettingsModel;
  fileTypes: typeof FileType2 = FileType2;
  listLoaded = false;
  dataTableSettings: TransfersDataTable;

  addSubscription: Subscription;
  dataTableSubscription: Subscription;
  listSubscription: Subscription;
  paginationSubscription: Subscription;
  singleItemSubscription: Subscription;

  isFeatureAvailable = new Features2({
    misc_AdvancedFiltering: this.subscriptionService.getAccess(SubscriptionFeatures.MiscAdvancedFiltering),
    misc_ExportCSVExcel: this.subscriptionService.getAccess(SubscriptionFeatures.MiscExportCSVExcel),
  });

  @HostBinding('style.width') private width = '100%';

  protected constructor(public filtersService: FiltersService, public subscriptionService: SubscriptionService) {
  }

  abstract setSort(params: any);
  abstract setSettings();
  abstract setFilters();
  abstract setParams();

  ngAfterContentChecked() {
    changeTableHeight();
  }

  ngOnDestroy() {
    if (this.listSubscription) {
      this.listSubscription.unsubscribe();
    }
    if (this.paginationSubscription) {
      this.paginationSubscription.unsubscribe();
    }
    if (this.dataTableSubscription) {
      this.dataTableSubscription.unsubscribe();
    }
    if (this.singleItemSubscription) {
      this.singleItemSubscription.unsubscribe();
    }
  }

  changeDisplay(val: string): void {
    this.dataTableSettings.settings[val].status = !this.dataTableSettings.settings[val].status;
    this.setSettings();
  }

  afterFiltersApplied(activeFilters: FilterRequestApplyModel[], resetPage: boolean = true): void {
    this.activeFilters = activeFilters;
    if (resetPage) {
      this.dataTableSettings.params.x_pageNumber = 1;
      this.resetFilters = false;
      setTimeout(() => (this.resetFilters = true), 0);
    }
    this.setFilters();
    changeTableHeight();
  }

  setColumnToSort(columnName: string, desc?: boolean): void {
    const { params } = this.dataTableSettings;
    if (columnName === params.x_order) {
      params.x_desc = !params.x_desc;
    } else {
      params.x_desc = false;
      params.x_order = columnName;
    }
    if (desc !== undefined) {
      params.x_desc = desc;
    }
    this.setSort(params);
  }

  openSettings(event: { value: boolean }): void {
    if (!event.value) {
      this.settingsPanel = true;
    }
  }

  toggleSettings(): void {
    this.settingsPanel = !this.settingsPanel;
  }


  changePagination(pager: PageInfo): void {
    this.dataTableSettings.params.x_pageSize = pager.pageSize;
    this.dataTableSettings.params.x_pageNumber = pager.pageNumber;
    this.setParams();
  }

  // Filters:
  toggleFilters(): void {
    this.filtersService.setFiltersAsOpened(true);
    if (!this.filtersOpened) {
      this.showFilters = false;
    }
  }
}
