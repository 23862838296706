<app-loader *ngIf="spinnerService.getGlobalSpinner() | async"></app-loader>
<router-outlet (activate)="onActivate()" *ngIf="loaded"></router-outlet>
<div class="confirm-modal-wrapper" [class.isOpen]="isModalVisible">
  <app-confirm-modal
    (cancelButtonClicked)="cancelConfirmModal()"
    (confirmButtonClicked)="confirmConfirmModal()"
    [dismissable]="false"
    [identifier]="modalId"
    [customBody]="false"
    [text]="modalConfiguration?.text"
    [title]="modalConfiguration?.title"
    [cancelButtonLabel]="modalConfiguration?.cancelButtonLabel"
    [confirmButtonLabel]="modalConfiguration?.confirmButtonLabel"
  ></app-confirm-modal>
</div>
