import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { Features2 } from '@onbatch/core/services/Account';

@Injectable()
export class RoleGuard implements CanActivate {
  userPermissions: any;

  constructor(private authService: AuthService,
              private toastr: ToastrService,
              private subscriptionService: SubscriptionService,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;
    const isFeatureAvailable = new Features2({
      misc_RolesPermissions: this.subscriptionService.getAccess(SubscriptionFeatures.MiscRolesPermissions)
    });
    if (!isFeatureAvailable.misc_RolesPermissions) {
      canActivate = true;
    } else {
      const user = this.authService.getCurrentUser();
      const company = this.authService.getOrgFromStorage();

      this.userPermissions = JSON.parse(user.profile.TenantRoles);
      if (this.userPermissions && this.userPermissions[company][next.data.module]) {
        this.userPermissions[company][next.data.module].map((permission: string) => {
          if (permission === next.data.permissions) {
            canActivate = true;
          }
        });
      } else {
        canActivate = false;
      }
      if (!canActivate) {
        this.toastr.warning(`You don't have permission to access.`, 'Access denied');
      }
    }
    return canActivate;

  }
}
