import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { CustomerAddressResponseModel } from '@onbatch/core/services/Sales';

@Component({
  selector: 'app-customer-addresses',
  templateUrl: './customer-addresses.component.html',
})
export class CustomerAddressesComponent {
  @Input() addresses: CustomerAddressResponseModel[];
  @Input() isRequired = false;
  @Input() formSubmitted = false;
  @Input() label: string;
  @Input() readonly = false;
  @Input() selectedAddress: CustomerAddressResponseModel;
  @Input() title: string;

  @Output() addressChanged = new EventEmitter<string>();
  @Output() newAddressClicked = new EventEmitter<null>();

  showModal = false;

  toggleChangeAddressModal(): void {
    this.showModal = !this.showModal;
  }

  closeChangeAddressModal(event: ClickOutsideData): void {
    if (event.value) {
      this.showModal = false;
    }
  }

  setAddress(externalId: string): void {
    this.addressChanged.emit(externalId);
    this.showModal = false;
  }
}
