import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { ClickOutsideData } from '../../../../../directives';
import { SalesOrdersService } from '../../../../../../sales/orders/orders.service';
import {
  PaginatedResponseOfSalesEquipmentLookupResponseModel,
  SalesEquipmentLookupResponseModel
} from '../../../../../../core/services/Sales';
import { LookupProps } from '../../../../../models';
import { defaultParamsForAll } from '../../../../create-item/create-item-statics';

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEquipmentComponent implements OnInit, OnChanges {
  @Input() itemExternalId: string;
  @Input() refreshEquipment: boolean;
  @Input() addedEquipment: string[] = [];
  @Output() itemSelected = new EventEmitter<SalesEquipmentLookupResponseModel>();

  equipment: SalesEquipmentLookupResponseModel[];
  showModal = false;
  params: LookupProps = defaultParamsForAll;

  constructor(private salesOrdersService: SalesOrdersService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fetchEquipment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshEquipment) {
      this.fetchEquipment();
    }
  }

  fetchEquipment(): void {
    this.salesOrdersService.lookupEquipment(
      this.itemExternalId,
      this.addedEquipment,
      this.params.x_query,
      this.params.x_order,
      this.params.x_desc,
      this.params.x_pageNumber,
      this.params.x_pageSize).subscribe((data: PaginatedResponseOfSalesEquipmentLookupResponseModel) => {
      this.equipment = data.list;
      this.cdr.markForCheck();
    });
  }

  onItemSelect(item: SalesEquipmentLookupResponseModel): void {
    this.itemSelected.emit(item);
    this.showModal = false;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
    this.cdr.markForCheck();
  }

  closeModal(event: ClickOutsideData): void {
    if (event.value) {
      this.showModal = false;
    }
  }

}
