import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';

import { checkIfDatePickerWasClicked, closeCalendar } from '@onbatch/shared/utils';
import {
  SalesOrderItemResponseModel,
  SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId,
  SalesOrderItemTransactionResponseModel,
  SalesOrderResponseModel,
  SalesOrderShippedItemRequestModel,
  SalesOrderShippedItemUpdateRequestModel,
  SellableMasterItemResponseModelMasterItemTypeId,
  ShipmentItemResponseModel,
  ShipmentResponseModel,
  ShippedItemRequestModel,
  ShipPickedItemsRequestModel,
  SalesOrderShippedItemUpdateRequestModelShipmentItemSelectionCriteriaId as ShipmentType
} from '@onbatch/core/services/Sales';
import { pricePrefix } from '@onbatch/shared/constants';
import { Shipping } from '../ship-order-modal/shipment-type.enum';
import { ShippingService } from '../../../services/shipping.service';
import { ClickOutsideData } from '@onbatch/shared/directives';
import { SubscriptionFeatures, SubscriptionService } from '@onbatch/core/services/subscription.service';
import { Features2 } from '@onbatch/core/services/Account';
import { TransferInBondResponseModel } from '@onbatch/core/services/Warehousing';
import { FinishedGoodsSelectItemsService } from '@onbatch/shared/services/finished-goods-select-items.service';
import { SpinnerService } from '@onbatch/core/services/spinner.service';
import { SemiFinishedGoodsSelectItemsService } from '@onbatch/shared/services/semi-finished-goods-select-items.service';

@Component({
  selector: 'app-ship-items-overlay',
  templateUrl: './ship-items-overlay.component.html'
})
export class ShipItemsOverlayComponent implements OnInit {

  @Input() opened: boolean;
  @Input() salesOrder: SalesOrderResponseModel;
  @Input() transfer: TransferInBondResponseModel;
  @Input() salesApproval = false;
  @Input() pickStrategy = false;
  @Input() shipmentToEdit: ShipmentResponseModel = null;
  @Input() isShippingEdition = false;

  @Output() closeOverlay = new EventEmitter();
  @Output() shippingConfirmed = new EventEmitter();

  form: FormArray = new FormArray([]);
  globalForm: FormGroup;

  remainingQuantity: RemainingQuantity[] = [];
  formSubmitStatus = false;
  closeDatepicker = closeCalendar;

  checkedItems: ItemState[] = [];
  checkedTransactions: string[] = [];

  requestInFlight = false;
  canConfirmShipping = false;

  showOverlay = false;

  isFeatureAvailable = new Features2({
    inventory_LotTraceability: this.subscriptionService.getAccess(SubscriptionFeatures.InventoryLotTraceability)
  });

  readonly pricePrefix = pricePrefix;
  readonly masterItemType = SellableMasterItemResponseModelMasterItemTypeId;
  readonly shipmentCriteria = SalesOrderItemTransactionDetailsResponseModelShipmentItemSelectionCriteriaId;
  currentItem: SalesOrderItemResponseModel;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private shippingService: ShippingService,
    private subscriptionService: SubscriptionService,
    private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService,
    private semiFinishedGoodsSelectItemsService: SemiFinishedGoodsSelectItemsService,
    public spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.salesOrder.items.salesOrderItems.forEach(
      (item: SalesOrderItemResponseModel) => this.finishedGoodsSelectItemsService.fetchShippingLotsFinishedGoodByMasterItemExternalIdGet(
        item.masterItem.externalId,
        item.masterItem.isRemnant,
        item.masterItem.numberOfUnits
      )
    );
  }

  doCloseOverlay(event: ClickOutsideData) {
    if (event) {
      const isSelect = event.target
        && (event.target as HTMLElement).className
        && (event.target as HTMLElement).className.includes
        && ((event.target as HTMLElement).className.includes('ng-option-marked') || (event.target as HTMLElement).className.includes('ng-option-label'));
      if (event.value && this.opened && !checkIfDatePickerWasClicked(event) && !isSelect && !this.showOverlay) {
        this.closeOverlayModal();
      }
    } else {
      this.closeOverlayModal();
    }
  }

  checkItem(item: SalesOrderItemResponseModel, idx: number, shipmentType?: ShipmentType, quantity?: number): void {
    if (!this.pickStrategy) {
      if (!this.isItemChecked(item.externalId)) {
        this.form.push(this.fb.group({
          externalId: new FormControl(item.externalId),
          numberOfUnits: new FormControl(item.masterItem.numberOfUnits),
          shippedDate: new FormControl(null),
          [this.getShipmentControlName(item)]: new FormControl(shipmentType || this.getDefaultShipmentType(item.masterItem.masterItemTypeId, item), Validators.required),
          unitIds: new FormArray([]),
          equipment: new FormArray([]),
          containerIds: new FormArray([]),
          lotIds: new FormArray([]),
          shippedLotQuantity: new FormControl(''),
          shippedVolume: new FormControl(quantity || ''),
          shippedQuantity: new FormControl(quantity || ''),
          items: new FormControl(null),
        }));
        this.remainingQuantity.push({
          quantity: this.getRemainingQuantity(item.externalId, idx),
          externalId: item.externalId
        });
        this.checkedItems.push({ externalId: item.externalId, isValid: false });
      } else {
        const formIdx: number = this.form.controls.findIndex((form: FormGroup) => form.controls['externalId'].value === item.externalId);
        const remainingQuantityIdx: number = this.remainingQuantity.findIndex(el => el.externalId === item.externalId);
        const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === item.externalId);

        this.form.removeAt(formIdx);
        this.remainingQuantity.splice(remainingQuantityIdx, 1);
        this.checkedItems.splice(checkedItemIdx, 1);
      }
    } else {
      this.multicheckTransactions(item);
    }
  }

  getDefaultShipmentType(type: SellableMasterItemResponseModelMasterItemTypeId, item?: SalesOrderItemResponseModel): ShipmentType {
    switch (type) {
      case SellableMasterItemResponseModelMasterItemTypeId.FinishedGood: {
        return ShipmentType.ByUnitIds;
      }
      case SellableMasterItemResponseModelMasterItemTypeId.SemiFinishedGood: {
        if (item && item.container && item.container.externalId) {
          return ShipmentType.ByItemBatchExternalId;
        }
        return ShipmentType.ByEquipmentId;
      }
      case SellableMasterItemResponseModelMasterItemTypeId.RawMaterial:
      case SellableMasterItemResponseModelMasterItemTypeId.PackagingMaterial: {
        if (!this.isFeatureAvailable.inventory_LotTraceability) {
          return ShipmentType.ByCount;
        } else {
          return ShipmentType.ByLotId;
        }
      }
      default: {
        return null;
      }
    }
  }

  initForm() {
    const date: number = this.shipmentToEdit ? this.shipmentToEdit.shippedDate : this.currentDate;
    this.globalForm = this.fb.group({
      shippedDate: new FormControl(date, Validators.required),
    });
    if (this.shipmentToEdit) {
      this.initShipmentToEdit();
    }
  }

  initShipmentToEdit() {
    this.shipmentToEdit.shipmentItems.forEach((item: ShipmentItemResponseModel, index: number) => {
      this.checkItem(new SalesOrderItemResponseModel({
        externalId: item.salesOrderItemExternalId,
        masterItem: item.masterItem
      }), index, ShipmentType[item.details[0].shipmentItemSelectionCriteriaId], item.totalQuantity);
    });
  }

  setDate(date: Moment) {
    this.shippedDate.patchValue(date.format('X'));
  }

  get currentDate(): number {
    return Math.floor(Date.now() / 1000);
  }

  getRemainingQuantity(externalId: string, idx: number): number {
    if (externalId) {
      const items: SalesOrderItemTransactionResponseModel[] =
        this.salesApproval
          ? this.salesOrder.items.salesOrderItems[idx].pickedItems
          : this.salesOrder.items.salesOrderItems[idx].shippedItems;

      return items.length
        ? items.reduce((quantity: number, item: SalesOrderItemTransactionResponseModel | ShippedItemRequestModel) => {
          return quantity - (item.quantity
            ? item.quantity
            : (item as ShippedItemRequestModel).volume);
        }, this.salesOrder.items.salesOrderItems[idx].quantity)
        : this.salesOrder.items.salesOrderItems[idx].quantity;
    }
  }

  getRemainingQuantityUpdated(externalId: string, idx: number): number {
    if (externalId) {
      const remainingQuantity: RemainingQuantity = this.remainingQuantity.find((el) => el.externalId === externalId);
      if (!!remainingQuantity && remainingQuantity.quantity >= 0) {
        return remainingQuantity.quantity;
      } else {
        return this.getRemainingQuantity(externalId, idx);
      }
    }
  }

  isItemChecked(externalId: string): boolean {
    return this.checkedItems.some(el => el.externalId === externalId);
  }

  isTransactionChecked(externalId: string): boolean {
    return this.checkedTransactions.includes(externalId);
  }

  checkTransaction(externalId: string): void {
    if (this.isTransactionChecked(externalId)) {
      this.checkedTransactions.splice(this.checkedTransactions.findIndex(el => el === externalId), 1);
    } else {
      this.checkedTransactions.push(externalId);
    }
  }

  multicheckTransactions(item: SalesOrderItemResponseModel): void {
    if (!this.isItemChecked(item.externalId)) {
      this.checkedItems.push({ externalId: item.externalId, isValid: true });
      if (item.pickedItems) {
        item.pickedItems.forEach(pickedItems => {
          if (!pickedItems.wasUsed) {
            if (!this.isTransactionChecked(pickedItems.transactionExternalId)) {
              this.checkedTransactions.push(pickedItems.transactionExternalId);
            }
          }
        });
      }
    } else {
      const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === item.externalId);
      this.checkedItems.splice(checkedItemIdx, 1);
      if (item.pickedItems) {
        item.pickedItems.forEach(pickedItems => {
          const pickedItemsIdx = this.checkedTransactions.findIndex(el => el === pickedItems.transactionExternalId);
          if (this.checkedTransactions.findIndex(el => el === pickedItems.transactionExternalId) >= 0) {
            this.checkedTransactions.splice(pickedItemsIdx, 1);
          }
        });
      }
    }
  }

  getRemainingPickedItems(item: SalesOrderItemResponseModel): boolean {
    return item.pickedItems.some(el => !el.wasUsed);
  }

  shippingChanged(data: Shipping, externalId: string, numberOfUnits: number = null, shouldSetShipping?: boolean): void {
    const remainingQuantityIdx = this.remainingQuantity.findIndex(el => el.externalId === externalId);
    const checkedItemIdx: number = this.checkedItems.findIndex(el => el.externalId === externalId);
    const formIdx: number = this.form.controls.findIndex((form: FormGroup) => {
      return form.controls['externalId'].value === externalId && form.controls['numberOfUnits'].value === numberOfUnits;
    });
    const shipmentType = this.getShipmentControl(this.form.controls[formIdx] as FormGroup, externalId, String(numberOfUnits));

    (this.form.controls[formIdx] as FormGroup).get('items').setValue(data.shipmentItems);
    if (!shipmentType.value) {
      shipmentType.setValue(data.shipmentItemSelectionCriteriaId);
    }
    this.remainingQuantity[remainingQuantityIdx].quantity = data.remainingQuantity;
    this.checkedItems[checkedItemIdx].isValid = data.isValid;
    this.canConfirmShipping = this.checkedItems.every(e => e.isValid) && this.globalForm.valid;
    if (shouldSetShipping) {
      const shippingExternalId: string = this.salesOrder.items.salesOrderItems.find(
        (item: SalesOrderItemResponseModel) => item.externalId === externalId).externalId;
      this.finishedGoodsSelectItemsService.setShipping(shippingExternalId, data);
    }
  }

  handleOverlayTrigger(event: boolean, item: SalesOrderItemResponseModel) {
    this.currentItem = item;
    this.showOverlay = event;
  }

  closeOverlayModal(): void {
    this.closeOverlay.emit();
    this.finishedGoodsSelectItemsService.clearAllManualSelectingState();
    this.semiFinishedGoodsSelectItemsService.clearAllManualSelectingState();
  }

  confirmShipping(): void {
    const onSuccess = () => {
      this.requestInFlight = false;
      this.closeOverlayModal();
      this.shippingConfirmed.emit();
      this.toastr.success('Success!',
        `${this.salesApproval
          ? `${this.pickStrategy ? 'Shipping' : 'Pick'}`
          : 'Shipping'} confirmed`);
    };

    if (!this.pickStrategy) {
      if (!this.shipmentToEdit) {
        const request: SalesOrderShippedItemRequestModel[] = this.form.controls.map((form: FormGroup) => {
          const itemId: string = form.get('externalId').value;
          const numberOfUnits: string = form.get('numberOfUnits').value;
          return new SalesOrderShippedItemRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            salesOrderItemExternalId: itemId,
            shipmentItemSelectionCriteriaId: this.getShipmentControl(form, itemId, numberOfUnits).value,
            shipmentItems: form.get('items').value
          });
        });
        this.requestInFlight = true;

        if (this.salesApproval) {
          this.shippingService.postPicking(this.salesOrder.externalId, request)
            .subscribe(onSuccess);
        } else {
          this.shippingService.postShipping(this.salesOrder.externalId, request)
            .subscribe(onSuccess);
        }
      } else {
        const editRequest: SalesOrderShippedItemUpdateRequestModel[] = this.form.controls.map((form: FormGroup) => {
          const itemId: string = form.get('externalId').value;
          const numberOfUnits: string = form.get('numberOfUnits').value;
          return new SalesOrderShippedItemUpdateRequestModel({
            shippedDate: Math.floor(this.shippedDate.value),
            salesOrderItemExternalId: itemId,
            shipmentItemSelectionCriteriaId: this.getShipmentControl(form, itemId, numberOfUnits).value,
            shipmentItems: form.get('items').value
          });
        });
        this.requestInFlight = true;

        if (this.salesApproval) {
          this.shippingService.putPicking(this.salesOrder.externalId, this.shipmentToEdit.externalId, editRequest)
            .subscribe(onSuccess);
        } else {
          this.shippingService.putShipping(this.salesOrder.externalId, this.shipmentToEdit.externalId, editRequest)
            .subscribe(onSuccess);
        }
      }
    } else {
      const pickedRequest = new ShipPickedItemsRequestModel({
        dateTime: Math.floor(this.shippedDate.value),
        transactionExternalIds: this.checkedTransactions
      });
      this.shippingService.putShipPickedItems(this.salesOrder.externalId, pickedRequest)
        .subscribe(onSuccess);
    }

  }

  toggleOverlay() {
    /** TODO
     * Workaround for wrong code execution order
     * This function should be related with clickOutside.
     * Click events emitted from manual selection overlay triggers clickOutside directive,
     * to avoid closing modal checking showOverlay property is required.
     */
    setTimeout(() => {
      this.showOverlay = !this.showOverlay;
      if (this.currentItem) {
        this.currentItem = null;
      }
    }, 300);
  }

  getFormById(externalId: string, numberOfUnits: number = null): FormGroup {
    return this.form.controls.find((form: FormGroup) => {
      return form.controls['externalId'].value === externalId
        && (!numberOfUnits || !!numberOfUnits && form.controls['numberOfUnits'].value === numberOfUnits);
    }) as FormGroup;
  }

  get shippedDate(): AbstractControl {
    return this.globalForm.get('shippedDate');
  }

  get getCanConfirmShipping(): boolean {
    return (this.pickStrategy ? this.checkedTransactions.length <= 0 : !this.canConfirmShipping) || this.requestInFlight;
  }

  getShipmentControlName(item: SalesOrderItemResponseModel): string {
    return `shipmentType-${item.masterItem.masterItemTypeId === SellableMasterItemResponseModelMasterItemTypeId.FinishedGood
      ? item.externalId + (item.numberOfUnits ? item.numberOfUnits : item.masterItem.numberOfUnits) : item.externalId}`;
  }

  getShipmentControl(formGroup: FormGroup, externalId: string, numberOfUnits: string = null): AbstractControl {
    return formGroup.get(`shipmentType-${externalId + numberOfUnits}`) ?
      formGroup.get(`shipmentType-${externalId + numberOfUnits}`) :
      formGroup.get(`shipmentType-${externalId}`);
  }

}

interface RemainingQuantity {
  quantity: number;
  externalId: string;
}

interface ItemState {
  externalId: string;
  isValid: boolean;
}
