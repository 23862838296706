import { NgModule } from '@angular/core';
import { UserAccountComponent } from './user-account.component';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared';
import { SidebarModule } from '../sidebar/sidebar.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LoginComponent } from './login/login.component';
import { CallbackPageComponent } from './callback-page/callback-page.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { SsrPageComponent } from './ssr/ssr-page.component';

const routes: Routes = [
  {
    path: 'user-account',
    component: UserAccountComponent,
    children: [
      {
        path: 'callback',
        component: CallbackPageComponent
      },
      { path: '', redirectTo: '', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [
    UserAccountComponent,
    LoginComponent,
    CallbackPageComponent,
    WelcomePageComponent,
    SsrPageComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    NgSelectModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [UserAccountComponent, CallbackPageComponent]
})
export class UserAccountModule {
}
