import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SpinnerService } from '../services/spinner.service';
import * as fetchIntercept from 'fetch-intercept';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private listRequests: (HttpRequest<any> | string)[] = [];
  private detailRequests: (HttpRequest<any> | string)[] = [];
  private doubledDetailRequests: (HttpRequest<any> | string)[] = [];

  private readonly ignoreRequestsPatterns = [
    RegExp('/Payment/token.*'),
    RegExp('/WarehouseContent/assign/bylot.*'),
    RegExp('/manufacturing/(Industry/validateBranchStageOrder).*'),
    RegExp('/reporting/TtbOperationalReports/((?!ttbOperationalReportsFilter).)*$'),
    RegExp('Settings/(manufacturing|formatting)'),
  ];
  private readonly detailsRequestsPatterns = [
    RegExp('/Account/.*'),
    RegExp('/SpiritItem/.*'),
    RegExp('/Pricing.*'),
    RegExp(
      '/LookUp/(roles|masteritems|unitofmeasurement|kindofspirit|fermentablematerial|categories|facilities|filters).*'
    ),
    RegExp(
      '/LookUp/(territories|industries|users|deploymentregions|contractors|unitOfMeasurement|defaultTemperature).*'
    ),
    RegExp('/InventoryHistoryLog/.*'),
    RegExp('/companies/.*'),
    RegExp('/Facility/.*'),
    RegExp('/Payment/.*'),
    RegExp(
      '/manufacturing/Batch/.*/(vessels|getAvailableStages|confirmStage|nextProcess|process|updateBatchProcess|nextAdHocProcess|status|calculateVesselInfo|insertProcess|vesselInfo|branch/.*/semiFinishedGoods/packagingRun/nextLotId).*'
    ),
    RegExp('/manufacturing/ProductFlowBatch/.*/(status|currentProcess|nextProcess|process|update|nextAdHocProcess|stages|finish).*'),
    RegExp('/manufacturing/Batch/(materialDepletion|semiFinishedGoods/generateLabel|finishedGood/packaging/nextLotId|filter).*'),
    RegExp('/manufacturing/Batch/.*/(startBatchInfo|startManualBatch)'),
    RegExp('/manufacturing/LookUp/(users|warehouse/recursive|packagignLine|measurement|volumeToWeight|weightToVolume|unitOfMeasurement|kindofspirit|simpleBatch).*'),
    RegExp('/manufacturing/SimpleBatch/.*'),
    RegExp('/WarehouseContent/assign/bylot.*'),
    RegExp('/WarehouseContent/itemsToTransfer/.*'),
    RegExp('/WarehouseContent/itemsToTransfer.*'),
    RegExp('/Warehouse/list.*'),
    RegExp('/Shipping/shipment/uploadFile/.*'),
    RegExp('/Shipping/pick/.*'),
    RegExp('MasterItem/duplicatewithname.*'),
    RegExp('StockCount/(cancel).*'),
  ];
  private readonly doubledDetailsRequestsPatterns = [
    RegExp('/manufacturing/(LookUp/(nextUnitId|remnantCase|nextContainerUnitId|materialDepletion)|ProductFlow/copyFromManualBatch|Batch/.*/(getAvailableProcesses)|Batch/finishedGood/pregenerateLabel).*'),
    RegExp('manufacturing/Batch/.*/(cost|adHocProcessAvailability|materials).*'),
    RegExp(
      '/LookUp/(rawMaterialItemBatches|wipSourceVessels|semiFinishedGoods|containersByFacility|itemBatchContainersByType|packaging/(finishedGoods|semiFinishedGoods)|semiFinishedGoodsItemBatches).*'
    ),
    RegExp('StockCount/(validateTransfer|transfer).*'),
    RegExp('/manufacturing/Equipment/equipmentModelImage/|ProductFlow/getCurrentIndustry.*'),
  ];

  constructor(private spinnerService: SpinnerService) {
    const that = this;
    fetchIntercept.register({
      request(url: string, config: any): Promise<any[]> | any[] {
        that.addRequest(url);
        return [url, config];
      },
      requestError(error: any) {
        that.removeAllFetchRequests();
        return Promise.reject(error);
      },
      response(response: Response): Response {
        that.removeRequest(response.url);
        return response;
      },
      responseError(error: any): Promise<any> {
        that.removeAllFetchRequests();
        return Promise.reject(error);
      }
    });
  }

  addRequest(request: HttpRequest<any> | string) {
    let url: URL = null;
    try {
      url = new URL(request instanceof HttpRequest ? request.url : request);
    } catch (_) {
      return;
    }

    for (const pattern of this.ignoreRequestsPatterns) {
      if (pattern.test(url.pathname)) {
        return;
      }
    }
    for (const pattern of this.detailsRequestsPatterns) {
      if (pattern.test(url.pathname)) {
        this.detailRequests.push(request);
        this.updateSpinners();
        return;
      }
    }
    for (const pattern of this.doubledDetailsRequestsPatterns) {
      if (pattern.test(url.pathname)) {
        this.doubledDetailRequests.push(request);
        this.updateSpinners();
        return;
      }
    }

    this.listRequests.push(request);
    this.updateSpinners();
  }

  updateSpinners() {
    this.spinnerService.setGlobalSpinner(this.listRequests.length > 0);
    this.spinnerService.setDetailSpinner(this.detailRequests.length > 0);
    this.spinnerService.setDoubledDetailSpinner(this.doubledDetailRequests.length > 0);
  }

  removeRequest(req: HttpRequest<any> | string) {
    let i = this.listRequests.indexOf(req);
    if (i !== -1) {
      this.listRequests.splice(i, 1);
    }
    i = this.detailRequests.indexOf(req);
    if (i !== -1) {
      this.detailRequests.splice(i, 1);
    }
    i = this.doubledDetailRequests.indexOf(req);
    if (i !== -1) {
      this.doubledDetailRequests.splice(i, 1);
    }
    this.updateSpinners();
  }

  removeAllFetchRequests() {
    this.listRequests = this.listRequests.filter((req: HttpRequest<any> | string) => req instanceof HttpRequest);
    this.detailRequests = this.detailRequests.filter((req: HttpRequest<any> | string) => req instanceof HttpRequest);
    this.doubledDetailRequests = this.doubledDetailRequests.filter((req: HttpRequest<any> | string) => req instanceof HttpRequest);
    this.updateSpinners();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addRequest(req);

    return Observable.create(observer => {
      const sub = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        });
      return () => {
        this.removeRequest(req);
        sub.unsubscribe();
      };
    });
  }
}
