import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { debounce, difference } from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, take, takeUntil } from 'rxjs/operators';
import {
  SalesOrderShippedItemRequestModelShipmentItemSelectionCriteriaId as ShippedCriteriaId,
  ShipmentIdsRequestModel,
  ShipmentIdsResponseModel,
  ShipmentUnitIdsResponseModel,
  ShippedItemRequestModel
} from '../../../../../core/services/Sales';
import { ShippingService } from '../../../../services/shipping.service';
import { BaseShipForm } from '../base-ship-form';
import { RangeItem } from '../../../../../sales/shared/components/sales-items/sales-items-interfaces';
import { FinishedGoodsSelectItemsService } from '../../../../services/finished-goods-select-items.service';
import { SettingsService } from 'app/account/settings/settings.service';

@Component({
  selector: 'app-finished-good',
  templateUrl: './finished-good.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinishedGoodComponent extends BaseShipForm implements OnInit, OnDestroy {

  validateUnitId = debounce((idx: number) => {
    this.unitIdChanged(idx);
  }, 200);

  quantityOnHand = 0;

  get itemExternalIdWithNumberOfUnits(): string {
    return this.item.numberOfUnits ? this.item.externalId + this.item.numberOfUnits : this.item.externalId;
  }

  get masterItemExternalIdWithNumberOfUnits(): string {
    return this.item.masterItem.numberOfUnits ? this.item.masterItem.externalId + this.item.masterItem.numberOfUnits : this.item.masterItem.externalId;
  }

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              public shippingService: ShippingService,
              public settingsService: SettingsService,
              private finishedGoodsSelectItemsService: FinishedGoodsSelectItemsService
  ) {
    super(shippingService, settingsService);
  }

  ngOnInit() {
    this.quantityOnHand = this.editMode ? this.item.masterItem.quantityOnHand + this.shippingDetails.quantity : this.item.masterItem.quantityOnHand;
    super.ngOnInit();
    this.shipmentTypeValueChanges$.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$))
      .subscribe(() => {
        for (let idx = 0; idx < this.validShipping.length; idx++) {
          this.removeFormArrayItem(idx);
        }
        this.form.get('shippedQuantity').setValue(null);
        this.emitCurrentShipping(this.form.get(`shipmentType-${this.itemExternalIdWithNumberOfUnits}`).value);
        this.cdr.markForCheck();
      });
    const selectedRanges = this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit();
    selectedRanges.pipe(
      skip(1),
      distinctUntilChanged(),
      map((items: Map<string, RangeItem[]>) => items),
      filter((items: Map<string, RangeItem[]>) => !!items.get(this.masterItemExternalIdWithNumberOfUnits)
        && !!items.get(this.masterItemExternalIdWithNumberOfUnits).length),
      takeUntil(this.destroy$)
    ).subscribe((items: Map<string, RangeItem[]>) => {
      const currentItems: RangeItem[] = items.get(this.masterItemExternalIdWithNumberOfUnits);
      const controlLength: number = this.unitIds.controls.length;
      if (controlLength > 0) {
        for (let len = controlLength - 1; len > -1; len--) {
          super.removeFormArrayItem(len, null);
          this.unitIds.removeAt(len);
          this.shippedQuantity.splice(len, 1);
          this.validShipping.splice(len, 1);
        }
      }

      currentItems.forEach((currentItem: RangeItem) => {
        const isElementAlreadyAdded = this.unitIds.controls.find(
          (formGroup: FormGroup) => formGroup.controls['startId'].value === currentItem.start
                                 && formGroup.controls['endId'].value === currentItem.end
                                 && formGroup.controls['lotId'].value === currentItem.lotId);

        if (!this.unitIds.controls.length || !isElementAlreadyAdded) {
          this.addUnitIds(currentItem.start, currentItem.end, currentItem.lotId, currentItem.length);
          const foundControl = this.unitIds.controls.find(control => control.value.startId === currentItem.start);
          const idx = this.unitIds.controls.indexOf(foundControl);
          this.updateQuantity(idx, currentItem);
        }
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.finishedGoodsSelectItemsService.clearSelectedRangesToSubmit();
    this.finishedGoodsSelectItemsService.clearSelectedUnitIdsToSubmit();
  }

  addUnitIds(startId: string = '', endId: string = '', lotId: string = null, quantity: number = 0) {
    this.unitIds.push(this.fb.group({
      startId: new FormControl(startId, { validators: Validators.required, updateOn: 'blur' }),
      endId: new FormControl(endId, { validators: Validators.required, updateOn: 'blur' }),
      lotId: new FormControl(lotId),
    }));
    this.shippedQuantity.push({quantity, isValid: true});
    if (!!quantity) {
      this.validShipping.push(new ShippedItemRequestModel({
        startId,
        endId,
        lotId,
        quantity
      }));
    } else {
      this.validShipping.push(null);
    }

    combineLatest([
      this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit(),
      this.finishedGoodsSelectItemsService.getSelectedUnitIdsToSubmit()
    ]).pipe(take(1)).subscribe(([selectedRangesMap, selectedUnitIds]: [Map<string, RangeItem[]>, Map<string, string[]>]) => {
      if (!selectedRangesMap.has(this.masterItemExternalIdWithNumberOfUnits)) {
        this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, null);
      }
      if (!selectedUnitIds.has(this.masterItemExternalIdWithNumberOfUnits)) {
        this.finishedGoodsSelectItemsService.setSelectedUnitIdsToSubmit(this.masterItemExternalIdWithNumberOfUnits, []);
      }
    });

    this.cdr.markForCheck();
  }

  initEditForm() {
    this.shippedQuantity = [];
    while (this.unitIds.length > 0) {
      this.unitIds.removeAt(0);
    }
    if (this.shipmentType.value === this.ShipmentType.ByUnitIds && this.shippingDetails) {
      this.shippingDetails.details.forEach(shippingDetails => {
        this.addUnitIds(shippingDetails.startId, shippingDetails.endId, shippingDetails.lotId, shippingDetails.quantity);
      });
    } else if (this.shipmentType.value === this.ShipmentType.ByCount) {
      this.quantityChanged();
    }
  }

  initFormBoxes() {
    this.formBoxes = {
      startId: {
        fieldType: 'input',
        name: 'startId',
        field: 'startId',
        placeholder: 'Start ID'
      },
      endId: {
        fieldType: 'input',
        name: 'endId',
        field: 'endId',
        placeholder: 'End ID'
      },
      lotId: {
        fieldType: 'input',
        name: 'lotId',
        field: 'lotId',
      },
      shippedQuantity: {
        fieldType: 'input-number',
        name: 'shippedQuantity',
        label: `${this.salesApproval ? 'Picked' : 'Shipped'} quantity`,
        field: 'shippedQuantity',
        isInteger: true,
        placeholder: `${this.salesApproval ? 'Picked' : 'Shipped'} quantity`
      },
    };
  }

  removeFormArrayItem(idx: number, event?: Event) {
    super.removeFormArrayItem(idx, event);
    this.unitIds.removeAt(idx);
    this.shippedQuantity.splice(idx, 1);
    this.validShipping.splice(idx, 1);

    combineLatest([
      this.finishedGoodsSelectItemsService.getSelectedUnitIdsToSubmit(),
      this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit()
    ]).pipe(take(1)).subscribe(
      ([unitIdsMap, rangeItemsMap]: [Map<string, string[]>,  Map<string, RangeItem[]>]) => {
        const newRangeItems: RangeItem[] = rangeItemsMap.get(this.masterItemExternalIdWithNumberOfUnits);
        const unitIds: string[] = unitIdsMap.get(this.masterItemExternalIdWithNumberOfUnits);
        const newUnitIds: string[] = difference(unitIds, newRangeItems ? newRangeItems[idx].items : []);
        if (newRangeItems && newRangeItems.length) {
          newRangeItems.splice(idx, 1);
        }
        this.finishedGoodsSelectItemsService.setSelectedUnitIdsToSubmit(this.masterItemExternalIdWithNumberOfUnits, newUnitIds);
        this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, newRangeItems);
      }
    );

    for (let index = 0; index < this.unitIds.length; index++) {
      this.unitIdChanged(index);
    }
    if (!this.unitIds.length) {
      this.emitCurrentShipping(ShippedCriteriaId.ByUnitIds);
    }
  }

  unitIdChanged(idx: number, shouldSave: boolean = true): void {
    this.validShipping[idx] = null;

    const form = this.unitIds.controls[idx];
    if (!!form.get('startId').value && !!form.get('endId').value) {
      this.shippingService.validateUnitId(new ShipmentIdsRequestModel({
        startId: form.get('startId').value,
        endId: form.get('endId').value,
        lotId: form.get('lotId').value,
        masterItemExternalId: this.item.masterItem.externalId,
        isRemnant: this.item.isRemnant,
        numberOfUnits: this.item.numberOfUnits,
      })).subscribe((data: ShipmentIdsResponseModel) => {
        const remainingQuantity = this.getRemainingQuantity;
        this.shippedQuantity[idx].quantity = data.quantity;
        this.shippedQuantity[idx].isValid = data.quantity <= remainingQuantity;
        this.formSubmitStatus = true;
        form.get('startId').setErrors(data.startIdValid ? null : {'incorrect': true});
        form.get('endId').setErrors(data.startIdValid ? null : {'incorrect': true});
        this.cdr.markForCheck();
        const isDataValid: boolean = data.startIdValid
          && data.endIdValid
          && data.quantity > 0
          && data.quantity <= remainingQuantity;
        if (!isDataValid) {
          this.validShipping[idx] = null;
          if (shouldSave) {
            combineLatest([
              this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit(),
              this.finishedGoodsSelectItemsService.getSelectedUnitIdsToSubmit()
            ]).pipe(take(1)).subscribe(([selectedRangesMap, selectedUnitIds]: [Map<string, RangeItem[]>, Map<string, string[]>]) => {
              const range: RangeItem[] = selectedRangesMap.get(this.masterItemExternalIdWithNumberOfUnits);
              const selectedIds: string[] = selectedUnitIds.get(this.masterItemExternalIdWithNumberOfUnits);
              if (!!selectedIds && !!selectedIds.length && !!range[idx]) {
                const diff: string[] = difference(selectedIds, range[idx].items);
                this.finishedGoodsSelectItemsService.setSelectedUnitIdsToSubmit(this.masterItemExternalIdWithNumberOfUnits, diff);
              }
              if (!!range && !!range.length && !!range[idx]) {
                const newRangeItems: RangeItem = {
                  start: data.startId,
                  end: data.endId,
                  items: [],
                  length: data.quantity,
                  lotId: data.lotId
                };
                range[idx] = newRangeItems;
                this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, range);
              }
            });
          }
        } else {
          this.validShipping[idx] = new ShippedItemRequestModel({
            startId: form.get('startId').value,
            endId: form.get('endId').value,
            lotId: form.get('lotId').value,
            quantity: data.quantity
          });

          this.finishedGoodsSelectItemsService.setSelectedUnitIdsData(
            this.masterItemExternalIdWithNumberOfUnits,
            data.lotId,
            data.unitIds
          );

          if (shouldSave) {
            combineLatest([
              this.finishedGoodsSelectItemsService.getSelectedRangesToSubmit(),
              this.finishedGoodsSelectItemsService.getSelectedUnitIdsToSubmit()
            ]).pipe(take(1)).subscribe(([selectedRangesMap, selectedUnitIds]: [Map<string, RangeItem[]>, Map<string, string[]>]) => {
              const items: string[] = data.unitIds.map((item: ShipmentUnitIdsResponseModel) => item.uniId);
              const newRangeItems: RangeItem = {
                start: data.startId,
                end: data.endId,
                items,
                length: data.quantity,
                lotId: data.lotId
              };
              const range: RangeItem[] = selectedRangesMap.get(this.masterItemExternalIdWithNumberOfUnits) || [];

              if (!range) {
                this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, [newRangeItems]);
              } else if (!!range[idx]) {
                range[idx] = newRangeItems;
                this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, range);
              } else {
                range.push(newRangeItems);
                this.finishedGoodsSelectItemsService.setSelectedRangesToSubmit(this.masterItemExternalIdWithNumberOfUnits, range);
              }

              if (selectedUnitIds.has(this.masterItemExternalIdWithNumberOfUnits) && !!range.length) {
                const uniqueUnitItems: string[] = [];
                range.forEach((rangeItem: RangeItem) => {
                  rangeItem.items.forEach((unitId: string) => uniqueUnitItems.push(unitId));
                });
                const uniqueArray: string[] = [...new Set(uniqueUnitItems)];
                this.finishedGoodsSelectItemsService.setSelectedUnitIdsToSubmit(this.masterItemExternalIdWithNumberOfUnits, uniqueArray);
              } else {
                this.finishedGoodsSelectItemsService.setSelectedUnitIdsToSubmit(this.masterItemExternalIdWithNumberOfUnits, items);
              }
            });
          }
        }
        this.emitCurrentShipping(ShippedCriteriaId.ByUnitIds);
      });
    }
  }

  quantityChanged(): void {
    this.validShipping = [null];

    const quantity = this.form.get('shippedQuantity');
    const quantityValue = +quantity.value;
    const remainingQuantity = this.getRemainingQuantity;
    const isQuantityAvailable = quantityValue <= remainingQuantity && quantityValue <= this.quantityOnHand;
    quantity.setErrors(isQuantityAvailable ? null : {'incorrect': true});
    this.formSubmitStatus = true;
    if (quantityValue && quantityValue > 0 && isQuantityAvailable) {
      this.validShipping = [new ShippedItemRequestModel({
        quantity: quantityValue
      })];
    } else {
      this.validShipping = [null];
    }
    this.emitCurrentShipping(ShippedCriteriaId.ByCount);
  }

  private updateQuantity(idx: number, data: any) {
    this.shippedQuantity[idx] = {
      ...this.shippedQuantity[idx],
      quantity: data.length,
      isValid: true,
    };
    this.formSubmitStatus = true;
    this.unitIdChanged(idx, false);
  }

}
