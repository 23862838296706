export enum Manufacturing {
}

export enum EquipmentType {
  ManufacturingEquipment = 'ManufacturingEquipment',
  PackagingEquipment = 'PackagingEquipment'
}

export enum EquipmentModelType {
  MashTun = 'MashTun',
  Fermenter = 'Fermenter',
  Still = 'Still',
  SpiritsTank = 'SpiritsTank',
  Misc = 'Misc',
  BottleFiller = 'BottleFiller',
  BottleRinser = 'BottleRinser',
  CartonSealer = 'CartonSealer'
}

export enum VolumeUnitType {
  Liter = 'Liter',
  Gallon = 'Gallon'
}

export enum WeightUnitType {
  Kilogram = 'Kilogram',
  Gram = 'Gram',
  Pound = 'Pound'
}

export enum TemperatureUnitType {
  Celsius = 'Celsius',
  Fahrenheit = 'Fahrenheit'
}

export enum TimeUnitType {
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day'
}

export enum PureAlcoholUnitType {
  ProofGallons = 'ProofGallons',
  Laa = 'Laa'
}

export enum AlcoholContentUnitType {
  Proof = 'Proof',
  Abv = 'Abv'
}

export enum ThroughputUnitType {
  PerMinute = 'PerMinute',
  PerHour = 'PerHour'
}

export enum CutType {
  Heads = 'Heads',
  Hearts = 'Hearts',
  LowWines = 'LowWines',
  Foreshots = 'Foreshots',
  Tails = 'Tails',
}

export enum FilterOptionsKind {
  String = 'String',
  Enum = 'Enum',
  Date = 'Date',
  Int = 'Int'
}

export enum EProcessAttributeType {
  BatchTemperature = 'BatchTemperature',
  Durations = 'Durations',
  Materials = 'Materials',
  CustomDecimal = 'CustomDecimal',
  CustomInteger = 'CustomInteger',
  CustomText = 'CustomText',
  CustomDate = 'CustomDate',
  AlcoholContent = 'AlcoholContent',
  PureAlcohol = 'PureAlcohol',
  KindOfSpirits = 'KindOfSpirits',
  CutType = 'CutType',
  Gravity = 'Gravity',
  FinalGravity = 'FinalGravity',
  BatchPhValue = 'BatchPhValue',
  Transfer = 'Transfer',
  Amount = 'Amount',
  SplitTransfer = 'SplitTransfer',
  MergeTransfer = 'MergeTransfer',
  SemiFinishedGoodsPackaging = 'SemiFinishedGoodsPackaging',
  FinishedGoodsPackaging = 'FinishedGoodsPackaging',
  FinalVolume = 'FinalVolume',
  FinalProof = 'FinalProof',
  LossVolume = 'LossVolume',
  TareWeight = 'TareWeight',
  SemiFinishedGoods = 'SemiFinishedGoods',
  FinishedGoods = 'FinishedGoods',
  Water = 'Water',
  AlcoholSource = 'AlcoholSource',
  WipMaterials = 'WipMaterials',
}

export enum TransferType {
  Regular = 'Regular',
  Split = 'Split',
  Merge = 'Merge'
}

export enum BatchVesselType {
  SplitOrigin = 'SplitOrigin',
  SplitTarget = 'SplitTarget',
  MergeOrigin = 'MergeOrigin',
  MergeTarget = 'MergeTarget'
}
